import { Sensor } from 'features/assets-management/models';

export const compare = (sensorA : Sensor, sensorB : Sensor) => {
  if (sensorA.sensorId < sensorB.sensorId) return -1;
  else if (sensorA.sensorId > sensorB.sensorId) return 1;
  else return 0;
};

export const compareSensorName = (sensorA : Sensor, sensorB : Sensor) => {
  if (sensorA.name < sensorB.name) return -1;
  else if (sensorA.name > sensorB.name) return 1;
  else return 0;
};
