import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import { ReactNode } from 'react';

export interface DataTableHeadContainerProps {
  selectedCount: number;
  rowCount: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
  checkboxes?: string;
  backgroundCell?: any;
  scrollable?: boolean;
  addPreColumn ?: boolean;
}

const DataTableHeadContainer = (props: DataTableHeadContainerProps) => {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: props.selectedCount ? 'primary.light' : '' }}>
        {props.addPreColumn && <TableCell colSpan={1} />}
        {props.checkboxes !== 'none' && (
          <TableCell
            padding='checkbox'
            sx={
              props.scrollable
                ? { zIndex: 10, background: props.selectedCount ? 'rgb(240, 243, 244)' : '#fff' }
                : undefined
            }
          >
            {props.checkboxes !== 'single-select' && (
              <Checkbox
                color='primary'
                // indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={props.rowCount > 0 && props.selectedCount >= props.rowCount}
                onChange={props.onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all items',
                }}
              />
            )}
          </TableCell>
        )}
        {props.children}
      </TableRow>
    </TableHead>
  );
};

export default DataTableHeadContainer;
