import { Box, IconButton, Modal } from "@mui/material";
import { FC, memo, useState } from "react";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import MainImage from "./MainImage";
import {Image} from "./models/image.model";

type FullImageProps = {
    assetId: number;
    image: Image;
}
const FullImage:FC<FullImageProps> = ({ assetId, image}) =>{
    const [expand, setExpand] = useState<boolean>(false);
    return (
     <>
      <Box
        position="absolute"
        zIndex="1000"
        bottom=".2rem"
        right=".2rem"
      > 
        <IconButton color="primary" onClick={() => setExpand(true)}>
            <OpenInFullIcon sx={{fontSize:"2rem"}} />
        </IconButton>
      </Box>
      <Modal
          open={expand}
          onClose={ () => setExpand(false)}
       >
          <Box sx={{position:"absolute", top:"50%", left:"50%", transform: "translate(-50%, -50%)", minWidth:"20rem", height:"auto", borderRadius:"0.8rem", display:"flex", justifyContent:"center", alignItems:"center", background:"silver" }}>
             <MainImage assetId={assetId} image={image} full={true}/>
          </Box>
       </Modal>
     </>
    )
}

export default memo(FullImage);