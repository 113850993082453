import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChannelDetails } from 'api/users';
import { RootState } from 'app/store';
import { UserRoleInfo } from 'models/user-role.model';
import moment from 'moment-timezone';

export interface AccountState {
  selectedPlant: UserRoleInfo | undefined;
  refetchAssetTree: boolean;
  navbarOpened: boolean;
  autoTimezone: boolean;
  eulaAccepted: boolean;
  timezone: string;
  language: string | null;
  channelDetails?: ChannelDetails;
}

const initialState: AccountState = {
  navbarOpened: false,
  selectedPlant: undefined,
  refetchAssetTree: false,
  autoTimezone: true,
  language: null,
  eulaAccepted: false,
  timezone: moment.tz.guess(true),
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setPlant: (state, action) => {
      state.selectedPlant = action.payload;
    },
    setRefetchAssetTree: (state, action) => {
      state.refetchAssetTree = action.payload;
    },
    setNavbarOpen: (state, action) => {
      state.navbarOpened = action.payload;
    },
    setAutoTimezone: (state, action) => {
      state.autoTimezone = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setEulaAccepted: (state, action) => {
      state.eulaAccepted = action.payload;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setChannelDetails: (state, action: PayloadAction<ChannelDetails | undefined>) => {
      state.channelDetails = action.payload;
    },
    setChannelId: (state, action: PayloadAction<number>) => {
      if (!state.channelDetails) return;
      state.channelDetails.channelId = action.payload;
    },
  },
});

export const {
  setNavbarOpen,
  setPlant,
  setRefetchAssetTree,
  setAutoTimezone,
  setEulaAccepted,
  setLanguage,
  setTimezone,
  setChannelId,
  setChannelDetails,
} = accountSlice.actions;

export const selectPlant = (state: RootState) => state.account.selectedPlant;
export const refetchAssetTree = (state: RootState) => state.account.refetchAssetTree;
export const selectPlantId = (state: RootState): number =>
  state.account.selectedPlant?.plantId as number;
export const selectNavbarOpen = (state: RootState) => state.account.navbarOpened;
export const selectAutoTimezone = (state: RootState) => state.account.autoTimezone;
export const selectLanguage = (state: RootState) => state.account.language;
export const selectEulaAccepted = (state: RootState) => state.account.eulaAccepted;
export const selectTimezone = (state: RootState) => state.account.timezone;
export const selectChannelDetails = (state: RootState) => state.account.channelDetails;
export const selectChannelId = (state: RootState) => state.account.channelDetails?.channelId;

export default accountSlice.reducer;
