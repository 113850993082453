import { selectPlantId } from '../../store/accountSlice';
import { useSelector } from 'react-redux';
import { useAuthMutation } from 'api/queries';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import axios from 'axios';

export interface CreateAssetBody {
  name: string;
  // speed: number;
  serialNumber: string;
  note: string;
  photosUrl: string[];
  nameplatesUrl: string[];
  partId: number;
  configuration: { [key: string]: string | number };
  assetApplicationId: number;
}

interface CreateAssetResponse {
  assetId: number;
}

export interface AssetDetails {
  id: number;
  name: string;
  assetGroupId: number;
  assetGroupName: string;
  assetType: string;
  assetApplicationId: number;
}

export const useCreateAsset = (groupId: number, onSuccess?: (assetId: number) => void) => {
  const plantId = useSelector(selectPlantId);
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastSuccessMess: translate('asset_created_successfully_toast'),
      toastErrorMess: translate('failed_to_create_asset_toast'),
      name: translate('menu_name_label'),
      exists: translate('already_exists_toast'),
    };
  }, []);

  return useAuthMutation<{ name: string }, CreateAssetBody, CreateAssetResponse>({
    url: `plants/${plantId}/assets/?assetGroupId=${groupId}`,
    method: 'POST',
    mutationOptions: {
      onSuccess: ({ data }) => {
        onSuccess?.(data.assetId);
        toast.success(componentText.toastSuccessMess);
        queryClient.invalidateQueries({ predicate: ({ queryKey }) => queryKey.includes('assets') });
        queryClient.invalidateQueries({ predicate: ({ queryKey }) => queryKey.includes('parts') });
      },
      onError: (e, variables) => {
        if (
          e.message === 'UniqueKeyViolation' ||
          (axios.isAxiosError(e) && e.response?.data.message === 'UniqueKeyViolation')
        ) {
          toast.error(`${componentText.name} ${variables.params?.name} ${componentText.exists}.`);
        } else {
          toast.error(componentText.toastErrorMess);
        }
      },
    },
  });
};
