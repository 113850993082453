import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export const useAcceptEula = (onAcceptEula: () => void) => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('successfully_accepted_eula_toast'),
      errorMess: translate('failed_to_accept_eula_toast'),
    };
  }, []);
  return useAuthMutation<{}>({
    url: `users/accept-eula`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.successMess);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('roles') });
        onAcceptEula();
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
