import { Grid } from '@mui/material';
import PlantDropdown from './PlantDropdown';
import PlantMenu from './PlantMenu';
import { useSelector } from 'react-redux';
import { selectPlant } from 'store/accountSlice';
import { useMemo } from 'react';
import { useDeviceSize } from 'shared/responsive';

const DEFAULT_FONT = '400 16px Archivo';
const DEFAULT_WIDTH = 256;
const DEFAULT_WIDTH_SMALLSCREEN = 195;
const DEFAULT_WIDTH_VERYSMALLSCREEN = 120;
export const DEFAULT_INPUT_WIDTH = 220;
export const INPUT = 185;
export const PADDING = 35;

export const getTextWidth = (text : string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context!.font = DEFAULT_FONT;
  const metrics = context!.measureText(text);
  return metrics.width;
};

const PlantFilter = () => {
  const selectedPlant = useSelector(selectPlant);
  const textWidth = getTextWidth(String(selectedPlant?.plantName));

  const { isXSPhone, isPhone, isTablet } = useDeviceSize();

  const width = useMemo(() => {
    return isXSPhone
      ? `${DEFAULT_WIDTH_VERYSMALLSCREEN}px`
      : !isPhone
        ? !isTablet
          ? `${textWidth > INPUT ? textWidth + 36 + PADDING : DEFAULT_WIDTH}px`
          : `${DEFAULT_WIDTH}px`
        : `${DEFAULT_WIDTH_SMALLSCREEN}px`;
  }, [isXSPhone, isPhone, isTablet, selectedPlant]);

  return (
    <Grid width={width} container justifyContent='space-between' alignItems='center'>
      <PlantDropdown textWidth={textWidth} />
      {!isXSPhone && <PlantMenu />}
    </Grid>
  );
};

export default PlantFilter;
