import { useAuthQuery } from 'api/queries';
import { SensorType } from 'models/sensor/models';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

const SORT_ORDER = ['assetName', 'assetGroupName', 'location', 'sensorType'] as const;
export type SortOrder = (typeof SORT_ORDER)[number];

export type NotifCategory = {
  key: string;
  label: string;
  email: boolean;
  push: boolean;
};

type Props = {
  pageSize: number;
  pageNumber: number;
  assetGroup?: string;
  sensorType?: SensorType | '';
  searchString?: string;
  sortOrder?: string;
};

type Record = {
  assetId: number;
  assetName: string;
  assetGroupName: string;
  notificationCategories: NotifCategory[];
};

type Response = {
  totalRecords: number;
  notificationCategories: {
    [Key: string]: string;
  };
  assetGroups: string[];
  records: Record[];
};

export const useGetManageRecurrEventsNotif = ({
  pageNumber,
  pageSize,
  assetGroup = '',
  searchString = '',
  sortOrder = '',
}: Props) => {
  const plantId = useSelector(selectPlantId);

  return useAuthQuery<Response>({
    url: `users/plant/${plantId}/manage-assets-notifications`,
    params: {
      pageSize: pageSize.toString(),
      pageNumber: (pageNumber + 1).toString(),
      assetGroupName: assetGroup,
      searchString,
      sortOrder,
    },
  });
};
