import { Card, CardActions, Collapse, Divider, Grid, IconButton, IconButtonProps, Typography, styled } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
export const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} sx={{p:0}} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

type Props = {
  title: string;
  children: ReactNode;
}
const Settings:FC<Props> = ({title, children}) =>{
    const [expanded, setExpanded] = useState(true);
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    
    return (
      <Card elevation={0} sx={{background:"inherit"}}>
        <CardActions disableSpacing onClick={handleExpandClick} sx={{cursor: "pointer"}}>
            <Typography color="black" fontSize=".875rem" fontWeight="600" letterSpacing="0.15px">{title}</Typography> 
            <ExpandMore
                expand={expanded}
                aria-expanded={expanded}
                color="primary"
            >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        {!expanded && <Divider sx={{width: "100%"}} />}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid container direction="column" gap={2} px=".4rem" my=".5rem">
             {children}
          </Grid>
        </Collapse>
        {expanded && !title.includes("Display") && <Divider sx={{width: "100%"}} />}
      </Card>
    )
}

export default Settings;