import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface ManufacturerApiModel {
  ids: number[];
  name: string;
}

export const useGetManufacturers = (category?: string, motorBearingManufacturer?: boolean) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_dodge_parts_toast'),
    };
  }, []);

  return useAuthQuery<ManufacturerApiModel[]>({
    url: `manufacturers`,
    params: {
      ...(category ? { category } : {}),
      ...(motorBearingManufacturer
        ? { motorBearingManufacturer: String(motorBearingManufacturer) }
        : {}),
    },
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
