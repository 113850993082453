import { Alert, Button, Divider, Grid, Typography } from "@mui/material";
import { Loading } from "components";
import { useEffect, FC, memo, Dispatch, SetStateAction, useState } from "react";
import { QrReader } from 'react-qr-reader';
import ReplayIcon from '@mui/icons-material/Replay';
import { DisplayType } from "../../CommissionsSensors/IntellilubeCommissionModal";
interface QrCodeReaderProps {
  setQrCode: Dispatch<SetStateAction<string>>;
  setDisplay?: Dispatch<SetStateAction<DisplayType>>;
  validateCartridgeSizeQRCode?: boolean;
  setCustomBack?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setCustomNext?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  videoWidth?: number;
  marginTop?: string;
}

const QrCodeReader: FC<QrCodeReaderProps> = ({ setQrCode, setDisplay, validateCartridgeSizeQRCode = true, setCustomBack, setCustomNext, videoWidth, marginTop}) => {
  const [scanSucces, setScanSucces] = useState(false);
  const [alertToggle, setAlertToggle] = useState(false);
  const [permission, setPermission] = useState(true);
  const [working, setWorking] = useState(false);
  const [qrCodeToValidate, setQrCodeToValidate] = useState('');

  useEffect(() => setQrCode(''), [setQrCode]);

  useEffect(() => {
    if (!qrCodeToValidate) {
      return;
    }

    const regexValidate = validateCartridgeSizeQRCode ? /^[A-Z]{2}(-\d+)+$/ : /^[\d.]+(;[\d.\-A-Z]+){15,}$/;
    if (regexValidate.test(qrCodeToValidate)) {
      setScanSucces(true);
      setQrCode(qrCodeToValidate);
    } else {
      setScanSucces(false);
    }
  }, [qrCodeToValidate, setQrCode, validateCartridgeSizeQRCode]);

  const alert = scanSucces ? (
    <Alert severity="success">
      QR code: {qrCodeToValidate}
    </Alert>
  ) : (
    <Alert severity="error">
      Wrong QR code. Try Again
    </Alert>
  );

  return (
    <>
      {alert && alertToggle && alert}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        marginTop={marginTop}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
        </Grid>
        <Grid item sx={{ width: videoWidth ? videoWidth : 0.8 }}>
          {!permission && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{
                minHeight: "30vh",
                textAlign: "center",
                color: "gray",
              }}
            >
              <Typography fontWeight='bold'>You can't scan qr code.</Typography>
              <Typography fontWeight='bold'>Check access to camera and try again.</Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: 'none', borderRadius: '0.75rem', fontWeight: 'bold', mt: '0.5rem' }}
                startIcon={<ReplayIcon />}
                onClick={() => setPermission(true)}
              >
                Retry
              </Button>
            </Grid>
          )}
          {permission && !working && <Loading height={"32rem"}/>}
          {permission && (
            <QrReader
              constraints={{ facingMode: "user" }}
              videoContainerStyle={{display: working ? "block" : "none"}}
              onResult={(result, error) => {
                if (result) {
                  setWorking(true);
                  setAlertToggle(true);
                  setQrCodeToValidate("");
                  try {
                    let qr = result.getText()
                    if (qr) {
                      setQrCodeToValidate(qr)
                      setScanSucces(true);
                    } else {
                      setScanSucces(false);
                    }
                  } catch (e) {
                    setScanSucces(false);
                  }
                }

                if (error) {
                  setWorking(true);
                  if (
                    !!error.message &&
                    error.message.toLowerCase() ===
                    "Permission denied".toLowerCase()
                  ) {
                    setPermission(false);
                  }
                }
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Divider />
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <Button
            variant="outlined"
            color="secondary"
            sx={{ color: "text.primary", fontWeight: "bold" }}
            onClick={setCustomBack ? (e) => setCustomBack(e) : () => setDisplay?.('form')}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "text.primary", fontWeight: "bold" }}
            disabled={!scanSucces}
            onClick={setCustomNext ? (e) => setCustomNext(e) : () => setDisplay?.('form')}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(QrCodeReader)