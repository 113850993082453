import { useTranslation } from "languages";

export const useGetCommissionStatusTranslated = () =>{
  const { translate } = useTranslation();
  const translationDictionary = {
    pending: translate("pending_commission_status"),
    "cannot connect": translate("cannot_connect_commission_status"),
    commissioned: translate("commissioned_commission_status"),
    commissioning: translate("commissioning_commission_status"),
    "firmware updating": translate("firmware_updating_commission_status"),
    "commissioning failed": translate("commissioning_failed_commission_status"),
    "firmware update failed" : translate("firmware_update_failed_commission_status"),
    "missing sensor firmware" : translate("missing_sensor_firmware_commission_status")
  };

    const getCommissionStatusTranslated = (status : string) => {
      const statusKey = status.toLowerCase()
      if (status && statusKey in translationDictionary) {
          return translationDictionary[statusKey as keyof typeof translationDictionary];
        }
        return status;
      }

    return getCommissionStatusTranslated;
}