import { FC, memo, useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRejectUserFromPlant } from 'api/users';

interface DeletePlantProps {
  selectedPlant: number;
}
const DeletePlant: FC<DeletePlantProps> = ({ selectedPlant }) => {
  const { mutate: rejectUserFromPlant, isPending } = useRejectUserFromPlant();
  const deletePlantHandler = useCallback(
    () => rejectUserFromPlant({ params: { plantId: selectedPlant } }),
    [rejectUserFromPlant, selectedPlant],
  );
  return (
    <LoadingButton
      loading={isPending}
      onClick={deletePlantHandler}
      sx={isPending ? { minWidth: 0 } : { padding: 0, minWidth: 0 }}
    >
      {!isPending && <DeleteIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
    </LoadingButton>
  );
};

export default memo(DeletePlant);
