import { Typography } from "@mui/material";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import CookiePageContainer from "../CookiePageContainer";
import { bigSubTitle, body, subTitle } from "../styles/cookiePageStyles";

type Props = {
    open: boolean;
    onClose: () => void;
    showAccept: boolean;
  }

export const LicenceArgeement = (props: Props) =>{
  const { width } = useWindowDimensions();
  let fontSizeString = "1rem";
  let fontExhibit = "1.1rem";
  let fontBigSubtitle = "1rem";
  if(width < 550){
    fontSizeString = "0.9rem";
    fontExhibit = "0.9rem";
    fontBigSubtitle = "0.9rem";
  }
    return(
        <CookiePageContainer open={props.open} title="DODGE OPTIFY END USER LICENSE AGREEMENT" showAccept = {props.showAccept} >
          <Typography sx={{...body}} fontSize={fontSizeString} >
            Please read the terms and conditions of this End User License Agreement carefully before accessing or using the Dodge OPTIFY Platform which includes Software: 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString} >
           This End User License Agreement (“Agreement”) is an agreement between You and Dodge Industrial, Inc. ("Dodge") with a registered office at 1061 Holland Road, Simpsonville, SC 29681 USA, governing the use by you of the Platform.  Unless otherwise indicated, capitalized terms used in this Agreement shall have the meanings ascribed to such terms in Section 14 of this Agreement. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={2} mt={2}>
           Upon your initial login to the Platform, You will be prompted to accept  this Agreement.  By (i) downloading, installing, accessing, activating, or otherwise using the Platform or you are agreeing to the terms and conditions of this Agreement. If You are performing any of the foregoing on behalf of a company or other entity, “You” means that entity, and you are binding that entity to this Agreement. You represent and warrant that you have the legal power and authority to enter into this Agreement and that, if the licensee is an entity, this Agreement is entered into by an employee or agent with all necessary authority to bind that entity to this Agreement. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={2} mt={2}>
           <span style={{fontWeight:"bold"}}>If You do not agree to these terms,</span> do not (i) download, install, access, activate or otherwise use the Platform or (ii) accept this Agreement; and promptly return the unused media, documentation, and Proof of Entitlement to the party from which it was obtained. If the Software was downloaded or otherwise installed, please destroy all copies of the Software.
         </Typography>
         <Typography sx={{...subTitle, fontSize:fontSizeString}}>
           1. Provision of Services 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>1.1</span> Subject to the terms and conditions of this Agreement and the Proof of Entitlement, and in particular subject to Your activation of the Platform, Dodge shall (i) make the Services within the Platform available to You in accordance with this Agreement and the Proof of Entitlement in all material respects; (ii) apply commercially reasonable safeguards, including technical and organizational measures, in order to establish an appropriate level of security and protection of Customer Data against accidental or unlawful destruction, loss, alteration, and unauthorized disclosure of, or access to Customer Data; and (iii) grant You access to the Platform. Dodge has no liability arising from any malfunction of the Platform and/or the Software, or for any damage to, or malfunctioning of, any device You use to access the Platform and/or the Software. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>1.2</span> Dodge may make any changes to the Platform it deems appropriate in its sole discretion and/or change or remove features or functionality of the Platform from time to time. Such changes may include, for example, changes that are necessary to comply with any applicable laws or safety or security requirement(s). 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>1.3</span> Dodge may provide reports, or access to data and reports, as part of the Platform. Reports are based on the Customer Data collected by the OPTIFY sensors and provided to Dodge. Such data is subject to measurement tolerance and may not fully and/or accurately reflect the status of the machine to which the OPTIFY sensor(s) is attached. Accordingly, any such reports may not fully and/or accurately describe the status of the machine(s) and the actual operational performance may differ from reports and the outcome of data analysis. The reports shall be used as a means of guidance and any decision based on the information provided in a report is taken at Your own risk. To the extent agreed in a separate agreement or otherwise authorized by You, Dodge may provide reports and/or Customer Data to third parties, for example, when such third party provides the Platform as part of its own services to You. Dodge has no liability for the accuracy of any information contained in such reports, and does not undertake any duty to correct any such information, or to notify You of any data and/or other information contained in such reports. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
          <span style={{fontWeight:"bold"}}>1.4</span> Your use of the Platform and access to reports and data may require You to download an app from app stores, such as Apple's App Store or Google Play. Both the app and the app stores have their own terms of use. These terms must be accepted by You when downloading and opening the app for the first time. Dodge has no liability in connection with Your use of such app. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>1.5</span> The Platform may include access to third party websites, app stores and other material provided by third parties, including on the Internet and You may have to access such third-party websites, app stores and/or material and/or download Software from such third-party websites or app stores. Dodge does not operate or control any third-party websites or any other information, services, opinions or other content provided by third parties, including on the Internet (collectively, "External Content"). DODGE MAKES NO WARRANTIES OR REPRESENTATIONS AND HAS NO RESPONSIBILITY OR LIABILITY FOR SUCH EXTERNAL CONTENT AND YOU AGREE THAT YOU SHALL MAKE NO CLAIM WHATSOEVER IN CONNECTION WITH THIS AGREEMENT AGAINST DODGE RELATING TO EXTERNAL CONTENT.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
           2. Software 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>2.1</span> Except if provided otherwise, where Dodge provides Software to You as part of the Platform or embedded in OPTIFY sensors, Dodge hereby grants to You a non-exclusive, non-transferable license to use the Platform for the purposes of receiving the Platform. Installation of Software provided by any party other than Dodge is expressly prohibited under this Agreement. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>2.2</span> You shall have no right to access, copy, alter, make publicly available or in any other way exploit or use Software, including the source code of the Software. You shall have no right to remove or attempt to remove any Software that is embedded in the OPTIFY sensors or to use such Software in any way separate from or unrelated to the OPTIFY sensors or this Agreement. You agree that You shall not, except to the extent that such actions are explicitly permitted by mandatory applicable Laws, attempt to reverse compile, decompile, disassemble or reverse engineer the Software, nor shall You amalgamate, amend, incorporate, modify, reproduce or otherwise alter the same into or with any other software. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>2.3</span> You shall not attempt to make any part of the Software available to any third party or otherwise allow access to the same to any third party if not strictly necessary for the deployment of the Software and in any case under its direct liability and responsibility. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>2.4</span> Dodge may remotely install updates or upgrades to the Software with or without notice. Dodge may further recommend to You to install updates or upgrades to the Software as well as to Your provided infrastructure or software. Updates or upgrades shall be governed by the terms and conditions of this Agreement unless such updates or upgrades are accompanied by a separate license provided by Dodge in which case the terms and conditions of such separate license will govern. Notwithstanding the foregoing, except to the extent specifically set out otherwise in this Agreement, Dodge is not obligated to provide any updates or upgrades.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>2.5</span> You are solely responsible and liable for Your or Third Party Software that You connect to or otherwise use with the Software and You must comply with any third party terms and conditions, including any third party end-user license agreement relating to such Third Party Software. Dodge has no responsibility and liability with regard to Third Party Software.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>2.6</span> You acknowledge and agree that any breach of the terms set forth in this Article 2 shall be deemed a material breach of this Agreement for which Dodge shall be entitled to seek any and all remedies available to it under this Agreement, at law and/or in equity, including, without limitation, injunctive relief.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
           3. Dodge OPTIFY Platform (the Platform) 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>3.1</span> Your use of the Platform will require You to establish an account on the Platform. Failing to do so will mean that You are unable to use the Platform. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>3.2</span> For purposes of administrating the account, You will be required to provide business contact information (for example name, address, email and user IDs) and other information as may be requested. You are responsible for the accuracy, timeliness, completeness and relevance of this business contact information and for any and all activities of a User under an account that is attributable to You. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>3.3</span> You shall use the account and the Platform (i) in accordance with this Agreement, the Acceptable Use Policy and all applicable Laws; (ii) in such a way so as not to impair or compromise the stability or security of the Platform; and (iii) in accordance with any instructions from Dodge.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>3.4</span> You shall ensure that You and any Users keep accounts and credentials (e.g. username, passwords, certificates, keys) confidential. You will notify Dodge immediately on You discovering any attempted or actual unauthorized use of Your account or any other attempted or actual breach of security in connection with this Agreement. Dodge is not responsible or liable for any unauthorized access to Your accounts except if and to the extent such unauthorized access was caused by Dodge's breach of this Agreement. 
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
           4. Your responsibilities  
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>4.1</span> You shall: (i) co-operate with Dodge in all matters relating to the Platform; (ii) comply with any restrictions on permitted User types; (iii) provide Dodge with such information and materials as Dodge may reasonably require in order to provide the Platform, and ensure that such information is accurate, timely and complete in all material respects; (iv) obtain and maintain all necessary licenses, permissions, filings and consents (which shall include consent of individuals where You provide Personal Data to Dodge) which may be required regarding the Customer Data and software and content, if any, provided by You; (v) when using External Content, comply with the respective terms and conditions of use and the license terms and conditions in connection with External Content; (vi) promptly install the necessary software and any updates or upgrades provided by Dodge (in accordance with the respective specification and instructions) on Your computer systems and/or mobile devices (as applicable); (vii) comply with any additional obligations as set out in the Service Description; (viii) comply with Dodge's reasonable instructions regarding the proper use of the Platform as may be given in individual cases from time to time; (ix) comply with the Laws, in particular when providing Customer Data; (x) maintain the OPTIFY sensors in good condition and working order so as to ensure the proper gathering of data from such OPTIFY sensors; and (xi) not disclose to any unauthorized person non-public information, specifications or data which are designated at the time of disclosure as confidential or are recognizable as being of a confidential nature. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>4.2</span> The provision of the Platform requires and is based on the collection and processing of certain Customer Data and may require the establishment of a remote connection between the Platform on one side and certain systems (including mobile devices) and the OPTIFY sensors on the other side. You shall (i) establish and maintain such remote connection; (ii) upon Your request, permit Dodge, its employees, agents, consultants and/or subcontractors to remotely access certain systems (including mobile devices) owned, controlled or operated by or on Your behalf as well as the OPTIFY sensors, as necessary for Dodge to provide the Platform; (iii) maintain adequate security protection on the remote connection as well as Your systems, devices and hardware that directly or indirectly connect to the Software and the Platform; and (iv) install and maintain any hardware, software, or other equipment necessary to establish and maintain the remote connection. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>4.3</span> You agree to comply, and shall ensure that all Users comply, with the terms and conditions of the Acceptable Use Policy. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>4.4</span> If Dodge's performance of any of its obligations under this Agreement is prevented or delayed by any act or omission by You, including breach of the Agreement, or failure by You to perform any relevant obligation ("Customer Default") Dodge shall without limiting its other rights or remedies have the right to suspend performance of the Platform, your connection to the Platform until You remedy the Customer Default. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>4.5</span> You are obligated to inform Dodge about any planning penetration tests, audits or red teaming operations that You or any third party hired by You would perform and that would or may include the Platform. Such information should be delivered to Dodge not later than two (2) weeks before any planned run of any such activity.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
           5. Proprietary rights, use of data and restrictions   
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>5.1</span> As between the parties, all right, title and interest, including all Intellectual Property Rights, in and to Customer Data are and remain with You and Your licensors. Dodge acquires no rights in Customer Data, other than the rights You grant to Dodge under this Agreement. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>5.2</span> Dodge and its Affiliates have the right to collect, monitor, store, use, extract, compile, synthesize, aggregate, analyze or otherwise process Customer Data  for (i) providing, maintaining, protecting and improving the Platform and/or Software to You, Your Affiliates and/or (to the extent agreed in a separate agreement or otherwise authorized by You) third parties; (ii) preventing, detecting and repairing problems related to the security and/or the operation of the Software, the OPTIFY sensors, and the Platform; (iii) improving and developing existing services, technologies and products and developing new services, technologies and products, where all such improvements and developments (including all resulting Intellectual Property Rights) are exclusively owned by Dodge; and (iv) making You an offer to purchase products or services through a Dodge authorized distributor. In addition, Dodge has the right to use for marketing, testing and benchmarking purposes anonymized Customer Data.  
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>5.3</span> During the course of Your use of the Platform, You may provide Feedback to Dodge. Dodge shall exclusively own all rights, title and interest, including all Intellectual Property Rights, in this Feedback. In the event that any Intellectual Property Rights in Feedback is deemed for any reason not to be exclusively owned by Dodge, You agree to assign, transfer and convey to Dodge, and hereby assign, transfer and convey to Dodge, all right, title and interest, including Intellectual Property Rights, in such Feedback, and agree to provide reasonable cooperation to Dodge, at Dodge's expense, to perfect such rights. While Dodge does not wish to receive Your preexisting Intellectual Property Rights in such Feedback, in the unlikely event that You do include your Intellectual Property Rights in Feedback, You grant Dodge and its Affiliates a worldwide, irrevocable, non-exclusive, royalty free, fully-paid-up right and license, without accounting, to take or have taken any of the following actions: (i) to use, make, have made, make available, execute, copy, merge, reproduce, prepare derivative works, improvements and other modifications, license, sublicense, market, distribute (internally and externally), display and perform all, or any portion of the Feedback, alone or in any combination; and (ii) to authorize others to do any of the foregoing. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>5.4</span> During the term of this Agreement as set out in the Proof of Entitlement, You may have the ability to access and export certain Customer Data stored on the Platform. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>5.5</span> As between the parties, all right, title and interest, including all Intellectual Property Rights, in and to the Software, the OPTIFY sensors technology, the Platform as well as Dodge Device Data (including all tools, software, hardware, materials, data, content, application program interfaces provided by Dodge or its Affiliates as part of or in relation to the Platform) or other Dodge intellectual property (collectively and for the purposes of Sections 5.5 and 5.6 referred to as <span style={{fontWeight:"bold"}}>“Dodge Content”</span>) are and remain exclusively with Dodge or its licensors. You shall have no rights in and to the Dodge Content, other than those expressly granted pursuant to this Agreement. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>5.6</span> You will not (i) use the Dodge Content for any third-party use including license, sublicense, sell, resell, lease, transfer, assign, distribute, disclose, or otherwise commercially exploit or make it, or any portion thereof, available to any third party in any manner; (ii) modify, tamper with, repair or make derivative works based upon the Dodge Content; (iii) copy, reproduce, publish, reverse engineer, attempt to derive the source code of, modify, disassemble, decompile or create derivative works of the Dodge Content (except to the extent that applicable Laws prohibit reverse engineering restrictions, and then only as permitted by such Laws); (iv) copy any ideas, features, functions or graphics of the Dodge Content; (v) access or use the Dodge Content in a way to avoid incurring fees or exceeding usage limits or quotas or to circumvent or render inoperative any usage restriction features contained in Dodge Content; and/or (vi) remove, obscure, alter, or move Dodge's and its licensors’ proprietary notices. Use of the Dodge Content other than as specifically permitted in this Agreement, is expressly prohibited.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
           6. Customer Warranty and Indemnification
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>6.1</span> You represent and warrant that the use by Dodge of any Customer Data or other data provided by You or Your grant of any license or right under this Agreement, will not infringe the Intellectual Property Rights or other rights of any person.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>6.2</span> You shall indemnify and hold Dodge harmless from and against all costs, claims, demands, liabilities, expenses, damages or losses arising out of or in connection with (i) any alleged or actual infringement under any Laws, of any third party's Intellectual Property Rights or other rights arising out of any Customer Data or its use by Dodge in accordance with this Agreement, (ii) any breach by You of the Applicable Use Policy, or (iii) any other breach by You of this Agreement.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
          7. Disclaimer
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE PLATFORM IS AT YOUR SOLE RISK. DESPITE SECTION 1.1, DODGE PROVIDES YOU THE SERVICES AND SOFTWARE “AS IS” AND “AS AVAILABLE”, WITH ALL FAULTS AND DEFECTS, WITHOUT WARRANTY AND WITHOUT MAINTENANCE OR ANY SUPPORT SERVICES AND SOLELY FOR THE PURPOSE CONTEMPLATED IN THIS AGREEMENT. DODGE MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, AND DODGE DISCLAIMS ALL WARRANTIES AND REPRESENTATIONS WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THE SERVICES AND SOFTWARE INCLUDING, WITHOUT LIMITATION, ANY WARRANTY THAT THE SERVICES OR SOFTWARE WILL BE SECURE, UNINTERRUPTED, AVAILABLE, ERROR FREE OR FREE OF HARMFUL COMPONENTS, OR THAT THE REPORTS PROVIDED ARE ACCURATE, OR ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
          8. Limitation of liability
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         NOTWITHSTANDING ANYTHING TO THE CONTRARY AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL DODGE BE LIABLE, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), FOR BREACH OF STATUTORY DUTY, OR OTHERWISE, ARISING UNDER OR IN CONNECTION WITH A CONTRACT, AND EVEN IF DODGE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, FOR (I) LOSS OF PROFITS, SALES OR BUSINESS, AGREEMENTS OR CONTRACTS, ANTICIPATED SAVINGS, REVENUE, OR DAMAGE TO GOODWILL; (II) BUSINESS INTERRUPTION OR LOSS OR CORRUPTION OF DATA; (III) COSTS OF SUBSTITUTE GOODS OR SERVICES; (IV) ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE DAMAGES OR EXEMPLARY LOSS; AND/OR (V) OTHER LOSS OR DAMAGES INCLUDING DIRECT DAMAGES.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
          9.Suspension
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         Dodge may suspend the Platform and/or Your connection to the Platform in whole or in part if Dodge determines that Your use of the Platform and/or connection to the Platform poses a security risk to the Platform and/or any third party; (ii) may adversely impact the performance of the Software, or the Platform; (iii) is in violation of the Laws or poses a risk that Dodge is or will be in violation of the Laws; or (iv) may subject Dodge or any third party to liability. In addition, Dodge may suspend the Platform and/or Your connection to the Platform under the circumstances specified in Section 4 of the Acceptable Use Policy.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
          10. Termination
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>10.1</span> This Agreement will enter into effect as described in the introduction above and will remain in effect unless terminated in accordance with Section 10.2 below.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>10.2</span> Without limiting its other rights or remedies, Dodge may terminate this Agreement (in whole or in part) with immediate effect by giving written notice to You if (i) You are in breach of this Agreement; (ii) there is a change in the Laws in one or more countries applicable to the performance of the Platform that would render the continued performance of the Platform illegal, impractical or would otherwise have a material impact (including a cost impact) on the provision of the Platform; or (iii) Dodge elects to discontinue the Platform.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>10.3</span> Upon termination or expiration of this Agreement for any reason: (i) You shall uninstall all Software from Your computer systems, and cease use of the Software and, if requested by Dodge, provide Dodge with evidence that You have done so; (ii) Dodge may disconnect Your access to the Platform, Software as well as connection to the Platform on or after the effective date of termination or expiration; and (iii) the accrued rights, remedies, obligations and liabilities of the parties as at termination or expiration shall be un-affected, including the right to claim damages in respect of any breach of the Agreement which existed at or before the date of termination or expiration. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>10.4</span> You are responsible to export Customer Data prior to the effective date of termination or expiration as instructed by Dodge.  After expiration of such period Dodge may delete the Customer Data.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>10.5</span> Where You require assistance to retrieve the Customer Data, Dodge may agree to provide You with additional assistance which may be charged to You on a time and materials basis at Dodge's standard rates as in force at the time the assistance is to be provided or on such other rates as agreed between the parties.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>10.6</span> The provisions on the limitation of liability (Section 8), Customer warranty and indemnification (Section 6), the limitations on Customer use in the Software license (Sections 2.2, 5.6), the provisions on Customer’s Intellectual Property Rights (Section 5.1) and the provisions on Dodge's Intellectual Property Rights (in particular Sections 5.2, 5.3, 5.5) shall survive the termination of this Agreement.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
          11. Export control
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         You shall not export, directly or indirectly, any technical data acquired from <span style={{fontWeight:"bold"}}>Dodge</span> under this Agreement (or any products, including software, incorporating any such data) in breach of any applicable export control laws, including United States export laws, to any country for which the government or any agency thereof at the time of export requires an export license or other governmental approval without first obtaining such license or approval. In particular, the Platform and/or Software may not be exported (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person’s List or Entity List. By using the Platform, You represent and warrant that You are not located in any such country or on any such list. You also agree that You will not use the Platform for any purposes prohibited by any Laws, including, without limitation, the development, design, manufacture or production of nuclear missiles, or chemical or biological weapons.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
          12.Governing Law and Jurisdiction
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
            <span style={{fontWeight:"bold"}}>12.1</span> This Agreement, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by, and construed in accordance with, the substantive laws of the State of New York without regard to Its principles regarding conflicts of law.  The parties agree that the United Nations Convention on Contracts for the International Sale of Goods (Vienna, 1980) does not apply to this Agreement.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
            <span style={{fontWeight:"bold"}}>12.2</span> Any dispute, controversy or claim arising out of, or in relation to, the Agreement, including the validity, invalidity, breach, or termination thereof, shall be brought and maintained exclusively in the state or federal courts located in the State of New York and each of the parties irrevocably submits to the jurisdiction of the state and federal courts located in the State of New York for the purpose of any such proceeding.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
           13. General Provisions
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>13.1</span> <span style={{textDecoration:"underline"}}>Force majeure.</span> Dodge shall not be in breach of the Agreement nor liable for delay in performing, or failure to perform, any of its obligations under the Agreement if such delay or failure result from events, circumstances or causes beyond its reasonable control, including: (i) acts of God, flood, drought, earthquake or other natural disaster; (ii) epidemic or pandemic; (iii) terrorist attack, civil war, cyber-attacks, civil commotion or riots, war, threat of or preparation for war, armed conflict, imposition of sanctions, embargo, or breaking off of diplomatic relations; (iv) nuclear, chemical or biological contamination or sonic boom; (v) any Laws or any action taken by a government or public authority, including without limitation imposing an export or import restriction, quota or prohibition, or failing to grant a necessary license or consent; collapse of buildings, fire or explosion; and (vi) any labor or trade dispute, strikes, industrial action or lockouts.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
           <span style={{fontWeight:"bold"}}>13.2</span> <span style={{textDecoration:"underline"}}>Assignment and other dealings.</span> Dodge may at any time assign, transfer, mortgage, charge or deal in any other manner with all or any of its rights under the Agreement. You shall not, without the prior written consent of Dodge, assign, transfer, mortgage, charge, subcontract, declare a trust over or deal in any other manner with any or all of its rights or obligations under the Agreement.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         <span style={{fontWeight:"bold"}}>13.3</span> <span style={{textDecoration:"underline"}}>Business Contact Information. </span> Subject to them complying with the Dodge Data Privacy Policy and Cookie Policy and privacy laws applicable to Dodge, Dodge and its Affiliates, and their subcontractors (including those who process data on their behalf), may, wherever they do business, store and otherwise process business contact information (for example name, business telephone, address, email and user IDs) of You, Your personnel and Users for the purpose of business dealings with them.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         <span style={{fontWeight:"bold"}}>13.4</span> <span style={{textDecoration:"underline"}}>Data Protection.</span> The parties agree that You shall be the data controller, who shall ensure compliance with the applicable data protection laws, in particular the lawfulness of the processing of personal data. Dodge shall be processing personal data on Your behalf in accordance with the Dodge Data Privacy Policy and Cookie Policy and shall ensure compliance only with regard to those obligations under applicable data protection laws that are specifically directed to processors and shall act according to Your lawful instructions. Dodge's personnel engaged in the Processing of Personal Data are informed of the confidential nature of the Personal Data, have received appropriate training on their responsibilities and have executed written confidentiality agreements. Dodge may, and You agree that You will not withhold or delay Your consent if needed, to any changes to this data protection clause and/or to any additional data processing or data protection agreements which in the reasonable opinion of Dodge are required to be made in order to comply with applicable data protection laws and regulations and/or with guidelines from any competent supervisory authority, and their application to the services provided by Dodge from time to time.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         <span style={{fontWeight:"bold"}}>13.5</span> <span style={{textDecoration:"underline"}}>Subcontracting.</span> Subject to mandatory applicable Laws, Dodge shall be permitted to subcontract or delegate in any manner any or all of the performance of its obligations under the Agreement to any Affiliate, contractor or any other third party service provider without requiring Your prior written consent.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         <span style={{fontWeight:"bold"}}>13.6</span> <span style={{textDecoration:"underline"}}>Updates.</span> Dodge reserves the right, at any time and from time to time, to update, revise, supplement, and otherwise modify this Agreement. At the time of login, You will be prompted of these changes and asked  to accept the revised agreement.  Your acceptance of the revised agreement and continued use of the Platform will be deemed to constitute Your acceptance of such updates, revisions, supplements and other modifications. If you do not agree to any such updates, revisions, supplements and other modifications, please discontinue using the Platform and uninstall the Software.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         <span style={{fontWeight:"bold"}}>13.7</span> <span style={{textDecoration:"underline"}}>Severance.</span> . If any provision or part-provision of this Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this Section shall not affect the validity and enforceability of the rest of this Agreement.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         <span style={{fontWeight:"bold"}}>13.8</span> The Software and any accompanying documentation has been developed at private expense and are deemed to be a “commercial item,” as that term is defined in 48 C.F.R. 2.101, consisting of “commercial computer software” and “commercial computer software documentation,” as such terms are used in 48 C.F.R. 12.212. Use, duplication, and disclosure by civilian agencies of the U.S. Government shall be in accordance with FAR 52.227-19(c) or other agency data rights provisions, as may be applicable. Use, duplication, and disclosure by DOD agencies are subject solely to the terms of this Agreement as stated in DFAR 227.7202. All U.S. Government users license the Software with only those rights set forth herein, including, without limitation, the following: Software may be transferred to the U.S. government only with the prior written consent of an officer of Dodge and solely as restricted computer software as provided in FAR 52.227-19 or subsequent citation (or DFAR 227-7202 or subsequent citation if the transfer is to a defense-related agency).
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         <span style={{fontWeight:"bold"}}>13.9</span> <span style={{textDecoration:"underline"}}>Notices.</span> Any notice given to a party under or in connection with this Agreement shall be in writing and shall be (i) delivered by hand or courier or by pre-paid registered first-class post or special delivery at its registered office (if a company) or its principal place of business (in any other case); or (ii) sent by email or other electronic notification forms available in the used systems (or other electronic notification forms) as well as the email address to which the notices must be delivered to.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={1}>
         <span style={{fontWeight:"bold"}}>13.10</span> <span style={{textDecoration:"underline"}}>Third-party beneficiaries.</span> No one other than a party to the Agreement shall be a beneficiary of the Agreement or shall have any right to enforce any of its terms, unless specified in the Agreement.
         </Typography>
          <Typography sx={{...subTitle, fontSize:fontSizeString}}>
          14. Definitions and interpretation
         </Typography>
         <Typography fontWeight="bold" mt={2} mb={2}>Definitions:</Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Acceptable Use Policy" </span>means the Dodge OPTIFY Platform Acceptable Use Policy, in the form attached as Exhibit A to this Agreement, as the same may be updated by Dodge from time to time.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Affiliate" </span>means any entity, whether incorporated or not, which presently or in the future, directly or indirectly controls, is controlled by, or is under common control with a party, by virtue of a controlling interest of 50% or more of the voting rights or the capital, or by means of controlling the constitution of the board and the voting at board meetings.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Customer Data" </span>means (i) data owned or controlled by You that is provided to Dodge or its Affiliates through or in connection with the use of the Platform, including any data collected by the OPTIFY sensor, and any data manually entered into the Platform by or on Your behalf; excluding Dodge Device Data.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Customer Default" </span>has the meaning set out in Section 4.4.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Dodge" </span>means Dodge Industrial, Inc. with a registered office at 1061 Holland Road, Simpsonville, SC 29681 USA.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Dodge Content" </span>has the meaning set forth in Section 5.5. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Dodge Data Privacy Policy and Cookie Policy" </span>means Dodge's privacy policy, and cookie policy available aton the Dodge Industrial website: <a href="https://dodgeindustrial.com"> https://dodgeindustrial.com </a>, as may be updated by Dodge from time to time. OPTIFY users have an additonal Privacy and Cookies notice unique to OPTIFY which they will be asked  to accept upon their Initial login to the Platform, and which will be updated by Dodge from time to time.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Dodge Device Data" </span>means  devices like: - sensors that deliver measurements from customer assets , gateway that delivers data from sensors to Dodge OPTIFY system in cloud.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Dodge OPTIFY" </span>is a trademark of Dodge which is a wholly-owned subsidiary of Roller Bearing Company of America, Inc. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Dodge OPTIFY Platform" or "Platform" </span>means Dodge's and its Affiliates industrial internet platform which includes both edge and cloud infrastructure upon or via which Dodge OPTIFY solutions (including all or part of the Services hereunder) operate.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"External Content" </span>has the meaning set forth in Section 1.5.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Feedback" </span>means, except for Customer Data or Your trademarks, all observing, evaluative or corrective information, statement, comment or observation about an incident, action, event, or process and other content or items prepared or otherwise provided by You to Dodge in relation to the Services, Software or OPTIFY sensors.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Intellectual Property Rights" </span>means (a) inventions, patents, utility models, copyrights, moral rights, mask work rights, database rights and rights in trademarks, trade names, designs, know-how, and invention disclosures (whether registered or unregistered); (b) applications for registration, and the right to apply for registration, for any of these rights; and (c) all other intellectual property rights and equivalent or similar forms of protection existing anywhere in the world.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Laws" </span>means any applicable legislation, regulations, codes of practice, guidance and other requirements of any relevant government, governmental or regulatory agency, authority, or other relevant body, as amended or re-enacted.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"OPTIFY Sensor" </span>means the Dodge OPTIFY Sensor purchased by or provided to You by Dodge, its Affiliates or a third party pursuant to one or more separate arrangements, which generates or gathers data through embedded sensors or otherwise, where such data is accessed, stored or processed by the Services hereunder.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Personal Data" </span>means any data or information of an identified or identifiable natural person.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Proof of Entitlement" </span>means the document that entitles You to use the Services. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Services" </span>means access to and use of the Platform and the Software, all as more particularly described in this Agreement, including, without limitation, the Proof of Entitlement and the Services Description. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Software" </span>means all computer programs (which may include mobile applications or cloud-based software) provided (or given access to) by Dodge under this Agreement as part of or in connection with the Services, including any modifications, updates, upgrades, new versions or releases and derivative works as well as any related documentation, but excluding Third Party Software.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"Third Party Software" </span>means any computer program (which may include mobile applications), including proprietary, freeware and open source software, that is licensed  by You from third parties.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"User" </span>means (i) an individual within Your organization or working in a separate legal entity or third party for You; or (ii) an individual within a third party’s organization who is legitimately authorized to receive and use the Services and use the Software.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={1}>
           <span style={{fontWeight:"bold"}}>"You" and "Your" </span>means the individual or legal entity being a party to this Agreement.
         </Typography>
         <Typography fontWeight="bold" mt={3} mb={2}>Interpretation</Typography>
          <Typography sx={{...body}} fontSize={fontSizeString} >
         Any phrase introduced by the terms "e.g.", "including", "include", "in particular", "such as" or any similar expression, shall be construed as illustrative and shall not introduce an exhaustive list of phrases nor limit the sense of the words preceding those terms.
         </Typography>
         <Typography color="primary" sx={{...subTitle, fontSize:fontExhibit, textAlign:"center", mb:"-2rem", mt:"4rem"}}>
         Exhibit A
         </Typography>
         <Typography color="primary" sx={{...subTitle, fontSize:fontExhibit, textAlign:"center"}}>
         DODGE ACCEPTABLE USE POLICY
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={2}>
         This Acceptable Use Policy (“AUP”) applies to your access to and the use of Dodge OPTIFY Platform (the "Platform"), and any other Dodge web portals and or any services that provide or include access to the internet or are provided over the internet or wireless data networks.  This applies to all users of, and visitors to, the Platform and the aforementioned portals and services between you and us that reference this AUP. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString} >
         The words ‘you’ or ‘your’ include individuals whether acting on their own behalf or on behalf of any other person or Company (to include any form of corporation, legal entity or undertaking). Where an individual is acting on behalf of anyone else or on behalf of a Company then this AUP shall apply to that other individual or Company as if that individual or Company acted on its own behalf. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}   mb={2}>
         Your use of the Platform means that you accept, and agree to abide by, all the terms and policies in this AUP, which supplement our service specific terms of use and the terms of the agreement between you and us that reference this AUP. 
          </Typography>
          <Typography sx={{...bigSubTitle, fontSize:fontBigSubtitle}}>
          1 Prohibited Uses 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString} >
         You shall use the Platform only for lawful purposes. You may not use, or facilitate or instruct others to use, the Platform: 
         </Typography>
          <ul style={{marginLeft:"2rem"}}>
            <li><Typography fontSize={fontSizeString}>In any way that breaches any applicable local, state, provincial, national, or international law or regulation.</Typography> </li>
            <li><Typography fontSize={fontSizeString}>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</Typography> </li>
            <li><Typography fontSize={fontSizeString}>For the purpose of harming or attempting to harm others, including minors, or our operations, in any way.</Typography> </li>
            <li><Typography fontSize={fontSizeString}>To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards set out below.</Typography> </li>
            <li><Typography fontSize={fontSizeString}>To transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam).</Typography> </li>
            <li><Typography fontSize={fontSizeString}>To transmit any data, send or upload any material that contains viruses, trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</Typography> </li>
            <li><Typography fontSize={fontSizeString}>In a way that could harm the Platform, any underlying infrastructure and/or Dodge provided services or impair other persons use of it.</Typography> </li>
            <li><Typography fontSize={fontSizeString}>In any application or situation where failure of the Platform could lead to the death or serious bodily injury of any person, or to severe physical or environmental damage.</Typography> </li>
            <li><Typography fontSize={fontSizeString}>In any way to monitor data or traffic on the Platform without our explicit permission.</Typography> </li>
            <li><Typography fontSize={fontSizeString}>Attempt to, access without authority, interfere with, damage, or disrupt, or to violate, or attempt to violate the security, accessibility or integrity, of the Platform of any of the following: 
                <ul style={{marginLeft:"2rem", listStyleType:"square"}}>
                   <li><Typography fontSize={fontSizeString}>In any equipment, infrastructure, or network on which the Platform is stored. </Typography> </li>
                   <li><Typography fontSize={fontSizeString}>any software used in the provision of the Platform. </Typography> </li>
                   <li><Typography fontSize={fontSizeString}>any equipment, infrastructure or network or software owned or used by any third party; or </Typography></li>
                   <li><Typography fontSize={fontSizeString}>the proper functioning of any of the above.</Typography> </li>
                </ul>
            </Typography> </li>
          </ul>
          <Typography sx={{...bigSubTitle, fontSize:fontBigSubtitle}}>
          2 Interactive Services  
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString} >
         We may from time to time provide interactive services on the Platform including, without limitation: 
         </Typography>
         <ul style={{marginLeft:"2rem"}}>
            <li><Typography fontSize={fontSizeString}>Chat rooms. </Typography>  </li>
            <li><Typography fontSize={fontSizeString}>
              Bulletin boards (<span style={{fontWeight:"bold"}}>"Interactive Services"</span>). 
            </Typography>  </li>
          </ul>
         <Typography sx={{...body}} fontSize={fontSizeString}  mb={2} mt={2}>
        Where we do provide any Interactive Service, we will inform you about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical). 
        </Typography>
         <Typography sx={{...body}} fontSize={fontSizeString}  mb={2}>
        In addition, we may oversee or monitor content of any Contributions (as defined below) and activity as necessary to comply with applicable laws, regulations or governmental or judicial requests; or to protect the Platform and our customers. However, we are under no obligation to oversee, monitor or moderate any Interactive Service we provide on the Platform, and we expressly exclude our liability for any loss or damage arising from the use of any Interactive Service by a user in contravention of our content standards, whether the service is moderated or not. 
Where we do moderate an interactive service, we will normally provide you with a means of contacting the moderator, should a concern or difficulty arise. 
        </Typography>
        <Typography sx={{...bigSubTitle, fontSize:fontBigSubtitle}}>
        3 Content Standards 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString} >
         These content standards apply to all material which you contribute to our site ("Contributions"). You must comply with the spirit and the letter of the following standards. The standards apply to each part of any Contribution as well as to its whole. 
Contributions must: 
         </Typography>
         <ul style={{marginLeft:"2rem"}}>
            <li><Typography fontSize={fontSizeString}>Be accurate (where they state facts). </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Be genuinely held (where they state opinions). </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Comply with applicable law in any country from which they are posted. </Typography> </li>
         </ul>
          <Typography sx={{...body}} fontSize={fontSizeString}  mt={2}>Contributions must not: </Typography>
         <ul style={{marginLeft:"2rem"}}>
            <li><Typography fontSize={fontSizeString}>Contain any material which is defamatory of any person or Company or other legal entity. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Contain any material which is obscene, offensive, hateful, or inflammatory. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Promote sexually explicit material. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Promote violence. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Infringe any intellectual property right of any other person. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Be likely to deceive any person. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Promote any illegal activity. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Be threatening, abuse, invade another’s privacy, or cause annoyance, inconvenience, or needless anxiety. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Be likely to harass, upset, embarrass, alarm, or annoy any other person. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Be used to impersonate any person, or to misrepresent your identity or affiliation with any person. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Give the impression that they emanate from us if this is not the case. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Advocate, promote or assist any unlawful act such as (by way of example only) intellectual property right infringement, violation of privacy rights or computer misuse. </Typography> </li>
         </ul>
         <Typography sx={{...bigSubTitle, fontSize:fontBigSubtitle}}>
         4 Suspension, Termination and Enforcement
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString} >
         We will determine, in our discretion, whether there has been a breach of this AUP through your use of the Platform, other Dodge web portals and or any services that provide or include access to the internet or are provided over the internet or wireless data networks.  When a breach of this AUP has occurred, we may take such action as we deem appropriate. 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mt={1}>
         Failure to comply with this AUP constitutes a material breach of the agreement upon which you are permitted to use the Platform that reference this AUP, and may result in our taking all or any of the following actions: 
         </Typography>
         <ul style={{marginLeft:"2rem"}}>
            <li><Typography fontSize={fontSizeString}>Immediate, temporary, or permanent withdrawal of your right to use the Platform and/or any digital services. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Immediate, temporary, or permanent suspension of your access to the Platform. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Immediate, temporary, or permanent modification or removal of any Contribution, posting or material uploaded by you which was acknowledged as illegal or not compliant with our policy. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Issue of a warning to you. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Further legal action against you. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Investigation of the breach, including in co-operation with third parties. </Typography> </li>
            <li><Typography fontSize={fontSizeString}>Reporting and disclosure of any unlawful or fraudulent activity to law enforcement authorities as we reasonably feel is necessary. Such reporting and disclosure may include information about you and your use of the Platform. </Typography> </li>
         </ul>
          <Typography sx={{...body}} fontSize={fontSizeString}  mt={2}>
         We, in our reasonable discretion, may provide you with a notice of an AUP breach via e-mail or otherwise allowing you to promptly correct such breach. However, we may suspend your access to the Platform immediately and without notice in response to a court order, a government notice that certain conduct must be stopped or when we reasonably determine, that:
         </Typography>
         <ul style={{marginLeft:"2rem"}}>
            <li><Typography fontSize={fontSizeString}>your conduct may violate any applicable law or regulation; </Typography> </li>
            <li><Typography fontSize={fontSizeString}>exposes us to any legal action, sanctions, or liability; or </Typography> </li>
            <li><Typography fontSize={fontSizeString}>otherwise causes, or creates an immediate risk to cause, harm to us, the Platform, the underlying infrastructure and its operation, the digital services or to our customers. </Typography> </li>
         </ul>
          <Typography sx={{...body}} fontSize={fontSizeString}  mt={2}>
         We exclude liability for actions taken in response to breaches of this AUP. The responses described in this AUP are not limited, and we may take any other action we reasonably deem appropriate. 
         </Typography>
         <Typography sx={{...bigSubTitle, fontSize:fontBigSubtitle}}>
         5 Incident reporting 
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString}  mb={2}>
         Should you become aware of any act or omission which contravenes this AUP (whether committed by you or by someone on your behalf) you shall immediately notify Dodge using the contact information available on the Platform providing details as are reasonably required to permit us to evaluate the nature of the contravention and to take any action which we determine necessary.
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString} >
         If information in the incident reported is sensitive in nature you shall report to Dodge by applying suitable data protection mechanisms, including but not limited to data encryption and authentication. as also information requested additively by us. 
         </Typography>
         <Typography sx={{...bigSubTitle, fontSize:fontBigSubtitle}}>
         6 Changes to the AUP  
         </Typography>
          <Typography sx={{...body}} fontSize={fontSizeString} >
         We may revise this AUP at any time. When changes are made you will be notified during login to the  Platform so you can accept the change and continue using the Platform. Your further access to or use of the Platform after you are notified of the changes to the AUP or these are posted shall constitute acceptance of any changed or additional terms. 
         </Typography>
        </CookiePageContainer>
    );
}

