import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAuthMutation } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface SelectedPlantsParams {
  plantId?: number;
  assetId?: number;
}

export const usePutSelectedPlant = () => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_change_selected_plant_settings_toast'),
    };
  }, []);
  return useAuthMutation<SelectedPlantsParams, {}>({
    url: ({ plantId, assetId }: SelectedPlantsParams) =>
      `users/selected-plant` + (plantId ? `?plantId=${plantId}` : `?assetId=${assetId}`),
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('roles') });
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
