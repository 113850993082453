import { SinglePeak } from 'features/assets-management/models/FFTChart.models';
import { useAuthQueries } from './../queries';

type Measurement = {
  time: number;
  value: number;
};

export interface FFTDataAPI {
  vibrationRawData: Measurement[];
  fftData: Measurement[];
  peaks: SinglePeak[] | null;
  xLabelName: string | null;
  yLabelName: string | null;
  bearingPartsCage: { begin: number; end: number }[] | null;
  bearingPartsInnerRace: { begin: number; end: number }[] | null;
  bearingPartsOuterRace: { begin: number; end: number }[] | null;
  bearingPartsRoller: { begin: number; end: number }[] | null;
  nominalSpeedFrequencies: { begin: number; end: number }[] | null;
  nominalSpeed: number;
  nominalSpeedFrequency: number;
  runningSpeed: number;
  samplingFrequency: number;
  unitAfterScaling: number;
}

type Props = {
  assetId: number;
  sensorId: number;
  dateTime: string[];
  freqUnit: string;
  velocityUnit: string;
  FFTType: string;
  axis: string;
  bearingParts?: string;
  noOfHarmonics?: number;
  marginRange?: number;
  speed?: number;
  peakFactor?: number;
  peakSamples?: number;
  windowing?: boolean;
  filterBins?: number;
  assetType?: string;
  dePN?: string;
  ndePN?: string;
  faultDetection?: boolean | string;
  peakDetection?: boolean;
};

export const useGetMultipleFFT = (props: Props) => {
  const { assetId, dateTime } = props;

  const parsedProps = Object.fromEntries(
    Object.entries(props).map(([key, value]) => [key, String(value)]),
  );

  const paramsArr = dateTime.map((date) => ({
    ...parsedProps,
    dateTime: date,
  }));

  const urlBase = `assets/${assetId}/fft`;

  return useAuthQueries<FFTDataAPI>({
    queries: paramsArr.map((params) => ({
      url: urlBase,
      params,
    })),
  });
};
