import { Box, IconButton } from "@mui/material";
import { FC, memo } from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

type SliderProps = {
    slideLeft: () => void;
    slideRight: () => void;
}
const Slider:FC<SliderProps> = ({slideLeft, slideRight}) =>{
    return (
     <Box
       position="absolute"
       zIndex="1000"
       display="flex"
       justifyContent="space-between"
       width="100%"
     >
        <IconButton color="primary" onClick={slideLeft} >
          <KeyboardArrowLeftIcon sx={{fontSize:"3rem"}} />
        </IconButton>
        <IconButton color="primary" onClick={slideRight} >
          <KeyboardArrowRightIcon sx={{fontSize:"3rem"}} />
        </IconButton>
     </Box>
    );
}

export default memo(Slider)