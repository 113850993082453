import { useTranslation } from 'languages';

export const useGetTranslatedAssetLabel = () => {
  const { translate } = useTranslation();
  const translationDictionary = {
    bearing: translate('asset_type_bearing'),
    'gear reducer': translate('asset_type_gear_reducer'),
    motor: translate('asset_type_motor'),
    pump: translate('asset_type_pump'),
    other: translate('asset_type_other'),
  };

  const getTranslatedAssetLabel = (type: string) => {
    const typeKey = type.toLowerCase().replace('-', ' ');
    if (type && typeKey in translationDictionary) {
      return translationDictionary[typeKey as keyof typeof translationDictionary];
    }
    return type;
  };

  return getTranslatedAssetLabel;
};
