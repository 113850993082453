import { Delete } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useDeleteApiKey } from 'api/users/useDeleteApiKey';
import { GetApiKeysResponse } from 'api/users/useGetApiKeys';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { useTranslation } from 'languages';
import { memo, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

type Props = {
  keys: GetApiKeysResponse[] | undefined | [];
  isLoading: boolean;
  onCreateClick: () => void;
  afterDelete?: () => void;
};

const ListView = (props: Props) => {
  const dateFormatter = useDateFormatter();
  const [idToDelete, setIdToDelete] = useState<string>('');

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      apiDescription: translate('menu_api_description'),
      addNewKeyButton: translate('menu_api_add_button'),
      nameLabel: translate('menu_api_name_label'),
      timestampLabel: translate('menu_api_timestamp_label'),
      actionLabel: translate('menu_api_action_label'),
      successToast: translate('removed_api_key_successfully_toast'),
      errorToast: translate('unable_to_remove_api_key_toast'),
    };
  }, []);
  // Delete api key
  const { mutate: deleteApiKey, isPending } = useDeleteApiKey(idToDelete);

  // Remove api key when id is changed
  useEffect(() => {
    if (idToDelete) {
      deleteApiKey(
        {},
        {
          onSuccess: () => {
            props.afterDelete?.();
            toast.success(componentText.successToast);
          },
          onError: () => {
            toast.error(componentText.errorToast);
          },
        },
      );
      setIdToDelete('');
    }
  }, [idToDelete]);

  const handleDeleteApiKey = (id: string) => {
    setIdToDelete(id);
  };

  return (
    <>
      <Grid container>
        <Grid item>
          <Typography variant='body1'>{componentText.apiDescription}</Typography>
        </Grid>
        {!props.isLoading && props.keys && props.keys.length > 0 && (
          <Grid item container mt={3}>
            <Grid item xs={4}>
              <Typography fontWeight={700}>{componentText.nameLabel}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item>
                <Typography fontWeight={700}>{componentText.timestampLabel}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item>
                <Typography fontWeight={700}>{componentText.actionLabel}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!props.isLoading &&
          props.keys?.map((apiKey, index) => {
            return (
              <Grid item container alignItems={'center'} mt={3} key={index}>
                <Grid data-testid='manage_account_api_keys_name_label' item xs={4}>
                  <Typography fontWeight={400}>{apiKey.name}</Typography>
                </Grid>
                <Grid data-testid='manage_account_api_keys_timestamp_date' item xs={4}>
                  <Grid item>
                    <Typography fontWeight={400}>
                      {dateFormatter(apiKey.timestamp, 'MM/DD/YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  {isPending ? (
                    <CircularProgress size={16} />
                  ) : (
                    <IconButton
                      onClick={() => {
                        handleDeleteApiKey(apiKey.id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}
        <Grid item my={3}>
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={props.onCreateClick}
            data-testid='new_api_add_button'
          >
            {componentText.addNewKeyButton}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(ListView);
