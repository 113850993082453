import React, { FC, ReactNode, useMemo } from 'react';
import { Typography, TextField, Divider, Grid, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useJoinPlant } from 'api/plants';
import { LoadingButton } from '@mui/lab';
import DodgeCard from 'components/DodgeCard/DodgeCard';
import RequestSubmitted from '../RequestSubmitted/RequestSubmitted';
import { useValidatePlantId } from 'api/plants/useValidatePlantId';
import { useDeviceSize } from 'shared/responsive';
import { useTranslation } from 'languages';
import CloseIcon from '@mui/icons-material/Close';

type PanelType = 'panel1' | 'panel2' | 'panel3';

interface JoinPlantProps {
  inDashboard?: boolean;
  onClose?: () => void;
}

const JoinPlant: FC<JoinPlantProps> = ({ inDashboard, onClose }) => {
  const [open, setOpen] = React.useState<boolean>(true);
  const [panel, setPanel] = React.useState<PanelType>('panel1');
  const [plantId, setPlantId] = React.useState<number>(0);
  const [validPlantId, setValidPlantId] = React.useState<boolean>(true);

  const { mutate: joinPlantMutation, isPending } = useJoinPlant(() => {
    setPanel('panel3');
  });

  // Validate plant id mutation
  const {
    mutate: validatePlantIdMutation,
    isPending: validationLoading,
    data: validateResponse,
  } = useValidatePlantId();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const plantId = event.target.value;
    setPlantId(Number(plantId));
  };

  const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const sendJoinPlantRequest = () => {
    joinPlantMutation({ body: { plantId: plantId } });
  };

  const validatePlantId = () => {
    validatePlantIdMutation(
      {
        params: {
          plantId,
        },
      },
      {
        onSuccess(data, variables, context) {
          setValidPlantId(true);
          setPanel('panel2');
        },
        onError(error, variables, context) {
          setValidPlantId(false);
        },
      },
    );
  };

  const handleClose = () => {
    onClose?.();
    setOpen(false);
  };

  const { isPhone, isTablet } = useDeviceSize();

  const responsiveProps = {
    modalWidth: isTablet || isPhone ? '22rem' : '30rem',
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      joinPlantText: translate('onboarding_join_plant_label'),
      joinPlantDescription: translate('onboarding_join_plant_description'),
      questionLabel: translate('onboarding_join_plant_question'),
      backButton: translate('menu_back_button_label'),
      nextButton: translate('modal_next_button'),
      requestToJoin: translate('onboarding_join_plant_request'),
      areUsure: translate('onboarding_join_plant_are_you_sure'),
      plantID: translate('onboarding_join_plant_plantid', { plantID: plantId }),
      plantIDLabel: translate('menu_table_plantid_label'),
    };
  }, [plantId]);

  const PanelHeader = (props: { children: ReactNode }) => {
    return (
      <Box width='100%' display='flex' justifyContent='space-between' alignItems='center'>
        {props.children}
        <CloseIcon
          sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
          onClick={() => setOpen(false)}
        />
      </Box>
    );
  };

  if (open)
    return (
      <DodgeCard width={panel === 'panel3' ? '70rem' : responsiveProps.modalWidth}>
        {
          {
            panel1: (
              <>
                <PanelHeader>
                  <Typography
                    variant='h5'
                    color='primary'
                    data-testid='plants_management_join_plant_modal_title'
                    fontWeight='600'
                  >
                    {componentText.joinPlantText}
                  </Typography>
                </PanelHeader>
                <Typography
                  variant='body1'
                  my={2}
                  data-testid='plants_management_join_plant_modal_subtitle'
                  color='rgba(0, 0, 0, 0.87)'
                >
                  {componentText.joinPlantDescription}
                </Typography>
                <TextField
                  value={plantId === 0 ? undefined : plantId}
                  fullWidth
                  data-testid='plants_management_join_plant_modal_textfield'
                  onChange={onChange}
                  label={componentText.plantIDLabel}
                  helperText={
                    validPlantId === false
                      ? 'We didn’t find a plant ID. Please try again.'
                      : undefined
                  }
                  type='number'
                  required
                  onKeyDown={handleKeyboardEvent}
                  error={!validPlantId}
                />
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ color: 'rgba(11, 121, 208, 1)' }}
                  href='https://iiot-dodgeindustrial.swipeguide.com/guide/optify-user-guide?redirect_referrer=http%3A%2F%2Fiiot-dodgeindustrial.swipeguide.com%2Fshare%2Fguide%2Ffba83e23-b487-419a-b5f0-f0567ff8a350%2Ff3370919-4e85-46a5-91cc-54c8e7f49555'
                >
                  <Typography variant='body1' sx={{ fontWeight: 600, fontSize: '0.875rem', mt: 3 }}>
                    {componentText.questionLabel}
                  </Typography>
                </a>
                <Divider orientation='horizontal' sx={{ my: 3 }} />
                <Grid container justifyContent='space-between' sx={{ mt: 3 }}>
                  <Grid item>
                    {!inDashboard && (
                      <Button
                        component={Link}
                        data-testid='plants_management_join_plant_modal_back_router_button'
                        to='/onboarding/welcome'
                        variant='outlined'
                        color='secondary'
                        startIcon={<ArrowBack />}
                        sx={{
                          fontWeight: 600,
                          color: 'black',
                        }}
                      >
                        {componentText.backButton}
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      variant='contained'
                      data-testid='plants_management_join_plant_modal_next_button'
                      color='secondary'
                      disabled={plantId === 0}
                      endIcon={<ArrowForward />}
                      sx={{ textTransform: 'uppercase', fontWeight: 600 }}
                      onClick={validatePlantId}
                      loading={validationLoading}
                    >
                      {componentText.nextButton}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </>
            ),
            panel2: (
              <>
                <PanelHeader>
                  <Typography
                    variant='h5'
                    color='primary.dark'
                    data-testid='plants_management_join_plant_modal_title'
                    sx={{ fontWeight: 600 }}
                  >
                    {componentText.joinPlantText}
                  </Typography>
                </PanelHeader>
                <Typography
                  data-testid='plants_management_join_plant_modal_confirm_message'
                  variant='body1'
                  marginY={3}
                  color='rgba(0, 0, 0, 0.87)'
                >
                  {componentText.areUsure}
                  <b> {componentText.plantID} </b>?
                </Typography>
                <Divider orientation='horizontal' sx={{ my: 3 }} />
                <Grid container justifyContent='space-between' spacing={2}>
                  <Grid item xs={4}>
                    <Button
                      variant='outlined'
                      fullWidth
                      data-testid='plants_management_join_plant_modal_request_join_back_button'
                      color='secondary'
                      startIcon={<ArrowBack />}
                      disabled={isPending}
                      sx={{
                        fontWeight: 600,
                        color: 'black',
                      }}
                      onClick={() => setPanel('panel1')}
                    >
                      {componentText.backButton}
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <LoadingButton
                      variant='contained'
                      fullWidth
                      color='secondary'
                      loading={isPending}
                      onClick={sendJoinPlantRequest}
                      data-testid='plants_management_join_plant_modal_request_join_button'
                      sx={{
                        fontWeight: 600,
                        color: 'black',
                      }}
                    >
                      {componentText.requestToJoin}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </>
            ),
            panel3: <RequestSubmitted inDashboard={inDashboard} onClose={handleClose} />,
          }[panel]
        }
      </DodgeCard>
    );
  return null;
};
export default JoinPlant;
