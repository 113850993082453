import { DropdownPlant } from 'models/plant/dropdownPlant.model';
import { UserRoleInfo } from 'models/user-role.model';

export const mapToAcceptedDropdownPlant = (plantList: UserRoleInfo[]) => {
  return plantList
    .filter(
      (x: any) =>
        x.plantName !== null && x.plantId !== 0 && x.invitationStatus.toLowerCase() === 'accepted',
    )
    .map(
      (x) => ({ plantId: x.plantId, plantName: x.plantName, visible: x.visible }) as DropdownPlant,
    );
};
