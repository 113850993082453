import { useTranslation } from 'languages';

const days = {
  MON: 'monday',
  TUE: 'tuesday',
  WED: 'wednesday',
  THU: 'thursday',
  FRI: 'friday',
  SAT: 'saturday',
  SUN: 'sunday',
};
export const useGetTranslatedDay = () => {
  const { translate } = useTranslation();
  const translationDictionary = {
    monday: translate('monday_chp'),
    tuesday: translate('tuesday_chp'),
    wednesday: translate('wednesday_chp'),
    thursday: translate('thursday_chp'),
    friday: translate('friday_chp'),
    saturday: translate('saturday_chp'),
    sunday: translate('sunday_chp'),
  };

  const getTranslatedDay = (day: string) => {
    const dayKey = days[day as keyof typeof days] || day.toLowerCase();
    if (day && dayKey in translationDictionary) {
      return translationDictionary[dayKey as keyof typeof translationDictionary];
    }
    return day;
  };

  return getTranslatedDay;
};
