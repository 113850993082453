import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

export interface CustomPartDetails {
  number: string;
  assetType: string;
  description: string;
  properties: {
    [key: string]: {
      label: string;
      type: string;
      unit: string;
      value: string | number;
    };
  };
}

export const getCustomPartDetailsUrl = (plantId: number, partId: number) =>
  `plants/${plantId}/part/${partId}`;

export const useGetCustomPartDetails = (partId: number) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_custom_parts_toast'),
    };
  }, []);
  const plantId = useSelector(selectPlantId);

  return useAuthQuery<CustomPartDetails>({
    url: `plants/${plantId}/part/${partId}`,
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
