import React, { useEffect, useMemo, useState } from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import PopperDateRange from './PopperDateRange';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEndDate,
  selectLastXdaysSelected,
  selectRefreshDateRangeDisplay,
  selectStartDate,
  setActiveEndDate,
  setActiveStartDate,
  setLastXdaysSelected,
  setRefreshDateRangeDisplay,
} from 'features/assets-management/store/assetsManagementSlice';
import EventIcon from '@mui/icons-material/Event';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { useTranslation } from 'languages';

type DataRangeProps = {
  isFromKPITrend?: boolean;
  background?: string;
};

const DateRange = ({ isFromKPITrend, background }: DataRangeProps) => {
  const dateFormatter = useDateFormatter();
  const dispatch = useDispatch();

  const start = useSelector(selectStartDate);
  const end = useSelector(selectEndDate);
  const lastXDaysSelected = useSelector(selectLastXdaysSelected);
  const refreshDateRangeDisplay = useSelector(selectRefreshDateRangeDisplay);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date(start),
    lastXDaysSelected ? new Date() : new Date(end),
  ]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [placement, setPlacement] = React.useState('');

  const handleSetRange = (dateRange: [Date | null, Date | null]) => {
    dispatch(setLastXdaysSelected(false));
    dateRange[1]?.setHours(23, 59, 59, 999);
    setDateRange(dateRange);
  };

  useEffect(() => {
    const [start, end] = dateRange;
    if (start && end) {
      dispatch(setActiveStartDate(start.toISOString()));
      dispatch(setActiveEndDate(end.toISOString()));
      setAnchorEl(null);
    }
  }, [dateRange]);

  useEffect(() => {
    if (!refreshDateRangeDisplay) return;
    setDateRange([new Date(start), new Date(end)]);
    dispatch(setRefreshDateRangeDisplay(false));
  }, [start, end, refreshDateRangeDisplay]);

  const handleDateRange = (days: number, hrs: number) => {
    dispatch(setLastXdaysSelected(true));
    const currentTime = new Date();
    const startTime = moment(currentTime).subtract(days, 'd').subtract(hrs, 'h').toDate();
    setDateRange([startTime, currentTime])
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, x: string) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
      setPlacement(x);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'open' : undefined;

  const { translate } = useTranslation();
  const componentText = useMemo(() => { 
    return {
      startDate : translate("start_date_label"),
      endDate : translate("end_date_label"),
      formatDate: translate("assets_format_date")
    };
  }, []);

  
  const formatStartDate = dateFormatter(start, componentText.formatDate);
  const formatEndDate = end ? dateFormatter(end, componentText.formatDate) : '';

  return (
    <>
      <Box
        display='inline-flex'
        alignItems='center'
        mb='1rem'
        sx={{ float: 'right' }}
        onClick={(e) => handleClick(e, 'bottom-center')}
        width='100%'
      >
        <TextField
          data-testid='assets_management_calendar_start_date'
          label={componentText.startDate}
          id='start'
          size='small'
          variant='outlined'
          value={formatStartDate}
          sx={{
            backgroundColor: isFromKPITrend ? 'white' : undefined,
            width: isFromKPITrend ? '40%' : '50%',
            '& fieldset': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRightWidth: 0,
            },
            '.MuiOutlinedInput-input': { cursor: 'pointer' },
            '& .MuiOutlinedInput-root': { background },
          }}
          InputProps={
            !isFromKPITrend
              ? {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <EventIcon />
                    </InputAdornment>
                  ),
                }
              : undefined
          }
        />
        <TextField
          data-testid='assets_management_calendar_end_date'
          label={componentText.endDate}
          id='end'
          size='small'
          variant='outlined'
          value={formatEndDate}
          sx={{
            backgroundColor: isFromKPITrend ? 'white' : undefined,
            width: isFromKPITrend ? '60%' : '50%',
            '& fieldset': { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
            '.MuiOutlinedInput-input': { cursor: 'pointer' },
            '& .MuiOutlinedInput-root': { background },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <EventIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <PopperDateRange
        id={id}
        open={open}
        anchorEl={anchorEl}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        handleDateRange={handleDateRange}
        placement={placement}
        handleSetRange={handleSetRange}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};

export default React.memo(DateRange);
