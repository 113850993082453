import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export const useUpdateAssetImage = (
  assetId: number,
  imageId: string,
  type: string,
  onSuccess?: () => void,
) => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('image_successfully_updated_toast'),
      errorMess: translate('failed_to_update_image_toast'),
    };
  }, []);
  return useAuthMutation({
    url: `assets/${assetId}/images/${imageId}/default?type=${type}`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.successMess);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('images') });
        onSuccess?.();
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
