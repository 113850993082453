import { alarmColor, okColor, warningColor } from "features/dashboard/colors/colors";
import { AssetStatusGroup } from "features/dashboard/models/assetStatusGroup.model";
import { FC, memo } from "react";
import GroupTileBody from "./GroupTileBody";

interface AssetTileProps {
  row:AssetStatusGroup,
  index: number,
  handleChangeExpand: (id: number) => void;
  tileWidth: number;
}

const GroupTile: FC<AssetTileProps> = ({row, index, handleChangeExpand, tileWidth}) => { 
  let color = row.alarmCounter ? alarmColor :(row.warningCounter ? warningColor:okColor);
  
  return (
   <GroupTileBody row={row} index={index} handleChangeExpand={handleChangeExpand} tileWidth={tileWidth} />
  );
};

export default memo(GroupTile);

