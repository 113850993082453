import { SortOrder } from "./models";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T | undefined): number {
  if (!orderBy) return 0;
  if(typeof a[orderBy] === "string") {
    return (a[orderBy] as string).localeCompare(b[orderBy] as string, undefined,  { sensitivity: 'base', numeric: true })
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<T>(order: SortOrder, orderBy: keyof T | undefined) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}