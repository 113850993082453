import { LoadingButton } from '@mui/lab';
import { Divider, Grid } from '@mui/material';
import { useGetAssetGroups } from 'api/asset-groups';
import { useGetImageByAsset } from 'api/dashboard/assets';
import { useGetAssetImages } from 'api/plants/useGetAssetImages';
import { useGetAssetTypeConfiguration } from 'api/plants/useGetAssetTypeConfiguration';
import { useGetUserRoles } from 'api/users';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { RoleRequired } from 'components/RoleRequired';
import { UserRole } from 'features/plants-management/models/user.model';
import { useTranslation } from 'languages';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useResponsiveLanguage } from 'shared/responsive';
import { selectPlantId } from 'store/accountSlice';
import AssignSensors from '../../CommissionsSensors/AssignSensors';
import CommissionSensors from '../../CommissionsSensors/CommissionSensors';
import EditAssetHeader from './EditAssetHeader';
import EditAssetTabs, { EditAssetTabsRefProps } from './EditAssetTabs';
import EditImage from './EditImage';
import { useGetAssets } from 'api/assets';
import { useGetAssetById } from 'api/plants/useGetAssetById';

type EditAssetProps = {
  open: boolean;
  onClose: () => void;
  selectedAssetsIds: number[];
  tab?: number;
  sensorSerialNumber?: string;
};

export type DisplayType = 'form' | 'commission' | 'image' | 'assign';

const EditAsset = (props: EditAssetProps) => {
  const editAssetTabsRef = useRef<EditAssetTabsRefProps>(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);

  const plantId = useSelector(selectPlantId);
  const { data: assets } = useGetAssets();
  const { data: userRoles = [] } = useGetUserRoles();
  const filteredRoles = useMemo(
    () => userRoles?.filter((role) => role.plantName !== null && role.plantId !== 0),
    [userRoles],
  );

  const userRole = filteredRoles.find((x) => x.plantId === plantId)?.role;
  const hasUserRole = ['Plant Viewer'].includes(userRole as UserRole);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      applyButton: translate('menu_save_button'),
      editTitle: translate('edit_title'),
      commissionSensorsTitle: translate('commission_sensors_title'),
      assignSensorsTitle: translate('assign_sensors_title'),
    };
  }, []);
  const [forceCommisionSensorModalOpen, setForceCommisionSensorModalOpen] = useState(false);

  const [display, setDisplay] = useState<DisplayType>('form');

  const [assetApplicationId, setAssetApplicationId] = useState<number | null>(null);

  const [selectedAsset, setSelectedAsset] = useState<number>(props.selectedAssetsIds[0]);
  const { data: asset, refetch: refetchAsset } = useGetAssetById(selectedAsset);
  const { data: assetGroups = [] } = useGetAssetGroups();

  const [assetName, setAssetName] = useState<string | undefined>(asset?.name ? asset.name : '');
  const [partId, setPartId] = useState<number | undefined>(asset?.partId ? asset.partId : 0);

  const [partNumber, setPartNumber] = useState<string | undefined>(
    asset?.partNumber ? asset.partNumber : '',
  );
  const [assetType, setAssetType] = useState<string | undefined>(asset?.type ? asset.type : '');

  const [newAssetTypeConf, setNewAssetTypeConf] = useState<{ [key: string]: string | number }>({});
  const { data: assetTypeConfiguration } = useGetAssetTypeConfiguration(assetType || '');

  const [partProperties, setPartProperties] = useState<{
    [key: string]: {
      label: string;
      type: string;
      value: string | number;
      unit: string;
    };
  }>({});

  useEffect(() => {
    if (
      !assetTypeConfiguration ||
      assetTypeConfiguration.length === 0 ||
      assetType === asset?.type
    ) {
      return;
    }
    const startConf: { [key: string]: string | number } = {};
    assetTypeConfiguration.forEach(
      (typeConf) => (startConf[typeConf.name] = typeConf.defaultValue),
    );
    setNewAssetTypeConf(startConf);
  }, [assetTypeConfiguration, asset, assetType]);

  useEffect(() => {
    if (asset?.configuration && asset.type === assetType) {
      const assetInitialValues: { [key: string]: string | number } = {};
      for (const [key, value] of Object.entries(asset.configuration)) {
        assetInitialValues[key] = value.value;
      }
      setNewAssetTypeConf(assetInitialValues);
    }
  }, [asset, assetType]);

  const [partDescription, setPartDescription] = useState<string | undefined>(
    asset?.partDescription ? asset.partDescription : '',
  );
  const [serialNumber, setSerialNumber] = useState<string | undefined>(
    asset?.serialNumber ? asset.serialNumber : '',
  );
  const [note, setNote] = useState<string | undefined>(asset?.note ? asset.note : '');
  const [assetGroup, setAssetGroup] = useState<number | undefined>(
    asset?.assetGroupId ? asset.assetGroupId : 0,
  );
  const [assetGroupName, setAssetGroupName] = useState<string | undefined>(
    assetGroups.find((g) => g.id === assetGroup)?.name,
  );
  const [quantity, setQuantity] = useState<number | undefined>(
    asset?.sensorsQuantity ? asset.sensorsQuantity : 0,
  );
  const [forcedTab, setForcedTab] = useState<number | undefined>(props.tab);

  React.useEffect(() => {
    setAssetName(asset?.name ? asset.name : '');
    setPartNumber(asset?.partNumber ? asset.partNumber : '');
    setPartId(asset?.partId ? asset.partId : 0);
    setAssetType(asset?.type ? asset.type : '');
    setPartDescription(asset?.partDescription ? asset.partDescription : '');
    setSerialNumber(asset?.serialNumber ? asset.serialNumber : '');
    setNote(asset?.note ? asset.note : '');
    setAssetGroup(asset?.assetGroupId ? asset.assetGroupId : 0);
    setQuantity(asset?.sensorsQuantity ? asset.sensorsQuantity : 0);
    setPartProperties(asset?.partProperties ? asset.partProperties : {});
    setAssetApplicationId(asset?.assetApplicationId ?? null);
  }, [selectedAsset, asset]);

  React.useEffect(() => {
    setAssetGroupName(assetGroups.find((g) => g.id === assetGroup)?.name);
  }, [assetGroup]);

  const { data: images } = useGetAssetImages(selectedAsset);

  const queryClient = useQueryClient();
  const handleSave = () => {
    editAssetTabsRef.current?.applyChanges();
  };

  const handleClose = () => {
    if (forceCommisionSensorModalOpen) {
      return;
    }
    props.onClose();
    queryClient.invalidateQueries({ queryKey: ['assets'] });
  };

  const [imageUrl, setImageUrl] = useState('');

  const {
    data: imageBlob,
    isLoading: imageLoading,
    isSuccess: imageSuccess,
    refetch: imageRefetch,
  } = useGetImageByAsset(
    selectedAsset,
    images?.photos.find((item) => item.default)?.thumbnail || '',
  );

  React.useEffect(() => {
    if (!imageLoading && imageBlob && imageSuccess) {
      setImageUrl(URL.createObjectURL(imageBlob));
    }
  }, [imageBlob, imageLoading, imageSuccess]);

  const mainImageRefetch = () => {
    if (images?.photos.find((item) => item.default)?.thumbnail || '') {
      imageRefetch();
    }
  };

  const switchTitle = () => {
    switch (display) {
      case 'form':
        return componentText.editTitle;
      case 'image':
        return '';
      case 'commission':
        return componentText.commissionSensorsTitle;
      case 'assign':
        return componentText.assignSensorsTitle;
    }
  };

  const selectedAssetName = useMemo(
    () => assets?.records.find((item) => item.id === selectedAsset)?.name,
    [assets, selectedAsset],
  );
  const refetchAssetAndImage = useCallback(() => {
    refetchAsset();
    imageRefetch();
  }, [refetchAsset, imageRefetch]);
  const onDecommissionHandler = useCallback(() => {
    refetchAsset();
  }, [refetchAsset]);

  const setSelectedAssetHandler = useCallback((assetId: number) => {
    setSelectedAsset(assetId);
    setImageUrl('');
  }, []);

  const responsiveProps = {
    assignOtherWidthModal: useResponsiveLanguage({
      en: { xs: { width: '90%' }, md: { width: '90%' }, lg: { width: '53rem' } },
    }),
  };
  return (
    <DodgeModal
      title={switchTitle()}
      open={props.open || forceCommisionSensorModalOpen}
      hideCloseIco={forceCommisionSensorModalOpen}
      onClose={handleClose}
      width={
        display === 'commission'
          ? '50vh'
          : display === 'assign'
            ? '26rem'
            : (responsiveProps.assignOtherWidthModal as string)
      }
    >
      {display === 'form' && (
        <div data-testid='edit_asset_modal'>
          <EditAssetHeader
            data={assets?.records ?? []}
            selectedAsset={selectedAsset}
            assetType={assetType}
            setSelectedAsset={setSelectedAssetHandler}
            setDisplay={setDisplay}
            selectedImage={imageUrl ?? 'none'}
          />
          <EditAssetTabs
            assetName={assetName}
            newAssetTypeConf={newAssetTypeConf}
            assetTypeConfiguration={assetTypeConfiguration}
            partNumber={partNumber}
            assetType={assetType}
            partDescription={partDescription}
            serialNumber={serialNumber}
            note={note}
            assetGroup={assetGroup}
            assetGroups={assetGroups}
            partId={partId}
            partProperties={partProperties}
            setIsLoading={setIsUpdateLoading}
            setPartId={setPartId}
            onCommission={setDisplay}
            onDecomission={onDecommissionHandler}
            setAssetName={setAssetName}
            setNewAssetTypeConf={setNewAssetTypeConf}
            setPartNumber={setPartNumber}
            setAssetType={setAssetType}
            setPartDescription={setPartDescription}
            setAssetGroup={setAssetGroup}
            setSerialNumber={setSerialNumber}
            setNote={setNote}
            selectedAsset={selectedAsset}
            tab={forcedTab}
            sensorsQuantity={quantity}
            selectedAssetName={selectedAssetName}
            refetchAsset={refetchAssetAndImage}
            disableInput={hasUserRole}
            assetApplicationId={assetApplicationId}
            setAssetApplicationId={setAssetApplicationId}
            sensorSerialNumber={props.sensorSerialNumber}
            setDisabledSave={setDisabledSave}
            ref={editAssetTabsRef}
          />
          <Divider sx={{ my: 2 }} />
          <Grid
            item
            container
            justifyContent='space-between'
            direction='row-reverse'
            sx={{ mt: 4 }}
          >
            <RoleRequired roles={['General User', 'Plant Administrator', 'Super Administrator']}>
              <LoadingButton
                loading={isUpdateLoading}
                disabled={disabledSave}
                data-testid='asset_edit_save_all_button'
                variant='contained'
                color='secondary'
                sx={{ color: 'text.primary', fontWeight: 'bold' }}
                onClick={handleSave}
              >
                {componentText.applyButton}
              </LoadingButton>
            </RoleRequired>
          </Grid>{' '}
        </div>
      )}
      {display === 'image' && (
        <EditImage
          setDisplay={setDisplay}
          mainImageRefetch={mainImageRefetch}
          defaultPhoto={images?.photos.find((item) => item.default)?.name || null}
          defaultNameplate={images?.nameplates.find((item) => item.default)?.name || null}
          photos={images?.photos || []}
          nameplates={images?.nameplates || []}
          selectedAsset={selectedAsset}
        />
      )}
      {display === 'commission' &&
        assetName &&
        assetGroup &&
        assetGroupName &&
        asset &&
        assetType && (
          <CommissionSensors
            setForceOpen={setForceCommisionSensorModalOpen}
            assetDetails={{
              id: selectedAsset,
              name: assetName,
              assetGroupId: assetGroup,
              assetGroupName: assetGroupName,
              sensorsCount: asset.sensorsQuantity,
              assetType: assetType,
            }}
            onCancel={() => {
              setForcedTab(0);
              setDisplay('form');
            }}
            onClose={props.onClose}
            onAssignSensors={() => setDisplay('assign')}
          />
        )}
      {display === 'assign' && (
        <AssignSensors
          onClose={props.onClose}
          onCancel={() => {
            setForcedTab(0);
            setDisplay('form');
          }}
          assetName={assetName}
          assetId={selectedAsset}
        />
      )}
    </DodgeModal>
  );
};

export default EditAsset;
