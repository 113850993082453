import { CircularProgress, Typography } from '@mui/material';
import { useGetImageByAsset } from 'api/dashboard/assets';
import { FC, memo, useEffect, useState } from 'react';
import { Image } from './models/image.model';

type MainImageProps = {
  assetId : number;
  image : Image;
  full ?: boolean;
  list ?: boolean;
};

const MainImage : FC<MainImageProps> = ({ assetId, image, full, list }) => {
  const [imageUrl, setImageUrl] = useState('');

  const {
    data: imageBlob,
    isLoading,
    isSuccess,
  } = useGetImageByAsset(assetId, list ? String(image.thumbnail) : String(image.name));

  useEffect(() => {
    if (!isLoading && imageBlob && isSuccess) {
      setImageUrl(URL.createObjectURL(imageBlob));
    }
  }, [imageBlob]);

  return isSuccess && imageBlob && imageUrl ? (
    <img
      src={imageUrl}
      style={{
        maxWidth: full ? '120%' : '100%',
        maxHeight: full ? '80vh' : undefined,
        height: full ? '120%' : '100%',
        width: '100%',
        borderRadius: '12px',
        objectFit: list ? 'cover' : 'contain',
      }}
    />
  ) : isLoading ? (
    <CircularProgress />
  ) : (
    <Typography fontSize='0.8rem' textAlign='center'>
      Failed to download image
    </Typography>
  );
};

export default memo(MainImage);
