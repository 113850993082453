import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface AssetBody {
  name: string | undefined;
  configuration: { [key: string]: string | number };
  assetGroupId: number | undefined;
  serialNumber: string | undefined;
  note: string | undefined;
  partId: number | undefined;
  assetApplicationId: number;
}

export interface ResponseData {
  location: string;
  serialNumber: string;
  status: string;
}
export const useUpdateAsset = (assetId: string, onSuccess?: (data: ResponseData[]) => void) => {
  const queryClient = useQueryClient();
  const plantId = useSelector(selectPlantId);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastEditAssetError: translate('toast_edit_asset_error'),
      toastEditAssetSuccess: translate('toast_edit_asset_success'),
    };
  }, []);

  return useAuthMutation<undefined, AssetBody, ResponseData[]>({
    url: `/plants/${plantId}/assets/${assetId}`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: (data) => {
        toast.success(componentText.toastEditAssetSuccess);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes(`parts`) });
        onSuccess?.(data.data);
      },
      onError: () => {
        toast.error(componentText.toastEditAssetError);
      },
    },
  });
};
