import { useTranslation } from 'languages';
import moment from 'moment-timezone';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectAutoTimezone, selectTimezone } from 'store/accountSlice';

const useDateFormatter = () => {
  const currentTimeZone = useSelector(selectTimezone);
  const isAutoTimezone = useSelector(selectAutoTimezone);
  const { GLOBAL_LANGUAGE } = useTranslation();

  const dateFormatter = useCallback(
    (
      date: string | Date,
      format: string,
      initialFormat?: moment.MomentFormatSpecification | undefined,
    ) => {
      if (isAutoTimezone) {
        return initialFormat && typeof date === 'string'
          ? moment(date, initialFormat).locale(GLOBAL_LANGUAGE ?? 'en').local().format(format)
          : moment(date).locale(GLOBAL_LANGUAGE ?? 'en').local().format(format);
      } else if (initialFormat && typeof date === 'string') {
        return moment.tz(date, initialFormat, currentTimeZone).locale(GLOBAL_LANGUAGE ?? 'en').format(format);
      } else {
        return moment.tz(date, currentTimeZone).locale(GLOBAL_LANGUAGE ?? 'en').format(format);
      }
    },
    [isAutoTimezone, currentTimeZone],
  );

  return dateFormatter;
};

export default useDateFormatter;
