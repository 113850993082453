import { useAuthMutation } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

export const useDeclineInvitation = (plantId: number) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_decline_invitation_toast'),
    };
  }, []);
  return useAuthMutation<{}>({
    url: `users/roles?plantId=${plantId}`,
    method: 'DELETE',
    mutationOptions: {
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
