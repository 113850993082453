import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { PlantBody } from 'models/plant/createPlantBody.model';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import axios from 'axios';

export const predictedErrors = {
  UniqueKeyViolation: {
    message: 'Provided plant name is already in use',
  },
};

export const useUpdatePlant = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('plant_successfully_updated_toast'),
      errorMess: translate('unexpected_error_toast'),
    };
  }, []);
  return useAuthMutation<{ plantId: number }, PlantBody, unknown>({
    url: ({ plantId }) => `plants/${plantId}`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.successMess);
        queryClient.invalidateQueries({
          queryKey: ['plants'],
        });
        queryClient.invalidateQueries({ queryKey: ['roles'] });
        queryClient.invalidateQueries({
          queryKey: ['last-measurements'],
        });
        onSuccess?.();
      },
      onError(error) {
        Object.entries(predictedErrors).forEach(([key, value]) => {
          if (axios.isAxiosError(error)) {
            if (key === error.response?.data.message) {
              toast.error(value.message);
            } else {
              toast.error('Unexpected error');
            }
          } else {
            if (key === error.message) {
              toast.error(value.message);
            } else {
              toast.error('Unexpected error');
            }
          }
        });
      },
    },
  });
};
