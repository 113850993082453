import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export const useDeleteAssetApplication = (onSuccess?: () => void) => {
  const plantId = useSelector(selectPlantId);
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_delete_asset_application_toast'),
    };
  }, []);
  return useAuthMutation<{ assetApplicationId: number }, {}>({
    url: (params) => `plants/${plantId}/asset-applications/${params.assetApplicationId}`,
    method: 'DELETE',
    mutationOptions: {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes('asset-applications'),
        });
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
