import DodgeModal from 'components/DodgeModal/DodgeModal';
import { memo, useMemo } from 'react';
import ManageAccountContainer from './ManageAccountContainer';
import { useDeviceSize, useResponsiveLanguage } from 'shared/responsive';
import { Button, Grid, Link } from '@mui/material';
import { fontMedium } from './styles/fontStyle';
import { useTranslation } from 'languages';

type ManageAccountProps = {
  open: boolean;
  onClose?: () => void;
  link?: string;
  logout: () => void;
  firstName: string;
  lastName: string;
  email: string;
};

const ManageAccount = ({
  open,
  onClose,
  link,
  logout,
  firstName,
  lastName,
  email,
}: ManageAccountProps) => {
  const { isPhone, isTablet, isLandscape, isPortrait, isDesktop } = useDeviceSize();
  const responsiveProps = {
    minHeight: isPhone
      ? '42rem'
      : isTablet && isLandscape && !isDesktop
        ? '90vh'
        : isTablet && isPortrait && !isDesktop
          ? '45rem'
          : '47rem',
    modalWidth: useResponsiveLanguage({
      en: { xs: { width: '85%' }, md: { width: '80%' }, lg: { width: '52rem' } },
    }),
  };
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      manageAccountHeader: translate('manage_account_header'),
      signOut: translate('menu_sign_out_text'),
    };
  }, [translate]);

  return (
    <DodgeModal
      title={componentText.manageAccountHeader}
      open={open}
      onClose={onClose}
      sx={{ overflowY: (isPhone || isTablet) && !isDesktop ? 'auto' : 'inherit' }}
      width={responsiveProps.modalWidth as string}
      height={responsiveProps.minHeight}
    >
      <ManageAccountContainer
        link={link}
        logout={logout}
        firstName={firstName}
        lastName={lastName}
        email={email}
        onClose={onClose}
      />
      {(isPhone || isTablet) && !isDesktop ? (
        <Grid item xs={12}>
          <Link
            width={'100%'}
            data-testid='manage_account_sign_out_button'
            id='sign-out'
            component='button'
            sx={{ display: 'block', textDecoration: 'none', ...fontMedium, color: 'black' }}
            onClick={logout}
          >
            <Button fullWidth variant='contained' color='secondary'>
              {componentText.signOut}
            </Button>
          </Link>
        </Grid>
      ) : (
        <></>
      )}
    </DodgeModal>
  );
};

export default memo(ManageAccount);
