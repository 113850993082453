import { ClickAwayListener, Grid, Popper } from '@mui/material';
import React, { FC } from 'react';
import KpiDatePicker from './KpiDatePicker';
import Range from './Range';
import './styles/PopperDateRange.scss';

interface PopperProps {
  id?: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  placement: string;
  handleDateRange: (days: number, hrs: number) => void;
  handleSetRange: (dateRange: [Date | null, Date | null]) => void;
  setAnchorEl: (el: HTMLElement | null) => void;
  disableMaxDate?: boolean;
}

const PopperDateRange: FC<PopperProps> = ({
  id,
  open,
  anchorEl,
  startDate,
  endDate,
  handleDateRange,
  placement,
  handleSetRange,
  setAnchorEl,
  disableMaxDate,
}) => {
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      style={{ border: 'none', background: 'white', borderRadius: '12px', zIndex: 10000 }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid
          width='700px'
          container
          sx={{
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px rgb(0 0 0 / 14%), 0px 1px 8px rgb(0 0 0 / 12%)',
            borderRadius: '12px',
            background: 'white',
          }}
        >
          <Range handleDateRange={handleDateRange} />
          <Grid item xs={9} container justifyContent='center' pb='1rem' pt='1.5rem'>
            <Grid item container justifyContent='center' alignItems='center'>
              <KpiDatePicker
                startDate={startDate}
                endDate={endDate}
                handleSetRange={handleSetRange}
                disableMaxDate={disableMaxDate}
              />
            </Grid>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </Popper>
  );
};

export default React.memo(PopperDateRange);
