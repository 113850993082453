import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ConfigurableGroup } from 'features/plants-management/models/asset.model';
import { AssetInConfigurableGroup } from '../models/assetInConfigurableGroup';

export interface ReportState {
    reportType: string;
    newReport: boolean;
    step: number;
    onConfGroupRowsSelectedArray: Array<any>;
    onAllAssetsRowsSelectedArray: Array<AssetInConfigurableGroup>;
  }
  
  const initialState: ReportState = {
    reportType: "",
    newReport: false,
    step: 0,
    onConfGroupRowsSelectedArray: [],
    onAllAssetsRowsSelectedArray:[],
  };
 
  export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
      chooseReport: (state, action) => {
        state.reportType = action.payload;
      },
      createReport: (state, action) => {
        state.newReport = action.payload;
      },
      setStep: (state, action) => {
        state.step = action.payload;
      },
      addRowToConfGroupRowsSelectedArray: (state, action) => {
        state.onConfGroupRowsSelectedArray = state.onConfGroupRowsSelectedArray.concat([action.payload]);
      },
      deleteRowFromConfGroupRowsSelectedArray: (state, action) => {
        state.onConfGroupRowsSelectedArray = state.onConfGroupRowsSelectedArray.filter(item => item.id !== action.payload.id);
      },
      deleteAllRowFromConfGroupRowsSelectedArray: (state) => {
        state.onConfGroupRowsSelectedArray.length = 0;
      },

      addRowToAllAssetsRowsSelectedArray: (state, action) => {
        state.onAllAssetsRowsSelectedArray = state.onAllAssetsRowsSelectedArray.concat([action.payload]);
      },
      deleteRowFromAllAssetsRowsSelectedArray: (state, action) => {
        state.onAllAssetsRowsSelectedArray = state.onAllAssetsRowsSelectedArray.filter(item => item.id !== action.payload.id);
      },
      deleteAllRowFromAllAssetsRowsSelectedArray: (state) => {
        state.onAllAssetsRowsSelectedArray.length = 0;
      },
    },
  })
  
  export const { chooseReport, createReport, setStep, addRowToConfGroupRowsSelectedArray, deleteRowFromConfGroupRowsSelectedArray, deleteAllRowFromConfGroupRowsSelectedArray, addRowToAllAssetsRowsSelectedArray, deleteRowFromAllAssetsRowsSelectedArray, deleteAllRowFromAllAssetsRowsSelectedArray } = reportSlice.actions;
  
  export const selectReportType = (state: RootState) => state.report.reportType;
  export const selectNewReport = (state: RootState) => state.report.newReport;
  export const selectStep = (state: RootState) => state.report.step;
  export const selectOnConfGroupRowsSelectedArray =  (state: RootState) => state.report.onConfGroupRowsSelectedArray;
  export const selectOnAllAssetsRowsSelectedArray =  (state: RootState) => state.report.onAllAssetsRowsSelectedArray;
  
  export default reportSlice.reducer;