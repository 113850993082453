import { Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import img from 'components/assets/img-create-plant.png';
import { FC, useMemo } from 'react';
import { useGetAuthData } from 'auth/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'languages';

type RequestSubmittedProps = Readonly<{
  memberName?: string;
  memberEmail?: string;
  inDashboard?: boolean;
  onClose?: () => void;
}>;

const RequestSubmitted: FC<RequestSubmittedProps> = ({
  memberName = 'memberName',
  memberEmail = 'memberEmail',
  inDashboard,
  onClose,
}) => {
  const { userEmail } = useGetAuthData();
  const queryClient = useQueryClient();

  const handleClose = () => {
    updateUserRoles();
    onClose?.();
  };

  const updateUserRoles = () => {
    queryClient.resetQueries({
      queryKey: ['roles'],
    });
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      requestSubmitted: translate('request_submitted_join_plant'),
      confirmationEmailSent: translate('confirmation_email_sent_join_plant'),
      adminApprovalNeeded: translate('admin_approval_needed_join_plant'),
      managePendingRequest: translate('manage_pending_request_join_plant'),
      profile: translate('profile_join_plant'),
      managePlants: translate('manage_plants_join_plant'),
      gotIt: translate('got_it_join_plant'),
    };
  }, []);

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      columnSpacing={3}
    >
      <Grid container item direction='column' xs={6} spacing={3}>
        <Grid item>
          <Typography
            variant='h4'
            color='primary.dark'
            sx={{ fontWeight: 600 }}
            data-testid='join_plant_success_box_confirmation'
          >
            {componentText.requestSubmitted}
          </Typography>
        </Grid>
        <Grid item data-testid='join_plant_success_box_information_grid'>
          <Typography variant='body1'>{componentText.confirmationEmailSent}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1'>{componentText.adminApprovalNeeded}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            {componentText.managePendingRequest}{' '}
            <b style={{ fontStyle: 'italic' }}>
              {componentText.profile} -&gt;{' '}
              <span style={{ color: '#0B79D0' }}>{componentText.managePlants}</span>
            </b>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            data-testid='plants_management_join_plant_modal_got_it_button'
            component={Link}
            to='/plants-management'
            onClick={handleClose}
            variant='contained'
            color='primary'
            fullWidth
            sx={{ mt: 2, color: 'white !important' }}
          >
            {componentText.gotIt}
          </Button>
        </Grid>
      </Grid>
      <Grid container item alignItems='center' justifyContent='center' height='20rem' xs={6}>
        <Grid item>
          <img src={img} style={{ transform: 'scale(3)' }} alt='Dodge Product' />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestSubmitted;
