import { useAuthMutation } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

interface JoinPlantBody {
  plantId: number;
}

export const useJoinPlant = (onSuccess: () => void) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_join_a_plant_toast'),
    };
  }, []);
  return useAuthMutation<{}, JoinPlantBody>({
    url: `users/roles`,
    method: 'POST',
    mutationOptions: {
      onSuccess: () => {
        onSuccess?.();
      },
      onError: (error: any) => {
        error && error.response.status === 429
          ? toast.error(error.response.data.message)
          : toast.error(componentText.errorMess);
      },
    },
  });
};
