import { Button, Grid, Switch, Typography } from '@mui/material';
import { useGetUnits } from 'api/users/useGetUnits';
import { usePutUnit } from 'api/users/usePutUnit';
import { Loading } from 'components';
import { ChangeEvent, Fragment, memo, useEffect, useMemo, useState } from 'react';
import { fontLarge } from './styles/fontStyle';
import { useDeviceSize } from 'shared/responsive';
import { useTranslation } from 'languages';

export interface Unit {
  devName: string;
  name: string;
  isImperialOn: boolean;
  unit: {
    first: string;
    second?: string;
  };
}

const UnitSettings = () => {
  const { data: response, isLoading } = useGetUnits();
  const { isPhone } = useDeviceSize();
  const { mutate: putUnitMutation, isPending: putLoading } = usePutUnit();

  const [units, setUnits] = useState<Unit[]>([]);

  const processName = (rawName: string) => {
    const name: string = rawName.charAt(0).toUpperCase() + rawName.slice(1);
    return name.replace(/([a-z])([A-Z])/g, '$1 $2');
  };

  const handleSwitchAll = (switchTo: 'Imperial' | 'Metric') => {
    switch (switchTo) {
      case 'Imperial':
        break;
      case 'Metric':
    }
    units.forEach((unit) => {
      switch (switchTo) {
        case 'Imperial':
          if (!unit.isImperialOn) {
            putUnitMutation({
              body: {
                name: unit.devName,
                isImperialOn: true,
              },
            });
          }
          break;
        case 'Metric':
          if (unit.isImperialOn) {
            putUnitMutation({
              body: {
                name: unit.devName,
                isImperialOn: false,
              },
            });
          }
      }
    });
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      selectUnitsFor: translate('menu_select_units_for_all'),
      imperialLabel: translate('menu_units_imperial'),
      metricLabel: translate('menu_units_metric'),
      unitsCanBeChanged: translate('menu_units_can_be_changed'),
    };
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    putUnitMutation({
      body: {
        name: key,
        isImperialOn: e.target.checked,
      },
    });
  };

  const changeToTranslationKey = (data: any) => {
    const convertedData = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const label = data[key].label.replace(/\s+/g, '_').toLowerCase();
        //@ts-ignore
        convertedData[key] = { ...data[key], label };
      }
    }
    return convertedData as any;
  };

  useEffect(() => {
    if (!isLoading && response) {
      const keys = Object.keys(response);
      const convertedResponse = changeToTranslationKey(response);

      setUnits(
        keys.map((key) => ({
          devName: key,
          name: translate(convertedResponse[key].label),
          isImperialOn: convertedResponse[key].isImperialOn,
          unit: {
            first: convertedResponse[key].metName,
            second: convertedResponse[key]?.impName,
          },
        })),
      );
    }
  }, [isLoading, response]);

  return (
    <>
      <Grid
        container
        sx={{
          overflow: 'auto',
          '&::-webkit-scrollbar': { width: '5px' },
          '&::-webkit-scrollbar-thumb': { background: '#337179' },
          '&::-webkit-scrollbar-track-piece:start': {
            background: 'transparent',
            mt: '1rem',
          },
        }}
        spacing={2}
        mt='0.2px'
      >
        {isLoading && units ? (
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              width: '100%',
              height: '30rem',
            }}
          >
            <Loading height='10rem' />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              container
              spacing={2}
              sx={{ background: 'rgba(51, 113, 121, 0.08)' }}
              py={2}
            >
              <Grid item xs={12}>
                <Typography variant='body2' fontSize={'16px'} fontWeight={400}>
                  {componentText.selectUnitsFor}
                </Typography>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item>
                  <Button
                    data-testid='manage_account_imperial_button'
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={() => handleSwitchAll('Imperial')}
                  >
                    {componentText.imperialLabel}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-testid='manage_account_metric_button'
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={() => handleSwitchAll('Metric')}
                  >
                    {componentText.metricLabel}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {units.map((item) => (
              <Fragment key={item.name}>
                <Grid item xs={5} lg={6} sx={{ ...fontLarge }}>
                  {item.name}
                </Grid>
                <Grid item lg={5} xs={7} container alignItems='center' justifyContent='center'>
                  <Grid item xs={4} md={5} lg={item.unit.second ? 5 : 2}>
                    <Typography sx={{ ...fontLarge, textAlign: 'right' }}>
                      {item.unit?.first}
                    </Typography>
                  </Grid>
                  {item.unit.second ? (
                    <>
                      <Grid item xs={isPhone ? 4 : 2} lg={3}>
                        <Switch
                          checked={item.isImperialOn}
                          onChange={(e) => handleChange(e, item.devName)}
                          sx={{ '& .MuiSwitch-thumb': { color: '#327179' } }}
                          disabled={putLoading}
                        />
                      </Grid>
                      <Grid item lg={4} xs={4}>
                        <Typography sx={{ ...fontLarge }}>{item.unit?.second}</Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Fragment>
            ))}
            <Typography sx={{ fontSize: '0.625rem', ml: '1rem', mt: '1rem' }}>
              {componentText.unitsCanBeChanged}
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
};

export default memo(UnitSettings);
