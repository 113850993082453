import { Button, Divider, Typography, Box } from "@mui/material";
import { useTranslation } from "languages";
import { useMemo } from "react";

interface TransferSuccessProps {
    serialNumber: string,
    sensorLocation: string,
    oldAssetName: string,
    newAssetName: string,
    onClose: () => void
}

const TransferSuccess = ({ serialNumber, sensorLocation, oldAssetName, newAssetName, onClose }: TransferSuccessProps) => {

    const { translate } = useTranslation();
    const componentText = useMemo(() => { 
      return { 
        confirmButton: translate('confirm_button'),
        successMessage: translate('transfer_sensor_success_message', { serialNumber, sensorLocation, oldAssetName, newAssetName })
      };
    }, [serialNumber, sensorLocation, oldAssetName, newAssetName]);
    
    return (
        <>
            <Typography sx={{ mt: '1rem' }}>{componentText.successMessage}</Typography>
            <Divider sx={{ mt: '1rem', mb: '1rem' }}  />
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Button
                    data-testid="plant_management_modal_transfer_sensor_confirm_button"
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                >
                    {componentText.confirmButton}
                </Button>
            </Box>
        </>
    );
}

export default TransferSuccess;