import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAuthMutation } from 'api/queries';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface AutoCloseParams {
  closeEventsAfterDays: number;
}

export const putAutoCloseEventsUrl = (plantId: number, closeEventsAfterDays: number) =>
  `plant/${plantId}/auto-close?closeEventsAfterDays=${closeEventsAfterDays}`;

export const usePutAutoCloseEvents = () => {
  const queryClient = useQueryClient();
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successToast: translate('successfully_changed_auto_close_settings_toast'),
      errorToast: translate('failed_to_change_auto_close_settings_toast'),
    };
  }, []);
  return useAuthMutation<AutoCloseParams, {}>({
    url: ({ closeEventsAfterDays }: AutoCloseParams) =>
      putAutoCloseEventsUrl(plantId, closeEventsAfterDays),
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes(`auto-close`),
        });
        toast.success(componentText.successToast);
      },
      onError: () => {
        toast.error(componentText.errorToast);
      },
    },
  });
};
