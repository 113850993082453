import { Grid, Switch, Typography } from '@mui/material';
import { useTranslation } from 'languages';
import { memo, useMemo } from 'react';
import { useDeviceSize } from 'shared/responsive';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};

const PlantViewerSettings = ({ value, onChange }: Props) => {
  const { translate } = useTranslation();
  const { isPhone, isTablet } = useDeviceSize();

  const componentText = useMemo(
    () => ({
      switchState: {
        enabled: translate('enabled'),
        disabled: translate('disabled'),
      },
      plantViewerCanLoadMeasurements: {
        label: translate('plant_viewers_can_load_measurements'),
        desc: translate('plant_viewers_can_load_measurements_description'),
      },
    }),
    [],
  );

  const responsiveProps = useMemo(
    () => ({
      textWidth: isPhone ? '100%' : '12.5rem',
      inputMinWidths: isPhone ? '15rem' : '13.37rem',
      customXS: isPhone ? 12 : isTablet && 4,
      timePosition: isPhone || isTablet ? 'flex-start' : 'flex-end',
      timeLeftMargin: isPhone || isTablet ? 0 : 0.5,
      timeTopMargin: isPhone || isTablet ? 1 : 0,
    }),
    [isPhone, isTablet],
  );

  return (
    <>
      <Grid item container xs={12} spacing={3} mb='2.5%' mt='1%' alignItems='center'>
        <Grid item lg={4} xs={isPhone ? 12 : isTablet && 4}>
          <Typography fontWeight='600' mt={'0.3rem'} width={responsiveProps.textWidth}>
            {componentText.plantViewerCanLoadMeasurements.label}
          </Typography>
        </Grid>
        <Grid item xs={8} ml='-1%'>
          {componentText.switchState.disabled}
          <Switch checked={value} onChange={(e) => onChange(e.target.checked)} />
          {componentText.switchState.enabled}
        </Grid>
      </Grid>
      <Grid item>
        <Typography align='justify' fontSize={'0.9rem'} mt={2} color='gray'>
          {componentText.plantViewerCanLoadMeasurements.desc}
        </Typography>
      </Grid>
    </>
  );
};

export default memo(PlantViewerSettings);
