import { Box, Card, CardActionArea, CardMedia, CircularProgress } from "@mui/material";
import { useGetImageByAsset } from "api/dashboard/assets/useGetImageByAsset";
import { AssetImage } from "features/plants-management/models/asset.model";
import { FC, memo, useEffect, useState } from "react";

interface ImageCardProps {
    image: string;
    selectedAsset: number;
    selectedImage: string;
    type: string,
    images: AssetImage[] | [],
    changeSelected: (id: string) => void;
    changeImageType: (type: string) => void;
}
const ImageCard:FC<ImageCardProps> = ({image, selectedAsset, selectedImage, type, changeSelected, changeImageType, images}) =>{
    const [imageUrl, setImageUrl] = useState("");

    const { data: imageBlob, isLoading: imageLoading, isSuccess: imageSuccess } = useGetImageByAsset(
      selectedAsset,
      images.find( img => img.name === image)?.thumbnail ?? image
    );
  
    useEffect(() => {
      if (!imageLoading && imageBlob && imageSuccess) {
        setImageUrl(URL.createObjectURL(imageBlob));
      }
    }, [imageBlob]);

     const handleChangeSelected = () =>{
        changeSelected(image);
        changeImageType(type);
     }
    return (
       <Card sx={{ borderRadius:"0.8rem", boxShadow:"none", border: image === selectedImage ? "4px solid #F0B323" : "4px solid white"}}>
        {imageLoading ? 
        (<Box display='flex' justifyContent='center' alignItems='center' minWidth='116px' height='90px'><CircularProgress color='primary' /></Box>) : 
        (<CardActionArea onClick={handleChangeSelected}>
          <CardMedia
              component="img"
              height="90"
              image={imageUrl}
              alt="image"
              sx={{minWidth:"116px", borderRadius:"0.8rem"}}
          />
      </CardActionArea>)}
     </Card>
    );
}

export default memo(ImageCard);