/* eslint-disable */
import { Grid, Typography } from '@mui/material';
import { FFTDataAPI, useGetMultipleFFT } from 'api/assets/useGetFFT';
import { GetUnitsResponse } from 'api/users/useGetUnits';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { Sensor } from 'features/assets-management/models';
import { RMSDataType } from 'features/assets-management/models/DataType.models';
import { FFTData, Peak } from 'features/assets-management/models/FFTChart.models';
import {
  selectAxis,
  selectFaultFrequencies,
  selectFilterBeans,
  selectMarginRange,
  selectNominalSpeed,
  selectNumberOfHarmonics,
  selectPeakDetection,
  selectPeakFactor,
  selectPeakSamples,
  selectSelectedSensorTriAxisMode,
  selectSelectedSensors,
  selectShowChart,
  selectViewType,
  selectWindowingFunction,
  selectedMeasurements,
  setMaxRunningSpeedValue,
} from 'features/assets-management/store/assetsManagementSlice';
import { useTranslation } from 'languages';
import { SensorType } from 'models/sensor/models';
import { FC, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { utils, write } from 'xlsx';
import BearingPartsLegend from '../../FftControl/BearingPartsLegend';
import { DelayedRefetchProps, Units, fftLabelDict } from '../../FftControl/FftControl';
import SelectDataPrompt from '../../SelectDataPrompt/SelectDataPrompt';
import useArrayMemo from '../../TimeSeriesGraph/utils/useArrayMemo';
import ChartTooltip, { ChartTooltipData } from '../../charts/ChartTooltip';
import FftChart, { HarmonicShape } from '../../charts/FFTCharts';
import Spectogram from '../../charts/Spectogram';
import Loader from './Loader';
import SpectogramHeader from './menu/SpectogramHeader';

type Props = {
  activeAssetId: number;
  activeSensorId: number;
  activeUnit: string;
  selectedFFTType: string;
  unitsData?: GetUnitsResponse;
  velocityUnit: string;
  pr: string;
  setUrl: (url: string) => void;
  setIsUserClickDownload: (isClick: boolean) => void;
  isUserClickDownload: boolean;
  openTreeMenu: boolean;
  assetType?: string;
  dePN: string;
  ndePN: string;
  faultDetection: boolean | string;
  isMotor: boolean;
  sensorInfo?: Sensor;
  freqUnit: string;
};

const FFTContent: FC<Props> = ({
  activeAssetId,
  activeSensorId,
  activeUnit,
  selectedFFTType,
  velocityUnit,
  unitsData,
  pr,
  setUrl,
  setIsUserClickDownload,
  isUserClickDownload,
  openTreeMenu,
  assetType,
  dePN,
  ndePN,
  faultDetection,
  isMotor,
  sensorInfo,
  freqUnit,
}) => {
  const checkedMeasurements = useSelector(selectedMeasurements);
  const selectedFaultFrequencies = useSelector(selectFaultFrequencies);
  const noOfHarmonics = useSelector(selectNumberOfHarmonics);
  const marginRange = useSelector(selectMarginRange);
  const nominalSpeed = useSelector(selectNominalSpeed);
  const windowingFunction = useSelector(selectWindowingFunction);
  const filterBins = useSelector(selectFilterBeans);
  const peakFactor = useSelector(selectPeakFactor);
  const peakSamples = useSelector(selectPeakSamples);
  const triAxisMode = useSelector(selectSelectedSensorTriAxisMode);
  const selectedAxisPerformanceSensor = useSelector(selectAxis);
  const peakDetection = useSelector(selectPeakDetection);
  const showChart = useSelector(selectShowChart);
  const view = useSelector(selectViewType);
  const dateFormatter = useDateFormatter();
  const dispatch = useDispatch();
  const [tooltipValue, setTooltipValue] = useState<ChartTooltipData | undefined>();
  const [expanded] = useState<boolean>(false);
  const [units, setUnits] = useState<Units>();
  const [delayedRefetchProps, setDelayedRefetchProps] = useState<DelayedRefetchProps>({
    marginRange: marginRange,
    nominalSpeed: nominalSpeed,
  });
  const selectedsensors = useSelector(selectSelectedSensors);
  const is3AxisEnabled = useSelector(selectSelectedSensorTriAxisMode);

  const displayRunningSpeed: boolean = useMemo(
    () => selectedFaultFrequencies.includes('runningSpeed'),
    [selectedFaultFrequencies],
  );
  const windowing: boolean = useMemo(() => windowingFunction.includes('Hann'), [windowingFunction]);

  const [FFTType, selectedAxis] = useMemo(() => {
    const splittedType = selectedFFTType.split('-');
    return splittedType.length > 1 ? splittedType : [splittedType[0], 'Z'];
  }, [selectedFFTType]);

  const isPerformanceSensor: boolean = useMemo(() => {
    return sensorInfo && sensorInfo.type === 'PerformanceSensor' ? true : false;
  }, [sensorInfo]);

  useEffect(() => {
    if (marginRange < 30 || marginRange > 600 || marginRange.toString().length === 0) {
      return;
    }
    const timeout = setTimeout(() => {
      setDelayedRefetchProps({
        marginRange: marginRange,
        nominalSpeed: nominalSpeed,
        peakFactor: peakFactor,
        peakSamples: peakSamples,
      });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [marginRange, nominalSpeed, peakFactor, peakSamples]);

  useEffect(() => {
    if (unitsData) {
      setUnits({
        rms: unitsData.velocityrms.isImperialOn
          ? unitsData.velocityrms.impName
          : unitsData.velocityrms.metName,
        frequency: 'Hz',
      });
    }
  }, [unitsData]);

  const queriesResults = useGetMultipleFFT({
    assetId: activeAssetId,
    sensorId: activeSensorId,
    dateTime: checkedMeasurements,
    freqUnit: activeUnit,
    velocityUnit: velocityUnit,
    FFTType,
    //selectedAxis,
    axis: isPerformanceSensor || is3AxisEnabled ? selectedAxisPerformanceSensor : selectedAxis,
    bearingParts: selectedFaultFrequencies.join(','),
    noOfHarmonics,
    marginRange:
      activeUnit === 'metric'
        ? delayedRefetchProps.marginRange / 60
        : delayedRefetchProps.marginRange,
    speed: delayedRefetchProps.nominalSpeed,
    peakFactor: delayedRefetchProps.peakFactor,
    peakSamples: delayedRefetchProps.peakSamples,
    windowing,
    filterBins,
    //assetType === 'motor' ? (!dePN && !ndePN ? '' : assetType) : assetType,
    assetType,
    dePN,
    ndePN,
    faultDetection: view === '3d' ? undefined : faultDetection,
    peakDetection,
  });

  const [isLoading, setIsloading] = useState<boolean>(false);

  useEffect(() => {
    setIsloading(queriesResults.filter((res) => res.isLoading).length > 0 ? true : false);
  }, [queriesResults]);

  const memoizedArray = useArrayMemo(
    queriesResults
      .filter((result: UseQueryResult<any, unknown>) => result.status === 'success')
      .map((query) => query.data),
  ) as FFTDataAPI[];

  useEffect(() => {
    dispatch(
      setMaxRunningSpeedValue(
        memoizedArray?.length === 1 ? Number(memoizedArray[0].fftData?.at(-1)?.time ?? 0) : 0,
      ),
    );
    
  }, [memoizedArray]);

  const [chartData, axesLabels, runningSpeedValues, harmonicShapes, peaks] = useMemo(() => {
    const chartDataTemp: FFTData[] = [];
    const axesLabelsTemp: (string | null)[] = [];
    const runningSpeedTemp: { nominalSpeedFrequency: number; nominalSpeed: number }[] = [];
    const harmonicShapes: HarmonicShape[] = [];
    const peaks: Peak[] = [];

    memoizedArray?.forEach((data, index) => {
      const fftData = data.fftData;
      if (fftData) {
        const yMax = fftData.reduce((max, item) => (item.value > max ? item.value : max), -Infinity);
        axesLabelsTemp.push(data.xLabelName, data.yLabelName);
        chartDataTemp.push({
          measurmentDate: checkedMeasurements[index],
          data: fftData,
        });
        runningSpeedTemp.push({
          nominalSpeedFrequency: data.nominalSpeedFrequency,
          nominalSpeed: data.nominalSpeed,
        });
        if (data.bearingPartsCage) {
          data.bearingPartsCage.forEach((cage) =>
            harmonicShapes.push({
              type: 'rect',
              layer: 'below',
              x0: cage.begin,
              x1: cage.end,
              y0: 0,
              y1: yMax,
              fillcolor: 'rgba(255, 196, 255, 0.5)',
              line: {
                color: 'rgba(255, 196, 255, 0.5)',
                width: 0,
              },
              measurementDate: String(
                dateFormatter(checkedMeasurements[index], 'MM/DD/YYYY|HH:mm:ss'),
              ),

              // moment(checkedMeasurements[index])
              //   .local()
              //   .format("MM/DD/YYYY|HH:mm:ss"),
            }),
          );
        }
        if (data.bearingPartsRoller) {
          data.bearingPartsRoller.forEach((roller) =>
            harmonicShapes.push({
              type: 'rect',
              layer: 'below',
              x0: roller.begin,
              x1: roller.end,
              y0: 0,
              y1: yMax,
              fillcolor: 'rgba(224, 115, 92, 0.5)',
              line: {
                color: 'rgba(224, 115, 92, 0.5)',
                width: 0,
              },
              measurementDate: String(
                dateFormatter(checkedMeasurements[index], 'MM/DD/YYYY|HH:mm:ss'),
              ),
            }),
          );
        }
        if (data.bearingPartsInnerRace) {
          data.bearingPartsInnerRace.forEach((innerRace) =>
            harmonicShapes.push({
              type: 'rect',
              layer: 'below',
              x0: innerRace.begin,
              x1: innerRace.end,
              y0: 0,
              y1: yMax,
              fillcolor: 'rgba(103, 196, 126, 0.5)',
              line: {
                color: 'rgba(103, 196, 126, 0.5)',
                width: 0,
              },
              measurementDate: String(
                dateFormatter(checkedMeasurements[index], 'MM/DD/YYYY|HH:mm:ss'),
              ),
            }),
          );
        }
        if (data.bearingPartsOuterRace) {
          data.bearingPartsOuterRace.forEach((outerRace) =>
            harmonicShapes.push({
              type: 'rect',
              layer: 'below',
              x0: outerRace.begin,
              x1: outerRace.end,
              y0: 0,
              y1: yMax,
              fillcolor: 'rgba(94, 174, 248, 0.5)',
              line: {
                color: 'rgba(94, 174, 248, 0.5)',
                width: 0,
              },
              measurementDate: String(
                dateFormatter(checkedMeasurements[index], 'MM/DD/YYYY|HH:mm:ss'),
              ),
            }),
          );
        }
        if (data.nominalSpeedFrequencies && selectedFaultFrequencies.includes('runningSpeed')) {
          data.nominalSpeedFrequencies.forEach((item) => {
            harmonicShapes.push({
              type: 'rect',
              layer: 'below',
              x0: item.begin,
              x1: item.end,
              y0: 0,
              y1: yMax,
              fillcolor: 'rgb(240, 179, 35, 0.5)',
              line: {
                color: 'rgb(240, 179, 35, 0.5)',
                width: 0,
              },
              measurementDate: String(
                dateFormatter(checkedMeasurements[index], 'MM/DD/YYYY|HH:mm:ss'),
              ),
            });
          });
        }
        if (data.peaks) {
          peaks.push({
            measurementDate: String(
              dateFormatter(checkedMeasurements[index], 'MM/DD/YYYY|HH:mm:ss'),
            ),
            data: data.peaks,
          });
        }
      }
    });
    return [chartDataTemp, axesLabelsTemp, runningSpeedTemp, harmonicShapes, peaks, peakDetection];
  }, [memoizedArray, checkedMeasurements]);

  // Find proper vibration label
  const findVibrationLabel = (sensorType: SensorType, triAxisMode: boolean, rms: string) => {
    if (sensorType === 'PerformanceSensor' || triAxisMode === true) {
      return RMSDataType.triAxisMode.find((entry) => entry.axis === rms)?.label;
    } else {
      return RMSDataType.default.find((entry) => entry.axis === rms)?.label;
    }
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      fftPickNotificationLabel: translate('asset_fft_pick_notification'),
      excelAssetName: translate('fft_excel_label_asset_name'),
      excelPositionName: translate('fft_excel_label_position_name'),
      excelTimestamp: translate('fft_excel_label_timestamp'),
      excelUnit: translate('fft_excel_label_unit'),
      excelFreqUnit: translate('fft_excel_label_freq_unit'),
      excelFrequency: translate('fft_excel_label_freq'),
      excelVal: translate('fft_excel_label_val'),
    };
  }, []);

  const handleDownload = () => {
    if (units && chartData && chartData.length) {
      const { name, parentName, type } = selectedsensors.find(
        (sensor) => sensor.sensorId === activeSensorId,
      ) as Sensor;
      const dataType = findVibrationLabel(type as SensorType, !!triAxisMode, selectedFFTType);
      const workbook = utils.book_new();
      chartData.forEach((item) => {
        if (item.measurmentDate) {
          const header = [
            [componentText.excelAssetName, parentName],
            [componentText.excelPositionName, name],
            [
              componentText.excelTimestamp,
              dateFormatter(item.measurmentDate, 'MM/DD/YYYY hh:mm a'),
            ],
            [
              `${dataType ?? 'undefined'} ${componentText.excelUnit}`,
              selectedFFTType.toUpperCase().includes('ACCELERATION')
                ? 'g'
                : units.rms.split('RMS')[0],
            ],
            [componentText.excelFreqUnit, freqUnit],
            ['', ''],
            [`${componentText.excelFrequency} [${freqUnit}]`, componentText.excelVal],
          ];

          const traces = item.data.map((item) => {
            return [String(item.time.toFixed(4)), String(item.value.toFixed(4))];
          });

          const content = [...header, ...traces];

          const sheetName = dateFormatter(item.measurmentDate, 'MM-DD-YYYY-HHmm');
          workbook.SheetNames.push(sheetName);
          const worksheet = utils.aoa_to_sheet(content as string[][]);
          workbook.Sheets[sheetName] = worksheet;
          worksheet['!cols'] = [{ wch: 30 }, { wch: 30 }];
        }
      });
      const wbout = write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([new Uint8Array(wbout)], { type: 'application/octet-stream' });
      setUrl(URL.createObjectURL(blob));
    }
  };

  useEffect(() => {
    if (isUserClickDownload) {
      handleDownload();
      setIsUserClickDownload(false);
    }
  }, [isUserClickDownload]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography fontWeight='600' fontSize='1.6rem' ml='.8rem'>
          FFT
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} position='relative' data-testid='assets_management_vibration_fft_chart'>
          {isLoading && <Loader height='90%' />}
          {checkedMeasurements.length > 0 && showChart ? (
            <>
              {Boolean(selectedFaultFrequencies.length) && view !== '3d' && (
                <BearingPartsLegend faultFrequencies={selectedFaultFrequencies} zIndex='10' />
              )}
              {view === 'Spectrogram' ? (
                <>
                  {checkedMeasurements.length >= 2 ? (
                    <>
                      <SpectogramHeader
                        isEnvelop={Boolean(selectedFaultFrequencies.length)}
                        amplitudeLabel={axesLabels[1]}
                      />
                      <Spectogram
                        xAxisLabel={axesLabels[0] ?? ''}
                        yAxisLabel='Date'
                        chartData={chartData}
                        marginTop={selectedFaultFrequencies.length ? 80 : 20}
                        pr={pr}
                        openTreeMenu={openTreeMenu}
                        harmonicShapes={harmonicShapes}
                      />
                    </>
                  ) : (
                    <SelectDataPrompt infoText='You must select 2 or more measurements' />
                  )}
                </>
              ) : (
                <FftChart
                  xAxisLabel={axesLabels[0] ?? ''}
                  yAxisLabel={`${fftLabelDict.get(
                    `${FFTType}-${
                      isPerformanceSensor || is3AxisEnabled
                        ? selectedAxisPerformanceSensor
                        : selectedAxis
                    }`,
                  )} [${axesLabels[1]}]`}
                  type={FFTType}
                  chartData={chartData}
                  setTooltipValue={setTooltipValue}
                  displayRunningSpeed={displayRunningSpeed}
                  runningSpeedValues={runningSpeedValues[0]}
                  harmonicShapes={harmonicShapes}
                  peaks={peaks}
                  marginTop={selectedFaultFrequencies.length ? 80 : 20}
                  pr={pr}
                  openTreeMenu={openTreeMenu}
                />
              )}
            </>
          ) : (
            <Grid ml={2}>
              <SelectDataPrompt infoText={componentText.fftPickNotificationLabel} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {tooltipValue && <ChartTooltip {...tooltipValue} renderInModal={expanded} />}
    </Grid>
  );
};

export default FFTContent;
