import { Box, Button, TableCell, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'languages';

interface DataTableItemsSelectedHeadProps {
  headCellsLength: number;
  selectedCount: number;
  children?: ReactNode;
  customDeleteText?: string;
  customDeleteIcon?: ReactNode;
  deleteDisabledPrompt?: string;
  isDisabled?: boolean;
  onDelete?: () => void;
  deleteButton?: 'none' | 'disabled';
  checkboxes?: string;
  hideDeleteButton?: boolean;
  customDeleteStyle?: SxProps<Theme>;
}

const DataTableItemsSelectedHead = (props: DataTableItemsSelectedHeadProps) => {
  const onClickHandler = () => {
    props.onDelete?.();
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      deleteButton: translate('asset_delete_asset_button'),
      itemSelected: translate('table_item_selected', {
        itemsCount: props.selectedCount,
        isPlural: props.selectedCount > 1,
      }),
    };
  }, [props.selectedCount]);

  const renderButton = (tooltip?: string, displayTooltip: boolean = true) => {
    if (tooltip && displayTooltip) {
      return (
        <Tooltip title={tooltip}>
          <Box sx={{ cursor: 'pointer' }}>
            {!props.hideDeleteButton && (
              <Button
                onClick={onClickHandler}
                data-testid='delete_decommission_button_disabled'
                startIcon={props.customDeleteIcon ? props.customDeleteIcon : <DeleteIcon />}
                disabled={props.isDisabled}
                sx={{ fontWeight: '600', ...props.customDeleteStyle }}
              >
                {props.customDeleteText ? props.customDeleteText : componentText.deleteButton}
              </Button>
            )}
          </Box>
        </Tooltip>
      );
    } else if (!props.hideDeleteButton) {
      return (
        <Button
          onClick={onClickHandler}
          data-testid='data_table_delete_button'
          startIcon={props.customDeleteIcon ? props.customDeleteIcon : <DeleteIcon />}
          disabled={props.isDisabled}
          sx={{ fontWeight: '600', ...props.customDeleteStyle }}
        >
          {props.customDeleteText ? props.customDeleteText : componentText.deleteButton}
        </Button>
      );
    }
  };

  return (
    <TableCell colSpan={props.headCellsLength} sx={{ background: 'rgb(240, 243, 244)' }}>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' gap='5rem' alignItems='center'>
          {props.checkboxes !== 'single-select' && (
            <Typography>{componentText.itemSelected}</Typography>
          )}
        </Box>
        <Box display='flex' justifyContent='flex-end' alignItems='center'>
          {props.children}
          {props.deleteButton !== 'none' &&
            renderButton(props.deleteDisabledPrompt, props.isDisabled)}
        </Box>
      </Box>
      {/* {props.isDisabled &&
        props.deleteDisabledPrompt &&
        props.deleteDisabledPrompt !== "" && (
          <Box
            sx={{
              position: "absolute",
              backgroundColor: "#FFFFFF",
              boxShadow:
                "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
              borderRadius: "12px",
              maxWidth: "222px",
              padding: "10px 17px 9px",
              right: "10px",
            }}
          >
            {props.deleteDisabledPrompt}
          </Box>
        )} */}
    </TableCell>
  );
};

export default DataTableItemsSelectedHead;
