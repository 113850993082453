import { Box } from "@mui/material";
import { FC, memo, ReactNode } from "react";

interface CounterTileProps{
    counter: number,
    icon: ReactNode,
    background: string,
}
const CounterTile: FC<CounterTileProps> = ({icon, background, counter}) =>{
    return(
        <Box width="60px" 
             height="27px" 
             sx={{background, 
                  borderRadius:"16px", 
                  color:"white", 
                  display:"flex", 
                  justifyContent:"center", 
                  alignItems:"center"}} 
              mr={1}>{counter}
                  {icon}
        </Box>
    );
}

export default memo(CounterTile);