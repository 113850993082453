import React from "react";
import PlaceholderLoading from "react-placeholder-loading";
import { Box } from "@mui/system";

type PropsType = {
  rows: number;
};
const LoadingTree = ({ rows }: PropsType) => {
  const randomWidths = Array.from(
    { length: rows },
    () => Math.floor(Math.random() * 31) + 60
  );

  return (
    <Box height={'calc(100vh - 65px)'} sx={{background:"white"}}>
      {randomWidths.map((width, index) => {
        return (
          <React.Fragment key={index}>
            <PlaceholderLoading shape="rect" width={width + "%"} height={20} />
            <br />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default React.memo(LoadingTree);
