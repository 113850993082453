// @ts-nocheck

import { useEffect, useState } from 'react';
import en from './english.json';
import es from './spanish.json';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'store/accountSlice';
import { usePutChangeLanguage } from 'api/users/usePutChangeLanguage';

export type TranslationKey = keyof typeof en;

const translationCache: Record<string, string> = {};

export const useTranslation = () => {
  const userLanguage = useSelector(selectLanguage);
  const [currentLanguage, setCurrentLanguage] = useState(userLanguage);
  const { mutate: updateLanguageMutation} =
    usePutChangeLanguage(currentLanguage ?? "");

  const translate = (key : TranslationKey, replacement ?: Record<string, any>, disableDangerousHtml ?: boolean) => {
    let selectedLanguage;
    switch (userLanguage) {
      case 'en':
        selectedLanguage = en;
        break;
      case 'es':
        selectedLanguage = es;
        break;
      default:
        selectedLanguage = en;
        break;
    }

    // Use provided isPlural flag for pluralization
    const pluralKey = replacement?.isPlural ? key + '_plural' : undefined;
    const translationKey = pluralKey
      ? selectedLanguage[pluralKey] || en[key]

      : selectedLanguage[key] || en[key] || key;

    // Check for dynamic replacement
    let translation = translationKey;
    if (replacement) {
      Object.keys(replacement).forEach((replaceKey) => {
        const placeholder = '${' + replaceKey + '}';
        if (translation.includes(placeholder)) {
          translation = translation.replace(placeholder, replacement[replaceKey].toString());
        }
      });
    }
    // If the translation is taken from the default language, check if there's a specific plural form or other parameters
    if (selectedLanguage[key] === undefined) {
      console.log('Translation not found for key: ' + key + ' in language: ' + currentLanguage);
      const defaultPluralKey = replacement?.isPlural ? key + '_plural' : undefined;
      const defaultTranslationKey = defaultPluralKey ? en[defaultPluralKey] || en[key] : en[key];
      if (defaultTranslationKey !== undefined) {
        let defaultTranslation = defaultTranslationKey;
        if (replacement) {
          Object.keys(replacement).forEach((replaceKey) => {
            const placeholder = '${' + replaceKey + '}';
            if (defaultTranslation.includes(placeholder)) {
              defaultTranslation = defaultTranslation.replace(
                placeholder,
                replacement[replaceKey].toString(),
              );
            }
          });
        }
        translation = defaultTranslation;
      }
    }
    translationCache[key] = translation;

    if (translation.includes('<') && !disableDangerousHtml) {
      return <span dangerouslySetInnerHTML={{ __html: translation }} />;
    }
    
    return translation;
  };

  const setGlobalLanguage = (newLanguage: string) => {
    setCurrentLanguage(newLanguage);
  };

  useEffect(() => {
    if (currentLanguage && currentLanguage !== userLanguage) {
        updateLanguageMutation({
            body: {
             language: currentLanguage,
             onSucces: () => {
                 console.log("Language changed");
             }
            }
         });
    }
  
  }, [currentLanguage])

  return { translate, setGlobalLanguage, GLOBAL_LANGUAGE: currentLanguage };
};
