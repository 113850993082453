import React from "react";
import { FC } from "react";

 type IntelliLubeKpiProps = {
    fill: string,
    name: string;
 }
const IntelliLubeKpi:FC<IntelliLubeKpiProps> = ({fill, name}) =>{
    return (
        <React.Fragment>
         {
            name.includes("EMPTY") &&
             <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path  fill= "#fff" d="m15.21,4.6c-.69-1.04-5.66-1.05-6.49-.05-.09.1-.13.2-.13.31v11.89c.01.11.03.22.09.32,0,0,0,.01.01.01.24.53,1.03.9,1.98,1.06l.05.28.27,1.54c.02.14.12.24.25.24h1.48c.12,0,.23-.1.25-.24l.3-1.54.06-.31c.84-.16,1.54-.47,1.83-.93h0c.08-.14.13-.28.14-.43V4.86c0-.09-.03-.18-.09-.26Z"/>
                <rect  fill= "#fff" x="10.5" y="8.16" width="3.1" height="6.71" rx="1.09" ry="1.09"/>
                <path fill= {fill} d="m16.37,16.77c0,.91-1.42,1.47-3.1,1.65-.42.05-.84.07-1.27.07s-.86-.02-1.28-.07c-1.67-.19-3.08-.74-3.08-1.65h.75c0,.08.1.19.29.3,0,0,0,.01.01.01.49.31,1.61.66,3.31.66,1.54,0,2.6-.28,3.16-.56h0c.3-.16.46-.31.46-.41h.75Z"/>
                <path fill= {fill} d="m16.37,4.21c0,.5-.42.89-1.07,1.17-.84.37-2.07.56-3.3.56s-2.57-.21-3.41-.62c-.58-.27-.95-.64-.95-1.11h.75c0,.1.11.23.33.34.53.32,1.69.64,3.28.64s2.65-.29,3.21-.59c.27-.13.41-.27.41-.39h.75Z"/>
                <path fill= {fill} d="m12.51,7.41h-.92c-1.02,0-1.84.83-1.84,1.84v4.53c0,1.02.82,1.84,1.84,1.84h.92c1.01,0,1.84-.82,1.84-1.84v-4.53c0-1.01-.83-1.84-1.84-1.84Z"/>
             </svg>
         }
         {
            name.includes("OVERLOAD") &&
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="80%">
                <path fill="white" d="m7.42,3.95h-2.65c-.22,0-.39-.18-.39-.39h0c0-.22.18-.39.39-.39h2.65c.22,0,.39.18.39.39h0c0,.22-.18.39-.39.39Z"/>
                <path fill="white" d="m18.95,20.75h-2.65c-.22,0-.39-.18-.39-.39h0c0-.22.18-.39.39-.39h2.65c.22,0,.39.18.39.39h0c0,.22-.18.39-.39.39Z"/>
                <path fill="white" d="m15.02,6.14c-2.28,0-4.13,1.85-4.13,4.13v2.11c0,.91-.74,1.65-1.65,1.65s-1.65-.74-1.65-1.65V4.34h-3.05v8.05c0,2.59,2.11,4.7,4.7,4.7s4.7-2.11,4.7-4.7v-2.11c0-.6.49-1.09,1.09-1.09s1.09.49,1.09,1.09v9.29h3.05v-9.29c0-2.28-1.85-4.13-4.13-4.13Zm-1.65,4.13v2.11c0,2.28-1.85,4.13-4.13,4.13-1.05,0-2.01-.39-2.74-1.04.12-.3.28-.63.49-.93.19-.26.4-.49.6-.68.41.45.99.74,1.64.74,1.22,0,2.21-.99,2.21-2.21v-2.11c0-.43.08-.85.22-1.24.33.05.73.15,1.19.32.24.09.45.18.64.28-.08.2-.13.41-.13.63Z"/>
                <circle fill="white" cx="7.81" cy="15.59" r=".37"/>
                <circle fill="white" cx="9.59" cy="15.22" r=".37"/>
                <circle fill="white" cx="11.09" cy="15.22" r=".37"/>
                <circle fill="white" cx="11.85" cy="13.93" r=".37"/>
                <circle fill="white" cx="12.81" cy="12.63" r=".37"/>
                <circle fill="white" cx="12.21" cy="11.16" r=".37"/>
            </svg>
         }
         {
            name.includes("BATTERY") &&
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="80%">
                <path fill= "#fff" d="m16.06,4.23h-2.03s0-.06,0-.08v-.61c0-.37-.3-.66-.66-.66h-2.36c-.37,0-.66.3-.66.66v.61s0,.06,0,.08h-2.04c-.77,0-1.39.62-1.39,1.39v14.12c0,.77.62,1.39,1.39,1.39h7.74c.77,0,1.39-.62,1.39-1.39V5.62c0-.77-.62-1.39-1.39-1.39Z"/>
                <rect fill= "#fff" x="7.62" y="4.92" width="9.15" height="15.53" rx=".7" ry=".7"/>
                <path fill={fill} d="m15.8,19.82c.19,0,.35-.15.35-.35v-1.91l-6.19,2.25h5.84Z"/>
            </svg>
         }
         {
            name.includes("LOW TEMPERATURE") &&
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="75%">
                <path fill= "#fff" d="m13.1,15.4V4.66c0-1.21-.98-2.2-2.2-2.2s-2.2.98-2.2,2.2v10.75c-.77.63-1.27,1.6-1.27,2.68,0,1.91,1.55,3.46,3.46,3.46s3.46-1.55,3.46-3.46c0-1.08-.49-2.04-1.27-2.68Z"/>
                <path fill= "#fff" d="m10.9,20.62c-1.4,0-2.54-1.14-2.54-2.54,0-.76.34-1.48.93-1.96l.34-.28V4.66c0-.7.57-1.27,1.27-1.27s1.27.57,1.27,1.27v11.18l.34.28c.59.48.93,1.2.93,1.96,0,1.4-1.14,2.54-2.54,2.54Z"/>
                <path fill={fill} d="m11.57,16.36v-2.04c0-.37-.3-.67-.67-.67h0c-.37,0-.67.3-.67.67v2.04c-.68.27-1.17.93-1.17,1.71,0,1.02.82,1.84,1.84,1.84s1.84-.82,1.84-1.84c0-.78-.48-1.44-1.17-1.71Z"/>
                <path fill= "#fff" d="m16.18,14.41h-1.75c-.21,0-.38-.17-.38-.38s.17-.38.38-.38h1.75c.21,0,.38.17.38.38s-.17.38-.38.38Z"/>
                <path fill= "#fff" d="m15.56,12.18h-1.13c-.21,0-.38-.17-.38-.38s.17-.38.38-.38h1.13c.21,0,.38.17.38.38s-.17.38-.38.38Z"/>
                <path fill= "#fff" d="m16.18,9.95h-1.75c-.21,0-.38-.17-.38-.38s.17-.38.38-.38h1.75c.21,0,.38.17.38.38s-.17.38-.38.38Z"/>
                <path fill= "#fff" d="m15.56,7.72h-1.13c-.21,0-.38-.17-.38-.38s.17-.38.38-.38h1.13c.21,0,.38.17.38.38s-.17.38-.38.38Z"/>
                <path fill= "#fff" d="m16.18,5.49h-1.75c-.21,0-.38-.17-.38-.38s.17-.38.38-.38h1.75c.21,0,.38.17.38.38s-.17.38-.38.38Z"/>
            </svg>
         }
         {
            name.includes("FILL") &&
            <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M5.33333 12.6667C3.85556 12.6667 2.59722 12.1639 1.55833 11.1583C0.519444 10.1528 0 8.91111 0 7.43333C0 6.7 0.141667 6.01667 0.425 5.38333C0.708333 4.75 1.09444 4.18889 1.58333 3.7L5.33333 0L9.08333 3.7C9.57222 4.18889 9.95833 4.75 10.2417 5.38333C10.525 6.01667 10.6667 6.7 10.6667 7.43333C10.6667 8.91111 10.1472 10.1528 9.10833 11.1583C8.06944 12.1639 6.81111 12.6667 5.33333 12.6667ZM1.05 8H9.61667C9.76111 7.23333 9.67778 6.52778 9.36667 5.88333C9.05556 5.23889 8.74444 4.76667 8.43333 4.46667L5.33333 1.41667L2.23333 4.46667C1.92222 4.76667 1.61111 5.23889 1.3 5.88333C0.988889 6.52778 0.905556 7.23333 1.05 8Z" fill="white"/>
            </svg>
         }
         {
            name.includes('BREATHER LIFE') &&
            <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="1.1875" width="14" height="16.625" fill="white"/>
              <path d="M6.86786 0.791395C4.33056 0.791395 1.94899 0.531943 0 0.0769231V17.5776C0.00786517 17.7405 0.0487628 17.8827 0.0896617 18.0261C1.36225 18.5267 3.68404 19 6.82382 19C10.3207 19 12.8187 18.4133 13.9591 17.854C13.9733 17.7862 13.9953 17.7171 13.9984 17.6506L14 0C11.9975 0.503259 9.51843 0.791395 6.86786 0.791395ZM10.3663 11.5215L10.1571 10.7066C8.49438 11.0313 7.92022 12.7236 7.72674 13.9648V17.2934H6.2182V12.5189C6.02 11.1643 4.87955 9.32725 2.60337 8.73924L2.3218 9.53585L1.16247 7.77575L3.28607 6.81486L2.99034 7.64798C4.35258 7.99609 5.44899 8.73924 6.23865 9.63625C6.30629 8.4472 6.60202 5.206 7.86989 3.18774L7 2.80704L9.31236 2.21382L10.0281 4.13038L9.20539 3.77053C7.83213 5.96089 7.72831 10.0704 7.72674 10.1004V10.7366C8.24112 10.1917 8.93798 9.76923 9.87079 9.59452L9.65213 8.74707L11.6829 9.83833L10.3663 11.5215Z" fill={fill}/>
            </svg>
         }
        </React.Fragment>
    )
}

export default IntelliLubeKpi;