import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import {LicenceArgeement} from "../LicenceArgeement/LicenceArgeement";
import { changeBlockUserPanel } from "../store/cookieSlice";

type LicenceArgeementProps =  {
    eulaAccepted?: boolean;
}
const LicenceArgeementPage:FC<LicenceArgeementProps> = ({eulaAccepted}) => {
    const [openModal, setOpenModal] = React.useState(!eulaAccepted);
    
    const dispatch = useDispatch();
    useEffect(()=>{dispatch(changeBlockUserPanel(openModal))},[]);
    
    return (
        <LicenceArgeement open={openModal} onClose={() => setOpenModal(false)} showAccept = {typeof eulaAccepted === 'undefined' ? false : !eulaAccepted } />
    )
}
export default React.memo(LicenceArgeementPage)