import { Button, Divider, Grid, useTheme } from '@mui/material';
import { ResponseData } from 'api/plants/useUpdateAsset';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { alarmColor, okColor } from 'features/dashboard/colors/colors';
import { FC, useMemo } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'languages';

type EditSummaryProps = {
  open: boolean;
  onClose: () => void;
  summaryData: ResponseData[];
};

const EditSummary: FC<EditSummaryProps> = ({ open, onClose, summaryData }) => {
  const theme = useTheme();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      edit: translate('asset_edit_asset_button'),
      info: translate('edit_asset_error'),
      accept: translate('accept_button'),
    };
  }, []);
  const translationDictionary = {
    Error: translate('error_status'),
    Success: translate('success_status'),
    'In progress': translate('in_progress_status'),
  };
  const getTranslatedStatus = (status: string) => {
    if (status && status in translationDictionary) {
      return translationDictionary[status as keyof typeof translationDictionary];
    }
    return status;
  };
  return (
    <DodgeModal open={open} onClose={onClose} title={componentText.edit} width='37.5rem'>
      <Grid container spacing={2} mt={1}>
        <Grid item container xs={12}>
          {summaryData &&
            summaryData.length &&
            summaryData.map((sensor) => {
              const status = getStatusDetails(sensor.status);
              return (
                <Grid
                  key={sensor.serialNumber}
                  item
                  container
                  xs={12}
                  fontWeight='600'
                  fontSize='0.875rem'
                  color={theme.palette.primary.main}
                >
                  <Grid
                    item
                    xs={5}
                    container
                    alignItems='center'
                  >{`${sensor.serialNumber}, ${sensor.location}`}</Grid>
                  <Grid
                    item
                    xs={0.5}
                    container
                    alignItems='center'
                    height='2rem'
                    color={status.color}
                  >
                    {status.icon}
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    container
                    alignItems='center'
                    height='2rem'
                    color={status.color}
                  >
                    {getTranslatedStatus(status.name)}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
        {summaryData && summaryData.length && summaryData.some((s) => s.status === 'error') && (
          <>
            <Grid item container xs={12} color={alarmColor} fontSize='.75rem'>
              {componentText.info}
            </Grid>
            <Grid item container xs={12}>
              <Divider sx={{ width: '100%' }} />
            </Grid>
          </>
        )}
        <Grid item container xs={12} justifyContent='flex-end'>
          <Button
            data-testid='plants_management_edit_asset_summary_accept_button'
            variant='contained'
            color='secondary'
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            onClick={() => onClose()}
          >
            {componentText.accept}
          </Button>
        </Grid>
      </Grid>
    </DodgeModal>
  );
};

export default EditSummary;

function getStatusDetails(status: string) {
  switch (status) {
    case 'error':
      return {
        name: 'Error',
        color: alarmColor,
        icon: <ErrorOutlineIcon sx={{ fontSize: '1rem' }} />,
      };
    case 'success':
      return {
        name: 'Success',
        color: okColor,
        icon: <CheckCircleOutlineIcon sx={{ fontSize: '1rem' }} />,
      };
    default:
      return { name: 'In progress', color: undefined, icon: undefined };
  }
}
