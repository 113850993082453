import moment, { MomentSetObject } from "moment-timezone";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectAutoTimezone, selectTimezone } from "store/accountSlice";

//react-datepicker always uses local machine timezone
//this hook is created to create Date objects that have added timezone offsets
//keep in mind that Date objects always have UTC time so we should use this hook only for edge cases like this
//using Date objects created that way outside date pickers might cause invalid time calculations
const useDatePickerConverter = () => {
    const currentTimeZone = useSelector(selectTimezone);
    const isAutoTimezone = useSelector(selectAutoTimezone);

    //this function should be used directly before giving Date object to date picker (startDate/endDate/maxDate)
    //it adds calculated offset to Date object, so given values should be displayed with proper time offset
    const convertDateToTimezone = useCallback((date: Date | null | undefined) => {
        if (!date) {
            return date;
        }
        if (isAutoTimezone) {
            return date;
        }

        const timezoneOffset = moment.tz(currentTimeZone).utcOffset();

        //moment.utcOffset and Date.getTimezoneOffset gives opposite values
        //example: for UTC + 01 moment.utcOffset gives 120 and Date.getTimezoneOffset gives -120
        return new Date(date.getTime() + ((timezoneOffset + date.getTimezoneOffset()) * 60000));
    }, [isAutoTimezone, currentTimeZone]);

    //this function should be used when we are receiving values from DatePicker (onChange)
    //because displayed values are shifted, we should restore original UTC values by substracting offset
    const convertDateFromTimezone = useCallback((date: Date | null | undefined) => {
        if (!date) {
            return date;
        }
        if (isAutoTimezone) {
            return date;
        }

        const timezoneOffset = moment.tz(currentTimeZone).utcOffset();

        return new Date(date.getTime() - ((timezoneOffset + date.getTimezoneOffset()) * 60000));
    }, [isAutoTimezone, currentTimeZone]);

    const setCustomHourInTimezone = useCallback((date: Date | string, val: MomentSetObject) => {
        console.log("auto", isAutoTimezone, "current", currentTimeZone, date, val)
        if (isAutoTimezone) {
            return moment(date).local().set(val);
        }
        return moment.tz(date, currentTimeZone).set(val);
    }, [currentTimeZone, isAutoTimezone]);

    return {
        convertDateToTimezone: convertDateToTimezone,
        convertDateFromTimezone: convertDateFromTimezone,
        setCustomHourInTimezone: setCustomHourInTimezone
    };
}

export default useDatePickerConverter;