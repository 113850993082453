import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export const ModalHeader = (props:{name:string}) =>{

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      editLabel: translate("menu_edit_button"),
    };
  }, []);
  
  return (
    <Box display="flex" alignItems="center" >{componentText.editLabel}<ArrowForwardIosIcon fontSize="small"/> {props.name}</Box>
  );
   
}