import { Box, Button, Divider, Link, Typography } from '@mui/material';
import style from './styles/Help.module.scss';
import { memo, useMemo } from 'react';
import { useInformations } from './informations';
import { fontMedium, fontSubDesc, fontTitle } from './styles/fontStyle';
import Resources from './Resources';
import { scroller } from './styles/scrollbar';
import { useTranslation } from 'languages';
import InformationComponent from './InformationComponent';
const Help = () => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => { 
    return {
      contactHeader: translate('menu_contact_header'),
    };
  }, []);

  const { informations } = useInformations();

  return (
    <Box sx={{ ...scroller}}>
      <Resources />
      <Box display='flex' alignItems='center' flexDirection='column' mr='.4rem'>
        <Typography sx={{ ...fontTitle }} width='100%' pl={1} pb='1rem'>
          {componentText.contactHeader}
        </Typography>
        <Divider sx={{ mb: '1rem', width: '100%' }} />
      </Box>
      <Box display='flex' justifyContent='center' mr='.4rem'>
        {informations.map((item) => (
          <InformationComponent key={item.title} item={item} />
        ))}
      </Box>
    </Box>
  );
};

export default memo(Help);
