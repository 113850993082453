import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { usePostApiKey } from 'api/users/usePostApiKey';
import { useTranslation } from 'languages';
import { memo, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

type Props = {
  refetchLoading: boolean;
  onClose: () => void;
};

const CreateView = (props: Props) => {
  const [name, setName] = useState<string>('');
  // Post api key
  const { mutate: postApiKeyMutation, isPending: postLoading } = usePostApiKey();

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successToast: translate('successfully_created_api_key_toast'),
      errorToast: translate('failed_to_create_api_key_toast'),
      chooseNameForNewApiKey: translate('choose_name_for_new_api_key_api'),
      name: translate('name_api'),
      cancel: translate('cancel_api'),
      create: translate('create_api'),
    };
  }, []);

  const handleCreateApiKey = () => {
    postApiKeyMutation(
      {
        body: {
          name: name,
        },
      },
      {
        onSuccess: () => {
          toast.success(`${componentText.successToast} ${name}`);
          props.onClose();
        },
        onError: () => {
          toast.error(`${componentText.errorToast} ${name}`);
        },
      },
    );
  };
  return (
    <>
      <Grid container>
        <Grid item my={1} xs={12}>
          <Typography variant='body1'>{componentText.chooseNameForNewApiKey}</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size='small'
            variant='outlined'
            label={componentText.name}
            value={name}
            data-testid='new_api_key_name_textfield'
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={postLoading}
          />
        </Grid>
        <Grid item xs={12} container mt={30} justifyContent={'space-between'}>
          <Grid item>
            <Button
              variant='outlined'
              size='large'
              color='primary'
              onClick={props.onClose}
              data-testid='new_api_cancel_button'
            >
              {componentText.cancel}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant='contained'
              size='large'
              color='primary'
              onClick={handleCreateApiKey}
              loading={postLoading || props.refetchLoading}
              disabled={!name}
              data-testid='new_api_create_button'
            >
              {componentText.create}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(CreateView);
