import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Autocomplete, LoadingButton } from '@mui/lab';
import {
  AutocompleteRenderInputParams,
  Divider,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useGetAssetGroups } from 'api/asset-groups/useGetAssetGroups';
import { AssetInfo, useGetAssets } from 'api/assets';
import { useSensorTransfer } from 'api/plants/useSensorTransfer';
import { Asset, AssetGroup } from 'features/plants-management/models/asset.model';
import { useTranslation } from 'languages';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
interface SelectionPageProps {
  serialNumber: string;
  oldAssetId: number;
  onAssetTransferSuccess: (assetName: string) => void;
}

const SelectionPage = ({
  serialNumber,
  oldAssetId,
  onAssetTransferSuccess,
}: SelectionPageProps) => {
  const [selectedAssetGroup, setSelectedAssetGroup] = useState<AssetGroup | null>();
  const [selectedAsset, setSelectedAsset] = useState<AssetInfo | null>();
  const { data: assetGroups = [] } = useGetAssetGroups();
  const { data: assets } = useGetAssets();
  const filteredAssets = useMemo(
    () =>
      assets
        ? assets.records.filter(
            (asset) => asset.assetGroupId === selectedAssetGroup?.id && asset.id !== oldAssetId,
          )
        : [],
    [assets, selectedAssetGroup, oldAssetId],
  );
  const successTransferHandler = useCallback(() => {
    onAssetTransferSuccess(selectedAsset!.name);
  }, [selectedAsset, onAssetTransferSuccess]);
  const { mutate: transferSensorMutation, isPending } = useSensorTransfer(successTransferHandler);

  const transferSensorHandler = useCallback(() => {
    if (!selectedAsset) {
      return;
    }
    transferSensorMutation({
      params: {
        newAssetId: selectedAsset?.id,
        serialNumber: serialNumber,
        oldAssetId: oldAssetId,
      },
    });
  }, [selectedAsset, oldAssetId, serialNumber, transferSensorMutation]);

  const setSelectedAssetGroupHandler = useCallback(
    (_: SyntheticEvent<Element, Event>, assetGroup: AssetGroup | null) => {
      setSelectedAsset(null);
      setSelectedAssetGroup(assetGroup);
    },
    [],
  );
  const setSelectedAssetHandler = useCallback(
    (_: SyntheticEvent<Element, Event>, asset: AssetInfo | null) => {
      setSelectedAsset(asset);
    },
    [],
  );
  const getAssetGroupName = useCallback((assetGroup: AssetGroup) => assetGroup.name, []);
  const getAssetName = useCallback((asset: AssetInfo) => asset.name, []);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      transferSensorContent: translate('transfer_sensor_content'),
      transferButton: translate('asset_transfer_asset_button'),
      assetGroupLabel: translate('asset_group_label'),
      assetLabel: translate('asset_label'),
    };
  }, []);

  const renderAssetGroupInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label={componentText.assetGroupLabel} />
    ),
    [],
  );
  const renderAssetInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label={componentText.assetLabel} />
    ),
    [],
  );

  return (
    <>
      <Typography sx={{ mt: '1rem', mb: '2rem' }}>{componentText.transferSensorContent}</Typography>
      <FormControl fullWidth>
        <Autocomplete
          data-testid='plants_management_modal_transfer_sensor_asset_group'
          options={assetGroups}
          onChange={setSelectedAssetGroupHandler}
          getOptionLabel={getAssetGroupName}
          value={selectedAssetGroup}
          renderInput={renderAssetGroupInput}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: '1rem' }}>
        <Autocomplete
          data-testid='plants_management_modal_transfer_sensor_asset_list'
          key={selectedAssetGroup?.id}
          options={filteredAssets}
          onChange={setSelectedAssetHandler}
          getOptionLabel={getAssetName}
          value={selectedAsset}
          renderInput={renderAssetInput}
        />
      </FormControl>
      <Divider sx={{ mt: '2rem' }} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          mt: '1rem',
        }}
      >
        <LoadingButton
          variant='contained'
          color='secondary'
          disabled={!selectedAsset}
          loading={isPending}
          endIcon={<SwapHorizIcon />}
          onClick={transferSensorHandler}
          data-testid='plant_management_modal_transfer_button'
        >
          {componentText.transferButton}
        </LoadingButton>
      </Box>
    </>
  );
};

export default SelectionPage;
