import { Box, Button, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Fullscreen, Delete } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import { useGetImageByAsset } from 'api/dashboard/assets';
import { useUpdateAssetImage } from 'api/plants/useUpdateAssetImages';
import { useDeleteAssetImage } from 'api/plants/useDeleteAssetImage';
import SelectDataPrompt from 'features/assets-management/components/SelectDataPrompt/SelectDataPrompt';
import { RoleRequired } from 'components/RoleRequired';
import { useDeviceSize } from 'shared/responsive';
import { useTranslation } from 'languages';

interface ImageMainProps {
  selectedImage: string;
  selectedAsset: number;
  defaultPhoto: string | null;
  defaultNameplate: string | null;
  selectedImageType: string;
  photosLength: number;
  nameplatesLength: number;
  onImageDelete: () => void;
}

const ImageMain: FC<ImageMainProps> = ({
  selectedImage,
  selectedAsset,
  defaultPhoto,
  defaultNameplate,
  selectedImageType,
  photosLength,
  nameplatesLength,
  onImageDelete,
}) => {
  const [expand, setExpand] = React.useState(false);
  const handleExpand = () => setExpand(true);
  const handleCloseExpand = () => setExpand(false);

  const [imageUrl, setImageUrl] = useState('');

  const {
    data: imageBlob,
    isLoading: imageLoading,
    isSuccess: imageSuccess,
  } = useGetImageByAsset(selectedAsset, selectedImage);

  useEffect(() => {
    if (!imageLoading && imageBlob && imageSuccess) {
      setImageUrl(URL.createObjectURL(imageBlob));
    }
  }, [imageBlob]);

  const { mutate: updateAssetImage } = useUpdateAssetImage(
    selectedAsset,
    selectedImage,
    selectedImageType,
  );

  const changeDefaultImage = () => {
    updateAssetImage({
      params: {
        assetId: selectedAsset,
        image: selectedImage,
        type: selectedImageType,
      },
    });
  };

  const { mutate: deleteAssetImage, isSuccess: isSuccessDeleteImage } = useDeleteAssetImage(
    selectedAsset,
    selectedImage,
    onImageDelete,
  );
  const handleDeleteImage = () => {
    deleteAssetImage({
      params: {
        assetId: selectedAsset,
        image: selectedImage,
      },
    });
  };
  const [displayImage, setDisplayImage] = useState(true);
  useEffect(() => {
    setDisplayImage(false);
  }, [isSuccessDeleteImage]);
  useEffect(() => {
    setDisplayImage(true);
  }, [selectedImage]);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
  return {
    noImages: translate("no_images_info"),
    selectImage : translate("select_image_info"),
    selectAsDefault  : translate("select_as_default"),
    default : translate("default_label"),
    expand : translate("assets_expand_label")
  };
}, []);

  const isDefault =
    (defaultPhoto && defaultPhoto === selectedImage) ||
    (defaultNameplate && defaultNameplate === selectedImage)
      ? true
      : false;
  const isImages = !photosLength && !nameplatesLength ? false : true;
  const { isPhone, isTablet } = useDeviceSize();
  const responsiveProps = {
    modalHeight: isTablet || isPhone ? '15rem' : '20rem',
    boxHeight: isTablet || isPhone ? '15rem' : '27.5rem',
  };
  return displayImage && isImages && selectedImage ? (
    <Box
      width='98%'
      height={responsiveProps.boxHeight}
      sx={{
        m: 0,
        p: 0,
        border: 'none',
        position: 'relative',
        borderRadius: '16px',
        background: `url(${imageUrl}) 0/cover no-repeat`,
      }}
    >
      {isDefault && (
        <Box
          sx={{
            p: '0 15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ml: '-1px',
            position: 'absolute',
            bottom: -1,
            background: '#E0EAEB',
            height: '50px',
            width: '100.2%',
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
          }}
        >
          <Typography color='primary' fontWeight='600'>
            {componentText.default}
          </Typography>
          <InfoIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
        </Box>
      )}
      {selectedImage && (
        <Grid
          spacing={1}
          container
          alignItems='center'
          justifyContent='flex-end'
          sx={{ position: 'absolute', right: 0, height: '80px', pr: '15px' }}
        >
          <Grid item>
            <Button variant='contained' onClick={changeDefaultImage} disabled={isDefault}>
              {componentText.selectAsDefault}
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' startIcon={<Fullscreen />} onClick={handleExpand}>
              {componentText.expand}
            </Button>
            <Modal open={expand} onClose={handleCloseExpand}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <CardMedia
                  component='img'
                  image={imageUrl}
                  alt='image'
                  sx={{
                    minWidth: '80rem',
                    height: 'auto',
                    borderRadius: '0.8rem',
                    objectFit: 'contain',
                    pointerEvents: 'none',
                  }}
                />
              </Box>
            </Modal>
          </Grid>
          <RoleRequired roles={['Super Viewer', 'Plant Viewer']} mode='blacklist'>
            <Grid item>
              <IconButton
                sx={{
                  color: 'white',
                  borderRadius: '4px',
                  background: '#327179',
                  '&:hover': { background: '#004851' },
                }}
                onClick={handleDeleteImage}
              >
                <Delete sx={{ fontSize: '1.35rem' }} />
              </IconButton>
            </Grid>
          </RoleRequired>
        </Grid>
      )}
    </Box>
  ) : (
    <Box width='98%' height={responsiveProps.modalHeight}>
      <SelectDataPrompt
        infoText={
          isImages
            ? componentText.selectImage
            : componentText.noImages
        }
      />
    </Box>
  );
};

export default memo(ImageMain);
