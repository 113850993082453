export const RMSDataType = {
  default: [
    {axis: "Velocity-Z", label: "Radial Velocity"},
    {axis: "Acceleration-Z", label: "Radial Acceleration"},
  ],
  triAxisMode: [
    {axis: "Velocity-X", label: "Axial Velocity"},
    {axis: "Velocity-Y", label: "Tangential Velocity"},
    {axis: "Velocity-Z", label: "Radial Velocity"},
    {axis: "Acceleration-X", label: "Axial Acceleration"},
    {axis: "Acceleration-Y", label: "Tangential Acceleration"},
    {axis: "Acceleration-Z", label: "Radial Acceleration"},
  ]
}