import LanguageIcon from '@mui/icons-material/Language';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useGetTimezoneSettings } from 'api/users/useGetTimezoneSettings';
import { usePutTimezoneSettings } from 'api/users/usePutTimezoneSettings';
import { useGetAuthData } from 'auth/hooks';
import { Loading } from 'components';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { useTranslation } from 'languages';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useDeviceSize } from 'shared/responsive';
import { PersonalInfo, languages } from './models/models';
import { fontMedium } from './styles/fontStyle';
import moment from 'moment';
import 'moment/locale/es';

interface UserProfileProps {
  isEditMode: boolean;
  isConfirmOpen: boolean;
  isLoading: boolean;
  email: string;
  personalInfo: PersonalInfo;
  setPersonalInfo: (newInfo: PersonalInfo) => void;
  onBackClick: () => void;
  onConfirmClick: () => void;
}

const UserProfile: FC<UserProfileProps> = ({
  personalInfo,
  email,
  isEditMode,
  isConfirmOpen,
  isLoading,
  setPersonalInfo,
  onBackClick,
  onConfirmClick,
}) => {
  const { userEmail } = useGetAuthData();
  const { isPhone } = useDeviceSize();
  const { data, isLoading: timezoneSettingsLoading } = useGetTimezoneSettings();
  const [currentTimezone, setCurrentTimezone] = useState<[string, string] | null>(null);
  const [autoTimezoneEnabled, setAutoTimezoneEnabled] = useState(true);
  const { mutate, isPending: saveTimezoneLoading } = usePutTimezoneSettings();
  const { setGlobalLanguage, GLOBAL_LANGUAGE, translate } = useTranslation();

  const handleSaveTimezone = () => {
    if (!currentTimezone) {
      return;
    }
    mutate({
      body: {
        timezone: currentTimezone[0],
        autoTimezone: autoTimezoneEnabled,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setCurrentTimezone(
        Object.entries(data.timezones).find((timezone) => timezone[0] === data.timezone) ?? null,
      );
      setAutoTimezoneEnabled(data.autoTimezone);
    }
  }, [data]);

  const changePasswordUrl: string = `https://${process.env.REACT_APP_B2C_TENANT}.b2clogin.com/${
    process.env.REACT_APP_B2C_TENANT
  }.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_WEB_RESET&client_id=${
    process.env.REACT_APP_B2C_CLIENT_ID
  }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
    `${process.env.REACT_APP_DOMAIN}/`,
  )}&scope=${encodeURIComponent(
    `https://${process.env.REACT_APP_B2C_TENANT}.onmicrosoft.com/${process.env.REACT_APP_B2C_API_SCOPE}`,
  )}&response_type=token&login_hint=${userEmail}&prompt=login`;

  const componentText = {
    surnameLabel: translate('menu_surname_label'),
    nameLabel: translate('menu_name_label'),
    emailLabel: translate('menu_email_label'),
    passwordLabel: translate('menu_password_label'),
    changePasswordText: translate('menu_change_password_text'),
    logoutWarningText: translate('menu_logout_warning_text'),
    currentDateAndTimeLabel: translate('menu_current_date_and_time_label'),
    autoTimezoneLabel: translate('menu_auto_timezone_label'),
    setTimezoneLabel: translate('menu_set_timezone_label'),
    languageLabel: translate('menu_language_label'),
    languageSettingsLabel: translate('menu_language_settings_label'),
    languageSettingsSubtitle: translate('menu_language_settings_subtitle'),
    changePasswordButton: translate('menu_change_password_button'),
    logoutButton: translate('menu_logout_button'),
    confirmButtonText: translate('menu_confirm_button_text'),
    backButtonLabel: translate('menu_back_button_label'),
    saveChangesButton: translate('menu_save_changes_button'),
    autoTimezoneDescription: translate('menu_auto_timezone_description'),
    timezoneSettingsLabel: translate('menu_timezone_settings_label'),
    currentDateTimeSettingsLabel: translate('menu_current_date_and_time_settings_label'),
    enabledButtonText: translate('menu_enabled_button_text'),
    disabledButtonText: translate('menu_disabled_button_text'),
  };

  const changeLanguage = () => {};

  return timezoneSettingsLoading || !data ? (
    <Grid container direction='column'>
      <Grid item container>
        <Loading height='20rem' />
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container direction='column'>
        <Grid item container>
          <Grid item xs={5}>
            <Typography
              py='0.8rem'
              data-testid='firstname_label'
              color={'primary'}
              fontWeight={'bold'}
            >
              {componentText.nameLabel}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isEditMode ? (
              <TextField
                data-testid='dashboard_manage_account_user_profile_firstname_input'
                fullWidth
                size='small'
                value={personalInfo.name}
                onChange={(e) => setPersonalInfo({ ...personalInfo, name: e.target.value })}
              />
            ) : (
              <Typography
                sx={{ ...fontMedium }}
                py='0.8rem'
                data-testid='firstname_value_label'
                className='user-profile-name-container'
              >
                {personalInfo.name}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={5}>
            <Typography
              py='0.8rem'
              data-testid='surname_label'
              color={'primary'}
              fontWeight={'bold'}
            >
              {componentText.surnameLabel}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isEditMode ? (
              <TextField
                data-testid='dashboard_manage_account_user_profile_surname_input'
                fullWidth
                size='small'
                value={personalInfo.surname}
                onChange={(e) => setPersonalInfo({ ...personalInfo, surname: e.target.value })}
              />
            ) : (
              <Typography
                sx={{ ...fontMedium }}
                py='0.8rem'
                data-testid='surname_value_label'
                className='user-profile-surname-container'
              >
                {personalInfo.surname}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={5}>
            <Typography py='0.8rem' data-testid='email_label' color={'primary'} fontWeight={'bold'}>
              {componentText.emailLabel}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{ ...fontMedium }}
              py='0.8rem'
              data-testid='email_value_label'
              className='user-profile-email-container'
            >
              {email}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={5}></Grid>
          <Grid item xs={6}>
            <Button variant='contained' color='primary' size='small' fullWidth>
              <a href={changePasswordUrl}>{componentText.changePasswordButton}</a>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DodgeModal
        open={isConfirmOpen}
        title={componentText.logoutWarningText}
        hideCloseIco
        width={'45rem'}
      >
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant='body1' pt={3}>
              {componentText.logoutWarningText}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <Button
                variant='outlined'
                color='secondary'
                size='medium'
                sx={{ fontWeight: 'bold', color: 'text.primary' }}
                onClick={() => onBackClick()}
              >
                {componentText.backButtonLabel}
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                data-testid='manage_account_user_profile_modal_confirm_button'
                variant='contained'
                color='secondary'
                size='medium'
                sx={{ fontWeight: 'bold', color: 'text.primary' }}
                onClick={() => onConfirmClick()}
                loading={isLoading}
              >
                {componentText.confirmButtonText}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </DodgeModal>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '1.25rem',
          margin: isPhone ? '2rem 0 0' : '2rem 0 0 -0.5rem',
        }}
      >
        {componentText.timezoneSettingsLabel}
      </Typography>
      <Typography
        sx={{ fontSize: '0.8125rem', opacity: '0.6', ml: isPhone ? undefined : '-0.5rem' }}
      >
        {componentText.currentDateTimeSettingsLabel}
      </Typography>
      <Divider sx={{ ml: '-0.5rem' }} />
      <Grid container>
        <Grid item xs={5}>
          <Typography
            py='0.8rem'
            fontSize='0.825rem'
            data-testid='firstname_label'
            color='primary'
            fontWeight='bold'
          >
            {componentText.currentDateAndTimeLabel}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize='0.825rem' py='0.8rem'>
            <CurrentTime />
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5} py='0.8rem'>
          <Typography
            fontSize='0.825rem'
            data-testid='firstname_label'
            color='primary'
            fontWeight='bold'
          >
            {componentText.autoTimezoneLabel}
          </Typography>
          <Typography
            fontSize='0.75rem'
            sx={{
              opacity: '0.6',
              ml: isPhone ? undefined : '-0.5rem',
            }}
          >
            {componentText.autoTimezoneDescription}
          </Typography>
        </Grid>
        <Grid item xs={6} pt='0.2rem'>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography fontSize='0.825rem'>{componentText.disabledButtonText}</Typography>
            <Switch
              checked={autoTimezoneEnabled}
              onChange={(_, val) => {
                setAutoTimezoneEnabled(val);
                if (val) {
                  setCurrentTimezone(
                    Object.entries(data.timezones).find(
                      (timezone) => timezone[0] === data.timezone,
                    ) ?? null,
                  );
                }
              }}
            />
            <Typography fontSize='0.825rem'>{componentText.enabledButtonText}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <Typography
            pt='0.4rem'
            fontSize='0.825rem'
            data-testid='firstname_label'
            color='primary'
            fontWeight='bold'
          >
            {componentText.setTimezoneLabel}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            data-testid='manage_account_time_zone_input'
            disabled={autoTimezoneEnabled}
            disableClearable={!!currentTimezone}
            disablePortal
            sx={{
              '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                fontSize: '0.725rem',
              },
            }}
            size='small'
            value={currentTimezone}
            onChange={(_, val) => setCurrentTimezone(val)}
            options={Object.entries(data.timezones)}
            getOptionLabel={(val) => val[1]}
            isOptionEqualToValue={(option, val) => option[0] === val[0]}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ '& input': { fontSize: '0.725rem' } }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LanguageIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '1.25rem',
          margin: isPhone ? '2rem 0 0' : '2rem 0 0 -0.5rem',
        }}
      >
        {componentText.languageSettingsLabel}
      </Typography>
      <Typography
        sx={{ fontSize: '0.8125rem', opacity: '0.6', ml: isPhone ? undefined : '-0.5rem' }}
      >
        {componentText.languageSettingsSubtitle}
      </Typography>
      <Divider sx={{ ml: '-0.5rem' }} />
      <Grid item container alignItems='center'>
        <Grid item xs={5} lg={5}>
          <Typography pt='0.4rem' fontSize='0.825rem' color='primary' fontWeight='bold'>
            {componentText.languageLabel}
          </Typography>
        </Grid>
        <Grid item xs={7} mt={2}>
          <FormControl sx={{ width: '86%' }}>
            <Select
              data-testid='manage_account_user_profile_modal_language_input'
              id='language-select'
              value={GLOBAL_LANGUAGE}
              onClick={changeLanguage}
              onChange={(e) => setGlobalLanguage(e.target.value as string)}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ height: 33.67 }}
            >
              {languages.map((language) => (
                <MenuItem value={language.value} key={language.value}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={language.icon} alt={language.value} style={{ maxWidth: '1.1rem' }} />
                    <Box
                      display='inline'
                      sx={{
                        textTransform: 'uppercase',
                        color: 'rgba(0, 0, 0, 0.38)',
                        fontSize: '0.875rem',
                      }}
                    >{`(${language.value})`}</Box>
                    <Box display='inline' sx={{ fontSize: '0.875rem' }}>
                      {language.label}
                    </Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pr: '2.8rem',
          mt: '1rem',
        }}
      >
        <LoadingButton
          data-testid='manage_account_save_changes_button'
          loading={saveTimezoneLoading}
          disabled={
            data.autoTimezone === autoTimezoneEnabled && data.timezone === currentTimezone?.[0]
          }
          variant='contained'
          size='small'
          onClick={handleSaveTimezone}
        >
          {componentText.saveChangesButton}
        </LoadingButton>
      </Box>
    </>
  );
};

const dateFormatDefault = 'h:mm a, dddd, MMMM DD, YYYY';

const CurrentTime = () => {
  const dateFormatter = useDateFormatter();
  const { GLOBAL_LANGUAGE, translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      dateFormat: translate("date_format_user_profile")
    };
  }, []); 
  
  moment.locale(GLOBAL_LANGUAGE as string)
  const newDate = moment(new Date()).toISOString()

  const [time, setTime] = useState(dateFormatter(newDate, componentText.dateFormat ?? dateFormatDefault));
 
  useEffect(() => {
    const interval = setInterval(() => setTime(dateFormatter(newDate, componentText.dateFormat ?? dateFormatDefault)), 1000);
    return () => clearInterval(interval);
  }, [dateFormatter, GLOBAL_LANGUAGE]);

  return <>{time}</>;
};

export default memo(UserProfile);
