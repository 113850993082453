import { UserRole } from 'features/plants-management/models/user.model';
import { FC, ReactNode } from 'react';
import { useRoleRequired } from './hooks';

type RoleRequiredProps = Readonly<{
  roles: UserRole[];
  elseTemplate?: ReactNode;
  allowExternalUsers?: boolean;
  mode?: 'whitelist' | 'blacklist';
  ignoreGlobalRoles?: boolean;
}>;

/**
This component restricts of displaying specific content inside of it for specific User Roles.
@param {UserRole} roles - Array of roles which should be able / unable to see specific content.
@param {ReactNode} elseTemplate - Content which should be displayed when role requirements are not met.
@param {string} mode - whitelist or blacklist roles passed in roles array.
**/
const RoleRequired: FC<RoleRequiredProps> = ({
  roles,
  children,
  elseTemplate,
  mode = 'whitelist',
  ignoreGlobalRoles = false,
}) => {
  const { haveUserRole } = useRoleRequired();

  const haveRole = haveUserRole(roles, mode, ignoreGlobalRoles);

  return (
    <>
      {haveRole && children}
      {!haveRole && elseTemplate}
    </>
  );
};

export default RoleRequired;
