import { useAuthMutation } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

export interface AcceptInvitationBody {
  plantId: number;
}

export const useAcceptInvitation = () => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('successfully_accepted_invitation_to_plant_toast'),
      errorMess: translate('failed_to_accept_invitation_toast'),
    };
  }, []);
  return useAuthMutation<{}, AcceptInvitationBody>({
    url: `users/roles`,
    method: 'PUT',

    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.successMess);
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
