import { useAuthMutation } from "api/queries";
import { useTranslation } from "languages";
import { useMemo } from "react";
import { toast } from "react-toastify";

interface PurgeIntellilubeParams {
  assetId: number;
  serialNumber: string;
  purgesCount: number;
}

export const usePurgeIntellilube = (onSuccess?: () => void) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess : translate("selected_sensor_purged_successfully_toast"),
      errorMess : translate("failed_to_purge_selected_sensor_toast")
    };
  }, []);
  return useAuthMutation<PurgeIntellilubeParams, {}>({
    url: (params: PurgeIntellilubeParams) =>
      `assets/${params.assetId}/sensors/${params.serialNumber}/purge?purgesCount=${params.purgesCount}`,
    method: "POST",
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.successMess);
        onSuccess?.();
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};