import { FC, useEffect, useMemo, useState } from "react";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { Sensor } from "features/gateways-management/components/SensorAssignmentTable/models/SensorAssignment.models";
import { SelectedData } from "./AssignSensors";
import { useTranslation } from "languages";

type Props = {
    sensors:Sensor[],
    selectedIds: number[], 
    setSelectedIds: (ids: number[]) => void;
    selectedData: SelectedData[];
    selectedGatewayId?: string;
}

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const comma = ', ';

const SensorsList:FC<Props> = ({sensors, selectedIds, setSelectedIds, selectedData, selectedGatewayId}) =>{

    const [sensorName, setSensorName] = useState<string[]>(selectedIds ? sensors.filter(s => selectedIds.includes(s.id)).map( sensor => sensor.serialNumber.toString() + comma + sensor.location) : []); 

    const handleChange = (event: SelectChangeEvent<typeof sensorName>) => {
        const {
          target: { value },
        } = event;
        setSensorName(
          typeof value === 'string' ? value.split(',') : value,
        );

        const selectedSensors = value as string[];
        setSensorName(selectedSensors);

        const selectedIds = sensors
        .filter((sensor) => selectedSensors.includes(sensor.serialNumber.toString() + comma + sensor.location ))
        .map((sensor) => sensor.id);

        setSelectedIds(selectedIds);
      };

      const { translate } = useTranslation();
      const componentText = useMemo(() => {
        return {
          selectSensors: translate("select_sensors")
        };
      }, []);

    return(
       <FormControl fullWidth disabled={!Boolean(sensors.length)}>
        <InputLabel id="sensor-list-label">{componentText.selectSensors}</InputLabel>
        <Select
          labelId="sensor-list-label"
          data-testid="plant_managements_assign_select_sensors"
          id="sensors-multiple-checkbox"
          multiple
          value={sensorName}
          onChange={handleChange}
          input={<OutlinedInput label={componentText.selectSensors} />}
          renderValue={(selected) =>
            (selected as string[]).map((value) => {
              const selectedSensor = sensors.find((sensor) => sensor.serialNumber.toString() + comma + sensor.location === value);
              return selectedSensor ? selectedSensor.serialNumber  + comma + selectedSensor.location : '';
            }).join(' | ')
          }
          MenuProps={MenuProps}
        >
          {sensors.map((sensor) => (
            <MenuItem key={sensor.id} value={sensor.serialNumber  + comma + sensor.location}>
              <Checkbox checked={sensorName.indexOf(sensor.serialNumber.toString()  + comma + sensor.location) > -1} />
              <ListItemText primary={sensor.serialNumber.toString() + comma + sensor.location} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
}

export default SensorsList;