import { CheckCircleOutline, ErrorOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  CommissioningStatus,
  CommissioningStatusErrors,
  CommissioningStatusFinished,
  useGetCommissionStatus,
} from 'api/gateways/useGetCommissionStatus';
import { setSensorCommissionStatus } from 'features/plants-management/store/plantsManagementSlice';
import { ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DisplayType } from '../AssetsTable/EditAsset/EditAsset';
import { useTranslation } from 'languages';
import { useGetCommissionStatusTranslated } from './useGetcommissionStatusTranslated';

interface Props {
  sensors: {
    serialNumber: string;
    location: string;
  }[];
  gatewayId: string;
  onClose?: () => void;
  setForceOpen?: (forceOpen: boolean) => void;
  onAssignSensors?: (display: DisplayType) => void;
}

interface Styling {
  icon: ReactNode;
  textColor: string;
}

type StatusStyling = {
  [P in CommissioningStatus]: Styling;
};

const statusStyling: StatusStyling = {
  Pending: {
    icon: (
      <div style={{ marginLeft: '0.25rem' }}>
        <CircularProgress size={16} color={'primary'} />
      </div>
    ),
    textColor: 'primary',
  },
  Commissioning: {
    icon: (
      <div style={{ marginLeft: '0.25rem' }}>
        <CircularProgress size={16} color={'primary'} />
      </div>
    ),
    textColor: 'primary',
  },
  CommissioningFailed: {
    icon: (
      <div style={{ marginLeft: '0.25rem' }}>
        <ErrorOutlined fontSize='small' color='error' />
      </div>
    ),
    textColor: 'error',
  },
  Commissioned: {
    icon: (
      <div style={{ marginLeft: '0.25rem' }}>
        <CheckCircleOutline fontSize='small' htmlColor='#4CAF50' />
      </div>
    ),
    textColor: '#4CAF50',
  },
  FirmwareUpdating: {
    icon: (
      <div style={{ marginLeft: '0.25rem' }}>
        <CircularProgress size={16} color={'primary'} />
      </div>
    ),
    textColor: 'primary',
  },
  FirmwareUpdateFailed: {
    icon: (
      <div style={{ marginLeft: '0.25rem' }}>
        <ErrorOutlined fontSize='small' color='error' />
      </div>
    ),
    textColor: 'error',
  },
  MissingSensorFirmware: {
    icon: (
      <div style={{ marginLeft: '0.25rem' }}>
        <ErrorOutlined fontSize='small' color='error' />
      </div>
    ),
    textColor: 'error',
  },
  CannotConnect: {
    icon: (
      <div style={{ marginLeft: '0.25rem' }}>
        <ErrorOutlined fontSize='small' color='error' />
      </div>
    ),
    textColor: 'error',
  },
};

const CommissionMonitor = (props: Props) => {
  const dispatch = useDispatch();

  const [interval, setInterval] = useState<number>(3000);
  const [finished, setFinished] = useState<boolean>(false);

  const { data, isLoading } = useGetCommissionStatus({
    sensorsSN: props.sensors.map((s) => s.serialNumber),
    gatewayId: props.gatewayId,
    interval: interval,
  });

  useEffect(() => {
    if (data?.every((status) => CommissioningStatusFinished.includes(status.updateStatus))) {
      setInterval(0);
      setFinished(true);
      props.setForceOpen?.(false);
      dispatch(
        setSensorCommissionStatus(
          data
            .filter((s) => s.updateStatus === 'Commissioned')
            .map((s) => props.sensors.find((sensor) => sensor.serialNumber === s.serialNumber)),
        ),
      );
    } else {
      setInterval(3000);
      setFinished(false);
    }
  }, [data]);

  const handleConfirm = useCallback(() => {
    setInterval(0);
    if (data?.every((status) => CommissioningStatusErrors.includes(status.updateStatus))) {
      props.onClose?.();
      return;
    }
    props.onAssignSensors ? props.onAssignSensors?.('assign') : props.onClose?.();
  }, [props.onClose, props.onAssignSensors, data]);

const { translate } = useTranslation();
const getCommissionStatusTranslated = useGetCommissionStatusTranslated();
const componentText = useMemo(() => {
  return {
    info : translate("download_commissioned_sensors_list"),
    confirm : translate("menu_confirm_button_text"),
  };
}, []);
  return (
    <>
      <Grid container spacing={2} data-testid='plants_management_modal_window_commission_sensors'>
        <Grid item>
          <Typography variant='body1'>
            {componentText.info}
          </Typography>
        </Grid>
        <Grid item>
          {(isLoading || !data || data.every((s) => s.updateStatus === null)) && (
            <CircularProgress />
          )}
          {!isLoading && (
            <Box>
              {data?.map((s, index) => {
                const styling = statusStyling[s.updateStatus];
                return (
                  styling && (
                    <Grid container alignItems={'center'} key={index}>
                      <Typography
                        sx={{ mb: '0.4rem' }}
                        variant='subtitle1'
                        color={'primary'}
                        fontWeight={700}
                      >{`${s.serialNumber}, ${
                        props.sensors.find((sensor) => sensor.serialNumber === s.serialNumber)!
                          .location
                      } -  `}</Typography>{' '}
                      {styling.icon}{' '}
                      <Typography
                        sx={{ marginLeft: '0.2rem', mb: '0.3rem' }}
                        variant='subtitle1'
                        color={styling.textColor}
                        fontWeight={700}
                      >
                        {getCommissionStatusTranslated(s.updateStatus.replace(/([A-Z])/g, ' $1').trim())}
                      </Typography>
                    </Grid>
                  )
                );
              })}
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      {finished && (
        <>
          <Grid container justifyContent={'flex-end'}>
            <Grid item>
              <Button
                data-testid='plant_management_commission_modal_confirm_button'
                variant='contained'
                color='secondary'
                onClick={handleConfirm}
                sx={{ fontWeight: 700 }}
              >
                {componentText.confirm}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default memo(CommissionMonitor);
