import { Tooltip } from "@mui/material"
import { FC } from "react";
import { tooltipStyle } from "./SyncError";
import { useTranslation } from "languages";

type Props = {
  children: any;
}
const CartridgeInfoMissing: FC<Props> = ({ children }) => {
  const { translate } = useTranslation()
  return (
    <Tooltip
      title={translate("asset_tile_body_cartridge_info_missing_tooltip")}
      placement="top"
      componentsProps={{ tooltip: { sx: {...tooltipStyle, width:"19rem"} } }}
    >
      {children}
    </Tooltip>
  );
}

export default CartridgeInfoMissing;