import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface CookieState {
    blockUserPanel: boolean;
  }
  
  const initialState: CookieState = {
    blockUserPanel: false,
  };
  
 
  export const cookieSlice = createSlice({
    name: 'cookie',
    initialState,
    reducers: {
      changeBlockUserPanel: (state, action) => {
            state.blockUserPanel = action.payload;
        },
    },
  })
  
  export const {changeBlockUserPanel } = cookieSlice.actions;
   
  export const selectBlockUserPanel = (state: RootState) => state.cookie.blockUserPanel;

  export default cookieSlice.reducer;