import Plot from 'react-plotly.js';
import { PlotData, Layout, PlotMouseEvent, PlotHoverEvent } from 'plotly.js';
import { FFTData, Peak } from 'features/assets-management/models/FFTChart.models';
import { FC, useCallback, memo, useRef, MouseEvent, useMemo, useEffect } from 'react';
import { ChartTooltipData } from './ChartTooltip';
import theme from 'theme/theme';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { compareMeasurements } from './utils/compareMeasurements';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveAsset,
  selectActiveUnit,
  selectSelectedAssets,
  setTempNominalSpeed,
} from 'features/assets-management/store/assetsManagementSlice';
import { Asset } from 'features/assets-management/models';
import { useGetAxisLabel } from './useGetAxisLabel';

export interface HarmonicShape {
  type: 'rect';
  layer?: 'above' | 'below';
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  line: {
    color: string;
    width: number;
  };
  fillcolor: string;
  measurementDate: string;
}

const roundTo4SignificantDigits = (val: string) => {
  const parsed = Number(val);
  if (parsed > 999) {
    return parsed.toFixed(0);
  }
  return parsed.toPrecision(4);
};

interface FFTChartProps {
  xAxisLabel: string;
  yAxisLabel: string;
  chartData: FFTData[];
  type: string;
  setTooltipValue: (val: ChartTooltipData | undefined) => void;
  displayRunningSpeed: boolean;
  runningSpeedValues: { nominalSpeedFrequency: number; nominalSpeed: number };
  peaks: Peak[];
  harmonicShapes?: HarmonicShape[];
  marginTop?: number;
  height?: number;
  pr?: string;
  openTreeMenu?: boolean;
}

const FftChart: FC<FFTChartProps> = ({
  xAxisLabel,
  yAxisLabel,
  chartData,
  type,
  setTooltipValue,
  displayRunningSpeed,
  runningSpeedValues,
  harmonicShapes,
  peaks,
  marginTop,
  height,
  pr,
  openTreeMenu,
}) => {
  const dispatch = useDispatch();
  const { axisLabelTranslated: xAxisLabelTranslated } = useGetAxisLabel(xAxisLabel);
  const { axisLabelTranslated: yAxisLabelTranslated } = useGetAxisLabel(yAxisLabel);
  const { axisLabelTranslated: zAxisLabelTranslated } = useGetAxisLabel('Date');
  const dateFormatter = useDateFormatter();

  const asset: string = useSelector(selectActiveAsset);
  const selectedAssetInfo: Asset | undefined = useSelector(selectSelectedAssets).find(
    (obj) => obj.name === asset,
  );
  const unit = useSelector(selectActiveUnit);
  const mousePosDetection = useRef<MouseEvent<HTMLSpanElement>>();
  const dateLabelList: { date: string; i: number }[] = [];
  const fftData: Partial<PlotData>[] = chartData
    .sort(compareMeasurements)
    .map((data: FFTData, i) => {
      dateLabelList.push({
        date: dateFormatter(data.measurmentDate, 'MM/DD/YYYY|HH:mm'),
        i,
      });
      return {
        x: Array(data.data.length).fill(i),
        y: data.data.map((point) => {
          return point.time;
        }),
        z: data.data.map((point) => {
          return point.value;
        }),
        mode: 'lines',
        marker: {
          size: 24,
          symbol: 'circle',
          line: {
            width: 3,
          },
        },
        type: 'scatter3d',
        xaxis: 'x',
        yaxis: 'y',
        automargin: true,
        hoverinfo: 'none',
        showlegend: false,
      };
    });

  const showTooltip = useCallback(
    (event: Readonly<PlotMouseEvent>) => {
      if ('name' in event.points[0].data && event.points[0].data.name === 'speed') {
        setTooltipValue({
          xValue: 'Running speed [RPM]:',
          value: Number(event.points[0].data.hovertext),
          unit: '',
          xPos: event.event.clientX,
          yPos: event.event.clientY,
        });
        return;
      }

      const title =
        typeof event.points[0].yaxis.title === 'string'
          ? event.points[0].yaxis.title
          : event.points[0].yaxis.title.text!;

      const xUnit: string =
        typeof event.points[0].xaxis.title === 'string'
          ? event.points[0].xaxis.title
          : event.points[0].xaxis.title.text!;
      const unit = title.split('[')[1].replaceAll(']', '');
      const xValue = event.points[0].x?.toString();
      const yValue = event.points[0].y?.toString();

      const formattedXValue = xUnit.includes('CPM')
        ? String(Math.round(Number(xValue)))
        : String(Math.round(Number(xValue) * 10) / 10);

      setTooltipValue({
        xValue: xValue ? `${xUnit}: ${formattedXValue}` : undefined,
        value: yValue ? roundTo4SignificantDigits(yValue) : undefined,
        unit: unit,
        xPos: event.event.clientX,
        yPos: event.event.clientY,
      });
    },
    [setTooltipValue],
  );

  //For scatter3d chart with multiple active plots plotly does not return event Readonly<PlotMouseEvent>
  // Instead it returns object wit points array
  const showTooltip3d = useCallback(
    (event: Readonly<PlotHoverEvent>) => {
      const regex = /\[(.*?)\]/;
      const unitMatch = yAxisLabel.match(regex);
      const unit = unitMatch ? unitMatch[1] : '';
      setTooltipValue({
        xValue: xAxisLabel.includes('CPM')
          ? // @ts-expect-error Plotly event object has more properties in orthographic mode than is declared
            `Frequency [CPM]: ${Math.round(event.points[0].y)}`
          : // @ts-expect-error Plotly event object has more properties in orthographic mode than is declared
            `Frequency [Hz]: ${event.points[0].y.toFixed(2)}`,
        // @ts-expect-error Plotly event object has more properties in orthographic mode than is declared
        value: `${roundTo4SignificantDigits(event.points[0].z)} ${unit}`,
        unit: '',
        xPos: mousePosDetection.current?.clientX || 100,
        yPos: mousePosDetection.current?.clientY || 100,
        // @ts-expect-error Plotly event object has more properties in orthographic mode than is declared
        borderColor: event.points[0].fullData.line.color,
      });
    },
    [setTooltipValue, xAxisLabel, yAxisLabel],
  );

  const hideTooltip = useCallback(
    (_: Readonly<PlotMouseEvent>) => {
      setTooltipValue(undefined);
    },
    [setTooltipValue],
  );

  const FFTchartData: Partial<PlotData>[] = useMemo(() => {
    if (displayRunningSpeed && fftData.length) {
      const zData = fftData[0].z as number[];
      const minY = zData.reduce((min, val) => (val < min ? val : min), Infinity);
      const maxY = zData.reduce((max, val) => (val > max ? val : max), -Infinity);
      const lengthY = fftData[0].z?.length as number;
      const stepY = (maxY - minY) / (lengthY - 1);

      return fftData.concat({
        x: new Array(fftData[0].x?.length ?? 0).fill(runningSpeedValues.nominalSpeedFrequency),
        y: Array.from({ length: lengthY }, (_, i) => minY + i * stepY),
        type: 'scatter',
        mode: 'lines',
        showlegend: false,
        hoverinfo: 'none',
        hovertext: String(runningSpeedValues.nominalSpeed),
        name: 'speed',
        line: {
          shape: 'linear',
          color: theme.palette.secondary.main,
          width: 0.5,
        },
      });
    } else return fftData;
  }, [displayRunningSpeed, fftData]);

  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
  }, [pr, openTreeMenu]);

  // @ts-expect-error: ...
  const peaksData: Partial<PlotData>[] = useMemo(() => {
    if (peaks.length !== 1) {
      return [];
    }
    const max = Math.max(...peaks[0].data.map((e) => e.y));
    const markerTrace = peaks[0].data.map((el) => ({
      id: 'significant-peaks',
      x: [el.x],
      y: [el.y + (max * 4) / 100],
      type: 'scatter',
      mode: 'markers',
      showlegend: false,
      hoverinfo: 'none',
      marker: {
        symbol: 'square',
        color: 'white',
        size: 18,
        line: {
          width: 1.5,
          color: 'rgb(31, 119, 180)',
        },
      },
    }));

    const textTrace = peaks[0].data.map((el) => ({
      id: 'significant-peaks-text',
      x: [el.x],
      y: [el.y + (max * 4) / 100],
      type: 'scatter',
      mode: 'text',
      text: [el.name],
      textfont: { color: 'black', size: 10 },
      showlegend: false,
      hoverinfo: 'none',
      name: 'peaks',
    }));

    const circleTrace = peaks[0].data.map((el) => ({
      id: 'significant-peaks-circle',
      x: [el.x],
      y: [el.y],
      type: 'scatter',
      mode: 'markers',
      showlegend: false,
      hoverinfo: 'none',
      marker: {
        symbol: 'circle-open',
        color: 'rgb(31, 119, 180)',
        size: 3.2,
        line: {
          width: 1.5,
          color: 'rgb(31, 119, 180)',
        },
      },
    }));

    return [...markerTrace, ...textTrace, ...circleTrace];
  }, [peaks]);

  const onClick = (event: Readonly<PlotMouseEvent>) => {
    if (
      Object.prototype.hasOwnProperty.call(event.points[0].data, 'id') &&
      // @ts-expect-error incorrect plotly typing
      event.points[0].data.id.includes('significant-peaks') &&
      selectedAssetInfo?.nominalSpeed
    ) {
      const value = Number(event.points[0].data.x);
      if (unit === 'Hz') {
        dispatch(setTempNominalSpeed(Number(value) * 60));
      } else {
        dispatch(setTempNominalSpeed(Number(value)));
      }
    }
  };

  if (fftData.length === 1) {
    fftData[0].type = 'scatter';
    fftData[0].x = fftData[0].y;
    // @ts-expect-error
    fftData[0].y = fftData[0].z;

    const layout = {
      title: '',
      xaxis: { title: xAxisLabelTranslated },
      yaxis: { title: yAxisLabelTranslated },
      shapes: harmonicShapes,
      margin: {
        t: marginTop,
      },
    };

    return (
      <Plot
        data={[...FFTchartData, ...peaksData]}
        layout={layout}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
        onHover={showTooltip}
        onUnhover={hideTooltip}
        onClick={onClick}
      />
    );
  } else {
    const layout: Partial<Layout> = {
      title: '',
      autosize: true,
      showlegend: false,
      width: undefined,
      height: 650,
      margin: {
        t: marginTop,
      },
      scene: {
        xaxis: {
          title: `.<br><br><br><br>${zAxisLabelTranslated}`,
          type: 'category',
          range: [-1, fftData.length + 1],
          tickmode: 'array', // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
          tickvals: dateLabelList.map((el) => el.i),
          ticktext: dateLabelList.map((el) => el.date),
        },
        // .<br><br> is suggested directly by plotly team, because currently
        //there is no support for label offsets in 3d mode
        yaxis: { title: `.<br><br>${xAxisLabelTranslated}` },
        zaxis: { title: `.<br><br>${yAxisLabelTranslated}` },
        camera: {
          // @ts-expect-error
          projection: {
            type: 'orthographic',
          },
          eye: { x: 3, y: 2, z: 0.9 },
        },

        aspectmode: 'manual',
        aspectratio: {
          x: 3,
          y: 3,
          z: 1,
        },
      },
      shapes: harmonicShapes,
    };

    return (
      <span onMouseMove={(e) => (mousePosDetection.current = e)}>
        <Plot
          data={fftData}
          layout={layout}
          style={{ width: '100%', height: '100%' }}
          useResizeHandler
          config={{ responsive: true, scrollZoom: true, autosizable: true }}
          onHover={showTooltip3d}
          onUnhover={hideTooltip}
        />
      </span>
    );
  }
};

export default memo(FftChart);
