import * as React from 'react';
import { Box, Link, Typography, Grid } from '@mui/material';
import style from './CopyrightBar.module.scss';
import useDateFormatter from 'components/Uitls/useDateFormatter';

interface CopyrightBarProps {
  logged?: null | any;
}
const CopyrightBar: React.FC<CopyrightBarProps> = ({ logged }) => {
  const dateFormatter = useDateFormatter();
  // const isLogged = logged ? 'auth' : '';
  const privacyUrl: string = `${process.env.REACT_APP_DOMAIN}/privacy-and-cookie-notice`;
  const licenseUrl: string = `${process.env.REACT_APP_DOMAIN}/end-user-license-agreement`;
  return (
    <Box className={style.box}>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        sx={{ '@media (max-width: 650px)': { flexDirection: 'column', alignItems: 'flex-start' } }}
      >
        <Grid item order={2}>
          <Typography
            className={style.copyright}
            variant='subtitle2'
            sx={{
              mt: 1.4,
              mr: 2,
              'font-weight': 'bold',
              'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
              '@media (max-width: 650px)': { ml: 2 },
            }}
          >
            {`© Copyright ${dateFormatter(new Date(), 'YYYY')} Dodge Industrial, Inc.`}
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            sx={{
              mt: 1.6,
              ml: 2,
            }}
          >
            <Grid>
              <Typography
                className={style.policies}
                sx={{
                  mr: 1,
                  'letter-spacing': '1px',
                  'line-height': '1.5',
                  'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
                }}
              >
                <Link
                  href={privacyUrl}
                  underline='none'
                  color='#327179'
                  sx={{ color: '#327179 !important' }}
                >
                  PRIVACY AND COOKIE NOTICE
                </Link>{' '}
                |{' '}
                <Link
                  href={licenseUrl}
                  underline='none'
                  color='#327179'
                  sx={{ color: '#327179 !important' }}
                >
                  LICENSE AGREEMENT
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(CopyrightBar);
