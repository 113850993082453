import moment from 'moment-timezone';
import { Datum } from 'plotly.js';
import { useRef, useState, useEffect, ReactNode, FC } from 'react';
import styles from './ChartTooltip.module.scss';
import { createPortal } from 'react-dom';

export interface ChartTooltipData {
  date?: Date | string;
  value?: Datum | string;
  xValue?: Datum | string;
  unit?: string;
  name?: string;
  borderColor?: string;
  xPos: number;
  yPos: number;
  renderInModal?: boolean;
  infoText?: string | ReactNode;
  offset ?: number;
  renderInPortal ?: boolean;
}

const ChartTooltip : FC<ChartTooltipData> = ({
  date,
  yPos,
  xPos,
  name,
  value,
  unit,
  xValue,
  renderInModal,
  borderColor,
  infoText,
  offset,
  renderInPortal,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [xOffscreenCorrection, setXOffscreenCorrection] = useState(0);
  const [yOffscreenCorrection, setYOffscreenCorrection] = useState(0);
  const renderOffset = 5;
  const correctionSingleStep = 70;
  const formattedDate = date ? moment(date).format('MMM DD, YYYY HH:mm') : undefined;

  useEffect(() => {
    if (!tooltipRef.current) {
      return;
    }
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    if (tooltipRect.x + tooltipRect.width > window.innerWidth) {
      setXOffscreenCorrection((prevVal) => prevVal - correctionSingleStep);
    }
    if (tooltipRect.y + tooltipRect.height > window.innerHeight) {
      setYOffscreenCorrection((prevVal) => prevVal - correctionSingleStep);
    }
  }, [tooltipRef, xOffscreenCorrection, yOffscreenCorrection]);

  const tooltip = (
    <div
      ref={tooltipRef}
      className={styles.tooltip}
      style={{
        top: yPos + renderOffset + yOffscreenCorrection,
        left: xPos + renderOffset + xOffscreenCorrection,
        border: borderColor ? `1px solid ${borderColor}` : undefined,
        zIndex: renderInModal ? 10000 : undefined,
      }}
    >
      {infoText ? (
        <div style={{ maxWidth: '25rem' }}>{infoText}</div>
      ) : (
        <>
          {formattedDate && <div>{formattedDate}</div>}
          {xValue && <div>{xValue}</div>}
          {name && <div className={styles.bolded}>{`${name}${offset ? ' ↥' : ''}:`}</div>}
          <div className={styles.bolded}>
            {`${typeof value === 'number' && value ? parseFloat(value.toFixed(3)) : value} ${unit}`}
            {offset && typeof offset === 'number'
              ? ' (offset: ' + parseFloat(offset.toFixed(3)) + ' ' + unit + ')'
              : ''}
          </div>
        </>
      )}
    </div>
  );

  if (renderInPortal) {
    return createPortal(tooltip, document.body);
  }

  return tooltip;
};

export default ChartTooltip;
