import { Box, Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { alarmColor, okColor, warningColor } from "features/dashboard/colors/colors";
import { Gateway } from "features/gateways-management/components/SensorAssignmentTable/models/SensorAssignment.models";
import { FC, useEffect, useMemo, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "languages";
import { useGetSignalStrengthTranslated } from "./useGetSignalStrengthTranslated";

type Props = {
    id?:  string;
    setId: (id: string | undefined) => void;
    gateways?: Gateway[];
    selectedIds: number[];
    allowableGateways: string[];
    signalsData: {gatewayId: string, sumRssi: number, count: number}[];
}
const GatewaysList:FC<Props> = ({id, setId, gateways, selectedIds, allowableGateways, signalsData}) =>{

   const [gatewayName, setGatewayName] = useState<string | undefined>(id ? gateways?.find(g=>g.deviceId === id)?.name : undefined); // id ? gateways?.find(g=>g.deviceId === id)?.name : undefined

    const handleChange = (event: any) => {
        const {
          target: { value },
        } = event; 
        setGatewayName(value);
      };

    const handleSetId = (id: string) =>{
      setId(id);
    }

    const handleClear = () =>{ 
      setId(undefined);
      setGatewayName(undefined)
    }

    useEffect(() => {},[signalsData]);

    const { translate } = useTranslation();
    const getSignalStrengthTranslated  = useGetSignalStrengthTranslated();
    const componentText = useMemo(() => {
      return {
        onlineGateways: translate("online_gateways"),
      };
    }, []);

return (
 <FormControl fullWidth disabled={!Boolean(gateways?.length) || selectedIds.length === 0 || allowableGateways.length === 0}>
  <InputLabel id="gateway-list-label">{componentText.onlineGateways}</InputLabel>
  <Select
    data-testid="plangs_managements_assign_select_gateway"
    labelId="gateway-list-label"
    id="gateway-select"
    value={gatewayName}
    label={componentText.onlineGateways}
    onChange={handleChange}
    renderValue={selected => (
      <Box display={id ? "flex": "none"} alignItems="center" justifyContent="space-between">
        <span>{selected || 'Select Gateway'}</span>
        {selected && (
          <IconButton
          onClick={handleClear}
          sx={{p: 0, mr:1}}
          >
            <CloseIcon onMouseDown={(event) => event.stopPropagation()}/>
          </IconButton>
        )}
      </Box>
    )}
  >
    {
      gateways?.filter( (g) => allowableGateways.includes(g.deviceId) ).map(gateway => (
        <MenuItem
          key={gateway.name}
          value={gateway.name}
          onClick={() => handleSetId(gateway.deviceId)}
        >
          <Box display="flex" alignItems="center">
            <Box sx={{ width: "18rem !important" }}>{gateway.name}</Box>
             <Box
              width="5rem"
              height="1.2rem"
              p={0.1}
              ml={1}
              sx={{
                background: getColor(getSignalStrength(gateway.deviceId, signalsData)),
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: ".8rem"
              }}
            >
              {getSignalStrengthTranslated(getSignalStrength(gateway.deviceId, signalsData))}
            </Box> 
          </Box>
        </MenuItem>
      ))
    }
  </Select>
</FormControl>      
    )
}

export default GatewaysList;

export function getColor(signal: string){
    switch(signal){
        case "Good": return okColor;
        case "Bad": return alarmColor;
        case "Week": return warningColor;
        default: return "silver";
    }
}

export function getSignalStrength(gateId: string, signalsData: {gatewayId: string, sumRssi: number, count: number}[]) {
  const gate = signalsData.find( s => s.gatewayId === gateId);
  const value = gate ? gate.sumRssi/gate.count : null; 
  if(value){
    if (value >= -67) return "Good";
    else if (value >= -80 && value < -67) return "Weak";
    else if (value < -80) return "Bad";
    else return "Bad";
  } else return "-"
}