import { useAuthMutation } from 'api/queries';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

interface NotificationBody {
  type: string;
  notificationWay: string;
  value: boolean;
}

export const useUpdateNotifications = () => {
  const plantId = useSelector(selectPlantId);
  return useAuthMutation<never, NotificationBody, undefined>({
    url: () => `users/plant/${plantId}/notifications`,
    method: 'PUT',
  });
};
