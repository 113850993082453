import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPlantId } from 'store/accountSlice';
import { getCustomPartDetailsUrl } from './useGetCustomPartDetails';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface UpdateCustomPartDetails {
  number: string;
  assetType: string;
  description: string;
  manufacturer?: number;
  properties: {
    [key: string]: string | number | boolean | null;
  };
}

export const useUpdateCustomPart = (partId: number, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('custom_part_successfully_updated_toast'),
      errorMess: translate('failed_to_update_custom_part_toast'),
    };
  }, []);
  return useAuthMutation<{}, UpdateCustomPartDetails>({
    url: getCustomPartDetailsUrl(plantId, partId),
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.successMess);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('parts') });
        onSuccess?.();
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
