import { useEffect, useRef } from "react";

const useArrayMemo = <T,>(array: T[]) => {
    const ref = useRef<T[]>();
    const areArraysConsideredTheSame =
        ref.current && array.length === ref.current.length
            ? array.every((element, i) => {
                return element === ref.current?.[i];
            })
            : false;
    useEffect(() => {
        if (!areArraysConsideredTheSame) {
            ref.current = array;
        }
    }, [areArraysConsideredTheSame, array]);
    return areArraysConsideredTheSame ? ref.current : array;
}
export default useArrayMemo;