import DodgeModal from 'components/DodgeModal/DodgeModal';
import { AdditionalProperties } from 'features/plants-management/models/sensor.model';
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import QrCodeReader from './QrCodeReader';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { getCatrigeSize } from '../../CommissionsSensors/IntellilubeCommissionModal';
import { GetSensorsByAssetIdResponse } from 'api/plants/useGetSensorsByAssetId';
import { useReplaceCartridge } from 'api/assets/useReplaceCartridge';
import { LoadingButton } from '@mui/lab';
import { getSensorIco } from 'helpers/getSensorIco';
import { useTranslation } from 'languages';

type DisplayType = 'form' | 'qr-reader';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedSensor: GetSensorsByAssetIdResponse;
  selectedAssetId: number;
}

const IntellilubeChangeCartridgeSize: FC<Props> = ({
  open,
  onClose,
  selectedSensor,
  selectedAssetId,
}) => {
  const { mutate, isPending } = useReplaceCartridge('', onClose);

  const [display, setDisplay] = useState<DisplayType>('form');
  const [qrCode, setQrCode] = useState<string>('');
  const [additionalProperties, setAdditionalProperties] = useState<AdditionalProperties>();
  const [cartridgeSize, setCartridgeSize] = useState('');

  const cartridgeSizeScan = useRef(true);

  useEffect(() => {
    setCartridgeSize(selectedSensor.properties.cartridgeSize.value.toString());
  }, [selectedSensor]);

  useEffect(() => {
    if (!qrCode) {
      return;
    }
    if (cartridgeSizeScan.current) {
      const arrayQrCode = qrCode.split('-');
      setAdditionalProperties((prev) => ({
        ...prev,
        cartridgePN: arrayQrCode[3],
        cartridgeBN: arrayQrCode[2],
        cartridgeFillDate: arrayQrCode[1],
        cartridgeQRcode: qrCode,
      }));
      const cartridgeSize = getCatrigeSize(qrCode);
      setCartridgeSize(cartridgeSize);
    } else {
      setAdditionalProperties((prev) => ({
        ...prev!,
        ptWizardQRcode: qrCode,
      }));
    }
  }, [qrCode]);

  useEffect(() => {
    setDisplay('form');
    setQrCode('');
  }, [open]);

  const handleSaveSettings = () => {
    mutate({
      params: {
        serialNumber: selectedSensor.serialNumber,
        assetId: selectedAssetId,
      },
      body: {
        properties: {
          cartridgeSize: cartridgeSize,
          dischargePeriod: null,
        },
        additionalProperties: additionalProperties ?? null,
      },
    });
  };

  const handleScanCartridgeSize = () => {
    setDisplay('qr-reader');
    cartridgeSizeScan.current = true;
  };
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      modalTitle: translate("change_cartridge_size_label"),
      saveButton: translate("menu_save_button")
    };
  }, []);

  return (
    <DodgeModal title={componentText.modalTitle} open={open} onClose={onClose} width='31.25rem'>
      {display === 'form' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              fontWeight={600}
              sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              {getSensorIco(selectedSensor.type)} {`Intelli-Lube (${selectedSensor.serialNumber})`}
            </Typography>
            <Typography>{selectedSensor.location}</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Button
                variant='contained'
                sx={{
                  padding: '0.5rem 0.75rem',
                  mr: '0.75rem',
                  minWidth: 0,
                }}
                onClick={handleScanCartridgeSize}
              >
                <QrCodeScannerIcon />
              </Button>
              <TextField
                data-testid='plant_management_sensors_change_settings_cartridge_size_input'
                value={`${cartridgeSize}cc`}
                size='small'
                sx={{
                  width: '85%',
                  color: 'black',
                  '& input': {
                    cursor: 'default',
                  },
                }}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ margin: '2rem 0 0.5rem' }} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <LoadingButton
              data-testid='plant_management_sensors_change_settings_save_button'
              loading={isPending}
              variant='contained'
              color='secondary'
              sx={{ fontWeight: 'bold' }}
              disabled={!cartridgeSize}
              onClick={handleSaveSettings}
            >
              {componentText.saveButton}
            </LoadingButton>
          </Grid>
        </Grid>
      )}
      {display === 'qr-reader' && (
        <QrCodeReader
          setQrCode={setQrCode}
          setDisplay={setDisplay}
          validateCartridgeSizeQRCode={cartridgeSizeScan.current}
        />
      )}
    </DodgeModal>
  );
};

export default memo(IntellilubeChangeCartridgeSize);
