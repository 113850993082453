import {useCallback, useMemo, useRef } from "react";
import { TimeSeriesData } from "../TimeSeriesGraph/models/TimeSeriesGraph.models";
import useDateFormatter from "components/Uitls/useDateFormatter";
import { Layout, PlotData, PlotHoverEvent, PlotMouseEvent } from "plotly.js";
import { compareMeasurements } from "./utils/compareMeasurements";
import Plot from "react-plotly.js";
import { ChartTooltipData } from "./ChartTooltip";
import { useGetAxisLabel } from "./useGetAxisLabel";

type Props = {
    chartData: TimeSeriesData[];
    marginTop?: number;
    yaxis ?: string;
    setTooltipValue: (val: ChartTooltipData | undefined) => void;
    tooltipValue?: ChartTooltipData;
    yAxisLabel?: string
  };
  

const TimeSeries3D = (
    {
        chartData,
        marginTop,
        yaxis,
        setTooltipValue,
        tooltipValue,
        yAxisLabel
      } : Props
) =>{
  const {axisLabelTranslated : yAxisLabelTranslated} = useGetAxisLabel('Time [s]');
  const {axisLabelTranslated : xAxisLabelTranslated} = useGetAxisLabel('Date');
    const dateFormatter = useDateFormatter();
    // @ts-expect-error: A element and derives from the HTMLElement interface, but without implementing any additional properties or methods.
    const mousePosDetection = useRef<MouseEvent<HTMLSpanElement> | undefined>();
    
    const {traces, dateLabelList} : {traces: Partial<PlotData>[], dateLabelList: { date : string; i : number }[]} = useMemo(() => {
        const tracesTemp: Partial<PlotData>[] = [];
        const labelList : { date : string; i : number }[] = [];
        chartData.sort(compareMeasurements).forEach((measurement: TimeSeriesData, index: number) => {
            tracesTemp.push({
                x: Array(measurement.data.length).fill(index),
                y: measurement.data.map(point => point.time),
                z: measurement.data.map(point => point.value),
                mode: 'lines',
                marker: {
                  size: 24,
                  symbol: 'circle',
                  line: {
                    width: 3,
                  },
                },
                type: 'scatter3d',
                xaxis: 'x',
                yaxis: 'y',
                automargin: true,
                hoverinfo: 'none',
                showlegend: false,
            });
            labelList.push({date: dateFormatter(measurement.measurmentDate, 'MM/DD/YYYY|HH:mm'), i: index});
        });
        return {traces: tracesTemp, dateLabelList: labelList};
    }, [chartData]);

    const layout: Partial<Layout> = {
        title: '',
        autosize: true,
        showlegend: false,
        width: undefined,
        height: 650,
        margin: {
          t: marginTop,
        },
        scene: {
          xaxis: {
            title: `.<br><br><br><br>${xAxisLabelTranslated}`,
            type: 'category',
            range: [-1, traces.length + 1],
            tickmode: 'array',
            tickvals: dateLabelList.map((el) => el.i),
            ticktext: dateLabelList.map((el) => el.date),
          },
          yaxis: { title: `.<br><br>${yAxisLabelTranslated}` },
          //zaxis: { title: `.<br><br>${yAxisLabel} [${yaxis}]` },
          zaxis: { title: `[g]` },
          camera: {
            // @ts-expect-error: Projection type is intentionally set to 'orthographic' for 3D chart.
            projection: {
              type: 'orthographic',
            },
            eye: { x: 3, y: 2, z: 0.9 },
          },
          aspectmode: 'manual',
          aspectratio: {
            x: 3,
            y: 3,
            z: 1,
          },
        },
      };

      
      const showTooltip = useCallback(
        (event: Readonly<PlotHoverEvent>) => { 
            const timeValue = `${yAxisLabelTranslated}: ${String(Math.round(Number(event.points[0].y) * 10) / 10)}`
            // @ts-expect-error Plotly event object has more properties in orthographic mode than is declared
            const value = String(Math.round(Number(event.points[0].z) * 10000) / 10000);
            const unit = yaxis as string;
            const xPos = mousePosDetection.current?.clientX || 100;
            const yPos = mousePosDetection.current?.clientY || 100;
            // @ts-expect-error Plotly event object has more properties in orthographic mode than is declared
            const borderColor = (event.points[0].fullData.line as any).color;
            if (
              xPos !== tooltipValue?.xPos ||
              yPos !== tooltipValue?.yPos ||
              timeValue !== tooltipValue?.xValue ||
              value !== tooltipValue?.value ||
              unit !== tooltipValue?.unit
            ) {
              setTooltipValue({
                xValue: timeValue,
                value,
                unit,
                xPos,
                yPos,
                borderColor
              });
            }
          },
        [setTooltipValue, tooltipValue, yaxis]
      );

      const hideTooltip = useCallback((_ : Readonly<PlotMouseEvent>) => {setTooltipValue(undefined)}, [setTooltipValue, tooltipValue])
      
    return (
    <span onMouseMove={(e) => (mousePosDetection.current = e)}>
        <Plot
          data={traces}
          layout={layout}
          style={{ width: '100%', height: '100%' }}
          useResizeHandler
          config={{ responsive: true, scrollZoom: true, autosizable: true }}
          onHover={showTooltip}
          onUnhover={hideTooltip}
        />
      </span>
    );
}

export default TimeSeries3D;
