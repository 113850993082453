import { useAuthMutation } from 'api/queries';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface SensorTransferParams {
  newAssetId: number;
  serialNumber: string;
  oldAssetId: number;
}

export const useSensorTransfer = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      failedToastLabel: translate('failed_to_transfer_toast'),
    };
  }, []);

  return useAuthMutation<SensorTransferParams, {}>({
    url: (params: SensorTransferParams) =>
      `assets/${params.oldAssetId}/sensors/${params.serialNumber}/transfer?newAssetId=${params.newAssetId}`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: (_, { params }) => {
        onSuccess?.();
        if (!params) {
          return;
        }
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('sensors') });
      },
      onError: () => {
        toast.error(componentText.failedToastLabel);
      },
    },
  });
};
