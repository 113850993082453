export const SENSOR_TYPES = [
  'PerformanceSensor',
  'Sensor',
  'BreatherSensor',
  'IntelliLube',
] as const;
export type SensorType = (typeof SENSOR_TYPES)[number];
export type VariableType = 'int' | 'boolean' | 'string' | '';

export const HEALTH_KPI_LABELS = {
  temperatureBottom: 'Asset Skin Temperature',
  accelerationRMS: 'Acceleration RMS',
  velocityRMS: 'Velocity RMS',
  accRmsX: 'Axial Acceleration RMS',
  accRmsY: 'Tangential Acceleration RMS',
  accRmsZ: 'Radial Acceleration RMS',
  velRmsX: 'Axial Velocity RMS',
  velRmsY: 'Tangential Velocity RMS',
  velRmsZ: 'Radial Velocity RMS',
  fillLevel: 'Fill level',
  laggThA: 'Channel A: Remaining Thickness',
  laggThB: 'Channel B: Remaining Thickness',
  laggThC: 'Channel C: Remaining Thickness',
  beltSpeed: 'Linear Belt Speed',
  unevenWear: 'Uneven Wear',
  breatherLife: 'Breather Life',
  batteryLife: 'Battery Life',
  temperature: 'Headspace Temperature',
} as const;

export const OPERATIONAL_KPI_LABELS = {
  index: 'Index',
  kurtosis: 'Kurtosis',
  skewness: 'Skewness',
  peakAcceleration: 'Peak Acceleration',
  peakFreq: 'Velocity Peak Frequency',
  speed: 'Motor speed',
  saturationDirection: 'Saturation Direction',
  rateOfSaturation: 'Rate Of Saturation',
} as const;

export const KPI_LABELS = {
  healthParameters: HEALTH_KPI_LABELS,
  operationalPArameters: OPERATIONAL_KPI_LABELS,
} as const;

export type HealthKpis = keyof typeof HEALTH_KPI_LABELS;
export type OperationalKpis = keyof typeof OPERATIONAL_KPI_LABELS;
export type KpiType = HealthKpis | OperationalKpis;

export const DEVICE_FLAGS = [
  'desynchronized',
  'noCartridgeInfo',
  'noRecordedData',
  'projected',
  'emptyProjected',
  'cartridgeMissing',
  'cartridgeIncompatible',
  'overload',
  'empty',
  'lowBattery',
  'lowTemperature',
] as const;

export type DeviceFlag = (typeof DEVICE_FLAGS)[number];
