import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPlantId } from 'store/accountSlice';
import { useAuthMutation } from '../queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface Params {
  assetId: number;
  serialNumber: string;
}

export const useClearCartridgeFlag = (onSucces?: () => void) => {
  const queryClient = useQueryClient();
  const plantId = useSelector(selectPlantId);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastSuccessMess: translate('cartridge_flag_successfully_cleared_toast'),
      toastErrorMess: translate('failed_to_clear_cartridge_flag_toast'),
    };
  }, []);

  return useAuthMutation<Params, unknown>({
    url: ({ assetId, serialNumber }) =>
      `assets/${assetId}/sensors/${serialNumber}/clear-cartridge-flag`,
    method: 'POST',
    mutationOptions: {
      onSuccess: (_, variables) => {
        toast.success(componentText.toastSuccessMess);
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes('sensors'),
        });
        onSucces?.();
      },
      onError: () => {
        toast.error(componentText.toastErrorMess);
      },
    },
  });
};
