import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

interface CheckGatewayAvailableResponse {
  gatewayId: string;
}

export const useCheckGatewayAvailable = (
  macAddress: string,
  serialNumber: string,
  enabled = true,
) => {
  const { translate } = useTranslation();
  const translations = useMemo(() => {
    return {
      toastError: translate('toast_error_gateway_not_found'),
    };
  }, []);
  const plantId = useSelector(selectPlantId);

  return useAuthQuery<CheckGatewayAvailableResponse>({
    url: `gateways/available`,
    params: {
      serialNumber: serialNumber.trim(),
      macAddress: macAddress.trim(),
      plantId: plantId.toString(),
    },
    queryOptions: {
      enabled: !!serialNumber && !!macAddress && enabled,
      meta: {
        error: {
          message: translations.toastError,
        },
      },
    },
  });
};
