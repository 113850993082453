import { Box, Button, Typography, Modal } from '@mui/material';
import { FC, memo, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useMenuButtons } from '../menu-buttons';
import { fontButton, fontTitle } from './styles/fontStyle';
import JoinPlant from 'features/onboarding/components/JoinPlantFlow/JoinPlant/JoinPlant';
import CreatePlant from 'features/onboarding/components/CreatePlantFlow/CreatePlantForm/CreatePlant';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'languages';
import { useResponsiveLanguage } from 'shared/responsive';

interface ManageAccountHeaderProps {
  activeLink?: string;
  numberOfPlant: number | null;
  onClose?: () => void;
  onEditClick: () => void;
  onSaveClick: () => void;
  isEditMode: boolean;
}

const ManageAccountHeader: FC<ManageAccountHeaderProps> = ({
  activeLink,
  numberOfPlant,
  onClose,
  onEditClick,
  isEditMode,
  onSaveClick,
}) => {
  const menuButtons = useMenuButtons();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      joinPlantText: translate('menu_join_plant_button'),
      createPlantText: translate('menu_create_plant_button'),
      personalInformationText: translate('menu_personal_information'),
      manageNotificationsText: translate('menu_manage_notifications'),
      automaticEventClosureText: translate('menu_automatic_event_closure'),
      unitsOfMeasurementText: translate('menu_units_of_measurement'),
      apiTestEnvironmentText: translate('menu_api_test_environment'),
      optifyReleaseNotesText: translate('menu_optify_release_notes'),
      resourcesText: translate('menu_resources'),
      editText: translate('menu_edit_button'),
      saveText: translate('menu_save_button'),
      plantsText: translate('menu_plants_count', { isPlural: numberOfPlant && numberOfPlant > 1 }),
    };
  }, [numberOfPlant, translate]);

  const getTitle = () => {
    if (menuButtons[0] && activeLink === menuButtons[0].id && numberOfPlant === null) return ``;
    if (menuButtons[0] && activeLink === menuButtons[0].id)
      return `${`${numberOfPlant} ${componentText.plantsText}`}`;
    else if (menuButtons[1] && activeLink === menuButtons[1].id)
      return componentText.personalInformationText;
    else if (menuButtons[2] && activeLink === menuButtons[2].id)
      return componentText.manageNotificationsText;
    else if (activeLink === menuButtons[3].id) return componentText.automaticEventClosureText;
    else if (menuButtons[4] && activeLink === menuButtons[4].id)
      return componentText.unitsOfMeasurementText;
    else if (menuButtons[5] && activeLink === menuButtons[5].id)
      return componentText.apiTestEnvironmentText;
    else if (menuButtons[6] && activeLink === menuButtons[6].id)
      return componentText.optifyReleaseNotesText;
    else if (menuButtons[6] && activeLink === menuButtons[7].id) return componentText.resourcesText;
  };

  const [isJoinPlantOpen, setIsJoinPlantOpen] = useState(false);
  const [isCreatePlantOpen, setIsCreatePlantOpen] = useState(false);
  const title = getTitle();
  const managePlants = activeLink === 'manage-plants';

  const responsiveProps = {
    windowWidth: useResponsiveLanguage({
      en: { lg: { width: '120%' } },
      es: { lg: { width: '120%' } },
    }),
    joinPlantButton: useResponsiveLanguage({
      en: {
        xs: { ...fontButton, fontSize: '0.8rem', mr: 0.5 },
        md: { fontSize: '0.9rem', width: '12rem', mr: 1 },
      },
      es: { xs: { mr: '0.5rem'}, md: { width: '13rem'} },
    }),
    createPlantButton: useResponsiveLanguage({
      en: {
        xs: { ...fontButton, float: 'right', fontSize: '0.8rem' },
        md: { width: '12rem', fontSize: '0.9rem' },
      },
      es: { lg: { fontSize: '0.95rem' } },
    }),
  };
  return (
    <>
      <Box display='flex' alignItems='center' pb='1rem'>
        <Typography
          sx={{ ...fontTitle }}
          width={activeLink === menuButtons[0].id ? '100%' : '100%'}
        >
          {title}
        </Typography>
        {managePlants && (
          <>
            <Box sx={responsiveProps.windowWidth}>
              <Button
                sx={responsiveProps.joinPlantButton}
                variant='outlined'
                startIcon={<AddIcon />}
                onClick={() => setIsJoinPlantOpen(true)}
                data-testid='plants_management_join_plant_button'
              >
                {componentText.joinPlantText}
              </Button>
            </Box>
            <Box sx={responsiveProps.windowWidth}>
              <Button
                sx={responsiveProps.createPlantButton}
                variant='outlined'
                startIcon={<AddIcon />}
                onClick={() => setIsCreatePlantOpen(true)}
                data-testid='plants_management_create_plant_button'
              >
                {componentText.createPlantText}
              </Button>
            </Box>
          </>
        )}
        {activeLink === 'user-profile' && (
          <Box>
            {isEditMode ? (
              <Button
                data-testid='manage_account_user_profile_save_button'
                variant='contained'
                size='small'
                color='secondary'
                sx={{ fontWeight: 'bold' }}
                onClick={onSaveClick}
              >
                {componentText.saveText}
              </Button>
            ) : (
              <Button
                data-testid='manage_account_user_profile_edit_button'
                variant='outlined'
                size='small'
                color='primary'
                startIcon={<Edit />}
                sx={{ fontWeight: 'bold' }}
                onClick={onEditClick}
              >
                {componentText.editText}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Modal
        open={isJoinPlantOpen}
        onClose={() => {
          setIsJoinPlantOpen(false);
          onClose?.();
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflowY: 'auto',
            width: 'auto',
          }}
        >
          <JoinPlant
            inDashboard={true}
            onClose={() => {
              setIsJoinPlantOpen(false);
              onClose?.();
            }}
          />
        </Box>
      </Modal>
      <Modal
        data-testid='create_plant_modal'
        open={isCreatePlantOpen}
        onClose={() => {
          setIsCreatePlantOpen(false);
          onClose?.();
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflowY: 'auto',
            width: 'auto',
            maxHeight: '95vh',
          }}
        >
          <CreatePlant
            inDashboard={true}
            onClose={() => {
              setIsCreatePlantOpen(false);
              onClose?.();
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default memo(ManageAccountHeader);
