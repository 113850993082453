import { FFTData } from "features/assets-management/models/FFTChart.models";


export const compareMeasurements = (fftA:FFTData, fftB: FFTData) =>{
  const a = new Date(fftA.measurmentDate);
  const b = new Date(fftB.measurmentDate);
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}