import { Grid, Typography } from '@mui/material';
import { FC, memo, useMemo, useState } from 'react';
import ImageMain from './ImageMain';
import ImageList from './ImageList';
import { AssetImage } from 'features/plants-management/models/asset.model';
import { useTranslation } from 'languages';
import { useDeviceSize } from 'shared/responsive';

interface ImageContainerProps {
  defaultPhoto: string | null;
  defaultNameplate: string | null;
  photos: AssetImage[] | [];
  nameplates: AssetImage[] | [];
  selectedAsset: number;
}

const ImageContainer : FC<ImageContainerProps> = ({
  photos,
  nameplates,
  selectedAsset,
  defaultPhoto,
  defaultNameplate,
}) => {
  const [selectedImage, setSelectedImage] = useState(defaultPhoto || '');
  const [selectedImageType, setSelectedImageType] = useState('photos');

  const onImageDelete = () => {
    setSelectedImage('');
    setSelectedImageType('photos');
  };

  const { isPhone, isTablet } = useDeviceSize();
  const { translate } = useTranslation();
  const componentText = useMemo(() => { 
    return { 
      maximumFileSizeInfo: translate('maximum_file_size_info'),
      assetPhotosTitle: translate('asset_photos_title'),
      nameplatePhotosTitle: translate('nameplate_photos_title')
    };
  }, []);

  return (
    <>
      <Grid justifyContent='center' alignItems='center' mt={2} ml={3} sx={{ overflowY: 'auto' }}>
        <Grid container my={3}>
          <ImageMain
            selectedImage={selectedImage}
            selectedImageType={selectedImageType}
            selectedAsset={selectedAsset}
            defaultPhoto={defaultPhoto}
            defaultNameplate={defaultNameplate}
            photosLength={photos.length}
            nameplatesLength={nameplates.length}
            onImageDelete={onImageDelete}
          />
        </Grid>
        <Grid container>
          <ImageList
            images={photos}
            selectedImage={selectedImage}
            selectedAsset={selectedAsset}
            title={componentText.assetPhotosTitle}
            type='photos'
            changeImageType={setSelectedImageType}
            changeSelected={setSelectedImage}
          />
        </Grid>
        <Grid container>
          <ImageList
            images={nameplates}
            selectedImage={selectedImage}
            selectedAsset={selectedAsset}
            title={componentText.nameplatePhotosTitle}
            type='nameplates'
            changeImageType={setSelectedImageType}
            changeSelected={setSelectedImage}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' ml={isPhone || isTablet ? 0 : '130px'} mb={isPhone || isTablet ? 2 : 0} mt={2}>
            {componentText.maximumFileSizeInfo}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(ImageContainer);
