import { Grid, Typography } from '@mui/material';
import { useGetMultipleFFT } from 'api/assets/useGetFFT';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { Sensor } from 'features/assets-management/models';
import {
  selectAxis,
  selectSelectedSensors,
  selectedMeasurements,
} from 'features/assets-management/store/assetsManagementSlice';
import { useTranslation } from 'languages';
import { FC, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { utils, write } from 'xlsx';
import { fftLabelDict } from '../../FftControl/FftControl';
import SelectDataPrompt from '../../SelectDataPrompt/SelectDataPrompt';
import AmplitudeChart from '../../TimeSeriesGraph/AmplitudeChart';
import { TimeSeriesData } from '../../TimeSeriesGraph/models/TimeSeriesGraph.models';
import useArrayMemo from '../../TimeSeriesGraph/utils/useArrayMemo';
import ChartTooltip, { ChartTooltipData } from '../../charts/ChartTooltip';
import TimeSeries3D from '../../charts/TimeSeries3D';
import Loader from './Loader';

type Props = {
  activeAssetId: number;
  activeSensorId: number;
  activeUnit: string;
  selectedFFTType: string;
  velocityUnit: string;
  pr: string;
  openTreeMenu: boolean;
  setIsUserClickDownloadRawData: (isClick: boolean) => void;
  isUserClickDownloadRawData: boolean;
  setUrl: (url: string) => void;
};

const TimeSeriesContent: FC<Props> = ({
  activeAssetId,
  activeSensorId,
  activeUnit,
  selectedFFTType,
  velocityUnit,
  pr,
  openTreeMenu,
  setUrl,
  setIsUserClickDownloadRawData,
  isUserClickDownloadRawData,
}) => {
  const checkedMeasurements = useSelector(selectedMeasurements);
  const [tooltipValue, setTooltipValue] = useState<ChartTooltipData | undefined>();

  const selectedsensors = useSelector(selectSelectedSensors);
  const axis = useSelector(selectAxis);

  const [FFTType] = useMemo(() => {
    const splittedType = selectedFFTType.split('-');
    return splittedType.length > 1 ? splittedType : [splittedType[0], 'Z'];
  }, [selectedFFTType]);

  const dateFormatter = useDateFormatter();

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      excelAssetName: translate('fft_excel_label_asset_name'),
      excelPositionName: translate('fft_excel_label_position_name'),
      excelTimestamp: translate('fft_excel_label_timestamp'),
      excelUnit: translate('fft_excel_label_unit'),
      excelFreqUnit: translate('fft_excel_label_freq_unit'),
      excelFrequency: translate('fft_excel_label_freq'),
      excelVal: translate('fft_excel_label_val'),
      excelSampleFrequency: translate('fft_excel_samp_freq'),
      excelTime: translate('fft_excel_time'),
      timeSeries: translate('fft_time_series'),
    };
  }, []);

  const queriesResults = useGetMultipleFFT({
    assetId: activeAssetId,
    sensorId: activeSensorId,
    dateTime: checkedMeasurements,
    freqUnit: activeUnit,
    velocityUnit,
    FFTType,
    axis,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(queriesResults.filter((res) => res.isLoading).length > 0);
  }, [queriesResults]);

  const memoizedArray = useArrayMemo(
    queriesResults
      .filter((result) => result.status === 'success')
      .flatMap((query) => (query.data ? query.data : [])),
  );

  const { chartData, yaxis }: { chartData: TimeSeriesData[]; yaxis: string } = useMemo(() => {
    const chartDataTemp: TimeSeriesData[] = [];
    let yLabelName: string | null = '';
    memoizedArray?.forEach((data, index) => {
      const vibrationRawData = data.vibrationRawData;
      yLabelName = data.yLabelName;
      if (vibrationRawData) {
        chartDataTemp.push({
          measurmentDate: checkedMeasurements[index],
          data: vibrationRawData,

          unitAfterScaling: data.unitAfterScaling,
          samplingFrequency: data.samplingFrequency,
        });
      }
    });
    return { chartData: chartDataTemp, yaxis: yLabelName };
  }, [memoizedArray, checkedMeasurements]);

  const handleDownload = () => {
    const { name, parentName } = selectedsensors.find(
      (sensor) => sensor.sensorId === activeSensorId,
    ) as Sensor;
    const workbook = utils.book_new();
    chartData.forEach((item) => {
      if (item.measurmentDate) {
        const header = [
          [componentText.excelAssetName, parentName],
          [componentText.excelPositionName, name],
          [componentText.excelTimestamp, dateFormatter(item.measurmentDate, 'MM/DD/YYYY hh:mm a')],
          [`${componentText.excelSampleFrequency} [Hz]`, String(item.samplingFrequency)],
          ['', ''],
          [`${componentText.excelTime} [s]`, "[g's]"],
        ];
        const traces = item.data.map((item) => {
          return [String(item.time.toFixed(4)), String(item.value.toFixed(4))];
        });

        const content = [...header, ...traces];
        const sheetName = dateFormatter(item.measurmentDate, 'MM-DD-YYYY-HHmm');
        workbook.SheetNames.push(sheetName);
        const worksheet = utils.aoa_to_sheet(content);
        workbook.Sheets[sheetName] = worksheet;
        worksheet['!cols'] = [{ wch: 30 }, { wch: 30 }];
      }
    });
    const wbout = write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([new Uint8Array(wbout)], { type: 'application/octet-stream' });
    setUrl(URL.createObjectURL(blob));
  };

  useEffect(() => {
    if (isUserClickDownloadRawData) {
      handleDownload();
      setIsUserClickDownloadRawData(false);
    }
  }, [isUserClickDownloadRawData]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography fontWeight='600' fontSize='1.6rem' ml='.8rem'>
          {componentText.timeSeries}
        </Typography>
      </Grid>
      <Grid item xs={12} container>
        <Grid
          item
          xs={12}
          position='relative'
          data-testid='assets_management_vibration_fft_time_series_chart'
        >
          {isLoading && <Loader height='90%' />}
          {checkedMeasurements.length === 1 && (
            <AmplitudeChart
              chartData={chartData}
              setTooltipValue={setTooltipValue}
              marginTop={20}
              pr={pr}
              openTreeMenu={openTreeMenu}
              yaxis={yaxis}
              yAxisLabel={`${fftLabelDict.get(`${FFTType}-${axis}`)}`}
            />
          )}
          {checkedMeasurements.length > 1 && (
            <TimeSeries3D
              chartData={chartData}
              marginTop={20}
              yaxis={yaxis}
              setTooltipValue={setTooltipValue}
              tooltipValue={tooltipValue}
              yAxisLabel={`${fftLabelDict.get(`${FFTType}-${axis}`)}`}
            />
          )}
          {checkedMeasurements.length === 0 && (
            <SelectDataPrompt infoText='Pick measurement date from menu to display chart' />
          )}
        </Grid>
      </Grid>
      {tooltipValue && <ChartTooltip {...tooltipValue} />}
    </Grid>
  );
};

export default TimeSeriesContent;
