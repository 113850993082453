import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface WithdrawUserFromPlantParams {
  plantId: number;
}

export const useWithdrawUserFromPlant = () => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('user_invitation_successfully_withdrawn_from_plant_toast'),
      errorMess: translate('user_failed_to_be_withdrawn_from_plant_toast'),
    };
  }, []);
  return useAuthMutation<WithdrawUserFromPlantParams, string, undefined>({
    url: ({ plantId }) => `plants/${plantId}/users`,
    method: 'DELETE',
    mutationOptions: {
      onSuccess: (_, { params }) => {
        toast.success(componentText.successMess);
        queryClient.invalidateQueries({ queryKey: ['users'] });
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
