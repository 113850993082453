import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export const useCreateAssetApplication = (onSuccess?: (createdApplicationId: number) => void) => {
  const plantId = useSelector(selectPlantId);
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_create_asset_application_toast'),
      name: translate('menu_name_label'),
      exists: translate('already_exists_toast'),
    };
  }, []);
  return useAuthMutation<{ name: string }, undefined, { assetApplicationId: number }>({
    url: (params) => `plants/${plantId}/asset-applications?name=${params.name}`,
    method: 'POST',
    mutationOptions: {
      onSuccess: (data) => {
        onSuccess?.(data.data.assetApplicationId);
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes('asset-applications'),
        });
      },
      onError: (e, variables) => {
        if (e.message === 'UniqueKeyViolation') {
          toast.error(`${componentText.name} ${variables.params?.name} ${componentText.exists}.`);
        } else {
          toast.error(componentText.toastErrorMess);
        }
      },
    },
  });
};
