import { useAuthQuery } from 'api/queries';
import { User, UserInvitationStatus, UserRole } from 'features/plants-management/models/user.model';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface UserApiModel {
  email: string;
  firstName: string;
  lastName: string;
  invitationStatus: UserInvitationStatus;
  plantId: number;
  plantRole: string;
  assetGroupIds: number[];
}

export const convertToPlantRole = (role: string): UserRole => {
  return role
    .split('_')
    .map((x) => `${x[0].toUpperCase()}` + x.slice(1))
    .join(' ') as UserRole;
};

export const useGetUsersByPlant = (plantId: number) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_users_from_a_plant_toast'),
    };
  }, []);

  return useAuthQuery<User[], UserApiModel[]>({
    url: `plants/${plantId}/users`,
    queryOptions: {
      select: (response): User[] =>
        response.map((data) => ({
          id: data.email,
          email: data.email,
          name: data.firstName && data.lastName ? `${data.firstName} ${data.lastName}` : '',
          plantRole: convertToPlantRole(data.plantRole),
          invitationStatus: data.invitationStatus,
          assetGroupIds: data.assetGroupIds,
        })),
      meta: {
        error: { message: componentText.toastErrorMess },
      },
    },
  });
};
