import { Grid, Switch, Typography } from '@mui/material';
import { useTranslation } from 'languages';
import { memo, useMemo } from 'react';

type Props = {
  enabled: boolean;
  onChange: () => void;
};

const GenUserAlarmSetting = ({ enabled, onChange }: Props) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      generalUsersCanChangeUserAlarm: translate("general_users_can_change_user_alarm"),
      alertAndAlarmLevelsUserAlarm: translate("alert_and_alarm_levels_user_alarm"),
      disabledUserAlarm: translate("disabled_user_alarm"),
      enabledUserAlarm: translate("enabled_user_alarm"),
      toggleFeatureDescriptionUserAlarm: translate("toggle_feature_description_user_alarm"),
    };
  }, []);
  return (
    <>
      <Grid container xs={12} spacing={3} mb='2.5%' alignItems={'center'}>
        <Grid item xs={12} lg={4}>
          <Typography fontWeight='600'>{componentText.generalUsersCanChangeUserAlarm}</Typography>
          <Typography fontWeight='600'>{componentText.alertAndAlarmLevelsUserAlarm}</Typography>
        </Grid>
        <Grid item xs={8}>
          {componentText.disabledUserAlarm}
          <Switch checked={enabled} onChange={onChange} />
          {componentText.enabledUserAlarm}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography align='justify' fontSize={'0.9rem'} mt={2} color='gray'>
          {componentText.toggleFeatureDescriptionUserAlarm}
        </Typography>
      </Grid>
    </>
  );
};

export default memo(GenUserAlarmSetting);
