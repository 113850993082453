import { useAuthQuery } from '../queries';
import { FFTDetails } from 'features/assets-management/models/FFTDetails.model';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export const useGetFFTDetails = (assetId: number) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_load_fft_details_toast'),
    };
  }, []);

  return useAuthQuery<FFTDetails>({
    url: `assets/${assetId}/fft-details`,
    queryOptions: {
      enabled: !!assetId,
      meta: {
        error: {
          message: componentText.errorMess,
        },
      },
    },
  });
};
