import { Tooltip } from '@mui/material';
import { useTranslation } from 'languages';
import { FC } from 'react';

export const tooltipStyle = {
  background: 'white',
  color: 'black',
  fontSize: '.75rem',
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  borderRadius: '1rem',
  p: 1.5,
  display: 'flex',
  justifyContent: 'center',
  aliginItems: 'center',
  width: '22.5rem',
};

type PropsSyncError = {
  children : any;
};
const SyncError : FC<PropsSyncError> = ({ children }) => {
  const { translate } = useTranslation();
  return (
    <Tooltip
      title={translate('asset_tile_body_sync_error_tooltip')}
      placement='top'
      componentsProps={{ tooltip: { sx: tooltipStyle } }}
    >
      {children}
    </Tooltip>
  );
};

export default SyncError;
