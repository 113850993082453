import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export interface GetReleaseNoteResponse {
  pi: string;
  name: string;
  contentMobile: string;
  contentWeb: string;
}

export const useGetReleaseNote = (
  releaseNotesId: string | number,
  enabled?: boolean,
  onError?: () => void,
) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_get_release_notes_toast'),
    };
  }, []);

  return useAuthQuery<GetReleaseNoteResponse>({
    url: `release-notes/${releaseNotesId.toString()}`,
    queryOptions: {
      enabled: enabled || releaseNotesId ? true : false,
      meta: {
        error: {
          fn: onError,
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
