import { useTranslation } from 'languages';

export const useGetTranslatedIndustryName = () => {
  const { translate } = useTranslation();
  const translationDictionary = {
    'Mining, Aggregate, & Cement': translate('mining_aggregate_cement'),
    'Air Handling & Office': translate('air_handling_office'),
    'Chemical & Oil': translate('chemical_oil'),
    'Food, Beverage, & Pharmaceuticals': translate('food_beverage_pharmaceuticals'),
    'Forest Products, Paper, & Agriculture': translate('forest_products_paper_agriculture'),
    'Material & Unit Handling': translate('material_unit_handling'),
    Metals: translate('metals'),
    'Power Generation': translate('power_generation'),
    'Training & Test': translate('training_test'),
    'Water & Wastewater': translate('water_wastewater'),
    Aggregate: translate('aggregate'),
    Asphalt: translate('asphalt'),
    Cement: translate('cement'),
    'Sand, Silica': translate('sand_silica'),
    'Iron, Gold, & Copper': translate('iron_gold_copper'),
    Coal: translate('coal'),
    Mining: translate('mining'),
    Fans: translate('fans'),
    Filtration: translate('filtration'),
    Chemical: translate('chemical'),
    Oil: translate('oil'),
    Gas: translate('gas'),
    'Ethanol (Grain)': translate('ethanol_grain'),
    Food: translate('food'),
    Pharmaceuticals: translate('pharmaceuticals'),
    Beverage: translate('beverage'),
    Grain: translate('grain'),
    'MDF & OSB': translate('mdf_osb'),
    Lumber: translate('lumber'),
    'Consumer Paper': translate('consumer_paper'),
    Plywood: translate('plywood'),
    Pulp: translate('pulp'),
    'Corrugated Paper': translate('corrugated_paper'),
    Baggage: translate('baggage'),
    Recycling: translate('recycling'),
    Parcel: translate('parcel'),
    Warehousing: translate('warehousing'),
    Automotive: translate('automotive'),
    'Metals Processing': translate('metals_processing'),
    Steel: translate('steel'),
    Aluminum: translate('aluminum'),
    Biomass: translate('biomass'),
    Electric: translate('electric'),
    Inactive: translate('inactive'),
    Training: translate('training'),
    Test: translate('test'),
    Water: translate('water'),
    Wastewater: translate('wastewater'),
  };

  const getTranslatedIndustryName = (type: string) => {
    const typeKey = type;
    if (type && typeKey in translationDictionary) {
      return translationDictionary[typeKey as keyof typeof translationDictionary];
    }
    return type;
  };

  return getTranslatedIndustryName;
};
