import { ColumnRowTemplate } from "./models";


const propertyToString = (property: unknown): string => {
  if (typeof property === 'string') {
    return property as string;
  }

  throw new Error('Cannot convert property to string');
}

export const propertyIncludesFilter = <T>(row: T, property: keyof T, filter: string): boolean => { 
  return row[property] ? propertyToString(row[property]).toLowerCase().includes(filter.toLowerCase()) : false;
}

export const filterFunction = <T>(list: T[], key: keyof T, filters: string[]): T[] => {
  const filtersLowered = filters.map((f) => f.toLowerCase())
  return list.filter(x => filtersLowered.some((f) => f.includes(propertyToString(x[key]).toLowerCase())));
}

export const getFilterableProperties = <T>(columnRowTemplates: ColumnRowTemplate<T>[]): (keyof T)[] => { 
  return columnRowTemplates
    .filter(x => x.filterable && !!x.columnProperty)
    .map(x => x.columnProperty as keyof T);
}

export const filterByProperties = <T>(rows: T[], properties: (keyof T)[], filter: string, isExtensible: boolean, additionalSearch?:(row:T, filter:string) => boolean | undefined): T[] => {
  return rows.filter(row => !filter.trim() || properties.some(property => propertyIncludesFilter(row, property, filter)) || (isExtensible && additionalSearch?.(row, filter)));
}

