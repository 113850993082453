import { CustomizedUseQueryOptions, useAuthQuery } from 'api/queries';
import { UserInvitationStatus } from 'features/plants-management/models/user.model';
import { UserRoleInfo } from 'models/user-role.model';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {
  setAutoTimezone,
  setChannelDetails,
  setEulaAccepted,
  setLanguage,
  setTimezone,
} from 'store/accountSlice';
import { convertToPlantRole } from './useGetUsersByPlant';

export const CHANNEL_USERS = ['channel_user', 'channel_administrator'] as const;

export type ChannelUser = (typeof CHANNEL_USERS)[number];

export type ChannelDetails = {
  channelId: number;
  channelName: string;
  role: ChannelUser;
};

export type PlantSettings = {
  enableOffset: boolean;
  enableAlertAndAlarmForGeneralUser: boolean;
  rawDataCron: string;
  enableLoadMeasurementsForPlantViewers: boolean;
};

interface ApiResponse {
  autoTimezone: boolean;
  eulaAccepted: boolean;
  roles: {
    plantId: number;
    plantName: string;
    role: string;
    invitationStatus: UserInvitationStatus;
    assetGroupIds: number[];
    selected: boolean;
    visible: boolean;
    plantSettings: PlantSettings;
  }[];
  timezone: string;
  language: string;
  channelDetails?: ChannelDetails;
}

export const useGetUserRoles = (
  queryOptions?: CustomizedUseQueryOptions<UserRoleInfo[], ApiResponse>,
) => {
  const dispatch = useDispatch();
  const timezone = moment.tz.guess(true);

  return useAuthQuery<UserRoleInfo[], ApiResponse>({
    url: `users/roles`,
    params: { timezone },
    queryOptions: {
      ...queryOptions,
      select: (response) => {
        localStorage.setItem('language', JSON.stringify(response.language));
        dispatch(setAutoTimezone(response.autoTimezone));
        dispatch(setEulaAccepted(response.eulaAccepted));
        dispatch(setTimezone(response.timezone));
        dispatch(setLanguage(response.language));
        dispatch(setChannelDetails(response.channelDetails));

        return response.roles.map((userRole): UserRoleInfo => {
          return {
            plantName: userRole.plantName,
            plantId: userRole.plantId,
            role: convertToPlantRole(userRole.role),
            invitationStatus: userRole.invitationStatus,
            assetGroupIds: userRole.assetGroupIds,
            selected: userRole.selected,
            visible: userRole.visible,
            channelDetails: response.channelDetails,
            plantSettings: userRole.plantSettings,
          };
        });
      },
    },
  });
};
