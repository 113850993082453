import { WarningAmberOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { memo } from 'react';

type Props = {
  onClose: () => void;
};

const CartridgeEmptyWarning = ({ onClose }: Props) => {
  return (
    <>
      <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
        <Stack
          direction={'row'}
          spacing={1}
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '34px',
              height: '34px',
              borderRadius: '50%',
              backgroundColor: '#FDF0E5',
            }}
          >
            <WarningAmberOutlined sx={{ color: '#C77700', fontSize: 24 }} />
          </Box>
          <Typography variant='h5' fontWeight={600}>
            Warning!
          </Typography>
        </Stack>
        <Typography variant='body1'>
          Purge is unavailable as the cartridge is empty. Please replace the cartridge and try
          again.
        </Typography>
        <Divider flexItem />
        <Box width={'100%'}>
          <Button
            variant='contained'
            color={'secondary'}
            fullWidth
            sx={{ fontWeight: 600 }}
            onClick={() => {
              onClose();
            }}
          >
            Ok
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default memo(CartridgeEmptyWarning);
