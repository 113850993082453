import { Box, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useGetApiKeys } from "api/users/useGetApiKeys";
import { memo, useMemo, useState } from "react";
import CreateView from "./CreateView";
import ListView from "./ListView";
import { useTranslation } from "languages";

type Props = {
  indent: string;
};

type Display = "list" | "create";

const ApiKeyManagement = ({ indent }: Props) => {
  const [display, setDisplay] = useState<Display>("list");

  // Fetch api keys
  const { data: keys, isLoading, isFetching, refetch } = useGetApiKeys();

  const handleGoToCreate = () => {
    setDisplay("create");
  };

  const handleClose = () => {
    refetch();
    setDisplay("list");
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => { 
    return {
      apiLabel: translate('menu_api_environment'),
      testEnvLabel: translate('menu_optify_test_env_label'),
      apiKeysLabel: translate('menu_api_keys_label'),
    };
  }, []);

  return (
    <>
      {display === "list" && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" mb={1}>
                {componentText.apiLabel}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" mb={3}>
                {componentText.testEnvLabel}{" "}
                <a
                  data-testid="manage_account_api_information_test_environment"
                  rel="noreferrer noopener"
                  target="_blank"
                  style={{ textDecoration: "underline", color: "#0B79D0" }}
                  href={"https://wiki.dodgeoptify.com"}
                >
                  wiki.dodgeoptify.com
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "32px",
                  letterSpacing: "0.15px",
                  pb: "1rem",
                }}
              >
                {componentText.apiKeysLabel}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: "1rem" }} />
            </Grid>
            <Grid item>
              <Box ml={indent}>
                <ListView
                  keys={keys}
                  isLoading={isLoading}
                  onCreateClick={handleGoToCreate}
                  afterDelete={() => refetch()}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      {display === "create" && <CreateView onClose={handleClose} refetchLoading={isFetching} />}
    </>
  );
};

export default memo(ApiKeyManagement);
