import { Typography, Grid, Button, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import style from './CreatePlantSuccessBox.module.scss';
import img from 'components/assets/img-create-plant.png';
import { Add, GroupAdd } from '@mui/icons-material';
import { useGetAuthData } from 'auth/hooks';
import { FC, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'languages';

interface CreatePlantSuccessProps {
  inDashboard?: boolean;
  plantName: string;
  onClose?: () => void;
}

const CreatePlantSuccessBox: FC<CreatePlantSuccessProps> = ({
  inDashboard,
  plantName,
  onClose,
}) => {
  const { userEmail } = useGetAuthData();
  const queryClient = useQueryClient();

  const handleClose = () => {
    updateUserRoles();
    onClose?.();
  };

  const updateUserRoles = () => {
    queryClient.resetQueries({
      queryKey: ['roles'],
    });
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      plantCreated: translate('plant_created_create_plant'),
      successfullyCreated: translate('successfully_created_create_plant'),
      plant: translate('plant_create_plant'),
      confirmationEmailSent: translate('confirmation_email_sent_create_plant'),
      addUsers: translate('add_users_create_plant'),
      addUsersDescription: translate('add_users_description_create_plant'),
      createAssets: translate('create_assets_create_plant'),
      createAssetsDescription: translate('create_assets_description_create_plant'),
      goToHomeScreen: translate('go_to_home_screen_create_plant'),
    };
  }, []);

  return (
    <Grid container>
      <Grid container item flexDirection='column' xs={6}>
        <Grid item>
          <Typography
            color='primary.dark'
            sx={{ fontWeight: 600 }}
            variant='h4'
            data-testid='createPlant-successBox-confirmation'
          >
            {componentText.plantCreated}
          </Typography>
          <Alert sx={{ mt: 4 }}>
            {componentText.successfullyCreated}{' '}
            <b data-testid='createPlant-successBox-plant'>{plantName}</b> {componentText.plant}.{' '}
            <br />
          </Alert>
          <Typography sx={{ mt: 2 }} variant='body1'>
            {componentText.confirmationEmailSent}{' '}
            <i data-testid='createPlant-successBox-email'>{userEmail}</i>
          </Typography>
          <Grid container direction='row' columnSpacing={4} sx={{ mt: 4 }}>
            <Grid container item direction='column' gap={2} xs={6}>
              <Button
                component={Link}
                to='/plants-management?redirect=addUsers'
                onClick={handleClose}
                variant='contained'
                color='secondary'
                startIcon={<GroupAdd />}
                fullWidth={true}
                sx={{ fontWeight: 600, height: '2.5rem' }}
                data-testid='createPlant-successBox-users'
              >
                {componentText.addUsers}
              </Button>
              <Typography variant='body1'>{componentText.addUsersDescription}</Typography>
            </Grid>
            <Grid container item flexDirection='column' gap={2} xs={6}>
              <Button
                component={Link}
                to='/plants-management?redirect=createAsset'
                onClick={handleClose}
                variant='outlined'
                color='secondary'
                fullWidth={true}
                startIcon={<Add />}
                sx={{ fontWeight: 600, height: '2.6rem' }}
                data-testid='createPlant-successBox-assets'
              >
                {componentText.createAssets}
              </Button>
              <Typography variant='body1'>{componentText.createAssetsDescription}</Typography>
            </Grid>
          </Grid>
          {!inDashboard && (
            <Button
              color='primary'
              component={Link}
              onClick={updateUserRoles}
              to='/dashboard'
              variant='contained'
              fullWidth={true}
              sx={{ mt: 7, color: 'white !important' }}
              data-testid='createPlant-successBox-homeScreen'
            >
              {componentText.goToHomeScreen}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={6} justifyContent='center' alignItems='center'>
        <Grid item>
          <img src={img} className={style.image} alt='DRG logo' />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreatePlantSuccessBox;
