import { Box, SxProps, Theme } from "@mui/material";
import { FC, PropsWithChildren } from "react";

const defaultProps: SxProps<Theme> = {
  background: "#ffffff",
  borderRadius: "16px",
  boxShadow:
    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
  display: "flex",
  justifyContent: "space-between",
  p: 4,
};

type DodgeCardProps = Readonly<{
  width: number | string;
  sx?: SxProps<Theme>;
}>;

const DodgeCard: FC<PropsWithChildren<DodgeCardProps>> = ({
  children,
  ...props
}) => {
  return (
    <Box sx={{ ...defaultProps, ...props, width: props.width }}>
      <Box width="100%">{children}</Box>
    </Box>
  );
};

export default DodgeCard;
