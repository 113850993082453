/* eslint-disable */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import TimePicker from 'features/assets-management/components/EventsTable/components/CreateEvent/TimePicker';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TimeOutput } from 'react-timekeeper';
import CheckIcon from '@mui/icons-material/Check';
import { useDeviceSize } from 'shared/responsive';
import { useTranslation } from 'languages';
import { useGetTranslatedDay } from 'features/channel-partners/reports/components/utils/useGetTranslatedDay';

type Props = {
  enabled: boolean;
  onChange: () => void;
  cronString: string | undefined;
  handleCronTemp: (cron: string) => void;
};

const dayInMinutes = 1440;

const intervals: string[] = ['Weekly', 'Daily'];
const days: string[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const AutomaticRawDataSetting: FC<Props> = ({ enabled, onChange, cronString, handleCronTemp }) => {
  const { translate } = useTranslation();
  const getTranslatedDay  = useGetTranslatedDay();
  const componentText = useMemo(() => {
    return {
      automaticGatewayRawDataCollection: translate("automatic_gateway_raw_data_collection_raw_data"),
      disabledRawData: translate("disabled_raw_data"),
      enabledRawData: translate("enabled_raw_data"),
      rawDataCollectionDescription: translate("raw_data_collection_description_raw_data"),
      collectMeasurementsRawData: translate("collect_measurements_raw_data"),
      interval: translate("interval_channel_partners"),
      weekly: translate("weekly_channel_partners"),
      daily: translate("daily_channel_partners"),
      day: translate("day_channel_partners"),
    };
  }, []);

  const intervals: {value: string, label: string}[] = [{value:'Weekly', label: componentText.weekly}, {value:'Daily', label: componentText.daily}];

  const offset = new Date().getTimezoneOffset();
  const [interval, setInterval] = useState<string>(intervals[0].value);
  const [selectedDays, setSelectedDates] = useState<string[]>([]);
  const [isListExpanded, setListExpanded] = useState<boolean>(false);

  const [timeObject, setTimeObject] = useState<TimeOutput>();
  const [initialTime, setInitialTime] = useState<string>();
  const { isPhone, isTablet } = useDeviceSize();

  const handleSetCron = () => {
    if (cronString) {
      const cron = cronString.split(' ');
      let minutes: string | number = Number(cron[1]);
      let hours = Number(cron[2]);
      const time = hours * 60 + minutes;
      let convertedTime = time - offset;
      let dayOffset = 0;
      if (convertedTime > dayInMinutes) {
        convertedTime -= dayInMinutes;
        dayOffset++;
      } else if (convertedTime < 0) {
        convertedTime += dayInMinutes;
        dayOffset--;
      }
      const convertedHours = Math.floor(convertedTime / 60);
      let convertedMinutes: string | number = convertedTime - convertedHours * 60;
      if (convertedMinutes < 10) {
        convertedMinutes = `0${convertedMinutes}`;
      }
      if (convertedTime === dayInMinutes) {
        setInitialTime('12:00 am');
      } else if (convertedHours === 0) {
        setInitialTime(`12:${convertedMinutes} am`);
      } else if (convertedHours === 12) {
        setInitialTime(`12:${convertedMinutes} pm`);
      } else {
        setInitialTime(
          convertedHours > 12
            ? `${convertedHours - 12}:${convertedMinutes} pm`
            : `${convertedHours}:${convertedMinutes} am`,
        );
      }

      const cornDays = cron
        .slice(5)[0]
        .split(',')
        .concat([])
        .map((d) => {
          const dayIndex = days.findIndex((day) => day.toUpperCase().includes(d));
          if (dayIndex === -1) {
            return undefined;
          }
          let convertedDayIndex = dayIndex + dayOffset;
          if (convertedDayIndex > 6) {
            convertedDayIndex = 0;
          } else if (convertedDayIndex < 0) {
            convertedDayIndex = 6;
          }
          return days[convertedDayIndex];
        })
        .filter((d) => d !== undefined) as string[];
      if (cornDays && cornDays.length) setSelectedDates(cornDays);
      cornDays && cornDays.length > 1 ? setInterval('Daily') : setInterval('Weekly');
    }
  };

  useEffect(() => {
    handleSetCron();
  }, [cronString]);

  useEffect(() => {
    let hour;
    let minute;
    if (timeObject) {
      hour = timeObject.hour;
      minute = timeObject.minute;
    } else if (initialTime) {
      hour = initialTime.split(/:|\s/)[0];
      minute = initialTime.split(/:|\s/)[1];
    } else {
      const now = new Date();
      hour = now.getHours().toString();
      minute = now.getMinutes().toString();
    }
    const calculatedTime = Number(hour) * 60 + Number(minute);
    let convertedTime = calculatedTime + offset;
    let dayOffset = 0;
    if (convertedTime > dayInMinutes) {
      convertedTime -= dayInMinutes;
      dayOffset++;
    } else if (convertedTime < 0) {
      convertedTime += dayInMinutes;
      dayOffset--;
    }
    const convertedHours = Math.floor(convertedTime / 60);
    let convertedMinutes: string | number = convertedTime - convertedHours * 60;
    if (convertedMinutes < 10) {
      convertedMinutes = `0${convertedMinutes}`;
    }

    const mappedDays = selectedDays
      .map((day) => {
        const numericDay = days.findIndex((d) => d.startsWith(day));
        if (numericDay === -1) {
          return 'skip';
        }
        let convertedDayIndex = numericDay + dayOffset;
        if (convertedDayIndex < 0) {
          convertedDayIndex = 6;
        } else if (convertedDayIndex > 6) {
          convertedDayIndex = 0;
        }
        return days[convertedDayIndex];
      })
      .filter((days) => days !== 'skip');

    if (interval === 'Weekly') {
      handleCronTemp(
        `0 ${convertedMinutes} ${convertedHours} * * ${
          mappedDays.length && mappedDays[0].slice(0, 3).toUpperCase()
        }`,
      );
    } else {
      handleCronTemp(
        `0 ${convertedMinutes} ${convertedHours} * * ${
          mappedDays.length && mappedDays.map((d) => d.slice(0, 3).toUpperCase()).join(',')
        }`,
      );
    }
  }, [interval, selectedDays, timeObject]);

  const responsiveProps = {
    textWidth: isPhone ? '100%' : '12.5rem',
    inputMinWidths: isPhone ? '15rem' : '13.37rem',
    customXS: isPhone ? 12 : isTablet && 4,
    timePosition: isPhone || isTablet ? 'flex-start' : 'flex-end',
    timeLeftMargin: isPhone || isTablet ? 0 : 0.5,
    timeTopMargin: isPhone || isTablet ? 1 : 0,
  };
  return (
    <React.Fragment>
      <Grid item container xs={12} spacing={3} mb='2.5%' mt='1%' alignItems='center'>
        <Grid item lg={4} xs={isPhone ? 12 : isTablet && 4}>
          <Typography fontWeight='600' mt={'0.3rem'} width={responsiveProps.textWidth}>
           {componentText.automaticGatewayRawDataCollection}
          </Typography>
        </Grid>
        <Grid item xs={8} ml='-1%'>
          {componentText.disabledRawData}
          <Switch checked={enabled} onChange={onChange} />
          {componentText.enabledRawData}
        </Grid>
      </Grid>
      <Grid item>
        <Typography align='justify' fontSize={'0.9rem'} mt={2} color='gray'>
          {componentText.rawDataCollectionDescription}
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={3} my='2.5%' alignItems='center'>
        <Grid item lg={2.5} xs={12}>
          <Typography fontWeight='600' mt={'0.3rem'} width={responsiveProps.textWidth}>
            {componentText.collectMeasurementsRawData}
          </Typography>
        </Grid>
        <Grid item xs={9.5} container alignItems='center' spacing={1}>
          <Grid item xs={responsiveProps.customXS} lg={4}>
            <FormControl fullWidth size='small'>
              <InputLabel id='interval-label'>{componentText.interval}</InputLabel>
              <Select
                data-testid='manage_account_edit_plant_interval_input'
                labelId='interval-label'
                id='interval-select'
                disabled={!enabled}
                value={interval}
                label={componentText.interval}
                size='small'
                onChange={(e) => {
                  const intervalValue = e.target.value as string;
                  setInterval(intervalValue);
                  intervalValue === 'Daily' ? setSelectedDates(['Tuesday']) : setSelectedDates([]);
                }}
              >
                {intervals.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>on</Grid>
          <Grid
            item
            lg={3}
            xs={responsiveProps.customXS}
            minWidth={responsiveProps.inputMinWidths}
            maxWidth='25rem'
            width={isListExpanded ? responsiveProps.inputMinWidths : 'auto'}
            maxHeight='3rem'
          >
            <FormControl fullWidth size='small'>
              <InputLabel id='day-label'>{componentText.day}</InputLabel>
              <Select
                data-testid='manage_account_edit_plant_day_input'
                labelId='day-label'
                id='day-select'
                disabled={!enabled}
                value={selectedDays}
                label={componentText.day}
                size='small'
                multiple={interval === 'Daily' ? true : false}
                onOpen={() => setListExpanded(true)}
                onClose={() => setListExpanded(false)}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  return setSelectedDates(typeof value === 'string' ? value.split(',') : value);
                }}
              >
                {days.map((item) => (
                  <MenuItem value={item} key={item}>
                    {isListExpanded && interval === 'Daily' && (
                      <Box width='2.25rem'>
                        {selectedDays.includes(item) && (
                          <ListItemIcon key={item}>
                            <CheckIcon sx={{ fontSize: '1rem' }} />
                          </ListItemIcon>
                        )}
                      </Box>
                    )}
                    {getTranslatedDay(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent={responsiveProps.timePosition}>
        <Grid item xs={12} lg={9.5} container spacing={1}>
          <Grid
            item
            xs={responsiveProps.customXS}
            lg={4}
            mt={responsiveProps.timeTopMargin}
            ml={responsiveProps.timeLeftMargin}
            data-testid='manage_account_edit_plant_time_input'
          >
            <TimePicker
              setTimeObject={setTimeObject}
              initialTime={initialTime}
              disabled={!enabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AutomaticRawDataSetting;
