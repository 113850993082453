import { Box, useTheme } from "@mui/material";
import { ReactNode} from "react";

export interface DataTableAdditionalBarProps {
  row?: ReactNode;
  displayTable?: boolean;
}

const DataTableAdditionalBar = (
  props: DataTableAdditionalBarProps
) => {
  const theme = useTheme();
  return <Box sx={props.displayTable === false ? {display: "flex", ml: -1, mt: '-3.5rem', mb: '1rem', [theme.breakpoints.down("lg")]: {mt: 0, mb: 0}} : { display: 'flex', ml: -1 }}>{props.row}</Box>;
};

export default DataTableAdditionalBar;