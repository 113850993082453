import { useAuthMutation } from 'api/queries';
import {
  Gateway,
  Sensor,
} from "features/gateways-management/components/SensorAssignmentTable/models/SensorAssignment.models";
import { useSelector } from "react-redux";
import { selectPlantId } from "store/accountSlice";

export interface ResponseModel {
  monitored : Gateway[];
  unassigned : Sensor[];
}

export type Params = {
  gatewayId: string;
};

export interface GatewaysAssignment {
  id: string;
  sensorsIds: number[];
}

export const usePostMonitoredSensors = () => {
  const selectedPlantId = useSelector(selectPlantId);
  return useAuthMutation<Params, number[]>({
    url(params) {
      return `plants/${selectedPlantId}/gateways/${params.gatewayId}/monitored-sensors`;
    },
    method: 'POST',
  });
};
