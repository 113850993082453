import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface Body {
  autoTimezone: boolean;
  timezone: string;
}

export const usePutTimezoneSettings = () => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_save_timezone_settings_toast'),
    };
  }, []);
  return useAuthMutation<undefined, Body>({
    url: `users/datetime-settings`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('roles') });
        setTimeout(
          () =>
            queryClient.invalidateQueries({
              predicate: (query) => query.queryKey.includes('datetime-settings'),
            }),
          500,
        );
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
