import { FC, memo } from 'react';
import ManagePlants from './ManagePlants';
import UserProfile from './UserProfile';
import Notification from './Notification/Notification';
import Help from './Help';
import { useMenuButtons } from '../menu-buttons';
import UnitSettings from './UnitSettings';
import ApiKeyManagement from './ApiKeyManagement/ApiKeyManagement';
import AutomaticEventClosure from './AutomaticEventClosure';
import { Box } from '@mui/material';
import { GetPlantsBody } from 'api/plants/useGetPlants';
import { PersonalInfo } from './models/models';
import ReleaseNotes from './ReleaseNotes/ReleaseNotes';
import { useDeviceSize } from 'shared/responsive';

interface ManageAccountDetailsProps {
  activeLink?: string;
  email: string;
  plantList?: GetPlantsBody;
  searchInput: string;
  setSearchInput: (text: string) => void;
  plantName: string;
  managePlantsPage: number;
  managePlantsPageSize: number;
  setManagePlantsPage: (page: number) => void;
  setManagePlantsPageSize: (page: number) => void;
  managePlantsSearchText: string;
  setManagePlantsSearchText: (text: string) => void;
  managePlantsSortOrder: string;
  setManagePlantsSortOrder: (sortOrder: string) => void;
  isPlantsListLoading: boolean;
  totalPlantListSize: number | null;
  isEditMode: boolean;
  isConfirmOpen: boolean;
  isChangePersonalInfoLoading: boolean;
  personalInfo: PersonalInfo;
  setPersonalInfo: (newInfo: PersonalInfo) => void;
  onBackClick: () => void;
  onConfirmClick: () => void;
}

const ManageAccountDetails: FC<ManageAccountDetailsProps> = ({
  activeLink,
  personalInfo,
  email,
  plantList,
  searchInput,
  setSearchInput,
  plantName,
  managePlantsPage,
  managePlantsPageSize,
  setManagePlantsPage,
  setManagePlantsPageSize,
  managePlantsSearchText,
  setManagePlantsSearchText,
  managePlantsSortOrder,
  setManagePlantsSortOrder,
  isPlantsListLoading,
  totalPlantListSize,
  isEditMode,
  isConfirmOpen,
  isChangePersonalInfoLoading,
  setPersonalInfo,
  onBackClick,
  onConfirmClick,
}) => {
  const { isPhone, isTablet } = useDeviceSize();

  const responsiveProps = {
    // Here define left margin/indent
    indent: isPhone ? '0.3rem' : isTablet ? '0.7rem' : '0.8rem',
  };

  const menuButtons = useMenuButtons();

  const getComponent = () => {
    if (activeLink === menuButtons[0].id)
      return (
        <Box>
          <ManagePlants
            plantList={plantList}
            email={email}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            page={managePlantsPage}
            pageSize={managePlantsPageSize}
            setPage={setManagePlantsPage}
            setPageSize={setManagePlantsPageSize}
            searchText={managePlantsSearchText}
            setSearchText={setManagePlantsSearchText}
            sortOrder={managePlantsSortOrder}
            setSortOrder={setManagePlantsSortOrder}
            isLoading={isPlantsListLoading}
            totalRecords={totalPlantListSize}
          />
        </Box>
      );
    else if (activeLink === menuButtons[1].id)
      return (
        <Box ml={responsiveProps.indent}>
          <UserProfile
            isEditMode={isEditMode}
            personalInfo={personalInfo}
            email={email}
            setPersonalInfo={setPersonalInfo}
            onBackClick={onBackClick}
            onConfirmClick={onConfirmClick}
            isConfirmOpen={isConfirmOpen}
            isLoading={isChangePersonalInfoLoading}
          />
        </Box>
      );
    else if (activeLink === menuButtons[2].id)
      return (
        <Box ml={responsiveProps.indent}>
          <Notification plantName={plantName}/>
        </Box>
      );
    else if (activeLink === menuButtons[3].id)
      return (
        <Box ml={responsiveProps.indent}>
          <AutomaticEventClosure plantName={plantName} email={email} />
        </Box>
      );
    else if (activeLink === menuButtons[4].id)
      return (
        <Box ml={responsiveProps.indent}>
          <UnitSettings />
        </Box>
      );
    else if (activeLink === menuButtons[5].id)
      return <ApiKeyManagement indent={responsiveProps.indent} />;
    else if (activeLink === menuButtons[6].id) return <ReleaseNotes />;
    else if (activeLink === menuButtons[7].id)
      return (
        <Box ml={responsiveProps.indent}>
          <Help />
        </Box>
      );
  };

  const component = getComponent();

  return <>{component}</>;
};

export default memo(ManageAccountDetails);
