import { useTranslation } from 'languages';

const prepareLabel = (label: string) => {
  return label.replace(/\[.*?\]/g, '').trim();
};
const getUnit = (label: string) => {
  const test = label.match(/\[(.*?)\]/);
  if (test) {
    return `[${test[1]}]`;
  } else {
    return '';
  }
};
export const useGetAxisLabel = (label: string) => {
  const axisLabel = prepareLabel(label);
  const unit = getUnit(label);

  const { translate } = useTranslation();

  const translationDictionary = {
    'Radial Velocity': translate('fft_radial_velocity'),
    'Radial Acceleration': translate('fft_radial_acceleration'),
    'Envelope Velocity': translate('fft_envelope_velocity'),
    'Envelope Acceleration': translate('fft_envelope_acceleration'),
    'Axial Velocity': translate('fft_axial_velocity'),
    'Tangential Velocity': translate('fft_tangential_velocity'),
    'Axial Acceleration': translate('fft_axial_acceleration'),
    'Tangential Acceleration': translate('fft_tangential_acceleration'),
    'Radial Envelope Velocity': translate('fft_radial_envelope_velocity'),
    'Axial Envelope Velocity': translate('fft_axial_envelope_velocity'),
    'Tangential Envelope Velocity': translate('fft_tangential_envelope_velocity'),
    'Radial Envelope Acceleration': translate('fft_radial_envelope_acceleration'),
    'Axial Envelope Acceleration': translate('fft_axial_envelope_acceleration'),
    'Tangential Envelope Acceleration': translate('fft_tangential_envelope_acceleration'),
    Frequency: translate('fft_frequency'),
    Time: translate('fft_time'),
    Date: translate('fft_date'),
    Amplitude: translate('fft_amplitude'),
  };

  if (axisLabel && axisLabel in translationDictionary) {
    return {
      axisLabelTranslated: `${translationDictionary[axisLabel as keyof typeof translationDictionary]} ${unit}`,
    };
  }
  return { axisLabelTranslated: `${axisLabel} ${unit}` };
};
