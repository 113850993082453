import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

interface AssetBody {
  assetGroupId: number | undefined;
  assetId: number;
  configuration: {
    [key: string]: {
      label: string;
      type: string;
      value: string | number;
      unit: string;
    };
  };
  name: string | undefined;
  note: string | undefined;
  partDescription: string | undefined;
  partId: number | undefined;
  partNumber: string | undefined;
  sensorsQuantity: number;
  serialNumber: string | undefined;
  thumbnailId: string | undefined;
  type: string | undefined;
  assetApplicationId: number | null;
  partProperties:
    | {
        [key: string]: {
          label: string;
          type: string;
          value: string | number;
          unit: string;
        };
      }
    | {};
}

export const useGetAssetById = (assetId: number | undefined) => {
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_asset_toast'),
    };
  }, []);

  return useAuthQuery<AssetBody>({
    url: `plants/${plantId}/assets/${assetId}`,
    queryOptions: {
      enabled: !!assetId,
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
