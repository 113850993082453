import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAuthMutation } from 'api/queries';

export const usePutChangeLanguage = (language: string) => {
  const queryClient = useQueryClient();

  return useAuthMutation<undefined, any>({
    url: `users/language?language=${language}`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        toast.success('Language settings have been successfully changed. Page will be reloaded.');
        setTimeout(() => window.location.reload(), 1500);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('roles') });
      },
      onError: () => {
        toast.error('Failed to change language settings');
      },
    },
  });
};
