import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export interface CatalogPartBody {
  id: number;
  number: string;
  assetType: string;
  description: string;
  numberOfAssetInstances: number;
  manufacturer: null | string;
  properties?: Record<string, string | number>;
}

interface DodgePartApiModel {
  records: CatalogPartBody[];
  totalRecords: number;
}

export const useGetCatalogParts = (
  numOfDodgePartsPerPageDisplayed: number,
  page: number,
  search: string,
  assetType: string,
  manufacturerIds: string | undefined,
  sortOrder: string,
  isMotorBearing?: boolean,
) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_dodge_parts_toast'),
    };
  }, []);

  return useAuthQuery<DodgePartApiModel>({
    url: 'catalogue-parts',
    params: {
      pageSize: numOfDodgePartsPerPageDisplayed.toString(),
      pageNumber: page.toString(),
      sortOrder: sortOrder,
      searchString: search,
      assetType: assetType,
      ...(manufacturerIds && { manufacturerId: manufacturerIds }),
      ...(isMotorBearing !== undefined && { isMotorBearing: String(isMotorBearing) }),
    },
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
