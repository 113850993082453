import { useMsal } from '@azure/msal-react';
import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { CopyrightBar } from '../../CopyrightBar';
import style from '../OnboardingLayout/OnboardingLayout.module.scss';
import { useGetImageUrl } from 'helpers/useGetImageUrl';

const LayoutContext = React.createContext(null);

type PropsTypes = {
  children: React.ReactNode;
};

const CookiesLayout = ({ children }: PropsTypes) => {
  const { accounts } = useMsal();

  return (
    <LayoutContext.Provider value={null}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        className={style.page}
        gap='1.5rem'
      >
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          paddingLeft={4}
          paddingRight={4}
          paddingTop={4}
        >
          <Link to={'/'}>
            <img src={useGetImageUrl('public/dodge.png')} className={style.img} alt='DRG logo' />
          </Link>
        </Box>
        <Box display='flex' justifyContent='center'>
          {children}
        </Box>
        <Box>
          <CopyrightBar logged={accounts}></CopyrightBar>
        </Box>
      </Box>
    </LayoutContext.Provider>
  );
};

export default React.memo<PropsTypes>(CookiesLayout);
