import Plot from 'react-plotly.js';
import {Data, Shape } from 'plotly.js';
import { FFTData } from 'features/assets-management/models/FFTChart.models';
import { FC, memo, useMemo, useEffect } from 'react';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { HarmonicShape } from './FFTCharts';
import { selectColorScale } from 'features/assets-management/store/assetsManagementSlice';
import { useSelector } from 'react-redux';
import { compareMeasurements } from './utils/compareMeasurements';
import { useGetAxisLabel } from './useGetAxisLabel';

interface Layout {
    title: string;
    autosize: boolean;
    showlegend: boolean;
    width?: number | undefined;
    height: number | undefined;
    margin?: { t: number | undefined, l: number | undefined }
    xaxis?: { title: string },
    shapes?: Partial<Shape>[];
  }

interface FFTChartProps {
  xAxisLabel: string;
  yAxisLabel: string;
  chartData: FFTData[];
  marginTop?: number;
  height?: number;
  pr?: string;
  openTreeMenu?: boolean;
  harmonicShapes?: HarmonicShape[]
}

const Spectogram: FC<FFTChartProps> = ({
  xAxisLabel,
  yAxisLabel,
  chartData,
  marginTop,
  height,
  pr,
  openTreeMenu,
  harmonicShapes,
}) => {

const spectogramData: {xValues: number[], yzValues: Map<string, number[]>} = { xValues: [], yzValues: new Map()};

const dateFormatter = useDateFormatter();
const {axisLabelTranslated : xAxisLabelTranslated} = useGetAxisLabel(xAxisLabel); 

const colorScale = useSelector(selectColorScale)

chartData.sort(compareMeasurements).forEach((fft:FFTData, index: number) =>{
  const tempZvalues: number[] = []
  fft.data.forEach((data: {time: number, value: number}) =>{
    if(index === 0){
        spectogramData.xValues.push(data.time);
    }
    tempZvalues.push(data.value)
  });
  spectogramData.yzValues.set(fft.measurmentDate, tempZvalues)
});

  const spectogramPlotData: Data[] = useMemo(() =>{
   const yValues: string[] = [];
    const zValues: number[][] = [];
    spectogramData.yzValues.forEach((value: number[], key: string) => {
        yValues.push(String(dateFormatter(key, 'MM/DD/YYYY|HH:mm:ss')));
        zValues.push(value);
      });
  
     return (
        [
            {
              z: zValues,
              y: yValues,
              x: spectogramData.xValues,
              type: 'heatmap',
              hoverongaps: false,
              colorscale: colorScale!== 'Default' ? colorScale : undefined
            }
          ]
     )
  }, [spectogramData]);

  const shapes = useMemo(() =>{
    const numberOfMeasurements = chartData ? chartData.length : 0;
    const coordinates:{x0: number, x1: number, y0: number, y1: number, color: string}[] = []; 
    if(numberOfMeasurements){
      const step = 1/numberOfMeasurements;
      chartData.forEach((fft:FFTData, i: number) =>{
        const measurement = String(dateFormatter(fft.measurmentDate, 'MM/DD/YYYY|HH:mm:ss'));
        harmonicShapes && harmonicShapes.length && harmonicShapes.filter( shape => shape.measurementDate === measurement).forEach((shape) =>{
          coordinates.push({x0: (shape.x0 + shape.x1)/2, x1: (shape.x0 + shape.x1)/2, y0: i*step, y1: (i + 1) * step, color: shape.fillcolor.slice(0, -4) + '1)'})
        })
      })
    }
    return coordinates.length ? coordinates : []
  }, [chartData, harmonicShapes]);

  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
  }, [pr, openTreeMenu]);

    const layout: Layout = {
      title: '',
      autosize: true,
      showlegend: false,
      width: undefined,
      height: 650,
      margin: {
        t: marginTop ? marginTop + 25 : undefined,
        l: 150,
      },
      xaxis: { title: xAxisLabelTranslated},
      shapes: shapes.map( shape => ({...shape, type: 'line', yref: 'paper', line:{color: shape.color, width: 2, dash: 'dash'}}))
    };

    return (
        <Plot
          data={[...spectogramPlotData]}
          layout={layout}
          style={{ width: '100%', height: '100%' }}
          useResizeHandler
          config={{ responsive: true, scrollZoom: true, autosizable: true }}

        />
    );
};

export default memo(Spectogram);
