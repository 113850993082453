import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IconButton, InputAdornment, Popover, TextField } from '@mui/material';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useGetLangText } from '../../lang';
import '../../styles/time.scss';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import useDateFormatter from 'components/Uitls/useDateFormatter';

interface TimePickerProps {
  setTimeObject : (time: any) => void;
  size ?: 'small' | 'medium';
  initialTime ?: string;
  disabled ?: boolean;
  isEdit ?: boolean;
}

const TimePicker : FC<TimePickerProps> = ({ setTimeObject, isEdit, size = 'small', disabled, initialTime }) => {
  const dateFormatter = useDateFormatter();

  const initialHours = useMemo(
    () =>
      dateFormatter(
        moment()
          .add({ m: isEdit ? 0 : 15 })
          .toDate(),
        'hh:mm a',
      ),
    [dateFormatter],
  );
  const [time, setTime] = useState<Dayjs>(dayjs(initialHours, 'hh:mm a'));
  
  useEffect(() => {
    if (initialTime) {
      setTime(dayjs(initialTime, 'hh:mm a'));
    }
  },[initialTime]);

  const handleSetNewTime = (newTime : string) => {
    setTime(dayjs(newTime));
    setTimeObject({
      hour: dayjs(newTime).format('HH'),
      minute: dayjs(newTime).format('mm'),
      ampm: dayjs(newTime).format('a'),
      hour12: dayjs(newTime).format('hh'),
    });
  };
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const langText = useGetLangText({});

  return (
    <>
      <TextField
        fullWidth
        label={langText.createEvent.timePickerLabel}
        id='time'
        variant='outlined'
        disabled={Boolean(disabled)}
        size={size}
        value={time.format('hh:mm a') || ''}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={!disabled ? handleClick : undefined} disabled={open}>
                <AccessTimeIcon color='primary' />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onClick={!disabled ? handleClick : undefined}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            overflowX: 'visible',
            overflowY: 'visible',
            boxShadow:
              '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px rgb(0 0 0 / 14%), 0px 1px 8px rgb(0 0 0 / 12%)',
            background: '#fff0',
            borderRadius: '12px',
          },
        }}
      >
        <div className='wrapper'>
          <StaticTimePicker
            defaultValue={dayjs()}
            value={time}
            onClose={handleClose}
            onAccept={() => setAnchorEl(null)}
            onChange={(newTime) => handleSetNewTime(newTime as any)}
            localeText={{cancelButtonLabel: langText.cancelButton, okButtonLabel: langText.okButton, toolbarTitle: langText.selectTimeText}}
          />
        </div>
      </Popover>
    </>
  );
};

export default memo(TimePicker);
