import React from 'react';
import DOMPurify from 'dompurify';

interface ContentViewerProps {
  htmlContent: string;
}

const ContentViewer: React.FC<ContentViewerProps> = ({ htmlContent }) => {
  const sanitizedHTML = { __html: DOMPurify.sanitize(htmlContent) };

  return (
    <div className="view ql-editor" dangerouslySetInnerHTML={sanitizedHTML} />
    );
};

export default ContentViewer;