import { SensorType } from 'models/sensor/models';

export const getUserFriendlySensorTypeName = (
  type: SensorType | string,
  short: boolean = false,
) => {
  if (short) {
    switch (type) {
      case 'Sensor':
        return 'OPTIFY Sensor';
      case 'PerformanceSensor':
        return 'OPTIFY Performance Sensor';
      case 'IntelliLube':
        return 'Intelli-Lube™';
      case 'PerformanceV1':
        return 'OPTIFY Sensor';
      case 'PerformanceV2':
        return 'OPTIFY Performance Sensor';
      case 'Intelli-Lube':
        return 'Intelli-Lube™';
      case 'Performance Sensor':
        return 'OPTIFY Performance Sensor';
      case 'BreatherSensor':
        return 'OPTIFY Breather';
      default:
        return 'OPTIFY Sensor';
    }
  }

  switch (type) {
    case 'Sensor':
      return 'OPTIFY Sensor';
    case 'PerformanceSensor':
      return 'OPTIFY Performance Sensor';
    case 'IntelliLube':
      return 'Intelli-Lube™';
    case 'PerformanceV1':
      return 'OPTIFY Sensor';
    case 'PerformanceV2':
      return 'OPTIFY Performance Sensor';
    case 'Intelli-Lube':
      return 'Intelli-Lube™';
    case 'Performance Sensor':
      return 'OPTIFY Performance Sensor';
    case 'BreatherSensor':
      return 'OPTIFY Breather';
    case 'Breather Sensor':
      return 'OPTIFY Breather';
    default:
      return 'OPTIFY Sensor';
  }
};
