import { FC, memo, useMemo } from 'react';
import { FormControl, TextField, MenuItem, Grid, Box } from '@mui/material';
import { getAssetIcons } from 'features/dashboard/components/CurrentAssetStatusTile/Tile/AssetTile';
import { DisplayType } from './EditAsset';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useTranslation } from 'languages';
import { AssetInfo } from 'api/assets';

interface EditAssetHeaderProps {
  data: AssetInfo[];
  selectedAsset: number;
  assetType?: string;
  setSelectedAsset: (e: number) => void;
  setDisplay: (x: DisplayType) => void;
  selectedImage: string;
}
const EditAssetHeader: FC<EditAssetHeaderProps> = ({
  data,
  assetType,
  selectedAsset,
  setSelectedAsset,
  setDisplay,
  selectedImage,
}) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      selectAsset: translate('asset_select_asset_label'),
    };
  }, []);
  return (
    <Grid item container alignItems='center'>
      <Grid item container xs={4} md={2} alignItems='center' my={3}>
        <Box
          width='98px'
          onClick={() => setDisplay('image')}
          height='95px'
          sx={{
            cursor: 'pointer',
            background:
              selectedImage !== 'none' ? `url(${selectedImage}) 0/cover no-repeat` : undefined,
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          {selectedImage === 'none' && assetType ? (
            <Box
              sx={{
                width: '98px',
                height: '95px',
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {getAssetIcons(assetType, true).bigIcon}
            </Box>
          ) : null}
          {
            <Box
              width='38px'
              height='38px'
              sx={{
                background: '#337179',
                borderRadius: '4px',
                position: 'absolute',
                bottom: '-8px',
                right: '-8px',
                boxShadow:
                  '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <PhotoCameraIcon
                sx={{ fontSize: '24px', color: 'white', cursor: 'pointer' }}
                onClick={() => setDisplay('image')}
              />
            </Box>
          }
        </Box>
      </Grid>
      <Grid item container md={10} xs={8} alignItems='center'>
        <FormControl fullWidth sx={{ mt: 3, mb: 2 }}>
          <TextField
            data-testid='asset_edit_select_asset_input'
            value={selectedAsset}
            select
            size='small'
            label={componentText.selectAsset}
            onChange={(e) => setSelectedAsset(Number(e.target.value))}
          >
            {data.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default memo(EditAssetHeader);
