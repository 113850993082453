import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Button,
  IconButton,
  Typography,
  TextField,
  Box,
} from '@mui/material';
import { useGetAssetApplications } from 'api/assets/useGetAssetApplications';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { LoadingButton } from '@mui/lab';
import { useCreateAssetApplication } from 'api/plants/useCreateAssetApplication';
import { useDeleteAssetApplication } from 'api/plants/useDeleteAssetApplication';
import { useTranslation } from 'languages';
import { useGetAssetApplicationName } from './useGetAssetApplicationName';

interface Props {
  assetApplicationId: number | null;
  setAssetApplicationId: Dispatch<SetStateAction<number | null>>;
}

const ManageAssetApplication: FC<Props> = ({ assetApplicationId, setAssetApplicationId }) => {
  const { data: assetApplications, isLoading, isFetching } = useGetAssetApplications();
  const [newApplicationName, setNewApplicationName] = useState('');
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { mutate: createMutation, isPending: isCreateLoading } = useCreateAssetApplication(
    (newAppId) => {
      setAssetApplicationId(newAppId);
      setOpenCreateModal(false);
    },
  );
  const getAssetApplicationName = useGetAssetApplicationName()
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      applicationLabel: translate('plants_application_label'),
      createNewApplicationButton: translate('plants_create_new_application_button'),
      createApplication: translate('create_application_app'),
      createCustomApplication: translate('create_custom_application_app'),
      application: translate('application_app'),
      uniqueName: translate('unique_name_app'),
      createButton: translate('create_button_app'),
      delete: translate('delete_app'),
      deleteWarning: translate('delete_warning_app'),
      confirm: translate('confirm_app'),
      cancel: translate('cancel_app'),
    };
  }, []);

  const [deleteAppId, setDeleteAppId] = useState<number>();

  const { mutate: deleteMutation, isPending: isDeleteLoading } = useDeleteAssetApplication(() => {
    if (assetApplicationId === deleteAppId) {
      setAssetApplicationId(null);
    }
    setDeleteAppId(undefined);
  });

  const handleDeleteMutation = () => {
    deleteMutation({
      params: {
        assetApplicationId: deleteAppId!,
      },
    });
  };

  const handleCreateMutation = () => {
    createMutation({
      params: {
        name: newApplicationName,
      },
    });
  };

  return (
    <>
      <FormControl fullWidth size='small'>
        <InputLabel required>{componentText.applicationLabel}</InputLabel>
        <Select
          value={assetApplicationId?.toString() ?? ''}
          required
          renderValue={(val) =>
            getAssetApplicationName(assetApplications?.find((application) => application.id.toString() === val)?.name)
          }
          size='small'
          label={componentText.applicationLabel}
          disabled={isLoading || isFetching}
          data-testid={'asset_application_list'}
          onChange={(e) => {
            if (Number(e.target.value) === -1) {
              setOpenCreateModal(true);
              return;
            }
            setAssetApplicationId(Number(e.target.value));
          }}
        >
          {assetApplications
            ?.filter((assetApplication) => assetApplication.isDodge)
            .map((assetApplication) => (
              <MenuItem key={assetApplication.id} value={assetApplication.id.toString()}>
                {getAssetApplicationName(assetApplication.name)}
              </MenuItem>
            ))}
          <Divider />
          <MenuItem value={-1}>
            <Button
              variant='contained'
              fullWidth
              data-testid='plants_management_create_new_application_button'
            >
              {componentText.createNewApplicationButton}
            </Button>
          </MenuItem>
          {assetApplications
            ?.filter((assetApplication) => !assetApplication.isDodge)
            .map((assetApplication) => (
              <MenuItem
                key={assetApplication.id}
                value={assetApplication.id.toString()}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {assetApplication.name}
                <IconButton
                  size='small'
                  color='primary'
                  sx={{
                    padding: 0,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteAppId(assetApplication.id);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {openCreateModal && (
        <DodgeModal
          title={componentText.createApplication}
          open={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
        >
          <Typography sx={{ margin: '1rem 0' }}>{componentText.createCustomApplication}</Typography>
          <TextField
            data-testid='plants_management_create_application_modal_window_application_input'
            variant='outlined'
            size='small'
            label={componentText.applicationLabel}
            fullWidth
            helperText={componentText.uniqueName}
            value={newApplicationName}
            onChange={(e) => setNewApplicationName(e.target.value)}
          />
          <Divider sx={{ margin: '1rem 0' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              data-testid='plants_management_create_application_modal_window_create_button'
              disabled={newApplicationName.length === 0}
              loading={isCreateLoading}
              variant='contained'
              color='secondary'
              onClick={handleCreateMutation}
            >
              {componentText.createButton}
            </LoadingButton>
          </Box>
        </DodgeModal>
      )}
      {deleteAppId !== undefined && (
        <DodgeModal
          title={`${componentText.delete} ${assetApplications?.find((assetApp) => assetApp.id === deleteAppId)?.name}?`}
          open={deleteAppId !== undefined}
          onClose={() => setDeleteAppId(undefined)}
        >
          <Typography sx={{ mt: '1rem' }}>{componentText.deleteWarning}</Typography>
          <Divider sx={{ margin: '1rem 0' }} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant='outlined'
              color='secondary'
              sx={{ color: 'black' }}
              onClick={() => setDeleteAppId(undefined)}
            >
              {componentText.cancel}
            </Button>
            <LoadingButton
              data-testid='plants_management_delete_application_modal_window_confirm_button'
              loading={isDeleteLoading}
              variant='contained'
              color='secondary'
              onClick={handleDeleteMutation}
            >
              {componentText.confirm}
            </LoadingButton>
          </Box>
        </DodgeModal>
      )}
    </>
  );
};

export default ManageAssetApplication;
