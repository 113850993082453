import { Box, Tooltip } from '@mui/material';

type Position =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'right-end'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-start'
  | 'top-end'
  | 'top-start';

export const shortenName = (assetName : string, length = 25, position : Position = 'bottom') => {
  if (assetName.length > length) {
    return (
      <Tooltip title={<h3>{assetName}</h3>} placement={position}>
        <Box sx={{ cursor: 'pointer' }}>{assetName.slice(0, length) + '...'}</Box>
      </Tooltip>
    );
  }
  return assetName;
};
