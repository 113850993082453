import { TableCell, TableSortLabel, Typography, Box } from '@mui/material';
import { Background } from 'features/dashboard/models/backgroundCell.model';
import { ReactNode } from 'react';
import { DataTableHeadCell, Entity, SortOrder } from '../models';

interface DataTableDefaultHeadProps<T> {
  headCells: DataTableHeadCell<T>[];
  onRequestSort: (property: keyof T) => void;
  order: SortOrder;
  minimised ?: boolean;
  orderBy: keyof T | undefined;
  cellBackground?: Background;
}

const DataTableDefaultHead = <T extends Entity>(
  props : DataTableDefaultHeadProps<T> & { children ?: ReactNode },
) => {
  const { headCells, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property : keyof T) => () => {
    onRequestSort(property);
  };

  return (
    <>
      {headCells.map((headCell, index) => (
        <TableCell
          key={headCell.label}
          // align={headCell.numeric ? 'right' : 'left'}
          // padding={headCell.disablePadding ? 'none' : 'normal'}
          sortDirection={orderBy === headCell.columnProperty ? order : false}
          sx={{
            paddingLeft: props.minimised ? 0.5 : 2,
            paddingBottom: props.minimised ? 0.5 : 2,
            backgroundColor:
              props.cellBackground && props.cellBackground.cells.includes(index)
                ? props.cellBackground.color
                : '#fff',
          }}
        >
          <Box display='flex' alignItems='center' gap='0.5rem'>
            {headCell.renderIcon ? headCell.renderIcon() : null}
            {headCell.sortable && !!headCell.columnProperty ? (
              <TableSortLabel
                active={orderBy === headCell.columnProperty}
                direction={orderBy === headCell.columnProperty ? order : 'asc'}
                onClick={createSortHandler(headCell.columnProperty)}
              >
                <Typography fontWeight='700'>{headCell.label}</Typography>
              </TableSortLabel>
            ) : (
              <Typography fontWeight='700'>{headCell.label}</Typography>
            )}
            {headCell.columnIcon}
          </Box>
        </TableCell>
      ))}
    </>
  );
};

export default DataTableDefaultHead;
