import { FC, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Divider,
  Button,
  Autocomplete,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { IndustryRow, industries } from './private/IndustryRow';
import { useCreatePlant } from 'api/plants';
import { IndustryCategory } from './private/industries.model';
import { LoadingButton } from '@mui/lab';
import DodgeCard from 'components/DodgeCard/DodgeCard';
import { CreatePlantSuccessBox } from '../CreatePlantSuccessBox';
import { allCountries } from 'country-region-data';
import { AxiosError } from 'axios';
import type { PanelType } from './types';
import { useDeviceSize } from 'shared/responsive';
import { useTranslation } from 'languages';

export const CreatePlantStyle = {
  textField: {
    '& label': {
      color: '#1E1E1E',
    },
    '& input': {
      color: 'black',
      fontWeight: '500',
    },
  },
};

type CreatePlantProps = {
  inDashboard?: boolean;
  onClose?: () => void;
};

const CreatePlant: FC<CreatePlantProps> = ({ inDashboard, onClose }) => {
  const [panel, setPanel] = useState<PanelType>('panel1');
  const [industryCategory, setIndustryCategory] = useState<IndustryCategory>(
    industries[0].industryCategory,
  );
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [company, setCompany] = useState('');
  const [industry, setIndustry] = useState(industries[0].industry[0]);
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      createPlantText: translate('menu_create_plant_button'),
      createInfo: translate('menu_create_plant_info'),
      plantName: translate('menu_plant_name_label'),
      company: translate('menu_company_label'),
      country: translate('menu_country_label'),
      state: translate('menu_state_label'),
      postalCode: translate('menu_postal_code_label'),
      address: translate('menu_address_label'),
      addressOptional: translate('menu_address_optional_label'),
      city: translate('menu_city_label'),
      industry: translate('menu_industry_label'),
      industryCategory: translate('menu_industry_category_label'),
      nameInUse: translate('name_in_use'),
      uniqueValue: translate('unique_value'),
      backButton: translate('menu_back_button'),
      createButton: translate('create_button_label'),
    };
  }, []);

  const countriesList = useMemo(
    () => [
      'United States',
      ...allCountries.map((country) => country[0]).filter((country) => country !== 'United States'),
    ],
    [],
  );
  const statesList = useMemo(
    () => allCountries.find((element) => element[0] === country)?.[2].map((region) => region[0]),
    [country],
  );

  const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const { mutate: createPlantMutation, isPending } = useCreatePlant(() => {
    setPanel('panel2');
  });

  const isCountryInList = useMemo(() => {
    return countriesList.includes(country);
  }, [country]);

  const getIndastryCategory = (category: IndustryCategory) => {
    return industries.find((item) => item.industry.includes(category))
      ?.industryCategory as IndustryCategory;
  };

  const sendCreatePlantRequest = () => {
    const industryCategory = getIndastryCategory(industry as IndustryCategory);

    createPlantMutation(
      {
        body: {
          name,
          company,
          industryCategory,
          industry,
          country,
          settings: {
            enableOffset: false,
            enableAlertAndAlarmForGeneralUser: true,
            enableLoadMeasurementsForPlantViewers: false,
          },
          city,
          address: `${address1}\n${address2}`,
          state,
          postalCode,
        },
      },
      {
        onError(error) {
          if (error.message === 'UniqueKeyViolation') {
            // Plant name in use
            setNameError(true);
          }
        },
      },
    );
  };

  const { isPhone, isTablet } = useDeviceSize();
  const responsiveProps = {
    modalWidth: isTablet ? '45rem' : isPhone ? '25rem' : '55rem',
  };

  return (
    <DodgeCard width={panel === 'panel2' ? '70rem' : responsiveProps.modalWidth}>
      {
        {
          panel1: (
            <>
              <Box display='flex' flexDirection='column' justifyContent='center' gap='1.1rem'>
                <Typography
                  alignSelf='flex-start'
                  variant='h5'
                  color='primary.dark'
                  sx={{ fontWeight: 600 }}
                  data-testid='createPlant-form-title'
                >
                  {componentText.createPlantText}
                </Typography>
                <Typography data-testid='createPlant-form-text'>
                  {componentText.createInfo}
                </Typography>
                <Grid container direction='row' spacing={3}>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        required
                        id='outlined-required'
                        label={componentText.plantName}
                        placeholder={componentText.plantName}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        onKeyDown={handleKeyboardEvent}
                        data-testid='createPlant-form-name'
                        sx={{ ...CreatePlantStyle.textField }}
                        size='small'
                        error={nameError}
                        helperText={nameError ? componentText.nameInUse : componentText.uniqueValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        required
                        id='outlined-required'
                        label={componentText.company}
                        placeholder={componentText.company}
                        onChange={(e) => {
                          setCompany(e.target.value);
                        }}
                        onKeyDown={handleKeyboardEvent}
                        data-testid='createPlant-form-company'
                        sx={{ ...CreatePlantStyle.textField }}
                        size='small'
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <IndustryRow
                  industryCategory={industryCategory}
                  onChangeIndustryCategory={(industryCategory) => {
                    setIndustryCategory(industryCategory);
                  }}
                  industry={industry}
                  onChangeIndustry={(industry) => {
                    setIndustry(industry);
                  }}
                ></IndustryRow>
                <Divider></Divider>
                <Grid container direction='row' spacing={3}>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true} size='small'>
                      <Autocomplete
                        data-testid='createPlant-form-country'
                        freeSolo
                        forcePopupIcon
                        options={countriesList}
                        id='outlined-required'
                        inputValue={country}
                        onInputChange={(_, newValue) => {
                          setCountry(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label={componentText.country}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            sx={{ ...CreatePlantStyle.textField }}
                            onKeyDown={handleKeyboardEvent}
                            size='small'
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth={true}>
                      <Autocomplete
                        data-testid='createPlant-form-state'
                        freeSolo
                        forcePopupIcon
                        options={statesList ? statesList : []}
                        id='state'
                        inputValue={state}
                        onInputChange={(_, newValue) => setState(newValue)}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label={componentText.state}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            sx={{ ...CreatePlantStyle.textField }}
                            onKeyDown={handleKeyboardEvent}
                            size='small'
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth={true}>
                      <TextField
                        required
                        id='postal_code'
                        label={componentText.postalCode}
                        placeholder={componentText.postalCode}
                        onChange={(e) => {
                          setPostalCode(e.target.value);
                        }}
                        onKeyDown={handleKeyboardEvent}
                        data-testid='createPlant-form-postal'
                        sx={{ ...CreatePlantStyle.textField }}
                        size='small'
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction='row' spacing={3}>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        required
                        id='outlined-required'
                        label={componentText.address}
                        placeholder={componentText.address}
                        onChange={(e) => {
                          setAddress1(e.target.value);
                        }}
                        onKeyDown={handleKeyboardEvent}
                        data-testid='createPlant-form-address1'
                        sx={{ ...CreatePlantStyle.textField }}
                        size='small'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        id='outlined-required'
                        label={componentText.addressOptional}
                        placeholder={componentText.addressOptional}
                        onChange={(e) => {
                          setAddress2(e.target.value);
                        }}
                        onKeyDown={handleKeyboardEvent}
                        data-testid='createPlant-form-address2'
                        sx={{ ...CreatePlantStyle.textField }}
                        size='small'
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction='row' spacing={3}>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        required
                        id='outlined-required'
                        label={componentText.city}
                        placeholder={componentText.city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        onKeyDown={handleKeyboardEvent}
                        data-testid='createPlant-form-city'
                        sx={{ ...CreatePlantStyle.textField }}
                        size='small'
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider></Divider>
                <Grid container justifyContent='space-between' mb={-1}>
                  <Grid item>
                    {!inDashboard && (
                      <Button
                        component={Link}
                        to='/onboarding/welcome'
                        variant='outlined'
                        color='secondary'
                        sx={{ color: 'text.primary', fontWeight: 600 }}
                        startIcon={<ArrowBack />}
                        data-testid='createPlant-form-back'
                      >
                        {componentText.backButton}
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      variant='contained'
                      color='secondary'
                      loading={isPending}
                      disabled={
                        !name ||
                        !company ||
                        !isCountryInList ||
                        !city ||
                        !address1 ||
                        !state ||
                        !postalCode
                      }
                      sx={{ fontWeight: 600 }}
                      onClick={sendCreatePlantRequest}
                      data-testid='createPlant-form-create'
                    >
                      {componentText.createButton}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </>
          ),
          panel2: (
            <CreatePlantSuccessBox
              inDashboard={inDashboard}
              plantName={name}
              onClose={() => {
                setPanel('panel1');
                onClose?.();
              }}
            />
          ),
        }[panel]
      }
    </DodgeCard>
  );
};

export default CreatePlant;
