import { useAuthQuery } from 'api/queries';
import {
  ConnectivityType,
  GatewaysData,
  SignalStrength,
  StatusType,
} from 'features/gateways-management/models/gateway.model';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

interface GatewayApiModel {
  deviceId : string;
  name: string;
  macAddress: string;
  ipAddress: string;
  status: StatusType;
  lastActivityTime: string;
  connectivity: ConnectivityType;
  signalStrength: SignalStrength;
  allowToDelete: boolean;
  model: string;
  cellularExpirationDate: string | null;
  isCellularGateway: boolean;
  serialNumber: string;
}

interface ApiData {
  gateways : GatewayApiModel[];
  onlineGatewaysCount : number;
  offlineGatewaysCount : number;
  unassignedSensorsCount : number;
}

export const useGetGateways = () => {
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_gateways_toast'),
    };
  }, []);

  return useAuthQuery<GatewaysData, ApiData>({
    url: `gateways`,
    params: {
      plantId: plantId.toString(),
    },
    queryOptions: {
      select: (response) => {
        return {
          gateways: response.gateways.map((data) => ({
            deviceId: data.deviceId,
            name: data.name,
            macAddress: data.macAddress,
            ipAddress: data.ipAddress,
            connectivity: data.connectivity,
            signalStrength: data.signalStrength,
            status: data.status,
            lastActivityTime: data.lastActivityTime,
            allowToDelete: data.allowToDelete,
            model: data.model,
            cellularExpirationDate: data.cellularExpirationDate,
            isCellularGateway: data.isCellularGateway,
            serialNumber: data.serialNumber,
          })),
          onlineGatewaysCount: response.onlineGatewaysCount,
          offlineGatewaysCount: response.offlineGatewaysCount,
          unassignedSensorsCount: response.unassignedSensorsCount,
        };
      },
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
