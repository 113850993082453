import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { GatewayReport } from '../models/gateway.model';

export interface GatewaysState {
  modal: {
    macAddress: string | null;
    serialNumber: string | null;
    gatewayName: string | null;
    gatewayId: string | null;
  },
  csvReport: GatewayReport,
}

const initialState: GatewaysState = {
  modal: {
    macAddress: null,
    serialNumber: null,
    gatewayName: null,
    gatewayId: null
  },
  csvReport: {
    data:[], 
    headers:[], 
    filename:""
  },
};

export const gatewaysSlice = createSlice({
  name: 'gateways',
  initialState,
  reducers: {
    setMacAddress: (state, action: PayloadAction<string>) => {
      state.modal.macAddress = action.payload
    },
    setSerialNr: (state, action: PayloadAction<string>) => {
      state.modal.serialNumber = action.payload
    },
    setGatewayNm: (state, action: PayloadAction<string>) => {
      state.modal.gatewayName = action.payload
    },
    setGatewayId: (state, action: PayloadAction<string>) => {
      state.modal.gatewayId = action.payload
    },
    setGatewayClear: (state) => {
      state = initialState;
    },
    setCsvReport: (state, action) => {
      state.csvReport = action.payload;
    },
  },
});

export const { setMacAddress, setSerialNr, setGatewayNm, setGatewayId, setGatewayClear, setCsvReport } = gatewaysSlice.actions;
export const selectMacAddress = (state: RootState) => state.gateways.modal.macAddress;
export const selectSerialNr = (state: RootState) => state.gateways.modal.serialNumber;
export const selectGatewayName = (state: RootState) => state.gateways.modal.gatewayName;
export const selectGatewayId = (state: RootState) => state.gateways.modal.gatewayId;
export const selectCsvReport = (state: RootState) => state.gateways.csvReport;

export default gatewaysSlice.reducer;
