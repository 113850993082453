import { useAuthQuery } from 'api/queries';

export interface GetUnitsResponse {
  [key: string]: {
    metName: string;
    impName: string;
    isImperialOn: boolean;
    label: string;
  };
}

export const useGetUnits = () => {
  return useAuthQuery<GetUnitsResponse>({
    url: `users/units`,
  });
};
