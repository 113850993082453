import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export interface GetPlantsBody {
  totalRecords: number;
  records: {
    plantId: number;
    plantName: string;
    role: string;
    invitationStatus: string;
  }[];
}

export const useGetPlants = (
  pageSize: number,
  pageNumber: number,
  searchText: string,
  sortOrder: string,
) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_plant_list_toast'),
    };
  }, []);

  return useAuthQuery<GetPlantsBody>({
    url: `users/plants`,
    params: {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
      searchString: searchText,
      sortOrder,
    },
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
