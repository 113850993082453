import { Grid, Typography } from "@mui/material";
import { Help } from "@mui/icons-material";
import { memo } from "react";
import { useDeviceSize } from "shared/responsive";

type Props = {
  infoText: string;
  disableIcon?: boolean,
};

const SelectDataPrompt = ({ infoText, disableIcon }: Props) => {
  const { isPhone, isTablet } = useDeviceSize();

  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ pt: isPhone  ? '2rem' : isTablet? '5rem' : '8rem',  pb: "12rem"}}
    >
      { !disableIcon && 
        (
        <Grid item xs={1}>
         <Help fontSize="large" color="primary" />
        </Grid>
        )}
      <Grid item xs={11}>
        <Typography variant="h5" fontWeight={600} data-testid="dashboard_tiles_no_data_text">
          {infoText}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default memo(SelectDataPrompt);
