import { useAuthQuery } from 'api/queries';
import { AxiosError } from 'axios';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface Response {
  isPowerOn: boolean;
  lastMeasurementDate: string;
  serialNumber: string;
  fillLevelValue?: number;
}

export const useGetSensorsLastKnownState = (assetId?: number, serialNumber?: string, isLubricator?: boolean) => {
  const { translate } = useTranslation();
  const componentText = useMemo(
    () => ({
      toastErrorMess: translate('failed_to_load_last_known_state_toast'),
    }),
    [],
  );

  return useAuthQuery<Response>({
    url: `assets/${assetId}/sensors/${serialNumber}/sensor-last-known-status`,
    queryOptions: {
      enabled: Boolean(assetId && serialNumber) && isLubricator,
      retry: (failureCount, error) => {
        const caughtError = error as AxiosError;
        if (failureCount > 3) {
          return false;
        }
        if (caughtError?.response?.status === 400) {
          return false;
        }
        return true;
      },
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
