import {
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { useGetAutoClose } from 'api/users/useGetAutoCloseEvents';
import { usePutAutoCloseEvents } from 'api/users/usePutAutoCloseEvents';
import React, { useEffect, FC, memo, useMemo } from 'react';
import { fontLarge, fontMedium } from './styles/fontStyle';
import { useTranslation } from 'languages';
import { useDeviceSize, useResponsiveLanguage } from 'shared/responsive';

interface AutomaticEventClosureProps {
  plantName: string;
  email: string;
}

const AutomaticEventClosure: FC<AutomaticEventClosureProps> = ({ plantName, email }) => {
  const { data, isSuccess } = useGetAutoClose();
  const { mutate: putAutoCloseMutation } = usePutAutoCloseEvents();
  const [timePeriod, setTimePeriod] = React.useState<number>(0);

  useEffect(() => {
    if (isSuccess) setTimePeriod(Number(data));
  }, [data]);

  const handleChange = (event : SelectChangeEvent) => {
    putAutoCloseMutation({
      params: {
        closeEventsAfterDays: Number(event.target.value),
      },
    });
  };
  
  const responsiveProps = {
    formStyle: useResponsiveLanguage({
      en: { xs: { width: '100%', mt: 1}, md: { width: '100%'}, lg: { width: '50%'}}
    }),
  }
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      autoClose: translate('menu_events_auto_close'),
      allChanges: translate('menu_events_all_changes_applied', {plantName}),
      noteOnce: translate('menu_events_note_once'),
      closeEvents: translate('menu_events_close_events'),
      timePeriod: translate('menu_events_time_period'),
      never: translate('menu_events_never'),
      days7: translate('menu_events_7_days'),
      days14: translate('menu_events_14_days'),
      days30: translate('menu_events_30_days'),
      days60: translate('menu_events_60_days'),
      days90: translate('menu_events_90_days'),
    };
  }, []);
  return (
    <Grid container>
      <Typography sx={{ ...fontLarge, mb: '1rem', mr: '2rem' }}>
        {componentText.autoClose}
        <br />
        {componentText.allChanges}
      </Typography>
      <Typography sx={{ ...fontMedium, mb: '1rem', mr: '2rem' }}>
        {componentText.noteOnce}
      </Typography>
      <Grid item container sx={{ py: '0.5rem' }} alignItems="center">
        <Grid item xs={12} lg={3}>
          {componentText.closeEvents}
        </Grid>
        <Grid item xs={12} lg={9} mt={2}>
          <FormControl sx={{ ...responsiveProps.formStyle}}>
            <InputLabel id="time-period-label">{componentText.timePeriod}</InputLabel>
            <Select
              labelId="time-periood-label"
              id="time-period-select"
              value={timePeriod.toString()}
              label={componentText.timePeriod}
              onChange={handleChange}
              data-testid="manage_account_time_period_input"
            >
              <MenuItem value={0}>{componentText.never}</MenuItem>
              <MenuItem value={7}>{componentText.days7}</MenuItem>
              <MenuItem value={14}>{componentText.days14}</MenuItem>
              <MenuItem value={30}>{componentText.days30}</MenuItem>
              <MenuItem value={60}>{componentText.days60}</MenuItem>
              <MenuItem value={90}>{componentText.days90}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(AutomaticEventClosure);
