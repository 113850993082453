import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

export interface GetSensorsByAssetIdResponse {
  firmwareVersion: string;
  location: string;
  serialNumber: string;
  type: string;
  isMonitored: boolean;
  pin: '-' | '****';
  properties: {
    [key: string]: {
      label: string;
      type: string;
      unit: string;
      value: number | boolean | string;
    };
  };
  additionalPropertiesDb?: {
    cartridgePN?: string;
    cartridgeInstallationDate?: string;
  };
  deviceFlag?: string;
}

export const useGetSensorsByAssetId = (assetId: number | undefined) => {
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_sensors_toast'),
    };
  }, []);

  return useAuthQuery<GetSensorsByAssetIdResponse[]>({
    url: `plants/${plantId}/assets/${assetId}/sensors`,
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
