import { WarningAmberOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';

type Props = {
  onClose: () => void;
  onAccept: () => void;
  isLoading: boolean;
  fillLevel: number;
  unableToPurge: number;
  ableToPurge: number;
};

const CartridgeLevelLowWarning = ({
  onClose,
  onAccept,
  isLoading,
  fillLevel,
  unableToPurge,
  ableToPurge,
}: Props) => {
  return (
    <>
      <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
        <Stack
          direction={'row'}
          spacing={1}
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '34px',
              height: '34px',
              borderRadius: '50%',
              backgroundColor: '#FDF0E5',
            }}
          >
            <WarningAmberOutlined sx={{ color: '#C77700', fontSize: 24 }} />
          </Box>
          <Typography variant='h5' fontWeight={600}>
            Warning!
          </Typography>
        </Stack>
        <Typography variant='body1'>
          {`Cannot complete a purge of ${unableToPurge}cc. Current cartridge fill level is ${fillLevel}cc. Would you like to purge ${ableToPurge}cc?`}
        </Typography>
        <Divider flexItem />
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            width: '100%',
          }}
        >
          <Button
            fullWidth
            variant={'outlined'}
            color={'secondary'}
            sx={{ color: 'black', fontWeight: 600 }}
            onClick={() => {
              onClose();
            }}
          >
            NO
          </Button>
          <LoadingButton
            fullWidth
            loading={isLoading}
            variant={'contained'}
            color={'secondary'}
            onClick={onAccept}
            sx={{ fontWeight: 600 }}
          >
            Yes
          </LoadingButton>
        </Box>
      </Stack>
    </>
  );
};

export default CartridgeLevelLowWarning;
