import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export const useSyncSensor = (assetId: number, serialNumber: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastSyncSensorError: translate('toast_sync_sensor_error'),
      toastSyncSensorSuccess: translate('toast_sync_sensor_success'),
    };
  }, []);

  return useAuthMutation<{}>({
    url: `assets/${assetId}/sensors/${serialNumber}/sync`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.toastSyncSensorSuccess);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes(`sensors`) });
        onSuccess?.();
      },
      onError: () => {
        toast.error(componentText.toastSyncSensorError);
      },
    },
  });
};
