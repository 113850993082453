import { useAuthMutation } from 'api/queries';
import { useTranslation } from 'languages';
import { toast } from 'react-toastify';

interface ValidatePlantIdParams {
  plantId: number;
}

export const useValidatePlantId = () => {
  const { translate } = useTranslation();

  const componentText = {
    toastErrorFailedToJoin: translate("toast_error_failed_to_join_plant"),
  }
  
  return useAuthMutation({
    url: (params: ValidatePlantIdParams) => `plants/${params.plantId}/status`,
    method: 'GET',
    mutationOptions: {
      // eslint-disable-next-line
      onError(error: any) {
        error && error.response.status === 429
          ? toast.error(error.response.data.message)
          : toast.error(componentText.toastErrorFailedToJoin);
      },
    },
  });
};
