import { Box, Typography } from '@mui/material';
import { useGetAxisLabel } from 'features/assets-management/components/charts/useGetAxisLabel';
import { FC } from 'react';

type Props = {
  isEnvelop : boolean;
  amplitudeLabel : string | null;
};

const SpectogramHeader : FC<Props> = ({ isEnvelop, amplitudeLabel }) => {
  const {axisLabelTranslated : amplitudeTranslated} = useGetAxisLabel('Amplitude');
  const {axisLabelTranslated : dateTranslated} = useGetAxisLabel('Date');

  return (
    <Box
      position='absolute'
      width='100%'
      height='20px'
      sx={{ zIndex: 11, display: 'flex', justifyContent: 'space-between', color: 'black' }}
      top={isEnvelop ? 80 : 15}
    >
      <Typography sx={{ width: '130px', ml: '20px', textAlign: 'center' }}>{dateTranslated}</Typography>
      <Typography
        data-testid='assets_management_vibration_fft_spectrogram_units'
        sx={{ width: '135px', textAlign: 'center' }}
      >{`${amplitudeTranslated} [${amplitudeLabel ?? ''}]`}</Typography>
    </Box>
  );
};

export default SpectogramHeader;
