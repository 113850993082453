import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { getSensorIco } from "helpers/getSensorIco"
import React, { FC } from "react"
import { SensorInfo, SensorType } from "./MissingCartridge"

const sensorMap = new Map<SensorType | undefined, {label: string, iconSize: string, ml: string}>();
sensorMap.set('IntelliLube', {label: 'Intelli-Lube', iconSize: '1.1rem', ml: '2rem'});
sensorMap.set('BreatherSensor', {label: 'Breather', iconSize: '1.5rem', ml: '2rem'});

type Props = {
    data?: SensorInfo,
}
const SensorData:FC<Props> = ({data}) =>{
    const sensor = sensorMap.get(data?.type);
    return(
        <React.Fragment>
            <Box display='flex' alignItems='center' gap={2} mt={1}>
             <Box height={sensor?.iconSize ?? 0}>{getSensorIco(data?.type ?? '', true)}</Box>
            <Typography fontSize='1.1rem' letterSpacing='0.15px' fontWeight='600'>{`${sensor?.label ?? ''} (${data?.serialNumber || ''})`}</Typography>
          </Box>
          <Typography fontSize='0.95' ml={sensor?.ml ?? 0} mb={1}>{data?.location || ''}</Typography>
        </React.Fragment>
    )
}

export default SensorData;