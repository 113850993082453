import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Grid, Slider, Tooltip, Typography } from '@mui/material';
import { usePurgeIntellilube } from 'api/plants/usePurgeIntellilube';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { ModalHeader } from '../ModalHeader';
import CartridgeEmptyWarning from './CartridgeEmptyWarning';
import CartridgeLevelLowWarning from './CartridgeLevelLowWarning';
import { useTranslation } from 'languages';

interface PurgeProps {
  open: boolean;
  onClose: () => void;
  assetId: number;
  location: string;
  serialNumber: string;
  fillLevel?: number;
}

// make sure the values are increasing
const marks = [
  { value: 2, label: '2cc' },
  { value: 4, label: '4cc' },
  { value: 6, label: '6cc' },
];

const Purge: FC<PurgeProps> = ({ open, onClose, location, serialNumber, assetId, fillLevel }) => {
  const [value, setValue] = useState<number>(2);
  const [warning, setWarning] = useState<'empty' | 'insufficient'>();

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      selectedLabel: translate("purge_selected"),
      purgeLabel: translate("purge_label"),
      purgeStart: translate("purge_start"),
      backLabel: translate("menu_back_button_label"),
      textPartI : translate("purge_text_part_I"),
      textPartII : translate("purge_text_part_II"),
      textPartIII : translate("purge_text_part_III"),
    };
  }, []);

  const { mutate: purgeIntellilubeMutation, isPending } = usePurgeIntellilube(onClose);

  const handleChange = useCallback((_: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  }, []);

  const purgeRequest = useCallback(
    (amount: number) => {
      purgeIntellilubeMutation(
        {
          params: {
            assetId: assetId,
            serialNumber: serialNumber,
            purgesCount: amount / 2,
          },
        },
        {
          onSuccess: () => {
            setWarning(undefined);
            onClose();
          },
        },
      );
    },
    [assetId, serialNumber],
  );

  const purgeIntellilubeHandler = useCallback(() => {
    if (fillLevel !== undefined) {
      if (fillLevel === 0) {
        setWarning('empty');
        return;
      }
      if (fillLevel < value) {
        setWarning('insufficient');
        return;
      }
    }

    purgeRequest(value);
  }, [assetId, purgeIntellilubeMutation, serialNumber, value]);

  const textAboveTheSlider = useMemo(() => {
    const dispenseLabel = marks.find((mark) => value === mark.value)?.label;
    const dispenseLabelHTML = (
      <Box sx={{ fontWeight: 'bold', textDecoration: 'underline', mx: 0.5, display: 'inline' }}>
        {dispenseLabel}
      </Box>
    );
    return (
      <Box mr={10}>
        {componentText.textPartI} {dispenseLabelHTML} {componentText.textPartII}
        {value > 1 ? componentText.textPartIII : '.'}
      </Box>
    );
  }, [value, componentText]);

  const mainContent = useMemo(() => {
    return (
      <Grid container mt={1.5} spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6' fontWeight={600}>
            {componentText.selectedLabel}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={-2}>
          <Typography>{`${serialNumber}, ${location}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          {textAboveTheSlider}
        </Grid>
        <Grid item xs={12} mx={1}>
          <Slider
            value={value}
            step={2}
            min={2}
            max={6}
            color='secondary'
            marks={marks}
            valueLabelDisplay='auto'
            onChange={handleChange}
            valueLabelFormat={marks.find((mark) => value === mark.value)?.label ?? value.toString()}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Divider />
        </Grid>
        <Grid item xs={12} container justifyContent='space-between'>
          <Button
            variant='outlined'
            color='secondary'
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            onClick={onClose}
          >
            {componentText.backLabel}
          </Button>
          <Tooltip title={isPending ? 'Purge may take up to a few minutes long.' : ''}>
            <Box>
              <LoadingButton
                loading={isPending}
                variant='contained'
                color='secondary'
                sx={{ color: 'text.primary', fontWeight: 'bold' }}
                onClick={purgeIntellilubeHandler}
                data-testid='plant_management_sensors_purge_modal_window_save_button'
              >
                {componentText.purgeStart}
              </LoadingButton>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }, [value, marks, serialNumber, location, textAboveTheSlider, isPending]);

  return (
    <DodgeModal title={<ModalHeader name={componentText.purgeLabel} />} open={open} onClose={onClose} width='464px'>
      {warning === 'empty' && (
        <CartridgeEmptyWarning
          onClose={() => {
            setWarning(undefined);
            onClose();
          }}
        />
      )}
      {warning === 'insufficient' && fillLevel && (
        <CartridgeLevelLowWarning
          onClose={() => {
            setWarning(undefined);
            onClose();
          }}
          fillLevel={fillLevel}
          unableToPurge={value}
          ableToPurge={fillLevel}
          isLoading={isPending}
          onAccept={() => purgeRequest(marks.find((m) => m.value >= fillLevel)?.value ?? 0)}
        />
      )}
      {warning === undefined && mainContent}
    </DodgeModal>
  );
};

export default memo(Purge);
