import React from 'react';
import { createRoot } from 'react-dom/client';

import { PublicClientApplication } from '@azure/msal-browser';

import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material';
import { msalConfig } from 'auth/msalConfig';
import 'react-datepicker/dist/react-datepicker.css';
import ReactGA from 'react-ga4';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './app/store';
import './fonts/archivo.ttf';
import './index.css';
import Routes from './routes/Routes';
import * as serviceWorker from './serviceWorker';
import theme from './theme/theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { queryClient } from 'api/queryClient';

ReactGA.initialize(
  `${process.env.REACT_APP_GA_MEASUREMENT_ID}`,
  // {
  //   gaOptions: {
  //     debug_mode: true,
  //   },
  //   gtagOptions: {
  //     debug_mode: true,
  //   },
  // }
);

export const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Routes />
                <ToastContainer
                  transition={Slide}
                  theme='colored'
                  autoClose={2500}
                  pauseOnFocusLoss
                  pauseOnHover
                  closeButton
                />
              </BrowserRouter>
            </ThemeProvider>
          </LocalizationProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>,
);

serviceWorker.unregister();
