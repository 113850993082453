import RefreshIcon from '@mui/icons-material/Refresh';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { usePostDecommissionSensor } from 'api/assets/usePostDecommissionSensor';
import {
  GetSensorsByAssetIdResponse,
  useGetSensorsByAssetId,
} from 'api/plants/useGetSensorsByAssetId';
import { useSetIntellilubeOnOff } from 'api/plants/useSetIntellilubeOnOff';
import { useUpdateSensor } from 'api/plants/useUpdateSensor';
import { RoleRequired } from 'components/RoleRequired';
import { alarmColor } from 'features/dashboard/colors/colors';
import { UserRole } from 'features/plants-management/models/user.model';
import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Purge from './Purge/Purge';
import TransferSensorModal from './TransferSensor/TransferSensorModal';
import {
  accelerometerRangeConfigurationList,
  measurementIntervalHoursList,
  measurementIntervalMinutesList,
} from './list-option';

import SyncIcon from '@mui/icons-material/Sync';
import SyncError from 'features/dashboard/components/AdditionalErrors/SyncError';

import { ReportProblemOutlined } from '@mui/icons-material';
import { useGetSensorsLastKnownState } from 'api/plants/useGetSensorsLastKnownState';
import { useSyncSensor } from 'api/plants/useSyncSensor';
import { AxiosError } from 'axios';
import IncompatibleCartridgeError from 'features/dashboard/components/AdditionalErrors/IncompatibleCartridgeError';
import MissingCartridgeError from 'features/dashboard/components/AdditionalErrors/MissingCartridgeError';
import ProjectedUsed from 'features/dashboard/components/AdditionalErrors/ProjectedUsed';
import { useTimeConverter } from 'features/dashboard/utils/formatDate';
import DecommissionWarning from './DecommissionWarning';
import IntellilubeChangeCartridgeSize from './IntellilubeChangeCartridgeSize';
import IntellilubeChangeDischargePeriod from './IntellilubeChangeDischargePeriod';
import { useTranslation } from 'languages';
import { useDeviceSize, useResponsiveLanguage } from 'shared/responsive';
import { EditAssetTabsRefProps } from './EditAssetTabs';
import MissingCartridge from '../../MissingCartridge/MissingCartridge';

const ableToTransferSensors: UserRole[] = [
  'Super Administrator',
  'Plant Administrator',
  'General User',
];

export type Type = 'edwin' | 'ares' | 'lagging' | 'lubricator';

interface SensorsFormProps {
  onDecomission?: () => void;
  selectedAsset: number;
  changeTab: (tab: number) => void;
  selectedAssetName?: string;
  refetchAsset: () => void;
  disableInput?: boolean;
  sensorSerialNumber?: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setDisabledSave: Dispatch<SetStateAction<boolean>>;
}
const SensorsForm = forwardRef<EditAssetTabsRefProps, SensorsFormProps>(
  (
    {
      selectedAsset,
      onDecomission,
      changeTab,
      selectedAssetName,
      disableInput,
      refetchAsset,
      sensorSerialNumber,
      setIsLoading,
      setDisabledSave,
    },
    ref,
  ) => {
    const [canSwitchTabs, setCanSwitchTabs] = useState(false);
    const { data: sensors, refetch: refetchSensors } = useGetSensorsByAssetId(selectedAsset);
    const isSensors = sensors && sensors.length > 0;
    const { calculateTime } = useTimeConverter();

    useEffect(() => {
      if (sensors?.length === 0 && canSwitchTabs) changeTab(0);
    }, [sensors, canSwitchTabs, changeTab]);

    const [type, setType] = useState('Sensor');

    const isEdwin = type === 'Sensor';
    const isLubricator = type === 'IntelliLube';
    const isBreather = type === 'BreatherSensor';
    const [openCartridgeSize, setOpenCartridgeSize] = useState(false);
    const [openDischargePeriod, setOpenDischargePeriod] = useState(false);
    const [modalOpen, setModalOpen] = useState<'purge' | 'decommission'>();
    const [turnOn, setTurnOn] = useState<boolean>(true);
    const [serialNumber, setSerialNumber] = useState(sensorSerialNumber ?? '');
    const [location, setLocation] = useState('');
    const [selectedSensorMonitored, setSelectedSensorMonitored] = useState(false);
    const [newSensorProperties, setNewSensorProperties] = useState<{
      [key: string]: string | boolean | number;
    }>({});
    const [isDesynchronized, setIsDesynchronized] = useState(false);
    const [isMissingCartdrige, setIsMissingCartdrige] = useState(false);
    const [isIncompatible, setIsIncompatible] = useState(false);
    const [isProjected, setIsProjected] = useState(false);
    const [helperErrorText, setHelperErrorText] = React.useState<string | undefined>();
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [selectedSensor, setSelectedSensor] = useState<GetSensorsByAssetIdResponse>();
    const [lastMeasurementDate, setLastMeasurementDate] = useState('');
    const [lastSerialNumber, setLastSerialNumber] = useState('');
    const [cartridgePN, setCartridgePN] = useState<string>('');
    const [isCartridgeSizeModalOpen, setIsCartridgeSizeModalOpen] = useState<boolean>(false);
    const dateCache = useRef('');

    const {
      data: lastKnownState,
      isLoading: isLastKnownStateLoading,
      isFetching: isLastKnownStateFetching,
      refetch: refetchLastKnownState,
      error,
    } = useGetSensorsLastKnownState(selectedAsset, serialNumber, isLubricator);

    const { mutateAsync: decommissionSensor } = usePostDecommissionSensor();

    useEffect(() => {
      setSelectedSensor(sensors?.find((sensor) => sensor.serialNumber === serialNumber));
    }, [sensors, serialNumber]);

    useEffect(() => {
      if (!lastKnownState || isLastKnownStateLoading || isLastKnownStateFetching) {
        return;
      }
      if (
        lastMeasurementDate &&
        lastMeasurementDate > lastKnownState.lastMeasurementDate &&
        lastSerialNumber &&
        lastSerialNumber === lastKnownState.serialNumber
      ) {
        return;
      }
      setLastMeasurementDate(lastKnownState.lastMeasurementDate);
      setTurnOn(lastKnownState.isPowerOn);
      setLastSerialNumber(lastKnownState.serialNumber);
    }, [
      isLastKnownStateFetching,
      isLastKnownStateLoading,
      lastKnownState,
      lastMeasurementDate,
      lastSerialNumber,
    ]);

    useEffect(() => {
      if (serialNumber && isLubricator) {
        refetchLastKnownState();
      }
    }, [isLubricator, serialNumber]);

    const { mutate: intellilubeOnOffMutation, isPending: isIntellilubeOnOffLoading } =
      useSetIntellilubeOnOff(() => {
        setTurnOn((prev) => !prev);
        setLastMeasurementDate(dateCache.current);
      });

    const turnOnIntellilube = useCallback(() => {
      dateCache.current = new Date().toISOString();
      intellilubeOnOffMutation({
        params: {
          assetId: selectedAsset,
          serialNumber: serialNumber,
          onOff: true,
        },
      });
    }, [intellilubeOnOffMutation, selectedAsset, serialNumber]);

    const turnOffIntellilube = useCallback(() => {
      dateCache.current = new Date().toISOString();
      intellilubeOnOffMutation({
        params: {
          assetId: selectedAsset,
          serialNumber: serialNumber,
          onOff: false,
        },
      });
    }, [intellilubeOnOffMutation, selectedAsset, serialNumber]);

    const openTransferModalHandler = useCallback(() => setOpenTransferModal(true), []);
    const closeTransferModalHandler = useCallback(() => {
      setOpenTransferModal(false);
      setCanSwitchTabs(true);
    }, []);

    useEffect(() => {
      if (
        'measurementInterval' in newSensorProperties &&
        newSensorProperties['measurementInterval'] === 0
      ) {
        setHelperErrorText('Measurement Interval cannot be 0');
      } else {
        setHelperErrorText(undefined);
      }
    }, [newSensorProperties]);

    React.useEffect(() => {
      if (isSensors && !sensors.some((sensor) => sensor.serialNumber === serialNumber)) {
        setSerialNumber(sensors[0].serialNumber);
      }
    }, [sensors, selectedAsset, isSensors, serialNumber]);

    React.useEffect(() => {
      if (sensors && serialNumber !== '') {
        const foundSensor = sensors.find((x) => x.serialNumber === serialNumber);
        if (foundSensor) {
          setLocation(foundSensor.location);
          setType(foundSensor.type);
          setSerialNumber(foundSensor.serialNumber);
          const sensorProperties: { [key: string]: string | number | boolean } = {};
          for (const [key, value] of Object.entries(foundSensor.properties)) {
            sensorProperties[key] = value.value;
          }
          setNewSensorProperties(sensorProperties);
          setSelectedSensorMonitored(foundSensor.isMonitored);
          setIsDesynchronized(
            (foundSensor.deviceFlag && foundSensor.deviceFlag === 'desynchronized') || false,
          );
          setIsMissingCartdrige(
            (foundSensor.deviceFlag && foundSensor.deviceFlag === 'cartridgeMissing') || false,
          );
          setIsIncompatible(
            (foundSensor.deviceFlag && foundSensor.deviceFlag === 'cartridgeIncompatible') || false,
          );
          setIsProjected(
            (foundSensor.deviceFlag && foundSensor.deviceFlag === 'projected') || false,
          );
          setCartridgePN(foundSensor.additionalPropertiesDb?.cartridgePN ?? '');
        }
      }
    }, [sensors, serialNumber, selectedAsset]);

    const { mutate: updateSensorMutation, isPending } = useUpdateSensor(
      selectedAsset.toString(),
      serialNumber,
    );

    useEffect(() => {
      setIsLoading(isPending);
    }, [isPending, setIsLoading]);

    const handleUpdateSensor = useCallback(() => {
      const data = {
        location: location,
        properties: newSensorProperties,
      };
      updateSensorMutation({
        params: {
          assetId: selectedAsset.toString(),
          serialNumber,
        },
        body: data,
      });
    }, [location, newSensorProperties, selectedAsset, serialNumber, updateSensorMutation]);

    useImperativeHandle(
      ref,
      () => ({
        applyChanges: handleUpdateSensor,
      }),
      [handleUpdateSensor],
    );

    const { mutate: syncSensor, isPending: syncLoading } = useSyncSensor(
      selectedAsset,
      serialNumber,
    );

    const { translate } = useTranslation();
    const componentText = useMemo(() => {
      return {
        decommissionButton: translate('decommission_sensor_button'),
        applyInfoLabel: translate('apply_info_label'),
        selectSensorLabel: translate('select_sensor_label'),
        synchronizeNowButton: translate('synchronize_now_button'),
        measurementIntervalLabel: translate('measurement_interval_label'),
        incompatibleCartridgeLabel: translate('incompatible_cartridge_label'),
        missingCartridgeLabel: translate('missing_cartridge_label'),
        projectionUsedLabel: translate('projection_used_label'),
        noDataLabel: translate('no_data_label'),
        sensorDesyncLabel: translate('sensor_desync_label'),
        transferSensorButton: translate('transfer_sensor_button'),
        lastKnownStateLabel: translate('last_known_state_label'),
        configurationLabel: translate('configuration_label'),
        refreshStateButton: translate('refresh_state_button'),
        turnOnButton: translate('turn_on_button'),
        turnOffButton: translate('turn_off_button'),
        purgeButton: translate('purge_button'),
        changeDischargePeriodButton: translate('change_discharge_period_button'),
        changeCartridgeSizeButton: translate('change_cartridge_size_button'),
        changeCartridgeTypeButton: translate('change_cartridge_type_button'),
        configuredNotificationLabel: translate('configured_notification_label'),
        accelerometerRangeLabel: translate('accelerometer_range_config_label'),
        hoursLabel: translate('hours_label'),
        minutesLabel: translate('minutes_label'),
        positionLabel: translate('position_label'),
        threedAxisEnableLabel: translate('3d_axis_enable_label'),
        cartridgeSizeLabel: translate('cartridge_size_label'),
        cartridgeTypeLabel: translate('cartridge_type_label'),
        information: translate('information'),
        reqInformation: translate('plants_required_fields_header'),
        dischargePeriodLabel: translate('sensor_details_discharge_period'),
        true: translate('true'),
        false: translate('false'),
        turnedOn: translate("turned_on"),
        turnedOff: translate("turned_off"),
        accRawDataCollection: translate("acceleration_raw_data_collection"),
        sampleAxis: translate("sample_axis"),
        sampleRate: translate("sample_rate"),
        tip: translate("tip"),
        month: translate("month_label"),
        week: translate('week_label')
      };
    }, []);

    const handleDecommissionSensor = useCallback(async () => {
      await decommissionSensor({ params: { assetId: selectedAsset, serialNumber } });

      refetchSensors();
      setCanSwitchTabs(true);
      onDecomission?.();
      setModalOpen(undefined);
    }, [onDecomission, refetchSensors, selectedAsset, serialNumber]);

    // Check if sensor is monitored
    const checkIfMonitored = (serialNumber: string) => {
      return sensors?.find((s) => s.serialNumber === serialNumber)?.isMonitored;
    };

    const additionalPropFields = useMemo(() => {
      const sensor = sensors?.find((x) => x.serialNumber === serialNumber);
      if (!sensor) {
        return [];
      }
      return Object.entries(sensor.properties)
        .filter(
          (property) =>
            property[0] !== 'measurementInterval' &&
            property[0] !== 'accelerometerRange' &&
            property[0] !== 'triAxisEnable' &&
            property[0] !== 'sampleNumberRawData' &&
            property[0] !== 'sampleRateRawData',
        )
        .map((property) => {
          if (property[1].type === 'bool') {
            return (
              <Grid item xs={6} key={property[0]}>
                <FormControl fullWidth>
                  <TextField
                    select
                    size='small'
                    required
                    label={property[1].label}
                    value={newSensorProperties[property[0]].toString()}
                    onChange={(e) => {
                      const newVal = e.target.value === 'true';
                      setNewSensorProperties((prevProps) => {
                        const newProps = { ...prevProps };
                        newProps[property[0]] = newVal;
                        return newProps;
                      });
                    }}
                  >
                    {['true', 'false'].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            );
          }
          return (
            <Grid item xs={6} key={property[0]}>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  required
                  label={property[1].label}
                  type={property[1].type === 'string' ? 'text' : 'number'}
                  value={newSensorProperties[property[0]]}
                  inputProps={{
                    step: property[1].type === 'int' ? '1' : '0.01',
                    min: 0,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>{property[1].unit}</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    if (
                      property[1].type === 'int' &&
                      !/^[0-9\b]+$/.test(e.target.value) &&
                      e.target.value !== ''
                    ) {
                      return;
                    }
                    const newVal =
                      property[1].type !== 'string' && e.target.value !== ''
                        ? Number(e.target.value)
                        : e.target.value;

                    setNewSensorProperties((oldProps) => {
                      const newProps = { ...oldProps };
                      newProps[property[0]] = newVal;
                      return newProps;
                    });
                  }}
                />
              </FormControl>
            </Grid>
          );
        });
    }, [sensors, serialNumber, newSensorProperties]);

    useEffect(() => {
      setDisabledSave(!isSensors || !!helperErrorText || location.trim() === '');
    }, [helperErrorText, isSensors, location, setDisabledSave]);

    const openPurgeHandler = useCallback(() => setModalOpen('purge'), []);
    const closePurgeHandler = useCallback(() => setModalOpen(undefined), []);

    const openCartridgeSizeHandler = useCallback(() => setOpenCartridgeSize(true), []);
    const closeReplaceCartridgeHandler = useCallback(() => setOpenCartridgeSize(false), []);

    const setLocationHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => setLocation(e.target.value),
      [],
    );
    const triAxisChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = e.target.value === 'true';
      setNewSensorProperties((prevProps) => {
        const newProps = { ...prevProps };
        newProps['triAxisEnable'] = newVal;
        return newProps;
      });
    }, []);
    const accelerometerRangeChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = Number(e.target.value);
        setNewSensorProperties((prevProps) => {
          const newProps = { ...prevProps };
          newProps['accelerometerRange'] = newVal;
          return newProps;
        });
      },
      [],
    );

    const hoursChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const minutes = Number(newSensorProperties['measurementInterval']) % 60;
        const newHourVal = Number(e.target.value) * 60;
        setNewSensorProperties((prevProps) => {
          const newProps = { ...prevProps };
          newProps['measurementInterval'] = newHourVal + minutes;
          return newProps;
        });
      },
      [newSensorProperties],
    );
    const minutesChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const hours = Math.floor(Number(newSensorProperties['measurementInterval']) / 60);
        const newMinValue = Number(e.target.value);
        setNewSensorProperties((prevProps) => {
          const newProps = { ...prevProps };
          newProps['measurementInterval'] = hours * 60 + newMinValue;
          return newProps;
        });
      },
      [newSensorProperties],
    );
    const sampleRateRawDataChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = Number(e.target.value);
      setNewSensorProperties((prevProps) => {
        const newProps = { ...prevProps };
        newProps['sampleRateRawData'] = newVal;
        return newProps;
      });
    }, []);
    const sampleNumberRawDataChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVal = Number(e.target.value);
        setNewSensorProperties((prevProps) => {
          const newProps = { ...prevProps };
          newProps['sampleNumberRawData'] = newVal;
          return newProps;
        });
      },
      [],
    );
    const setSerialNumberHandler = useCallback((e) => setSerialNumber(e.target.value), []);

    const { isPhone, isTablet } = useDeviceSize();
    const responsiveProps = {
      decommissionButtonStyle: useResponsiveLanguage({
        en: { xs: { mt: 1 }, md: { mt: 0 } },
      }),
      buttonTransferStyle: useResponsiveLanguage({
        en: { xs: { ml: 0, mt: 1 }, md: { ml: '0.5rem', mt: 0 } },
      }),
      applyButtonStyle: useResponsiveLanguage({
        en: { xs: { mb: 1 }, md: { mb: 0 } },
      }),
    };
    return (
      <>
        <Typography sx={{ mb: 2, mt: 2, fontWeight: 500 }} variant='h6'>
          {componentText.selectSensorLabel}
        </Typography>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              data-testid='plants_management_edit_select_sensor_input'
              size='small'
              required
              select
              label={componentText.selectSensorLabel}
              value={serialNumber}
              onChange={setSerialNumberHandler}
              disabled={!isSensors}
            >
              {isSensors &&
                sensors.map((option: any, i: number) => (
                  <MenuItem key={i} value={option.serialNumber}>
                    {`${option.serialNumber}, ${option.location}`}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
        </Grid>
        {isDesynchronized && (
          <SyncError>
            <Grid item mt={1} width='fit-content'>
              <Typography color={alarmColor} fontSize='1rem'>
                {componentText.sensorDesyncLabel}
              </Typography>
            </Grid>
          </SyncError>
        )}
        {isIncompatible && (
          <IncompatibleCartridgeError>
            <Box mt={1} width='fit-content' sx={{ display: 'flex', alignItems: 'center' }}>
              <ReportProblemOutlined sx={{ color: alarmColor, fontSize: '1.1rem', mr: 1 }} />
              <Typography color={alarmColor} fontSize='1rem'>
                {componentText.incompatibleCartridgeLabel}
              </Typography>
            </Box>
          </IncompatibleCartridgeError>
        )}
        {isMissingCartdrige && (
          <MissingCartridgeError>
            <Box mt={1} width='fit-content' sx={{ display: 'flex', alignItems: 'center' }}>
              <ReportProblemOutlined sx={{ color: alarmColor, fontSize: '1.1rem', mr: 1 }} />
              <Typography color={alarmColor} fontSize='1rem'>
                {componentText.missingCartridgeLabel}
              </Typography>
            </Box>
          </MissingCartridgeError>
        )}
        {isProjected && (
          <ProjectedUsed>
            <Grid item mt={1} width='fit-content'>
              <Typography color={alarmColor} fontSize='1rem'>
                {componentText.projectionUsedLabel}
              </Typography>
            </Grid>
          </ProjectedUsed>
        )}
        <Grid item sx={{ mt: 4, mb: 4 }}>
          {isSensors && isDesynchronized && (
            <LoadingButton
              variant='contained'
              startIcon={<SyncIcon sx={{ color: !syncLoading ? 'white' : undefined }} />}
              sx={{ mr: '0.5rem' }}
              loading={syncLoading}
              onClick={() => syncSensor({})}
              fullWidth={isPhone}
            >
              {componentText.synchronizeNowButton}
            </LoadingButton>
          )}
          {isSensors && (
            <Button
              data-testid='plant_management_sensors_decommission_button'
              variant='outlined'
              startIcon={
                <img src='/icons/decommisssion_sensor_icon.svg' alt='decommission sensor' />
              }
              onClick={() => setModalOpen('decommission')}
              sx={responsiveProps.decommissionButtonStyle}
              fullWidth={isPhone}
            >
              {componentText.decommissionButton}
            </Button>
          )}
          {isSensors && (
            <RoleRequired roles={ableToTransferSensors}>
              <Button
                data-testid='plant_management_sensors_transfer_button'
                variant='outlined'
                startIcon={<SwapHorizIcon />}
                sx={responsiveProps.buttonTransferStyle}
                onClick={openTransferModalHandler}
                fullWidth={isPhone}
              >
                {componentText.transferSensorButton}
              </Button>
            </RoleRequired>
          )}
        </Grid>
        <Typography sx={{ mb: 3, mt: 2, fontWeight: 500 }} variant='h6'>
          {isLubricator ? componentText.reqInformation : componentText.information}
        </Typography>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          alignItems='flex-start'
          sx={{ display: isLubricator ? 'none' : 'flex' }}
        >
          {'measurementInterval' in newSensorProperties && (
            <>
              <Grid item xs={12} sx={{ width: '100%' }}>
                <Typography sx={{ fontSize: '0.75rem' }}>
                  {componentText.measurementIntervalLabel}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  sx={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  error={!!helperErrorText}
                >
                  <TextField
                    data-testid='plants_management_edit_hours_input'
                    select
                    size='small'
                    required
                    label={componentText.hoursLabel}
                    value={Math.floor(Number(newSensorProperties['measurementInterval']) / 60)}
                    onChange={hoursChangeHandler}
                    disabled={!isSensors || !checkIfMonitored(serialNumber) || disableInput}
                    sx={{ width: '48%' }}
                    error={!!helperErrorText}
                  >
                    {isSensors &&
                      measurementIntervalHoursList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {`${option}h`}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    data-testid='plants_management_edit_minutes_input'
                    select
                    size='small'
                    required
                    label={componentText.minutesLabel}
                    value={Number(newSensorProperties['measurementInterval']) % 60}
                    onChange={minutesChangeHandler}
                    disabled={!isSensors || !checkIfMonitored(serialNumber) || disableInput}
                    sx={{ width: '48%', float: 'right' }}
                    error={!!helperErrorText}
                  >
                    {isSensors &&
                      measurementIntervalMinutesList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {`${option} min`}
                        </MenuItem>
                      ))}
                  </TextField>
                  <FormHelperText sx={{ ml: 0, width: '100%' }}>{helperErrorText}</FormHelperText>
                </FormControl>
              </Grid>
            </>
          )}
          {'accelerometerRange' in newSensorProperties && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  data-testid='plants_management_edit_accelerometer_range_input'
                  select
                  required
                  size='small'
                  label={componentText.accelerometerRangeLabel}
                  value={newSensorProperties['accelerometerRange']}
                  onChange={accelerometerRangeChangeHandler}
                  disabled={!isSensors || !checkIfMonitored(serialNumber) || disableInput}
                >
                  {isSensors &&
                    accelerometerRangeConfigurationList.map((option) => (
                      <MenuItem key={option} value={option}>
                        {!option ? 'Auto' : `${option}g`}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Grid>
          )}
          <Grid item container xs={12} md={isBreather ? 12 : isEdwin ? 6 : 4} spacing={2}>
            <Grid item xs={isBreather ? 6 : 12}>
              <FormControl fullWidth>
                <TextField
                  data-testid='plants_management_edit_position_input'
                  size='small'
                  required
                  label={componentText.positionLabel}
                  value={location}
                  onChange={setLocationHandler}
                  disabled={!isSensors || disableInput}
                />
              </FormControl>
            </Grid>
            {isBreather && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ cursor: 'pointer' }}
                    label={componentText.cartridgeTypeLabel}
                    disabled
                    value={(() => {
                      switch (cartridgePN) {
                        case '750017':
                          return 'OB4';
                        case '750015':
                          return 'OB1';
                        default:
                          return '';
                      }
                    })()}
                    size='small'
                  />
                </FormControl>
                {isCartridgeSizeModalOpen && (
                  <MissingCartridge
                    open={isCartridgeSizeModalOpen}
                    onClose={() => setIsCartridgeSizeModalOpen(false)}
                    initStep={4}
                    data={{
                      type: 'BreatherSensor',
                      assetId: selectedAsset,
                      serialNumber,
                      location,
                      sensorId: 0,
                    }}
                  />
                )}
              </Grid>
            )}
          </Grid>
          {'triAxisEnable' in newSensorProperties && (
            <Grid item xs={12} md={isEdwin ? 6 : 4}>
              <FormControl fullWidth>
                <TextField
                  data-testid='plants_management_edit_3Axis_enable_input'
                  select
                  size='small'
                  required
                  label={componentText.threedAxisEnableLabel}
                  value={newSensorProperties['triAxisEnable'].toString()}
                  onChange={triAxisChangeHandler}
                  disabled={!isSensors || !checkIfMonitored(serialNumber) || disableInput}
                >
                  {[
                    { value: 'true', label: componentText.true },
                    { value: 'false', label: componentText.false },
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          )}
          {additionalPropFields}
        </Grid>
        {'sampleRateRawData' in newSensorProperties &&
          'sampleNumberRawData' in newSensorProperties && (
            <Grid container alignItems='center' spacing={2} mt={0.5}>
              <Grid item xs={12}>
                <Typography sx={{ mb: 2, mt: 2, fontWeight: 500 }} variant='h6'>
                  {componentText.accRawDataCollection}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    select
                    size='small'
                    label={componentText.sampleRate}
                    value={newSensorProperties['sampleRateRawData'].toString()}
                    onChange={sampleRateRawDataChangeHandler}
                    disabled={!isSensors}
                  >
                    {['16000', '8000', '4000'].map((option) => (
                      <MenuItem key={option} value={option}>
                        {`${Number(option) / 1000} KHz`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    select
                    size='small'
                    required
                    label={componentText.sampleAxis}
                    value={newSensorProperties['sampleNumberRawData'].toString()}
                    onChange={sampleNumberRawDataChangeHandler}
                    disabled={!isSensors}
                  >
                    {['32768', '16384', '8192'].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          )}
        {isLubricator && (
          <Grid container spacing={2} alignItems='flex-start'>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  label={componentText.cartridgeSizeLabel}
                  value={newSensorProperties.cartridgeSize}
                  disabled={true}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  label={componentText.dischargePeriodLabel}
                  value={
                    (newSensorProperties.dischargePeriod as string).endsWith('m')
                      ? `${(newSensorProperties.dischargePeriod as string).split('m')[0]} ${componentText.month}${
                          Number((newSensorProperties.dischargePeriod as string).split('m')[0]) > 1
                            ? componentText.tip
                            : ''
                        }`
                      : `${(newSensorProperties.dischargePeriod as string).split('w')[0]} ${componentText.week}${
                          Number((newSensorProperties.dischargePeriod as string).split('w')[0]) > 1
                            ? componentText.tip
                            : ''
                        }`
                  }
                  disabled={true}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  size='small'
                  required
                  label={componentText.positionLabel}
                  value={location}
                  onChange={setLocationHandler}
                  disabled={!isSensors || disableInput}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        )}
        {isBreather && (
          <>
            <Typography sx={{ mb: 2, mt: 2, fontWeight: 500 }} variant='h6'>
              {componentText.configurationLabel}
            </Typography>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Button
                  data-testid='plants_management_sensors_change_cartridge_type_button'
                  variant='contained'
                  fullWidth
                  onClick={() => setIsCartridgeSizeModalOpen(true)}
                >
                  {componentText.changeCartridgeTypeButton}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {isLubricator && (
          <>
            <Typography sx={{ mb: 2, mt: 2, fontWeight: 500 }} variant='h6'>
              {componentText.configurationLabel}
            </Typography>
            <Grid container alignItems='center' gap='0.25rem' mb='1rem' mt={isPhone ? 2 : 0}>
              <Grid
                item
                xs={12}
                lg={12}
                mb={isPhone || isTablet ? 0 : 2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography fontWeight='bold' fontSize='1.1rem'>
                  {componentText.lastKnownStateLabel}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography fontWeight='bold' fontSize='0.9rem'>
                    {lastKnownState
                      ? `${turnOn ? componentText.turnedOn : componentText.turnedOff}, ${calculateTime(
                          lastMeasurementDate,
                          false,
                          'ago',
                        )}`
                      : '-'}
                  </Typography>
                </Box>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={2.2}>
                  <LoadingButton
                    data-testid='plants_management_sensors_refresh_state_button'
                    loading={isLastKnownStateLoading || isLastKnownStateFetching}
                    fullWidth={isPhone || isTablet}
                    sx={{
                      color: '#2196F3',
                      textTransform: 'none',
                    }}
                    startIcon={<RefreshIcon />}
                    disabled={isIntellilubeOnOffLoading}
                    variant='outlined'
                    onClick={() => refetchLastKnownState()}
                  >
                    {componentText.refreshStateButton}
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} md={1.6} mt={isPhone || isTablet ? 1 : 0}>
                  <LoadingButton
                    data-testid='plant_management_sensors_turn_on_button'
                    loading={isIntellilubeOnOffLoading && !turnOn}
                    fullWidth={isPhone || isTablet}
                    disabled={
                      isIntellilubeOnOffLoading ||
                      turnOn ||
                      !selectedSensorMonitored ||
                      isLastKnownStateLoading ||
                      isLastKnownStateFetching
                    }
                    variant='contained'
                    onClick={turnOnIntellilube}
                  >
                    {componentText.turnOnButton}
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} md={2.5} mt={isPhone || isTablet ? 1 : 0}>
                  <LoadingButton
                    data-testid='plant_management_sensors_turn_off_button'
                    loading={isIntellilubeOnOffLoading && turnOn}
                    fullWidth={isPhone || isTablet}
                    disabled={
                      isIntellilubeOnOffLoading ||
                      !turnOn ||
                      !selectedSensorMonitored ||
                      isLastKnownStateLoading ||
                      isLastKnownStateFetching
                    }
                    variant='contained'
                    onClick={turnOffIntellilube}
                  >
                    {componentText.turnOffButton}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb='2rem'>
              <Grid item xs={12} md={4}>
                <Button
                  data-testid='plant_management_change_cartridge_size_button'
                  disabled={!selectedSensorMonitored}
                  variant='contained'
                  fullWidth
                  onClick={openCartridgeSizeHandler}
                >
                  {componentText.changeCartridgeSizeButton}
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  data-testid='plant_management_change_discharge_period_button'
                  disabled={!selectedSensorMonitored}
                  variant='contained'
                  fullWidth
                  onClick={() => setOpenDischargePeriod(true)}
                >
                  {componentText.changeDischargePeriodButton}
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  data-testid='plant_management_sensors_purge_button'
                  disabled={!selectedSensorMonitored}
                  variant='contained'
                  fullWidth
                  onClick={openPurgeHandler}
                >
                  {componentText.purgeButton}
                </Button>
              </Grid>
              {!selectedSensorMonitored && (
                <Grid item xs={12}>
                  <Typography>{componentText.configuredNotificationLabel}</Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
        <Grid item container sx={{ mt: 0, mb: 0 }} justifyContent='space-between'></Grid>

        {modalOpen === 'decommission' && (
          <DecommissionWarning
            sensorSN={serialNumber}
            onClose={() => setModalOpen(undefined)}
            onAccept={() => handleDecommissionSensor()}
          />
        )}

        {openCartridgeSize &&
          sensors &&
          !isPending &&
          serialNumber &&
          isLubricator &&
          selectedSensor && (
            <IntellilubeChangeCartridgeSize
              open={openCartridgeSize}
              onClose={closeReplaceCartridgeHandler}
              selectedSensor={selectedSensor}
              selectedAssetId={selectedAsset}
            />
          )}
        {openDischargePeriod &&
          sensors &&
          !isPending &&
          serialNumber &&
          isLubricator &&
          selectedSensor && (
            <IntellilubeChangeDischargePeriod
              open={openDischargePeriod}
              onClose={() => setOpenDischargePeriod(false)}
              selectedSensor={selectedSensor}
              selectedAssetId={selectedAsset}
            />
          )}
        <Purge
          open={modalOpen === 'purge'}
          onClose={closePurgeHandler}
          assetId={selectedAsset}
          location={location}
          serialNumber={serialNumber}
          fillLevel={lastKnownState?.fillLevelValue}
        />
        {openTransferModal && (
          <TransferSensorModal
            serialNumber={serialNumber}
            isOpen={openTransferModal}
            onClose={closeTransferModalHandler}
            oldAssetId={selectedAsset}
            oldAssetName={selectedAssetName}
            sensorLocation={location}
            refetchAsset={refetchAsset}
          />
        )}
      </>
    );
  },
);

SensorsForm.displayName = 'SensorsForm';

export default SensorsForm;
