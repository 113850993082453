import { useAuthQuery } from 'api/queries';
import useCacheImageBlob from './useCacheImageBlob';

export const useGetImageByAsset = (assetId: number, thumbnailUrl: string | undefined) => {
  const queryResult = useAuthQuery<Blob>({
    url: `assets/${assetId}/images/${thumbnailUrl}`,
    responseType: 'blob',
    queryOptions: {
      enabled: !!thumbnailUrl,
    },
  });

  //Instead of caching react-query returns new blob each time is called which leads to creating new image URL an unnecessary requests
  const cachedBlob = useCacheImageBlob(
    assetId,
    thumbnailUrl,
    queryResult.data,
    queryResult.isSuccess,
    queryResult.isLoading,
  );

  return {
    ...queryResult,
    data: cachedBlob,
  };
};
