import { PersonOutline } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { FC, memo } from "react";

interface LoginAvatarProps{
    border?: string,
    size: string,
    xs?: boolean,
}
const LoginAvatar: FC<LoginAvatarProps> = ({border, size, xs})=> { 
    return(
      <Avatar
        sx={{
            width: size,
            height: size,
            backgroundColor: "white",
            mr: !xs? "1rem" : 0,
            border: border ? border : "none"
        }}
    >
        <PersonOutline fontSize="small" sx={{color:"black"}}/>
      </Avatar>);};

export default memo(LoginAvatar);