import React from 'react';
import { Box, Button, Divider, Typography, Link} from '@mui/material';
import style from './styles/Help.module.scss';
import { fontMedium, fontSubDesc } from './styles/fontStyle';
import { useResponsiveLanguage } from 'shared/responsive';

type PropsType = {
    item: {
        icon: string;
        title: string;
        desc: string;
        subDesc: string;
        buttonLabel?: string;
        link?: string;
        testid?: string;
    };
}

const InformationComponent = ({ item } : PropsType) => {
  const { icon, title, desc, subDesc, buttonLabel, link, testid } = item;

  const responsiveProps = {
    buttonWidth: useResponsiveLanguage({
      en: { xs : { mt: 1}},
      es: { xs: { fontSize: '0.5rem', fontWeight: 800}}
    })
  }

  return (
    <Box
      display='inline-flex'
      flexDirection='column'
      justifyContent='space-between'
      width={{ xs: '100%', md: '32%' }}
    >
      <Box display='flex' alignItems='center' flexDirection='column'>
        <img src={icon} className={style.image} />
        <Typography color='secondary' sx={{ ...fontMedium, fontWeight: 700 }}>
          {title}
        </Typography>
        <Box
          m='1rem 0.3rem 0 0.3rem'
          textAlign='center'
          sx={{ ...fontMedium, fontWeight: 600 }}
        >
          {desc.split('\n').map((str: string) => (
            <Box key={str}>{str}</Box>
          ))}
          <Divider
            variant='middle'
            sx={{ mr: 4, ml: 4, mb: 1, mt: 1, background: '#327179' }}
          />
          <Typography sx={{ ...fontSubDesc }}>{subDesc}</Typography>
        </Box>
      </Box>
      {buttonLabel && (
        <Button
          variant='contained'
          component={Link}
          href={link}
          target='_blank'
          underline='none'
          sx={{ m: '0 0.5rem', ...responsiveProps.buttonWidth }}
          style={{ color: 'white' }}
          data-testid={testid}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
};

export default InformationComponent;