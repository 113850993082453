import * as React from "react";
import { Box, Button, Typography, Modal, Grid, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "0.5rem",
  boxShadow: 24,
  padding: "1rem",
  width: "40%",
};

type PropsTypes = Readonly<{
  pages: {
    title?: string;
    alert?: any;
    content: any; //need to change that
    skippable?: boolean;
    noBackButton?: boolean;
    noButtons?: boolean;
    width?: string;
    pageEvent?: () => void;
    nextPageButtonName?: string;
    backPageButtonName?: string;
  }[];
  openButtonText?: string;
  finishButtonText?: string;
  hideFinishButton?: boolean;
  dispatchEndEvent?: () => void;
  fullWidthModalButton?: boolean;
}>;

type StateTypes = Readonly<{
  commonModal: {
    isBlockedNextPage: boolean;
  };
}>;

const CommonModal = ({
  pages,
  openButtonText,
  hideFinishButton,
  finishButtonText,
  fullWidthModalButton,
  dispatchEndEvent,
}: PropsTypes) => {
  const [open, setOpen] = React.useState(false);
  const [activePageNr, setActivePageNr] = React.useState(0);

  const { isBlockedNextPage } = useSelector((state: StateTypes) => {
    return state.commonModal;
  });

  const page = pages[activePageNr];
  if (page.width !== undefined) {
    style["width"] = pages[activePageNr].width!;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearState();
    setOpen(false);
  };

  const handleFinish = () => {
    // dispatch event for example at the end of the form, its
    // kind of connection to the page with the modal finish button
    dispatchEndEvent && dispatchEndEvent();
    handleClose();
  };

  const handleSkip = React.useCallback(() => {
    activePageNr < pages.length - 1 && setActivePageNr(activePageNr + 1);
  }, [activePageNr]);

  const clearState = React.useCallback(() => {
    setActivePageNr(0);
  }, [activePageNr]);

  const handleSpecialClose = () => {
    if (page.pageEvent) {
      page.pageEvent();
      handleClose();
    }
  };
  const handleNext = React.useCallback(() => {
    activePageNr < pages.length - 1 && setActivePageNr(activePageNr + 1);
    handleSpecialClose();
  }, [activePageNr]);

  return (
    <>
      <Button fullWidth={fullWidthModalButton ? true : false} variant="contained" color="secondary" onClick={handleOpen} sx={{fontWeight:600}}>
        {openButtonText ? openButtonText : "Open Modal"}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="common-modal">
        <Box sx={style}>
          {page.title && (
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <Typography
                  sx={{ mt: 1, ml: 3, fontWeight: "bold" }}
                  variant="h5"
                  color="primary.dark"
                >
                  {page.title}
                </Typography>
              </Grid>
              <Grid container item xs={1} justifyContent="center">
                <Close
                  onClick={handleClose}
                  sx={{ color: "action.active", cursor: "pointer" }}
                />
              </Grid>
            </Grid>
          )}

          {page && React.createElement(page.content, {})}

          <Box
            sx={{
              justifyContent: !page.noBackButton ? "space-between" : "flex-end",
              display: "flex",
              mt: 2,
              ml: 2,
              mr: 2,
            }}
          >
            {!page.skippable && !page.noBackButton && !page.noButtons && (
              <Button
                variant="outlined"
                color="secondary"
                sx={{ color: "text.primary", fontWeight: "bold" }}
                onClick={handleClose}
                startIcon={page.backPageButtonName ? <ArrowBack /> : <Close />}
              >
                {page.backPageButtonName ? page.backPageButtonName : "close"}
              </Button>
            )}
            {!hideFinishButton &&
              activePageNr === pages.length - 1 &&
              !page.noButtons && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleFinish}
                  sx={{ fontWeight: "bold" }}
                >
                  {finishButtonText ? finishButtonText : "Finish"}
                </Button>
              )}
            {page.skippable && !page.noButtons && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleSkip}
                sx={{ color: "text.primary", fontWeight: "bold" }}
              >
                {"skip for now"}
              </Button>
            )}
            {activePageNr < pages.length - 1 && !page.noButtons && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ fontWeight: "bold" }}
                disabled={isBlockedNextPage}
                onClick={handleNext}
                endIcon={page.nextPageButtonName ? null : <ArrowForward />}
              >
                {page.nextPageButtonName ? page.nextPageButtonName : "Next"}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default React.memo<PropsTypes>(CommonModal);
