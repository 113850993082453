import { AxiosResponse, ResponseType } from 'axios';
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQueries,
  useQuery,
} from '@tanstack/react-query';
import axiosApi from './axios';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type CustomizedUseQueryOptions<TData, TApiResponse = unknown> = Omit<
  UseQueryOptions<TApiResponse, unknown, TData, QueryKey>,
  'queryKey' | 'queryFn'
>;

export interface MutationPayload<TRouteParams, TBody> {
  params?: TRouteParams;
  body?: TBody;
}
export interface UseAuthMutationOptions<TRouteParams, TBody, TApiResponse> {
  url: ((params: TRouteParams) => string) | string;
  method: HttpMethod;
  mutationOptions?: UseMutationOptions<
    AxiosResponse<TApiResponse>,
    Error,
    MutationPayload<TRouteParams, TBody>,
    unknown
  >;
  responseType?: ResponseType;
}
// #docs
/**
 * Centralized useQuery hook
 * @param {TData} TData - Type of result data.
 * @param {TApiResponse} - Type of api response data. Default is unknown.
 * @param {string} url - Api url. PLEASE DO NOT ADD PARAMS IN URL.
 * @param {CustomizedUseQueryOptions} queryOptions - Customized useQuery options.
 * @param {Record<string, string>} params - Params to be added to url. Used in query key generation.
 * @param {Record<string, string>} keyExcludedParams - Params to be excluded from query key.
 * @returns
 */

export const useAuthQuery = <TData, TApiResponse = unknown>({
  url,
  queryOptions,
  responseType,
  params,
  keyExcludedParams,
}: {
  url: string;
  queryOptions?: CustomizedUseQueryOptions<TData, TApiResponse>;
  responseType?: ResponseType;
  params?: Record<string, string>;
  keyExcludedParams?: Record<string, string>;
}) => {
  return useQuery<TApiResponse, unknown, TData, QueryKey>({
    ...queryOptions,
    queryKey: [...url.split('/'), params],
    queryFn: async () =>
      axiosApi<TApiResponse>({
        url,
        method: 'GET',
        responseType,
        params: { ...params, ...keyExcludedParams },
      }).then((res) => res.data),
  });
};

export const useAuthQueries = <TData = unknown>({
  queries,
}: {
  queries: { url: string; params?: Record<string, string>; refetchInterval?: number }[];
}) => {
  return useQueries({
    queries: queries.map((q) => ({
      queryKey: [...q.url.split('/'), q.params],
      queryFn: async () =>
        axiosApi<TData>({
          url: q.url,
          method: 'GET',
          params: q.params,
        }).then((res) => res.data),
      refetchInterval: q.refetchInterval,
    })),
  });
};

export const useAuthMutation = <TRouteParams = unknown, TBody = unknown, TApiResponse = unknown>({
  url,
  method,
  responseType,
  mutationOptions,
}: UseAuthMutationOptions<TRouteParams, TBody, TApiResponse>) => {
  return useMutation({
    ...mutationOptions,
    mutationFn: async (payload) =>
      axiosApi<TApiResponse>({
        url: typeof url === 'string' ? url : url(payload.params as TRouteParams),
        method,
        responseType,
        data: payload.body,
      }).then((res) => res),
  });
};
