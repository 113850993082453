import { Divider, Grid } from '@mui/material';
import { useGetPlants } from 'api/plants/useGetPlants';
import { FC, memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import ManageAccountDetails from './ManageAccountDetails';
import ManageAccountHeader from './ManageAccountHeader';
import { PersonalInfo } from './models/models';
import { usePutPersonalInfo } from 'api/users/usePutPersonalInfo';
import { toast } from 'react-toastify';
import { useDeviceSize } from 'shared/responsive';
import { useGetInitialPageCount } from './ManagePlants';

interface ManageAccountContentProps {
  activeLink?: string;
  onClose?: () => void;
  firstName: string;
  lastName: string;
  email: string;
}

const ManageAccountContent: FC<ManageAccountContentProps> = ({
  activeLink,
  firstName,
  lastName,
  email,
  onClose,
}) => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState('plantName');
  const [searchInput, setSearchInput] = useState(searchText);
  const [plantListSize, setPlantListSize] = useState<number | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(0);

  const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
    name: firstName,
    surname: lastName,
  });
  const { isPhone, isTablet, isDesktop } = useDeviceSize();

  const initialTablePageCount = useGetInitialPageCount();

  useEffect(() => {
    setPageSize(initialTablePageCount);
  }, [initialTablePageCount]);

  const { data: plantList, isLoading } = useGetPlants(pageSize, page + 1, searchText, sortOrder);
  const plantId = useSelector(selectPlantId);

  const [plantName, setPlantName] = useState('');

  const { mutate: updatePersonalInfoMutation, isPending: personalInfoLoading } =
    usePutPersonalInfo();

  useEffect(() => {
    if (plantList?.totalRecords) {
      setPlantListSize(plantList.totalRecords);
    }
  }, [plantList]);

  useEffect(() => {
    const activePlant = plantList?.records.find((x) => x.plantId === plantId);
    if (activePlant) setPlantName(activePlant.plantName);
  }, [plantList, plantId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPage(0);
      setSearchText(searchInput);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [setSearchText, searchInput]);

  const handleUpdateUserInfo = () => {
    updatePersonalInfoMutation(
      {
        body: {
          firstName: personalInfo.name,
          lastName: personalInfo.surname,
        },
      },
      {
        onSuccess(data, variables, context) {
          setIsEditMode(false);
          setIsConfirmOpen(false);
        },
        onError(error, variables, context) {
          toast.error('Error during updating personal info!');
        },
      },
    );
  };

  const responsiveProps = {
    gridProps:
      (isPhone || isTablet) && !isDesktop
        ? {
            height: isPhone ? '26rem' : '30rem',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
          }
        : {},
  };

  return (
    <Grid sx={responsiveProps.gridProps}>
      <ManageAccountHeader
        activeLink={activeLink}
        numberOfPlant={plantListSize}
        onClose={onClose}
        onEditClick={() => setIsEditMode(!isEditMode)}
        onSaveClick={() => {
          setIsConfirmOpen(true);
        }}
        isEditMode={isEditMode}
      />
      <Divider sx={{ mb: '1rem' }} />
      <>
        <ManageAccountDetails
          activeLink={activeLink}
          personalInfo={personalInfo}
          setPersonalInfo={(newInfo: PersonalInfo) => setPersonalInfo(newInfo)}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          email={email}
          plantList={plantList}
          plantName={plantName}
          managePlantsPage={page}
          managePlantsPageSize={pageSize}
          setManagePlantsPage={setPage}
          setManagePlantsPageSize={setPageSize}
          managePlantsSearchText={searchText}
          setManagePlantsSearchText={setSearchText}
          managePlantsSortOrder={sortOrder}
          setManagePlantsSortOrder={setSortOrder}
          isPlantsListLoading={isLoading}
          totalPlantListSize={plantListSize}
          isEditMode={isEditMode}
          onBackClick={() => setIsConfirmOpen(false)}
          onConfirmClick={() => handleUpdateUserInfo()}
          isConfirmOpen={isConfirmOpen}
          isChangePersonalInfoLoading={personalInfoLoading}
        />
      </>
    </Grid>
  );
};

export default memo(ManageAccountContent);
