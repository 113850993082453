import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import newReportSlice from 'features/channel-partners/reports/store/newReportSlice';
import gatewaysReducer from 'features/gateways-management/store/gatewaysSlice';
import plantsManagementReducer from 'features/plants-management/store/plantsManagementSlice';
import authorizationReducer from '../auth/store/AuthorizationSlice';
import commonModalReducer from '../components/CommonModal/CommonModalSlice';
import cookieReducer from '../components/CookiePage/store/cookieSlice';
import universalModalReducer from '../components/UniversalModal/UniversalModalSlice';
import assetsManagementReducer from '../features/assets-management/store/assetsManagementSlice';
import cartridgesReducer from '../features/cartridges-management/store/cartridgesSlice';
import dashboardReducer from '../features/dashboard/store/dashboardSlice';
import reportReducer from '../features/reports-management/store/reportSlice';
import accountReducer from '../store/accountSlice';

export const store = configureStore({
  reducer: {
    commonModal: commonModalReducer,
    universalModal: universalModalReducer,
    gateways: gatewaysReducer,
    authorization: authorizationReducer,
    account: accountReducer,
    report: reportReducer,
    dashboard: dashboardReducer,
    assetsManagement: assetsManagementReducer,
    plantsManagement: plantsManagementReducer,
    cookie: cookieReducer,
    cartridges: cartridgesReducer,
    newReport: newReportSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
