import { AxiosError, AxiosResponse } from 'axios';
import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPlantId } from 'store/accountSlice';
import { errors } from 'features/plants-management/components/AssetsGroupsTable/AddAssetGroupModal/AddAssetGroupModal';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface CreateAssetGroupBody {
  name: string;
}
interface CreateAssetGroupResponse {
  assetGroupId: number;
}
export interface CreatedAssetGroup {
  id: number;
}

export const useCreateAssetGroup = (
  onSuccess: (createdAssetGroup: CreatedAssetGroup) => void,
  onError?: (error: number | undefined) => void,
) => {
  const plantId = useSelector(selectPlantId);
  const queryClient = useQueryClient();

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastSuccessMess: translate('asset_group_toast_mess_group_created'),
      toastErrorMess: translate('asset_group_toast_error_mess'),
    };
  }, []);

  return useAuthMutation<undefined, CreateAssetGroupBody, CreateAssetGroupResponse>({
    url: () => `plants/${plantId}/asset-groups`,
    method: 'POST',
    mutationOptions: {
      onSuccess: ({ data }: AxiosResponse<CreateAssetGroupResponse>) => {
        onSuccess({ id: data.assetGroupId });
        toast.success(componentText.toastSuccessMess);
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes('asset-groups'),
        });
      },
      onError: (error, variables, context) => {
        const caughtError = error as AxiosError;
        const status = caughtError.response?.status;
        onError?.(status);
        errors.has(Number(status))
          ? toast.error(errors.get(Number(status)))
          : toast.error(componentText.toastErrorMess);
      },
    },
  });
};
