import { alarmColor, okColor, warningColor } from "features/dashboard/colors/colors";
import { memo } from "react";
import { FC } from "react";
import SouthEastIcon from '@mui/icons-material/SouthEast';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';

interface ArrowProps{
  status: string,
}

const Arrow: FC<ArrowProps> = ({status}) =>{

 return(
    <>
    {
       status === "alarm" ? <SouthIcon sx={{fontSize:"0.9rem", ml:"0.3rem", color: alarmColor}} />
          :( status === "alert" ? <SouthEastIcon sx={{fontSize:"0.9rem", ml:"0.3rem", color: warningColor}} />
           : <NorthIcon sx={{fontSize:"0.9rem", ml:"0.3rem", color: okColor}} />
        )
    }
    </>
 );
}

export default memo(Arrow);