import {
  SxProps,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Theme,
} from '@mui/material';
import { useTranslation } from 'languages';
import { ChangeEvent, FC, ReactNode, useState } from 'react';

interface MainTableProps {
  headers : ReactNode;
  size ?: 'small' | 'medium';
  sx ?: SxProps<Theme>;
  page : number;
  setPage : (newPage : number) => void;
  pageSize : number;
  setPageSize : (newPageSize : number) => void;
  count : number;
  rowsPerPageOptions ?: number[];
}

const rowsPerPageOptionsDefault = [5, 10, 25, 50];

const MainTable : FC<MainTableProps> = ({
  headers,
  sx,
  children,
  size,
  setPage,
  setPageSize,
  pageSize,
  page,
  count,
  rowsPerPageOptions,
}) => {
  const [rowsPerPage] = useState(rowsPerPageOptionsDefault);
  const onPageChangeHandler = (_ : React.MouseEvent<HTMLButtonElement> | null, newPage : number) => {
    setPage(newPage);
  };
  const onRowsPerPageChangeHandler = (
    event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { translate } = useTranslation();

  const componentText = {
    rowsPerPageLabel: translate("datatable_rows_per_page_label"),
    of: translate("pagination_of_label")
  }

  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table size={size ? size : undefined} sx={sx}>
        <TableHead>{headers}</TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      <TablePagination
        labelRowsPerPage={componentText.rowsPerPageLabel}
        rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : rowsPerPage}
        component='div'
        count={count}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={onPageChangeHandler}
        onRowsPerPageChange={onRowsPerPageChangeHandler}
        labelDisplayedRows={
          ({ from, to, count }) => {
            return `${from}-${to} ${componentText.of} ${count}`
          }
        }
      />
    </TableContainer>
  );
};

export default MainTable;
