import { SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useAuthMutation } from 'api/queries';

type Body = {
  firstName: string;
  lastName: string;
};

export const usePutPersonalInfo = () => {
  const { instance, accounts } = useMsal();

  const accessTokenRequest: SilentRequest = {
    scopes: [
      `https://${process.env.REACT_APP_B2C_TENANT}.onmicrosoft.com/${process.env.REACT_APP_B2C_API_SCOPE}`,
    ],
    account: accounts[0],
    forceRefresh: true,
  };

  return useAuthMutation<undefined, Body>({
    url: `users/personal-info`,
    method: 'PUT',
    mutationOptions: {
      onSuccess(data, variables, context) {
        // instance.acquireTokenSilent(accessTokenRequest).then((r) => console.log(r))

        instance.logout();
      },
    },
  });
};
