import { useAuthQuery } from 'api/queries';
import {
  Gateway,
  Sensor,
} from 'features/gateways-management/components/SensorAssignmentTable/models/SensorAssignment.models';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

type SensorWithoutSignals = Omit<Sensor, 'rssi' | 'status'> & { rssi: number | null };

type MonitoredGateway = Omit<Gateway, 'sensors'> & {
  sensors: SensorWithoutSignals[];
};

export interface ResponseModel {
  monitored: MonitoredGateway[];
  unassigned: SensorWithoutSignals[];
  onlineGatewaysCount: number;
  offlineGatewaysCount: number;
  unassignedSensorsCount: number;
}

export const prepareUrl = (plantId: number) => `plants/${plantId}/gateways-sensors-base-info`;

export const useGetSensorsBaseInfo = (plantId: number) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_sensors_base_info_from_plant_id_toast'),
    };
  }, []);

  return useAuthQuery<ResponseModel>({
    url: `plants/${plantId}/gateways-sensors-base-info`,
    queryOptions: {
      enabled: !!plantId,
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
