import { Box, Typography } from "@mui/material";
import { selectSelectedBearingParts } from "features/assets-management/store/assetsManagementSlice";
import { useTranslation } from "languages";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

type Props = {
    faultFrequencies?: string[];
    zIndex?: string;
}
const BearingPartsLegend:FC<Props> = ({faultFrequencies, zIndex}) => {
    const bearingParts = useSelector(selectSelectedBearingParts);
    const legendEntries = faultFrequencies ?? bearingParts;

    const { translate } = useTranslation();
    const componentText = useMemo(() => {
      return {
        fftRunningSpeedOption: translate("fft_running_speed_option"),
        fftRoller: translate("fft_roller"),
        fftOuterRace: translate("fft_outer_race"),
        fftInnerRace: translate("fft_inner_race"),
        fftCage: translate("fft_cage"),
      };
    }, []);
    
    const dict = new Map([
        ["roller", { color: "rgba(224, 115, 92, 0.5)", displayName: componentText.fftRoller }],
        ["innerrace", { color: "rgba(103, 196, 126, 0.5)", displayName: componentText.fftInnerRace }],
        ["outerrace", { color: "rgba(94, 174, 248, 0.5)", displayName: componentText.fftOuterRace }],
        ["cage", { color: "rgba(255, 196, 255, 0.5)", displayName: componentText.fftCage }],
        ["runningSpeed", { color: "#f0b323", displayName: componentText.fftRunningSpeedOption }]
    ]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '2rem',
                zIndex: zIndex ?? '1000',
                position: 'absolute',
                top: "3rem",
                left: "5rem"
            }}
        >
            {legendEntries.map(el => (
                <Typography key={el} >
                    <span style={{
                        display: 'inline-block',
                        height: '0.7rem',
                        width: '0.7rem',
                        backgroundColor: dict.get(el)?.color,
                        borderRadius: '50%',
                        marginRight: '0.5rem',
                    }} />
                    {dict.get(el)?.displayName}
                </Typography>
            ))}
        </Box>
    )
}

export default BearingPartsLegend;