import {
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FC, memo, useMemo, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { fontMedium } from '../styles/fontStyle';
import PreviewReleaseNotes from './PreviewReleaseNotes';
import { useGetReleaseNotes } from 'api/notes';
import moment from 'moment';
import { Loading } from 'components';
import { useTranslation } from 'languages';

interface ReleaseNotesProps {}
const ReleaseNotes: FC<ReleaseNotesProps> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPiId, setSelectedPiId] = useState('');
  const { data, isLoading } = useGetReleaseNotes();

  const notes = data ? [...data].reverse() : [];

  const handleReleaseClick = (releaseNoteId: string) => {
    setSelectedPiId(releaseNoteId);
    setModalOpen(true);
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => { 
    return {
      nameLabel: translate('menu_release_notes_name_label'),
      releaseDateLabel: translate('menu_release_notes_release_date_label'),
    };
  }, []);

  const mappedNotes = notes.map(
    (
      note: any, // eslint-disable-line
    ) => (
      <TableRow
        key={note.id}
        sx={{
          display: 'table',
          tableLayout: 'fixed',
          width: '100%',
        }}
      >
        <TableCell
          sx={{
            borderBottom: 'none',
            maxWidth: '6rem',
            fontSize: '1.1rem',
            wordWrap: 'break-word',
          }}
          align='left'
        >
          <a
            onClick={() => handleReleaseClick(note.id)}
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              textDecorationColor: '#337179',
              color: '#337179',
            }}
          >
            {note.name}
          </a>
        </TableCell>
        <TableCell sx={{ borderBottom: 'none', fontSize: '1.1rem' }} align='left'>
          {moment(note.releasedOn).format('YYYY-MM-DD').toString()}
        </TableCell>
      </TableRow>
    ),
  );

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <TableContainer>
        <TableHead sx={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
          <TableRow>
            <TableCell align='left'>
              <Typography sx={{ ...fontMedium, fontSize: '1.1rem', fontWeight: 'bold' }}>
                {componentText.nameLabel}
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography sx={{ ...fontMedium, fontSize: '1.1rem', fontWeight: 'bold' }}>
                {componentText.releaseDateLabel}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          data-testid="manage_account_release_notes_table"
          sx={{
            display: 'block',
            overflowX: 'hidden',
            height: '24rem',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '5px' },
            '&::-webkit-scrollbar-thumb': { background: '#337179' },
            '&::-webkit-scrollbar-track-piece:start': {
              background: 'transparent',
              mt: '1rem',
              flexWrap: 'nowrap',
            },
          }}
        >
          {isLoading ? <Loading height={'20rem'} /> : mappedNotes}
        </TableBody>
        <TableFooter sx={{ display: 'table', tableLayout: 'fixed', width: '100%' }}></TableFooter>
      </TableContainer>
      <PreviewReleaseNotes
        isOpen={isModalOpen}
        onClose={handleModalClose}
        selectedPiId={selectedPiId}
      />
    </>
  );
};

export default memo(ReleaseNotes);
