import { Button, Divider, Grid, Switch, Typography } from '@mui/material';
import { NotificationResponse, useGetNotification } from 'api/users/useGetNotifications';
import { usePUTNotificationsSettings } from 'api/users/usePUTNotificationsSettings';
import { useUpdateNotifications } from 'api/users/useUpdateNotifications';
import { Loading } from 'components';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { RoleRequired } from 'components/RoleRequired';
import { useTranslation } from 'languages';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useDeviceSize, useResponsiveLanguage } from 'shared/responsive';
import { fontLarge, fontMedium, fontSmall } from '../styles/fontStyle';
import RecurrEventsNotificationsTable from './RecurrEventsNotificationsTable';
import SensorsNotificationsTable from './SensorsNotificationsTable';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

interface NotificationProps {
  plantName: string;
}

const Notification: FC<NotificationProps> = ({ plantName }) => {
  const [openSensorsNotif, setOpenSensorsNotif] = useState<boolean>(false);
  const [openRecurrEventsNotif, setOpenRecurrEventsNotif] = useState(false);
  const [isActionLocked, setIsActionLocked] = useState<boolean>(false);
  const { isPhone, isTablet } = useDeviceSize();
  const { translate } = useTranslation();

  const plantId = useSelector(selectPlantId);

  const {
    data: notifications,
    refetch: refetchNotif,
    isLoading: isNotifLoading,
  } = useGetNotification(plantId);

  const componentText = useMemo(() => {
    return {
      joinPlantText: translate('menu_join_plant_button'),
      notificationsDescription: translate('menu_notifications_description'),
      disable: translate('menu_notification_disable'),
      enable: translate('menu_notification_enable'),
      autoEnableNotifications: translate('menu_notification_auto_enable'),
      newlyEnabledSensors: translate('menu_notification_newly_enabled'),
      automaticOffline: translate('menu_notification_automatic_offline'),
      twoDaysOffline: translate('menu_notification_two_days_offline'),
      emailLabel: translate('menu_notification_email_label'),
      pushLabel: translate('menu_notification_push_label'),
      enabledFor: translate('menu_notification_enabled_for'),
      disabledFor: translate('menu_notification_disabled_for'),
      all: translate('menu_notification_all'),
      assets: translate('menu_notification_assets'),
      sensors: translate('menu_notification_sensors'),
      enabled: translate('menu_enabled_button_text'),
      disabled: translate('menu_disabled_button_text'),
      successToast: translate('successfully_updated_notifications_settings_toast'),
      errorToast: translate('error_during_updating_notifications_settings_toast'),
      noRecentDataNotifications: translate('no_recent_data_notifications_menu_notify'),
      noRecentDataNotificationsDescription: translate(
        'no_recent_data_notifications_description_menu_notify',
      ),
      manageNotifications: translate('manage_notifications_menu_notify'),
      toast: {
        successMess: translate('successfully_updated_notifications_toast'),
        errorMess: translate('error_during_updating_notifications_toast'),
        inProgress: translate('updating_notifications_toast'),
      },
    };
  }, [notifications]);

  const { mutateAsync: updateNotificationsMutation, isPending: isUpdateLoading } =
    useUpdateNotifications();

  const { mutate: updateNotifSettingsMutation, isPending: isUpdateSettingsLoading } =
    usePUTNotificationsSettings();

  const handleUpdateNotification = (type: string, notificationWay: string, value: boolean) => {
    const toastId = toast.loading(componentText.toast.inProgress);

    updateNotificationsMutation(
      {
        body: {
          type,
          notificationWay,
          value,
        },
      },
      {
        onSettled() {
          refetchNotif().then((res) => {
            autoNotifSwitchCheck(res.data);
          });
        },
      },
    )
      .then(() => {
        toast.update(toastId, {
          render: componentText.toast.successMess,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
        });
      })
      .catch(() => {
        toast.update(toastId, {
          render: componentText.toast.errorMess,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
        });
      });
  };

  const handleOpenSensorsNotifTable = () => {
    setOpenSensorsNotif(true);
  };

  const handleOpenRecurrEventsNotifTable = () => {
    setOpenRecurrEventsNotif(true);
  };

  const comparator = (total: number, enabled: number) => {
    if (total === enabled) return componentText.enabled;
    if (enabled === 0) return componentText.disabled;

    return total - enabled;
  };

  const buttonVariantFn = (total: number, enabled: number, reverse?: boolean) => {
    switch (comparator(total, enabled)) {
      case componentText.disabled:
        return reverse ? 'outlined' : 'contained';
      case componentText.enabled:
        return reverse ? 'contained' : 'outlined';
      default:
        return reverse ? 'outlined' : 'contained';
    }
  };

  const labelFn = (total: number, enabled: number, isRecurringEvents: boolean) => {
    switch (comparator(total, enabled)) {
      case componentText.disabled:
        return (
          <>
            {componentText.disabledFor}{' '}
            <b
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                textUnderlineOffset: '5px',
              }}
              onClick={
                isRecurringEvents ? handleOpenRecurrEventsNotifTable : handleOpenSensorsNotifTable
              }
            >
              {componentText.all} {isRecurringEvents ? componentText.assets : componentText.sensors}
            </b>
          </>
        );
      case componentText.enabled:
        return (
          <>
            {componentText.enabledFor}{' '}
            <b
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                textUnderlineOffset: '5px',
              }}
              onClick={
                isRecurringEvents ? handleOpenRecurrEventsNotifTable : handleOpenSensorsNotifTable
              }
            >
              {componentText.all} {isRecurringEvents ? componentText.assets : componentText.sensors}
            </b>
          </>
        );
      default:
        return (
          <>
            {componentText.enabledFor}{' '}
            <b
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                textUnderlineOffset: '5px',
              }}
              onClick={
                isRecurringEvents ? handleOpenRecurrEventsNotifTable : handleOpenSensorsNotifTable
              }
            >
              {enabled} {isRecurringEvents ? componentText.assets : componentText.sensors}
            </b>
          </>
        );
    }
  };

  const autoNotifSwitchCheck = (notif?: NotificationResponse) => {
    if (
      notif &&
      notifications &&
      notif.notificationCategories.every(
        (cat) => cat.emailCounter === cat.totalCounter && cat.pushCounter === cat.totalCounter,
      ) &&
      notif.automaticallySensorsNotifications === false
    ) {
      // Here trigger update notif settings to enable auto enable notifications on new sensors
      updateNotifSettingsMutation(
        {
          body: {
            automaticallySensorsNotifications: true,
            gatewayOfflineNotifications: notifications.gatewayOfflineNotifications,
            sensorsNoRecentDataNotifications: notifications.sensorsNoRecentDataNotifications,
          },
        },
        {
          onSettled(data, error, variables, context) {
            refetchNotif();
          },
        },
      );
    } else if (
      notif &&
      notifications &&
      notif.notificationCategories.every(
        (cat) => cat.emailCounter === 0 && cat.pushCounter === 0,
      ) &&
      notif.automaticallySensorsNotifications === true
    ) {
      updateNotifSettingsMutation(
        {
          body: {
            automaticallySensorsNotifications: false,
            gatewayOfflineNotifications: notifications.gatewayOfflineNotifications,
            sensorsNoRecentDataNotifications: notifications.sensorsNoRecentDataNotifications,
          },
        },
        {
          onSettled(data, error, variables, context) {
            refetchNotif();
          },
        },
      );
    }
  };

  useEffect(() => {
    if (isUpdateLoading || isUpdateSettingsLoading || isNotifLoading) setIsActionLocked(true);
    else setIsActionLocked(false);
  }, [isUpdateLoading, isUpdateSettingsLoading, isNotifLoading]);

  const handleChangeAutoSensorNotif = (value: boolean) => {
    if (notifications)
      updateNotifSettingsMutation(
        {
          body: {
            automaticallySensorsNotifications: value,
            gatewayOfflineNotifications: notifications.gatewayOfflineNotifications,
            sensorsNoRecentDataNotifications: notifications.sensorsNoRecentDataNotifications,
          },
        },
        {
          onSettled(data, error, variables, context) {
            refetchNotif();
          },
          onSuccess(data, variables, context) {
            toast.success(componentText.successToast);
          },
          onError(error, variables, context) {
            toast.error(componentText.errorToast);
          },
        },
      );
  };

  const handleChangeSensorsNoRecentDataNotif = (value: boolean) => {
    if (notifications)
      updateNotifSettingsMutation(
        {
          body: {
            sensorsNoRecentDataNotifications: value,
            automaticallySensorsNotifications: notifications.automaticallySensorsNotifications,
            gatewayOfflineNotifications: notifications.gatewayOfflineNotifications,
          },
        },
        {
          onSettled(data, error, variables, context) {
            refetchNotif();
          },
          onSuccess(data, variables, context) {
            toast.success(componentText.successToast);
          },
          onError(error, variables, context) {
            toast.error(componentText.errorToast);
          },
        },
      );
  };

  const handleChangeGwOfflineNotif = (value: boolean) => {
    if (notifications)
      updateNotifSettingsMutation(
        {
          body: {
            automaticallySensorsNotifications: notifications.automaticallySensorsNotifications,
            sensorsNoRecentDataNotifications: notifications.sensorsNoRecentDataNotifications,
            gatewayOfflineNotifications: value,
          },
        },
        {
          onSettled(data, error, variables, context) {
            refetchNotif();
          },
          onSuccess(data, variables, context) {
            toast.success(componentText.successToast);
          },
          onError(error, variables, context) {
            toast.error(componentText.errorToast);
          },
        },
      );
  };

  const Buttons = ({
    additionalStyles,
    notificationType,
    notificationWay,
    totalCounter,
    enabled,
    label,
  }: {
    notificationType: string;
    notificationWay: string;
    totalCounter: number;
    enabled: number;
    label: string;
    additionalStyles?: boolean;
  }) => {
    const { isPhone, isTablet } = useDeviceSize();
    const responsiveProps = {
      fontSizes: isPhone || isTablet ? { ...fontSmall } : {},
      buttonDisabledStyles: useResponsiveLanguage({
        en: { xs: { marginLeft: '0.1rem', ...fontSmall } },
        es: { xs: { fontSize: '0.65rem' } },
      }),
      buttonEnabledStyles:
        isPhone || isTablet
          ? { marginLeft: '1rem', ...fontSmall }
          : useResponsiveLanguage({
              en: { xs: { ...fontSmall } },
              es: { xs: { fontSize: '0.65rem' } },
            }),
    };

    return (
      <Grid
        item
        xs={4}
        sx={{ marginLeft: additionalStyles && (isPhone || isTablet) ? '1.2rem' : '' }}
        container
        alignItems='center'
        justifyContent='center'
        spacing={1}
      >
        <Grid item xs={6} lg={6}>
          <Button
            sx={responsiveProps.buttonDisabledStyles}
            variant={buttonVariantFn(totalCounter, enabled)}
            size='small'
            onClick={() => handleUpdateNotification(notificationType, notificationWay, false)}
            disabled={isActionLocked}
            data-testid='button_disable'
            fullWidth
          >
            {comparator(totalCounter, enabled) === componentText.disabled && componentText.disabled}
            {comparator(totalCounter, enabled) === componentText.enabled && componentText.disable}
            {typeof comparator(totalCounter, enabled) === 'number' && componentText.disable}
          </Button>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Button
            sx={responsiveProps.buttonEnabledStyles}
            variant={buttonVariantFn(totalCounter, enabled, true)}
            size='small'
            onClick={() => handleUpdateNotification(notificationType, notificationWay, true)}
            disabled={isActionLocked}
            data-testid='button_enable'
            fullWidth
          >
            {comparator(totalCounter, enabled) === componentText.disabled && componentText.enable}
            {comparator(totalCounter, enabled) === componentText.enabled && componentText.enabled}
            {typeof comparator(totalCounter, enabled) === 'number' && componentText.enable}
          </Button>
        </Grid>
        <Grid item xs={12} container justifyContent={'center'}>
          <Grid item height='2.8rem'>
            <Typography
              variant='subtitle1'
              fontSize={'13px'}
              textAlign='center'
              lineHeight='unset'
              data-testid='manage_account_disabled_enabled_number_sensors_assets_typography'
            >
              {labelFn(totalCounter, enabled, label.toLowerCase() === 'recurring events')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  if (isNotifLoading || !plantId)
    return (
      <>
        <Grid container>
          <Grid item container mt={5} ml={3} alignItems='center' justifyContent='center'>
            <Loading height='20rem' />;
          </Grid>
        </Grid>
      </>
    );

  const responsiveProps = {
    fontMediumSizes:
      isPhone || isTablet
        ? { ...fontSmall, wordBreak: 'break-all', width: '3.2rem' }
        : { ...fontLarge },
    fontSwitchSizes: isPhone || isTablet ? { fontSize: '13px' } : { fontSize: '16px' },
    mlPush: isPhone ? { ml: 7.5 } : { ml: 0.2 } || isTablet ? { ml: 2.5 } : { ml: 0 },
    mlEmail: isPhone ? { ml: 1.5 } : { ml: 0.2 } || isTablet ? { ml: 1.5 } : { ml: 0 },
  };

  const translationDictionary = {
    'parameter alarm': translate('parameter_alarm_notify_label'),
    'parameter alert': translate('parameter_alert_notify_label'),
    'device alarm': translate('device_alarm_notify_label'),
    'recurring events': translate('recurring_events_notify_label'),
  };

  const getTranslatedLabel = (labelString: string) => {
    const label = labelString ? labelString.toLowerCase() : '';
    if (label && label in translationDictionary) {
      return translationDictionary[label as keyof typeof translationDictionary];
    }
    return label;
  };

  return (
    <>
      <Grid container pr={1}>
        <Typography sx={{ ...fontMedium, mb: '1rem' }}>
          {componentText.notificationsDescription}{' '}
          <span style={{ fontWeight: 'bold' }}>{plantName}</span>
        </Typography>
        <Grid item container sx={{ py: '0.2rem' }}>
          <Grid item container xs={3} lg={4}></Grid>
          <Grid item xs={2} lg={4} container alignItems='center' justifyContent='center'>
            <Typography sx={{ ...fontMedium, ...responsiveProps.mlEmail }}>
              {componentText.emailLabel}
            </Typography>
          </Grid>
          <Grid item xs={7} lg={4} container alignItems='center' justifyContent='center'>
            <Typography sx={{ ...fontMedium, ...responsiveProps.mlPush }}>
              {componentText.pushLabel}
            </Typography>
          </Grid>
        </Grid>

        {notifications &&
          notifications.notificationCategories.length > 0 &&
          notifications.notificationCategories.map((item) => (
            <Grid item container sx={{ py: '0.2rem' }} key={item.type} spacing={1}>
              <Grid item container xs={2} lg={4}>
                <Grid item xs={12} mb={4}>
                  <Typography sx={{ ...responsiveProps.fontMediumSizes, fontWeight: 600 }}>
                    {getTranslatedLabel(item.label)}
                  </Typography>
                </Grid>
              </Grid>
              <Buttons
                notificationType={item.type}
                notificationWay='email'
                totalCounter={item.totalCounter}
                enabled={item.emailCounter}
                label={item.label}
              />
              <Buttons
                additionalStyles
                notificationType={item.type}
                notificationWay='push'
                totalCounter={item.totalCounter}
                enabled={item.pushCounter}
                label={item.label}
              />
            </Grid>
          ))}
        {notifications && (
          <>
            <RoleRequired mode='blacklist' roles={['Super Administrator', 'Super Viewer']}>
              <Grid item xs={12} container pt={2}>
                <Grid item xs={12} container alignItems={'center'} spacing={3}>
                  <Grid item lg={6} xs={isPhone ? 5 : 6} md={6}>
                    <Typography
                      variant='body2'
                      mb={1}
                      mt={1}
                      fontWeight={'bold'}
                      fontSize={responsiveProps.fontSwitchSizes}
                    >
                      {componentText.autoEnableNotifications}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={isPhone ? 7 : 6} md={6}>
                    {componentText.disable}
                    <Switch
                      data-testid='manage_account_automatically_enable_notifications_on_new_sensors_switch'
                      checked={notifications.automaticallySensorsNotifications}
                      disabled={isActionLocked}
                      onChange={(e) => handleChangeAutoSensorNotif(e.target.checked)}
                    />
                    {componentText.enable}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='subtitle1'
                    fontWeight={'bold'}
                    color={'text.disabled'}
                    fontSize={'14px'}
                    mb={1}
                  >
                    {componentText.newlyEnabledSensors}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </RoleRequired>
          </>
        )}
        {notifications && (
          <>
            <RoleRequired
              mode='whitelist'
              roles={['General User', 'Plant Administrator']}
              ignoreGlobalRoles
            >
              <Grid item xs={12} container pt={2}>
                <Grid item xs={12} container alignItems={'center'} spacing={3}>
                  <Grid item lg={6} xs={isPhone ? 5 : 6} md={6}>
                    <Typography
                      variant='body2'
                      mb={1}
                      mt={1}
                      fontWeight={'bold'}
                      fontSize={responsiveProps.fontSwitchSizes}
                    >
                      {componentText.noRecentDataNotifications}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={isPhone ? 7 : 6} md={6}>
                    {componentText.disable}
                    <Switch
                      data-testid='manage_account_no_recent_data_notifications_switch'
                      checked={notifications.sensorsNoRecentDataNotifications}
                      disabled={isActionLocked}
                      onChange={(e) => handleChangeSensorsNoRecentDataNotif(e.target.checked)}
                    />
                    {componentText.enable}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='subtitle1'
                    fontWeight={'bold'}
                    color={'text.disabled'}
                    fontSize={'14px'}
                    mb={1}
                  >
                    {componentText.noRecentDataNotificationsDescription}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </RoleRequired>
          </>
        )}
        {notifications && (
          <>
            <RoleRequired mode='whitelist' roles={['Plant Administrator']} ignoreGlobalRoles>
              <Grid item xs={12} container pt={2}>
                <Grid item xs={12} container alignItems={'center'} spacing={3}>
                  <Grid item xs={isPhone ? 5 : 6} md={6} lg={6}>
                    <Typography
                      variant='body2'
                      fontWeight={'bold'}
                      mb={1}
                      mt={1}
                      fontSize={responsiveProps.fontSwitchSizes}
                    >
                      {componentText.automaticOffline}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} xs={isPhone ? 7 : 6}>
                    {componentText.disable}
                    <Switch
                      data-testid='manage_account_automatic_offline_gateway_switch'
                      checked={notifications.gatewayOfflineNotifications}
                      disabled={isActionLocked}
                      onChange={(e) => handleChangeGwOfflineNotif(e.target.checked)}
                    />
                    {componentText.enable}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='subtitle1'
                    fontWeight={'bold'}
                    color={'text.disabled'}
                    fontSize={'14px'}
                  >
                    {componentText.twoDaysOffline}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </RoleRequired>
          </>
        )}
      </Grid>
      <DodgeModal
        title={componentText.manageNotifications}
        open={openSensorsNotif}
        onClose={() => {
          refetchNotif();
          setOpenSensorsNotif(false);
        }}
        width={'80rem'}
        minHeight={'80vh'}
      >
        <SensorsNotificationsTable />
      </DodgeModal>
      <DodgeModal
        title={componentText.manageNotifications}
        open={openRecurrEventsNotif}
        onClose={() => {
          refetchNotif();
          setOpenRecurrEventsNotif(false);
        }}
        width={'50rem'}
      >
        <RecurrEventsNotificationsTable />
      </DodgeModal>
    </>
  );
};

export default memo(Notification);
