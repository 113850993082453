import { assetGroupTitleColor } from "features/dashboard/colors/colors"

export const fontAssetGroup = {
    fontSize:"0.75rem",
    fontWeight:500,
    lineHeight:"1.245rem",
    letterSpacing:"0.4px",
    color: "rgba(0, 0, 0, 0.8)",
  }
export const fontAssetGroupName = {
    fontSize:"0.75rem", 
    fontWeight:400,
    lineHeight:"0.875rem",
    letterSpacing:"0.1px",
    color: assetGroupTitleColor,
  }
 export const fontName = {
    fontSize:"1rem",
    fontWeight:600,
    lineHeight:"1.75rem",
    letterSpacing:"0.15px",
  }
export const fontAlert = {
    fontSize:"0.8125rem",
    fontWeight:600,
    // lineHeight:"1.5rem",
    letterSpacing:"0.4px",
  }
  
export const fontLastMeasurement = {
    fontSize:"0.625rem",
    //lineHeight:"0.875rem",
    letterSpacing:"0.3px",
    color: assetGroupTitleColor,
    fontWeight: 700
  }