import { Box, Button, Divider, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { SensorInfo, Step } from './MissingCartridge';
import SensorData from './SensorData';
import { useClearCartridgeFlag } from 'api/assets/useClearCartridgeFlag';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'languages';

type Props = {
  setActiveStep: (newStep: Step) => void;
  onClose: () => void;
  data?: SensorInfo;
};

const CartridgeReplacement: FC<Props> = ({ setActiveStep, onClose, data }) => {
  const { mutate, isPending } = useClearCartridgeFlag(onClose);

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    mutate({
      params: {
        serialNumber: data!.serialNumber,
        assetId: data!.assetId,
      },
    });
  };
  const handleChangeStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    data?.type === 'IntelliLube' ? setActiveStep(2) : setActiveStep(4);
  };
  const {translate} = useTranslation()
  const componentText = useMemo(() =>{
    return({
      detectedInfo: translate("cartridge_replacement_detected"),
      askPart1 : translate("change_cartridge_ask"),
      askPart2 : translate("or_grease_type_ask"),
      yes: translate("yes")
   })
  }, [])

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Typography fontSize='1.5rem' fontWeight='600' color='primary'>
        {componentText.detectedInfo}
      </Typography>
      <Typography
        fontSize='1rem'
        letterSpacing='0.15px'
      >{`${componentText.askPart1} ${data?.type === 'IntelliLube' ? componentText.askPart2 : ''}?`}</Typography>
      <Divider />
      <SensorData data={data} />
      <Divider />
      <Box display='flex' justifyContent='space-between' mt={2}>
        <LoadingButton
          variant='outlined'
          color='secondary'
          sx={{ fontWeight: '600', color: 'black' }}
          onClick={handleCancel}
          disabled={!data}
          loading={isPending}
        >
          No
        </LoadingButton>
        <Button
          variant='contained'
          color='secondary'
          sx={{ fontWeight: '600', color: 'black' }}
          onClick={handleChangeStep}
        >
          {componentText.yes}
        </Button>
      </Box>
    </Box>
  );
};

export default CartridgeReplacement;
