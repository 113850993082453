import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface CartridgesState {
    cartridgeType: string,
    cartridgeSize: string,

    reportStatus: string,
  }
  
  const initialState: CartridgesState = {
    cartridgeType: "all",
    cartridgeSize: "all",

    reportStatus: "all",
  };
  
 
  export const cartridgesSlice = createSlice({
    name: 'cartridges',
    initialState,
    reducers: {
        changeCartridgeType: (state, action) => {
        state.cartridgeType = action.payload;
      },
       changeCartridgeSize: (state, action) => {
        state.cartridgeSize = action.payload;
      },  
      changeReportStatus: (state, action) => {
        state.reportStatus = action.payload;
      },  
    },
  })
  
  export const { changeCartridgeType, changeCartridgeSize, changeReportStatus} = cartridgesSlice.actions;
  
  export const selectCartridgeType = (state: RootState) => state.cartridges.cartridgeType;
  export const selectCartridgeSize = (state: RootState) => state.cartridges.cartridgeSize;
  export const selectReportStatus = (state: RootState) => state.cartridges.reportStatus;

  export default cartridgesSlice.reducer;