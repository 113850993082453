import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

interface AutoCloseBody {
  timePeriod: number;
}

export const getAutoCloseUrl = (plantId: number) => `/plant/${plantId}/auto-close`;

export const useGetAutoClose = () => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_get_auto_close_informations_toast'),
    };
  }, []);
  const plantId = useSelector(selectPlantId);

  return useAuthQuery<AutoCloseBody[]>({
    url: `/plant/${plantId}/auto-close`,
    queryOptions: {
      meta: {
        error: {
          message: componentText.errorMess,
        },
      },
    },
  });
};
