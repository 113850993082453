import { useAuthMutation } from 'api/queries';
import { AxiosResponse } from 'axios';
import { UseMutationResult } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

const NOTIFICATION_WAYS = ['email', 'push'] as const;
export type NotificationWay = (typeof NOTIFICATION_WAYS)[number];

type Params = {
  assetId: number;
};

type Body = {
  type: string;
  notificationWay: NotificationWay;
  value: boolean;
};

export const usePutRecurrEventsNotif = () => {
  const plantId = useSelector(selectPlantId);

  return useAuthMutation<Params, Body>({
    url(params) {
      return `users/plant/${plantId}/asset-notifications?assetId=${params.assetId}`;
    },
    method: 'PUT',
  }) as UseMutationResult<AxiosResponse<unknown>>;
};
