import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export interface AdditionalField {
  defaultValue: number | string;
  label: string;
  name: string;
  type: string;
  unit: number;
}

export const useGetAssetTypeConfiguration = (type: string, partId?: number | undefined) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_asset_type_configuration_toast'),
    };
  }, []);

  return useAuthQuery<AdditionalField[]>({
    url: `assets/types`,
    params: {
      type,
      ...(partId && { partId: partId.toString() }),
    },
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
