import { Sensor } from "features/plants-management/models/sensor.model";

const createSensorsRecord = (sensors: Sensor[]) =>
  sensors.reduce(
    (sensorsObject, sensor) => ({
      ...sensorsObject,
      [sensor.serialNumber]: {
         location: sensor.location,
         pin: sensor.pin ? sensor.pin : null,
         initialSettings: typeof sensor.initialSettings === 'undefined' ? {} : sensor.initialSettings,
         additionalProperties: typeof sensor.additionalProperties === 'undefined' ? {} : sensor.additionalProperties,
      }
    }),
    {}
  );

export default createSensorsRecord;
