import { GetApp } from '@mui/icons-material';
import { Box, Chip, Grid, Popover, Tooltip, Typography } from '@mui/material';
import { Asset, Sensor } from 'features/assets-management/models';
import { FFTDetails } from 'features/assets-management/models/FFTDetails.model';
import { getAssetIcon } from 'features/new-dashboard/components/tables/assets-table/Assets';
import { shortenName } from 'helpers/utils';
import { useTranslation } from 'languages';
import { FC, useState } from 'react';
import { useDeviceSize } from 'shared/responsive';
import '../styles/fftStyles.scss';
import { fftBackgroundColor } from './VibrationFFTPage';
import ColleactRawData from './menu/CollectRawData';

type Props = {
  selectedAssetName: string;
  selectedAssetDetails: Asset | undefined;
  activeSensor: number;
  selectedSensor: Sensor | undefined;
  disableDownload: boolean;
  url?: string;
  setIsUserClickDownload: (isClick: boolean) => void;
  fftDetails?: FFTDetails;
  disableDownloadRawData: boolean;
  setIsUserClickDownloadRawData: (isClick: boolean) => void;
  urlRawData?: string;
};

const FFTHeader: FC<Props> = ({
  selectedAssetName,
  selectedAssetDetails,
  activeSensor,
  selectedSensor,
  disableDownload,
  url,
  fftDetails,
  setIsUserClickDownload,
  disableDownloadRawData,
  setIsUserClickDownloadRawData,
  urlRawData,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleOnClick = () => {
    if (disableDownload) return;

    setIsUserClickDownload(true);
    setAnchorEl(null);
  };
  const handleOnClickRawData = () => {
    if (disableDownloadRawData) return;

    setIsUserClickDownloadRawData(true);
    setAnchorEl(null);
  };
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (disableDownload && disableDownloadRawData) return;
    setAnchorEl(event.currentTarget);
  };
  const openpopover = Boolean(anchorEl);
  const id = openpopover ? 'simple-popover' : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { translate } = useTranslation();

  const componentText = {
    collectRawDataLabel: translate('asset_collect_raw_data_label'),
    downloadButton: translate('download_button'),
  };

  const { isPhone, isTablet } = useDeviceSize();
  const responsiveProps = {
    marginRight: isPhone || isTablet ? '' : '310px',
    alignItems: isPhone || isTablet ? '' : 'center',
    justifyContent: isPhone || isTablet ? '' : 'space-between',
    height: isPhone ? '1.2rem' : '3.2rem',
  };
  const joinName =
    (fftDetails?.manufacturer ? fftDetails.manufacturer + '  ' : '') + (fftDetails?.number ?  fftDetails.number + '  ' : '') + (fftDetails?.description ?? '');
  return (
    <Grid
      container
      alignItems={responsiveProps.alignItems}
      justifyContent={responsiveProps.justifyContent}
      flexDirection={'row'}
      flexWrap={'nowrap'}
      sx={{
        background: fftBackgroundColor,
        borderRadius: '16px 0 0 0',
      }}
      p='1rem'
    >
      <Grid item container alignItems='center' gap={1} xs={12}>
        <Grid item container gap={2}>
          <Grid item width='3.2rem' container justifyContent='center' alignItems='center'>
            <Grid item container justifyContent='center' alignItems='center' className='icon'>
              {getAssetIcon(selectedAssetDetails?.type ?? '')}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              color: 'black',
              fontWeight: '600',
              textTransform: 'uppercase',
              fontSize: '1.2rem',
            }}
          >
            {isPhone || isTablet ? shortenName(selectedAssetName, 15) : selectedAssetName ?? ''}
          </Grid>
          <Grid
            item
            lg={6}
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: '1.2rem',
              letterSpacing: '0.15px',
              display: 'inline-flex',
              gap: 1,
            }}
          >
            <Typography fontSize='inherit' letterSpacing='inherit'>
              {isPhone || isTablet ? shortenName(joinName, 15) : joinName ?? ''}
            </Typography>
          </Grid>
        </Grid>
        {selectedAssetDetails?.type === 'motor' && (
          <Grid item>
            <Grid
              item
              container
              gap={2}
              sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '.85rem', letterSpacing: '0.15px' }}
            >
              <Typography fontSize='inherit' letterSpacing='inherit'>{`NDE ${
                fftDetails?.ndebNumber ?? '-'
              }`}</Typography>
              <Typography fontSize='inherit' letterSpacing='inherit'>{`DE ${
                fftDetails?.debNumber ?? '-'
              }`}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item container justifyContent='flex-end' xs={12} lg={6}>
        <Grid item container width='fit-content' justifyContent='center' alignItems='center'>
          <Tooltip
            title={''}
            placement='top'
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: '12rem',
                  background: 'white',
                  color: 'black',
                  fontSize: '.75rem',
                  boxShadow:
                    '0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
                  mt: '-5%',
                  py: 1.5,
                },
              },
            }}
          >
            <Chip
              data-testid='assets_management_vibration_fft_download_button'
              aria-describedby={id}
              label={componentText.downloadButton.toUpperCase()}
              variant='outlined'
              size='small'
              icon={
                <GetApp
                  sx={{
                    color:
                      disableDownload && disableDownloadRawData
                        ? 'rgba(0, 0, 0, 0.26) !important'
                        : '#327179 !important',
                  }}
                />
              }
              sx={{
                background: 'transparent',
                border: 'none',
                fontSize: '.9rem',
                fontWeight: '600',
                px: 1,
                py: 1.5,
                lineHeight: 1.75,
                '&.MuiButtonBase-root': {
                  borderRadius: 0,
                  color:
                    disableDownload && disableDownloadRawData
                      ? 'rgba(0, 0, 0, 0.26)'
                      : '#327179 !important',
                },
                '&.MuiButtonBase-root:hover': { background: 'none' },
              }}
              onClick={(e) => handleOpen(e)}
            />
          </Tooltip>
          <Popover
            id={id}
            open={openpopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{ '& .MuiPopover-paper': { borderRadius: '.5rem', width: '8rem' } }}
          >
            <Box
              data-testid='assets_management_vibration_download_fft_data'
              component={disableDownload ? 'div' : 'a'}
              href={url}
              download='fft_data.xlsx'
              sx={{
                '&:hover': { background: '#f2f1f1' },
                cursor: disableDownload ? 'default' : 'pointer',
                color: disableDownload ? 'rgba(0, 0, 0, 0.26)' : 'black',
                width: '8rem',
                display: 'block',
                my: 1,
                px: 1.5,
              }}
              onClick={handleOnClick}
            >
              FFT DATA
            </Box>
            {!disableDownloadRawData && (
              <Box
                data-testid='assets_management_vibration_download_raw_data'
                component={disableDownloadRawData ? 'div' : 'a'}
                href={urlRawData}
                download='raw_data.xlsx'
                sx={{
                  '&:hover': { background: '#f2f1f1' },
                  cursor: disableDownloadRawData ? 'default' : 'pointer',
                  color: disableDownloadRawData ? 'rgba(0, 0, 0, 0.26)' : 'black',
                  width: '8rem',
                  display: 'block',
                  mb: 1,
                  px: 1.5,
                }}
                onClick={handleOnClickRawData}
              >
                {componentText.collectRawDataLabel}
              </Box>
            )}
          </Popover>
        </Grid>
        <Grid item>
          <ColleactRawData />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FFTHeader;
