import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { fontMedium, fontTitle } from "./styles/fontStyle";
import HandymanIcon from '@mui/icons-material/Handyman';
import { useTranslation } from "languages";
import { useMemo } from "react";
import { useResponsiveLanguage } from "shared/responsive";

const Resources = () =>{
    const { translate } = useTranslation();
    const componentText = useMemo(() => { 
      return {
        optifyUserGuide: translate('menu_optify_user_guide_label'),
        iiotProducts: translate('menu_iiot_products_label'),
        swipeguideButton: translate('menu_swipeguide_button'),
        iiotLink: translate('menu_iiot_link'),
      };
    }, []);

    const responsiveProps = {
      linkStyles: useResponsiveLanguage({
        en: { xs : { mt: 1, ...fontMedium, color:"rgba(0, 122, 255, 1) !important", textDecoration:"underline !important"}},
        es: { xs: { fontWeight: 800}}
      }),
      buttonStyles: useResponsiveLanguage({
        en: { xs: { fontWeight:'600', fontSize: '0.7rem', mt: 1}, lg: { mt: 0}},
      })
    }
    return (
    <Grid pl={2} container>
        <Grid xs={12} pb="1rem">
          <Typography sx={{ ...fontMedium, display: 'inline-block', mr: 2 }}>{componentText.optifyUserGuide}</Typography>
          <Button data-testid="manage_account_help_support_dodge_swipeguide_button" variant="contained" startIcon={<HandymanIcon />} color="secondary" component="a" href="https://iiot-dodgeindustrial.swipeguide.com/guide/optify-user-guide" target="_blank" sx={{...responsiveProps.buttonStyles}}>{componentText.swipeguideButton}</Button>
        </Grid>
        <Grid xs={12} pb="1rem">
         <Typography sx={{ ...fontMedium, display: 'inline-block', mr: 2 }}>{componentText.iiotProducts} </Typography>
         <Typography data-testid="manage_account_help_support_iiot_technologies_dodge_industrial_button" sx={{ ...responsiveProps.linkStyles }} component="a" href="https://dodgeindustrial.com/products/iiot-technologies/" target="_blank">{componentText.iiotLink}</Typography>
        </Grid>
    </Grid>
    );
}

export default Resources;