import { Box, Grid, Typography, IconButton } from '@mui/material';
import { FC, memo } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { useTimeConverter } from 'features/dashboard/utils/formatDate';
import {
  alarmColor,
  assetGroupTitleColor,
  okColor,
  warningColor,
  noDataColor,
} from 'features/dashboard/colors/colors';
import CounterTile from './CounterTile';
import { fontAlert, fontAssetGroupName, fontLastMeasurement } from './styles/fontTile';
import MeasurementIcon from '../../../icons/measurement_icon.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AssetStatusGroup } from 'features/dashboard/models/assetStatusGroup.model';
import SyncError from '../../AdditionalErrors/SyncError';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useSelector } from 'react-redux';
import { selectOpenRowId } from 'features/dashboard/store/dashboardSlice';
import { useTranslation } from 'languages';
import IncompatibleCartridgeError from '../../AdditionalErrors/IncompatibleCartridgeError';
import MissingCartridgeError from '../../AdditionalErrors/MissingCartridgeError';
import NoDataError from '../../AdditionalErrors/NoDataError';
import CartridgeInfoMissing from '../../AdditionalErrors/CartridgeInfoMissing';

interface GroupTileBodyProps {
  row: AssetStatusGroup;
  index: number;
  handleChangeExpand: (id: number) => void;
  tileWidth: number;
}

const GroupTileBody: FC<GroupTileBodyProps> = ({ row, index, handleChangeExpand, tileWidth }) => {
  const openRowId = useSelector(selectOpenRowId);
  const { calculateTime } = useTimeConverter();

  const { translate } = useTranslation();
  const componentText = {
    alertTypeNoDataName: translate('asset_tile_alert_type_no_data_name'),
    alertTypeUnknown: translate('alert_type_unknown'),
    oldestMeasurementText: translate('oldest_measurement_text'),
    oldestMeasurementShortText: translate('oldest_measurement_short_text'),
    alertTypeGroupAlarmText: translate('status_alarm'),
    alertTypeGroupAlertText: translate('status_alert'),
    alarmTypeGroupAlarmsText: translate('status_alarms'),
    alarmTypeGroupAlertsText: translate('status_alerts'),
    alertTypeOkName: translate('dashboard_alert_type_ok_name'),
    noOnlineDataTypeName: translate('dashboard_no_online_data_name'),
    synchronizationErrorText: translate('synchronization_error_text'),
    incompatibleCartridge: translate('asset_tile_body_incompatible_cartridge'),
    missingCartridge: translate('asset_tile_body_missing_cartridge'),
    noData: translate('asset_tile_body_no_data'),
    cartridgeInfoMissing: translate('asset_tile_body_cartridge_info_missing'),
  };

  const isDesynchronized = row.sensors.length
    ? row.sensors.find((sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'desynchronized')
    : undefined;

  const isNoRecordedData = row.sensors.length
    ? row.sensors.find((sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'noRecordedData')
    : undefined;
  const isNoCartridgeInfo = row.sensors.length
    ? row.sensors.find(
        (sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'noCartridgeInfo',
      )
    : undefined;
  const isIncompatibleCartdrige = row.sensors.length
    ? row.sensors.find(
        (sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'cartridgeIncompatible',
      )
    : undefined;
  const isMissingCartdrige = row.sensors.length
    ? row.sensors.find(
        (sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'cartridgeMissing',
      )
    : undefined;

  const getError = (
    errorName: string,
    assetName: string,
    location: string,
    serialNumber: string,
  ) => {
    return (
      <Box display='flex' alignItems='center'>
        <ReportProblemOutlinedIcon sx={{ color: alarmColor, fontSize: '1rem', mr: 1, ml: 1 }} />
        <Typography
          display={tileWidth >= 360 ? 'inline' : 'none'}
          sx={{
            ...fontLastMeasurement,
            color: alarmColor,
          }}
        >{`${errorName}, ${assetName}, ${location} (${serialNumber})`}</Typography>
      </Box>
    );
  };

  return (
    <>
      <Grid item container alignItems='center' data-testid='asset_group_tile_body'>
        <Grid item mr='1rem'>
          <Typography sx={{ ...fontAssetGroupName }}>{row.assetGroupName.toUpperCase()}</Typography>
        </Grid>
        <Grid item mr='1rem'>
          {row.lastMeasurement && (
            <Box
              display='flex'
              alignItems='center'
              height='1rem'
              data-testid='asset_group_last_measurement'
              data-value={row.lastMeasurement}
            >
              <Box sx={{ mr: 1 }}>
                <img src={MeasurementIcon} alt='icon' />
              </Box>
              <Typography sx={{ ...fontLastMeasurement, mr: 1 }}>
                {' '}
                {`${componentText.oldestMeasurementText}`}{' '}
              </Typography>
              {
                <Typography sx={{ ...fontLastMeasurement }}>
                  {' '}
                  {`${calculateTime(row.lastMeasurement, false)}`}
                </Typography>
              }
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid item container mt='0.5rem'>
        <Box display='flex' alignItems='center' sx={{ height: '2rem' }}>
          {row.alarmCounter > 0 && (
            <CounterTile
              counter={row.alarmCounter}
              background={alarmColor}
              icon={<SouthIcon sx={{ fontSize: '1rem', marginLeft: '2px' }} />}
            />
          )}
          {row.warningCounter > 0 && (
            <CounterTile
              counter={row.warningCounter}
              background={warningColor}
              icon={<SouthEastIcon sx={{ fontSize: '1rem', marginLeft: '2px' }} />}
            />
          )}
          {row.healthyCounter > 0 && (
            <CounterTile
              counter={row.healthyCounter}
              background={okColor}
              icon={<NorthIcon sx={{ fontSize: '1rem', marginLeft: '2px' }} />}
            />
          )}
          {row.noDataCounter > 0 && (
            <CounterTile
              counter={row.noDataCounter}
              background={noDataColor}
              icon={<NorthIcon sx={{ fontSize: '1rem', marginLeft: '2px' }} />}
            />
          )}
        </Box>
      </Grid>
      <Grid item container>
        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%' pb={1}>
          <Box display='flex' alignItems='center'>
            {row.alarmCounter ? (
              <>
                <ErrorOutlineIcon
                  data-testid='alarm_asset_group_status'
                  sx={{ color: alarmColor, mr: 1, fontSize: '1.1rem' }}
                />
                <Typography sx={{ ...fontAlert, whiteSpace: 'nowrap' }}>
                  {row.alarmCounter > 1
                    ? `${componentText.alarmTypeGroupAlarmsText}`
                    : `${componentText.alertTypeGroupAlarmText}`}
                </Typography>
              </>
            ) : row.warningCounter > 0 ? (
              <>
                <ReportProblemOutlinedIcon
                  data-testid='alert_asset_group_status'
                  sx={{ color: warningColor, mr: 1, fontSize: '1.1rem' }}
                />
                <Typography sx={{ ...fontAlert, whiteSpace: 'nowrap' }}>
                  {' '}
                  {`${componentText.alertTypeGroupAlertText}`}{' '}
                </Typography>
              </>
            ) : row.healthyCounter > 0 ? (
              <>
                <CheckCircleOutlineIcon
                  data-testid='ok_asset_group_status'
                  sx={{ color: okColor, mr: 1, fontSize: '1.1rem' }}
                />
                <Typography sx={{ ...fontAlert, whiteSpace: 'nowrap' }}>
                  {' '}
                  {`${componentText.alertTypeOkName}`}
                </Typography>
              </>
            ) : (
              <>
                <CloudOffIcon sx={{ color: assetGroupTitleColor, mr: 1, fontSize: '1.1rem' }} />
                <Typography sx={{ ...fontAlert, whiteSpace: 'nowrap' }}>
                  {' '}
                  {`${componentText.noOnlineDataTypeName}`}
                </Typography>
              </>
            )}
            {isDesynchronized && (
              <SyncError>
                {getError(
                  componentText.synchronizationErrorText,
                  isDesynchronized.assetName,
                  isDesynchronized.location,
                  isDesynchronized.serialNumber,
                )}
              </SyncError>
            )}
            {isIncompatibleCartdrige && (
              <IncompatibleCartridgeError>
                {getError(
                  componentText.incompatibleCartridge,
                  isIncompatibleCartdrige.assetName,
                  isIncompatibleCartdrige.location,
                  isIncompatibleCartdrige.serialNumber,
                )}
              </IncompatibleCartridgeError>
            )}
            {isMissingCartdrige && (
              <MissingCartridgeError>
                {getError(
                  componentText.missingCartridge,
                  isMissingCartdrige.assetName,
                  isMissingCartdrige.location,
                  isMissingCartdrige.serialNumber,
                )}
              </MissingCartridgeError>
            )}
            {isNoRecordedData && (
              <NoDataError>
                {getError(
                  componentText.noData,
                  isNoRecordedData.assetName,
                  isNoRecordedData.location,
                  isNoRecordedData.serialNumber,
                )}
              </NoDataError>
            )}
            {isNoCartridgeInfo && (
              <CartridgeInfoMissing>
                {getError(
                  componentText.cartridgeInfoMissing,
                  isNoCartridgeInfo.assetName,
                  isNoCartridgeInfo.location,
                  isNoCartridgeInfo.serialNumber,
                )}
              </CartridgeInfoMissing>
            )}
          </Box>
          <IconButton
            onClick={() => handleChangeExpand(index)}
            disabled={!row.sensors.length}
            size='small'
          >
            {openRowId === index ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </Box>
      </Grid>
    </>
  );
};

export default memo(GroupTileBody);
