import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { PlantBody } from 'models/plant/createPlantBody.model';
import { useMemo } from 'react';

export const getPlantByIdUrl = (plantId: string) => `plants/${plantId}`;

export const useGetPlantById = (plantId: string | undefined) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_plant_toast'),
    };
  }, []);

  return useAuthQuery<PlantBody>({
    url: `plants/${plantId}`,
    queryOptions: {
      enabled: !!plantId,
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
