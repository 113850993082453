import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, Button, CircularProgress, FormControl, TextField } from '@mui/material';
import { useGetGateways, useGetSensorsByGateway } from 'api/gateways';
import { Sensor } from 'features/plants-management/models/sensor.model';
import { useTranslation } from 'languages';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { SensorsList } from './SensorsList';
import { Gateway } from 'features/gateways-management/components/SensorAssignmentTable/models/SensorAssignment.models';

type GatewaySensorsListProps = {
  onSelectedGatewayId: (gatewayId: string | undefined) => void;
  setSelectedSensors: Dispatch<SetStateAction<Sensor[]>>;
  selectedSensors: Sensor[];
  sensorsQuant: number;
  assetType: string;
  onlyIntelliLube?: boolean;
};

export const GatewaySensorsList: FC<GatewaySensorsListProps> = ({
  onSelectedGatewayId,
  setSelectedSensors,
  sensorsQuant,
  assetType,
  selectedSensors,
  onlyIntelliLube,
}) => {
  const [selectedGateway, setSelectedGateway] = useState<Omit<Gateway, 'sensors'> | null>(null);

  const [availableSensors, setAvailableSensors] = useState<Sensor[]>([]);

  const { data, isLoading: isGatewaysLoading } = useGetGateways();
  const { data: sensors = [], isLoading: isSensorsLoading } = useGetSensorsByGateway(
    selectedGateway?.deviceId,
    false,
  );

  const [gateways, setGateways] = useState<Omit<Gateway, 'sensors'>[]>([]);

  useEffect(() => {
    if (data && !isGatewaysLoading) {
      setGateways(data.gateways);
    }
  }, [data, isGatewaysLoading]);

  useEffect(() => {
    if (sensors.length) {
      const selectedSensorsSN = selectedSensors.map((sensor) => sensor.serialNumber);
      if (!onlyIntelliLube) {
        setAvailableSensors(
          sensors.filter((sensor) => !selectedSensorsSN.includes(sensor.serialNumber)),
        );
      } else if (onlyIntelliLube) {
        setAvailableSensors(
          sensors.filter(
            (sensor) =>
              sensor.type === 'IntelliLube' && !selectedSensorsSN.includes(sensor.serialNumber),
          ),
        );
      } else {
        setAvailableSensors(
          sensors.filter((sensor) => !selectedSensorsSN.includes(sensor.serialNumber)),
        );
      }
    }
  }, [assetType, selectedSensors, sensors]);

  useEffect(() => {
    setSelectedSensors(selectedSensors);
  }, [selectedSensors, setSelectedSensors]);

  const onGatewaySelected = (_: any, gateway: Omit<Gateway, 'sensors'> | null) => {
    if (!gateway) {
      setSelectedSensors([]);
      setAvailableSensors([]);
    } else {
      setSelectedGateway(gateway);
    }
    onSelectedGatewayId(gateway?.deviceId);
  };

  const onSensorSelected = (sensor: Sensor) => {
    setAvailableSensors((sensors) => sensors.filter((x) => x.macAddress !== sensor.macAddress));
    let posNumber = sensorsQuant + 1;
    while (true) {
      const newPos = `Position ${posNumber}`;
      if (
        selectedSensors.every((sensor) => sensor.location.toLowerCase() !== newPos.toLowerCase())
      ) {
        sensor.location = newPos;
        setSelectedSensors((sensors) => [...sensors, sensor]);
        return;
      }
      posNumber++;
    }
  };

  const onSensorUnselected = (sensor: Sensor) => {
    setAvailableSensors((sensors) => [sensor, ...sensors]);
    setSelectedSensors((sensors) => sensors.filter((x) => x.macAddress !== sensor.macAddress));
  };

  const changeLocation = (value: string, sensor: Sensor) => {
    setSelectedSensors(
      selectedSensors.map((item: Sensor) =>
        item.serialNumber === sensor.serialNumber ? { ...sensor, location: value } : item,
      ),
    );
  };

  const { translate } = useTranslation();

  const componentText = {
    rowsPerPageLabel: translate('datatable_rows_per_page_label'),
    onlineGateway: translate('commissioning_online_gateway_label'),
    select: translate('plants_select_button'),
    noSensorsAvailable: translate('no_sensors_available'),
    noSensorsSelected: translate('no_sensors_selected'),
    selected: translate('selected'),
    available: translate('available'),
    positionLabel: translate('position_label'),
    characters: translate('characters'),
    of: translate('pagination_of_label'),
  };
  return (
    <Box sx={{ pr: 1 }}>
      <FormControl fullWidth>
        <Autocomplete
          size='small'
          onChange={onGatewaySelected}
          options={gateways.filter((gateway) => gateway.status === 'Connected')}
          loading={isGatewaysLoading}
          getOptionLabel={(gateway: Omit<Gateway, 'sensors'>) => gateway.name}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ '& input': { fontWeight: 'bold' } }}
              label={componentText.onlineGateway}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isGatewaysLoading ? (
                      <CircularProgress color='primary' size={20} sx={{ mr: 1 }} />
                    ) : (
                      params.InputProps.endAdornment
                    )}
                  </>
                ),
              }}
            />
          )}
          sx={{ mb: 2 }}
          data-testid='plants_managment_commission_sensors_modal_online_gateway_autocomplete'
        />
      </FormControl>
      {!!selectedGateway && (
        <>
          <SensorsList
            componentText={componentText}
            sensors={availableSensors.filter((s) => s.ping < 300)}
            listHeader={componentText.available}
            emptyMessage={componentText.noSensorsAvailable}
            isLoading={isSensorsLoading}
            onSensorClicked={onSensorSelected}
            actionButton={
              <Button variant='contained' color='primary' size='small'>
                {componentText.select}
              </Button>
            }
            testid='plants_management_commission_sensors_modal_available_sensors_list'
          />
          <SensorsList
            componentText={componentText}
            sensors={selectedSensors}
            listHeader={componentText.selected}
            emptyMessage={componentText.noSensorsSelected}
            onSensorClicked={onSensorUnselected}
            changeLocation={changeLocation}
            actionButton={<CloseIcon sx={{ cursor: 'pointer' }} />}
            incrementIndex={sensorsQuant}
            testid='plants_management_commission_sensors_modal_selected_sensors_list'
          />
        </>
      )}
    </Box>
  );
};
