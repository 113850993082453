import { Configuration, LogLevel } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_B2C_CLIENT_ID}`,
    authority: `https://${process.env.REACT_APP_B2C_TENANT}.b2clogin.com/${process.env.REACT_APP_B2C_TENANT}.onmicrosoft.com/${process.env.REACT_APP_B2C_POLICY_ID}`,
    knownAuthorities: [`${process.env.REACT_APP_B2C_TENANT}.b2clogin.com`],
    redirectUri: `${process.env.REACT_APP_DOMAIN}/dashboard`,
    postLogoutRedirectUri: `${process.env.REACT_APP_DOMAIN}/`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    // loggerOptions: {
    //   loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //     }
    //   },
    //   piiLoggingEnabled: false,
    // },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};
