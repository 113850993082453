import { Box, Divider, MenuItem, Typography } from '@mui/material';
import { RoleRequired } from 'components/RoleRequired';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { useTranslation } from 'languages';
import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { useMenuButtons } from '../menu-buttons';
import LoginAvatar from './LoginAvatar';

interface MenuContentProps {
  logout: () => void;
  handleOpenManageAccount: (e: React.MouseEvent<HTMLElement>) => void;
  firstName: string;
  lastName: string;
  email: string;
}

const MenuContent: FC<MenuContentProps> = ({
  logout,
  handleOpenManageAccount,
  firstName,
  lastName,
  email,
}) => {
  const dateFormatter = useDateFormatter();
  const menuButtons = useMenuButtons();

  const { translate } = useTranslation();

  const componentText = {
    signOutText: translate('menu_sign_out_text'),
    termsOfServiceText: translate('menu_terms_of_service'),
  };
  return (
    <>
      <Box display='flex' justifyContent='center' alignItems='center' my='22px'>
        <LoginAvatar border='1px solid black' size='2.5rem' />
        <Box>
          <Typography
            fontWeight='600'
            fontSize='1.2rem'
            className='nav-name-container'
          >{`${firstName} ${lastName}`}</Typography>
          <Typography className='nav-email-container'>{email}</Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 0.5 }} />
      {menuButtons.map((item) => {
        const menuItem = (
          <MenuItem
            key={item.id}
            id={item.id}
            onClick={handleOpenManageAccount}
            disableRipple
            data-testid={item.testid}
          >
            {item.label}
          </MenuItem>
        );
        if (item.blacklist && item.blacklist.length !== 0) {
          return (
            <RoleRequired roles={item.blacklist} mode='blacklist' key={item.id}>
              {menuItem}
            </RoleRequired>
          );
        } else {
          return menuItem;
        }
      })}
      <Divider sx={{ my: 0.5 }} />

      <MenuItem onClick={logout} disableRipple data-testid='menu_sign_out'>
        {componentText.signOutText}
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <Box display='flex' justifyContent='space-between' alignItems='center' py='10px' px='20px'>
        <Typography fontSize='0.8rem' sx={{ color: 'rgba(118, 125, 138, 1)' }}>
          {`© ${dateFormatter(new Date(), 'YYYY')} Dodge Industrial, Inc.`}
        </Typography>
        <Typography
          fontSize='0.8rem'
          sx={{ color: 'rgba(118, 125, 138, 1)' }}
          data-testid='menu_tos'
        >
          <Link to='/end-user-license-agreement'>{componentText.termsOfServiceText}</Link>
        </Typography>
      </Box>
    </>
  );
};

export default memo(MenuContent);
