import { FC, memo } from "react";
import {  Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import LoginAvatar from "./LoginAvatar";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface LoginProps{
  open: boolean,
  handleClick: (event: React.MouseEvent<HTMLElement>)=>void,
  firstName: string,
  lastName: string,
  email: string,
}

const Login: FC<LoginProps> = ({open, handleClick, firstName, lastName, email}) =>{
  const theme = useTheme();
  const xs = useMediaQuery(`(max-width:750px)`);
  const display = xs ? "none" : undefined;
    return(
        <Box 
            display="inline-flex"
            alignItems="center"
            id="user"
            data-testid="login_navbar_menu"
            aria-controls={open ? 'menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
      >
        <LoginAvatar size="2rem" xs={xs} />
        <Box display={display}>
         <Typography fontWeight="600" letterSpacing="0.06rem" color="white" className="nav-name-container">{`${firstName} ${lastName}`}</Typography>
         <Typography fontSize="0.9rem" color="white" className="nav-email-container">{email}</Typography>
        </Box>
        <ArrowDropDownIcon sx={{ display, color:"white", fontSize:"2rem", ml:"1rem",}} />
      </Box>
    );
}

export default memo(Login);