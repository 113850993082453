import { useGetUserRoles } from 'api/users';
import { Loading } from 'components';
import LicenceArgeementPage from 'components/CookiePage/pages/LicenceArgeementPage';
import { MainLayout } from 'components/Layouts';
import { UserInvitationStatus } from 'features/plants-management/models/user.model';
import { UserRoleInfo } from 'models/user-role.model';
import { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { selectEulaAccepted } from 'store/accountSlice';

const checkUserInvitationStatus = (
  roles: UserRoleInfo[],
  status: UserInvitationStatus,
): boolean => {
  return roles.some((role: UserRoleInfo) => role.invitationStatus === status);
};

const PlantRequired = ({ children }: PropsWithChildren<void>) => {
  const { data: userRoles } = useGetUserRoles();
  const eulaAccepted = useSelector(selectEulaAccepted);

  const roles = useMemo(
    () => userRoles?.filter((role) => role.plantName !== null && role.plantId !== 0),
    [userRoles],
  );

  if (!roles) {
    return (
      <MainLayout>
        <Loading />
      </MainLayout>
    );
  }

  if (roles.length > 0 && !eulaAccepted) {
    return (
      <MainLayout>
        <LicenceArgeementPage eulaAccepted={eulaAccepted} />
      </MainLayout>
    );
  }

  if (checkUserInvitationStatus(roles, 'invited')) {
    return <Redirect to='/onboarding/invitation' />;
  }

  if (checkUserInvitationStatus(roles, 'accepted')) {
    return <>{children}</>;
  }

  if (checkUserInvitationStatus(roles, 'pending')) {
    return <Redirect to='/onboarding/Pending' />;
  }

  return <Redirect to='/onboarding/welcome' />;
};

export default PlantRequired;
