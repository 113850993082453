import React from "react";
import { Grid, Avatar } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import DropdownMenu from "features/user-panel/components/DropdownMenu/DropdownMenu";

const LoggedInProfile = () => {
  const { instance } = useMsal();

  const logout = () => {
    localStorage.removeItem('redirectionUrl');

    const logoutRequest = {
      account: instance.getActiveAccount(), 
    }; 
    instance.logout(logoutRequest);
  };

  return (
    <>
      <Grid container direction="row" alignItems="center">
        <Grid item container alignItems="center">
          <Grid item>
            <DropdownMenu logout={logout} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default React.memo(LoggedInProfile);
