import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPlantId } from 'store/accountSlice';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface UpdateSensorBody {
  location: string;
  properties: {
    [key: string]: string | number | boolean;
  };
}

export const useUpdateSensor = (assetId: string, serialNumber: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const plantId = useSelector(selectPlantId);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastEditSensorError: translate('toast_edit_sensor_error'),
      toastEditSensorSuccess: translate('toast_edit_sensor_success'),
    };
  }, []);

  return useAuthMutation<{}, UpdateSensorBody>({
    url: `assets/${assetId}/sensors/${serialNumber}`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.toastEditSensorSuccess);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('sensors') });
        onSuccess?.();
      },
      onError: () => {
        toast.error(componentText.toastEditSensorError);
      },
    },
  });
};
