import { useAuthMutation } from 'api/queries';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

type Body = {
  gatewayOfflineNotifications: boolean;
  automaticallySensorsNotifications: boolean;
  sensorsNoRecentDataNotifications: boolean;
};

export const usePUTNotificationsSettings = () => {
  const plantId = useSelector(selectPlantId);

  return useAuthMutation<{}, Body>({
    url: `/users/plant/${plantId}/notifications/settings`,
    method: 'PUT',
  });
};
