import { useTranslation } from "languages";
import moment from "moment";

export const formatDate = (lastMeasurement: string) => {
  return moment(lastMeasurement).utc().format("M/D/YYYY, HH:mm a");
};

export const useTimeConverter = () => {
    const { translate, GLOBAL_LANGUAGE: userLanguage } = useTranslation();

  const calculateTime = (lastMeasurement: string, short = false, suffix = 'old') => {
    const currentTime = moment();
    const measurementTime = moment.utc(lastMeasurement);

    if (measurementTime.isAfter(currentTime)) {
      return "0 second(s) old";
    }

    const duration = moment.duration(currentTime.diff(measurementTime));

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const translations = {
        years: translate('time_years_label'),
        yearsShort: translate('time_years_short_label'),
        months: translate('time_months_label'),
        monthsShort: translate('time_months_short_label'),
        days: translate('time_days_label'),
        daysShort: translate('time_days_short_label'),
        hours: translate('time_hours_label'),
        hoursShort: translate('time_hours_short_label'),
        minutes: translate('time_minutes_label'),
        minutesShort: translate('time_minutes_short_label'),
        seconds: translate('time_seconds_label'),
        secondsShort: translate('time_seconds_short_label'),
        oldSuffix: translate('time_old_suffix'),
        agoSuffix: translate('time_ago_suffix'),
    }

    const timePass = {
      years: years ? years + (short ? translations.yearsShort : translations.years) : "",
      months: months ? months + (short ? translations.monthsShort : translations.months) : "",
      days: days + (short ? translations.daysShort: translations.days),
      hours: hours ? hours + (short ? translations.hoursShort : translations.hours) : "",
      minutes: minutes ? minutes + (short ? translations.minutesShort : translations.minutes) : "",
      seconds: seconds + (short ? translations.secondsShort : translations.seconds),
    };

    const getTimeString = () => {
      if (years) return timePass.years;
      else if (months) return timePass.months;
      else if (days) return timePass.days;
      else if (hours) return timePass.hours;
      else if (minutes) return timePass.minutes;
      else return timePass.seconds;
    };

    const stringSuffixx = suffix === 'old' ? translations.oldSuffix : translations.agoSuffix;
    const timeString = getTimeString();
    if (userLanguage === 'es' && suffix === "ago") {
      return `${stringSuffixx} ${timeString}`;
    } else {
      return `${timeString} ${stringSuffixx}`;
    }
  };

  return { calculateTime };
};
