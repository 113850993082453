import {Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import TileDetails from "./TileDetails";
import AssetTile from "./AssetTile";
import GroupTile from "./GroupTile";
import { useDispatch, useSelector } from "react-redux";
import { changeOpenRowDetails, changeOpenRowId, selectOpenRowId, selectViewType } from "features/dashboard/store/dashboardSlice";
import { AssetStatusGroup } from "features/dashboard/models/assetStatusGroup.model";
import { AssetStatus } from "features/dashboard/models/assetStatus.model";
import React from "react";

interface TileProps {
 row: AssetStatusGroup | AssetStatus | unknown,
 index: number,
 length: number,
 page: number,
}

const Tile: FC<TileProps> = ({index, row, length, page}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(`(max-width:${theme.breakpoints.values.xs}px)`); 
  const isSm = useMediaQuery(`(min-width:${theme.breakpoints.values.xs + 1}px) and (max-width:${theme.breakpoints.values.sm}px)`); 
  const is1024 = useMediaQuery(`(min-width:${theme.breakpoints.values.sm}px) and (max-width: 1024px)`)
  const isMd = useMediaQuery(`(min-width:1025px) and (max-width:${theme.breakpoints.values.md}px)`); 
  const isLg = useMediaQuery(`(min-width:${theme.breakpoints.values.md + 1}px) and (max-width: 2000px)`); 
  const {column,x} = useMemo(() =>{
    if( isXs || isSm) return({column: 12, x:1});
    else if (is1024) return({column: 6, x:2})
    else if (isMd || isLg) return({column: 4, x:3});
    else return({column: 3, x:4})
  }, [isXs, isSm, isMd, isLg, is1024]);

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
 
  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  
  const viewType = useSelector(selectViewType);
  
  const dispatch = useDispatch();
  const expendedRow = useSelector(selectOpenRowId);

   const handleClearExpand = () =>{
    dispatch(changeOpenRowId(-100));
      dispatch(changeOpenRowDetails(null));
   }

  const handleChangeExpand = (id: number) =>{
    if(id!==expendedRow) {
      dispatch(changeOpenRowId(id));
      dispatch(changeOpenRowDetails(row));
    }
    else {
      handleClearExpand();
    }
  }
 React.useEffect( () => {handleClearExpand()}, [page]);
 
  const containerStyle ={
    padding: 1,
    paddingBottom: index === expendedRow ? 'calc(1.375rem + 8px)': 0,
    borderRadius: "0.75rem",
    border:"1px solid #E0E0E0",
    borderBottomWidth: index === expendedRow ? "calc(0.1875rem - 8px)":"0.0625rem", 
    borderBottomRightRadius: index === expendedRow ? 0:"1rem",
    borderBottomLeftRadius: index === expendedRow ? 0:"1rem",
    position:"relative",
  }

  return (
  <>
    <Grid item onClick={() => handleChangeExpand(index)} sx={{ cursor: 'pointer' }} xs={column} data-testid="data_table_tile_item" width={column === 12 ? "100%" : undefined}>
      {viewType === "assets" ? (
      <Grid container sx={{...containerStyle}} ref={ref} component="div">
        <AssetTile row={row as AssetStatus} index={index} handleChangeExpand={handleChangeExpand}  tileWidth = {width} isXs={isXs} column={column}/>
        { index === expendedRow && <Box width="100%" height="calc(0.5rem + 8px)" sx={{position:"absolute", background:"white", left:0, bottom:-3}}></Box>}
      </Grid>
      ) : (
        <Grid container sx={{...containerStyle}} ref={ref} component="div" minHeight="7.8rem">
          <GroupTile row={row as AssetStatusGroup} index={index} handleChangeExpand={handleChangeExpand} tileWidth = {width}/>
          { index === expendedRow && <Box width="100%" height="0.5rem" sx={{position:"absolute", background:"white", left:0, bottom:-3}}></Box>}
        </Grid>
      )}
    </Grid>
    
    {
    ((index+1) % x === 0 || index  === length  ) &&
      <Grid item xs={12} mt="calc(-1.075rem + 8px)" sx={{display: Math.floor(expendedRow/x) === Math.floor(index/x) ? "flex":"none" }} data-testid={`sensors-list-${Math.floor(expendedRow/x) === Math.floor(index/x) ? 'display':'hide'}`}>
        <TileDetails />
      </Grid>                          
   }
  </>
  );
};

export default memo(Tile);
