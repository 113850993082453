import AddIcon from '@mui/icons-material/Add';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { imageMimeTypes, useCreateAssetImage } from 'api/plants/useCreateAssetImage';
import { Loading } from 'components';
import { useTranslation } from 'languages';
import {
  ChangeEvent,
  FC,
  MutableRefObject,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { fontTitle } from './ImageList';

const sizeLimit = 5000000;

interface AddImageProps {
  type: string;
  selectedAsset: number;
}
const AddImage: FC<AddImageProps> = ({ type, selectedAsset }) => {
  const [selectedFile, setSelectedFile] = useState<File>();

  const fileInput = useRef<HTMLInputElement>();

  const clearFileInput = () => (fileInput.current!.value = '');

  const { mutate: uploadFileMutation, isPending } = useCreateAssetImage(
    selectedAsset,
    type,
    clearFileInput,
  );

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      fileSizeTooBigToast: translate('file_size_too_big_toast'),
      fileExtensionIncorrectToast: translate('file_extension_incorrect_toast'),
      addImageLabel: translate('add_image_label'),
    };
  }, []);

  useEffect(() => {
    if (fileInput.current) {
      const dataTransfer = new DataTransfer();
      if (selectedFile) dataTransfer.items.add(selectedFile);
      fileInput.current.files = dataTransfer.files;
    }
  }, [selectedFile]);

  const onFileSelectedHandler = (file: File) => {
    setSelectedFile(file);
    uploadFileMutation(file);
  };

  const onFileChange = (_: ChangeEvent<HTMLInputElement>) => {
    const file = fileInput.current?.files?.[0];
    if (!file) {
      return;
    }
    if (file.size > sizeLimit) {
      toast.error(componentText.fileSizeTooBigToast);
    } else if (
      !imageMimeTypes[file.name.toLowerCase().split('.').pop() as keyof typeof imageMimeTypes]
    ) {
      toast.error(componentText.fileExtensionIncorrectToast);
    } else {
      onFileSelectedHandler(file);
    }
  };
  return (
    <Grid item mr={1} pb={1.1}>
      <Card sx={{ borderRadius: '0.8rem', boxShadow: 'none' }}>
        <CardActionArea onClick={() => fileInput?.current?.click()} disabled={isPending}>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              minWidth: '116px',
              height: '90px',
              borderRadius: '0.8rem',
              background: 'rgba(51, 113, 121, 0.15)',
            }}
          >
            {isPending ? (
              <Loading size={70} height='90px' />
            ) : (
              <>
                <AddIcon color='primary' />
                <Typography color='primary' sx={{ ...fontTitle }}>
                  {componentText.addImageLabel}
                </Typography>
              </>
            )}
            <input
              ref={fileInput as MutableRefObject<HTMLInputElement>}
              type='file'
              accept='image/*'
              style={{ display: 'none' }}
              onChange={onFileChange}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default memo(AddImage);
