import { Box, Divider } from '@mui/material';
import { useGetFFTDetails } from 'api/assets/useGetFFTDetails';
import { useGetUnits } from 'api/users/useGetUnits';
import SideToolbox from 'components/SideToolbox/SideToolbox';
import { Asset, Sensor } from 'features/assets-management/models';
import {
  selectActiveAsset,
  selectActiveRMS,
  selectActiveSensor,
  selectActiveUnit,
  selectFFTSensor,
  selectSelectedAssets,
  selectSelectedSensors,
  selectShowChart,
  selectViewType,
  selectedMeasurements,
  setInitialmotorInfo,
  setRunningSpeedFFT,
} from 'features/assets-management/store/assetsManagementSlice';
import { useTranslation } from 'languages';
import { SensorType } from 'models/sensor/models';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeviceSize } from 'shared/responsive';
import { selectNavbarOpen } from 'store/accountSlice';
import SelectAssetsPrompt from '../../SelectDataPrompt/SelectAssetsPrompt';
import SelectDataPrompt from '../../SelectDataPrompt/SelectDataPrompt';
import FFTContent from './FFTContent';
import FFTHeader from './FFTHeader';
import TimeSeriesContent from './TimeSeriesContent';
import FFTMenu from './menu/FFTMenu';

// Here are array of Sensor Types that don't have FFT Data (They will be not visible in the sensor picker/select)
const sensorTypesWithoutFFT: SensorType[] = ['IntelliLube', 'BreatherSensor'];
export const fftBackgroundColor = '#EFF4F4';
export const menuWidth = '19.375rem';

type Props = {
  height: number;
  openTreeMenu: boolean;
};
const VibrationFFTPage: FC<Props> = ({ height, openTreeMenu }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [allSelectedAreExcluded, setAllSelectedAreExcluded] = useState<boolean>(false);
  const [disableDownload, setDisableDownload] = useState<boolean>(true);
  const [disableDownloadRawData, setDisableDownloadRawData] = useState<boolean>(true);
  const [url, setUrl] = useState<string>();
  const [urlRawData, setUrlRawData] = useState<string>();
  const [isUserClickDownload, setIsUserClickDownload] = useState(false);
  const [isUserClickDownloadRawData, setIsUserClickDownloadRawData] = useState(false);
  const view = useSelector(selectViewType);
  const checkedMeasurements = useSelector(selectedMeasurements);
  const { isPhone, isTablet } = useDeviceSize();

  useEffect(() => {
    const initialOpen = isPhone ? false : true;
    setOpenMenu(initialOpen);
  }, [isPhone, isTablet]);

  const fftSensor = useSelector(selectFFTSensor);

  const asset: string = useSelector(selectActiveAsset);
  const selectedAssetInfo: Asset | undefined = useSelector(selectSelectedAssets).find(
    (obj) => obj.name === asset,
  );
  const sensors: Sensor[] = useSelector(selectSelectedSensors);
  const location = useSelector(selectActiveSensor);
  const sensorInfo = sensors.find((selected) => selected.sensorId === location);
  const isMotor = useMemo(() => {
    return selectedAssetInfo && selectedAssetInfo.type === 'motor' ? true : false;
  }, [selectedAssetInfo]);

  const unit = useSelector(selectActiveUnit);
  const selectedFFTType: string = useSelector(selectActiveRMS);

  const { data: unitsData } = useGetUnits();
  const velocityUnit = unitsData?.velocityrms.isImperialOn ? 'imperial' : 'metric';

  const { data: fftDetails } = useGetFFTDetails(selectedAssetInfo?.assetId ?? 0);
  const showChart = useSelector(selectShowChart);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRunningSpeedFFT(null));
  }, []);

  useEffect(() => {
    if (fftDetails && selectedAssetInfo) {
      if (
        fftDetails.debNumber &&
        fftDetails.ndebNumber &&
        fftDetails.deReadyToAnalytics === 'ready' &&
        fftDetails.ndeReadyToAnalytics === 'ready' &&
        selectedAssetInfo.type === 'motor'
      )
        dispatch(setInitialmotorInfo('DE'));
      else if (fftDetails.debNumber && fftDetails.deReadyToAnalytics === 'ready')
        dispatch(setInitialmotorInfo('DE'));
      else if (fftDetails.ndebNumber && fftDetails.ndeReadyToAnalytics === 'ready')
        dispatch(setInitialmotorInfo('NDE'));
      else dispatch(setInitialmotorInfo(''));
    } else dispatch(setInitialmotorInfo(undefined));
  }, [fftDetails, selectedAssetInfo]);

  const faultDetection: boolean | string = useMemo(() => {
    if (selectedAssetInfo && fftDetails) {
      if (
        selectedAssetInfo.type === 'bearing' &&
        (fftDetails.bearingReadyToAnalytics === 'ready' ||
          fftDetails.bearingReadyToAnalytics === 'lowNominalSpeed')
      )
        return true;
      else if (selectedAssetInfo.type === 'motor') {
        if (fftSensor && fftSensor.motorInfo === 'DE' && fftDetails.deReadyToAnalytics === 'ready')
          return true;
        else if (
          fftSensor &&
          fftSensor.motorInfo === 'NDE' &&
          fftDetails.ndeReadyToAnalytics === 'ready'
        )
          return true;
        else return '';
      } else return '';
    } else {
      return '';
    }
  }, [fftDetails, selectedAssetInfo, fftSensor]);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      fftSelectedError: translate('asset_selected_fft_error'),
    };
  }, []);

  useEffect(() => {
    if (sensors.length > 0) {
      setSelected(true);

      // Check if all selected sensors are excluded
      if (sensors.every((sensor) => sensorTypesWithoutFFT.includes(sensor.type as SensorType))) {
        setAllSelectedAreExcluded(true);
      } else {
        setAllSelectedAreExcluded(false);
      }
    } else {
      setSelected(false);
    }
  }, [sensors]);

  useEffect(() => {
    if (checkedMeasurements.length > 5 || !checkedMeasurements.length) {
      setDisableDownload(true);
      setDisableDownloadRawData(true);
    } else {
      setDisableDownload(false);
      setDisableDownloadRawData(false);
    }
  }, [checkedMeasurements.length]);

  const handleChangeOpenMenu = () => {
    setOpenMenu((openMenu) => !openMenu);
  };

  const de = useMemo(() => {
    if (
      fftDetails &&
      fftSensor &&
      fftDetails.debNumber &&
      fftSensor.motorInfo === 'DE' &&
      fftDetails.deReadyToAnalytics === 'ready'
    )
      return fftDetails.debNumber;
    else return '';
  }, [fftDetails, fftSensor]);
  const nde = useMemo(() => {
    if (
      fftDetails &&
      fftSensor &&
      fftDetails.ndebNumber &&
      fftSensor.motorInfo === 'NDE' &&
      fftDetails.ndeReadyToAnalytics === 'ready'
    )
      return fftDetails.ndebNumber;
    else return '';
  }, [fftDetails, fftSensor]);

  const navbarOpened = useSelector(selectNavbarOpen);
  const paddingRight = useMemo(() => {
    if (!openMenu && !openTreeMenu && navbarOpened) return '3rem';
    return openMenu ? menuWidth : '0px';
  }, [openMenu, openTreeMenu, navbarOpened]);

  return (
    <Box
      sx={{
        mt: 2,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: `calc(100vh - 64px - 48px - 16px)`,
      }}
    >
      <Box
        sx={{
          gridColumn: 1,
          gridRow: 1,
          overflowY: 'scroll',
          '&::-webkit-scrollbar': { width: '5px', height: '5px' },
          '&::-webkit-scrollbar:horizontal': { width: '5px' },
          '&::-webkit-scrollbar-thumb': { background: '#337179' },
        }}
      >
        {!selected && <SelectAssetsPrompt />}
        {selected && !allSelectedAreExcluded ? (
          <>
            <FFTHeader
              selectedAssetName={asset}
              selectedAssetDetails={selectedAssetInfo}
              activeSensor={location}
              selectedSensor={sensorInfo}
              disableDownload={disableDownload}
              url={url}
              fftDetails={fftDetails}
              setIsUserClickDownload={setIsUserClickDownload}
              disableDownloadRawData={disableDownloadRawData}
              setIsUserClickDownloadRawData={setIsUserClickDownloadRawData}
              urlRawData={urlRawData}
            />
            <FFTContent
              activeAssetId={selectedAssetInfo?.assetId ?? 0}
              activeSensorId={location}
              activeUnit={unit === 'Hz' ? 'metric' : 'imperial'}
              selectedFFTType={selectedFFTType}
              unitsData={unitsData}
              velocityUnit={velocityUnit}
              pr={paddingRight}
              setUrl={setUrl}
              isUserClickDownload={isUserClickDownload}
              setIsUserClickDownload={setIsUserClickDownload}
              openTreeMenu={openTreeMenu}
              assetType={selectedAssetInfo?.type}
              dePN={de}
              ndePN={nde}
              faultDetection={faultDetection}
              isMotor={isMotor}
              sensorInfo={sensorInfo}
              freqUnit={unit}
            />
            {checkedMeasurements.length >= 1 && view !== 'Spectrogram' && showChart && (
              <>
                <Divider flexItem sx={{ width: '100%' }} />
                <TimeSeriesContent
                  activeAssetId={selectedAssetInfo?.assetId ?? 0}
                  activeSensorId={location}
                  activeUnit={unit === 'Hz' ? 'metric' : 'imperial'}
                  selectedFFTType={selectedFFTType}
                  velocityUnit={velocityUnit}
                  pr={paddingRight}
                  openTreeMenu={openTreeMenu}
                  isUserClickDownloadRawData={isUserClickDownloadRawData}
                  setIsUserClickDownloadRawData={setIsUserClickDownloadRawData}
                  setUrl={setUrlRawData}
                />
              </>
            )}
          </>
        ) : (
          <Box display='flex' justifyContent='center' alignItems='center' minHeight='80vh'>
            <SelectDataPrompt infoText={componentText.fftSelectedError} />
          </Box>
        )}
      </Box>
      {selected && !allSelectedAreExcluded && (
        <Box
          sx={{
            gridColumn: 2,
            gridRow: 1,
            overflowY: 'scroll',
            background: '#EFF4F4',
            borderLeft: '1px solid var(--Primary-Dark, #004851)',
            '&::-webkit-scrollbar': { width: '5px', height: '5px' },
            '&::-webkit-scrollbar:horizontal': { width: '5px' },
            '&::-webkit-scrollbar-thumb': { background: '#337179' },
          }}
        >
          <SideToolbox isOpen={openMenu} onChange={handleChangeOpenMenu} height={64}>
            <FFTMenu
              handleChangeOpenMenu={handleChangeOpenMenu}
              openMenu={openMenu}
              excludedSensorTypes={sensorTypesWithoutFFT}
              asset={asset}
              selectedAssetInfo={selectedAssetInfo}
              location={location}
              sensorInfo={sensorInfo}
              unit={unit}
              selectedFFTType={selectedFFTType}
              height={height}
              bearingReadyToAnalytics={fftDetails?.bearingReadyToAnalytics ?? null}
              deReadyToAnalytics={fftDetails?.deReadyToAnalytics ?? null}
              ndeReadyToAnalytics={fftDetails?.ndeReadyToAnalytics ?? null}
              dePartNumber={fftDetails?.debNumber}
              ndePartNumber={fftDetails?.ndebNumber}
            />
          </SideToolbox>
        </Box>
      )}
    </Box>
  );
};

export default VibrationFFTPage;
