import React, { useMemo, useState } from 'react';
import { Box, Divider, TextField, Typography } from '@mui/material';
import { useCreateAssetGroup } from 'api/asset-groups';
import { LoadingButton } from '@mui/lab';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import { useTranslation } from 'languages';

export const errors = new Map([
  [400, 'Provided asset group name is already in use.'],
  [409, 'The asset group name cannot be empty.'],
]);

type AddAssetGroupsProps = {
  open: boolean;
  onClose?: () => void;
  onCloseHandle?: (createdAssetGroupId: number, plantId: number, name: string) => void;
};

const AddAssetGroupModal = (props: AddAssetGroupsProps) => {
  const [assetGroupName, setAssetGroupName] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const plantId = useSelector(selectPlantId);
  const { mutate: createAssetGroupMutation, isPending } = useCreateAssetGroup(
    (createdAssetGroup) => {
      props.onCloseHandle?.(createdAssetGroup.id, plantId, assetGroupName);
      props.onClose?.();
    },
    (status) => {
      errors.has(Number(status))
        ? setError(errors.get(Number(status)))
        : setError('Unexpected error');
    },
  );

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      modalHeader: translate('plants_create_asset_group_modal_header'),
      modalContent: translate('plants_create_asset_group_modal_content'),
      modalHint: translate('plants_create_asset_group_modal_hint'),
      createButton: translate('create_button_label'),
      placeholder: translate('plants_create_asset_group_modal_placeholder'),
    };
  }, []);

  const assetGroupNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(undefined);
    setAssetGroupName(event.target.value);
  };

  const createAssetGroup = () => {
    createAssetGroupMutation({ body: { name: assetGroupName } });
  };

  return (
    <DodgeModal title={componentText.modalHeader} open={props.open} onClose={props.onClose}>
      <Box display='flex' flexDirection='column' gap='1rem' pt={2} pr={2}>
        <Typography id='modal-modal-description'>{componentText.modalContent}</Typography>
        <TextField
          error={error ? true : false}
          type='text'
          placeholder={componentText.placeholder}
          variant='outlined'
          label={componentText.placeholder}
          helperText={error ? error : componentText.modalHint}
          value={assetGroupName}
          onChange={assetGroupNameChangeHandler}
          data-testid='plants_management_asset_group_name_input'
        />
        <Divider sx={{ mt: 2 }} />
        <Box display='flex' justifyContent='flex-end'>
          <LoadingButton
            variant='contained'
            color='secondary'
            loading={isPending}
            disabled={!assetGroupName}
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            onClick={createAssetGroup}
            data-testid='plants_management_create_group_button'
          >
            {componentText.createButton}
          </LoadingButton>
        </Box>
      </Box>
    </DodgeModal>
  );
};

export default React.memo(AddAssetGroupModal);
