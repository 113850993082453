import { selectPlantId } from './../../store/accountSlice';
import { useSelector } from 'react-redux';
import { useAuthQuery } from 'api/queries';
import { VariableType } from 'models/sensor/models';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

type Properties = {
  [key: string]: {
    label: string;
    type: VariableType;
    value: any;
    unit: string;
  };
};
export interface GetSensorsDetailsByAssetIdResponse {
  id: number;
  serialNumber: string;
  location: string;
  macAddress: string;
  firmwareVersion: string;
  rssi: number | null;
  lastMeasurementDate: string | null;
  commissioningDate: string;
  manufacturingDate: string;
  properties: Properties;
  typeLabel: string;
  partNumber: string;
  pin?: string;
  additionalProperties: {
    [name: string]: {
      label: string;
      type: 'string' | 'dateString';
      value: string;
      unit: string;
    };
  };
  deviceFlag?: string;
  cartridgeSize?: string;
}

export const generateUrl = (assetId: number, plantId: number) =>
  `plants/${plantId}/assets/${assetId}/sensors?type=details`;

export const useGetSensorDetails = (assetId: number) => {
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_fetch_asset_details_toast'),
    };
  }, []);

  return useAuthQuery<GetSensorsDetailsByAssetIdResponse[]>({
    url: `plants/${plantId}/assets/${assetId}/sensors`,
    params: {
      type: 'details',
    },
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
