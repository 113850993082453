import { useAuthMutation } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { toast } from 'react-toastify';

export const usePostDecommissionSensor = () => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('sensor_successfully_decommissioned_toast'),
      errorMess: translate('sensor_decommission_failed_toast'),
    };
  }, []);

  return useAuthMutation<{ assetId: number; serialNumber: string }, undefined, undefined>({
    url: (params) => `assets/${params.assetId}/sensors/${params.serialNumber}/decommission`,
    method: 'POST',
    mutationOptions: {
      onError: () => {
        toast.error(componentText.errorMess);
      },
      onSuccess: () => {
        toast.success(componentText.successMess);
      },
    },
  });
};
