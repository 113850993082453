import { Check, KeyboardArrowDown } from "@mui/icons-material";
import { Box, Grid, ListItemIcon, MenuItem, Popover, Typography } from "@mui/material";
import React, { FC, memo, useRef, useState } from "react";
import { FilterOption } from "./models";

type Props = {
  filterName: string;
  filterOptions: FilterOption[];
  value?: string;
  onChange: (selectedFilterKey: string) => void;
};

const TableFilter: FC<Props> = ({ filterName, filterOptions, onChange, value }) => {
  const [selectedFilter, setSelectedFilter] = useState<string>(filterOptions[0].key);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);

  const boxRef = useRef<HTMLElement | undefined>();
  const open = !!anchorEl;

  const handleOpen = () => {
    setAnchorEl(boxRef.current);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleSelect = (filterKey: string) => {
    setSelectedFilter(filterKey);
    onChange(filterKey);
  };

  return (
    <>
      <Box display="flex" alignItems="center" ref={boxRef}>
        <Typography>
          {filterName}
          <b>{`: ${
            filterOptions.find((f) => f.key === (value ? value : selectedFilter))?.label
          }`}</b>
        </Typography>
        <KeyboardArrowDown sx={{ "&:hover": { cursor: "pointer" } }} onClick={handleOpen} />
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {filterOptions.map((option) => (
          <MenuItem key={option.key} onClick={() => handleSelect(option.key)}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
              <Grid item pr={3}>
                <Typography>{option.label}</Typography>
              </Grid>
              {(value ? value : selectedFilter) === option.key && (
                <Grid item>
                  <ListItemIcon>
                    <Check fontSize="small" />
                  </ListItemIcon>
                </Grid>
              )}
            </Grid>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default memo(TableFilter);
