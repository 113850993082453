import {
  Grid,
  FormControl,
  TextField,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { FC, useMemo } from "react";
import { IndustryCategory } from "./industries.model";
import { useTranslation } from "languages";
import { useGetTranslatedIndustryName } from "./useGetTranslatedIndustryName";

export const industries: {
  industryCategory: IndustryCategory;
  industry: string[];
}[] = [
  { industryCategory: "Mining, Aggregate, & Cement", industry: ["Aggregate", "Asphalt", "Cement", "Sand, Silica", "Iron, Gold, & Copper", "Coal", "Mining"] },
  {
    industryCategory: "Air Handling & Office",
    industry: ["Fans", "Filtration"],
  },
  {
    industryCategory: "Chemical & Oil",
    industry: ["Chemical", "Oil", "Gas", "Ethanol (Grain)"],
  },
  {
    industryCategory: "Food, Beverage, & Pharmaceuticals",
    industry: ["Food", "Pharmaceuticals", "Beverage", "Grain"],
  },
  {
    industryCategory: "Forest Products, Paper, & Agriculture",
    industry: ["MDF & OSB", "Lumber", "Consumer Paper", "Plywood", "Pulp" ,"Corrugated Paper"],
  },
  {
    industryCategory: "Material & Unit Handling",
    industry: ["Baggage", "Recycling", "Parcel", "Warehousing", "Automotive"],
  },
  {
    industryCategory: "Metals",
    industry: ["Metals Processing", "Steel", "Aluminum"],
  },
  { industryCategory: "Power Generation", industry: ["Biomass", "Electric"] },
  { industryCategory: "Training & Test", industry: ["Inactive", "Training", "Test"] },
  { industryCategory: "Water & Wastewater", industry: ["Water", "Wastewater"] },
];

type IndustryRowProps = Readonly<{
  industryCategory: string;
  onChangeIndustryCategory: (industryCategory: IndustryCategory) => void;
  industry: string;
  onChangeIndustry: (industry: string) => void;
}>;

export const IndustryRow: FC<IndustryRowProps> = ({
  industryCategory,
  onChangeIndustryCategory,
  industry,
  onChangeIndustry,
}) => {
  const foundIndustry = useMemo(() => {
    return industries.find(
      (item) => item.industryCategory === industryCategory
    );
  }, [industryCategory]);

  const getTranslatedIndustryName = useGetTranslatedIndustryName();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      industry: translate('menu_industry_label'),
      industryCategory: translate('menu_industry_category_label'),
      noMatch: translate("industry_no_match"),
      chooseBest: translate('industry_choose_best')
    };
  }, []);

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={6}>
        <FormControl fullWidth={true}>
          <TextField
            data-testid="manage_accounts_industry_category_field"
            id="industry-category"
            select
            required
            value={industryCategory}
            label={componentText.industryCategory}
            size="small"
            onChange={(e) => {
              onChangeIndustryCategory(e.target.value as IndustryCategory);
            }}
          >
            {industries
              .map((item) => {
                return item["industryCategory"];
              })
              .map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {getTranslatedIndustryName(item)}
                  </MenuItem>
                );
              })}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth={true}>
          <TextField
            data-testid="manage_accounts_industry_field"
            id="industry"
            required
            select
            value={industry}
            label={componentText.industry}
            disabled={!industryCategory}
            size="small"
            onChange={(e) => {
              onChangeIndustry(e.target.value);
            }}
          >
            {foundIndustry ? (
              foundIndustry?.industry.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {getTranslatedIndustryName(item)}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value="">
                {componentText.noMatch}
              </MenuItem>
            )}
          </TextField>
          <FormHelperText>{componentText.chooseBest}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};
