import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, IconButton, TableCell, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Title } from '../../models/reportsModels';

type Props = {
  cell: Title;
  asc: boolean;
  setAsc: (asc: boolean) => void;
  sort: string;
  setSort: (sort: string) => void;
  index: number;
  cellCheckbox?: ReactNode;
};
const HeaderTableCell: FC<Props> = ({ cell, sort, asc, setAsc, setSort, index, cellCheckbox }) => {
  const isSortable = cell.sortable;
  const sortName = cell.sortKey ?? cell.name
  const handleClick = () => {
    
    if (isSortable) { console.log(sortName, sort)
      sortName === sort ? setAsc(!asc) : setSort(sortName);
    }
  };

  return (
    <TableCell width={cell.columnWidth} key={cell.name}>
      <Box display='flex' alignItems='center'>
        {cellCheckbox}
        {isSortable ? (
          <IconButton disableRipple sx={{ p: 0, color: 'inherit' }} onClick={handleClick}>
            <Typography
              fontWeight='600'
              fontSize='0.9rem'
              display='inline'
              ml={index == 0 ? 1 : undefined}
            >
              {cell.name}
            </Typography>
            {sort === sortName &&
              (asc ? (
                <ArrowUpwardIcon sx={{ fontSize: '.9rem', color: '#8f8b8b', ml: 1 }} />
              ) : (
                <ArrowDownwardIcon sx={{ fontSize: '.9rem', color: '#8f8b8b', ml: 1 }} />
              ))}
          </IconButton>
        ) : (
          <Typography
            fontWeight='600'
            fontSize='0.9rem'
            display='inline'
            ml={index === 0 ? 1 : undefined}
          >
            {cell.name}
          </Typography>
        )}
      </Box>
    </TableCell>
  );
};

export default HeaderTableCell;
