import { Grid, Switch, Typography } from '@mui/material';
import { useTranslation } from 'languages';
import { memo, useMemo } from 'react';

type Props = {
  enabled: boolean;
  onChange: () => void;
};

const OffsetSetting = ({ enabled, onChange }: Props) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      enableTemperatureOffset: translate("enable_temperature_offset_offset"),
      disabled: translate("disabled_offset"),
      enabled: translate("enabled_offset"),
      temperatureOffsetDescription: translate("temperature_offset_description_offset"),
    };
  }, []);
  return (
    <>
      <Grid container xs={12} spacing={3} mb='2.5%' alignItems={'center'}>
        <Grid item mt={1} xs={12} lg={4}>
          <Typography fontWeight='600'>{componentText.enableTemperatureOffset}</Typography>
        </Grid>
        <Grid item xs={8}>
          {componentText.disabled}
          <Switch checked={enabled} onChange={onChange} />
          {componentText.enabled}
        </Grid>
      </Grid>
      <Grid item mb='2.5%'>
        <Typography align='justify' fontSize={'0.9rem'} mt={2} color='gray'>
          {componentText.temperatureOffsetDescription}
        </Typography>
      </Grid>
    </>
  );
};

export default memo(OffsetSetting);
