import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useGetSensorDetails } from 'api/plants/useGetSensorsDetails';
import { setSelectedSensorTriAxisMode } from 'features/assets-management/store/assetsManagementSlice';
import { useTranslation } from 'languages';
import { SensorType } from 'models/sensor/models';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  rms: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  selectedAssetId?: number;
  selectedSensor: number;
  sensorType: SensorType;
  isAssetDodgeAndBearing: boolean;
  background?: string;
};

const SelectRMS = ({
  rms,
  onChange,
  selectedAssetId,
  selectedSensor,
  sensorType,
  isAssetDodgeAndBearing,
  background,
}: Props) => {
  const [triAxisEnabled, setTriAxisEnabled] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { data: details } = useGetSensorDetails(selectedAssetId ? selectedAssetId : 0);

  useEffect(() => {
    if (details) {
      const sensorDetails = details.find((s) => s.id === selectedSensor);
      if (sensorDetails && 'triAxisEnable' in sensorDetails.properties) {
        setTriAxisEnabled(sensorDetails.properties['triAxisEnable'].value);
      }
    }
  }, [details, selectedSensor]);

  useEffect(() => {
    if (selectedSensor) {
      dispatch(setSelectedSensorTriAxisMode(triAxisEnabled));
    } else {
      dispatch(setSelectedSensorTriAxisMode(null));
    }
  }, [triAxisEnabled, selectedSensor]);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      fftRadialVelocity: translate("fft_radial_velocity"),
    fftRadialAcceleration: translate("fft_radial_acceleration"),
    fftEnvelopeVelocity: translate("fft_envelope_velocity"),
    fftEnvelopeAcceleration: translate("fft_envelope_acceleration"),
    fftVelocity: translate("fft_velocity"),
    fftAcceleration: translate("fft_acceleration"),
    };
  }, []);

  return (
    <FormControl size='small' fullWidth>
      <InputLabel id='select-rms-label'>FFT</InputLabel>
      <Select
        data-testid='assets_management_vibration_fft_input'
        labelId='select-rms-label'
        id='select-rms'
        value={rms}
        onChange={onChange}
        label='FFT'
        sx={{ '&.MuiOutlinedInput-root': { background } }}
      >
        {triAxisEnabled || sensorType === 'PerformanceSensor'
          ? [
            <MenuItem value='Velocity-Z' key='velocityz' data-testid='assets_management_vibration_fft_velocity'>
              {componentText.fftVelocity}
            </MenuItem>,
            <MenuItem value='Acceleration-Z' key='accelerationz' data-testid='assets_management_vibration_fft_acceleration'>
               {componentText.fftAcceleration}
            </MenuItem>,
            // <MenuItem value="Velocity-Y" key="velocityy">
            //   Tangential Velocity
            // </MenuItem>,
            // <MenuItem value="Acceleration-Y" key="accelerationy">
            //   Tangential Acceleration
            // </MenuItem>,
            // <MenuItem value="Velocity-X" key="velocityx">
            //   Axial Velocity
            // </MenuItem>,
            // <MenuItem value="Acceleration-X" key="accelerationx">
            //   Axial Acceleration
            // </MenuItem>,
          ]
          : [
            <MenuItem
              value='Velocity-Z'
              key='velocityz'
              data-testid='assets_management_vibration_fft_radial_velocity'
            >
              {componentText.fftRadialVelocity}
            </MenuItem>,
            <MenuItem
              value='Acceleration-Z'
              key='accelerationz'
              data-testid='assets_management_vibration_fft_radial_acceleration'
            >
              {componentText.fftRadialAcceleration}
            </MenuItem>,
          ]}
        [
        <MenuItem
          value='envelopeVelocity'
          key='envelopevelocity'
          data-testid='assets_management_vibration_fft_envelope_velocity'
        >
          {componentText.fftEnvelopeVelocity}
        </MenuItem>
        ,
        <MenuItem
          value='envelopeAcceleration'
          key='envelopeacceleration'
          data-testid='assets_management_vibration_fft_envelope_acceleration'
        >
          {componentText.fftEnvelopeAcceleration}
        </MenuItem>
        , ]
      </Select>
    </FormControl>
  );
};

export default memo(SelectRMS);
