import { Box, Grid } from "@mui/material";
import SelectDataPrompt from "features/assets-management/components/SelectDataPrompt/SelectDataPrompt";
import { AssetStatus} from "features/dashboard/models/assetStatus.model";
import { FC, useMemo } from "react";
import AssetTile from "./AssetTile";
import {ReactComponent as Bearing} from "../../../icons/bearing.svg";
import {ReactComponent as Pump}from "../../../icons/pump.svg";
import {ReactComponent as Motor} from "../../../icons/motor.svg";
import {ReactComponent as GearReducer} from "../../../icons/gear-reducer.svg";
import {ReactComponent as Other} from "../../../icons/other.svg";
import { alarmColor, noDataColor, okColor, warningColor } from "features/dashboard/colors/colors";
import "../../../styles/styles.scss";

type Props ={
    data: AssetStatus[];
}

export const getAssetIcon = (type: string) => {
    switch (type) {
      case "bearing":
          return <Bearing />;
      case "pump":
        return <Pump />
      case "motor":
        return <Motor />
      case "gear-reducer":
        return <GearReducer />
      default:
        return <Other />;
  };
};

export const getAssetStatusColor = (alarms: string[], alerts: string[], lastMeasurement: string | null | undefined) =>{
   if(alarms.length) return alarmColor;
   else if(alerts.length) return warningColor;
   else if (!alarms.length && !alerts.length && lastMeasurement) return okColor;
   else return noDataColor;
}

const Assets:FC<Props> = ({data}) =>{
    return (
       <>
        {
          data.length ?
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {
              data.map( asset => 
                 <AssetTile 
                   key={asset.id}
                   id={asset.id} 
                   assetColor={getAssetStatusColor(asset.alarms, asset.warnings, asset.lastMeasurement)}
                   assetIcon={getAssetIcon(asset.type)}
                   assetName={asset.assetName}
                   assetGroupName={asset.assetGroupName}
                   thumbnailUrl={asset.thumbnailUrl}
                   lastMeasurement={asset.lastMeasurement}
                   alarm={asset.alarms.length > 0 ? asset.alarms[0] : null}
                   alert={asset.warnings.length > 0 ? asset.warnings[0] : null}
                   noData={!asset.lastMeasurement ? "No online data" : null}
                   sensors={asset.sensors}
                 />)
            }
          </Grid>
          : <SelectDataPrompt infoText="No data." />
        }
        </>
    );
}

export default Assets;