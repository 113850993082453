import { Grid, Link, Select, MenuItem } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { useMenuButtons } from '../menu-buttons';
import { fontMedium } from './styles/fontStyle';
import { RoleRequired } from 'components/RoleRequired';
import { useDeviceSize } from 'shared/responsive';
import { useTranslation } from 'languages';
interface ManageAccountMenuProps {
  activeLink: string;
  handleActiveLink: (e: React.MouseEvent<HTMLElement>) => void;
  logout: () => void;
}

const ManageAccountMenu: FC<ManageAccountMenuProps> = ({
  activeLink,
  handleActiveLink,
  logout,
}) => {
  const { isPhone, isTablet, isLandscape, isPortrait, isDesktop } = useDeviceSize();

  const menuButtons = useMenuButtons();

  const { translate } = useTranslation();

  
  const componentText = useMemo(() => {
    return {
      signOut: translate('menu_sign_out_text'),
    };
  }, [translate]);

  const SelectMenu = () => {
    return (
      <Grid container direction='row' justifyContent='space-between' height='100%'>
        <Grid xs={12} mt={2}>
          <Select
            fullWidth
            sx={{ ...fontMedium, color: 'black', width: '100% !important' }}
            value={activeLink}
            // @ts-expect-error gonna fix that soon
            onChange={handleActiveLink}
          >
            {menuButtons.map((link) => (
              <MenuItem key={link.id} value={link.id}>
                {link.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    );
  };

  if ((isLandscape || isPortrait) && (isPhone || isTablet) && !isDesktop) {
    return <SelectMenu />;
  }

  return (
    <Grid container direction='column' justifyContent='space-between' height='100%'>
      <Grid item container direction='column' alignItems='flex-start'>
        {menuButtons.map((link) => {
          const linkComp = (
            <Link
              key={link.id}
              id={link.id}
              component='button'
              sx={{
                textAlign: 'left',
                display: 'block',
                my: '0.8rem',
                textDecoration: 'none',
                ...fontMedium,
                color: activeLink === link.id ? 'primary' : 'black',
              }}
              onClick={handleActiveLink}
            >
              {link.label}
            </Link>
          );
          if (link.blacklist && link.blacklist.length !== 0) {
            return (
              <RoleRequired key={link.id} roles={link.blacklist} mode='blacklist'>
                {linkComp}
              </RoleRequired>
            );
          } else {
            return linkComp;
          }
        })}
      </Grid>
      <Grid item>
        <Link
          data-testid='manage_account_sign_out_button'
          id='sign-out'
          component='button'
          sx={{ display: 'block', textDecoration: 'none', ...fontMedium, color: 'black' }}
          onClick={logout}
        >
          {componentText.signOut}
        </Link>
      </Grid>
    </Grid>
  );
};

export default memo(ManageAccountMenu);
