import { useTranslation } from 'languages';

export const useGetTranslatedUserRoles = () => {
  const { translate } = useTranslation();
  const translationDictionary = {
    'plant administrator': translate('plant_administrator_user_role'),
    'super administrator': translate('super_administrator_user_role'),
    'general user': translate('general_user_user_role'),
    'plant viewer': translate('plant_viewer_user_role'),
    'channel user': translate('channel_user_user_role'),
    'channel administrator': translate('channel_administrator_user_role'),
  };

  const getTranslatedUserRoles = (roleString: string) => {
    const role = roleString ? roleString.toLowerCase() : '';
    if (role in translationDictionary) {
      return translationDictionary[role as keyof typeof translationDictionary];
    }
    return role;
  };

  return getTranslatedUserRoles;
};
