import { alarmColor, noDataColor, okColor, warningColor } from 'features/dashboard/colors/colors';
import { AssetStatus } from 'features/dashboard/models/assetStatus.model';
import { selectTypeOfAlert } from 'features/dashboard/store/dashboardSlice';
import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import AssetTileBody from './AssetTileBody';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Bearing from '../../../icons/bearing.svg';
import BearingWhite from '../../../icons/bearing_white.svg';
import Pump from '../../../icons/pump.svg';
import PumpWhite from '../../../icons/pump_white.svg';
import Motor from '../../../icons/motor.svg';
import MotorWhite from '../../../icons/motor_white.svg';
import GearReducer from '../../../icons/gear-reducer.svg';
import GearReducerWhite from '../../../icons/gear-reducer_white.svg';
import Other from '../../../icons/other.svg';
import OtherWhite from '../../../icons/other_white.svg';
import IntelliLubeKpi from 'features/dashboard/icons/KpiIcons/IntelliLubeKpi';
import SpeedIcon from '@mui/icons-material/Speed';
import { useTranslation } from 'languages';

export interface Icons {
  smallIcon: JSX.Element;
  bigIcon: JSX.Element;
}

export const kpiIconStyle = {
  fontSize: '1rem',
  color: 'white',
};

interface AssetTileProps {
  row: AssetStatus;
  index: number;
  handleChangeExpand: (id: number) => void;
  tileWidth: number;
  isXs: boolean;
  column: number;
}

export const getAssetIcons = (type: string, noStyle?: boolean) => {
  switch (type) {
    case 'bearing':
      return {
        smallIcon: <img src={Bearing} alt='icon' />,
        bigIcon: (
          <img
            src={BearingWhite}
            alt='icon'
            width='55%'
            height='55%'
            style={noStyle ? undefined : { position: 'absolute', top: '22%', right: '23%' }}
          />
        ),
      };
    case 'pump':
      return {
        smallIcon: <img src={Pump} alt='icon' />,
        bigIcon: (
          <img
            src={PumpWhite}
            alt='icon'
            width='50%'
            height='50%'
            style={noStyle ? undefined : { position: 'absolute', top: '22%', right: '26%' }}
          />
        ),
      };
    case 'motor':
      return {
        smallIcon: <img src={Motor} alt='icon' />,
        bigIcon: (
          <img
            src={MotorWhite}
            alt='icon'
            width='50%'
            height='50%'
            style={noStyle ? undefined : { position: 'absolute', top: '22%', right: '26%' }}
          />
        ),
      };
    case 'gear-reducer':
      return {
        smallIcon: <img src={GearReducer} alt='icon' />,
        bigIcon: (
          <img
            src={GearReducerWhite}
            alt='icon'
            width='50%'
            height='50%'
            style={noStyle ? undefined : { position: 'absolute', top: '22%', right: '26%' }}
          />
        ),
      };
    default:
      return {
        smallIcon: <img src={Other} alt='icon' />,
        bigIcon: (
          <img
            src={OtherWhite}
            alt='icon'
            width='50%'
            height='50%'
            style={noStyle ? undefined : { position: 'absolute', top: '22%', right: '26%' }}
          />
        ),
      };
  }
};

const AssetTile: FC<AssetTileProps> = ({
  row,
  index,
  handleChangeExpand,
  tileWidth,
  isXs,
  column,
}) => {
  const alarms = row.alarms;
  const warnings = row.warnings;

  const { translate } = useTranslation();

  const componentText = {
    alertTypeOkName: translate('asset_tile_alert_type_ok_name'),
    alertTypeNoDataName: translate('asset_tile_alert_type_no_data_name'),
    alertTypeUnknown: translate('alert_type_unknown'),
    oldestMeasurementText: translate('oldest_measurement_text'),
    oldestMeasurementShortText: translate('oldest_measurement_short_text'),
  };

  const textDictionary = {
    'Asset Skin Temperature': translate('asset_tile_temperature_bottom'),
    'Acceleration RMS': translate('asset_tile_accelerationrms'),
    'Velocity RMS': translate('asset_tile_velocityrms'),
    'Fill level': translate('asset_tile_filllevel'),
    'Channel A: Remaining Thickness': translate('asset_tile_laggtha'),
    'Channel B: Remaining Thickness': translate('asset_tile_laggthb'),
    'Channel C: Remaining Thickness': translate('asset_tile_laggthc'),
    'Linear Belt Speed': translate('asset_tile_beltspeed'),
    'Uneven Wear': translate('asset_tile_unevenwear'),
    'Breather Life': translate('asset_tile_breatherlife'),
    'Battery Life': translate('asset_tile_batterylife'),
    'Headspace Temperature': translate('asset_tile_heads temperature'),
    'Improper Head Unit Install': translate('asset_tile_bottompcb'),
    'Channel Loss': translate('asset_tile_chdisc'),
    Overload: translate('asset_tile_overload'),
    Empty: translate('asset_tile_empty'),
    'Low Battery': translate('asset_tile_lowbattery'),
    'Low Temperature': translate('asset_tile_lowtemperature'),
    'Empty Projected': translate('asset_tile_emptyprojected'),
    Index: translate('asset_tile_index'),
    Kurtosis: translate('asset_tile_kurtosis'),
    Skewness: translate('asset_tile_skewness'),
    'Peak Acceleration': translate('asset_tile_peak_acceleration'),
    'Velocity Peak Amplitude': translate('asset_tile_peak_velocity'),
    'Velocity Peak Frequency': translate('asset_tile_peak_freq'),
    'Motor speed': translate('asset_tile_speed'),
    'Ambient Temperature': translate('asset_tile_temperature'),
    'Saturation Direction': translate('asset_tile_saturation_direction'),
    'Rate Of Saturation': translate('asset_tile_rate_of_saturation'),
    '% Of Worn': translate('asset_tile_perworn'),
    '% Of Worn Min': translate('asset_tile_perwornmin'),
    '% Of Worn Max': translate('asset_tile_perwornmax'),
    'Remaining Lagging Thickness': translate('asset_tile_remlaggthick'),
    'Remaining Lagging Thickness Min': translate('asset_tile_remlaggthickmin'),
    'Remaining Lagging Thickness Max': translate('asset_tile_remlaggthickmax'),
    'Rate of Wear': translate('asset_tile_rateofwear'),
    'Cumulative Run Time': translate('asset_tile_accumulatedruntime'),
    'Axial Acceleration RMS': translate('asset_tile_accrmsx'),
    'Tangential Acceleration RMS': translate('asset_tile_accrmsy'),
    'Radial Acceleration RMS': translate('asset_tile_accrmsz'),
    'Axial Velocity RMS': translate('asset_tile_velrmsx'),
    'Tangential Velocity RMS': translate('asset_tile_velrmsy'),
    'Radial Velocity RMS': translate('asset_tile_velrmsz'),
    'Fill level (Based on Projected Value)': translate('asset_tile_fill_level_projected'),
  };

  const translateByDictionary = (text: string) => {
    if (text in textDictionary) {
      return textDictionary[text as keyof typeof textDictionary];
    }
    return text;
  };

  let color = row.lastMeasurement ? (row.hasUnknownState ? noDataColor : okColor) : noDataColor;

  const status = useSelector(selectTypeOfAlert);

  const getAlertIcon = (kpi: string, alarm?: boolean) => {
    const kpiText = kpi.toUpperCase();
    if (kpiText.includes('ASSET SKIN TEMPERATURE') || kpiText.includes('HEADSPACE TEMPERATURE'))
      return <ThermostatIcon sx={{ ...kpiIconStyle }} />;
    else if (kpiText.includes('ACCELERATION') || kpiText.includes('VELOCITY'))
      return alarm ? (
        <TrendingUpIcon sx={{ ...kpiIconStyle }} />
      ) : (
        <ShowChartIcon sx={{ ...kpiIconStyle }} />
      );
    else if (
      kpiText.includes('BATTERY') ||
      kpiText.includes('OVERLOAD') ||
      kpiText.includes('EMPTY') ||
      kpiText.includes('LOW TEMPERATURE') ||
      kpiText.includes('FILL') ||
      kpiText.includes('BREATHER LIFE')
    )
      return <IntelliLubeKpi fill={color} name={kpiText} />;
    else if (kpiText.includes('SPEED')) return <SpeedIcon sx={{ ...kpiIconStyle }} />;
    else if (kpiText.includes('WEAR') || kpiText.includes('CHANNEL'))
      return <TrendingUpIcon sx={{ ...kpiIconStyle }} />;
  };
  const getAlertType = () => {
    if (alarms.length === 0 && warnings.length === 0) {
      return {
        type: 'ok',
        name: row.hasUnknownState ? componentText.alertTypeUnknown : componentText.alertTypeOkName,
      };
    } else if (alarms.length > 0 && warnings.length > 0 && status === 'alert') {
      color = warningColor;
      return {
        type: 'warning',
        name: translateByDictionary(warnings[0]),
        icon: getAlertIcon(warnings[0]),
      };
    } else if (alarms.length > 0) {
      color = alarmColor;
      return {
        type: 'alarm',
        name: translateByDictionary(alarms[0]),
        icon: getAlertIcon(alarms[0], true),
      };
    } else {
      color = warningColor;
      return {
        type: 'warning',
        name: translateByDictionary(warnings[0]),
        icon: getAlertIcon(warnings[0]),
      };
    }
  };

  const alerts = row.lastMeasurement
    ? getAlertType()
    : row.hasUnknownState
      ? { type: 'noData', name: componentText.alertTypeUnknown }
      : { type: 'noData', name: componentText.alertTypeNoDataName };
  const assetIcons = getAssetIcons(row.type);

  return (
    <AssetTileBody
      row={row}
      alerts={alerts}
      color={color}
      index={index}
      handleChangeExpand={handleChangeExpand}
      assetIcons={assetIcons}
      tileWidth={tileWidth}
      isXs={isXs}
      column={column}
    />
  );
};

export default memo(AssetTile);
