import { Search } from '@mui/icons-material';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Grid,
  InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import {
  NotifCategory,
  SortOrder,
  useGetManageRecurrEventsNotif,
} from 'api/users/useGetManageRecurrEventsNotif';
import { useGetNotification } from 'api/users/useGetNotifications';
import { usePutAllRecurrEventsNotif } from 'api/users/usePutAllRecurrEventsNotif';
import { NotificationWay, usePutRecurrEventsNotif } from 'api/users/usePutRecurrEventsNotif';
import { Loading } from 'components';
import { useDebounce } from 'helpers/useDebounce';
import { shortenName } from 'helpers/utils';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FilterOption } from './models';
import TableFilter from './TableFilter';
import { useTranslation } from 'languages';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

const PAGE_SIZE_OPTIONS = [5, 10, 15, 20] as const;
type PageSizeOptions = (typeof PAGE_SIZE_OPTIONS)[number];

const RecurrEventsNotificationsTable = () => {
  const { translate } = useTranslation();
  const [assetGroup, setAssetGroup] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [pageSize, setPageSize] = useState<PageSizeOptions>(10);
  const [page, setPage] = useState<number>(0);
  const [lockActions, setLockActions] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<SortOrder>('assetName');

  const debouncedSearch = useDebounce<string>(search, 500);

  const plantId = useSelector(selectPlantId);

  const componentText = {
    rowsPerPageLabel: translate('datatable_rows_per_page_label'),
    assetGroup: translate('asset_group_label'),
    sensorType: translate('table_sensor_type_label'),
    search: translate('search_placeholder'),
    assetName: translate('asset_name_label'),
    email: translate('menu_email_label'),
    push: translate('menu_notification_push_label'),
    all: translate('status_all'),
    of: translate('pagination_of_label'),
  };

  const {
    data: notifData,
    isLoading: isNotifDataLoading,
    isFetching: isDataFetching,
    refetch: refetchNotifData,
  } = useGetManageRecurrEventsNotif({
    pageNumber: page,
    pageSize: pageSize,
    searchString: debouncedSearch,
    assetGroup: assetGroup.toLowerCase() === 'all' ? undefined : assetGroup,
    sortOrder: `${orderBy}${order === 'asc' ? '' : '_desc'}`,
  });

  const {
    data: notifCounter,
    refetch: refetchNotifCounter,
    isFetching: isNotifCounterFetching,
  } = useGetNotification(plantId);

  const { mutateAsync: updateNotifMutation } = usePutRecurrEventsNotif();

  const { mutateAsync: selectAllMutation, isPending: isSelectAllLoading } =
    usePutAllRecurrEventsNotif();

  const assetGroupFilterOptions: FilterOption[] = useMemo(() => {
    const allOpt: FilterOption = { key: 'all', label: componentText.all };

    if (!notifData) return [allOpt];

    const arrOpt: FilterOption[] = notifData.assetGroups.map((g) => {
      return { key: g, label: g };
    });

    return [allOpt, ...arrOpt];
  }, [notifData]);

  const notifCategories = useMemo(() => {
    if (!notifData) return;

    return notifData.notificationCategories;
  }, [notifData]);

  const translationDictionary = {
    'parameter alarm': translate('parameter_alarm_notify_label'),
    'parameter alert': translate('parameter_alert_notify_label'),
    'device alarm': translate('device_alarm_notify_label'),
    'recurring events': translate('recurring_events_notify_label'),
  };
  const getTranslatedLabel = (labelString: string) => {
    const label = labelString ? labelString.toLowerCase() : '';
    if (label && label in translationDictionary) {
      return translationDictionary[label as keyof typeof translationDictionary];
    }
    return label;
  };

  const populateNotifCatColumns = useCallback(() => {
    if (!notifCategories) return;

    const columns = [];

    for (const [key, value] of Object.entries(notifCategories)) {
      columns.push(
        <TableCell key={key} sx={{ fontWeight: 'bold' }} colSpan={2} align='center'>
          {getTranslatedLabel(value)}
        </TableCell>,
      );
    }

    return columns;
  }, [notifCategories]);

  const handleNotifUpdate = async (
    categoryKey: string,
    assetId: number,
    method: NotificationWay,
    value: boolean,
  ) => {
    setLockActions(true);
    await updateNotifMutation(
      {
        params: {
          assetId: assetId,
        },
        body: {
          type: categoryKey,
          notificationWay: method,
          value: value,
        },
      },
      {
        onSettled(data, error, variables, context) {
          refetchNotifData();
          refetchNotifCounter();
        },
      },
    );
    setLockActions(false);
  };

  const populateNotifCells = useCallback(
    (categories: NotifCategory[], assetId: number) => {
      if (!notifCategories) return;

      const elArr = [];

      for (const [key] of Object.entries(notifCategories)) {
        const category = categories.find((c) => c.key === key);
        if (!category) break;

        elArr.push(
          <React.Fragment key={`assetId=${assetId}&notifKey=${key}`}>
            <TableCell align='left'>
              <Checkbox
                checked={category.email}
                onChange={(e) =>
                  handleNotifUpdate(category.key, assetId, 'email', e.target.checked)
                }
                disabled={lockActions}
              />
            </TableCell>
            <TableCell align='left'>
              <Checkbox
                checked={category.push}
                onChange={(e) => handleNotifUpdate(category.key, assetId, 'push', e.target.checked)}
                disabled={lockActions}
              />
            </TableCell>
          </React.Fragment>,
        );
      }
      return elArr;
    },
    [notifData, lockActions],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: SortOrder) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10) as PageSizeOptions);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const visibleRows = useMemo(() => {
    return notifData?.records;
  }, [notifData, page, pageSize]);

  const tablePlaceholder = useMemo(() => {
    const cellCount = notifCategories ? 2 + 2 * Object.entries(notifCategories).length : 4;

    const cellArr = [];
    const rowArr = [];

    for (let j = 0; j !== cellCount; j++) {
      cellArr.push(
        <TableCell>
          <Skeleton />
        </TableCell>,
      );
    }

    for (let i = 0; i !== pageSize; i++) {
      rowArr.push(<TableRow>{cellArr}</TableRow>);
    }

    return rowArr;
  }, []);

  // Check if all recurring notifications are enabled for specific type
  const isAllNotifEnabled = useCallback(
    (type: 'email' | 'push') => {
      if (!notifCounter) return false;

      const recurringEvents = notifCounter.notificationCategories.find(
        (c) => c.type === 'assetRecurring',
      );

      if (!recurringEvents) return false;

      switch (type) {
        case 'email':
          if (
            recurringEvents.emailCounter > 0 &&
            recurringEvents.emailCounter < recurringEvents.totalCounter
          )
            return undefined;
          return recurringEvents.emailCounter === recurringEvents.totalCounter;
        case 'push':
          if (
            recurringEvents.pushCounter > 0 &&
            recurringEvents.pushCounter < recurringEvents.totalCounter
          )
            return undefined;
          return recurringEvents.pushCounter === recurringEvents.totalCounter;
      }
    },
    [notifCounter],
  );

  // Handle click enable/disable all notifications
  const handleClickAll = useCallback(async (method: 'email' | 'push', value: boolean) => {
    setLockActions(true);

    await selectAllMutation(
      {
        params: {
          assetGroup: assetGroup === '' ? undefined : assetGroup,
        },
        body: {
          type: 'assetRecurring',
          notificationWay: method,
          value: value,
        },
      },
      {
        onSettled(data, error, variables, context) {
          refetchNotifData();
          refetchNotifCounter();
        },
      },
    );

    setLockActions(false);
  }, []);

  if (isNotifDataLoading) return <Loading />;

  return (
    <>
      <TableContainer sx={{ pt: 3 }}>
        <Table size='small'>
          <TableHead>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              <TableCell colSpan={10}>
                <Grid container spacing={3} p={1} alignItems={'center'}>
                  <Grid item xs={3}>
                    <TableFilter
                      filterName={componentText.assetGroup}
                      filterOptions={assetGroupFilterOptions}
                      onChange={(filter) => setAssetGroup(filter)}
                      value={assetGroup}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      value={search}
                      onChange={handleSearchChange}
                      size='small'
                      placeholder={componentText.search}
                      type='search'
                      variant='outlined'
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
              }}
            >
              <TableCell sx={{ fontWeight: 'bold' }} colSpan={2} />
              {populateNotifCatColumns()}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                sortDirection={orderBy === 'assetName' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'assetName'}
                  direction={orderBy === 'assetName' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'assetName')}
                >
                  {componentText.assetName}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                sortDirection={orderBy === 'assetGroupName' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'assetGroupName'}
                  direction={orderBy === 'assetGroupName' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'assetGroupName')}
                >
                  {componentText.assetGroup}
                </TableSortLabel>
              </TableCell>
              {populateNotifCatColumns()?.map((col) => (
                <>
                  <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                    <Checkbox
                      disabled={lockActions || isNotifCounterFetching}
                      checked={isAllNotifEnabled('email')}
                      indeterminate={isAllNotifEnabled('email') === undefined}
                      onChange={(e) => handleClickAll('email', e.target.checked)}
                    />
                    {componentText.email}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                    <Checkbox
                      disabled={lockActions || isNotifCounterFetching}
                      checked={isAllNotifEnabled('push')}
                      indeterminate={isAllNotifEnabled('push') === undefined}
                      onChange={(e) => handleClickAll('push', e.target.checked)}
                    />
                    {componentText.push}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isDataFetching && tablePlaceholder}
            {!isDataFetching &&
              visibleRows?.map((row) => (
                <React.Fragment key={row.assetId}>
                  <TableRow>
                    <TableCell>{shortenName(row.assetName, 12, 'top')}</TableCell>
                    <TableCell>{shortenName(row.assetGroupName, 12, 'top')}</TableCell>
                    {populateNotifCells(row.notificationCategories, row.assetId)}
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent={'flex-end'}>
        <Grid item>
          <TablePagination
            labelRowsPerPage={componentText.rowsPerPageLabel}
            component={'div'}
            rowsPerPageOptions={PAGE_SIZE_OPTIONS.slice()}
            rowsPerPage={pageSize}
            page={page}
            count={notifData ? notifData.totalRecords : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} ${componentText.of} ${count}`;
            }}
          />
        </Grid>
      </Grid>
      <Backdrop open={isDataFetching || isSelectAllLoading || lockActions} sx={{ color: '#fff' }}>
        <CircularProgress color='primary' />
      </Backdrop>
    </>
  );
};

export default memo(RecurrEventsNotificationsTable);
