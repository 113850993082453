import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { FC } from "react";

interface LoadingProps {
    height?: string;
    width?: string;
    marginTop?: string;
    circularProgress?: number;
    paddingRight?: string;
}

const Loader: FC<LoadingProps> = ({ height, width, marginTop, circularProgress, paddingRight }) => {
    return (
        <Box sx={{
            position: 'absolute',
            zIndex: '100',
            width: width ? width: '100%',
            height: height ? height : '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: marginTop || undefined,
            paddingRight: paddingRight || undefined,
        }}>
            <CircularProgress size={circularProgress ? circularProgress : 80} />
        </Box>
    );
}

export default Loader;