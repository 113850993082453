import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import NavigationIcon from '@mui/icons-material/Navigation';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import EsFlag from "./es.svg"
import DodgeChat from '../../images/Icons/dodge-chat.svg'
import { useTranslation } from 'languages';

 type Props = {
  open: boolean,
  handleClose: () => void,
 }
 const TOP = "3.1rem";
 const RIGHT = "-1.25rem"
 const arrow = <NavigationIcon sx={{color:"white", position: "fixed", top: `calc(${TOP} + 1rem)`, right: `calc(${RIGHT} + 4rem)`}} />;
 const dodgeChat = <Box position="fixed" sx={{top: `calc(${TOP} + 1.98rem)`, right: `calc(${RIGHT} + 24.5rem)`}}><img src={DodgeChat} /></Box>

const LanguagePopup:React.FC<Props> = ({open, handleClose}) => {
    const [checked, setChecked] = React.useState(false);

    const {setGlobalLanguage} = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      localStorage.setItem("hideLanguagePopup", JSON.stringify(event.target.checked));
    };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{top: TOP, right: RIGHT, bottom: "unset", left: "unset", "& .MuiPaper-root":{borderRadius: ".5rem"}}}
      > 
        {arrow}
        {dodgeChat}
        <DialogContent sx={{pb: 0}}>
          <DialogContentText id="alert-dialog-description" maxWidth="18rem">
          We’ve just noticed your Spanish region. Would you like to set your main language 
          to Spanish?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent:"flex-start", mx: "1rem"}}>        
            <FormControlLabel
                control={<Checkbox disableRipple checked={checked} onChange={handleChange} />}
                label="Don’t remind me"
                labelPlacement="end"
             />
            </DialogActions>
        <DialogActions sx={{justifyContent: "space-between", mx: "1rem", mb: 1}}>
          <Button variant="outlined" color="primary" size="small" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" size="small" onClick={() => setGlobalLanguage('es')} startIcon={<img src={EsFlag} alt='es'/>}>Seleccionar el Idioma (ES) Español</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default LanguagePopup;