import React, { useEffect, useState, memo, useMemo, useCallback } from 'react';
import {
  Grid,
  FormControl,
  TextField,
  Divider,
  Button,
  Modal,
  Box,
  IconButton,
  Typography,
  Autocomplete,
} from '@mui/material';
import { industries, IndustryRow } from '../CreatePlantFlow/CreatePlantForm/private/IndustryRow';
import { IndustryCategory } from '../CreatePlantFlow/CreatePlantForm/private/industries.model';
import EditIcon from '@mui/icons-material/Edit';
import { useGetPlantById } from 'api/plants/useGetPlantById';
import { useUpdatePlant } from 'api/plants/useUpdatePlant';
import { LoadingButton } from '@mui/lab';
import { alarmColor } from 'features/dashboard/colors/colors';
import { useRemovePlantById } from 'api/plants/useRemovePlantById';
import { CreatePlantStyle } from '../CreatePlantFlow/CreatePlantForm/CreatePlant';
import { allCountries } from 'country-region-data';
import style from './EditPlantStyles';
import GenUserAlarmSetting from './GenUserAlarmSetting';
import OffsetSetting from './OffsetSetting';
import AutomaticRawDataSetting from './AutomaticRawDataSetting';
import { useDeviceSize } from 'shared/responsive';
import { Loading } from 'components';
import { useTranslation } from 'languages';
import PlantViewerSettings from './PlantViewerSettings';

type EditPlantProps = {
  selectedPlant: number;
  role: string;
  email: string;
};

const EditPlant = ({ selectedPlant, role, email }: EditPlantProps) => {
  const [open, setOpen] = React.useState(false);
  const { data: plant, isLoading } = useGetPlantById(selectedPlant.toString());
  const offsetServedChecked = plant?.settings.enableOffset || false;
  const generalUserAlarmChecked = plant?.settings.enableAlertAndAlarmForGeneralUser || false;
  const rawDataCron = plant?.settings.rawDataCron || undefined;
  const isData = !isLoading && plant;
  const [industryCategory, setIndustryCategory] = useState<IndustryCategory | ''>(
    industries[0].industryCategory,
  );
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [industry, setIndustry] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [generalUserAlarm, setGeneralUserAlarm] = useState<boolean>(generalUserAlarmChecked);
  const [plantViewerCanLoadMeasurements, setPlantViewerCanLoadMeasurements] =
    useState<boolean>(false);

  //const [offsetChecked, setOffsetChecked] = useState<boolean>(false);
  // PI-005 fix later
  const [offsetChecked, setOffsetChecked] = useState<boolean>(offsetServedChecked);
  const [automaticRawDataChecked, setAutomaticRawDataChecked] = useState<boolean>(false);
  const [apiCronString, setApiCronString] = useState<string | undefined>(rawDataCron);
  const [calculatedCronString, setCalculatedCronString] = useState<string | undefined>(undefined);

  const { mutate: updatePlantMutation, isPending: isLoadingUpdate } = useUpdatePlant();

  const { mutate: removePlant, isPending: isLoadingRemove } = useRemovePlantById(() =>
    setOpen(false),
  );

  const isAdmin = useMemo(() => {
    return role === 'plant_administrator' || role === 'super_administrator';
  }, [role]);

  const countriesList = useMemo(
    () => [
      'United States',
      ...allCountries.map((country) => country[0]).filter((country) => country !== 'United States'),
    ],
    [],
  );
  const statesList = useMemo(
    () => allCountries.find((element) => element[0] === country)?.[2].map((region) => region[0]),
    [country],
  );

  useEffect(() => {
    setOffsetChecked(offsetServedChecked);
  }, [offsetServedChecked]);

  useEffect(() => {
    setGeneralUserAlarm(generalUserAlarmChecked);
  }, [generalUserAlarmChecked]);

  useEffect(() => {
    setApiCronString(rawDataCron);
    setAutomaticRawDataChecked(rawDataCron ? true : false);
  }, [rawDataCron]);

  const handleOpen = useCallback(() => {
    setNameError(false);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOffsetChange = React.useCallback(() => {
    setOffsetChecked(!offsetChecked);
  }, [offsetChecked]);

  const handleRawDataChange = React.useCallback(() => {
    setAutomaticRawDataChecked(!automaticRawDataChecked);
  }, [automaticRawDataChecked]);

  const handleKeyboardEvent = useCallback((e: React.KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    if (!isData || !open) {
      return;
    }

    const splittedAddress: string[] = plant.address ? plant.address.split('\n') : [];

    setName(plant.name ?? '');
    setCountry(plant.country ?? '');
    setCompany(plant.company ?? '');
    setCity(plant.city ?? '');
    setState(plant.state ?? '');
    setPostalCode(plant.postalCode ?? '');
    setAddress1(splittedAddress[0] ?? '');
    setAddress2(splittedAddress.length > 1 ? (splittedAddress[1] ?? '') : '');
    setIndustryCategory(plant.industryCategory as IndustryCategory);
    setIndustry(plant?.industry ?? '');
    setPlantViewerCanLoadMeasurements(
      plant.settings.enableLoadMeasurementsForPlantViewers ?? false,
    );
  }, [isData, open, plant]);

  const handleChangeGeneralUserAlarm = useCallback(() => {
    setGeneralUserAlarm(!generalUserAlarm);
  }, [generalUserAlarm]);

  const handleUpdatePlant = () => {
    console.log(plant);
    if (!plant) return;

    updatePlantMutation(
      {
        params: {
          plantId: selectedPlant,
        },
        body: {
          name,
          company,
          industryCategory: industryCategory,
          industry: industry,
          country,
          city,
          settings: {
            ...plant.settings,
            enableOffset: offsetChecked,
            enableAlertAndAlarmForGeneralUser: generalUserAlarm,
            rawDataCron: automaticRawDataChecked ? calculatedCronString : undefined,
            enableLoadMeasurementsForPlantViewers: plantViewerCanLoadMeasurements,
          },
          address: address1,
          state,
          postalCode,
        },
      },
      {
        onError(error) {
          if (error.message === 'UniqueKeyViolation') {
            // Plant name in use
            setNameError(true);
          }
        },
        onSuccess() {
          if (!nameError) {
            handleClose();
          }
        },
      },
    );
  };

  const rawDataCondition = useMemo(() => {
    return automaticRawDataChecked
      ? calculatedCronString
        ? calculatedCronString.slice(-1) === '0'
        : true
      : false;
  }, [calculatedCronString, automaticRawDataChecked]);

  const { isPhone, isTablet } = useDeviceSize();
  const responsiveProps = {
    maxHeight: isPhone ? '33rem' : isTablet ? '36rem' : '90%',
    modalWidth: isPhone ? '24rem' : isTablet ? '52rem' : '55rem',
    modalMarginBottom: isPhone || isTablet ? 2 : 0,
    customXS: isPhone ? 6 : isTablet ? 6 : 2,
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      plantName: translate('menu_plant_name_label'),
      company: translate('menu_company_label'),
      country: translate('menu_country_label'),
      state: translate('menu_state_label'),
      postalCode: translate('menu_postal_code_label'),
      address: translate('menu_address_label'),
      addressOptional: translate('menu_address_optional_label'),
      city: translate('menu_city_label'),
      industry: translate('menu_industry_label'),
      industryCategory: translate('menu_industry_category_label'),
      nameInUse: translate('name_in_use'),
      uniqueValue: translate('unique_value'),
      backButton: translate('menu_back_button'),
      createButton: translate('create_button_label'),
      editPlantTitle: translate('edit_plant_label'),
      cancelButton: translate('plants_cancel_button'),
      deletePlant: translate('delete_plant_label'),
      plantSettings: translate('plant_settings_label'),
      saveButton: translate('menu_save_button'),
    };
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ width: '2.5rem' }}>
        {isAdmin && (
          <IconButton onClick={handleOpen} sx={{ padding: '0.1rem', minWidth: 0 }}>
            <EditIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
          </IconButton>
        )}
      </Box>
      <Modal hideBackdrop open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: responsiveProps.modalWidth }}>
          <Typography fontSize='1.5rem' fontWeight='600' color='primary.dark' mb='3%'>
            {componentText.editPlantTitle}
          </Typography>
          <Divider style={{ width: '100%' }} sx={{ my: 2 }} />
          {isLoading ? (
            <Loading />
          ) : (
            <Grid
              container
              maxHeight={responsiveProps.maxHeight}
              sx={{
                '&::-webkit-scrollbar': { width: '5px', height: '5px' },
                '&::-webkit-scrollbar:horizontal': { width: '5px' },
                '&::-webkit-scrollbar-thumb': { background: '#337179' },
                '&::-webkit-scrollbar-track-piece:start': { background: 'transparent', mt: '1rem' },
              }}
            >
              <Grid item container xs={12} spacing={3} mb='1%'>
                <Grid item xs={6}>
                  <FormControl fullWidth={true}>
                    <TextField
                      data-testid='manage_accounts_plant_name_field'
                      required
                      id='outlined-required'
                      label={componentText.plantName}
                      placeholder={componentText.plantName}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameError(false);
                      }}
                      onKeyDown={handleKeyboardEvent}
                      sx={{ ...CreatePlantStyle.textField }}
                      size='small'
                      error={nameError}
                      helperText={nameError ? componentText.nameInUse : componentText.uniqueValue}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth={true}>
                    <TextField
                      data-testid='manage_accounts_company_field'
                      required
                      id='outlined-required'
                      label={componentText.company}
                      placeholder={componentText.company}
                      value={company}
                      onChange={(e) => {
                        setCompany(e.target.value);
                      }}
                      onKeyDown={handleKeyboardEvent}
                      sx={{ ...CreatePlantStyle.textField }}
                      size='small'
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container xs={12} my='1%'>
                <IndustryRow
                  industryCategory={industryCategory}
                  onChangeIndustryCategory={(industryCategory) => {
                    setIndustryCategory(industryCategory);
                  }}
                  industry={industry}
                  onChangeIndustry={(industry) => {
                    setIndustry(industry);
                  }}
                ></IndustryRow>
              </Grid>
              <Divider style={{ width: '100%' }} />
              <Grid item container xs={12} spacing={3} mt='0.5%' mb='2.5%'>
                <Grid item xs={6}>
                  <FormControl fullWidth={true}>
                    <Autocomplete
                      data-testid='manage_account_country_field'
                      freeSolo
                      forcePopupIcon
                      options={countriesList}
                      id='outlined-required'
                      inputValue={country}
                      onInputChange={(_, newValue) => {
                        setCountry(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={componentText.country}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          sx={{ ...CreatePlantStyle.textField }}
                          onKeyDown={handleKeyboardEvent}
                          size='small'
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4} xs={responsiveProps.customXS}>
                  <FormControl fullWidth={true}>
                    <Autocomplete
                      data-testid='manage_account_state_field'
                      freeSolo
                      forcePopupIcon
                      options={statesList ? statesList : []}
                      id='state'
                      inputValue={state}
                      onInputChange={(_, newValue) => setState(newValue)}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={componentText.state}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          sx={{ ...CreatePlantStyle.textField }}
                          onKeyDown={handleKeyboardEvent}
                          size='small'
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={responsiveProps.customXS} lg={2}>
                  <FormControl fullWidth={true}>
                    <TextField
                      required
                      id='postal_code'
                      label={componentText.postalCode}
                      placeholder={componentText.postalCode}
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                      }}
                      value={postalCode}
                      onKeyDown={handleKeyboardEvent}
                      data-testid='createPlant-form-postal'
                      sx={{ ...CreatePlantStyle.textField }}
                      size='small'
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={3} mb='2.5%'>
                <Grid item xs={6}>
                  <FormControl fullWidth={true}>
                    <TextField
                      data-testid='manage_account_addres_line_1_field'
                      required
                      id='outlined-required'
                      label={componentText.address}
                      placeholder={componentText.address}
                      value={address1}
                      onChange={(e) => {
                        setAddress1(e.target.value);
                      }}
                      onKeyDown={handleKeyboardEvent}
                      sx={{ ...CreatePlantStyle.textField }}
                      size='small'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth={true}>
                    <TextField
                      id='outlined-required'
                      label={componentText.addressOptional}
                      placeholder={componentText.addressOptional}
                      value={address2}
                      onChange={(e) => {
                        setAddress2(e.target.value);
                      }}
                      onKeyDown={handleKeyboardEvent}
                      sx={{ ...CreatePlantStyle.textField }}
                      size='small'
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={3} mb='2.5%'>
                <Grid item xs={6}>
                  <FormControl fullWidth={true}>
                    <TextField
                      data-testid='manage_account_city_field'
                      required
                      id='outlined-required'
                      label={componentText.city}
                      placeholder={componentText.city}
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      onKeyDown={handleKeyboardEvent}
                      sx={{ ...CreatePlantStyle.textField }}
                      size='small'
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mb='3.5%' mt='1%'>
                <Grid item xs={4}>
                  {plant && plant.allowToDelete && (
                    <LoadingButton
                      data-testid='delete_plant_button'
                      disabled={!isAdmin}
                      loading={isLoadingRemove}
                      fullWidth
                      variant='contained'
                      sx={{
                        background: alarmColor,
                        fontWeight: '600',
                        '&:hover': { background: '#ff3425' },
                        letterSpacing: '0.46px',
                      }}
                      onClick={() =>
                        removePlant({
                          params: {
                            plantId: selectedPlant,
                            email: email,
                          },
                        })
                      }
                    >
                      {componentText.deletePlant}
                    </LoadingButton>
                  )}
                </Grid>
              </Grid>
              <Divider style={{ width: '100%' }} />
              <Grid
                item
                container
                xs={12}
                justifyContent='space-between'
                mt='2.5%'
                mb='2rem'
                spacing={1}
              >
                <Grid item xs={12}>
                  <Typography fontSize='1.2rem' fontWeight='600' color='primary.dark'>
                    {componentText.plantSettings}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <OffsetSetting enabled={offsetChecked} onChange={handleOffsetChange} />
                </Grid>
                <Grid item xs={12}>
                  <GenUserAlarmSetting
                    enabled={generalUserAlarm}
                    onChange={handleChangeGeneralUserAlarm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PlantViewerSettings
                    value={plantViewerCanLoadMeasurements}
                    onChange={(v: boolean) => setPlantViewerCanLoadMeasurements(v)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutomaticRawDataSetting
                    enabled={automaticRawDataChecked}
                    onChange={handleRawDataChange}
                    cronString={apiCronString}
                    handleCronTemp={setCalculatedCronString}
                  />
                </Grid>
              </Grid>
              <Divider style={{ width: '100%' }} />
              <Grid item container xs={12} justifyContent='space-between' mt='2.5%'>
                <Grid item>
                  <Button
                    variant='outlined'
                    color='secondary'
                    sx={{ color: 'text.primary', fontWeight: 600 }}
                    onClick={handleClose}
                  >
                    {componentText.cancelButton}
                  </Button>
                </Grid>
                <Grid mb={responsiveProps.modalMarginBottom} item>
                  <LoadingButton
                    data-testid='plants_management_plant_settings_save_button'
                    variant='contained'
                    color='secondary'
                    loading={isLoadingUpdate}
                    disabled={
                      !name ||
                      !company ||
                      !country ||
                      !city ||
                      !address1 ||
                      !state ||
                      !postalCode ||
                      rawDataCondition
                    }
                    sx={{ fontWeight: 600 }}
                    onClick={handleUpdatePlant}
                  >
                    {componentText.saveButton}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default memo(EditPlant);
