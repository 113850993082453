import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { CatalogPartBody, useGetCatalogParts } from 'api/plants/useGetCatalogParts';
import { useGetManufacturers } from 'api/plants/useGetManufacturers';
import { DataTable } from 'components';
import DataTableSelectionFilterBar from 'components/DataTable/DataTableSelectionFilterBar/DataTableSelectionFilterBar';
import {
  ColumnRowTemplate,
  DataTableSelectedFilterOptions,
  Filter,
  FilterWithAlias,
} from 'components/DataTable/models';
import { useDebounce } from 'helpers/useDebounce';
import { shortenName } from 'helpers/utils';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { assetTypeMap } from './helpers';
import { useTranslation } from 'languages';
import { useGetTranslatedAssetLabel } from 'features/plants-management/utils/useGetTranslatedAssetLabel';

interface DodgePartNoSelectionProps {
  onClose: () => void;
  onPartSave: (part: CatalogPartBody) => void;
  mode?: 'pick';
  initFilter?: string | FilterWithAlias;
  disableSelect?: boolean;
  motorBearingManufacturer?: boolean;
}

const sortingDictionary = new Map([
  ['number', 'number'],
  ['description', 'description'],
  ['assetType', 'type'],
]);

const DodgePartNoSelection = ({
  onClose,
  onPartSave,
  mode,
  initFilter,
  disableSelect,
  motorBearingManufacturer,
}: DodgePartNoSelectionProps) => {
  const searchTypeOngingTimeout = useRef<NodeJS.Timeout | null>(null);
  const [search, setSearch] = useState('');
  const [filterOptions, setFilterOptions] = useState<
    DataTableSelectedFilterOptions<CatalogPartBody>[]
  >([]);
  const [sortValue, setSortValue] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const debouncedSearch = useDebounce<string>(search, 500);

  const {
    data: manufacturers,
    isFetching: isManuFetching,
    refetch: refetchManufacturers,
  } = useGetManufacturers(
    filterOptions
      .find((f) => f.key === 'assetType')
      ?.value.join()
      .toLowerCase()
      .replace(' ', '-'),
    motorBearingManufacturer,
  );

  const {
    data: response,
    isLoading,
    isFetching: isDataFetching,
    refetch: refetchCatalogParts,
  } = useGetCatalogParts(
    rowsPerPage,
    pageNumber + 1,
    debouncedSearch,
    filterOptions
      .find((f) => f.key === 'assetType')
      ?.value.join()
      .toLowerCase()
      .replace(' ', '-') ?? '',
    filterOptions.find((f) => f.key === 'manufacturer')?.value[0],
    sortValue,
    mode === 'pick',
  );

  const externalSortHandler = useCallback((sortBy: string, sortDesc: boolean) => {
    if (!sortingDictionary.has(sortBy)) {
      return;
    }
    setSortValue(`${sortingDictionary.get(sortBy)}${sortDesc ? '_desc' : ''}`);
    refetchManufacturers();
    refetchCatalogParts();
  }, []);

  const { translate } = useTranslation();
  const getTranslatedAssetLabel = useGetTranslatedAssetLabel();
  const componentText = useMemo(() => {
    return {
      requiredFieldsHeader: translate('plants_required_fields_header'),
      catalogPartsLabel: translate('plants_catalog_parts_label'),
      selfPartsLabel: translate('plants_self_parts_label'),
      searchPlaceholder: translate('plants_dodge_parts_search_label'),
      manufacturerLabel: translate('plants_manufacturer_label'),
      partNumberLabel: translate('plants_part_number_label'),
      descriptionLabel: translate('plants_description_label'),
      actionLabel: translate('plants_action_label'),
      assetTypeLabel: translate('plants_asset_type_label'),
      allLabel: translate('status_all'), // there is a problem with selection in other languages
      selectButton: translate('plants_parts_select_button'),
      cancelButton: translate('plants_cancel_button'),
      backButton: translate('plants_back_button'),
      search: translate('search_label'),
    };
  }, []);

  const data = response?.records ? response.records : [];

  const filters: Filter<CatalogPartBody>[] = useMemo(
    () => [
      {
        filterLabel: componentText.manufacturerLabel,
        filterKey: 'manufacturer',
        filterList: (() => {
          if (!manufacturers || manufacturers.length === 0) return [{value: 'All', alias: componentText.allLabel}];
          else
            return [
              {value: 'All', alias: componentText.allLabel},
              ...manufacturers.map((m) => ({ value: m.ids.toString(), alias: m.name })),
            ];
        })(),
      },
      {
        filterLabel: componentText.assetTypeLabel,
        filterKey: 'assetType',
        filterList: [{value: 'All', alias: componentText.allLabel}, ...Array.from(assetTypeMap.values()).map( a => ({value: a, alias: getTranslatedAssetLabel(a)}))],
        initValue: initFilter,
      },
    ],
    [initFilter, manufacturers],
  );

  const columnRowTemplates: ColumnRowTemplate<CatalogPartBody>[] = useMemo(
    () => [
      {
        columnName: componentText.manufacturerLabel,
        columnProperty: 'manufacturer',
        sortable: false,
        filterable: true,
        rowTemplateFn: (part: CatalogPartBody) => (
          <Typography ml={2.5}>{part.manufacturer ?? '-'}</Typography>
        ),
      },
      {
        columnName: componentText.partNumberLabel,
        columnProperty: 'number',
        sortable: false,
        filterable: true,
        rowTemplateFn: (part: CatalogPartBody) => <Typography>{part.number}</Typography>,
      },
      {
        columnName: componentText.descriptionLabel,
        columnProperty: 'description',
        sortable: false,
        rowTemplateFn: (part: CatalogPartBody) => (
          <Typography marginLeft={1}>
            {shortenName(part.description ? part.description : '-', 12)}
          </Typography>
        ),
      },
      {
        columnName: componentText.assetTypeLabel,
        columnProperty: 'assetType',
        sortable: false,
        rowTemplateFn: (part: CatalogPartBody) => (
          <Typography>
            {assetTypeMap.has(part.assetType)
              ? getTranslatedAssetLabel(assetTypeMap.get(part.assetType) ?? part.assetType)
              : getTranslatedAssetLabel(part.assetType)}
          </Typography>
        ),
      },
      {
        columnName: componentText.actionLabel,
        rowTemplateFn: (part: CatalogPartBody) => (
          <Box display={'flex'} alignItems='center' gap='0.5rem'>
            <Button
              data-testid='plants_management_part_select'
              variant='outlined'
              sx={{ pt: '0.2rem', pb: '0.2rem' }}
              onClick={() => {
                onPartSave(part);
                onClose();
              }}
              disabled={disableSelect}
            >
              {componentText.selectButton}
            </Button>
          </Box>
        ),
      },
    ],
    [],
  );

  const pageSize = useMemo(() => {
    return {
      rowsPageSize: rowsPerPage,
      rowsPerPage: [5, 10, 25, 50],
    };
  }, [rowsPerPage]);

  const setPage = useMemo(
    () => (pageNumber: number) => {
      setPageNumber(pageNumber);
      refetchManufacturers();
      refetchCatalogParts();
    },
    [setPageNumber],
  );

  const setPageSize = useMemo(
    () => (rowsPerPage: number) => {
      setRowsPerPage(rowsPerPage);
      refetchManufacturers();
      refetchCatalogParts();
    },
    [setRowsPerPage],
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageNumber(0);
    setSearch(e.target.value);

    if (searchTypeOngingTimeout.current) {
      clearTimeout(searchTypeOngingTimeout.current);
    }

    searchTypeOngingTimeout.current = setTimeout(() => {
      refetchManufacturers();
      refetchCatalogParts();
    }, 800);
  };

  const handleFilterChange = useCallback(
    (filters: DataTableSelectedFilterOptions<CatalogPartBody>[]) => {
      setFilterOptions(filters);
      setPage(0);
    },
    [],
  );

  return (
    <>
      <Grid container alignItems={'center'}>
        <Grid item xs={12}>
          <FormControl sx={{ margin: '0 1%', width: '98%' }}>
            <TextField
              label={componentText.search}
              InputLabelProps={{ shrink: true }}
              placeholder={componentText.searchPlaceholder}
              size='small'
              onChange={handleSearch}
              data-testid='plants_management_parts_search'
              disabled={isDataFetching || isManuFetching}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
          mt={2}
          mb={1}
          sx={{ 'div p.MuiTypography-root': { whiteSpace: 'nowrap' } }}
        >
          <DataTableSelectionFilterBar
            filters={filters}
            onDataTableSelectedFilter={handleFilterChange}
            blockedFilters={
              mode === 'pick'
                ? [{ filterKey: 'assetType' }]
                : isDataFetching || isManuFetching
                  ? [{ filterKey: 'assetType' }, { filterKey: 'manufacturer' }]
                  : undefined
            }
          />
        </Grid>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <DataTable
          header={''}
          checkboxes={'none'}
          // filters={filters}
          hideToolbar
          isLoading={isLoading || isDataFetching || isManuFetching}
          columnRowTemplates={columnRowTemplates}
          rows={data}
          page={pageNumber}
          minimised={true}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSizeControlled={rowsPerPage}
          pageSize={pageSize}
          totalCount={response?.totalRecords}
          externalSortHandler={externalSortHandler}
          scrollable
          scrollableHeight='40vh'
        />
      </Grid>
      <Grid container justifyContent='space-between' sx={{ width: '98%', margin: '0 1%' }}>
        <Button variant='outlined' onClick={onClose}>
          {mode === 'pick' ? componentText.backButton : componentText.cancelButton}
        </Button>
      </Grid>
    </>
  );
};

export default DodgePartNoSelection;
