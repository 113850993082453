import { PartBody } from "api/plants/useGetCustomParts";
import { CatalogPartBody } from "api/plants/useGetCatalogParts";
import { useTranslation } from "languages";

export const isCatalogPart = (part: PartBody | CatalogPartBody): part is CatalogPartBody => {
  if ((part as CatalogPartBody).manufacturer !== undefined) return true;
  else return false;
};

export const assetTypeMap = new Map([
  ["bearing", "Bearing"],
  ["gear-reducer", "Gear Reducer"],
  ["motor", "Motor"],
  ["pump", "Pump"],
  ["other", "Other"],
]);

// TODO: FIX Later - when i want to swap it, then request with values instead of keys is going to the API
const useGetAssetTypeName = () => {
  const { translate } = useTranslation();
  const keys = {
    bearing: translate("helper_bearing_label"),
    gearReducer: translate("helper_gear_reducer_label"),
    motor: translate("helper_motor_label"),
    pump: translate("helper_pump_label"),
    other: translate("helper_other_label")
  }
  const assetTypeMap = new Map([
    ["bearing", keys.bearing],
    ["gear-reducer", keys.gearReducer],
    ["motor", keys.motor],
    ["pump", keys.pump],
    ["other", keys.other],
  ]);
  return { assetTypeMap };
}