import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPlantId } from 'store/accountSlice';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface DeleteCustomPartParams {
  partId: number;
}

export const useDeleteCustomPart = (onSuccess?: () => void) => {
  const plantId = useSelector(selectPlantId);
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('custom_part_successfully_deleted_toast'),
      errorMess: translate('failed_to_delete_custom_part_toast'),
    };
  }, []);
  return useAuthMutation<DeleteCustomPartParams>({
    url: (params: DeleteCustomPartParams) => `plants/${plantId}/part/${params.partId}`,
    method: 'DELETE',
    mutationOptions: {
      onSuccess: (_, { params }) => {
        toast.success(componentText.successMess);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes(`parts`) });
        onSuccess?.();
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
