export const USER_PLANT_ROLES = ['General User', 'Plant Viewer', 'Plant Administrator'] as const;
export const USER_GLOBAL_ROLES = ['Super Viewer', 'Super Administrator'] as const;

export type UserPlantRole = (typeof USER_PLANT_ROLES)[number];
export type UserGlobalRole = (typeof USER_GLOBAL_ROLES)[number];

export type UserRole = UserPlantRole | UserGlobalRole;

export type UserInvitationStatus = 'pending' | 'invited' | 'accepted';

export interface UserAssetGroup {
  name: string;
}

export interface User {
  id: string;
  name: string;
  email: string;
  plantRole: UserRole;
  invitationStatus: UserInvitationStatus;
  assetGroupIds: number[];
}
