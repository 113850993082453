import { useMsal } from "@azure/msal-react";
import { Box, Button, Checkbox, Divider, FormControlLabel } from "@mui/material";
import { useAcceptEula } from "api/users/useAcceptEula";
import React, { useState } from "react";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { changeBlockUserPanel } from "../store/cookieSlice";

type AcceptArgeementProps = {

}
const AcceptArgeement:FC<AcceptArgeementProps> = ({}) =>{
    const dispatch = useDispatch();
    
    const onAcceptEula = () =>{
      dispatch(changeBlockUserPanel(false))
    }

    const { mutate: acceptEula } = useAcceptEula(onAcceptEula);

    const { instance } = useMsal();

    const logout = () => {
      localStorage.removeItem('redirectionUrl');
  
      const logoutRequest = {
        account: instance.getActiveAccount(), 
      }; 
      instance.logout(logoutRequest);
    };
    
    const [accept, setAccept] = useState(false);
    
    return (
     <Box mx={1.5} mb={1}>
        <Divider sx={{width: "100%", my: 3}} />
        <FormControlLabel
            control={<Checkbox 
            checked = {accept}
            onChange = { (e) => setAccept(e.target.checked)}
            />}
            label="I agree to the Dodge OPTIFY END USER LICENCE AGREEMENT"
            labelPlacement="end"
          />
        <Box mt={3} sx={{display:"flex", justifyContent:"space-between"}}>
          <Button variant="outlined" onClick={logout}>Decline and log out</Button>
          <Button variant="contained" disabled = {!accept} onClick={() => acceptEula({})}>Accept</Button>
        </Box>
    </Box>
    );
}

export default AcceptArgeement;