import { useAuthQuery } from 'api/queries';
import { Sensor } from 'features/plants-management/models/sensor.model';
import { useTranslation } from 'languages';
import { SensorType } from 'models/sensor/models';
import { useMemo } from 'react';

export type GatewaysScanREsponse = {
  serialNumber: string;
  macAddress: string;
  type: SensorType;
  rssi: number;
  ping: number;
  location: string;
};

export const useGetSensorsByGateway = (
  gatewayId: string | undefined,
  refetchOnWindowFocus?: boolean,
  refetchInterval: number = 0,
) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_sensors_toast'),
    };
  }, []);

  return useAuthQuery<Sensor[]>({
    url: `gateways/${gatewayId}/scan`,
    queryOptions: {
      refetchOnWindowFocus: refetchOnWindowFocus,
      refetchInterval: refetchInterval,
      enabled: !!gatewayId,
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
