import { useAuthQuery } from 'api/queries';
interface Props {
  sensorsSN: string[];
  gatewayId: string;
  interval: number;
  cacheTime?: number;
}

export type CommissioningStatus =
  | 'Pending'
  | 'Commissioning'
  | 'CommissioningFailed'
  | 'Commissioned'
  | 'FirmwareUpdating'
  | 'FirmwareUpdateFailed'
  | 'MissingSensorFirmware'
  | 'CannotConnect';

export const CommissioningStatusFinished = [
  'CommissioningFailed',
  'Commissioned',
  'FirmwareUpdateFailed',
  'MissingSensorFirmware',
  'CannotConnect',
];
export const CommissioningStatusErrors = [
  'CommissioningFailed',
  'FirmwareUpdateFailed',
  'MissingSensorFirmware',
  'CannotConnect',
];

export interface CommissionStatusResponse {
  serialNumber: string;
  updateStatus: CommissioningStatus;
}

export const useGetCommissionStatus = (props: Props) => {
  const serialNumbers: string = props.sensorsSN.join();

  return useAuthQuery<CommissionStatusResponse[]>({
    url: `/gateways/${props.gatewayId}/commission-sensors`,
    params: {
      serialNumbers,
    },
    queryOptions: {
      refetchInterval: props.interval,
      staleTime: props.interval,
      enabled: serialNumbers.length !== 0,
    },
  });
};
