import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPlantId } from 'store/accountSlice';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

type SerialNumber = string;
type SensorLocation = string;
export interface CommissionSensorsParams {
  gatewayId: string;
}

export interface CommissionSensorsBody {
  assetId: number;
  plantId: number;
  sensors: Record<SerialNumber, SensorLocation>;
}

export const useCommissionSensors = (
  onSuccess?: () => void,
  disableOnSuccessToast: boolean = false,
) => {
  const plantId = useSelector(selectPlantId);
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      infoMess: translate('commissioning_sensors_toast'),
      errorMess: translate('failed_to_commission_sensors_toast'),
    };
  }, []);
  return useAuthMutation<CommissionSensorsParams, CommissionSensorsBody>({
    url: (params: CommissionSensorsParams) => `gateways/${params.gatewayId}/commission-sensors`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        onSuccess?.();
        !disableOnSuccessToast && toast.info(componentText.infoMess);
        queryClient.invalidateQueries({ predicate: ({ queryKey }) => queryKey.includes('assets') });
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
