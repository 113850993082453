import { Box, Modal, SxProps, Theme, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const style: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25rem',
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: '16px',
  maxHeight: '90vh',
  overflowY: 'auto',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  '&::-webkit-scrollbar': { width: '5px' },
  '&::-webkit-scrollbar-thumb': { background: '#337179' },
  '&::-webkit-scrollbar-track-piece:start': {
    background: 'transparent',
    mt: '1rem',
    scrollPaddingRight: '1rem',
  },
  outline: 'none',
};

type DodgeModalProps = {
  title?: string | ReactNode;
  width?: number | string;
  height?: number | string;
  minHeight?: number | string;
  maxHeight ?: number | string;
  hideCloseIco?: boolean;
  open: boolean;
  onClose?: () => void;
  testId?: string;
  sx?: SxProps<Theme>;
};

const DodgeModal: FC<DodgeModalProps> = ({
  width = '25rem',
  height,
  minHeight,
  maxHeight,
  onClose,
  open,
  title,
  testId,
  hideCloseIco,
  children,
  sx,
}) => {
  const handleClose = () => {
    onClose?.();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      data-testid={testId}
    >
      <Box
        sx={{
          ...style,
          ...sx,
          height,
          minHeight,
          maxHeight,
          '@media (max-width: 750px)': {
            width: '24rem',
          },
          '@media (min-width: 751px)': {
            width: width,
          },
        }}
      >
        {title && (
          <Box display='flex' justifyContent='space-between' alignItems='center' sx={{marginBottom: '16px'}}>
            <Typography variant='h5' color='primary.dark' fontWeight='600'>
              {title}
            </Typography>
            {!hideCloseIco && (
              <CloseIcon
                data-testid='dodge_modal_close_button'
                onClick={handleClose}
                sx={{ '&:hover': { cursor: 'pointer' } }}
              />
            )}
          </Box>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default DodgeModal;
