import DodgeModal from "components/DodgeModal/DodgeModal";
import { FC, memo } from "react";
import Container from "./Container";

 type ImageViewerProps = {
    open: boolean;
    onClose: () => void;
    assetId: number,
    assetName: string,
 }

const ImageViewer:FC<ImageViewerProps> = ({open, onClose, assetId, assetName}) => {
    return (
      <DodgeModal
        title=" "
        open={open}
        onClose={onClose}
        width="45rem"
      >
       <Container id={assetId} name={assetName} />
     </DodgeModal>
    );
  };
  
export default memo(ImageViewer);