import { useAuthQuery } from 'api/queries';
import { AssetImage } from 'features/plants-management/models/asset.model';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
export interface ResponseModel {
  photos: AssetImage[] | [] | any[];
  nameplates: AssetImage[] | [] | any[];
}

export const useGetAssetImages = (assetId: number) => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_images_toast'),
    };
  }, []);

  return useAuthQuery<ResponseModel>({
    url: `assets/${assetId}/images`,
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
