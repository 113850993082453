import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { alarmColor } from 'features/dashboard/colors/colors';
import { Sensor } from 'features/dashboard/models/assetStatus.model';
import {
  selectOpenRowDetails,
  selectViewType,
  setTriggerRefetch,
} from 'features/dashboard/store/dashboardSlice';
import { useTimeConverter } from 'features/dashboard/utils/formatDate';
import EditAsset from 'features/plants-management/components/AssetsTable/EditAsset/EditAsset';
import MissingCartridge, {
  SensorType,
} from 'features/plants-management/components/MissingCartridge/MissingCartridge';
import { getSensorIco } from 'helpers/getSensorIco';
import { useTranslation } from 'languages';
import { memo, useCallback, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MeasurementIcon from '../../../icons/measurement_icon.svg';
import CartridgeInfoMissing from '../../AdditionalErrors/CartridgeInfoMissing';
import IncompatibleCartridgeError from '../../AdditionalErrors/IncompatibleCartridgeError';
import MissingCartridgeError from '../../AdditionalErrors/MissingCartridgeError';
import ProjectedUsed from '../../AdditionalErrors/ProjectedUsed';
import SyncError from '../../AdditionalErrors/SyncError';
import Arrow from './Arrow';
import { getAssetIcons } from './AssetTile';
import './styles/TileStyle.scss';
import { fontLastMeasurement } from './styles/fontTile';

const TileDetails = () => {
  const dispatch = useDispatch();
  const row = useSelector(selectOpenRowDetails);
  const [sensorSerialNumber, setSensorSerialNumber] = useState<string>();
  const [openEdit, setOpenEdit] = useState(false);
  const sensors: Sensor[] = row ? row.sensors : [];
  const viewType = useSelector(selectViewType);
  const { translate } = useTranslation();
  const componentText = {
    lastMeasurementText: translate('last_measurement_text'),
    syncError: translate('synchronization_error_text'),
    incompatibleCartridge: translate('asset_tile_body_incompatible_cartridge'),
    missingCartridge: translate('asset_tile_body_missing_cartridge'),
    cartridgeInfoMissing: translate('asset_tile_body_cartridge_info_missing'),
    projectedUsed: translate('tile_details_projected_used'),
    noOnlineData: translate("no_online_data")
  };
  const { calculateTime } = useTimeConverter();
  const [infoMissingModal, setInfoMissingModal] = useState(false);

  const clickedSensorId = useRef<number | null>(null);

  const queryClient = useQueryClient();

  const getSensor = useCallback(() => {
    const sensor = sensors.find((s) => s.sensorId === clickedSensorId.current);

    if (!sensor) return;

    return {
      ...sensor,
      type: sensor.type as SensorType,
    };
  }, [sensors, clickedSensorId]);

  return (
    <Grid
      container
      pt={1}
      pb={1}
      sx={{
        border: '1px solid #E0E0E0',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
      }}
    >
      {sensors.map((sensor) => (
        <Grid item lg={3} md={4} sm={6} p={2} key={sensor.sensorId}>
          <Box display='flex' justifyContent='space-between' alignItems='center' maxWidth='11.5rem'>
            <Box display='flex' alignItems='center'>
              {viewType === 'assets' ? (
                <Box className='SensorIcon'>{getSensorIco(sensor.type, true)}</Box>
              ) : (
                getAssetIcons(sensor.assetType).smallIcon
              )}
              <Typography
                ml='0.5rem'
                fontSize='14px'
                fontWeight='bold'
                component={Link}
                to={`/assets-management?assetId=${sensor.assetId}&sensorId=${sensor.sensorId}`}
                sx={{ textDecoration: 'none', color: 'black' }}
              >
                {viewType === 'assets'
                  ? sensor.location
                  : `${sensor.assetName} ${sensor.serialNumber}`}
              </Typography>
              <Arrow status={sensor.status} />
            </Box>
            {viewType === 'assets' && (
              <IconButton
                onClick={() => {
                  setSensorSerialNumber(sensor.serialNumber);
                  setOpenEdit(true);
                }}
              >
                <SettingsOutlinedIcon fontSize='small' />
              </IconButton>
            )}
          </Box>
          <Box
            display='flex'
            alignItems='center'
            data-testid='dashboard_current_asset_status_last_measurement'
          >
            <Box sx={{ mr: 1 }}>
              <img src={MeasurementIcon} alt='icon' />
            </Box>
            <Typography sx={{ ...fontLastMeasurement, mr: 1 }}>
              {componentText.lastMeasurementText}{' '}
            </Typography>
            <Typography sx={{ ...fontLastMeasurement }}>
              {sensor.lastMeasurement
                ? calculateTime(sensor.lastMeasurement, false)
                : componentText.noOnlineData}
            </Typography>
          </Box>
          {'deviceFlag' in sensor && sensor.deviceFlag === 'desynchronized' && (
            <SyncError>
              <Box display='flex' alignItems='center' width='fit-content'>
                <ReportProblemOutlinedIcon sx={{ color: alarmColor, fontSize: '1.1rem', mr: 1 }} />
                <Typography sx={{ ...fontLastMeasurement, mr: 1, color: alarmColor }}>
                  {componentText.syncError}{' '}
                  {viewType !== 'assets' ? `, ${sensor.location} (${sensor.serialNumber})` : ''}
                </Typography>
              </Box>
            </SyncError>
          )}
          {'deviceFlag' in sensor && sensor.deviceFlag === 'cartridgeIncompatible' && (
            <IncompatibleCartridgeError>
              <Box display='flex' alignItems='center' width='fit-content'>
                <ReportProblemOutlinedIcon sx={{ color: alarmColor, fontSize: '1.1rem', mr: 1 }} />
                <Typography sx={{ ...fontLastMeasurement, mr: 1, color: alarmColor }}>
                  {componentText.incompatibleCartridge}{' '}
                  {viewType !== 'assets' ? `, ${sensor.location} (${sensor.serialNumber})` : ''}
                </Typography>
              </Box>
            </IncompatibleCartridgeError>
          )}
          {'deviceFlag' in sensor && sensor.deviceFlag === 'cartridgeMissing' && (
            <MissingCartridgeError>
              <Box
                display='flex'
                alignItems='center'
                width='fit-content'
                sx={{ cursor: 'pointer' }}
              >
                <ReportProblemOutlinedIcon sx={{ color: alarmColor, fontSize: '1.1rem', mr: 1 }} />
                <Typography sx={{ ...fontLastMeasurement, mr: 1, color: alarmColor }}>
                  {componentText.missingCartridge}{' '}
                  {viewType !== 'assets' ? `, ${sensor.location} (${sensor.serialNumber})` : ''}
                </Typography>
              </Box>
            </MissingCartridgeError>
          )}
          {'deviceFlag' in sensor && sensor.deviceFlag === 'projected' && (
            <ProjectedUsed>
              <Box display='flex' alignItems='center' width='fit-content'>
                <ReportProblemOutlinedIcon sx={{ color: alarmColor, fontSize: '1.1rem', mr: 1 }} />
                <Typography sx={{ ...fontLastMeasurement, mr: 1, color: alarmColor }}>
                  {componentText.projectedUsed}
                </Typography>
              </Box>
            </ProjectedUsed>
          )}
          {'deviceFlag' in sensor && sensor.deviceFlag === 'noCartridgeInfo' && (
            <CartridgeInfoMissing>
              <Box
                display='flex'
                alignItems='center'
                width='fit-content'
                onClick={(e) => {
                  e.stopPropagation();
                  clickedSensorId.current = sensor.sensorId;
                  setInfoMissingModal(true);
                }}
                sx={{ cursor: 'pointer' }}
              >
                <ReportProblemOutlinedIcon sx={{ color: alarmColor, fontSize: '1.1rem', mr: 1 }} />
                <Typography sx={{ ...fontLastMeasurement, mr: 1, color: alarmColor }}>
                  {componentText.cartridgeInfoMissing}
                </Typography>
              </Box>
            </CartridgeInfoMissing>
          )}
        </Grid>
      ))}
      {infoMissingModal && (
        <MissingCartridge
          open={infoMissingModal}
          onClose={() => {
            queryClient.invalidateQueries({
              predicate: (query) => query.queryKey.includes(`assets-status`),
            });
            clickedSensorId.current = null;
            setInfoMissingModal(false);
          }}
          data={getSensor()}
        />
      )}
      {openEdit && row && (
        <EditAsset
          selectedAssetsIds={[Number(row.id)]}
          open={openEdit}
          onClose={() => {
            dispatch(setTriggerRefetch(true));
            setOpenEdit(false);
          }}
          sensorSerialNumber={sensorSerialNumber}
          tab={1}
        />
      )}
    </Grid>
  );
};

export default memo(TileDetails);
