import { useTranslation } from 'languages';

type Props = {
  totalEventsCount?: number;
  assetType?: string;
};

export const useGetLangText = ({ totalEventsCount = 0, assetType = '' }: Props) => {
  const { translate } = useTranslation();

  return {
    tableTitle: String(translate('events_table_title')),
    recurringTableTitle: String(translate('events_table_recurring_title')),
    recurringSearchPlaceholder: String(translate('events_table_search_recurring_placeholder')),
    eventsCounter: String(translate('events_table_counter', { totalCount: totalEventsCount })),
    searchPlaceholder: String(translate('events_table_search_placeholder')),
    createEventButton: String(translate('events_table_create_event_button')),
    editButton: String(translate('menu_edit_button')),
    noEventsForSelectedAssets: translate('no_events_for_selected_assets_events'),
    noEventsForSelectedPeriod: translate('no_events_for_selected_period_events'),
    okButton: translate('status_ok'),
    cancelButton: translate('cancel_button'),
    selectTimeText: translate('select_time_text'),
    buttons: {
      closeEvent: translate('events_table_close_event_button'),
      details: translate('events_table_details_button'),
    },
    eventsFilter: {
      label: String(translate('events_table_filter_label')),
      all: String(translate('events_table_filter_all')),
      systemGenerated: String(translate('events_table_filter_system_generated')),
      userGenerated: String(translate('events_table_filter_user_generated')),
    },
    eventTypeFilter: {
      label: String(translate('events_table_type_filter_label')),
      alarm: String(translate('events_table_type_filter_alarm')),
      alert: String(translate('events_table_type_filter_alert')),
      maintenance: String(translate('events_table_type_filter_maintenance')),
      sensorMaintenance: `${translate('sensor')} ${translate('status_maintenance')}`,
    },
    eventStatusFilter: {
      label: String(translate('events_table_status_filter_label')),
      open: String(translate('events_table_status_filter_open')),
      closed: String(translate('events_table_status_filter_closed')),
    },
    displayFilter: {
      label: String(translate('events_table_display_filter_label')),
      default: String(translate('events_table_display_filter_default')),
      grouped: String(translate('events_table_display_filter_grouped')),
    },
    eventDescFilter: {
      label: String(translate('events_table_desc_filter_label')),
      velocityRMS: String(translate('events_table_desc_filter_velocity_rms')),
      accelerationRMS: String(translate('events_table_desc_filter_acceleration_rms')),
      temperatureBottom: String(translate('events_table_desc_filter_temperature_bottom')),
      fillLevel: String(translate('events_table_desc_filter_fill_level')),
      overload: String(translate('events_table_desc_filter_overload')),
      empty: String(translate('events_table_desc_filter_empty')),
      emptyProjected: String(translate('events_table_desc_filter_empty_projected')),
      lowBattery: String(translate('events_table_desc_filter_low_battery')),
      lowTemperature: String(translate('events_table_desc_filter_low_temperature')),
      velRmsX: String(translate('events_table_desc_filter_velocity_rms_x')),
      velRmsY: String(translate('events_table_desc_filter_velocity_rms_y')),
      velRmsZ: String(translate('events_table_desc_filter_velocity_rms_z')),
      accRmsX: String(translate('events_table_desc_filter_acceleration_rms_x')),
      accRmsY: String(translate('events_table_desc_filter_acceleration_rms_y')),
      Lubrication: String(translate('events_table_desc_filter_lubrication')),
      Inspect: String(translate('events_table_desc_filter_inspect')),
      Other: String(translate('events_table_desc_filter_other')),
      accRmsZ: String(translate('events_table_desc_filter_acceleration_rms_z')),
      fillLevelProjected: String(translate('events_table_desc_filter_fill_level_projected')),
      noRecordedData: String(translate('events_table_desc_filter_no_recorded_data')),
      ReplaceBearing: String(translate('events_table_desc_filter_replace_bearing')),
      Repair: String(translate('events_table_desc_filter_repair')),
      oilChange: String(translate('events_table_desc_filter_oil_change')),
      replaceBearing: String(translate('events_table_desc_filter_replace_bearing')),
      replaceGearbox: String(translate('events_table_desc_filter_replace_gearbox')),
      replaceMotor: String(translate('events_table_desc_filter_replace_motor')),
      replace: String(translate('events_table_desc_filter_replace')),
      inspectPulley: String(translate('events_table_desc_filter_inspect_pulley')),
      clearDebris: String(translate('events_table_desc_filter_clear_debris')),
      adjustBelt: String(translate('events_table_desc_filter_adjust_belt')),
      sensorReplaced: String(translate('events_table_desc_filter_sensor_replaced')),
      airflowTemperature: String(translate('kpi_headspace_temperature')),
    },
    assetGroupFilter: {
      label: String(translate('events_table_group_filter_label')),
    },
    createEvent: {
      title: String(translate('events_table_create_event_title')),
      description: String(translate('events_table_create_event_description')),
      missingInfo: String(translate('events_table_create_event_missing_info')),
      createButton: String(translate('events_table_create_event_create_button')),
      selectedAssetsInputLabel: String(
        translate('events_table_create_event_selected_assets_input_label', {
          assetType,
        }),
      ),
      eventDescLabel: String(translate('events_table_create_event_event_desc_label')),
      eventCompletedLabel: String(translate('events_table_create_event_event_completed_label')),
      eventCompletedYes: String(translate('events_table_create_event_event_completed_yes')),
      eventCompletedNo: String(translate('events_table_create_event_event_completed_no')),
      dateInputLabel: String(translate('events_table_create_event_date_input_label')),
      noteInputLabel: String(translate('events_table_create_event_note_input_label')),
      timePickerLabel: String(translate('events_table_create_event_time_picker_label')),
    },
    columnTitles: {
      assetName: String(translate('events_table_column_asset_name')),
      assetGroup: String(translate('events_table_column_asset_group')),
      dateOccurred: String(translate('events_table_column_date_occurred')),
      dateCreation: String(translate('events_table_column_date_creation')),
      eventType: String(translate('events_table_column_event_type')),
      eventDescription: String(translate('events_table_column_event_description')),
      action: String(translate('events_table_column_action')),
      interval: String(translate('events_table_column_interval')),
      lastOccurred: String(translate('events_table_column_last_occured')),
    },
    assetType: {
      bearing: String(translate('asset_type_bearing')),
      gearReducer: String(translate('asset_type_gear_reducer')),
      motor: String(translate('asset_type_motor')),
      pump: String(translate('asset_type_pump')),
      pulley: String(translate('asset_type_pulley')),
      other: String(translate('asset_type_other')),
    },
    deleteDisabledPrompt: String(translate('events_table_delete_disabled_prompt')),
    toasts: {
      failedCreate: String(translate('events_table_toasts_failed_create')),
      successfullyCreated: String(translate('events_table_toasts_successfully_created')),
      creatingProgress: String(translate('events_table_toasts_creating_progress')),
    },
    assetGroup: String(translate('events_table_asset_group')),
  };
};
