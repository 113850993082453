// useMenuButtons.ts

import { UserRole } from 'features/plants-management/models/user.model';
import { useTranslation } from 'languages';

type MenuButton = {
  id: string;
  label: string;
  testid: string;
  blacklist?: UserRole[];
};

export const useMenuButtons = () => {
  const { translate } = useTranslation();

  const menuButtons: MenuButton[] = [
    { id: 'manage-plants', label: translate('user_panel_manage_plants'), testid: 'manage_plants' },
    { id: 'user-profile', label: translate('user_panel_user_profile'), testid: 'user_profile' },
    { id: 'notification', label: translate('user_panel_notifications'), testid: 'notifications' },
    {
      id: 'automatic-event-closure',
      label: translate('user_panel_automatic_event_closure'),
      testid: 'automatic-event-closure',
      blacklist: ['Plant Viewer', 'Super Viewer', 'General User'],
    },
    { id: 'unit-settings', label: translate('user_panel_unit_settings'), testid: 'unit_settings' },
    { id: 'api-key', label: translate('user_panel_API_information'), testid: 'api_key' },
    { id: 'release-notes', label: translate('user_panel_release_notes'), testid: 'release_notes' },
    { id: 'help', label: translate('user_panel_help_support'), testid: 'help_support' },
  ];

  return menuButtons;
};
