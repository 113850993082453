import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAuthMutation } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export interface PutUnitBody {
  name: string;
  isImperialOn: boolean;
}

export const usePutUnit = (onSucces?: () => void) => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_change_unit_setting_toast'),
    };
  }, []);
  return useAuthMutation<{}, PutUnitBody>({
    url: `users/units`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        // toast.success("Unit setting changed succesfully!")
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes(`units`) });
        onSucces?.();
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
