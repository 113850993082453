import { useAuthQuery } from 'api/queries';
import {
  PlantTemplateBody,
  StandardTemplateBody,
} from 'features/channel-partners/reports/models/reportsModels';
import { useSelector } from 'react-redux';
import { selectChannelDetails } from 'store/accountSlice';

type Props = {
  templateId: number;
  plantId: number;
};

export const useGetTemplateDetails = ({ plantId, templateId }: Props) => {
  const channelDetails = useSelector(selectChannelDetails);

  return useAuthQuery<
    StandardTemplateBody | PlantTemplateBody,
    StandardTemplateBody | PlantTemplateBody
  >({
    url: `channel-partners/${channelDetails?.channelId}/report-templates/${templateId}`,
    params: { plantId: plantId.toString() },
    queryOptions: {
      enabled: templateId > 0 && plantId !== -1,
      select: (response) => {
        if (response.type === 'Standard') return response;
        if (response.type === 'Plant')
          return {
            ...response,
            settings: {
              ...response.settings,
              imageURL: response.settings.imageURL
                ? response.settings.imageURL.split('/')[1]
                : response.settings.imageURL,
            },
          } satisfies PlantTemplateBody;
        else return response;
      },
    },
  });
};
