import { WarningAmberOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { useTranslation } from 'languages';
import { memo, useMemo } from 'react';

type Props = {
  sensorSN: string;
  isDecomissionLoading?: boolean;
  onClose: () => void;
  onAccept: () => void;
};

const DecommissionWarning = ({ sensorSN, isDecomissionLoading, onClose, onAccept }: Props) => {
  
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
  return {
    warningSensorDecommission: translate("warning_sensor_decommission"),
    historicalSensorDecommission: translate("historical_sensor_decommission"),
    replaceSensorDecommission: translate("replace_sensor_decommission"),
    featureSensorDecommission: translate("feature_sensor_decommission"),
    areYouSureDecommissionSensor: translate("are_you_sure_decommission_sensor_decommission"),
    cancelDecommissionSensor: translate("cancel_sensor_decommission"),
    yesDecommissionSensor: translate("yes_decommission_sensor_decommission"),
    removeDataDecommissionSensor : translate("remove_data_sensor_decommission")
  };
}, []);
  return (
    <>
      <DodgeModal open>
        <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
          <Stack
            direction={'row'}
            spacing={1}
            sx={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '34px',
                height: '34px',
                borderRadius: '50%',
                backgroundColor: '#FDF0E5',
              }}
            >
              <WarningAmberOutlined sx={{ color: '#C77700', fontSize: 24 }} />
            </Box>
            <Typography variant='h5' fontWeight={600}>
              {componentText.warningSensorDecommission}
            </Typography>
          </Stack>
          <Typography>{componentText.removeDataDecommissionSensor}</Typography>
          <Typography>
            {componentText.historicalSensorDecommission}{' '}
            <a
              href='https://iiot-dodgeindustrial.swipeguide.com/guide/optify-user-guide/mobile-app/replace-sensors-mobile-app-only-wip'
              target='_blank'
              rel='noreferrer noopener'
              style={{ textDecoration: 'none' }}
            >
              <Typography
                color={'primary'}
                fontWeight={600}
                sx={{ display: 'inline', cursor: 'pointer', textDecoration: 'underline' }}
              >
                {componentText.replaceSensorDecommission}
              </Typography>{' '}
            </a>
            {componentText.featureSensorDecommission}
          </Typography>
          <Typography>
            {componentText.areYouSureDecommissionSensor} (SN {sensorSN})?
          </Typography>
          <Divider flexItem />
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant='outlined'
              color='secondary'
              onClick={onClose}
              sx={{ color: 'text.primary', fontWeight: 600 }}
            >
              {componentText.cancelDecommissionSensor}
            </Button>
            <LoadingButton
              data-testid='plant_management_sensors_yes_decommission_button'
              variant='contained'
              color='secondary'
              onClick={onAccept}
              loading={isDecomissionLoading}
              sx={{ fontWeight: 600 }}
            >
              {componentText.yesDecommissionSensor}
            </LoadingButton>
          </Box>
        </Stack>
      </DodgeModal>
    </>
  );
};

export default memo(DecommissionWarning);
