import { Box, Button, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useGetAuthData } from "auth/hooks";
import { Skeleton } from "@mui/material";
import DodgeCard from "components/DodgeCard/DodgeCard";
import {ReactComponent as JoinIcon} from "./icons/join.svg"
import {ReactComponent as CreateIcon} from "./icons/create.svg"
import { useState } from "react";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

const WelcomeBox = () => {
  const { given_name, family_name } = useGetAuthData();
  const [skip, setSkip] = useState(false)

  return (
    <React.Fragment>
      {!skip ?
        (<DodgeCard width="27rem">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" color="primary" data-testid="welcome-box-title" fontWeight="600">
              {given_name && family_name ? (
                "Welcome, " + given_name + " " + family_name
              ) : (
                <Skeleton height={40} />
              )}
            </Typography>
            <CloseIcon sx={{color:"rgba(0, 0, 0, 0.54)", cursor: "pointer"}} onClick={() => setSkip(true)} />
          </Box>
          <Typography sx={{ my: 3 }} color="rgba(0, 0, 0, 0.87)">
          You can choose to create your own plant or join an existing plant.
          </Typography>
          <Box display="flex" gap={2}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={2} width="50%" height="12rem" sx={{background:"rgba(247, 248, 250, 1)", borderRadius: ".5rem"}}>
              <JoinIcon />
              <Button
                component={Link}
                data-testid="welcome-join-plant-btn"
                to="/onboarding/join-plant"
                variant="contained"
                color="primary"
                sx={{ fontWeight: 600, width: "9.5rem",mt: "1.5rem", color: "white !important"}}
              >
                Join Plant
              </Button>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={2} width="50%" height="12rem" sx={{background:"rgba(247, 248, 250, 1)", borderRadius: ".5rem"}}>
              <CreateIcon />
              <Button
                component={Link}
                data-testid="welcome-create-plant-btn"  
                to="/onboarding/create-plant"
                variant="contained"
                color="primary"
                sx={{ fontWeight: 600, width: "9.5rem", mt: "1.4rem", color: "white !important"}}
              >
                Create plant
              </Button>
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Button variant="outlined" color="secondary" sx={{color: "black", fontWeight: "600", px: "2rem"}} onClick={() => setSkip(true)}>Skip</Button>
        </DodgeCard>)
        : <></>
      }
    </React.Fragment>
  );
};

export default WelcomeBox;
