import { Edit } from '@mui/icons-material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { PartBody } from 'api/plants/useGetCustomParts';
import { useGetManufacturers } from 'api/plants/useGetManufacturers';
import { useUpdateCustomPart } from 'api/plants/useUpdateCustomPart';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { useEffect, useState } from 'react';
import SelectPartNoModal from './SelectPartNoModal';

interface CustomPartEditModalProps {
  open: boolean;
  onClose: () => void;
  onPartSave: (body: PartBody) => void;
  customPartsList: PartBody[];
  partToEdit: PartBody;
}

const assetTypesWithManufacturerField = ['bearing', 'motor'];

const CustomPartEditModal = ({
  open,
  onClose,
  onPartSave,
  customPartsList,
  partToEdit,
}: CustomPartEditModalProps) => {
  const [createPartNumber, setCreatePartNumber] = useState(partToEdit.number);
  const [selectedAssetType, setSelectedAssetType] = useState(partToEdit.assetType);
  const [partExistsError, setPartExistsError] = useState(false);
  const [description, setDescription] = useState(partToEdit.description);
  const [ndeBearingId, setNdeBearingId] = useState<number>();
  const [deBearingId, setDeBearingId] = useState<number>();
  const [isChoosingModalOpen, setIsChoosingModalOpen] = useState(false);
  const [currSelecting, setCurrSelecting] = useState<'nde' | 'de'>('nde');
  const [speedDrive, setSpeedDrive] = useState<boolean>();
  const [frameSize, setFrameSize] = useState<string>();
  const [nominalSpeed, setNominalSpeed] = useState<string>();
  const [horsePower, setHorsePower] = useState<string>();
  const [manufacturerId, setManufacturerId] = useState<number | undefined>(
    partToEdit.manufacturerId,
  );

  const {
    data: manufacturers,
    isLoading: isManuLoading,
    refetch: refetchManufacturers,
  } = useGetManufacturers(selectedAssetType);

  useEffect(() => {
    if (selectedAssetType && assetTypesWithManufacturerField.includes(selectedAssetType))
      refetchManufacturers();
  }, [selectedAssetType]);

  useEffect(() => {
    if (partToEdit.properties === undefined) return;

    if (partToEdit.properties.driveEndBearing)
      setDeBearingId(partToEdit.properties.driveEndBearing);
    if (partToEdit.properties.nonDriveEndBearing)
      setNdeBearingId(partToEdit.properties.nonDriveEndBearing);
    if (partToEdit.properties.variableSpeedDrive)
      setSpeedDrive(partToEdit.properties.variableSpeedDrive);
    if (partToEdit.properties.speed) setNominalSpeed(partToEdit.properties.speed);
    if (partToEdit.properties.power) setHorsePower(partToEdit.properties.power);
    if (partToEdit.properties.frameSize) setFrameSize(partToEdit.properties.frameSize);
  }, [partToEdit, customPartsList]);

  const { mutate: updateCustomPartMutation, isPending: isCreateLoading } = useUpdateCustomPart(
    partToEdit.id,
    () => {
      onPartSave({
        id: partToEdit.id,
        number: createPartNumber,
        description: description,
        assetType: selectedAssetType,
        numberOfAssetInstances: 0,
      });
      onClose();
    },
  );

  const sendUpdateCustomPartRequest = () => {
    updateCustomPartMutation({
      body: {
        number: createPartNumber,
        assetType: selectedAssetType,
        description: description ?? '',
        manufacturer: assetTypesWithManufacturerField.includes(selectedAssetType)
          ? manufacturerId
          : undefined,
        properties: {
          driveEndBearing: deBearingId ?? null,
          nonDriveEndBearing: ndeBearingId ?? null,
          frameSize: frameSize ?? null,
          speed: nominalSpeed ?? null,
          power: horsePower ?? null,
          variableSpeedDrive: speedDrive ?? null,
        },
      },
    });
  };

  const enableSave =
    !partExistsError &&
    createPartNumber !== '' &&
    description !== '' &&
    selectedAssetType !== '' &&
    (assetTypesWithManufacturerField.includes(selectedAssetType) ? manufacturerId : true);

  return (
    <DodgeModal
      open={open}
      onClose={onClose}
      width='700px'
      testId='plants_management_part_edition_modal'
      title={
        <Grid container alignItems='center'>
          <IconButton onClick={onClose} sx={{ ml: '-1rem' }}>
            <ChevronLeft />
          </IconButton>
          <Typography sx={{ color: 'black', fontSize: '1.2rem' }}>Edit</Typography>
        </Grid>
      }
      hideCloseIco
    >
      <FormControl fullWidth>
        <TextField
          required
          label='Your Parts Number'
          onChange={(e) => {
            setCreatePartNumber(e.target.value);
            setPartExistsError(
              customPartsList.some(
                (part) => part.number === e.target.value && part.number !== partToEdit.number,
              ),
            );
          }}
          value={createPartNumber}
          error={partExistsError}
          helperText={partExistsError ? 'Part with given part number already exists.' : ''}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: '1rem' }}>
        <TextField
          required
          label='Description'
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
      </FormControl>
      {partToEdit.numberOfAssetInstances !== 0 && (
        <Typography variant={'subtitle2'} color={'primary'} pt={1}>
          You cannot edit Asset Type when there are assets assigned to this part number.
        </Typography>
      )}
      <FormControl
        variant='standard'
        required
        fullWidth
        sx={{ mt: '0.5rem', ml: '1px' }}
        disabled={partToEdit.numberOfAssetInstances !== 0}
      >
        <InputLabel id='select-asset-type-label'>Asset type</InputLabel>
        <Select
          labelId='select-asset-type-label'
          value={selectedAssetType}
          label='Asset type'
          required
          onChange={(event) => {
            setSelectedAssetType(event.target.value);
            setManufacturerId(undefined);
          }}
        >
          <MenuItem value='bearing'>Bearing</MenuItem>
          <MenuItem value='gear-reducer'>Gear Reducer</MenuItem>
          <MenuItem value='motor'>Motor</MenuItem>
          <MenuItem value='pump'>Pump</MenuItem>
          <MenuItem value='other'>Other</MenuItem>
        </Select>
      </FormControl>
      {assetTypesWithManufacturerField.includes(selectedAssetType) && (
        <Grid container mt={2} spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                data-testid='plants_management_part_creation_manufacturer_input'
                value={(() => {
                  if (!manufacturers || !manufacturerId) return '';
                  return manufacturers.find((f) => f.ids.includes(manufacturerId))?.name ?? '';
                })()}
                onChange={(e) => {
                  setManufacturerId(manufacturers?.find((m) => m.name === e.target.value)?.ids[0]);
                }}
                label='Manufacturer'
                select
                required
                disabled={isManuLoading}
                SelectProps={{
                  MenuProps: {
                    MenuListProps: { sx: { maxHeight: '200px', overflow: 'auto' } },
                  },
                }}
              >
                {manufacturers
                  ?.filter((f) => !f.name.toLowerCase().includes('dodge'))
                  .map((m) => (
                    <MenuItem key={m.ids[0]} value={m.name}>
                      {m.name}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {selectedAssetType === 'motor' && (
        <>
          <Grid container mt={2} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  data-testid='plants_management_part_edition_modal_horse_power_input'
                  value={horsePower ?? ''}
                  onChange={(e) => setHorsePower(e.target.value)}
                  label={'Horse Power'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  data-testid='plants_management_part_edition_modal_nominal_speed_input'
                  value={nominalSpeed ?? ''}
                  onChange={(e) => setNominalSpeed(e.target.value)}
                  InputProps={{ endAdornment: 'RPM' }}
                  label={'Nameplate Speed'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  data-testid='plants_management_part_edition_modal_variable_speed_drive_input'
                  value={(() => {
                    if (speedDrive === undefined) return '';
                    else return speedDrive === true ? 'Yes' : 'No';
                  })()}
                  select
                  label={'Variable Speed Drive'}
                  onChange={(e) =>
                    e.target.value === 'Yes' ? setSpeedDrive(true) : setSpeedDrive(false)
                  }
                >
                  <MenuItem value={'Yes'}>Yes</MenuItem>
                  <MenuItem value={'No'}>No</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  data-testid='plants_management_part_edition_modal_motor_frame_size_input'
                  value={frameSize ?? ''}
                  onChange={(e) => setFrameSize(e.target.value)}
                  InputProps={{ endAdornment: 'mm' }}
                  label={'Motor Frame Size'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  data-testid='plants_management_part_edition_modal_nde_bearing_input'
                  label='NDE Bearing'
                  onClick={() => {
                    setCurrSelecting('nde');
                    setIsChoosingModalOpen(true);
                  }}
                  value={(() => {
                    if (!ndeBearingId) return '';
                    return String(ndeBearingId);
                  })()}
                  InputProps={{ endAdornment: <Edit color={'action'} /> }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  data-testid='plants_management_part_edition_modal_de_bearing_input'
                  label='DE Bearing'
                  onClick={() => {
                    setCurrSelecting('de');
                    setIsChoosingModalOpen(true);
                  }}
                  value={(() => {
                    if (!deBearingId) return '';
                    return deBearingId;
                  })()}
                  InputProps={{ endAdornment: <Edit color={'action'} /> }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <SelectPartNoModal
            isOpen={isChoosingModalOpen}
            onClose={() => setIsChoosingModalOpen(false)}
            onPartSave={(selectedPart) => {
              currSelecting === 'nde'
                ? setNdeBearingId(selectedPart.id)
                : setDeBearingId(selectedPart.id);
              setIsChoosingModalOpen(false);
            }}
            initFilter={'Bearing'}
            mode='pick'
          />
        </>
      )}
      <Grid container justifyContent='space-between' sx={{ width: '100%', mt: '1rem' }}>
        <Button
          variant='outlined'
          onClick={onClose}
          data-testid='plants_management_part_edition_modal_cancel_button'
        >
          CANCEL
        </Button>
        <LoadingButton
          loading={isCreateLoading}
          variant='contained'
          disabled={!enableSave}
          color='secondary'
          onClick={sendUpdateCustomPartRequest}
          data-testid='plants_management_part_edition_modal_save_button'
        >
          SAVE
        </LoadingButton>
      </Grid>
    </DodgeModal>
  );
};

export default CustomPartEditModal;
