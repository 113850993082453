import { useTranslation } from "languages";


export const useGetAssetApplicationName =() =>{
    const {translate}  = useTranslation();
    const translationDictionary = {
        "air handling": translate("air_handling"),
        "belt conveyor": translate("belt_conveyor"),
        "bucket elevator": translate("bucket_elevator"),
        "mixer": translate("mixer"),
        "palletizer": translate("palletizer"),
        "trunnion wheel": translate("trunnion_wheel"),
        "unit handling conveyor": translate("unit_handling_conveyor")
    }
    const getAssetApplicationName = (label : string | undefined) =>{
        const labelKey = label?.toLowerCase() ?? '';
        if (label && labelKey in translationDictionary) {
            return translationDictionary[labelKey as keyof typeof translationDictionary];
          }
          return label;
    }
    return getAssetApplicationName;
}