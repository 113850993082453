import { Sensor } from 'features/assets-management/models';
import { selectSelectedSensors } from 'features/assets-management/store/assetsManagementSlice';
import { SensorType } from 'models/sensor/models';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useAuthQuery } from './../queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

type Response = {
  date: string;
  downtime: boolean;
  nominalSpeed: number;
  nominalSpeedFrequency: number;
};

export const useGetFFTDates = (
  assetId: number,
  sensorId: number,
  from: string,
  to: string,
  axis: string,
  excludedSensorTypes: SensorType[],
) => {
  const selectedSensors: Sensor[] = useSelector(selectSelectedSensors).filter(
    (sensor) => sensor.sensorId !== 0 && !excludedSensorTypes.includes(sensor.type as SensorType),
  );

  const endDate = moment(new Date(to)).utc().set({ h: 23, m: 59, s: 59 }).toDate().toISOString();

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_fetch_fft_measurement_dates_toast'),
    };
  }, []);

  return useAuthQuery<Response[]>({
    url: `/assets/${assetId}/fft-dates`,
    params: {
      SensorId: sensorId.toString(),
      from: from,
      to: endDate,
      axis: axis,
    },
    queryOptions: {
      enabled: selectedSensors.length > 0 && sensorId !== 0,
      meta: {
        error: {
          message: componentText.errorMess,
        },
      },
    },
  });
};
