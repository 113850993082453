import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateCustomPart } from 'api/plants/useCreateCustomPart';
import { LoadingButton } from '@mui/lab';
import { PartBody } from 'api/plants/useGetCustomParts';
import { Edit } from '@mui/icons-material';
import { CatalogPartBody } from 'api/plants/useGetCatalogParts';
import SelectPartNoModal from './SelectPartNoModal';
import { isCatalogPart } from './helpers';
import { useGetManufacturers } from 'api/plants/useGetManufacturers';
import { useTranslation } from 'languages';

const assetTypesWithManufacturerField = ['bearing', 'motor'];

type InputKey = 'power' | 'speed' | 'frameSize';

type InvalidInputs = {
  [K in InputKey]?: boolean;
};

interface CustomPartCreationModalProps {
  open: boolean;
  onClose: () => void;
  onPartSave: (body: PartBody) => void;
  customPartsList: PartBody[];
}

const CustomPartCreationModal = ({
  open,
  onClose,
  onPartSave,
  customPartsList,
}: CustomPartCreationModalProps) => {
  const [createPartNumber, setCreatePartNumber] = useState('');
  const [selectedAssetType, setSelectedAssetType] = useState('');
  const [partExistsError, setPartExistsError] = useState(false);
  const [description, setDescription] = useState('');
  const [ndeBearing, setNdeBearing] = useState<PartBody | CatalogPartBody>();
  const [deBearing, setDeBearing] = useState<PartBody | CatalogPartBody>();
  const [isChoosingModalOpen, setIsChoosingModalOpen] = useState(false);
  const [currSelecting, setCurrSelecting] = useState<'nde' | 'de'>('nde');
  const [speedDrive, setSpeedDrive] = useState<boolean>();
  const [frameSize, setFrameSize] = useState<string>();
  const [nominalSpeed, setNominalSpeed] = useState<string>();
  const [horsePower, setHorsePower] = useState<string>();
  const [manufacturerId, setManufacturerId] = useState<number>();
  const [invalidInputs, setInvalidInputs] = useState<InvalidInputs>({});

  const { mutate: creatCustomPartMutation, isPending: isCreateLoading } = useCreateCustomPart(
    (id: number) => {
      onPartSave({
        id: id,
        number: createPartNumber,
        description: description,
        manufacturerId: manufacturerId,
        assetType: selectedAssetType,
        numberOfAssetInstances: 0,
      });
      onClose();
    },
  );

  const {
    data: manufacturers,
    isLoading: isManuLoading,
    refetch: refetchManufacturers,
  } = useGetManufacturers(selectedAssetType);

  useEffect(() => {
    if (selectedAssetType) {
      setManufacturerId(undefined);
      refetchManufacturers();
    }
  }, [selectedAssetType]);

  const sendCreateCustomPartRequest = () => {
    if (selectedAssetType === 'motor') {
      creatCustomPartMutation({
        body: {
          number: createPartNumber,
          assetType: selectedAssetType,
          description: description,
          manufacturer: manufacturerId,
          properties: {
            nonDriveEndBearing: ndeBearing ? ndeBearing.id : undefined,
            driveEndBearing: deBearing ? deBearing.id : undefined,
            power: horsePower,
            speed: nominalSpeed,
            variableSpeedDrive: speedDrive,
            frameSize: frameSize,
          },
        },
      });
    } else
      creatCustomPartMutation({
        body: {
          number: createPartNumber,
          assetType: selectedAssetType,
          description: description,
          manufacturer: manufacturerId,
        },
      });
  };

  const validateInput = useCallback(
    (inputValue: string, key: InputKey) => {
      const generalRegex = new RegExp(/^[0-9][0-9,/.\-\s]*$/g);
      const frameSizeRegex = new RegExp(/^[a-zA-Z0-9]*$/g);

      switch (key) {
        case 'frameSize':
          setInvalidInputs({
            ...invalidInputs,
            [key]: inputValue === '' ? undefined : !frameSizeRegex.test(inputValue),
          });
          break;
        default:
          setInvalidInputs({
            ...invalidInputs,
            [key]: inputValue === '' ? undefined : !generalRegex.test(inputValue),
          });
          break;
      }
    },
    [invalidInputs],
  );

  const enableSave =
    !partExistsError &&
    createPartNumber !== '' &&
    description !== '' &&
    selectedAssetType !== '' &&
    (selectedAssetType === 'motor' || selectedAssetType === 'bearing' ? !!manufacturerId : true);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      create: translate('create_add_part'),
      yourPartsNumber: translate('your_parts_number_add_part'),
      description: translate('description_add_part'),
      assetType: translate('asset_type_add_part'),
      manufacturer: translate('manufacturer_add_part'),
      horsePower: translate('horse_power_add_part'),
      invalidInput: translate('invalid_input_add_part'),
      nameplateSpeed: translate('nameplate_speed_add_part'),
      variableSpeedDrive: translate('variable_speed_drive_add_part'),
      motorFrameSize: translate('motor_frame_size_add_part'),
      ndeBearing: translate('nde_bearing_add_part'),
      deBearing: translate('de_bearing_add_part'),
      save: translate('save_add_part'),
      cancel: translate('cancel_add_part'),
      yes: translate('yes'),
      no: translate('no'),
      bearing: translate('asset_type_bearing'),
      gearReducer: translate('asset_type_gear_reducer'),
      motor: translate('asset_type_motor'),
      pump: translate('asset_type_pump'),
      other: translate('asset_type_other'),
    };
  }, []);

  return (
    <DodgeModal
      open={open}
      onClose={onClose}
      width='700px'
      testId='plants_management_part_creation_modal'
      title={
        <Grid container alignItems='center'>
          <IconButton onClick={onClose} sx={{ ml: '-1rem' }}>
            <ChevronLeft />
          </IconButton>
          <Typography sx={{ color: 'black', fontSize: '1.2rem' }}>
            {componentText.create}
          </Typography>
        </Grid>
      }
      hideCloseIco
    >
      <FormControl fullWidth>
        <TextField
          required
          label={componentText.yourPartsNumber}
          onChange={(e) => {
            setCreatePartNumber(e.target.value);
            setPartExistsError(customPartsList.some((part) => part.number === e.target.value));
          }}
          value={createPartNumber}
          error={partExistsError}
          helperText={partExistsError ? 'Part with given part number already exists.' : ''}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: '1rem' }}>
        <TextField
          required
          label={componentText.description}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
      </FormControl>
      <FormControl variant='standard' required fullWidth sx={{ mt: '0.5rem', ml: '1px' }}>
        <InputLabel id='select-asset-type-label'>{componentText.assetType}</InputLabel>
        <Select
          data-testid="plants_management_select_asset_type_input"
          labelId='select-asset-type-label'
          value={selectedAssetType}
          label={componentText.assetType}
          required
          onChange={(event) => {
            setSelectedAssetType(event.target.value);
          }}
        >
          <MenuItem data-testid='plants_management_asset_type_bearing' value='bearing'>{componentText.bearing}</MenuItem>
          <MenuItem data-testid='plants_management_asset_type_gear_reducer' value='gear-reducer'>{componentText.gearReducer}</MenuItem>
          <MenuItem data-testid='plants_management_asset_type_motor' value='motor'>{componentText.motor}</MenuItem>
          <MenuItem data-testid='plants_management_asset_type_pump' value='pump'>{componentText.pump}</MenuItem>
          <MenuItem data-testid='plants_management_asset_type_other' value='other'>{componentText.other}</MenuItem>
        </Select>
      </FormControl>
      {selectedAssetType && (
        <>
          <Grid container mt={2} spacing={2}>
            {assetTypesWithManufacturerField.includes(selectedAssetType) && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    data-testid='plants_management_part_creation_manufacturer_input'
                    value={(() => {
                      if (!manufacturers || !manufacturerId) return '';
                      return manufacturers.find((f) => f.ids.includes(manufacturerId))?.name;
                    })()}
                    onChange={(e) => {
                      setManufacturerId(
                        manufacturers?.find((m) => m.name === e.target.value)?.ids[0],
                      );
                    }}
                    label={componentText.manufacturer}
                    select
                    required
                    disabled={isManuLoading}
                    SelectProps={{
                      MenuProps: {
                        MenuListProps: { sx: { maxHeight: '200px', overflow: 'auto' } },
                      },
                    }}
                  >
                    {manufacturers
                      ?.filter((f) => !f.name.toLowerCase().includes('dodge'))
                      .map((m) => (
                        <MenuItem key={m.ids[0]} value={m.name}>
                          {m.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              </Grid>
            )}
            {selectedAssetType === 'motor' && (
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      data-testid='plants_management_part_creation_modal_horse_power_input'
                      value={horsePower ?? ''}
                      onChange={(e) => {
                        validateInput(e.target.value, 'power');
                        setHorsePower(e.target.value);
                      }}
                      label={componentText.horsePower}
                      error={invalidInputs.power}
                      helperText={invalidInputs.power && componentText.invalidInput}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      data-testid='plants_management_part_creation_modal_nominal_speed_input'
                      value={nominalSpeed ?? ''}
                      onChange={(e) => {
                        validateInput(e.target.value, 'speed');
                        setNominalSpeed(e.target.value);
                      }}
                      InputProps={{ endAdornment: 'RPM' }}
                      label={componentText.nameplateSpeed}
                      error={invalidInputs.speed}
                      helperText={invalidInputs.speed && 'Invalid input'}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      data-testid='plants_management_part_creation_modal_variable_speed_drive_input'
                      value={(() => {
                        if (speedDrive === undefined) return '';
                        else return speedDrive === true ? 'Yes' : 'No';
                      })()}
                      select
                      label={componentText.variableSpeedDrive}
                      onChange={(e) =>
                        e.target.value === 'Yes' ? setSpeedDrive(true) : setSpeedDrive(false)
                      }
                    >
                      <MenuItem value={'Yes'}>{componentText.yes}</MenuItem>
                      <MenuItem value={'No'}>{componentText.no}</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      data-testid='plants_management_part_creation_modal_motor_frame_size_input'
                      value={frameSize ?? ''}
                      onChange={(e) => {
                        validateInput(e.target.value, 'frameSize');
                        setFrameSize(e.target.value);
                      }}
                      label={componentText.motorFrameSize}
                      error={invalidInputs.frameSize}
                      helperText={invalidInputs.frameSize && 'Invalid input'}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      data-testid='plants_management_part_creation_nde_bearing_input'
                      label={componentText.ndeBearing}
                      onClick={() => {
                        setCurrSelecting('nde');
                        setIsChoosingModalOpen(true);
                      }}
                      value={(() => {
                        if (!ndeBearing) return '';
                        return (
                          (isCatalogPart(ndeBearing)
                            ? `${ndeBearing.manufacturer ? ndeBearing.manufacturer : 'unknown'}`
                            : `${ndeBearing.description ? ndeBearing.description : 'no desc'}`) +
                          `, ${ndeBearing.number}`
                        );
                      })()}
                      InputProps={{ endAdornment: <Edit color={'action'} /> }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      data-testid='plants_management_part_creation_de_bearing_input'
                      label={componentText.deBearing}
                      onClick={() => {
                        setCurrSelecting('de');
                        setIsChoosingModalOpen(true);
                      }}
                      value={(() => {
                        if (!deBearing) return '';
                        return (
                          (isCatalogPart(deBearing)
                            ? `${deBearing.manufacturer}`
                            : `${deBearing.description}`) + `, ${deBearing.number}`
                        );
                      })()}
                      InputProps={{ endAdornment: <Edit color={'action'} /> }}
                    />
                  </FormControl>
                </Grid>
                <SelectPartNoModal
                  isOpen={isChoosingModalOpen}
                  onClose={() => setIsChoosingModalOpen(false)}
                  onPartSave={(selectedPart) => {
                    currSelecting === 'nde'
                      ? setNdeBearing(selectedPart)
                      : setDeBearing(selectedPart);
                    setIsChoosingModalOpen(false);
                  }}
                  initFilter={'Bearing'}
                  mode='pick'
                  motorBearingManufacturer={
                    currSelecting === 'de' || currSelecting === 'nde' ? true : undefined
                  }
                />
              </>
            )}
          </Grid>
        </>
      )}
      <Grid container justifyContent='space-between' sx={{ width: '100%', mt: '1rem' }}>
        <Button variant='outlined' onClick={onClose}>
          {componentText.cancel}
        </Button>
        <LoadingButton
          loading={isCreateLoading}
          variant='contained'
          disabled={!enableSave}
          color='secondary'
          onClick={sendCreateCustomPartRequest}
          data-testid='plants_management_part_creation_modal_save_button'
        >
          {componentText.save}
        </LoadingButton>
      </Grid>
    </DodgeModal>
  );
};

export default CustomPartCreationModal;
