import { useGetUserRoles } from 'api/users';
import {
  USER_GLOBAL_ROLES,
  UserGlobalRole,
  UserPlantRole,
  UserRole,
} from 'features/plants-management/models/user.model';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

const checkRoles = (
  roles: UserRole[],
  mode: 'whitelist' | 'blacklist',
  currentPlantUserRole: UserPlantRole,
  currentGlobalUserRole: UserGlobalRole | null,
  ignoreGlobalRoles?: boolean,
): boolean => {
  if (mode === 'whitelist') {
    if (currentGlobalUserRole && !ignoreGlobalRoles)
      return roles.includes(currentPlantUserRole) || roles.includes(currentGlobalUserRole);
    else return roles.includes(currentPlantUserRole);
  } else {
    if (currentGlobalUserRole && !ignoreGlobalRoles)
      return !(roles.includes(currentPlantUserRole) || roles.includes(currentGlobalUserRole));
    else return !roles.includes(currentPlantUserRole);
  }
};

export const useRoleRequired = () => {
  const plantId = useSelector(selectPlantId);
  const { data: userRoles = [] } = useGetUserRoles();

  const filteredRoles = useMemo(
    () => userRoles?.filter((role) => role.plantName !== null && role.plantId !== 0),
    [userRoles],
  );

  const currentPlantUserRole = filteredRoles.find((x) => x.plantId === plantId)
    ?.role as UserPlantRole;
  const currentGlobalUserRole =
    (filteredRoles.find((x) => USER_GLOBAL_ROLES.includes(x.role as UserGlobalRole))
      ?.role as UserGlobalRole) ?? null;

  const haveUserRole = (
    roles: UserRole[],
    mode: 'whitelist' | 'blacklist' = 'whitelist',
    ignoreGlobalRoles?: boolean,
  ) =>
    currentPlantUserRole
      ? checkRoles(roles, mode, currentPlantUserRole, currentGlobalUserRole, ignoreGlobalRoles)
      : false;

  return { haveUserRole };
};
