import { Grid } from '@mui/material';
import { Asset, Sensor } from 'features/assets-management/models';
import {
  selectEndDate,
  selectFaultFrequencies,
  selectMarginRange,
  selectNominalSpeed,
  selectNumberOfHarmonics,
  selectSelectedSensors,
  selectStartDate,
  selectWindowingFunction,
} from 'features/assets-management/store/assetsManagementSlice';
import { SensorType } from 'models/sensor/models';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDeviceSize } from 'shared/responsive';
import { fftBackgroundColor, menuWidth } from '../VibrationFFTPage';
import AssetSettings from './AssetSettings';
import DisplaySettings from './DisplaySettings';
import MeasurementSettings, { scrollBar } from './MeasurementSettings';
import SpectralAnalysisSettings from './SpectralAnalysisSettings';

type Props = {
  openMenu: boolean;
  handleChangeOpenMenu: () => void;
  excludedSensorTypes: SensorType[];
  asset: string;
  selectedAssetInfo: Asset | undefined;
  location: number;
  sensorInfo: Sensor | undefined;
  unit: string;
  selectedFFTType: string;
  height: number;
  bearingReadyToAnalytics: string | null;
  deReadyToAnalytics: string | null;
  ndeReadyToAnalytics: string | null;
  dePartNumber: string | null | undefined;
  ndePartNumber: string | null | undefined;
};

const FFTMenu: FC<Props> = ({
  openMenu,
  handleChangeOpenMenu,
  excludedSensorTypes,
  asset,
  selectedAssetInfo,
  location,
  sensorInfo,
  unit,
  selectedFFTType,
  height,
  bearingReadyToAnalytics,
  deReadyToAnalytics,
  ndeReadyToAnalytics,
  dePartNumber,
  ndePartNumber,
}) => {
  const locations = useSelector(selectSelectedSensors);
  const selectedFrom: string = useSelector(selectStartDate);
  const selectedTo: string = useSelector(selectEndDate);
  const selectedFaultFrequencies = useSelector(selectFaultFrequencies);
  const numberOfHarmonics = useSelector(selectNumberOfHarmonics);
  const windowingFunction = useSelector(selectWindowingFunction);
  const marginRange = useSelector(selectMarginRange);
  const nominalSpeed = useSelector(selectNominalSpeed);
  const isSelectedSensors = useMemo(() => locations.length > 0, [locations]);
  const splitForMotor = useMemo(
    () =>
      Boolean(
        selectedAssetInfo?.type === 'motor' &&
          deReadyToAnalytics === 'ready' &&
          ndeReadyToAnalytics === 'ready' &&
          dePartNumber &&
          ndePartNumber,
      ),
    [selectedAssetInfo, deReadyToAnalytics, ndeReadyToAnalytics, dePartNumber, ndePartNumber],
  );
  const { isPhone } = useDeviceSize();

  const responsiveProps = {
    heightGrid: isPhone ? 'calc(100% - 8.5rem)' : 'calc(100% - 4.5rem)',
  };

  return (
    <Grid
      container
      flexDirection='column'
      width={menuWidth}
      maxWidth='100vw'
      sx={{
        background: fftBackgroundColor,
        p: 1,
        display: openMenu ? 'block' : 'none',
      }}
      right={0}
    >
      <Grid item container flexDirection='column' overflow='auto' flexWrap='nowrap' sx={scrollBar}>
        <Grid item>
          <AssetSettings
            excludedSensorTypes={excludedSensorTypes}
            asset={asset}
            locations={locations}
            location={location}
            isSelectedSensors={isSelectedSensors}
            splitForMotor={splitForMotor}
          />
        </Grid>
        <Grid item>
          <MeasurementSettings
            excludedSensorTypes={excludedSensorTypes}
            selectedAssetId={selectedAssetInfo?.assetId ?? 0}
            selectedSensorId={location}
            selectedFrom={selectedFrom}
            selectedTo={selectedTo}
            selectedFFTType={selectedFFTType}
            nominalSpeed={nominalSpeed}
          />
        </Grid>
        <Grid item>
          <SpectralAnalysisSettings
            rms={selectedFFTType}
            unit={unit}
            selectedAsset={selectedAssetInfo}
            selectedAssetId={selectedAssetInfo?.assetId ?? 0}
            location={location}
            sensorInfo={sensorInfo}
            faultFrequencies={selectedFaultFrequencies}
            numberOfHarmonics={numberOfHarmonics}
            isSelectedSensors={isSelectedSensors}
            windowingFunction={windowingFunction}
            marginRange={marginRange}
            nominalSpeed={nominalSpeed}
            bearingReadyToAnalytics={bearingReadyToAnalytics}
            deReadyToAnalytics={deReadyToAnalytics}
            ndeReadyToAnalytics={ndeReadyToAnalytics}
          />
        </Grid>
        <Grid item>
          <DisplaySettings />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FFTMenu;
