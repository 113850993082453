import { Button, Grid } from "@mui/material";
import { FC, memo, useMemo } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageContainer from "./ImageContainer";
import { DisplayType } from "./EditAsset";
import { AssetImage } from "features/plants-management/models/asset.model";
import { useTranslation } from "languages";

interface ImageContainerProps {
  setDisplay: (x: DisplayType) => void;
  mainImageRefetch: () => void;
  defaultPhoto: string | null;
  defaultNameplate: string | null;
  photos: AssetImage[] | [];
  nameplates: AssetImage[] | [];
  selectedAsset: number;
}

const EditImage: FC<ImageContainerProps> = ({
  setDisplay,
  mainImageRefetch,
  defaultPhoto,
  defaultNameplate,
  photos,
  nameplates,
  selectedAsset
}) => { 
  const handleBack = () =>{
    setDisplay("form");
    mainImageRefetch();
  }
  const { translate } = useTranslation();
  const componentText = useMemo(() => { 
    return { 
      saveAllButton: translate('save_all_button'),
      cancelButton: translate('cancel_button'),
      backButton: translate('menu_back_button')
    };
  }, []);
  return (
    <>
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        sx={{ ml: 0, mt: 0, fontWeight: 600 }}
        onClick={handleBack}
      >
        {componentText.backButton}
      </Button>
      <ImageContainer
        defaultPhoto = {defaultPhoto}
        defaultNameplate = {defaultNameplate}
        photos = {photos}
        nameplates = {nameplates}
        selectedAsset = {selectedAsset}
      />
      <Grid item container justifyContent="space-between" sx={{ mt: 6 }}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ color: "text.primary", fontWeight: "bold" }}
          onClick={handleBack}
        >
          {componentText.cancelButton}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ color: "text.primary", fontWeight: "bold" }}
          onClick={handleBack}
        >
          {componentText.saveAllButton}
        </Button>
      </Grid>
    </>
  );
};

export default memo(EditImage);
