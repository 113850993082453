import React from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import PlantFilter from 'components/PlantFilter/PlantFilter';
import { useDeviceSize } from 'shared/responsive';
import Profile from '../LoggedInProfile/Profile';

const Bar = () => {
  const theme = useTheme();
  const { isPhone, isTablet } = useDeviceSize();

  const responsiveProps = {
    environmentTitleHide: isPhone || isTablet ? { display: 'none' } : { display: 'block' },
  };
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        '@media (max-width: 750px)': {
          width: 'calc(100% - 3.75rem)',
        },
        '@media (min-width: 751px)': {
          width: 'calc(100% - 10.625rem)',
        },
      }}
    >
      <Grid item xs={2}>
        <PlantFilter />
      </Grid>
      <Grid item sm={4} sx={responsiveProps.environmentTitleHide}>
        <Box data-testid='dasboard_app_title'>
          <h1 style={{ color: 'lightgray' }}>{process.env.REACT_APP_TITLE}</h1>
        </Box>
      </Grid>
      <Grid item>
        <Profile />
      </Grid>
    </Grid>
  );
};
export default React.memo(Bar);
