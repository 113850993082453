import { Box } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';
import DataTableSelectFilter from '../DataTableSelectFilter/DataTableSelectFilter';
import { DataTableSelectedFilterOptions, Entity, Filter } from '../models';

export const ALL_FILTER = 'all';
export interface DataTableSelectionFilterBarProps<T> {
  onDataTableSelectedFilter: (filters: DataTableSelectedFilterOptions<T>[]) => void;
  filters?: Filter<T>[];
  additionalFilterJSX?: ReactNode;
  datePickerJSX ?: ReactNode;
  multiselectFilter?: boolean;
  blockedFilters ?: Pick<Filter<T>, 'filterKey'>[];
}

const DataTableSelectionFilterBar = <T extends Entity>(
  props : DataTableSelectionFilterBarProps<T>,
) => {
  // const [selectedFilters, setSelectedFilters] = useState<Record<keyof T, string[]>>();

  let selectedFilters: Record<keyof T, string[]> | undefined = useMemo(() => undefined, []);

  const filterSelectedHandler = useCallback(
    (filter: string, key: keyof T, multiselect: boolean) => {
      let copySelectedFilters = structuredClone(selectedFilters);

      if (filter.toLowerCase() === ALL_FILTER.toLowerCase()) {
        // No multiselect implementation
        if (!multiselect && copySelectedFilters && copySelectedFilters[key])
          delete copySelectedFilters[key];

        // Multiselect implementation
        if (multiselect && copySelectedFilters) {
          if (
            copySelectedFilters[key] &&
            copySelectedFilters[key].find((f) => f.toLowerCase() === ALL_FILTER.toLowerCase())
          )
            delete copySelectedFilters[key];
          else copySelectedFilters[key] = [ALL_FILTER];
        }
      } else if (copySelectedFilters && copySelectedFilters[key] && multiselect) {
        copySelectedFilters[key].includes(filter)
          ? (copySelectedFilters[key] = copySelectedFilters[key].filter((f) => f === filter))
          : (copySelectedFilters[key] = [...copySelectedFilters[key], filter]);
      } else if (copySelectedFilters) copySelectedFilters[key] = [filter];
      else copySelectedFilters = { [key]: [filter] } as Record<keyof T, string[]>;

      selectedFilters = copySelectedFilters;
      if (copySelectedFilters)
        props.onDataTableSelectedFilter(
          Object.entries(copySelectedFilters).map(([key, value]) => ({
            key: key as keyof T,
            value,
          })),
        );
      else {
        props.onDataTableSelectedFilter([]);
      }
    },
    [selectedFilters],
  );

  return (
    <Box sx={{ display: 'flex', ml: -1, alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }} data-testid='selection_filter_bar'>
        {props.filters?.map((filter) => {
          return (
            <DataTableSelectFilter
              key={filter.filterKey as string}
              filterProperties={filter}
              onDataTableSelectedFilter={filterSelectedHandler}
              multiselect={filter.multiselect}
              blockFilter={
                props.blockedFilters?.find((bf) => bf.filterKey === filter.filterKey) ? true : false
              }
            ></DataTableSelectFilter>
          );
        })}
        {props.additionalFilterJSX}
      </Box>
      {props.datePickerJSX}
    </Box>
  );
};

export default DataTableSelectionFilterBar;
