import { QueryCache, QueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

type Meta = {
  // success?: {
  //   fn?: () => void;
  //   message?: string;
  // };
  error?: {
    fn?: () => void;
    message?: string;
  };
};

declare module '@tanstack/react-query' {
  interface Register {
    queryMeta: Meta;
    mutationMeta: Meta;
  }
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error, query) {
      if (query.meta?.error) {
        const { fn, message } = query.meta.error;
        fn?.();
        if (message) toast.error(message);
      }
    },
  }),
});
