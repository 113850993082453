export const fontLarge = {
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
};

export const fontSmall = {
  fontWeight: 200,
  fontSize: '11px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
};

export const fontMedium = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20.02px',
  letterSpacing: '0.15px',
};

export const fontButton = {
  fontWeight: 600,
  fontSize: '15px',
  lineHeight: '26px',
  letterSpacing: '0.46px',
};

export const fontTitle = {
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '32px',
  letterSpacing: '0.15px',
};

export const fontSubDesc = {
  fontWeight: 700,
  fontSize: '12px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.7)',
};
