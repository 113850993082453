import { useAuthQuery } from 'api/queries';

export type GetApiKeysResponse = {
  email: string;
  name: string;
  id: string;
  timestamp: string;
};

export const useGetApiKeys = () => {
  return useAuthQuery<GetApiKeysResponse[]>({
    url: `users/api-keys`,
  });
};
