import { Grid } from "@mui/material";
import { FC, memo } from "react";


interface DataTableTilesBodyProps {
  children: any;
}

const DataTableTilesBody: FC<DataTableTilesBodyProps> = (props) => { 
  return (
    <Grid container mb={2} mt={1}>
      <Grid item xs={12}>
        <Grid container spacing={1} data-testid="data_table_tiles_container">
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(DataTableTilesBody);
