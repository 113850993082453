import useDatePickerConverter from 'components/Uitls/useDatePickerConverter';
import { memo } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";
import { useTranslation } from 'languages';

registerLocale("es", es);
registerLocale("en", en);

interface KpiDatePickerProps {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  handleSetRange: (dateRange: [Date | null, Date | null]) => void;
  disableMaxDate?: boolean;
}

const KpiDatePicker = ({
  startDate,
  endDate,
  handleSetRange,
  disableMaxDate,
}: KpiDatePickerProps) => {
  const { convertDateFromTimezone, convertDateToTimezone } = useDatePickerConverter();
  const {GLOBAL_LANGUAGE} = useTranslation();
  return (
    <DatePicker
      locale={GLOBAL_LANGUAGE as string}
      selectsRange
      startDate={convertDateToTimezone(startDate)}
      endDate={convertDateToTimezone(endDate)}
      onChange={(update) => {
        handleSetRange([
          convertDateFromTimezone(update[0]) ?? null,
          convertDateFromTimezone(update[1]) ?? null,
        ]);
      }}
      wrapperClassName='wrapper'
      className='inputDate'
      dateFormat='MM/dd/yyyy h:mm aa'
      monthsShown={2}
      maxDate={disableMaxDate ? undefined : convertDateToTimezone(new Date())}
      inline
    />
  );
};

export default memo(KpiDatePicker);
