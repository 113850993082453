import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { PartBody, useGetCustomParts } from 'api/plants/useGetCustomParts';
import { useGetUserRoles } from 'api/users';
import { DataTable } from 'components';
import DataTableSelectionFilterBar from 'components/DataTable/DataTableSelectionFilterBar/DataTableSelectionFilterBar';
import {
  ColumnRowTemplate,
  DataTableSelectedFilterOptions,
  Filter,
  FilterWithAlias,
} from 'components/DataTable/models';
import { shortenName } from 'helpers/utils';
import { useTranslation } from 'languages';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import CustomPartCreationModal from './CustomPartCreationModal';
import CustomPartEditModal from './CustomPartEditModal';
import DeletePartConfirmationModal from './DeletePartConfirmationModal';
import { assetTypeMap } from './helpers';
import { useGetTranslatedAssetLabel } from 'features/plants-management/utils/useGetTranslatedAssetLabel';

interface OwnPartNoSelectionProps {
  onClose: () => void;
  onPartSave: (part: PartBody) => any;
  mode?: 'pick';
  initFilter?: string | FilterWithAlias;
  disableSelect?: boolean;
}

const sortingDictionary = new Map([
  ['number', 'number'],
  ['description', 'description'],
  ['assetType', 'type'],
]);

const OwnPartNoSelection = ({
  onClose,
  onPartSave,
  mode,
  initFilter,
  disableSelect,
}: OwnPartNoSelectionProps) => {
  const searchTypeOngingTimeout = useRef<NodeJS.Timeout | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const [filterOptions, setFilterOptions] = useState<DataTableSelectedFilterOptions<PartBody>[]>(
    [],
  );
  const [search, setSearch] = useState('');
  const [sortValue, setSortValue] = useState('');
  const {
    data: response,
    isLoading,
    isFetching: isDataFetching,
  } = useGetCustomParts(
    rowsPerPage,
    pageNumber + 1,
    search,
    filterOptions.find((f) => f.key === 'assetType')?.value.join() ?? '',
    sortValue,
  );
  const [selectedPart, setSelectedPart] = useState<PartBody | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [openCreationModal, setOpenCreationModal] = useState(false);

  const data = useMemo(() => (response?.records ? response.records : []), [response]);

  const plantId = useSelector(selectPlantId);

  const { data: roles } = useGetUserRoles();

  const currentRole = roles?.find((r) => r.plantId === plantId)?.role;

  const { translate } = useTranslation();
  const getTranslatedAssetLabel = useGetTranslatedAssetLabel();
  const componentText = useMemo(() => {
    return {
      searchPlaceholder: translate('plants_own_parts_search'),
      selectButton: translate('plants_parts_select_button'),
      descriptionLabel: translate('plants_description_label'),
      actionLabel: translate('plants_action_label'),
      assetInstances: translate('plants_asset_instances_label'),
      assetTypeLabel: translate('plants_asset_type_label'),
      myOwnPartLabel: translate('plants_your_own_parts_label'),
      cancelButton: translate('plants_cancel_button'),
      backButton: translate('plants_back_button'),
      addPartButton: translate('plants_add_part_button'),
      statusAll: translate('status_all'),
      edit: translate('menu_edit_button'),
      info: translate('edit_part_number_info'),
      search: translate('search_label'),
    };
  }, []);

  const handleSetPage = useCallback((page: number) => {
    setPageNumber(page);
  }, []);

  const handleSetPageSize = useCallback((pageSize: number) => {
    setRowsPerPage(pageSize);
  }, []);

  const handleFilterChange = useCallback((filters: DataTableSelectedFilterOptions<PartBody>[]) => {
    setFilterOptions(filters);
    handleSetPage(0);
  }, []);

  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageNumber(0);
    setSearch(e.target.value);

    if (searchTypeOngingTimeout.current) {
      clearTimeout(searchTypeOngingTimeout.current);
    }

    searchTypeOngingTimeout.current = setTimeout(() => {}, 800);
  }, []);

  const externalSortHandler = useCallback((sortBy: string, sortDesc: boolean) => {
    if (!sortingDictionary.has(sortBy)) {
      return;
    }
    setSortValue(`${sortingDictionary.get(sortBy)}${sortDesc ? '_desc' : ''}`);
  }, []);

  const onItemsIdsSelected = useCallback(
    (ids: (string | number)[]) => {
      if (ids.length === 0) {
        setSelectedPart(undefined);
        return;
      }
      setSelectedPart(data.find((part) => part.id === ids[0]));
    },
    [data],
  );

  const columnRowTemplates: ColumnRowTemplate<PartBody>[] = useMemo(
    () => [
      {
        columnName: componentText.myOwnPartLabel,
        columnProperty: 'number',
        sortable: false,
        filterable: true,
        rowTemplateFn: (part: PartBody) => <Typography ml={1}>{part.number}</Typography>,
      },
      {
        columnName: componentText.descriptionLabel,
        columnProperty: 'description',
        sortable: false,
        filterable: true,
        rowTemplateFn: (part: PartBody) => (
          <Typography>{shortenName(part.description ?? '', 12)}</Typography>
        ),
      },
      {
        columnName: componentText.assetTypeLabel,
        columnProperty: 'assetType',
        sortable: false,
        rowTemplateFn: (part: PartBody) => (
          <Typography>
            {assetTypeMap.has(part.assetType)
              ? getTranslatedAssetLabel(assetTypeMap.get(part.assetType) ?? part.assetType)
              : getTranslatedAssetLabel(part.assetType)}
          </Typography>
        ),
      },
      {
        columnName: componentText.assetInstances,
        columnProperty: 'numberOfAssetInstances',
        sortable: false,
        rowTemplateFn: (part: PartBody) => (
          <Typography ml={6}>{part.numberOfAssetInstances}</Typography>
        ),
      },
      {
        columnName: componentText.actionLabel,
        rowTemplateFn: (part: PartBody) => (
          <Box display={'flex'} alignItems='center' gap='0.5rem'>
            <Button
              variant='outlined'
              sx={{ pt: '0.2rem', pb: '0.2rem' }}
              onClick={() => {
                onPartSave(part);
                onClose();
              }}
              disabled={disableSelect}
            >
              {componentText.selectButton}
            </Button>
          </Box>
        ),
      },
    ],
    [],
  );

  const filters: Filter<PartBody>[] = useMemo(
    () => [
      {
        filterLabel: componentText.assetTypeLabel,
        filterKey: 'assetType',
        filterList: [
          { alias: componentText.statusAll, value: 'All' },
          ...Array.from(assetTypeMap).map(([key, value]) => ({
            alias: getTranslatedAssetLabel(value),
            value: key,
          })),
        ],
        initValue: initFilter,
      },
    ],
    [initFilter],
  );

  const pageSize = useMemo(() => {
    return {
      rowsPageSize: 10,
      rowsPerPage: [5, 10, 25, 50],
    };
  }, []);

  const noPermission = currentRole === 'Plant Viewer' || currentRole === 'Super Viewer';

  return (
    <>
      {mode !== 'pick' && (
        <Button
          data-testid='plants_management_add_part_button'
          startIcon={<AddIcon />}
          variant='contained'
          color='secondary'
          onClick={() => setOpenCreationModal(true)}
          sx={{ fontWeight: 600, position: 'absolute', right: '2rem', top: '1.7rem' }}
          disabled={disableSelect}
        >
          {componentText.addPartButton}
        </Button>
      )}
      {openCreationModal && (
        <CustomPartCreationModal
          onPartSave={(body) => {
            onPartSave(body);
            onClose();
          }}
          open={openCreationModal}
          onClose={() => setOpenCreationModal(false)}
          customPartsList={data}
        />
      )}
      <Grid container alignItems={'center'}>
        <Grid item xs={12}>
          <FormControl sx={{ margin: '0 1%', width: '98%' }}>
            <TextField
              data-testid='plants_management_parts_search'
              label={componentText.search}
              InputLabelProps={{ shrink: true }}
              placeholder={componentText.searchPlaceholder}
              size='small'
              onChange={handleSearch}
              disabled={isDataFetching}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5} mt={2} mb={1}>
          <DataTableSelectionFilterBar
            filters={filters}
            onDataTableSelectedFilter={handleFilterChange}
            blockedFilters={
              mode === 'pick' || isDataFetching ? [{ filterKey: 'assetType' }] : undefined
            }
          />
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <DataTable
            header={''}
            checkboxes={'single-select'}
            hideToolbar
            isLoading={isLoading}
            customLoadingHeight='24.8rem'
            columnRowTemplates={columnRowTemplates}
            rows={data}
            minimised
            pageSize={pageSize}
            pageSizeControlled={rowsPerPage}
            setPage={handleSetPage}
            setPageSize={handleSetPageSize}
            page={pageNumber}
            totalCount={response?.totalRecords}
            onItemsIdsSelected={onItemsIdsSelected}
            deleteButton={
              selectedPart && selectedPart.numberOfAssetInstances === 0 && !noPermission
                ? undefined
                : 'none'
            }
            onDelete={() => setShowDeleteModal(true)}
            externalSortHandler={externalSortHandler}
            selectedItemsActions={
              !noPermission && (
                <Box display='flex' gap='1rem' width='500px' sx={{ justifyContent: 'flex-end' }}>
                  {selectedPart ? (
                    <Button
                      startIcon={<EditIcon />}
                      sx={{ fontWeight: 600 }}
                      onClick={() => setShowEditModal(true)}
                      data-testid='plants_management_part_selection_modal_edit_button'
                    >
                      {componentText.edit}
                    </Button>
                  ) : (
                    <Typography>{componentText.info}</Typography>
                  )}
                </Box>
              )
            }
            scrollable
            scrollableHeight='40vh'
          />
        </Grid>
      </Grid>
      {showEditModal && selectedPart && (
        <CustomPartEditModal
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          partToEdit={selectedPart}
          customPartsList={data}
          onPartSave={(body) => {
            onPartSave(body);
            onClose();
          }}
        />
      )}
      {showDeleteModal && selectedPart && (
        <DeletePartConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          partToDelete={selectedPart}
        />
      )}
      <Grid container justifyContent='space-between' sx={{ width: '98%', mt: 1 }}>
        <Button variant='outlined' onClick={onClose}>
          {mode === 'pick' ? componentText.backButton : componentText.cancelButton}
        </Button>
      </Grid>
    </>
  );
};

export default OwnPartNoSelection;
