import { Grid } from "@mui/material";
import { useGetAssetImages } from "api/plants/useGetAssetImages";
import { FC, memo, useEffect, useState } from "react";
import theme from "theme/theme";
import FullImage from "./FullImage";
import MainImage from "./MainImage";
import Slider from "./Slider";
import {Image} from "./models/image.model";
import BoxImageType from "./BoxImageType";

type ContentProps = {
    id: number;
}
const Content:FC<ContentProps> = ({id}) =>{

    const { data: images, isSuccess: isSuccessGetImages } =
      useGetAssetImages(id);

    const [selectedImage, setSelectedImage] = useState<Image | null>(null);
    const [index, setIndex] = useState<number>(0); 
    const [imageList, setImageList] = useState<Image[]>([]);

    useEffect(()=>{
      if(images && !selectedImage) {
        let imgArray:Image[] = [];
        let isSelectedImage = false;
        if(images.photos) {
          imgArray = images.photos.map(image => { return ({name:image.name, type: 'Asset Photo', thumbnail:image.thumbnail})});
          setSelectedImage({name: images.photos.find(image => image.default).name, type: 'Asset Photo', thumbnail: images.photos.find(image => image.default).thumbnail});
          isSelectedImage = true; 
        }
        if(images.nameplates){
          imgArray = imgArray.concat(images.nameplates.map(image => { return ({name:image.name, type: 'Nameplate Photo', thumbnail:image.thumbnail})}));
          if(!isSelectedImage) setSelectedImage({name: images.nameplates.find(image => image.default).name, type: 'Nameplate Photo', thumbnail: images.nameplates.find(image => image.default).thumbnail});
        } 
        setImageList(imgArray);  
      }
    },[isSuccessGetImages]);
   
    useEffect( () =>{
      if(imageList && imageList.length > 0 && selectedImage) setSelectedImage(imageList[index]);
    }, [index]);

    const slideRight = () => {
      setIndex((index + 1) % imageList.length); 
    };
  
    const slideLeft = () => {
      const nextIndex = index - 1;
      if (nextIndex < 0) {
        setIndex(imageList.length - 1); 
      } else {
        setIndex(nextIndex);
      }
    };

    return (
     <Grid container direction="column"  justifyContent="center">
        <Grid item container
               justifyContent = "center"
               alignItems = "center"
               height="28rem"
               position="relative"
               sx={{
                backgroundColor:"rgba(245, 245, 245, 1)",
                borderRadius:"12px",
              }}
        >
            {
              selectedImage && <MainImage assetId={id} image={selectedImage}/>
            }
            {
                images && imageList.length > 1 && <Slider slideRight={slideRight} slideLeft={slideLeft} />
            }
            {
              selectedImage && <FullImage assetId={id} image={selectedImage}/>
            }
            {
              selectedImage && <BoxImageType type={selectedImage.type}/>
            }
        </Grid>
        <Grid item container mt={2} xs={12}>
        {
                imageList && imageList.length > 0 && imageList.map( (image, index) => 
                    <Grid container item 
                      key={image.name}
                      width="8.5rem" 
                      height="7rem" 
                      m={1} 
                      justifyContent="center" 
                      alignItems="center" 
                      sx={{cursor:"pointer", border:image.name === selectedImage!.name ? `3px solid ${theme.palette.primary.main}` : ".5px solid rgba(245, 245, 245, 1)"}}
                      borderRadius="12px"
                      onClick={() => setIndex(index)}
                    >
                        <MainImage assetId={id} image={image} list={true} />
                    </Grid>)
            }
        </Grid>
     </Grid>
    );
}

export default memo(Content);