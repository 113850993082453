import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

interface AssetApplication {
  id: number;
  name: string;
  isDodge: boolean;
}

export const useGetAssetApplications = () => {
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_load_assets_from_plant_toast'),
    };
  }, []);

  return useAuthQuery<AssetApplication[]>({
    url: `plants/${plantId}/asset-applications`,
    queryOptions: {
      meta: {
        error: {
          message: componentText.errorMess,
        },
      },
    },
  });
};
