import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, IconButton, Typography } from '@mui/material';
import { AssetImage } from 'features/plants-management/models/asset.model';
import { FC, memo, useMemo, useState } from 'react';
import AddImage from './AddImage';
import ImageCard from './ImageCard';

import { RoleRequired } from 'components/RoleRequired';
import { useDeviceSize } from 'shared/responsive';

export type ImageType = 'asset' | 'nameplate';
type Action = 'next' | 'back';
export interface Image {
  id: string;
  name: string;
  selected: boolean;
  type: string;
  default: boolean;
}
interface ImageListProps {
  images: AssetImage[] | null;
  selectedAsset: number;
  selectedImage: string;
  title: string;
  type: string;
  changeImageType: (type: string) => void;
  changeSelected: (image: string) => void;
}

export const fontTitle = {
  fontWeight: 600,
  fontSize: '15px',
  lineHeight: '24px',
  letterSpacing: '0.17px',
};
const ImageList: FC<ImageListProps> = ({
  images,
  title,
  selectedAsset,
  changeSelected,
  selectedImage,
  type,
  changeImageType,
}) => {
  const [imageIndex, setImageIndex] = useState(() => {
    if (!images) return 0;
    const id = images.findIndex((i) => i.name === selectedImage);
    return id !== -1 ? id : 0;
  });

  const getValues = (id: number, action: Action, images: AssetImage[]) => {
    const defaultValues = { image: images[0].name, index: 0 };
    if (action === 'next')
      return id > -1 && id < images.length - 1
        ? { image: images[id + 1].name, index: id + 1 }
        : defaultValues;
    else return id > 0 ? { image: images[id - 1].name, index: id - 1 } : defaultValues;
  };
  const handleClick = (action: Action) => {
    if (!images) return;
    const id = images.findIndex((i) => i.name === selectedImage);
    const { image, index } = getValues(id, action, images);
    changeSelected(image);
    setImageIndex(index);
  };

  const { isPhone, isTablet, isDesktop } = useDeviceSize();

  const { start, end }: { start: number; end: number } = useMemo(
    () => (imageIndex < 3 ? { start: 0, end: 3 } : { start: imageIndex - 2, end: imageIndex + 1 }),
    [imageIndex],
  );

  return (
    <>
    {
      isPhone || isTablet && 
      <Typography
          sx={{
            ...fontTitle,
            mb: 2,
            mt: 2
          }}
        >
          {title}
        </Typography>
    }
    <Grid item container alignItems='center' spacing={1} ml={isPhone || isTablet ? 0 : '130px'}>
      {isDesktop && 
       <Grid item pb={1.1}>
       <Typography
           sx={{
             ...fontTitle,
             display: 'inline-flex',
             width: '130px',
             textAlign: 'right',
             ml: '-130px',
             pr: '1.5rem',
             justifyContent: 'flex-end',
           }}
         >
           {title}
         </Typography>
       </Grid>}
      <RoleRequired roles={['Super Viewer', 'Plant Viewer']} mode='blacklist'>
        <AddImage type={type} selectedAsset={selectedAsset} />
      </RoleRequired>
      <Grid
        item
        sx={{
          visibility: imageIndex === 0 || (images && images.length <= 3) ? 'hidden' : 'visible',
        }}
      >
        <IconButton onClick={() => handleClick('back')} color='primary'>
          <KeyboardArrowLeftIcon fontSize='small' />
        </IconButton>
      </Grid>
      <Grid
        item
        container
        width={isPhone || isTablet ? '40%' : '384px'}
        maxHeight='115px'
        flexWrap={'nowrap'}
        pb={2}
        sx={{
          overflowX: 'auto',
          '&::-webkit-scrollbar': { width: '5px', height: '5px' },
          '&::-webkit-scrollbar:horizontal': { width: '5px' },
          '&::-webkit-scrollbar-thumb': { background: '#337179' },
          '&::-webkit-scrollbar-track-piece:start': { background: 'transparent', mt: '1rem' },
        }}
      >
        {images &&
          images.length > 0 &&
          images
            .slice(start, end)
            .sort((a, b) => (a.default ? -1 : b.default ? 1 : 0))
            .map((item, i) => (
              <Grid item key={i}>
                <ImageCard
                  image={item.name}
                  selectedImage={selectedImage}
                  selectedAsset={selectedAsset}
                  type={type}
                  changeImageType={changeImageType}
                  changeSelected={changeSelected}
                  images={images}
                />
              </Grid>
            ))}
      </Grid>
      <Grid
        item
        sx={{
          visibility:
            images && images.length - 1 !== imageIndex && images.length > 3 ? 'visible' : 'hidden',
        }}
      >
        <IconButton onClick={() => handleClick('next')} color='primary'>
          <KeyboardArrowRightIcon fontSize='small' />
        </IconButton>
      </Grid>
    </Grid>
    </>
  );
};

export default memo(ImageList);
