import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export const useDeleteAssetImage = (assetId: number, imageId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('image_successfully_deleted_toast'),
      errorMess: translate('failed_to_delete_image_toast'),
    };
  }, []);

  return useAuthMutation({
    url: `assets/${assetId}/images/${imageId}`,
    method: 'DELETE',
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.successMess);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('images') });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes('assets'),
        });
        onSuccess?.();
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
