import { Search } from '@mui/icons-material';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Grid,
  InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  tableCellClasses,
} from '@mui/material';
import {
  NotifCategory,
  SortOrder,
  useGetManageSensorsNotif,
} from 'api/users/useGetManageSensorsNotif';
import { useGetNotification } from 'api/users/useGetNotifications';
import { NotificationWay, usePUTSensorNotif } from 'api/users/usePUTSensorNotif';
import { usePutAllSensorsNotif } from 'api/users/usePutAllSensorsNotif';
import { Loading } from 'components';
import { getSensorIco } from 'helpers/getSensorIco';
import { getUserFriendlySensorTypeName } from 'helpers/getUserFriendlySensorTypeName';
import { useDebounce } from 'helpers/useDebounce';
import { shortenName } from 'helpers/utils';
import { SENSOR_TYPES, SensorType } from 'models/sensor/models';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import TableFilter from './TableFilter';
import { FilterOption } from './models';
import { useTranslation } from 'languages';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

const PAGE_SIZE_OPTIONS = [5, 10, 15, 20] as const;
type PageSizeOptions = (typeof PAGE_SIZE_OPTIONS)[number];

const typesSorter = {
  PerformanceSensor: 0,
  Sensor: 1,
  BreatherSensor: 2,
  IntelliLube: 3,
};

// Temp type guard
const isSensorType = (x: any): x is SensorType => SENSOR_TYPES.includes(x);

const SensorsNotificationsTable = () => {
  const [assetGroup, setAssetGroup] = useState<string>('');
  const [sensorType, setSensorType] = useState<SensorType>();
  const [search, setSearch] = useState<string>('');
  const [pageSize, setPageSize] = useState<PageSizeOptions>(10);
  const [page, setPage] = useState<number>(0);
  const [lockActions, setLockActions] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<SortOrder>('assetName');

  const debouncedSearch = useDebounce<string>(search, 500);

  const plantId = useSelector(selectPlantId);

  const {
    data: notifData,
    isLoading: isNotifDataLoading,
    isFetching: isDataFetching,
    refetch: refetchNotifData,
  } = useGetManageSensorsNotif({
    pageNumber: page,
    pageSize: pageSize,
    searchString: debouncedSearch,
    assetGroup: assetGroup.toLowerCase() === 'all' ? undefined : assetGroup,
    sensorType: sensorType ? sensorType : '',
    sortOrder: `${orderBy}${order === 'asc' ? '' : '_desc'}`,
  });

  const {
    data: notifCounter,
    refetch: refetchNotifCounter,
    isFetching: isNotifCounterFetching,
  } = useGetNotification(plantId);

  const { mutateAsync: updateNotifMutation, isPending: isUpdateNotifLoading } = usePUTSensorNotif();
  const { mutateAsync: selectAllMutation, isPending: isSelectAllLoading } = usePutAllSensorsNotif();

  const { translate } = useTranslation();
  const componentText = {
    rowsPerPageLabel: translate('datatable_rows_per_page_label'),
    assetGroup: translate('asset_group_label'),
    sensorType: translate('table_sensor_type_label'),
    search: translate('search_placeholder'),
    assetName: translate('asset_name_label'),
    position: translate('position_label'),
    sensor: translate('asset_group_sensors_text'),
    email: translate('menu_email_label'),
    push: translate('menu_notification_push_label'),
    all: translate('status_all'),
    of: translate('pagination_of_label'),
  };

  const assetGroupFilterOptions: FilterOption[] = useMemo(() => {
    const allOpt: FilterOption = { key: 'all', label: componentText.all };

    if (!notifData) return [allOpt];

    const arrOpt: FilterOption[] = notifData.assetGroups.map((g) => {
      return { key: g, label: g };
    });

    return [allOpt, ...arrOpt];
  }, [notifData]);

  const availableTypes = notifData?.availableTypes || [];

  const sensorTypeFilterOptions: FilterOption[] = useMemo(() => {
    const base = availableTypes
      .sort((a, b) => {
        if (
          typeof typesSorter[a as keyof typeof typesSorter] === 'number' &&
          typeof typesSorter[b as keyof typeof typesSorter] === 'number'
        ) {
          return (
            typesSorter[a as keyof typeof typesSorter] - typesSorter[b as keyof typeof typesSorter]
          );
        }
        return 0;
      })
      .map((type) => {
        return {
          key: type,
          label: getUserFriendlySensorTypeName(type, true),
        };
      });

    return [{ key: 'all', label: componentText.all }, ...base];
  }, [availableTypes]);

  const notifCategories = useMemo(() => {
    if (!notifData) return;

    return notifData.notificationCategories;
  }, [notifData]);

  const translationDictionary = {
    'parameter alarm': translate('parameter_alarm_notify_label'),
    'parameter alert': translate('parameter_alert_notify_label'),
    'device alarm': translate('device_alarm_notify_label'),
    'recurring events': translate('recurring_events_notify_label'),
  };

  const getTranslatedLabel = (labelString: string) => {
    const label = labelString ? labelString.toLowerCase() : '';
    if (label && label in translationDictionary) {
      return translationDictionary[label as keyof typeof translationDictionary];
    }
    return label;
  };

  const populateNotifCatColumns = useCallback(() => {
    if (!notifCategories) return;

    const columns = [];

    for (const [key, value] of Object.entries(notifCategories)) {
      columns.push(
        <TableCell key={key} sx={{ fontWeight: 'bold' }} colSpan={2} align='center'>
          {getTranslatedLabel(value)}
        </TableCell>,
      );
    }

    return columns;
  }, [notifCategories]);

  const handleNotifUpdate = async (
    categoryKey: string,
    sensorId: number,
    method: NotificationWay,
    value: boolean,
  ) => {
    setLockActions(true);
    await updateNotifMutation(
      {
        params: {
          sensorId: sensorId,
        },
        body: {
          type: categoryKey,
          notificationWay: method,
          value: value,
        },
      },
      {
        onSettled(data, error, variables, context) {
          refetchNotifData();
          refetchNotifCounter();
        },
      },
    );
    setLockActions(false);
  };

  const populateNotifCells = useCallback(
    (categories: NotifCategory[], sensorId: number) => {
      if (!notifCategories) return;

      const elArr = [];

      for (const [key] of Object.entries(notifCategories)) {
        const category = categories.find((c) => c.key === key);
        if (!category) break;

        elArr.push(
          <React.Fragment key={`sensorId=${sensorId}&notifKey=${key}`}>
            <TableCell align='left'>
              <Checkbox
                checked={category.email}
                onChange={(e) =>
                  handleNotifUpdate(category.key, sensorId, 'email', e.target.checked)
                }
                disabled={lockActions}
              />
            </TableCell>
            <TableCell align='left'>
              <Checkbox
                checked={category.push}
                onChange={(e) =>
                  handleNotifUpdate(category.key, sensorId, 'push', e.target.checked)
                }
                disabled={lockActions}
              />
            </TableCell>
          </React.Fragment>,
        );
      }
      return elArr;
    },
    [notifData, lockActions],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: SortOrder) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10) as PageSizeOptions);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const visibleRows = useMemo(() => {
    return notifData?.records;
  }, [notifData, page, pageSize]);

  const tablePlaceholder = useMemo(() => {
    // Base 4 + 2 * categoryCount
    // 2 For now since there is Email and Push
    const cellCount = notifCategories ? 4 + 2 * Object.entries(notifCategories).length : 8;

    const cellArr = [];
    const rowArr = [];

    for (let j = 0; j !== cellCount; j++) {
      cellArr.push(
        <TableCell>
          <Skeleton />
        </TableCell>,
      );
    }

    for (let i = 0; i !== pageSize; i++) {
      rowArr.push(<TableRow>{cellArr}</TableRow>);
    }

    return rowArr;
  }, []);

  // Check if all recurring notifications are enabled for specific type
  const isAllNotifEnabled = useCallback(
    (type: 'email' | 'push', category: string) => {
      if (!notifCounter) return false;

      const categoryNotif = notifCounter.notificationCategories.find((c) => c.type === category);

      if (!categoryNotif) return false;

      switch (type) {
        case 'email':
          if (
            categoryNotif.emailCounter > 0 &&
            categoryNotif.emailCounter < categoryNotif.totalCounter
          )
            return undefined;
          return categoryNotif.emailCounter === categoryNotif.totalCounter;
        case 'push':
          if (
            categoryNotif.pushCounter > 0 &&
            categoryNotif.pushCounter < categoryNotif.totalCounter
          )
            return undefined;
          return categoryNotif.pushCounter === categoryNotif.totalCounter;
      }
    },
    [notifCounter],
  );

  // Handle click enable/disable all notifications
  const handleClickAll = useCallback(
    async (method: 'email' | 'push', category: string, value: boolean) => {
      setLockActions(true);

      await selectAllMutation(
        {
          params: {
            assetGroupName: assetGroup === 'all' ? undefined : assetGroup,
            sensorType: sensorType,
            searchString: search,
          },
          body: {
            type: category,
            notificationWay: method,
            value: value,
          },
        },
        {
          onSettled(data, error, variables, context) {
            refetchNotifData();
            refetchNotifCounter();
          },
        },
      );

      setLockActions(false);
    },
    [assetGroup, sensorType, search],
  );

  useEffect(() => {
    setPage(0);
  }, [assetGroup, search, sensorType]);

  if (isNotifDataLoading) return <Loading />;

  return (
    <>
      <TableContainer sx={{ pt: 3 }}>
        <Table size='small'>
          <TableHead>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              <TableCell colSpan={10}>
                <Grid container spacing={3} p={1} alignItems={'center'}>
                  <Grid item>
                    <TableFilter
                      filterName={componentText.assetGroup}
                      filterOptions={assetGroupFilterOptions}
                      onChange={(filter) => setAssetGroup(filter)}
                      value={assetGroup}
                    />
                  </Grid>
                  <Grid item>
                    <TableFilter
                      filterName={componentText.sensorType}
                      filterOptions={sensorTypeFilterOptions}
                      onChange={(filter) => {
                        if (isSensorType(filter)) setSensorType(filter);
                        else setSensorType(undefined);
                      }}
                      value={sensorType}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={search}
                      onChange={handleSearchChange}
                      size='small'
                      placeholder={componentText.search}
                      type='search'
                      variant='outlined'
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
              }}
            >
              <TableCell sx={{ fontWeight: 'bold' }} colSpan={4} />
              {populateNotifCatColumns()}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                sortDirection={orderBy === 'assetName' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'assetName'}
                  direction={orderBy === 'assetName' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'assetName')}
                >
                  {componentText.assetName}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                sortDirection={orderBy === 'assetGroupName' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'assetGroupName'}
                  direction={orderBy === 'assetGroupName' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'assetGroupName')}
                >
                  {componentText.assetGroup}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                sortDirection={orderBy === 'location' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'location'}
                  direction={orderBy === 'location' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'location')}
                >
                  {componentText.position}
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{ fontWeight: 'bold' }}
                sortDirection={orderBy === 'sensorType' ? order : false}
                align='center'
              >
                <TableSortLabel
                  active={orderBy === 'sensorType'}
                  direction={orderBy === 'sensorType' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'sensorType')}
                >
                  {componentText.sensor}
                </TableSortLabel>
              </TableCell>
              {populateNotifCatColumns()?.map((col) => (
                <>
                  <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                    <Checkbox
                      disabled={lockActions || isNotifCounterFetching}
                      checked={isAllNotifEnabled('email', String(col.key))}
                      indeterminate={isAllNotifEnabled('email', String(col.key)) === undefined}
                      onChange={(e) => handleClickAll('email', String(col.key), e.target.checked)}
                    />
                    {componentText.email}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                    <Checkbox
                      disabled={lockActions || isNotifCounterFetching}
                      checked={isAllNotifEnabled('push', String(col.key))}
                      indeterminate={isAllNotifEnabled('push', String(col.key)) === undefined}
                      onChange={(e) => handleClickAll('push', String(col.key), e.target.checked)}
                    />
                    {componentText.push}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isDataFetching && tablePlaceholder}
            {!isDataFetching &&
              visibleRows?.map((row) => (
                <React.Fragment key={row.sensorId + '/' + row.location}>
                  <TableRow>
                    <TableCell>{shortenName(row.assetName, 12, 'top')}</TableCell>
                    <TableCell>{shortenName(row.assetGroupName, 12, 'top')}</TableCell>
                    <TableCell>{shortenName(row.location, 12, 'top')}</TableCell>
                    <TableCell align='center'>{getSensorIco(row.sensorType)}</TableCell>
                    {populateNotifCells(row.notificationCategories, row.sensorId)}
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent={'flex-end'}>
        <Grid item>
          <TablePagination
            labelRowsPerPage={componentText.rowsPerPageLabel}
            component={'div'}
            rowsPerPageOptions={PAGE_SIZE_OPTIONS.slice()}
            rowsPerPage={pageSize}
            page={page}
            count={notifData ? notifData.totalRecords : 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} ${componentText.of} ${count}`;
            }}
          />
        </Grid>
      </Grid>
      <Backdrop open={isDataFetching || isSelectAllLoading || lockActions} sx={{ color: '#fff' }}>
        <CircularProgress color='primary' />
      </Backdrop>
    </>
  );
};

export default memo(SensorsNotificationsTable);
