//@ts-nocheck
import { useTranslation } from 'languages';
import { useDeviceSize } from './useDeviceSize';

const deepMerge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && key in target) {
      if (target[key] instanceof Object) {
        deepMerge(target[key], source[key]);
      } else {
        if (!(key in target)) {
          target[key] = source[key];
        }
      }
    } else {
      if (key === "__proto__" || key === "constructor") continue;
      target[key] = source[key];
    }
  }
  return target;
};

export const useResponsiveLanguage = (styles: any) => {
  const { isXSPhone, isPhone, isTablet, isDesktop } = useDeviceSize();
  const { GLOBAL_LANGUAGE: globalLanguage } = useTranslation();

  let selectedStyle = {};
  let languageStyles = {};
  const defaultStyles = styles['en'] || {};
  // Check if styles for the global language exist, otherwise use English styles
  if (styles[globalLanguage as string]) {
    languageStyles = deepMerge(defaultStyles, styles[(globalLanguage as string)] || {});
  } else {
    languageStyles = deepMerge(defaultStyles, styles['en'] || {});
  }

  // Determine which style to apply based on device size
  if (languageStyles) {
    if (isXSPhone) {
      selectedStyle = { ...selectedStyle, ...languageStyles.xs };
    } else if (isPhone) {
      selectedStyle = { ...selectedStyle, ...languageStyles.xs, ...languageStyles.sm };
    } else if (isTablet) {
      selectedStyle = {
        ...selectedStyle,
        ...languageStyles.xs,
        ...languageStyles.sm,
        ...languageStyles.md,
      };
    } else if (isDesktop) {
      selectedStyle = {
        ...selectedStyle,
        ...languageStyles.xs,
        ...languageStyles.sm,
        ...languageStyles.md,
        ...languageStyles.lg,
      };
    }
  }

  return selectedStyle;
};

/*
INFO: Example usage of useResponsiveLanguage
We can use it in different ways. For example, when we apply styles for English
(which is the default language), the styles will be applied for other languages as well, 
without having to write them again. However, if we apply styles for a specific language, 
only the styles for that language will be applied (overriding the default styles).

Example usage:
createPlantButton: useResponsiveLanguage({
  en: { xs: { ...fontButton, float: 'right' } },
  es: { xs: { color: 'yellow' }, lg: { fontSize: '0.95rem' } },
})

Output for small devices (and above!):
{
  ...fontButton,
  float: 'right',
  color: 'yellow'
}
*/
