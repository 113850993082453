export type Title = { name: string; sortable: boolean; columnWidth: string; sortKey?: string };
export type View = 'reports' | 'createReport';
export type ReportCategory = 'Standard' | 'Recurring';
export type ReportType = 'Standard' | 'Plant';
export type ActiveStep = 0 | 1 | 2 | 3;
export type EmailUserType = 'audience' | 'recipient';
export const SHIFTS_PER_DAY = ['1x8', '2x8', '3x8', '1x12', '2x12'] as const;
export const WEEKEND_OPERATIONS = ['only-sat', 'all', 'none'] as const;

export type WeekendOperation = (typeof WEEKEND_OPERATIONS)[number];

export type ShiftsPerDay = (typeof SHIFTS_PER_DAY)[number];

export interface SelectedPlant {
  plantId: number;
  plantName: string;
}

export interface AssetGroupAndAsset {
  id: number;
  groupName: string;
  assetName: string;
  assetCount: number;
  assetType: string;
  groupId?: number;
}

export interface CreateGroup {
  id: number;
  groupName: string;
  desc: string;
  assets: AssetGroupAndAsset[];
}

export interface User {
  id: number;
  name: string;
  email: string;
  ccUser: boolean;
  position?: string;
}

export interface StandardReport {
  id: number | string;
  name: string;
  plantName: string;
  plantId: number;
  reportType: ReportType;
  creationDate: string;
  generatedBy: string;
  status: string;
  reportName: string;
}

export interface ErrorMsg {
  message: string;
  enabled: boolean;
}
export interface RequiredFields {
  assets: ErrorMsg;
  recipients: ErrorMsg;
  timeRange: ErrorMsg;
  reportName: ErrorMsg;
  plant: ErrorMsg;
  reportRecurringName: ErrorMsg;
}

export const initReqFieldsState = {
  assets: {
    message: 'Please select asset(s) from the below list to create report',
    enabled: false,
  },
  recipients: {
    message: 'The list of email recipients cannot be empty. Please add recipients to the report.',
    enabled: false,
  },
  timeRange: {
    message: 'Select time range to the report.',
    enabled: false,
  },
  reportName: {
    message: 'The report name cannot be empty. Please provide a name.',
    enabled: false,
  },
  reportRecurringName: {
    message: 'The report name cannot be empty. Please provide a name.',
    enabled: false,
  },
  plant: {
    message: 'Please select plant from the below list to create report',
    enabled: false,
  },
};

export interface RecurringReport {
  id: number;
  templateId: number;
  startTime: string;
  nextTime: string;
  plantName: string;
  plantId: number;
  reportType: string; // type
  recipients: string[];
  frequency: string; // schedule
  status: string;
  name: string; // reportname
  recurringReportName: string;
}

export interface Template {
  id: number;
  name: string;
  createdOn: string;
}

interface TemplateAssetGroup {
  name: string;
  description: string;
  assetIds: number[];
}
interface TemplateUser {
  name: string;
  jobPosition: string;
  email: string;
  ccUser: boolean;
}
interface StandardTemplateSettings {
  recipients: TemplateUser[];
  plantName: string;
  plantId: number;
  reportTimeSpan: number;
  unit: string;
  threeAxisEnable: boolean;
  assetImages: boolean;
  assetGroupHeadings: boolean;
  descriptionFields: boolean;
  fileFormat: string;
  //reportName : string;
  audience: TemplateUser[];
  assetIds: number[];
  assetGroups: TemplateAssetGroup[];
}
export interface StandardTemplateBody {
  name: string;
  plantId: number;
  createdBy: string;
  type: 'Standard';
  settings: StandardTemplateSettings;
}

export type PlantTemplateSettings = {
  recipients: TemplateUser[];
  plantName: string;
  plantId: number;
  reportTimeSpan: number;
  weekendOperations: WeekendOperation;
  imageURL: string | null;
  shifts: ShiftsPerDay;
  unit: string;
  threeAxisEnable: boolean;
  assetImages: boolean;
  assetGroupHeadings: boolean;
  descriptionFields: boolean;
  fileFormat: string;
  plantUptime: number;
  generateStandardReport: boolean;
  standardReportAllAssets: boolean;
};

export type PlantTemplateBody = {
  name: string;
  plantId: number;
  createdBy: string;
  type: 'Plant';
  settings: PlantTemplateSettings;
};
