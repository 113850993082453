import DodgeModal from "components/DodgeModal/DodgeModal";
import SelectionPage from "./SelectionPage";
import { useCallback, useMemo, useState } from "react";
import TransferSuccess from "./TransferSuccess";
import { useTranslation } from "languages";

interface TransferSensorModalProps {
    serialNumber: string,
    sensorLocation: string,
    isOpen: boolean,
    oldAssetName?: string,
    oldAssetId: number,
    onClose: () => void,
    refetchAsset: () => void
}

const TransferSensorModal = ({ serialNumber, isOpen, onClose, oldAssetName = '', oldAssetId, sensorLocation, refetchAsset }: TransferSensorModalProps) => {
    const [isTransferInProgress, setIsTransferInProgress] = useState(true);
    const [transferredSensorSerialNumber] = useState(serialNumber);
    const [transferredSensorLocation] = useState(sensorLocation);
    const [newAssetName, setNewAssetName] = useState('');
    const onAssetTransferSuccess = useCallback((assetName: string) => {
        setNewAssetName(assetName);
        setIsTransferInProgress(false);
        refetchAsset();
    }, [refetchAsset]);

    const { translate } = useTranslation();
    const componentText = useMemo(() => { 
      return { 
        transferSensorTitle: translate('transfer_sensor_title'),
        sensorTransferredLabel: translate('sensor_transferred_label')
      };
    }, []);
    return (
        <DodgeModal
            data-testid="plant_management_modal_transfer_success" 
            open={isOpen}
            onClose={onClose}
            title={isTransferInProgress ? componentText.transferSensorTitle : componentText.sensorTransferredLabel}
        > { isTransferInProgress ? (
            <SelectionPage serialNumber={transferredSensorSerialNumber} oldAssetId={oldAssetId} onAssetTransferSuccess={onAssetTransferSuccess} />
        ) : (
            <TransferSuccess 
            serialNumber={transferredSensorSerialNumber} 
            sensorLocation={transferredSensorLocation} 
            oldAssetName={oldAssetName} 
            newAssetName={newAssetName} 
            onClose={onClose} />
        )}
        </DodgeModal>
    );
}

export default TransferSensorModal;