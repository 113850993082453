import { PersonOutline } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { CopyrightBar } from 'components';
import { FC, memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import BackgroundImage from '../../../components/assets/onboardng-background.png';
import { useGetImageUrl } from 'helpers/useGetImageUrl';

type Props = {
  children: ReactNode;
};

const SensorsInfoLayout: FC<Props> = ({ children }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      gap='1.5rem'
      minHeight={'100vh'}
      sx={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
      }}
    >
      <Grid container justifyContent='space-between' alignItems='center' p={4}>
        <Grid item>
          <img src={useGetImageUrl('public/dodge.png')} alt='DRG logo' />
        </Grid>
        <Grid item>
          <Link to={'login'} style={{ textDecoration: 'none' }}>
            <Stack direction={'row'} spacing={2}>
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  border: '1px solid #666666',
                  borderRadius: '50%',
                  backgroundColor: 'white',
                }}
              >
                <PersonOutline sx={{ color: '#666666' }} />
              </Box>
              <Typography fontWeight={400} color={'white'}>
                Sign in
              </Typography>
            </Stack>
          </Link>
        </Grid>
      </Grid>
      {/* <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        paddingLeft={4}
        paddingRight={4}
        paddingTop={4}
      >
        <img src={useGetImageUrl('public/dodge.png)} alt='DRG logo' />
      </Box> */}
      <Box display='flex' justifyContent='center'>
        {children}
      </Box>
      <Box>
        <CopyrightBar />
      </Box>
    </Box>
  );
};

export default memo(SensorsInfoLayout);
