import {
  Dispatch,
  ReactNode,
  SetStateAction,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import DetailsForm from './DetailsForm';
import { AssetType } from '../CreateAssetModalFlow/CreateAsset';
import { Box, Tab, Tabs } from '@mui/material';
import SensorsForm from './SensorsForm';
import { AssetGroup } from 'features/plants-management/models/asset.model';
import { DisplayType } from './EditAsset';
import { AdditionalField } from 'api/plants/useGetAssetTypeConfiguration';
import { useTranslation } from 'languages';
import { useResponsiveLanguage } from 'shared/responsive';

export interface EditAssetTabsRefProps {
  applyChanges : () => void;
}

interface EditAssetTabsProps {
  assetName : string | undefined;
  newAssetTypeConf : { [key : string] : string | number };
  assetTypeConfiguration: AdditionalField[] | undefined;
  partNumber: string | undefined;
  assetType: string | undefined;
  partDescription: string | undefined;
  serialNumber: string | undefined;
  note: string | undefined;
  assetGroup: number | undefined;
  partId: number | undefined;
  setPartId: (partId: number) => void;
  setIsLoading : Dispatch<SetStateAction<boolean>>;
  setDisabledSave : Dispatch<SetStateAction<boolean>>;
  assetGroups: AssetGroup[];
  onCommission: (displayType: DisplayType) => void;
  onDecomission?: () => void;
  setAssetName: (e: string) => void;
  setNewAssetTypeConf: (
    fn : (type : { [key : string] : string | number }) => { [key : string] : string | number },
  ) => void;
  setPartNumber: (e: string) => void;
  setAssetType: (e: AssetType) => void;
  setPartDescription: (e: string) => void;
  setAssetGroup: (e: number) => void;
  setSerialNumber: (e: string) => void;
  setNote: (e: string) => void;
  selectedAsset: number;
  tab?: number;
  sensorsQuantity?: number;
  selectedAssetName?: string;
  refetchAsset: () => void;
  disableInput?: boolean;
  partProperties : {
    [key : string] : {
      label : string;
      type : string;
      value : string | number;
      unit : string;
    };
  };
  assetApplicationId: number | null;
  setAssetApplicationId: Dispatch<SetStateAction<number | null>>;
  sensorSerialNumber ?: string;
}
const EditAssetTabs = forwardRef<EditAssetTabsRefProps, EditAssetTabsProps>(
  (
    {
      tab,
      selectedAsset,
      assetName,
      newAssetTypeConf,
      assetTypeConfiguration,
      partNumber,
      assetType,
      partDescription,
      serialNumber,
      note,
      assetGroup,
      assetGroups,
      sensorsQuantity,
      partProperties,
      onCommission,
      onDecomission,
      setAssetName,
      setNewAssetTypeConf,
      setPartNumber,
      setAssetType,
      setPartDescription,
      setAssetGroup,
      setSerialNumber,
      setNote,
      partId,
      setPartId,
      selectedAssetName,
      disableInput,
      refetchAsset,
      assetApplicationId,
      setAssetApplicationId,
      sensorSerialNumber,
      setIsLoading,
      setDisabledSave
    },
    ref,
  ) => {
    const [tabValue, setTabValue] = useState(tab ?? 0);

    const tabChangeHandler = (_ : unknown, tabValue : number) => {
      setTabValue(tabValue);
    };
    let component : ReactNode;

    switch (tabValue) {
      case 0:
        component = (
          <DetailsForm
            assetName={assetName}
            newAssetTypeConf={newAssetTypeConf}
            assetTypeConfiguration={assetTypeConfiguration}
            partNumber={partNumber}
            assetType={assetType}
            partDescription={partDescription}
            serialNumber={serialNumber}
            note={note}
            assetGroup={assetGroup}
            assetGroups={assetGroups}
            partId={partId}
            partProperties={partProperties}
            sensorsQuantity={sensorsQuantity}
            setPartId={setPartId}
            setAssetName={setAssetName}
            setNewAssetTypeConf={setNewAssetTypeConf}
            setPartNumber={setPartNumber}
            setAssetType={setAssetType}
            setPartDescription={setPartDescription}
            setAssetGroup={setAssetGroup}
            setSerialNumber={setSerialNumber}
            setNote={setNote}
            selectedAsset={selectedAsset}
            onCommission={onCommission}
            disableInput={disableInput}
            assetApplicationId={assetApplicationId}
            setAssetApplicationId={setAssetApplicationId}
            setIsLoading={setIsLoading}
            setDisabledSave={setDisabledSave}
            ref={ref}
          />
        );
        break;
      case 1:
        component = (
          <SensorsForm
            selectedAsset={selectedAsset}
            onDecomission={onDecomission}
            changeTab={setTabValue}
            selectedAssetName={selectedAssetName}
            refetchAsset={refetchAsset}
            disableInput={disableInput}
            sensorSerialNumber={sensorSerialNumber}
            setIsLoading={setIsLoading}
            setDisabledSave={setDisabledSave}
            ref={ref}
          />
        );
        break;
    }

    const { translate } = useTranslation();
    const componentText = useMemo(() => {
      return {
        sensorsTitle: translate('sensors_title'),
        detailsTitle: translate('details_title'),
      };
    }, []);

    const responsiveProps = {
      buttonMinWidth: useResponsiveLanguage({
        en: { xs: { width: '10rem' }, md: { width: '15rem' } },
      }),
    };
    return (
      <Box sx={{ padding: '0' }}>
        <Tabs
          value={tabValue}
          onChange={tabChangeHandler}
          sx={{
            background: 'white',
            '& .MuiButtonBase-root.MuiTab-root': { minWidth: responsiveProps.buttonMinWidth },
            '& .Mui-selected': { minWidth: responsiveProps.buttonMinWidth },
          }}
          textColor='primary'
          indicatorColor='primary'
        >
          <Tab
            label={componentText.detailsTitle}
            id='details'
            sx={{ fontWeight: 'bold' }}
            data-testid='plant_management_edit_sensor_details_tab'
          />
          {sensorsQuantity && (
            <Tab
              label={componentText.sensorsTitle}
              id='sensors'
              sx={{ fontWeight: 'bold' }}
              data-testid='plant_management_sensors_list_edit_sensor'
            />
          )}
        </Tabs>
        <Box
          data-testid='plant_managment_sensors_edit_div'
          sx={{
            margin: 0,
            maxHeight: 'calc(90vh - 25rem)',
            overflowY: 'auto',
            pr: '0.5rem',
            '&::-webkit-scrollbar': { width: '5px' },
            '&::-webkit-scrollbar-thumb': { background: '#337179' },
            '&::-webkit-scrollbar-track-piece:start': {
              background: 'transparent',
              mt: '1rem',
              flexWrap: 'nowrap',
            },
          }}
        >
          {component}
        </Box>
      </Box>
    );
  },
);

EditAssetTabs.displayName = 'EditAssetTabs';

export default EditAssetTabs;
