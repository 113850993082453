import { useTranslation } from "languages";

export const useGetSignalStrengthTranslated = () =>{
  const { translate } = useTranslation();
  const translationDictionary = {
    good: translate("signal_strength_good"),
    weak: translate( "signal_strength_weak"),
    bad: translate("signal_strength_bad"),
  };

    const getSignalStrengthTranslated = (signalString : string) => {
      const signalKey = signalString.toLowerCase()
      if (signalString && signalKey in translationDictionary) {
          return translationDictionary[signalKey as keyof typeof translationDictionary];
        }
        return signalString;
      }

    return getSignalStrengthTranslated;
}