import {
  Box,
  InputAdornment,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { fontMedium } from './styles/fontStyle';
import EditPlant from 'features/onboarding/components/EditPlant/EditPlant';
import DeletePlant from './DeletePlant';
import { GetPlantsBody } from 'api/plants/useGetPlants';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import TableCell from '@mui/material/TableCell';
import { Loading } from 'components';
import { useDeviceSize } from 'shared/responsive';
import { useTranslation } from 'languages';
import { useGetTranslatedUserRoles } from './useGetTranslatedUserRoles';

interface ManagePlantProps {
  plantList?: GetPlantsBody;
  email: string;
  page: number;
  searchInput: string;
  setSearchInput: (text: string) => void;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
  searchText: string;
  setSearchText: (text: string) => void;
  sortOrder: string;
  setSortOrder: (sortOrder: string) => void;
  isLoading: boolean;
  totalRecords: number | null;
}

export const useGetInitialPageCount = () => {
  const { isPhone, isTablet, isLandscape, isPortrait } = useDeviceSize();

  let initialPageCount;
  switch (true) {
    case isPhone:
      initialPageCount = 2;
      break;
    case isTablet && isLandscape:
      initialPageCount = 4;
      break;
    case isTablet && isPortrait:
      initialPageCount = 4;
      break;
    default:
      initialPageCount = 5;
      break;
  }
  return initialPageCount;
};

const ManagePlants: FC<ManagePlantProps> = ({
  plantList,
  email,
  page,
  pageSize,
  searchInput,
  setSearchInput,
  setPage,
  setPageSize,
  sortOrder,
  setSortOrder,
  isLoading,
  totalRecords,
}) => {
  const handleSearchText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchInput(event.target.value);
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (sort: string) => {
    if (sortOrder === sort) {
      setSortOrder(`${sort}_desc`);
    } else {
      setSortOrder(sort);
    }
    setPage(0);
  };

  const getTranslatedUserRoles = useGetTranslatedUserRoles();
  const { translate } = useTranslation();

  const componentText = {
    rowsPerPageLabel: translate('datatable_rows_per_page_label'),
    plantLabel: translate('menu_table_plant_label'),
    plantIdLabel: translate('menu_table_plantid_label'),
    roleLabel: translate('menu_table_role_label'),
    actionLabel: translate('menu_table_action_label'),
    searchPlaceholder: translate('search_placeholder'),
    of: translate('pagination_of_label')
  };

  const handleSortByPlantName = () => {
    handleSort('plantName');
  };

  const handleSortByPlantId = () => {
    handleSort('plantId');
  };

  const handleSortByRole = () => {
    handleSort('role');
  };

  const { isPhone, isTablet, isLandscape, isPortrait } = useDeviceSize();

  const initialTablePageCount = useGetInitialPageCount();

  const pageOptions = [initialTablePageCount, 6, 10, 25, 50, 100];

  const responsiveProps = {
    tableHeight: isPhone
      ? '10rem'
      : isTablet && isPortrait
        ? '16rem'
        : isTablet && isLandscape
          ? '16rem'
          : '22rem',
  };

  const mappedPlants = useMemo(
    () =>
      plantList?.records.map((plant) => (
        <TableRow
          key={plant.plantId}
          sx={{ display: 'table', tableLayout: 'fixed', width: '100%' }}
        >
          <TableCell
            sx={{ borderBottom: 'none', maxWidth: '8rem', wordWrap: 'break-word' }}
            align='left'
          >
            {plant.plantName}
          </TableCell>
          <TableCell sx={{ borderBottom: 'none' }} align='left'>
            {plant.plantId}
          </TableCell>
          <TableCell sx={{ borderBottom: 'none', textTransform: 'capitalize' }} align='left'>
            {plant.invitationStatus === 'pending' || plant.invitationStatus === 'invited'
              ? plant.invitationStatus
              : getTranslatedUserRoles(plant.role.replace('_', ' '))}
          </TableCell>
          <TableCell sx={{ borderBottom: 'none' }} align='left' data-testid={plant.plantName}>
            <Box ml={1.5}>
              {plant.invitationStatus === 'pending' || plant.invitationStatus === 'invited' ? (
                <DeletePlant selectedPlant={plant.plantId} />
              ) : (
                <EditPlant selectedPlant={plant.plantId} role={plant.role} email={email} />
              )}
            </Box>
          </TableCell>
        </TableRow>
      )),
    [plantList?.records, email],
  );
  return (
    <>
      <FormControl fullWidth>
        <TextField
          data-testid='manage_accounts_search_input'
          variant='outlined'
          placeholder={componentText.searchPlaceholder}
          value={searchInput}
          onChange={handleSearchText}
          InputProps={{
            sx: { height: '38px' },
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <TableContainer data-testid='manage_account_table_container'>
        <TableHead sx={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
          <TableRow>
            <TableCell align='left'>
              <TableSortLabel
                active={sortOrder.startsWith('plantName')}
                direction={sortOrder.endsWith('desc') ? 'desc' : 'asc'}
                onClick={handleSortByPlantName}
              >
                <Typography sx={{ ...fontMedium, fontWeight: 'bold' }}>
                  {componentText.plantLabel}
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align='left'>
              <TableSortLabel
                active={sortOrder.startsWith('plantId')}
                direction={sortOrder.endsWith('desc') ? 'desc' : 'asc'}
                onClick={handleSortByPlantId}
              >
                <Typography sx={{ ...fontMedium, fontWeight: 'bold' }}>
                  {componentText.plantIdLabel}
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align='left'>
              <TableSortLabel
                active={sortOrder.startsWith('role')}
                direction={sortOrder.endsWith('desc') ? 'desc' : 'asc'}
                onClick={handleSortByRole}
              >
                <Typography sx={{ ...fontMedium, fontWeight: 'bold' }}>
                  {componentText.roleLabel}
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell align='left'>
              <Typography sx={{ ...fontMedium, fontWeight: 'bold' }}>
                {componentText.actionLabel}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            display: 'block',
            overflowX: 'hidden',
            height: responsiveProps.tableHeight,
            overflowY: 'auto',
            paddingBottom: '1rem',
            '&::-webkit-scrollbar': { width: '5px' },
            '&::-webkit-scrollbar-thumb': { background: '#337179' },
            '&::-webkit-scrollbar-track-piece:start': {
              background: 'transparent',
              mt: '1rem',
              flexWrap: 'nowrap',
            },
          }}
        >
          {isLoading ? (
            <TableRow sx={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
              <TableCell colSpan={4} sx={{ borderBottom: 'none' }}>
                <Loading height='21.5rem' />
              </TableCell>
            </TableRow>
          ) : (
            mappedPlants
          )}
        </TableBody>
        <TableFooter sx={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
          <TableRow>
            <TablePagination
              labelRowsPerPage={componentText.rowsPerPageLabel}
              rowsPerPageOptions={pageOptions}
              count={totalRecords || 0}
              rowsPerPage={pageSize}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
              }}
              sx={{
                '.MuiTablePagination-toolbar': {
                  paddingLeft: 0.5,
                  marginTop: 1
                },
                borderBottom: 'none'
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={
                ({ from, to, count }) => {
                  return `${from}-${to} ${componentText.of} ${count}`
                }
              }
            />
          </TableRow>
        </TableFooter>
      </TableContainer>
    </>
  );
};

export default memo(ManagePlants);
