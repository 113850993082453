import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { KpiType } from 'models/sensor/models';
import moment from 'moment-timezone';
import { Asset, AssetGroup, KPI, Sensor, TreeState } from '../models';
import { Event } from '../models/Events.model';

export interface AssetsManagementState {
  selectedAssets: Asset[];
  // Selected sensors in AssetsTree
  selectedSensors: Sensor[];
  /**
   * Stores information about which KPI params are enabled for individual sensors.
   * @number contains sensor ID
   * @string contains KpiType param
   */
  activeKPIs: [number, KpiType[]][];
  // activeKPIs: Map<number, Set<KpiType>>;
  selectedAssetGroups: AssetGroup[];
  KPI: KPI[];
  treeState?: TreeState;
  activeAsset: string;
  activeSensor: number;
  activeRMS: string;
  activeUnit: string;
  activeStartDate: string;
  activeEndDate: string;
  plotDisplayMode: 'LANES' | 'OVERLAP';
  downloadFlag: boolean;
  isKpiDataLoading: boolean;
  isKpiNoData: boolean;
  events: Event[];
  kpiOrder: Array<string>;
  kpiEventsEnabled: boolean;
  kpiPlotExpanded: boolean;
  clickedEventId: string;
  selectedSensorTriAxisMode: boolean | null;
  actualReadingEnabled: boolean;
  showActualReading: boolean;
  selectedBearingParts: string[];
  numberOfHarmonics: number | undefined;
  marginRange: number;
  nominalSpeed: number;
  peakFactor?: number;
  peakSamples?: number;
  showSuggestedAlarmAlert: boolean;
  lastXdaysSelected: boolean;
  windowing: boolean;
  filterBeans: number;
  selectedMeasurements: string[];
  faultFrequencies: string[];
  windowingFunction: string;
  viewType: string;
  colorScale: string;
  selectedFFTSensor: Sensor | null;
  axis: string;
  runningSpeedFFT: number | null;
  tempNominalSpeed: string;
  initialMotorInfo: undefined | string;
  peakDetection: boolean;
  refreshDateRangeDisplay: boolean;
  isDowntimeSelected: boolean;
  showChart: boolean;
  disabledSettingsSaveButton: boolean;
  maxRunningSpeedValue : number;
  queueCollectRawData : Array<number>;
}
const currentDate = new Date();
const initialState: AssetsManagementState = {
  selectedAssets: [],
  selectedSensors: [],
  activeKPIs: [],
  selectedAssetGroups: [],
  KPI: [],
  treeState: { expanded: [] },

  activeAsset: '',
  activeSensor: 0,
  activeRMS: 'Velocity-Z',
  activeUnit: 'Hz',
  activeStartDate: moment(currentDate).subtract(7, 'd').toDate().toISOString(),
  activeEndDate: currentDate.toISOString(),
  plotDisplayMode: 'LANES',
  downloadFlag: false,
  isKpiDataLoading: true,
  isKpiNoData: true,
  events: [] as Event[],
  kpiOrder: [],
  kpiEventsEnabled: false,
  kpiPlotExpanded: false,
  clickedEventId: '',
  selectedSensorTriAxisMode: null,
  actualReadingEnabled: false,
  showActualReading: false,
  selectedBearingParts: ['roller'],
  numberOfHarmonics: 1,
  marginRange: 60,
  nominalSpeed: 0,
  showSuggestedAlarmAlert: true,
  lastXdaysSelected: true,
  windowing: false,
  filterBeans: 10,
  selectedMeasurements: [],
  faultFrequencies: ['runningSpeed'],
  windowingFunction: 'None',
  viewType: '3d',
  colorScale: 'Greys',
  selectedFFTSensor: null,
  axis: 'Z',
  runningSpeedFFT: null,
  initialMotorInfo: undefined,
  refreshDateRangeDisplay: false,
  peakDetection: false,
  tempNominalSpeed: '0',
  isDowntimeSelected: false,
  showChart: false,
  disabledSettingsSaveButton: false,
  maxRunningSpeedValue : 0,
  queueCollectRawData : []
};

export const assetsManagementSlice = createSlice({
  name: 'assetsManagement',
  initialState,
  reducers: {
    setSelectedSensors: (state, action: { payload: Sensor[] }) => {
      state.selectedSensors = action.payload;
    },
    pushSelectedSensors: (state, action) => {
      state.selectedSensors.push(action.payload);
    },
    setActiveKPIs: (state, action: { payload: [number, Set<KpiType>][]; type: string }) => {
      state.activeKPIs = Array.from(action.payload, ([key, value]) => [key, Array.from(value)]);
    },
    /**
     * Activates provided KPI parameter for specific sensor(id)
     */
    activateKPI: (state, action: { payload: { sensorId: number; kpi: KpiType } }) => {
      const { sensorId, kpi } = action.payload;

      const stateMap = new Map<number, Set<KpiType>>(
        state.activeKPIs.map(([key, val]) => [key, new Set(val)]),
      );

      const existing = stateMap.get(sensorId);
      if (existing) {
        existing.add(kpi);
      } else stateMap.set(sensorId, new Set<KpiType>([kpi]));

      state.activeKPIs = Array.from(stateMap, ([key, value]) => [key, Array.from(value)]);
    },
    /**
     * Deactivates provided KPI parameter for specific sensor(id)
     */
    deactivateKPI: (state, action: { payload: { sensorId: number; kpi: KpiType } }) => {
      const { sensorId, kpi } = action.payload;

      const stateMap = new Map<number, Set<KpiType>>(
        state.activeKPIs.map(([key, val]) => [key, new Set(val)]),
      );

      const existing = stateMap.get(sensorId);
      if (existing) existing.delete(kpi);

      state.activeKPIs = Array.from(stateMap, ([key, value]) => [key, Array.from(value)]);
    },
    setSelectedAssets: (state, action) => {
      state.selectedAssets = action.payload;
    },
    setSelectedAssetGroups: (state, action: { payload: AssetGroup[]; type: string }) => {
      state.selectedAssetGroups = action.payload;
    },
    pushSelectedAssets: (state, action) => {
      state.selectedAssets.push(action.payload);
    },
    setKPI: (state, action) => {
      state.KPI = action.payload;
    },
    setTreeState: (state, action) => {
      state.treeState = action.payload;
    },
    setPlotDisplayMode: (state, action) => {
      state.plotDisplayMode = action.payload;
    },
    setDownloadFlag: (state, action) => {
      state.downloadFlag = action.payload;
    },
    setIsKpiDataLoading: (state, action) => {
      state.isKpiDataLoading = action.payload;
    },
    setKpiPlotExpanded: (state, action: { payload: boolean; type: string }) => {
      state.kpiPlotExpanded = action.payload;
    },

    setActiveAsset: (state, action) => {
      state.activeAsset = action.payload;
    },
    setActiveSensor: (state, action) => {
      state.activeSensor = action.payload;
    },
    setActiveRMS: (state, action) => {
      state.activeRMS = action.payload;
    },
    setActiveUnit: (state, action) => {
      state.activeUnit = action.payload;
    },
    setActiveStartDate: (state, action) => {
      state.activeStartDate = action.payload;
    },
    setActiveEndDate: (state, action) => {
      state.activeEndDate = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setKpiOrder: (state, action) => {
      state.kpiOrder = action.payload;
    },
    setKpiEventsEnabled: (state, action) => {
      state.kpiEventsEnabled = action.payload;
    },
    setClickedEventId: (state, action) => {
      state.clickedEventId = action.payload;
    },
    setSelectedSensorTriAxisMode: (state, action) => {
      state.selectedSensorTriAxisMode = action.payload;
    },
    setActualReadingEnabled: (state, action) => {
      state.actualReadingEnabled = action.payload;
    },
    setShowActualReading: (state, action) => {
      state.showActualReading = action.payload;
    },
    setSelectedBearingParts: (state, action) => {
      state.selectedBearingParts = action.payload;
    },
    setQueueCollectRawData: (state, action) => {
      state.queueCollectRawData.push(action.payload);
    },
    setClearQueueCollectRawData: (state) => {
      state.queueCollectRawData = [];
    },
    setDeleteSensorQueueCollectRawData: (state, action) => {
      state.queueCollectRawData = state.queueCollectRawData.filter((sensorId) => sensorId !== action.payload);
    },
    setNumberOfHarmonics: (state, action) => {
      state.numberOfHarmonics = action.payload;
    },
    setMarginRange: (state, action) => {
      state.marginRange = action.payload;
    },
    setNominalSpeed: (state, action) => {
      state.nominalSpeed = action.payload;
    },
    setPeakFactor: (state, action) => {
      state.peakFactor = action.payload;
    },
    setPeakSamples: (state, action) => {
      state.peakSamples = action.payload;
    },
    setShowSuggestedAlarmAlert: (state, action) => {
      state.showSuggestedAlarmAlert = action.payload;
    },
    setLastXdaysSelected: (state, action) => {
      state.lastXdaysSelected = action.payload;
    },
    setWindowing: (state, action) => {
      state.windowing = action.payload;
    },
    setFilterBeans: (state, action) => {
      state.filterBeans = action.payload;
    },
    setSelectedMeasurements: (state, action) => {
      state.selectedMeasurements = action.payload;
    },
    setFaultFrequencies: (state, action) => {
      state.faultFrequencies = action.payload;
    },
    setWindowingFunction: (state, action) => {
      state.windowingFunction = action.payload;
    },
    setViewType: (state, action) => {
      state.viewType = action.payload;
    },
    setColorScale: (state, action) => {
      state.colorScale = action.payload;
    },
    setSelectedFFTSensor: (state, action) => {
      state.selectedFFTSensor = action.payload;
    },
    setAxis: (state, action) => {
      state.axis = action.payload;
    },
    setRunningSpeedFFT: (state, action) => {
      state.runningSpeedFFT = action.payload;
    },
    setInitialmotorInfo: (state, action) => {
      state.initialMotorInfo = action.payload;
    },
    setRefreshDateRangeDisplay: (state, action) => {
      state.refreshDateRangeDisplay = action.payload;
    },
    setPeakDetection: (state, action) => {
      state.peakDetection = action.payload;
    },
    setTempNominalSpeed: (state, action) => {
      state.tempNominalSpeed = action.payload;
    },
    setIsDowntimeSelected: (state, action) => {
      state.isDowntimeSelected = action.payload;
    },
    setShowChart: (state, action) => {
      state.showChart = action.payload;
    },
    setIsKpiNoData: (state, action: PayloadAction<boolean>) => {
      state.isKpiNoData = action.payload;
    },
    setDisabledSettingsSaveButton: (state, action: PayloadAction<boolean>) => {
      state.disabledSettingsSaveButton = action.payload;
    },
    setMaxRunningSpeedValue: (state, action: PayloadAction<number>) => {
      state.maxRunningSpeedValue = action.payload;
    },
  },
});

export const {
  setSelectedSensors,
  setActiveKPIs,
  activateKPI,
  deactivateKPI,
  setKPI,
  pushSelectedSensors,
  setSelectedAssets,
  setSelectedAssetGroups,
  pushSelectedAssets,
  setTreeState,
  setActiveAsset,
  setActiveSensor,
  setActiveRMS,
  setActiveUnit,
  setActiveStartDate,
  setActiveEndDate,
  setPlotDisplayMode,
  setDownloadFlag,
  setIsKpiDataLoading,
  setEvents,
  setKpiOrder,
  setKpiEventsEnabled,
  setClickedEventId,
  setSelectedSensorTriAxisMode,
  setActualReadingEnabled,
  setShowActualReading,
  setSelectedBearingParts,
  setNumberOfHarmonics,
  setMarginRange,
  setNominalSpeed,
  setPeakFactor,
  setPeakSamples,
  setShowSuggestedAlarmAlert,
  setLastXdaysSelected,
  setWindowing,
  setFilterBeans,
  setSelectedMeasurements,
  setFaultFrequencies,
  setWindowingFunction,
  setViewType,
  setColorScale,
  setSelectedFFTSensor,
  setAxis,
  setRunningSpeedFFT,
  setInitialmotorInfo,
  setKpiPlotExpanded,
  setRefreshDateRangeDisplay,
  setPeakDetection,
  setTempNominalSpeed,
  setIsDowntimeSelected,
  setShowChart,
  setIsKpiNoData,
  setDisabledSettingsSaveButton,
  setMaxRunningSpeedValue,
  setQueueCollectRawData,
  setClearQueueCollectRawData,
  setDeleteSensorQueueCollectRawData,
} = assetsManagementSlice.actions;

export const selectSelectedSensors = (state: RootState) => state.assetsManagement.selectedSensors;
export const selectActiveKPIs = (state: RootState) =>
  new Map(state.assetsManagement.activeKPIs.map(([key, val]) => [key, new Set<KpiType>(val)]));
export const selectKpiPlotExpanded = (state: RootState) => state.assetsManagement.kpiPlotExpanded;
export const selectKPI = (state: RootState) => state.assetsManagement.KPI;
export const selectSelectedAssets = (state: RootState) => state.assetsManagement.selectedAssets;
export const selectSelectedAssetGroups = (state: RootState) =>
  state.assetsManagement.selectedAssetGroups;
export const selectTreeState = (state: RootState) => state.assetsManagement.treeState;
export const selectPlotDisplayMode = (state: RootState) => state.assetsManagement.plotDisplayMode;
export const selectDownloadFlag = (state: RootState) => state.assetsManagement.downloadFlag;
export const selectIsKpiDataLoading = (state: RootState) => state.assetsManagement.isKpiDataLoading;
export const selectActiveAsset = (state: RootState) => state.assetsManagement.activeAsset;
export const selectActiveSensor = (state: RootState) => state.assetsManagement.activeSensor;
export const selectActiveRMS = (state: RootState) => state.assetsManagement.activeRMS;
export const selectActiveUnit = (state: RootState) => state.assetsManagement.activeUnit;
export const selectStartDate = (state: RootState) => state.assetsManagement.activeStartDate;
export const selectEndDate = (state: RootState) => state.assetsManagement.activeEndDate;
export const selectEvents = (state: RootState) => state.assetsManagement.events;
export const selectKpiOrder = (state: RootState) => state.assetsManagement.kpiOrder;
export const selectKpiEventsEnabled = (state: RootState) => state.assetsManagement.kpiEventsEnabled;
export const selectClickedEventId = (state: RootState) => state.assetsManagement.clickedEventId;
export const selectSelectedSensorTriAxisMode = (state: RootState) =>
  state.assetsManagement.selectedSensorTriAxisMode;
export const selectActualReadingEnabled = (state: RootState) =>
  state.assetsManagement.actualReadingEnabled;
export const selectShowActualReading = (state: RootState) =>
  state.assetsManagement.showActualReading;
export const selectSelectedBearingParts = (state: RootState) =>
  state.assetsManagement.selectedBearingParts;
export const selectNumberOfHarmonics = (state: RootState) =>
  state.assetsManagement.numberOfHarmonics;
export const selectMarginRange = (state: RootState) => state.assetsManagement.marginRange;
export const selectNominalSpeed = (state: RootState) => state.assetsManagement.nominalSpeed;
export const selectPeakFactor = (state: RootState) => state.assetsManagement.peakFactor;
export const selectPeakSamples = (state: RootState) => state.assetsManagement.peakSamples;
export const selectShowSuggestedAlarmAlert = (state: RootState) =>
  state.assetsManagement.showSuggestedAlarmAlert;
export const selectLastXdaysSelected = (state: RootState) =>
  state.assetsManagement.lastXdaysSelected;
export const selectWindowing = (state: RootState) => state.assetsManagement.windowing;
export const selectFilterBeans = (state: RootState) => state.assetsManagement.filterBeans;
export const selectedMeasurements = (state: RootState) =>
  state.assetsManagement.selectedMeasurements;
export const selectFaultFrequencies = (state: RootState) => state.assetsManagement.faultFrequencies;
export const selectWindowingFunction = (state: RootState) =>
  state.assetsManagement.windowingFunction;
export const selectViewType = (state: RootState) => state.assetsManagement.viewType;
export const selectColorScale = (state: RootState) => state.assetsManagement.colorScale;
export const selectFFTSensor = (state: RootState) => state.assetsManagement.selectedFFTSensor;
export const selectAxis = (state: RootState) => state.assetsManagement.axis;
export const selectInitialMotorInfo = (state: RootState) => state.assetsManagement.initialMotorInfo;
export const selectRunningSpeedFFT = (state: RootState) => state.assetsManagement.runningSpeedFFT;
export const selectRefreshDateRangeDisplay = (state: RootState) =>
  state.assetsManagement.refreshDateRangeDisplay;
export const selectPeakDetection = (state: RootState) => state.assetsManagement.peakDetection;
export const selectTempNominalSpeed = (state: RootState) => state.assetsManagement.tempNominalSpeed;
export const selectIsDowntimeSelected = (state: RootState) =>
  state.assetsManagement.isDowntimeSelected;
export const selectShowChart = (state: RootState) => state.assetsManagement.showChart;
export const selectIsKpiNoData = (state: RootState) => state.assetsManagement.isKpiNoData;
export const selectDisabledSettingsSaveButton = (state: RootState) => state.assetsManagement.disabledSettingsSaveButton;
export const selectMaxRunningSpeedValue = (state: RootState) => state.assetsManagement.maxRunningSpeedValue;
export const selectQueueCollectRawData = (state: RootState) => state.assetsManagement.queueCollectRawData;

export default assetsManagementSlice.reducer;
