import us from '../icons/Flags/us.svg'
import es from '../icons/Flags/es.svg'

export  const languages = [
  { value: 'en', icon: us, label: 'English' },
  { value: 'es', icon: es, label: 'Spanish' },
];




export type PersonalInfo = {
  name: string;
  surname: string;
}