import { FC, useMemo, useState } from 'react';
import { SensorInfo } from './MissingCartridge';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SensorData from './SensorData';
import { useClearCartridgeFlag } from 'api/assets/useClearCartridgeFlag';
import { useReplaceCartridge } from 'api/assets/useReplaceCartridge';
import { useTranslation } from 'languages';

type Props = {
  onClose: () => void;
  data?: SensorInfo;
};

const cartridgeSizeList = [
  { partNumber: '750015', label: 'OB1' },
  { partNumber: '750017', label: 'OB4' },
];
const BreatherCartridgeSize: FC<Props> = ({ data, onClose }) => {
  const [cartridgeSize, setCartridgeSize] = useState('750015');

  const { mutate, isPending } = useClearCartridgeFlag(onClose);
  const { mutate: mutateChangeCartridge, isPending: loading } = useReplaceCartridge(
    cartridgeSize === '750015' ? 'OB1' : 'OB4',
    onClose,
    data?.type,
  );

  const handleClearFlag = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    mutate({
      params: {
        serialNumber: data!.serialNumber,
        assetId: data!.assetId,
      },
    });
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (data && cartridgeSize) {
      mutateChangeCartridge({
        params: {
          serialNumber: data?.serialNumber,
          assetId: data?.assetId,
        },
        body: {
          properties: {},
          additionalProperties: {
            cartridgePN: cartridgeSize,
          },
        },
      });
    }
  };

  const {translate} = useTranslation()
  const componentText = useMemo(() =>{
    return({
      selectTypeInfo : translate("select_cartridge_type1"),
      selectSizeInfo :translate("select_cartridge_size1"),
      selectType: translate("select_cartridge_type2"),
      selectSize: translate("select_cartridge_size2"),
      confirm: translate("yes_confirm"),
   })
  }, [])

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={1}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        console.log(cartridgeSize);
      }}
    >
      <Typography fontSize='1.5rem' fontWeight='600' color='primary'>
        {data?.type === 'BreatherSensor'
          ?  componentText.selectTypeInfo
          :  componentText.selectSizeInfo}
      </Typography>
      <Divider />
      <SensorData data={data} />
      <Divider />
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id='select-label'>
          {data?.type === 'BreatherSensor' ? componentText.selectType : componentText.selectSize}
        </InputLabel>
        <Select
          labelId='select-label'
          id='select'
          value={cartridgeSize}
          label={
            data?.type === 'BreatherSensor' ? componentText.selectType : componentText.selectSize
          }
          onChange={(e: SelectChangeEvent<string>) => {
            setCartridgeSize(e.target.value);
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          size='small'
        >
          {cartridgeSizeList.map((item) => (
            <MenuItem value={item.partNumber}>
              <Box display='flex' gap={1}>
                <Typography>{item.label}</Typography>
                <Typography color='silver'>{`PN: ${item.partNumber}`}</Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider />
      <Box display='flex' justifyContent='space-between' mt={2}>
        <LoadingButton
          variant='outlined'
          color='secondary'
          sx={{ fontWeight: '600', color: 'black' }}
          onClick={handleClearFlag}
          disabled={!data}
          loading={isPending}
        >
          No
        </LoadingButton>
        <LoadingButton
          variant='contained'
          color='secondary'
          sx={{ fontWeight: '600', color: 'black' }}
          disabled={!data}
          onClick={handleConfirm}
          loading={loading}
        >
          {componentText.confirm}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default BreatherCartridgeSize;
