import NotificationsPausedOutlinedIcon from '@mui/icons-material/NotificationsPausedOutlined';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { useGetFFTDates } from 'api/assets/useGetFFTDates';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import DateRange from 'features/assets-management/components/DateRange/DateRange';
import { Measurement } from 'features/assets-management/models/FFTChart.models';
import {
  selectedMeasurements,
  setIsDowntimeSelected,
  setRunningSpeedFFT,
  setSelectedMeasurements,
  setShowChart,
} from 'features/assets-management/store/assetsManagementSlice';
import { tooltipStyle } from 'features/dashboard/components/AdditionalErrors/SyncError';
import { SensorType } from 'models/sensor/models';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fftBackgroundColor } from '../VibrationFFTPage';
import Settings from './Settings';
import { useTranslation } from 'languages';

export const scrollBar = {
  '&::-webkit-scrollbar': { width: '5px' },
  '&::-webkit-scrollbar-thumb': { background: '#337179' },
  '&::-webkit-scrollbar-track-piece:start': {
    background: 'transparent',
  },
};
const formGroupStyles = {
  border: '1px solid rgba(0, 0, 0, 0.25)',
  borderRadius: '.25rem',
  maxHeight: '16.3rem',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  overflow: 'auto',
  ...scrollBar,
};
export const checkboxStyles = { '&:hover': { background: 'transparent !important' }, ml: 1 };
const formLabelStyles = {
  fontSize: '.75rem',
  position: 'absolute',
  top: '-.5rem',
  left: '.7rem',
  px: 0.7,
};
const formControlLabelStyles = { ml: -1, mr: 0 };

type Props = {
  excludedSensorTypes: SensorType[];
  selectedSensorId: number;
  selectedFrom: string;
  selectedTo: string;
  selectedAssetId: number;
  selectedFFTType: string;
  nominalSpeed: number;
};

const MeasurementSettings: FC<Props> = ({
  excludedSensorTypes,
  selectedSensorId,
  selectedFrom,
  selectedTo,
  selectedAssetId,
  selectedFFTType,
  nominalSpeed,
}) => {
  const dispatch = useDispatch();
  const dateFormatter = useDateFormatter();

  const checkedMeasurements = useSelector(selectedMeasurements);

  const [fftType, selectedAxis] = useMemo(() => {
    const splittedType = selectedFFTType.split('-');
    return splittedType.length > 1 ? splittedType : [splittedType[0], 'Z'];
  }, [selectedFFTType]);

  const { data: measurementDates = [], isLoading } = useGetFFTDates(
    selectedAssetId,
    selectedSensorId,
    selectedFrom,
    selectedTo,
    selectedAxis,
    excludedSensorTypes,
  );

  const [showOnlySelectedMeasurements, setShowOnlySelectedMeasurements] = useState<boolean>(false);

  useEffect(() =>{
  measurementDates.length > 0 ? dispatch(setShowChart(true)) : dispatch(setShowChart(false))
  }, [measurementDates]);

  useEffect(() => { 
    if (checkedMeasurements.length === 0 || measurementDates.length === 0) {
      return;
    }

    if (
      !checkedMeasurements.every((measurement) =>
        measurementDates.some((m: Measurement) => m.date === measurement),
      )
    ) {
      dispatch(setSelectedMeasurements([]));
    }
  }, [checkedMeasurements, measurementDates]);

  useEffect(() => {
    // dispatch(setSelectedMeasurements([]));
    setShowOnlySelectedMeasurements(false);
  }, [selectedSensorId, selectedFrom, selectedTo]);

  const visibleMeasurement = useMemo(() => {
    // if (selectedFFTType.includes('envelop') && nominalSpeed < 500) return [];

    if (showOnlySelectedMeasurements) {
      return measurementDates.filter((filter: Measurement) =>
        checkedMeasurements.some((m) => m === filter.date),
      );
    } else {
      return measurementDates;
    }
  }, [
    showOnlySelectedMeasurements,
    measurementDates,
    selectedFFTType,
    nominalSpeed,
    selectedSensorId,
  ]);

  const toggleAllMeasurements = (checked: boolean) => {
    if (checked) {
      dispatch(setSelectedMeasurements(measurementDates.map((item: Measurement) => item.date)));
      dispatch(setRunningSpeedFFT(measurementDates[0].nominalSpeed));
      dispatch(setIsDowntimeSelected(measurementDates.every((item: Measurement) => item.downtime)));
    } else {
      dispatch(setSelectedMeasurements([]));
      dispatch(setRunningSpeedFFT(null));
      dispatch(setIsDowntimeSelected(false));
    }
  };

  const toggleMeasurement = (measurement: string, checked: boolean, speed: number) => {
    if (checked) {
      dispatch(setSelectedMeasurements(checkedMeasurements.concat([measurement])));
      dispatch(setRunningSpeedFFT(speed));
    } else {
      dispatch(
        setSelectedMeasurements(
          checkedMeasurements.filter((filter: string) => filter !== measurement),
        ),
      );
      checkedMeasurements.length > 1
        ? dispatch(setRunningSpeedFFT(measurementDates[0].nominalSpeed))
        : dispatch(setRunningSpeedFFT(null));
    }
  };

  useEffect(() => {
    checkedMeasurements.length === 1 &&
      dispatch(
        setIsDowntimeSelected(
          measurementDates.find((item: Measurement) => item.date === checkedMeasurements[0])
            ?.downtime ?? false,
        ),
      );
  }, [checkedMeasurements]);

  const toggleShow = () => {
    setShowOnlySelectedMeasurements((state: boolean) => !state);
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      fftMeasurementDate: translate("fft_measurement_date"),
      fftSelectMeasurement: translate("fft_select_measurement"),
      fftNoOptions: translate("fft_no_options"),
      fftShowOnlySelectedMeasurements: translate("fft_show_only_selected_measurements"),
      fftAll: translate("status_all"),
      fftTextDowntime: translate("fft_text_downtime"),
      formatDate : translate("measurement_format_date")
    };
  }, []);
  
  return (
    <Settings title={componentText.fftMeasurementDate}>
      <React.Fragment>
        <Grid item>
          <DateRange background='white' isFromKPITrend={true} />
        </Grid>
        <Grid item mt='-1rem'>
          <FormControl
            fullWidth
            variant='standard'
            sx={{ position: 'relative', background: 'white' }}
          >
            <FormLabel
              sx={{
                ...formLabelStyles,
                background: `linear-gradient(0deg, rgba(255,255,255,1) 50%, ${fftBackgroundColor} 51%)`,
              }}
            >
              {componentText.fftSelectMeasurement}
            </FormLabel>
            <FormGroup sx={formGroupStyles}>
              {visibleMeasurement && visibleMeasurement.length ? (
                <FormControlLabel
                  key='All'
                  checked={checkedMeasurements.length === measurementDates.length}
                  label={componentText.fftAll}
                  control={<Checkbox disableRipple sx={checkboxStyles} />}
                  onChange={(_, checked) => toggleAllMeasurements(checked)}
                  ///disabled={selectedFFTType.includes('envelope') ? true : false}
                  sx={{ ...formControlLabelStyles, mt: 0.8 }}
                />
              ) : isLoading ? (
                <Box display='flex' justifyContent='center' alignItems='center' p={1.2}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <Typography p={1.2}>{componentText.fftNoOptions}</Typography>
              )}
              {visibleMeasurement.map((measurement: Measurement, i: number) => (
                <FormControlLabel
                  data-testid='assets_management_vibration_fft_select_measurement'
                  key={measurement.date}
                  checked={checkedMeasurements.some(
                    (checkedMeasuremet) => checkedMeasuremet === measurement.date,
                  )}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minWidth: '100%',
                      }}
                    >
                      <Box>{dateFormatter(measurement.date, componentText.formatDate)}</Box>
                      {measurement.downtime && (
                        <Box mr={1} mt={0.4}>
                          <Tooltip
                            title={componentText.fftTextDowntime}
                            placement='top'
                            componentsProps={{ tooltip: { sx: tooltipStyle } }}
                          >
                            <NotificationsPausedOutlinedIcon
                              sx={{ color: '#7e8282', fontSize: '1.35rem' }}
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  }
                  control={<Checkbox disableRipple sx={checkboxStyles} />}
                  onChange={(_, checked) =>
                    toggleMeasurement(measurement.date, checked, measurement.nominalSpeed)
                  }
                  // disabled={
                  //   fftType && selectedFFTType.includes('envelope')
                  //     ? checkedMeasurements.length > 0 &&
                  //       !checkedMeasurements.some(
                  //         (checkedMeasuremet) => checkedMeasuremet === measurement.date,
                  //       )
                  //     : false
                  // }
                  sx={{
                    ...formControlLabelStyles,
                    background: checkedMeasurements.some(
                      (checkedMeasuremet) => checkedMeasuremet === measurement.date,
                    )
                      ? 'rgba(0, 0, 0, 0.05)'
                      : 'inherit',
                    '& .MuiFormControlLabel-label': { width: '100%' },
                  }}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControlLabel
            data-testid='assets_management_vibration_fft_checkbox_selected_measurements'
            checked={showOnlySelectedMeasurements}
            label={componentText.fftShowOnlySelectedMeasurements}
            control={<Checkbox disableRipple sx={checkboxStyles} />}
            onChange={() => toggleShow()}
            sx={{ '& .MuiTypography-root': { fontSize: '.75rem' }, ml: -1 }}
          />
        </Grid>
      </React.Fragment>
    </Settings>
  );
};

export default MeasurementSettings;
