import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'languages';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useDeviceSize } from 'shared/responsive';
import { useLocalStorage } from 'storage/localStorage';
import { selectChannelDetails, selectNavbarOpen, setNavbarOpen } from 'store/accountSlice';
import { Bar } from '../Bar';
import style from './LeftNavbar.scss';
import AssetIcon from './NavbarIcons/AssetsIcon.svg';
import CartridgeIcon from './NavbarIcons/CartridgeIcon.svg';
import ChannelPartnersIcon from './NavbarIcons/ChannelPartnersIcon.svg';
import DashboardIcon from './NavbarIcons/DashboardIcon.svg';
import GatewayIcon from './NavbarIcons/GatewayIcon.svg';
import PlantIcon from './NavbarIcons/PlantIcon.svg';
import dodgeSmall from './images/D-DDG-RGB_White.png';
import dodge from './images/DDG-RGB_White.png';
import logo from './images/optify_logo.png';

const useTranslateTitles = () => {
  const { translate } = useTranslation();

  const isChannelUser = useSelector(selectChannelDetails);

  const componentText = {
    dashboard: translate('navbar_dashboard_label'),
    assets: translate('navbar_assets_management_label'),
    plants: translate('navbar_plants_management_label'),
    gateways: translate('navbar_gateways_management_label'),
    cartridges: translate('navbar_cartridges_management_label'),
    channelPartners: translate('navbar_channel_partners_management_label'),
  };

  const redirections = [
    {
      icon: <img src={DashboardIcon} alt='dashboard icon' />,
      path: 'dashboard',
      text: componentText.dashboard.toUpperCase(),
      testId: 'dashboard_icon',
    },
    {
      icon: <img src={AssetIcon} alt='asset icon' />,
      path: 'assets-management',
      text: componentText.assets.toUpperCase(),
      testId: 'data_icon',
    },
    {
      icon: <img src={PlantIcon} alt='plant icon' />,
      path: 'plants-management',
      text: componentText.plants.toUpperCase(),
      testId: 'plant_icon',
    },
    {
      icon: <img src={GatewayIcon} alt='gateway icon' />,
      path: 'gateways-management',
      text: componentText.gateways.toUpperCase(),
      testId: 'gateway_icon',
    },
    {
      icon: <img src={CartridgeIcon} alt='cartridge icon' />,
      path: 'cartridges-management',
      text: componentText.cartridges.toUpperCase(),
      testId: 'cartridge_icon',
    },
  ];

  if (isChannelUser)
    redirections.push({
      icon: <img src={ChannelPartnersIcon} alt='channel partners icon' />,
      path: 'channel-partners',
      text: componentText.channelPartners.toUpperCase(),
      testId: 'channel_partners_icon',
    });
  return { redirections };
};

const drawerWidth = 170;

const key = 'isNavbarOpened';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme, open, matches) => {
  return {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    display: matches ? (open ? 'flex' : 'none') : 'flex',
  };
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, matches }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    // !matches && {
    (matches === 'false') && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${60}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, matches }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open &&
    matches === 'false' && {
    // !matches && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
  ...((!open || (open && matches === 'true')) && {
    ...closedMixin(theme, open, matches === 'true'),
    '& .MuiDrawer-paper': closedMixin(theme, open, matches === 'true'),
  // ...((!open || (open && matches)) && {
  //   ...closedMixin(theme, open, matches),
  //   '& .MuiDrawer-paper': closedMixin(theme, open, matches),
  }),
}));

const LeftNavbar = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const open = useSelector(selectNavbarOpen);
  const [value, setValue] = useLocalStorage(key, false);

  const handleDrawerOpen = () => {
    dispatch(setNavbarOpen(true));
    setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);
  };

  React.useEffect(() => {
    dispatch(setNavbarOpen(value));
  }, []);

  React.useEffect(() => {
    setValue(open);
  }, [open]);

  const handleDrawerClose = () => {
    dispatch(setNavbarOpen(false));
    setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const { isXSPhone, isPhone, isTablet } = useDeviceSize();
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const responsiveProps = {
    imageShow: isXSPhone ? { display: 'none' } : {},
    imageSize: isPhone ? { width: '4rem' } : isTablet ? { width: '6rem' } : {},
    buttonMarginRight: isPhone || isTablet ? 0 : 2,
    imageLeftPadding: isPhone ? 0 : isTablet ? 2 : 2,
  };

  const { redirections } = useTranslateTitles();

  const icons = redirections.map((direction) => (
    <Link
      to={'/' + direction.path}
      sx={{ color: 'white !important' }}
      className={style.a}
      key={direction.path}
      data-testid={direction.testId}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
          borderRight:
            splitLocation[1] === direction.path ? '4px solid #F0B323' : '4px solid #004851',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 0 : 'auto',
              justifyContent: 'center',
              color: splitLocation[1] === direction.path ? 'white' : 'silver',
            }}
          >
            {direction.icon}
          </ListItemIcon>
          <ListItemText
            primary={direction.text}
            sx={{
              display: open && !matches ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              wordBreak: 'break-word',
              color: splitLocation[1] === direction.path ? 'white' : 'silver',
            }}
          />
        </Box>
      </ListItemButton>
    </Link>
  ));

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        sx={{
          backgroundColor: '#004851',
          boxShadow: 'none',
          [theme.breakpoints.down('md')]: { p: '0 !important', m: '0 !important' },
        }}
        open={open}
        // matches={matches}
        matches={matches.toString()}
      >
        <Toolbar sx={{ maxHeight: '4rem', width: '100vw' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: responsiveProps.buttonMarginRight,
              ...(open && !matches && { display: 'none' }),
              [theme.breakpoints.between('xs', 'sm')]: { p: '0.9rem' },
            }}
            data-testid='open_drawer_icon'
          >
            {open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Typography
            sx={{ pr: 2, pl: responsiveProps.imageLeftPadding, ...responsiveProps.imageShow }}
          >
            <Link to='/dashboard'>
              <img src={logo} alt='OptifyLogo' style={{ ...responsiveProps.imageSize }} />
            </Link>
          </Typography>
          <Bar />
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={open}
        matches={matches.toString()}
        sx={{ '& .MuiDrawer-paper': { border: 0 }, position: 'relative' }}
      >
        <DrawerHeader sx={{ justifyContent: 'center' }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <MenuOpenIcon sx={{ color: 'white', ml: '-5rem' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ marginBottom: '16px' }} />
        {icons}

        <Box
          sx={{
            position: 'absolute',
            bottom: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <a target='_blank' href='https://dodgeindustrial.com/'>
            <img src={open && !matches ? dodge : dodgeSmall} alt='Logo' className={style.img} />
          </a>
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 2, pt: 8, width: '100%', ml: 'auto', mr: 'auto', minWidth: '0' }}>
        {children}
      </Box>
    </Box>
  );
};

export default React.memo(LeftNavbar);
