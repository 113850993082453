import { Box, Divider, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { SensorInfo, Step } from "./MissingCartridge";
import SensorData from "./SensorData";
import QrCodeReader from "../AssetsTable/EditAsset/QrCodeReader";
import { useTranslation } from "languages";

type Props = {
    setQrCode: Dispatch<SetStateAction<string>>;
    setActiveStep: (newStep: Step) => void;
    data?: SensorInfo,
}

const ScanQrCode:FC<Props> = ({setActiveStep, data, setQrCode}) =>{

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setActiveStep(1)
  };
  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setActiveStep(3)
  };

  const {translate} = useTranslation()
  const componentText = useMemo(() =>{
    return({
      scanQRcode: translate("plants_scan_qr_code_label")
   })
  }, [])

    return (
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography fontSize='1.5rem' fontWeight='600' color='primary'>{componentText.scanQRcode}</Typography>
          <Divider />
          <SensorData data={data}/>
          <Box>
          <QrCodeReader setQrCode={setQrCode} setCustomBack={handleBack} setCustomNext={handleNext} videoWidth={1} marginTop="-4rem !important" />
          </Box>
        </Box>
    )
}

export default ScanQrCode;