import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAuthMutation } from './../queries';
import { useTranslation } from 'languages';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetCollectRawData } from './useGetCollectRawData';
import {
  selectQueueCollectRawData,
  setClearQueueCollectRawData,
  setDeleteSensorQueueCollectRawData,
  setQueueCollectRawData,
} from 'features/assets-management/store/assetsManagementSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

export interface PostCollectRawDataParams {
  assetId?: number;
  sensorId?: number;
}

export type GetDataResponse = {
  rawDataJobStatus: string;
};

export const usePostCollectRawData = ({ assetId, sensorId }: PostCollectRawDataParams) => {
  const { translate } = useTranslation();
  const intervalRef = useRef<any>();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const prevLocationRef = useRef(location);
  const queueCollectRawData = useSelector(selectQueueCollectRawData);

  const isCollecting = sensorId && queueCollectRawData.includes(sensorId);

  useEffect(() => {
    if (prevLocationRef.current !== location) {
      prevLocationRef.current = location;
      return () => {
        clearInterval(intervalRef.current);
        dispatch(setClearQueueCollectRawData());
      };
    }
  }, [location]);

  const componentText = useMemo(() => {
    return {
      successToast: translate('successfully_started_requested_raw_data_toast'),
      errorToast: translate('could_not_request_collect_raw_data_toast'),
    };
  }, []);

  const cancelRequest = (sensorId: number) => {
    dispatch(setDeleteSensorQueueCollectRawData(sensorId));
  };

  const onSuccess = (data: GetDataResponse) => {
    if (data.rawDataJobStatus === 'Success') {
      toast.success(componentText.successToast);
      dispatch(setDeleteSensorQueueCollectRawData(sensorId));
      setTimeout(() => {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes('fft-dates'),
        });
      }, 3000);
    } else if (data.rawDataJobStatus === 'Failure') {
      toast.error(componentText.errorToast);
      dispatch(setDeleteSensorQueueCollectRawData(sensorId));
    }
  };

  const { isSuccess, data } = useGetCollectRawData({
    assetId: assetId ? assetId : 0,
    sensorId: sensorId ? sensorId : 0,
    enabled: isCollecting,
  });

  useEffect(() => {
    if (isSuccess && data) {
      onSuccess(data);
    }
  }, [data, isSuccess]);

  const collectRawData = useAuthMutation<PostCollectRawDataParams>({
    url: (params: PostCollectRawDataParams) =>
      `assets/${params.assetId}/sensors/${params.sensorId}/raw-data`,
    method: 'POST',
    mutationOptions: {
      onSuccess: (data, payload) => {
        toast.success(componentText.successToast);
        if (data.status === 202) {
          dispatch(setQueueCollectRawData(payload.params && payload.params.sensorId));
        }
      },
      onError: (_, payload) => {
        toast.error(componentText.errorToast);
        dispatch(setDeleteSensorQueueCollectRawData(payload.params && payload.params.sensorId));
      },
    },
  });

  return { collectRawData, cancelRequest };
};
