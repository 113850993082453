import { Box } from "@mui/material";
import { ReactNode} from "react";

export interface DataTableChartBarProps {
  row?: ReactNode;
}

const DataTableChartBar = (
  props: DataTableChartBarProps
) => {
  return <Box>{props.row}</Box>;
};

export default DataTableChartBar;