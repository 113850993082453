import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { alarmColor } from 'features/dashboard/colors/colors';
import SensorData from './SensorData';
import { SensorInfo } from './MissingCartridge';
import { useGetSensorsByAssetId } from 'api/plants/useGetSensorsByAssetId';
import { getCatrigeSize } from '../CommissionsSensors/IntellilubeCommissionModal';
import { AdditionalProperties } from 'features/plants-management/models/sensor.model';
import { useReplaceCartridge } from 'api/assets/useReplaceCartridge';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'languages';

type Props = {
  onClose: () => void;
  data?: SensorInfo;
  qrCode: string;
};

const getAdditionalProperties = (qrCode: string) => {
  if (!qrCode) return null;
  else {
    const arrayQrCode = qrCode.split('-');
    return {
      cartridgePN: arrayQrCode[3],
      cartridgeBN: arrayQrCode[2],
      cartridgeFillDate: arrayQrCode[1],
      cartridgeQRcode: qrCode,
    };
  }
};
const CartridgeSize: FC<Props> = ({ onClose, data, qrCode }) => {
  const { mutate, isPending: loading } = useReplaceCartridge('', onClose);

  const { data: sensors = [], isLoading, isSuccess } = useGetSensorsByAssetId(data?.assetId);

  const properties = useMemo(
    () => sensors?.find((s) => s.serialNumber === data?.serialNumber)?.properties,
    [sensors, isLoading, isSuccess],
  );

  const {
    cartridgeSize,
    additionalProperties,
  }: { cartridgeSize: string | null; additionalProperties: AdditionalProperties | null } = useMemo(
    () =>
      qrCode
        ? {
            cartridgeSize: getCatrigeSize(qrCode),
            additionalProperties: getAdditionalProperties(qrCode),
          }
        : { cartridgeSize: null, additionalProperties: null },
    [qrCode],
  );

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (data && cartridgeSize && properties) {
      mutate({
        params: {
          serialNumber: data.serialNumber,
          assetId: data?.assetId,
        },
        body: {
          properties: {
            cartridgeSize: cartridgeSize,
          },
          additionalProperties: additionalProperties ?? null,
        },
      });
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose();
  };

  const {translate} = useTranslation()
  const componentText = useMemo(() =>{
    return({
      cartridgeSize : translate("cartridge_size"),
      current:translate("current"),
      proposedChange:translate("proposed_change"),
      cartridgeSizeLabel:translate("cartridge_size_label"),
      confirmChange:translate("confirm_change"),
   })
  }, [])

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Box display='flex' alignItems='center' gap={2}>
        <Box
          position='relative'
          borderRadius='50%'
          width='2.3rem'
          height='2.3rem'
          sx={{ background: 'rgba(244, 67, 54, 0.1)' }}
        >
          <WarningAmberIcon
            sx={{
              position: 'absolute',
              size: '2rem',
              color: alarmColor,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </Box>
        <Typography fontSize='1.5rem' fontWeight='600'>
          {componentText.cartridgeSize}
        </Typography>
      </Box>
      <Divider />
      <SensorData data={data} />
      <Box display='flex' mb={1.5}>
        <Box width='70%' display='flex' flexDirection='column' gap={2}>
          <Typography height='1.35rem'></Typography>
          <Typography fontSize='0.9rem' height='1.35rem'>
            {componentText.current}
          </Typography>
          <Typography fontSize='0.9rem' height='1.35rem'>
            {componentText.proposedChange}
          </Typography>
        </Box>
        <Box width='30%' display='flex' flexDirection='column' gap={2}>
          <Typography fontSize='0.9rem' height='1.35rem'>
           {componentText.cartridgeSizeLabel}
          </Typography>
          <Typography fontSize='0.9rem' fontWeight='600' height='1.35rem'>
            {properties ? (
              `${properties?.cartridgeSize.value || ''}cc`
            ) : (
              <CircularProgress size='0.9rem' />
            )}
          </Typography>
          <Typography fontSize='0.9rem' fontWeight='600' height='1.35rem'>
            {cartridgeSize ? cartridgeSize + 'cc' : '-'}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box display='flex' justifyContent='space-between' mt={2}>
        <Button
          variant='outlined'
          color='secondary'
          sx={{ fontWeight: '600', color: 'black' }}
          onClick={handleCancel}
        >
          No
        </Button>
        <LoadingButton
          loading={loading}
          variant='contained'
          color='secondary'
          sx={{ fontWeight: '600', color: 'black' }}
          onClick={handleConfirm}
          disabled={!properties || !cartridgeSize}
        >
          {componentText.confirmChange}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CartridgeSize;
