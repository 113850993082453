import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export interface NotificationResponse {
  automaticallySensorsNotifications: boolean;
  gatewayOfflineNotifications: boolean;
  sensorsNoRecentDataNotifications: boolean;
  notificationCategories: {
    label: string;
    type: string;
    emailCounter: number;
    pushCounter: number;
    totalCounter: number;
  }[];
}

export const useGetNotification = (plantId: number) => {

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_notifications_toast'),
    };
  }, []);

  return useAuthQuery<NotificationResponse>({
    url: `users/plant/${plantId}/notifications`,
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
      enabled: !!plantId
    },
  });
};
