import { useMediaQuery, useTheme } from '@mui/material';

export const useDeviceSize = () => {
  const theme = useTheme();
  const isXSPhone = useMediaQuery(`(max-width:${theme.breakpoints.values.xs}px)`);
  const isTablet = useMediaQuery(
    `(max-width:${theme.breakpoints.values.md}px) and (min-width:${
      theme.breakpoints.values.sm + 1
    }px)`,
  );
  const isPhone = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);
  const isDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.lg - 500}px)`);
  const isXLDesktop = useMediaQuery(`(min-width:${theme.breakpoints.values.xl - 500}px)`);

  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');

  // Add combinations
  const isPhoneAndLandscape = isPhone && isLandscape;
  const isTabletAndPortrait = isTablet && isPortrait;

  const isXSPhoneAndLandscape = isXSPhone && isLandscape;
  const isXSPhoneAndPortrait = isXSPhone && isPortrait;
  const isTabletAndLandscape = isTablet && isLandscape;
  const isPhoneAndPortrait = isPhone && isPortrait;
  const isDesktopAndLandscape = isDesktop && isLandscape;
  const isXLDesktopAndPortrait = isXLDesktop && isPortrait;

  return {
    isXSPhone,
    isTablet,
    isPhone,
    isDesktop,
    isXLDesktop,
    isPortrait,
    isLandscape,
    isPhoneAndLandscape,
    isTabletAndPortrait,
    isXSPhoneAndLandscape,
    isXSPhoneAndPortrait,
    isTabletAndLandscape,
    isPhoneAndPortrait,
    isDesktopAndLandscape,
    isXLDesktopAndPortrait,
  };
};
