import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActions, CardContent, Collapse, IconButton, IconButtonProps, Typography, styled } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useState } from "react";
import Status from "../Status";
import { Sensor } from "features/dashboard/models/assetStatus.model";
import SensorStatus from "./SensorStatus";

type Props = {
    status: string,
    assetId: number,
    assetColor: string,
    sensors: Sensor[],
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} sx={{p:0, mr: ".2rem"}} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandTile:FC<Props> = ({status, assetId, assetColor, sensors}) =>{
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

    return (
      <Card elevation={0} sx={{background:"inherit"}}>
        <CardActions sx={{pt:0}} disableSpacing>
          <Box pl=".5rem">
            {expanded ? 
              <Typography textTransform="uppercase" color="#767D8A" fontSize=".75rem" fontWeight="600">Sensors</Typography> 
              : <Status status={status} reqColor={assetColor} />}
          </Box>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            disabled={!sensors.length}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
           {
            sensors.length && sensors.map( (sensor, index) => 
              <CardContent key={sensor.sensorId} sx={{p: ".4rem 1rem !important"}}>
                <SensorStatus key={sensor.sensorId} assetId={assetId} sensor={sensor} last={sensors.length === index + 1} />
              </CardContent>)
           }
        </Collapse>
      </Card>
    );
}

export default ExpandTile;