export const scroller = {
      maxHeight: "30rem",
      overflow: "auto",
      "&::-webkit-scrollbar": { width: "5px" },
      "&::-webkit-scrollbar-thumb": { background: "#337179" },
      "&::-webkit-scrollbar-track-piece:start": {
        background: "transparent",
      },
      ml:"-0.8rem",
      mr:"-.5rem"
}