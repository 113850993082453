import { Autocomplete, Grid, Popper, TextField, styled, useMediaQuery } from '@mui/material';
import { useGetUserRoles } from 'api/users';
import { usePutSelectedPlant } from 'api/users/usePutSelectedPlant';
import getUrlWithoutParams from 'helpers/getUrlWithoutParams';
import { UserRoleInfo } from 'models/user-role.model';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectPlant, setPlant } from 'store/accountSlice';
import { v4 as uuidv4 } from 'uuid';
import style from './PlantDropdown.module.scss';
import { DEFAULT_INPUT_WIDTH, INPUT, PADDING, getTextWidth } from './PlantFilter';

const CssTextField = styled(TextField)({
  input: {
    color: 'white',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiStandardInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
});

const PlantDropdown = (props: { textWidth: number }) => {
  const { data: userRoles = [], isLoading, refetch } = useGetUserRoles();
  const plantList = useMemo(
    () => userRoles.filter((role) => role.plantName !== null && role.plantId !== 0),
    [userRoles],
  );
  const { mutate: putSelectedPlantMutation } = usePutSelectedPlant();
  const dispatch = useDispatch();
  const selectedPlant = useSelector(selectPlant);
  const location = useLocation();

  const visiblePlants = useMemo(
    () => plantList.filter((plant) => plant.visible && plant.invitationStatus === 'accepted'),
    [plantList],
  );

  const isSmallScreen = useMediaQuery(`(max-width:420px)`);

  const optionListWidth = useMemo(() => {
    if (visiblePlants.length && !isSmallScreen) {
      const max = visiblePlants.reduce((acc, curr) =>
        curr.plantName.length > acc.plantName.length ? curr : acc,
      );
      return getTextWidth(max.plantName) + PADDING + 25;
    } else return DEFAULT_INPUT_WIDTH;
  }, [visiblePlants, isSmallScreen]);

  useEffect(() => {
    if (isLoading || visiblePlants.length === 0) {
      return;
    }

    const filteredSelectedPlant = visiblePlants.find(
      (plant) => plant.selected && plant.invitationStatus === 'accepted',
    );

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('plantId')) {
      const requestedPlantId = Number(urlParams.get('plantId'));
      const requestedPlant = visiblePlants.find((plant) => plant.plantId === requestedPlantId);
      if (requestedPlant) {
        putSelectedPlantMutation({
          params: {
            plantId: requestedPlantId,
          },
        });
        dispatch(setPlant(requestedPlant));
        return;
      }
    }

    if (filteredSelectedPlant) {
      dispatch(setPlant(filteredSelectedPlant));
    } else {
      putSelectedPlantMutation({
        params: {
          plantId: visiblePlants[0].plantId,
        },
      });
      dispatch(setPlant(visiblePlants[0]));
    }
  }, [isLoading, visiblePlants]);

  const handleChange = (_: any, value: UserRoleInfo) => {
    putSelectedPlantMutation({
      params: {
        plantId: value.plantId,
      },
    });
    // FIXME - now only base URL left
    window.history.replaceState({}, '', getUrlWithoutParams());

    dispatch(setPlant(value));
    setTimeout(() => {
      if (location.pathname.includes('/assets-management')) {
        window.location.reload();
      }
    }, 200);
    refetch();
  };

  const PopperComponent = (props: any) => (
    <Popper {...props} style={{ width: optionListWidth }} placement='bottom-start' />
  );

  return (
    <Grid item className={style.select}>
      {visiblePlants.length > 0 && !isLoading && selectedPlant && (
        <Autocomplete
          data-testid='plant_dropdown'
          PopperComponent={PopperComponent}
          disableClearable
          options={visiblePlants}
          sx={{
            mt: 0.5,
            width: `${props.textWidth > INPUT ? props.textWidth + PADDING : DEFAULT_INPUT_WIDTH}px`,
            '@media (max-width:300px)': {
              '& .MuiFormControl-root': {
                width: '110px',
              },
            },
            '@media (min-width:301px) and (max-width:420px)': {
              '& .MuiFormControl-root': {
                width: '160px',
              },
            },
            '@media (min-width:421px) and (max-width: 820px)': {
              '& .MuiFormControl-root': {
                width: '216px',
              },
            },
          }}
          onChange={handleChange}
          getOptionLabel={(option) => option.plantName}
          value={selectedPlant}
          renderInput={(params) => <CssTextField {...params} variant='standard' />}
          renderOption={(props, option) => (
            <li data-testid='plant_dropdown_element' {...props} key={uuidv4()}>
              {option.plantName}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option.plantId === value.plantId}
        />
      )}
    </Grid>
  );
};

export default PlantDropdown;
