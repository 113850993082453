import {  useRef } from "react";

const useCacheImageBlob = (assetId: number, thumbnailUrl: string | undefined, blob: Blob | undefined, isSuccess: boolean, isLoading: boolean) => {
    const prevAssetIdRef = useRef<number>();
    const prevThumbnailUrlRef = useRef<string>();
    const cachedBlob = useRef<Blob>();

    if (isSuccess && !isLoading && blob && (assetId !== prevAssetIdRef.current || thumbnailUrl !== prevThumbnailUrlRef.current)) {
        prevAssetIdRef.current = assetId;
        prevThumbnailUrlRef.current = thumbnailUrl;
        cachedBlob.current = blob;
        return blob;
    }

    return cachedBlob.current;
}

export default useCacheImageBlob;