import { useQueryClient } from '@tanstack/react-query';
import { useAuthMutation } from 'api/queries';
import { toast } from 'react-toastify';
import { ResponseData } from 'api/plants/useUpdateAsset';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

type Params = {
  plantId: number;
};

export const generateUrl = (plantId: number) =>
  `plants/${plantId}/gateways/monitoring/add-sensors-to-nearest`;

export const usePostAddSensorsToNearest = (onSuccess?: (isSuccess: boolean) => void) => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('sensors_successfully_assigned_toast'),
      errorMess: translate('fail_during_assigning_sensors_toast'),
    };
  }, []);
  return useAuthMutation<Params, {}>({
    url: (params) => generateUrl(params.plantId),
    method: 'POST',
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.successMess);
        onSuccess?.(true);
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
