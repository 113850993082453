import { useAuthQuery } from 'api/queries';
import { AssetGroup } from 'features/plants-management/models/asset.model';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

export const useGetAssetGroups = () => {
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_load_asset_groups_from_plant_toast'),
    };
  }, []);

  return useAuthQuery<AssetGroup[]>({
    url: `plants/${plantId}/asset-groups`,
    queryOptions: {
      meta: {
        error: {
          message: componentText.errorMess,
        },
      },
    },
  });
};
