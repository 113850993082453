import { FC, ReactNode, useMemo, useState } from 'react';
import CartridgeReplacement from './CartridgeReplacement';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import ScanQrCode from './ScanQrCode';
import CartridgeSize from './CartridgeSize';
import BreatherCartridgeSize from './BreatherCartridgeSize';

export type SensorType = 'IntelliLube' | 'BreatherSensor';

export interface SensorInfo {
  sensorId: number;
  assetId: number;
  serialNumber: string;
  location: string;
  type: SensorType;
}

export type Step = 1 | 2 | 3 | 4;

type Props = {
  open: boolean;
  onClose: () => void;
  initStep?: Step;
  data?: SensorInfo;
};

const MissingCartridge: FC<Props> = ({ open, onClose, data, initStep }) => {
  const [activeStep, setActiveStep] = useState<Step>(initStep ?? 1);
  const [qrCode, setQrcode] = useState<string>('');

  const { component, modalWidth }: { component: ReactNode; modalWidth: string } = useMemo(() => {
    if (activeStep === 2)
      return {
        component: <ScanQrCode setActiveStep={setActiveStep} data={data} setQrCode={setQrcode} />,
        modalWidth: '36rem',
      };
    else if (activeStep === 3)
      return {
        component: <CartridgeSize onClose={onClose} data={data} qrCode={qrCode} />,
        modalWidth: '23rem',
      };
    else if (activeStep === 4)
      return {
        component: <BreatherCartridgeSize onClose={onClose} data={data} />,
        modalWidth: '30rem',
      };
    else
      return {
        component: (
          <CartridgeReplacement setActiveStep={setActiveStep} onClose={onClose} data={data} />
        ),
        modalWidth: '30rem',
      };
  }, [activeStep]);

  return (
    <DodgeModal open={open} onClose={onClose} hideCloseIco={true} width={modalWidth}>
      {component}
    </DodgeModal>
  );
};

export default MissingCartridge;
