import { Box } from '@mui/material';
import { memo } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';

type Props = {
  unfoldClick: () => void;
};

const Folded = ({ unfoldClick }: Props) => {
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: '1vh',
          right: 0,
          background: '#d4d2d2',
          height: '48px',
          width: '32px',
          borderTopLeftRadius: '1rem',
          borderBottomLeftRadius: '1rem',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={unfoldClick}
      >
        <SettingsIcon color='primary' />
      </Box>
    </>
  );
};

export default memo(Folded);
