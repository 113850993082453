import React, { FC, useMemo } from 'react'
import {Grid, CircularProgress, SxProps, Theme, useTheme, useMediaQuery} from '@mui/material';

interface LoadingProps{
  height?: string,
  size?: number,
  dashboard?: boolean,
}

const DEFAULT_SIZE = 120;

const Loading:FC<LoadingProps> = ({height, size, dashboard}) => {

  const theme = useTheme();
  const isSmall = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const sizeVal = useMemo(() =>{
    return dashboard ? (isSmall ? 40 : DEFAULT_SIZE) : (size ? size : DEFAULT_SIZE)
  }, [isSmall, size])
  
    return (
        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: height? height : '75vh' }}
      >
      
        <Grid item xs={3}>
            <CircularProgress size={sizeVal} />
        </Grid>   
         
      </Grid> 
    )
}

export default React.memo(Loading);