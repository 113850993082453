import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { TileId } from 'features/new-dashboard/components/carousel-tile/CarouselData';
import { AssetStatusGroup } from '../models/assetStatusGroup.model';
import { AssetStatus } from '../models/assetStatus.model';
import { AssetType } from 'models/Asset/models';

export interface DashboardState {
  viewType: string;
  typeOfAlert: string;
  typeOfSensor: string;
  modeAttention: string;
  modeInactive: string;
  measurement: string;
  criticness: string;
  sortDirec: string;
  sortBy: string;
  openRowId: number;
  openRowDetails: AssetStatusGroup | AssetStatus | null;
  assetType: AssetType | 'all';
  gatewayModel: string;
  gatewayConnectivity: string;
  gatewaySignalStrength: string;
  sensorStatus: string;
  cartridgeType: string;
  cartridgeStatus: string;

  tileId: TileId;
  triggerRefetch: boolean;
}

const initialState: DashboardState = {
  viewType: 'assets',
  typeOfAlert: 'all',
  typeOfSensor: 'all',
  modeAttention: 'weekly',
  modeInactive: 'weekly',
  measurement: 'newest',
  criticness: 'alarm-to-ok',
  sortDirec: 'desc',
  sortBy: 'alarm',
  openRowId: -100,
  openRowDetails: null,
  assetType: 'all',
  gatewayModel: 'all',
  gatewayConnectivity: 'all',
  gatewaySignalStrength: 'all',
  sensorStatus: 'all',
  cartridgeType: 'all',
  cartridgeStatus: 'all',

  tileId: 'asset',
  triggerRefetch: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeViewType: (state, action) => {
      state.viewType = action.payload;
    },
    changeTypeOfAlert: (state, action) => {
      state.typeOfAlert = action.payload;
    },
    changeAssetType: (state, action: PayloadAction<AssetType | 'all'>) => {
      state.assetType = action.payload;
    },
    changeTypeOfSensor: (state, action) => {
      state.typeOfSensor = action.payload;
    },
    changeModeAttention: (state, action) => {
      state.modeAttention = action.payload;
    },
    changeModeInactive: (state, action) => {
      state.modeInactive = action.payload;
    },
    changeMeasurement: (state, action) => {
      state.measurement = action.payload;
    },
    changeCriticness: (state, action) => {
      state.criticness = action.payload;
    },
    changeSortDirec: (state, action) => {
      state.sortDirec = action.payload;
    },
    changeSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    changeOpenRowId: (state, action) => {
      state.openRowId = action.payload;
    },
    changeOpenRowDetails: (state, action) => {
      state.openRowDetails = action.payload;
    },

    changeGatewayModel: (state, action) => {
      state.gatewayModel = action.payload;
    },
    changeGatewayConnectivity: (state, action) => {
      state.gatewayConnectivity = action.payload;
    },
    changeGatewaySignalStrength: (state, action) => {
      state.gatewaySignalStrength = action.payload;
    },
    changeSensorStatus: (state, action) => {
      state.sensorStatus = action.payload;
    },
    changeCartridgeType: (state, action) => {
      state.cartridgeType = action.payload;
    },
    changeCartridgeStatus: (state, action) => {
      state.cartridgeStatus = action.payload;
    },

    changeTileId: (state, action) => {
      state.tileId = action.payload;
    },
    setTriggerRefetch: (state, action) => {
      state.triggerRefetch = action.payload;
    },
  },
});

export const {
  changeViewType,
  changeTypeOfAlert,
  changeModeAttention,
  changeModeInactive,
  changeMeasurement,
  changeCriticness,
  changeSortDirec,
  changeSortBy,
  changeOpenRowId,
  changeOpenRowDetails,
  changeTypeOfSensor,
  changeGatewayModel,
  changeGatewayConnectivity,
  changeGatewaySignalStrength,
  changeSensorStatus,
  changeCartridgeType,
  changeCartridgeStatus,
  changeTileId,
  setTriggerRefetch,
  changeAssetType,
} = dashboardSlice.actions;

export const selectViewType = (state: RootState) => state.dashboard.viewType;
export const selectTypeOfAlert = (state: RootState) => state.dashboard.typeOfAlert;
export const selectTypeOfSensor = (state: RootState) => state.dashboard.typeOfSensor;
export const selectModeAttention = (state: RootState) => state.dashboard.modeAttention;
export const selectModeInactive = (state: RootState) => state.dashboard.modeInactive;
export const selectMeasurement = (state: RootState) => state.dashboard.measurement;
export const selectCriticness = (state: RootState) => state.dashboard.criticness;
export const selectSortDirec = (state: RootState) => state.dashboard.sortDirec;
export const selectSortBy = (state: RootState) => state.dashboard.sortBy;
export const selectOpenRowId = (state: RootState) => state.dashboard.openRowId;
export const selectOpenRowDetails = (state: RootState) => state.dashboard.openRowDetails;
export const selectAssetType = (state: RootState) => state.dashboard.assetType;

export const selectGatewayModel = (state: RootState) => state.dashboard.gatewayModel;
export const selectGatewayConnectivity = (state: RootState) => state.dashboard.gatewayConnectivity;
export const selectGatewaySignalStrength = (state: RootState) =>
  state.dashboard.gatewaySignalStrength;
export const selectSensorStatus = (state: RootState) => state.dashboard.sensorStatus;
export const selectCartridgeType = (state: RootState) => state.dashboard.cartridgeType;
export const selectCartridgeStatus = (state: RootState) => state.dashboard.cartridgeStatus;

export const selectTileId = (state: RootState) => state.dashboard.tileId;
export const selectTriggerRefetch = (state: RootState) => state.dashboard.triggerRefetch;

export default dashboardSlice.reducer;
