import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useAuthMutation } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export type VisiblePlantsBody = number[];

export const usePutVisiblePlants = (onSucces?: () => void) => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_change_visible_plants_setting_toast'),
    };
  }, []);
  return useAuthMutation<{}, VisiblePlantsBody>({
    url: `users/visible-plants`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes(`roles`) });
        onSucces?.();
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
