import axios, { AxiosResponse, AxiosResponseTransformer } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import axiosApi from 'api/axios';

export const imageMimeTypes = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
};

interface UploadFileResponse {
  imagePath: string;
}

export interface FileUploadSuccess {
  file: File;
  filePath: string;
}

export const useCreateAssetImage = (assetId: number, type: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (file: File) => {
      const formData = new FormData();
      formData.append('data', file);

      return axiosApi.post(`/assets/${assetId}/images?type=${type}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformResponse: [
          ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
          (data: UploadFileResponse): FileUploadSuccess => {
            return { file, filePath: data.imagePath };
          },
        ],
      });
    },
    onSuccess: ({ data }: AxiosResponse<FileUploadSuccess>) => {
      toast.success(`Image ${data.file.name} has successfully uploaded.`);
      onSuccess?.();
      queryClient.invalidateQueries({ queryKey: ['assets'] });
    },
    onError: () => {
      toast.error('Failed to upload image.');
    },
  });
};
