import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useGetImageByAsset } from 'api/dashboard/assets/';
import {
  setSelectedAssets,
  setSelectedSensors,
} from 'features/assets-management/store/assetsManagementSlice';
import { alarmColor, assetGroupTitleColor, noDataColor } from 'features/dashboard/colors/colors';
import { AssetStatus } from 'features/dashboard/models/assetStatus.model';
import { selectOpenRowId } from 'features/dashboard/store/dashboardSlice';
import { useTimeConverter } from 'features/dashboard/utils/formatDate';
import MissingCartridge, {
  SensorInfo,
} from 'features/plants-management/components/MissingCartridge/MissingCartridge';
import { useTranslation } from 'languages';
import React, { FC, MouseEvent, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MeasurementIcon from '../../../icons/measurement_icon.svg';
import CartridgeInfoMissing from '../../AdditionalErrors/CartridgeInfoMissing';
import IncompatibleCartridgeError from '../../AdditionalErrors/IncompatibleCartridgeError';
import MissingCartridgeError from '../../AdditionalErrors/MissingCartridgeError';
import NoDataError from '../../AdditionalErrors/NoDataError';
import SyncError from '../../AdditionalErrors/SyncError';
import ImageViewer from '../../ImageViewer/ImageViewer';
import { Icons } from './AssetTile';
import { fontAlert, fontAssetGroupName, fontLastMeasurement } from './styles/fontTile';

interface Alerts {
  type: string;
  name: string;
  icon?: React.ReactNode;
}
interface AssetTileBodyProps {
  row: AssetStatus;
  index: number;
  alerts: Alerts;
  color: string;
  handleChangeExpand: (id: number) => void;
  assetIcons: Icons;
  tileWidth: number;
  isXs: boolean;
  column: number;
}

const AssetTileBody: FC<AssetTileBodyProps> = ({
  row,
  index,
  color,
  alerts,
  handleChangeExpand,
  assetIcons,
  tileWidth,
  isXs,
  column,
}) => {
  const dispatch = useDispatch();
  const openRowId = useSelector(selectOpenRowId);
  const [imageUrl, setImageUrl] = React.useState('');
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const { calculateTime } = useTimeConverter();
  const [infoMissingModal, setInfoMissingModal] = React.useState(false);

  const queryClient = useQueryClient();

  const { data: imageBlob } = useGetImageByAsset(row.id, row.thumbnailUrl);

  React.useEffect(() => {
    if (imageBlob) {
      const url = URL.createObjectURL(imageBlob);
      setImageUrl(url);
    }
  }, [imageBlob]);

  const image = row.thumbnailUrl ? imageUrl : '';

  const handleImageClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (image) setOpenImageViewer(true);
  };

  const isDesynchronized = row.sensors.length
    ? row.sensors.find((sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'desynchronized')
    : undefined;
  const isNoRecordedData = row.sensors.length
    ? row.sensors.find((sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'noRecordedData')
    : undefined;
  const isNoCartridgeInfo = row.sensors.length
    ? row.sensors.find(
        (sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'noCartridgeInfo',
      )
    : undefined;
  const isProjected = row.sensors.length
    ? row.sensors.find((sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'projected')
    : undefined;
  const isIncompatibleCartdrige = row.sensors.length
    ? row.sensors.find(
        (sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'cartridgeIncompatible',
      )
    : undefined;
  const isMissingCartdrige = row.sensors.length
    ? row.sensors.find(
        (sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'cartridgeMissing',
      )
    : undefined;
  const isBasedOnProjectedValue =
    alerts?.name?.includes('Based on Projected Value') ||
    alerts?.name?.includes('Basado en el valor proyectado');

  const getError = (
    errorName: string,
    location: string,
    serialNumber: string,
    isClickable?: boolean,
  ) => {
    const onClick = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      e.stopPropagation();
      setInfoMissingModal(true);
    };
    return (
      <Box display='flex' alignItems='center' onClick={isClickable ? (e) => onClick(e) : () => {}}>
        <ReportProblemOutlinedIcon sx={{ color: alarmColor, fontSize: '1rem', mr: 1, ml: 1 }} />
        <Typography
          display={tileWidth >= 360 ? 'inline' : 'none'}
          sx={{
            ...fontLastMeasurement,
            color: alarmColor,
          }}
        >{`${errorName}, ${location} (${serialNumber})`}</Typography>
      </Box>
    );
  };

  const getMissingInfoData = () => {
    const sensor = row.sensors.length
      ? row.sensors.find(
          (sensor) => 'deviceFlag' in sensor && sensor.deviceFlag === 'noCartridgeInfo',
        )
      : undefined;
    if (sensor)
      return {
        sensorId: sensor.sensorId,
        assetId: sensor.assetId,
        serialNumber: sensor.serialNumber,
        location: sensor.location,
        type: sensor.type,
      } as SensorInfo;
    else return sensor;
  };

  const ref = useRef<HTMLDivElement>(null);
  const [sensorWidth, setSensorWidth] = useState(0);

  const { translate } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setSensorWidth(ref.current.offsetWidth);
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const sensorsNumber = row.sensors.length;
  const componentText = useMemo(() => {
    return {
      synchronizationErrorText: translate('synchronization_error_text'),
      oldestMeasurementText: translate('oldest_measurement_text'),
      oldestMeasurementShortText: translate('oldest_measurement_short_text'),
      assetGroupNameDefaultText: translate('asset_group_name_default_text'),
      sensorsNumberText: translate('asset_group_sensors_text', { isPlural: sensorsNumber > 1 }),
      incompatibleCartridge: translate('asset_tile_body_incompatible_cartridge'),
      missingCartridge: translate('asset_tile_body_missing_cartridge'),
      noData: translate('asset_tile_body_no_data'),
      cartridgeInfoMissing: translate('asset_tile_body_cartridge_info_missing'),
    };
  }, [sensorsNumber, translate]);

  return (
    <>
      <Grid item container width='5.5rem' data-testid='asset_tile_body' mt='1.5%'>
        <Box
          width='calc(4.75rem - 9%)'
          height='calc(4.25rem - 1%)'
          onClick={handleImageClick}
          sx={{
            background:
              isBasedOnProjectedValue && !image
                ? `repeating-linear-gradient(135deg, white, white 1px, ${color} 1px, ${color} 19px )`
                : `url(${image}) 0/cover no-repeat`,
            borderRadius: '16px',
            position: 'relative',
            backgroundColor: color,
            cursor: image ? 'pointer' : undefined,
          }}
        >
          {!image && assetIcons.bigIcon}
          <Box
            width='1.875rem'
            height='1.875rem'
            sx={{
              background: color,
              borderRadius: '50%',
              position: 'absolute',
              bottom: '-0.5rem',
              right: '-0.5rem',
              border: '3px solid white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {alerts.icon ? alerts.icon : ''}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        direction='column'
        width='calc(100% - 5.5rem)'
        justifyContent='space-between'
        ml={-1}
      >
        <Grid item width='100%' position='relative'>
          <Box
            display='flex'
            alignItems='center'
            maxWidth={
              !isXs
                ? tileWidth >= 550
                  ? undefined
                  : tileWidth <= 310 && column === 12
                    ? '9.5rem'
                    : 'calc(100% - 1.5rem)'
                : tileWidth <= 230
                  ? '6rem'
                  : tileWidth <= 250
                    ? '7rem'
                    : tileWidth <= 270
                      ? '8.2rem'
                      : tileWidth <= 290
                        ? '8.8rem'
                        : tileWidth <= 310
                          ? '10.5rem'
                          : tileWidth <= 330
                            ? '12rem'
                            : '12.5rem'
            }
            //12.5 dla 310
          >
            {assetIcons.smallIcon}
            <Typography
              ml={1}
              fontSize='0.77rem'
              fontWeight='bold'
              color='#252935'
              textOverflow={'ellipsis'}
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              sx={{ display: 'inline-block' }}
            >{`${row.assetName.toUpperCase()}`}</Typography>
          </Box>
          {row.hasMaintenanceEvents && (
            <Box
              sx={{
                width: '1.3rem',
                height: '1.3rem',
                borderRadius: '50%',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                background: 'rgba(111, 38, 136, 0.1)',
                position: 'absolute',
                top: -1,
                right: '-5px',
              }}
              component={Link}
              to={`/assets-management?assetId=${row.id}&tab=events&oldestEventDate=${row.oldestEventDate}&eventType=maintenance&eventStatus=open`}
              onClick={() => {
                dispatch(setSelectedAssets([]));
                dispatch(setSelectedSensors([]));
              }}
            >
              <BuildIcon sx={{ color: 'rgba(111, 38, 136, 1)', fontSize: '.8rem' }} />
            </Box>
          )}
        </Grid>
        <Grid
          item
          container
          alignItems={tileWidth >= 310 ? 'center' : 'flex-start'}
          my={0.5}
          flexWrap={tileWidth >= 310 ? 'nowrap' : 'wrap'}
          flexDirection={tileWidth >= 310 ? 'row' : 'column'}
        >
          <Grid item mr='0.9rem' flexShrink={1} minWidth={0}>
            <Typography
              sx={{ ...fontAssetGroupName }}
              maxWidth={
                tileWidth >= 310
                  ? `calc(${tileWidth}px - 5.6rem - ${sensorWidth}px)`
                  : `calc(${tileWidth}px - 6rem)`
              }
              whiteSpace={'nowrap'}
              overflow='hidden'
              textOverflow='ellipsis'
            >
              {row.assetGroupName.toLowerCase() === 'default'
                ? componentText.assetGroupNameDefaultText
                : row.assetGroupName.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item mr='0.9rem' ref={ref} component='div' whiteSpace={'nowrap'}>
            <Box display='flex' alignItems='center'>
              <Box
                sx={{
                  width: '0.25rem',
                  height: '0.25rem',
                  background: assetGroupTitleColor,
                  mr: 1,
                  borderRadius: '50%',
                }}
              ></Box>
              <Typography sx={{ ...fontAssetGroupName }}>
                {`${row.sensors.length} `}
                {componentText.sensorsNumberText}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item container mb={0.5}>
          <Grid
            item
            mr='0.9rem'
            height='1.2rem'
            width='100%'
            sx={{ mr: '-1rem' }}
            display='flex'
            alignItems='center'
          >
            {row.lastMeasurement ? (
              <Box
                display='flex'
                alignItems='center'
                data-testid='asset_last_measurement'
                data-value={row.lastMeasurement}
              >
                <Box sx={{ mr: 1 }}>
                  <img src={MeasurementIcon} alt='icon' />
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <Typography sx={{ ...fontLastMeasurement, mr: 0.5 }}>
                    {tileWidth >= 310
                      ? componentText.oldestMeasurementText
                      : componentText.oldestMeasurementShortText}
                  </Typography>
                  {
                    <Typography sx={{ ...fontLastMeasurement }}>{`${calculateTime(
                      row.lastMeasurement,
                      false,
                    )}`}</Typography>
                  }
                </Box>
              </Box>
            ) : (
              <Box height='1.2rem'></Box>
            )}
          </Grid>
        </Grid>
        <Grid item data-testid={`asset-kpi-${index}`}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            width='100%'
            mt={-1.2}
          >
            {
              <Box
                display='flex'
                alignItems='center'
                sx={{ ...fontAlert }}
                data-testid={`${alerts.type}_status`}
                mt={0.5}
              >
                {alerts.type === 'ok' ? (
                  <Box display='flex' alignItems='center'>
                    {row.hasUnknownState ? (
                      <HelpOutlineIcon sx={{ mr: 0.5, fontSize: '1rem', color: noDataColor }} />
                    ) : (
                      <CheckCircleOutlineIcon sx={{ mr: 0.5, fontSize: '1rem', color }} />
                    )}
                    <Box fontSize='.7rem'>{`${alerts.name}${
                      isProjected ? ' (Based on Projected Value)' : ''
                    }`}</Box>
                  </Box>
                ) : alerts.type === 'alarm' ? (
                  <Box display='flex' alignItems='center'>
                    <ErrorOutlineIcon sx={{ mr: 0.5, fontSize: '1rem', color }} />
                    <Box fontSize='.7rem'>{alerts.name}</Box>
                  </Box>
                ) : alerts.type === 'warning' ? (
                  <Box display='flex' alignItems='center'>
                    <ReportProblemOutlinedIcon sx={{ mr: 0.5, fontSize: '1rem', color }} />
                    <Box fontSize='.7rem'>{alerts.name}</Box>
                  </Box>
                ) : (
                  <Box display='flex' alignItems='center'>
                    {row.hasUnknownState ? (
                      <HelpOutlineIcon sx={{ mr: 0.5, fontSize: '1rem', color: noDataColor }} />
                    ) : (
                      <CloudOffIcon sx={{ mr: 0.5, fontSize: '1rem', color }} />
                    )}
                    <Box fontSize='.7rem'>{alerts.name}</Box>
                  </Box>
                )}
                {isDesynchronized && (
                  <SyncError>
                    {getError(
                      componentText.synchronizationErrorText,
                      isDesynchronized.location,
                      isDesynchronized.serialNumber,
                    )}
                  </SyncError>
                )}
                {isIncompatibleCartdrige && (
                  <IncompatibleCartridgeError>
                    {getError(
                      componentText.incompatibleCartridge,
                      isIncompatibleCartdrige.location,
                      isIncompatibleCartdrige.serialNumber,
                    )}
                  </IncompatibleCartridgeError>
                )}
                {isMissingCartdrige && (
                  <MissingCartridgeError>
                    {getError(
                      componentText.missingCartridge,
                      isMissingCartdrige.location,
                      isMissingCartdrige.serialNumber,
                    )}
                  </MissingCartridgeError>
                )}
                {isNoRecordedData && (
                  <NoDataError>
                    {getError(
                      componentText.noData,
                      isNoRecordedData.location,
                      isNoRecordedData.serialNumber,
                    )}
                  </NoDataError>
                )}
                {isNoCartridgeInfo && (
                  <CartridgeInfoMissing>
                    <Box
                      display='flex'
                      alignItems='center'
                      onClick={(e) => {
                        e.stopPropagation();
                        setInfoMissingModal(true);
                      }}
                    >
                      <ReportProblemOutlinedIcon
                        sx={{ color: alarmColor, fontSize: '1rem', mr: 1, ml: 1 }}
                      />
                      <Typography
                        sx={{
                          ...fontLastMeasurement,
                          color: alarmColor,
                          display: tileWidth >= 360 ? 'inline' : 'none',
                        }}
                      >
                        {componentText.cartridgeInfoMissing}
                      </Typography>
                    </Box>
                  </CartridgeInfoMissing>
                )}
              </Box>
            }
            <IconButton
              sx={{ mr: '-0.625rem' }}
              onClick={() => handleChangeExpand(index)}
              disabled={!row.sensors.length}
              size='small'
            >
              {openRowId === index ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      {openImageViewer && (
        <span onClick={(e) => e.stopPropagation()}>
          <ImageViewer
            open={openImageViewer}
            onClose={() => setOpenImageViewer(false)}
            assetId={row.id}
            assetName={row.assetName}
          />
        </span>
      )}
      {infoMissingModal && (
        <MissingCartridge
          open={infoMissingModal}
          onClose={() => {
            queryClient.invalidateQueries({
              predicate: (query) => query.queryKey.includes(`assets-status`),
            });
            setInfoMissingModal(false);
          }}
          data={getMissingInfoData()}
        />
      )}
    </>
  );
};

export default memo(AssetTileBody);
