import VirtualSupport from './icons/VirtualSupport.svg';
import Call from './icons/Call.svg';
import Email from './icons/Email.svg';
import { useTranslation } from 'languages';


export const useInformations = () => {
  const { translate } = useTranslation();

  const informations = [
    {
      icon: VirtualSupport,
      title: translate('menu_virtual_support_title'),
      desc: translate('menu_virtual_support_desc'),
      subDesc: translate('menu_virtual_support_sub_desc'),
      buttonLabel: translate('menu_virtual_support_button'),
      link: 'https://info.dodgeindustrial.com/virtualsupport',
      testid: 'help_support_virtual_support',
    },
    {
      icon: Email,
      title: translate('menu_email_title'),
      desc: translate('menu_email_desc'),
      subDesc: translate('menu_email_sub_desc'),
      buttonLabel: translate('menu_email_button'),
      link: 'https://dodgeindustrial.com/contact/#contact-form',
      testid: 'help_support_email',
    },
    {
      icon: Call,
      title: translate('menu_call_title'),
      desc: translate('menu_call_desc'),
      subDesc: translate('menu_call_sub_desc'),
      testid: 'help-support-call',
    },
  ];

  return { informations }
}