import { useAuthMutation } from './../queries';
import { SensorType } from 'models/sensor/models';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

type Params = {
  assetGroup?: string;
  sensorType?: SensorType;
  searchString?: string;
};

type Body = {
  type: 'assetRecurring';
  notificationWay: 'email' | 'push';
  value: boolean;
};

export const usePutAllRecurrEventsNotif = () => {
  const plantId = useSelector(selectPlantId);

  return useAuthMutation<Params, Body>({
    url(params) {
      const baseUrl = `users/plant/${plantId}/assets-notifications`;

      const entries = Object.entries(params);

      if (entries.length === 0) return baseUrl;

      let paramsUrl = baseUrl.concat('?');
      entries.forEach(([key, value], index) => {
        if (!value) return;
        if (index > 0) paramsUrl = paramsUrl.concat('&');
        paramsUrl = paramsUrl.concat(`${key}=${value}`);
      });

      return paramsUrl;
    },
    method: 'PUT',
  }) as UseMutationResult<AxiosResponse<unknown>>;
};
