import { Grid, Typography } from "@mui/material";
import { FC, memo } from "react";
import Content from "./Content";

type ContainerProps ={
    id: number;
    name: string;
}

const Container:FC<ContainerProps> = ({id, name}) =>{

    return (
      <Grid container>
       <Grid item xs={12} sx={{zIndex:"-1"}}>
        <Typography
            fontSize="1.5rem"
            fontWeight="600"
            marginTop={-3.8}
            textAlign="center"
            >
                {name}
         </Typography>
        </Grid>
       <Grid item xs={12} m={3} >
         <Content id={id}/>
       </Grid>
      </Grid>
    )
}

export default memo(Container);