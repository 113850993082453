import {
  ListItemIcon,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  Box,
  Checkbox,
} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import { Entity, Filter, FilterWithAlias } from '../models';
import { ALL_FILTER } from '../DataTableSelectionFilterBar/DataTableSelectionFilterBar';

interface DataTableSelectFilterProps<T> {
  filterProperties: Filter<T>;
  onDataTableSelectedFilter: (filter: string, key: keyof T, multiselect: boolean) => void;
  multiselect?: boolean;
  blockFilter?: boolean;
}

const DataTableSelectFilter = <T extends Entity>(props: DataTableSelectFilterProps<T>) => {
  const { filterProperties, onDataTableSelectedFilter, multiselect } = props;
  const boxRef = useRef<HTMLElement | undefined>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const [selectedFilter, setSelectedFilter] = useState<(string | FilterWithAlias)[]>([
    filterProperties.filterList[0],
  ]);
  const open = !!anchorEl;

  const handleOpen = () => {
    setAnchorEl(boxRef.current);
  };

  useEffect(() => {
    if (filterProperties.initValue) {
      setSelectedFilter([filterProperties.initValue]);
      if (typeof filterProperties.initValue === 'string') {
        onDataTableSelectedFilter(
          filterProperties.initValue,
          filterProperties.filterKey,
          filterProperties.multiselect ? filterProperties.multiselect : false,
        );
      } else {
        onDataTableSelectedFilter(
          filterProperties.initValue.value,
          filterProperties.filterKey,
          filterProperties.multiselect ? filterProperties.multiselect : false,
        );
      }
    }
  }, []);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleSelect = (filter: string | FilterWithAlias, specMultiSelect?: boolean) => {
    const filterVal = typeof filter === 'string' ? filter : filter.value;
    if (!(filterVal.length && filterVal.length > 0)) {
      handleClose();
      return;
    }

    if (!(specMultiSelect || multiselect)) {
      setSelectedFilter([filter]);
      handleClose();
      return;
    }

    const hasAll = selectedFilter.some((el) => el === 'All');
    if (filter === 'All' && hasAll) {
      setSelectedFilter(hasAll ? [] : ['All']);
      handleClose();
      return;
    }

    const hasGivenFilter = selectedFilter.some((el) => {
      const elVal = typeof el === 'string' ? el : el.value;
      const filterVal = typeof filter === 'string' ? filter : filter.value;
      handleClose();
      return elVal === filterVal;
    });

    setSelectedFilter((prev) => {
      if (hasAll) {
        return [
          ...filterProperties.filterList.filter((el) => {
            const elVal = typeof el === 'string' ? el : el.value;
            const filterVal = typeof filter === 'string' ? filter : filter.value;
            handleClose();
            return elVal !== filterVal && elVal !== 'All';
          }),
        ];
      }
      if (hasGivenFilter) {
        handleClose();
        return [
          ...prev.filter((el) => {
            const elVal = typeof el === 'string' ? el : el.value;
            const filterVal = typeof filter === 'string' ? filter : filter.value;
            return elVal !== filterVal;
          }),
        ];
      }
      handleClose();
      return [...prev, filter];
    });
  };

  useEffect(() => {
    if (
      selectedFilter.length + 1 === filterProperties.filterList.length &&
      !selectedFilter.some((el) => (typeof el === 'string' ? el === 'All' : el.value === 'All'))
    ) {
      setSelectedFilter([filterProperties.filterList[0], ...selectedFilter]);
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (selectedFilter.length >= 2 && selectedFilter.some((f) => typeof f === 'string')) {
      onDataTableSelectedFilter?.(
        ALL_FILTER,
        filterProperties.filterKey,
        props.multiselect ?? false,
      );
      return;
    }
    if (selectedFilter.length === 0) {
      onDataTableSelectedFilter?.(
        ALL_FILTER,
        filterProperties.filterKey,
        props.multiselect ?? false,
      );
      return;
    }
    selectedFilter.forEach((f) => {
      const filter = typeof f === 'string' ? f : f.value;
      onDataTableSelectedFilter?.(filter, filterProperties.filterKey, props.multiselect ?? false);
    });
  }, [selectedFilter]);

  return (
    <Box sx={{ ml: 3 }}>
      <Box display='flex' alignItems='center' ref={boxRef}>
        <Typography>
          {filterProperties?.filterLabel}:{' '}
          {selectedFilter.length > 1 &&
          !selectedFilter.find((f) => typeof f === 'string' && f.toLowerCase() === 'all')
            ? `${selectedFilter.length} filters`
            : typeof selectedFilter[0] === 'string'
              ? selectedFilter[0]
              : selectedFilter[0]?.alias}
        </Typography>
        <KeyboardArrowDownIcon
          color={props.blockFilter ? 'disabled' : undefined}
          sx={{ '&:hover': { cursor: 'pointer' } }}
          onClick={props.blockFilter ? undefined : handleOpen}
        />
      </Box>
      <Popover
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {filterProperties?.filterList.map((filter) =>
            typeof filter === 'string' ? (
              <MenuItem
                key={filter}
                onClick={() => handleSelect(filter, filterProperties.multiselect)}
              >
                <ListItemIcon>
                  {filterProperties.multiselect ? (
                    <Checkbox
                      checked={selectedFilter.includes(filter) || selectedFilter.includes('All')}
                    />
                  ) : (
                    selectedFilter.includes(filter) && <CheckIcon fontSize='small' />
                  )}
                </ListItemIcon>
                <Typography>{filter}</Typography>
              </MenuItem>
            ) : (
              <MenuItem
                key={filter.value}
                onClick={() => handleSelect(filter, filterProperties.multiselect)}
              >
                <ListItemIcon>
                  {filterProperties.multiselect ? (
                    <Checkbox
                      checked={
                        selectedFilter.find((v) =>
                          typeof v !== 'string'
                            ? v.value === filter.value
                            : v.toLowerCase() === 'all',
                        )
                          ? true
                          : false
                      }
                    />
                  ) : (
                    selectedFilter.find(
                      (v) => typeof v !== 'string' && v.value === filter.value,
                    ) && <CheckIcon fontSize='small' />
                  )}
                </ListItemIcon>
                <Typography>{filter.alias}</Typography>
              </MenuItem>
            ),
          )}
        </MenuList>
      </Popover>
    </Box>
  );
};

export default DataTableSelectFilter;
