import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Theme,
} from '@mui/material';
import StrokeContainer from 'components/StrokeContainer/StrokeContainer';
import { Loading } from 'components/Uitls';
import SelectDataPrompt from 'features/assets-management/components/SelectDataPrompt/SelectDataPrompt';
import DataTableTilesBody from 'features/dashboard/components/CurrentAssetStatusTile/DataTableTilesBody/DataTableTilesBody';
import Tile from 'features/dashboard/components/CurrentAssetStatusTile/Tile/Tile';
import { Background } from 'features/dashboard/models/backgroundCell.model';
import { PageSize } from 'features/dashboard/models/pageSize.model';
import { selectMeasurement, selectTypeOfAlert } from 'features/dashboard/store/dashboardSlice';
import React, {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import DataTableAdditionalBar from './DataTableAdditionalBar/DataTableAdditionalBar';
import DataTableChartBar from './DataTableAdditionalBar/DataTableChartBar';
import DataTableDefaultHead from './DataTableDefaultHead/DataTableDefaultHead';
import DataTableHeadContainer from './DataTableHeadContainer/DataTableHeadContainer';
import DataTableItemsSelectedHead from './DataTableItemsSelectedHead/DataTableItemsSelectedHead';
import DataTableRow from './DataTableRow/DataTableRow';
import DataTableSelectionFilterBar from './DataTableSelectionFilterBar/DataTableSelectionFilterBar';
import DataTableToolbar from './DataTableToolbar/DataTableToolbar';
import { filterByProperties, filterFunction, getFilterableProperties } from './datatable-filter';
import { getComparator } from './get-comparator';
import {
  ColumnRowTemplate,
  DataTableHeadCell,
  DataTableSelectedFilterOptions,
  Entity,
  Filter,
  SortOrder,
} from './models';
import { useTranslation } from 'languages';
import { useDeviceSize } from 'shared/responsive';

const rowsPerPageOptions = [5, 10, 25, 50];

const defaultRowsPageSize = 10;

export interface DataTableColumnHeader {
  name: string;
  isSortable: boolean;
}

export interface DataTableProps<T> {
  header: string;
  columnRowTemplates: ColumnRowTemplate<T>[];
  rows: T[];
  isLoading?: boolean;
  tableAdditionalInfo?: string;
  actionButtons?: ReactNode;
  selectedItemsActions?: ReactNode;
  onItemsIdsSelected?: (ids: (string | number)[]) => void;
  filters?: Filter<T>[];
  additionaFilterJSX?: ReactNode;
  additionalFilter?: string;
  datePickerJSX?: ReactNode;
  multiselectFilter?: boolean;
  downloadIcon?: boolean;
  deleteButton?: 'none' | 'disabled';
  searchBox?: string;
  searchInitValue?: string;
  checkboxes?: string;
  cellBackground?: Background;
  minimised?: boolean;
  displayTable?: boolean;
  additionalRow?: ReactNode;
  additionalSelectList?: ReactNode;
  shadow?: string;
  pageSize?: PageSize;
  chartRow?: ReactNode;
  deleteButtonText?: string;
  deleteButtonIcon?: ReactNode;
  deleteButtonDisabled?: boolean;
  onDelete?: () => void;
  deleteDisabledPrompt?: string;
  clearSelected?: boolean;
  onDownload?: (sortedAndFilteredRows?: T[] | []) => void;
  searchPlaceholder?: string;
  isExtensibleSearch?: boolean;
  additionalSearch?: (row: T, filter: string) => boolean;
  additionalFilterFunction?: (row: T, filter: string) => boolean;
  hideToolbar?: boolean;
  page?: number;
  setPage?: (page: number) => void;
  pageSizeControlled?: number;
  setPageSize?: (pageSize: number) => void;
  totalCount?: number;
  hideDeleteButton?: boolean;
  externalSortHandler?: (sortBy: string, sortDesc: boolean) => void;
  additionalSearchColumns?: (keyof T)[];
  scrollable?: boolean;
  scrollableHeight?: string;
  externalSearchHandler?: (val: string) => void;
  disabledTotal?: boolean;
  disabledBorder?: boolean;
  headerSx?: SxProps<Theme>;
  disableHeaderSeparator?: boolean;
  downloadIconColor?: string;
  searchControlled?: string;
  setSearchControlled?: (value: string) => void;
  externalFilterHandler?: (filters: DataTableSelectedFilterOptions<T>[]) => void;
  externalIdsList?: (string | number)[];
  customRowBody?: ReactNode;
  addPreColumn?: boolean;
  externalSelected?: (string | number)[];
  setExternalSelected?: Dispatch<SetStateAction<(string | number)[]>>;
  customTotalInfo?: string;
  customLoadingHeight?: string;
  customDeleteStyle?: SxProps<Theme>;
}

const DataTable = <T extends Entity>(props: PropsWithChildren<DataTableProps<T>>) => {
  const [pageInternal, setPageInternal] = useState(0);
  const [pageSizeInternal, setPageSizeInternal] = useState(
    typeof props.pageSize !== 'undefined' ? props.pageSize.rowsPageSize : defaultRowsPageSize,
  );
  const page = props.page ? props.page : pageInternal;
  const setPage = props.setPage ? props.setPage : setPageInternal;
  const pageSize = props.pageSizeControlled ? props.pageSizeControlled : pageSizeInternal;
  const setPageSize = props.setPageSize ? props.setPageSize : setPageSizeInternal;

  const [rowsPerPage, setRowsPerPage] = useState(
    typeof props.pageSize !== 'undefined' ? props.pageSize.rowsPerPage : rowsPerPageOptions,
  );
  const [order, setOrder] = useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof T | undefined>(undefined);
  const [filter, setFilter] = useState('');
  const [internalSelected, setInternalSelected] = useState<(string | number)[]>([]);

  const selected = props.externalSelected ?? internalSelected;
  const setSelected = props.setExternalSelected ?? setInternalSelected;

  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    DataTableSelectedFilterOptions<T>[]
  >([]);
  
  const { translate } = useTranslation();

  const componentText = {
    rowsPerPageLabel: translate("datatable_rows_per_page_label"),
    noDataInfoText: translate("dashboard_no_data_info_text"),
    of: translate("pagination_of_label")
  }
  const plantId = useSelector(selectPlantId);

  //We need to use variable empty with memoized empty array,
  //because each time DataTable receives an empty array
  //it receives new Array object instead of the reference to the same object,
  //which leads to infinite loop in useEffect below
  const empty = useMemo(() => [], []);
  const rows = useMemo(() => (props.rows.length > 0 ? props.rows : empty), [props.rows, empty]);

  useEffect(() => {
    setSelected((selected) => selected.filter((id) => rows.some((row) => id === row.id)));
  }, [rows]);

  useEffect(() => {
    if (typeof props.pageSize !== 'undefined') {
      setPageSize(props.pageSize.rowsPageSize);
      setRowsPerPage(props.pageSize.rowsPerPage);
      setPage(0);
    } else {
      setPageSize(defaultRowsPageSize);
      setRowsPerPage(rowsPerPageOptions);
      setPage(0);
    }
  }, [props.pageSize]);

  const alert = useSelector(selectTypeOfAlert);
  const lastMeasurement = useSelector(selectMeasurement);
  useEffect(() => {
    setPage(0);
  }, [alert, lastMeasurement]);

  useEffect(() => {
    props.onItemsIdsSelected?.(selected);
  }, [selected]);

  useEffect(() => {
    if (props.clearSelected) setSelected([]);
  }, [props.clearSelected]);

  const onPageChangeHandler = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const onRowsPerPageChangeHandler = (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchHandler = useCallback(
    (filter: string) => {
      if (props.externalSearchHandler) {
        props.externalSearchHandler(filter);
      } else {
        setFilter(filter);
      }
      setPage(0);
    },
    [props.externalSearchHandler, setPage],
  );

  useEffect(() => {
    searchHandler('');
  }, [plantId, searchHandler]);

  const selectAllHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (props.externalIdsList) {
        setSelected(props.externalIdsList);
        return;
      }
      const selectedIds = sortedAndFilteredRows.map((row) => row.id);
      setSelected(selectedIds);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = useCallback(
    (id: string | number) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: (string | number)[] = [];

      if (props.checkboxes === 'single-select') {
        setSelected(selectedIndex !== -1 ? [] : [id]);
        return;
      }

      if (selectedIndex === -1) {
        newSelected = [...selected, id];
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    },
    [props.checkboxes, selected],
  );

  const isSelected = useCallback((id: string | number) => selected.indexOf(id) !== -1, [selected]);

  const dataTableSelectedFilterHandler = (selectedFilters: DataTableSelectedFilterOptions<T>[]) => {
    setSelectedFilterOptions(selectedFilters);
    setPage(0);
  };

  const handleRequestSort = (property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setPage(0);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    props.externalSortHandler?.(property.toString(), isAsc);
  };

  useEffect(() => {
    if (props.externalFilterHandler) {
      props.externalFilterHandler(selectedFilterOptions);
    }
  }, [props.externalFilterHandler, selectedFilterOptions]);

  const filteredRowsByFilterOptions = useMemo(() => {
    if (props.externalFilterHandler) {
      return rows;
    }

    return selectedFilterOptions
      .reduce((prev, curr) => filterFunction(prev, curr.key, curr.value), rows)
      .filter((row) =>
        props.additionalFilter
          ? props.additionalFilterFunction?.(row, props.additionalFilter)
          : true,
      );
  }, [props.externalFilterHandler, rows, selectedFilterOptions, props.additionalFilter]);

  const sortedAndFilteredRows = useMemo(
    () =>
      filterByProperties(
        props.externalSortHandler
          ? filteredRowsByFilterOptions
          : [...filteredRowsByFilterOptions].sort(getComparator(order, orderBy)),
        props.additionalSearchColumns
          ? [...getFilterableProperties(props.columnRowTemplates), ...props.additionalSearchColumns]
          : getFilterableProperties(props.columnRowTemplates),
        filter,
        !!props.isExtensibleSearch,
        props.additionalSearch,
      ),
    [
      filter,
      filteredRowsByFilterOptions,
      order,
      orderBy,
      props.additionalSearch,
      props.additionalSearchColumns,
      props.columnRowTemplates,
      props.externalSortHandler,
      props.isExtensibleSearch,
    ],
  );

  const mappedSortedAndFilteredRows = useMemo(
    () =>
      props.setPage && props.setPageSize
        ? sortedAndFilteredRows.map((row: T, index: number) =>
            props.displayTable !== false ? (
              <DataTableRow
                key={row.id}
                cellsTemplates={props.columnRowTemplates.map((x) => x.rowTemplateFn(row))}
                rowId={row.id}
                minimised={props.minimised}
                isRowSelected={isSelected(row.id)}
                onRowCheckboxClick={handleRowClick}
                checkboxes={props.checkboxes}
                cellBackground={props.cellBackground}
              />
            ) : (
              <Tile
                key={row.id}
                row={row}
                index={index}
                length={sortedAndFilteredRows.length - 1}
                page={page}
              />
            ),
          )
        : sortedAndFilteredRows
            .slice(page * pageSize, page * pageSize + pageSize)
            .map((row: T, index: number) =>
              props.displayTable !== false ? (
                <DataTableRow
                  key={row.id}
                  cellsTemplates={props.columnRowTemplates.map((x) => x.rowTemplateFn(row))}
                  rowId={row.id}
                  minimised={props.minimised}
                  isRowSelected={isSelected(row.id)}
                  onRowCheckboxClick={handleRowClick}
                  checkboxes={props.checkboxes}
                  cellBackground={props.cellBackground}
                />
              ) : (
                <Tile
                  key={row.id}
                  row={row}
                  index={index + page * pageSize}
                  length={sortedAndFilteredRows.length - 1}
                  page={page}
                />
              ),
            ),
    [
      props.setPage,
      props.setPageSize,
      props.displayTable,
      props.columnRowTemplates,
      props.checkboxes,
      props.cellBackground,
      sortedAndFilteredRows,
      page,
      pageSize,
      isSelected,
      handleRowClick,
    ],
  );

  const {isPhone, isTablet} = useDeviceSize();

  return (
    <StrokeContainer sx={props.disabledBorder ? { border: 0 } : undefined}>
      {!props.hideToolbar && (
        <DataTableToolbar
          header={props.header}
          headerSx={props.headerSx}
          total={props.totalCount ?? sortedAndFilteredRows.length}
          onSearch={props.setSearchControlled ? props.setSearchControlled : searchHandler}
          searchControlled={props.searchControlled}
          searchBox={props.searchBox}
          searchPlaceholder={props.searchPlaceholder}
          leftToolbarAdditionalText={props.tableAdditionalInfo}
          rightToolbarSlot={props.actionButtons}
          leftToolbarAdditionalDownloadIcon={props.downloadIcon}
          leftToolbarAdditionalSelect={props.additionalSelectList}
          onDownload={props.onDownload}
          disabledTotal={props.disabledTotal}
          disableHeaderSeparator={props.disableHeaderSeparator}
          downloadIconColor={props.downloadIconColor}
          customTotalInfo={props.customTotalInfo}
          sortedAndFilteredRows={
            props.onDownload && props.onDownload.length > 0 ? sortedAndFilteredRows : undefined
          }
          searchInitValue={props.searchInitValue}
        />
      )}
      <DataTableSelectionFilterBar
        filters={props.filters}
        additionalFilterJSX={props.additionaFilterJSX}
        datePickerJSX={props.datePickerJSX}
        onDataTableSelectedFilter={dataTableSelectedFilterHandler}
        multiselectFilter={props.multiselectFilter ? props.multiselectFilter : false}
      />
      <DataTableChartBar row={props.chartRow} />
      <DataTableAdditionalBar row={props.additionalRow} displayTable={props.displayTable} />
      <TableContainer
        sx={
          props.scrollable
            ? {
                display: 'block',
                height: props.scrollableHeight || '30rem',
                overflowY: 'auto',
                '&::-webkit-scrollbar': { width: '5px', height: isPhone || isTablet ? '5px' : '6px'},
                '&::-webkit-scrollbar-thumb': { background: '#337179' },
                '&::-webkit-scrollbar-track-piece:start': {
                  background: 'transparent',
                  mt: '3.5rem',
                  flexWrap: 'nowrap',
                },
              }
            : props.displayTable === false
              ? { overflowX: 'hidden' }
              : undefined
        }
      >
        <Table stickyHeader={props.scrollable}>
          {/* <Table data-testid={props.testId}> */}
          {props.displayTable !== false && (
            <DataTableHeadContainer
              selectedCount={selected.length}
              rowCount={
                props.externalIdsList !== undefined
                  ? props.externalIdsList.length
                  : props.totalCount ?? sortedAndFilteredRows.length
              }
              onSelectAllClick={selectAllHandler}
              checkboxes={props.checkboxes}
              scrollable={props.scrollable}
              addPreColumn={props.addPreColumn}
            >
              {!selected.length && (
                <DataTableDefaultHead
                  minimised={props.minimised}
                  headCells={mapToHeadCells(props.columnRowTemplates)}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  cellBackground={props.cellBackground}
                />
              )}
              {!!selected.length && (
                <DataTableItemsSelectedHead
                  checkboxes={props.checkboxes}
                  headCellsLength={props.columnRowTemplates.length}
                  selectedCount={selected.length}
                  deleteButton={props.deleteButton}
                  customDeleteText={props.deleteButtonText}
                  customDeleteIcon={props.deleteButtonIcon}
                  isDisabled={props.deleteButtonDisabled}
                  onDelete={props.onDelete}
                  hideDeleteButton={props.hideDeleteButton}
                  deleteDisabledPrompt={props.deleteDisabledPrompt}
                  customDeleteStyle={props.customDeleteStyle}
                >
                  {props.selectedItemsActions}
                </DataTableItemsSelectedHead>
              )}
            </DataTableHeadContainer>
          )}
          {props.displayTable !== false && (
            <TableBody data-testid='table_item_container'>
              {props.isLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={
                      props.checkboxes === 'none'
                        ? props.columnRowTemplates.length
                        : props.columnRowTemplates.length + 1
                    }
                  >
                    <Loading
                      height={
                        props.customLoadingHeight
                          ? props.customLoadingHeight
                          : props.scrollable
                            ? '24rem'
                            : undefined
                      }
                    />
                  </TableCell>
                </TableRow>
              ) : (
                props.customRowBody ?? mappedSortedAndFilteredRows
              )}
            </TableBody>
          )}
          {props.displayTable === false &&
            (props.isLoading ? (
              <Loading
                height={
                  props.customLoadingHeight
                    ? props.customLoadingHeight
                    : props.scrollable
                      ? '24rem'
                      : '12rem'
                }
                dashboard={true}
              />
            ) : sortedAndFilteredRows.length > 0 ? (
              <DataTableTilesBody>{mappedSortedAndFilteredRows}</DataTableTilesBody>
            ) : (
              <SelectDataPrompt infoText= {`${componentText.noDataInfoText}`} />
            ))}
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={componentText.rowsPerPageLabel}
        rowsPerPageOptions={rowsPerPage}
        component='div'
        count={props.totalCount ? props.totalCount : sortedAndFilteredRows.length}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={onPageChangeHandler}
        onRowsPerPageChange={onRowsPerPageChangeHandler}
        labelDisplayedRows={
          ({ from, to, count }) => {
            return `${from}-${to} ${componentText.of} ${count}`
          }
        }
      />
    </StrokeContainer>
  );
};

function mapToHeadCells<T>(columnRowTemplates: ColumnRowTemplate<T>[]): DataTableHeadCell<T>[] {
  return columnRowTemplates.map((item) => ({
    label: item.columnName,
    sortable: !!item.sortable,
    columnProperty: item.columnProperty,
    renderIcon: item.renderIcon,
    columnIcon: item.columnIcon,
  }));
}

export default DataTable;
