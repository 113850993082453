import {useAuthQuery } from './../queries';
import { GetDataResponse } from './usePostCollectRawData';
export interface GetCollectRawDataParams {
  assetId: number;
  sensorId: number;
  enabled: number | boolean | undefined;
}

export const useGetCollectRawData = ({assetId, sensorId, enabled}: GetCollectRawDataParams) => {

  return useAuthQuery<GetDataResponse, GetCollectRawDataParams>({
    url: `assets/${assetId}/sensors/${sensorId}/raw-data`,
    queryOptions: {
      refetchInterval: 5000,
      enabled: Boolean(enabled),
      gcTime: 2000,
    },
  });
};


