import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { AssetType } from '../CreateAssetModalFlow/CreateAsset';
import { ResponseData, useUpdateAsset } from 'api/plants/useUpdateAsset';
import { AssetGroup } from 'features/plants-management/models/asset.model';
import { DisplayType } from './EditAsset';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import SelectPartNoModal from '../SelectPartNoModal/SelectPartNoModal';
import { PartBody } from 'api/plants/useGetCustomParts';
import { CatalogPartBody } from 'api/plants/useGetCatalogParts';
import { AdditionalField } from 'api/plants/useGetAssetTypeConfiguration';
import EditSummary from './EditSummary';
import ManageAssetApplication from '../CreateAssetModalFlow/ManageAssetApplication';
import { useTranslation } from 'languages';
import { useDeviceSize, useResponsiveLanguage } from 'shared/responsive';
import { EditAssetTabsRefProps } from './EditAssetTabs';

interface DetailsFormProps {
  assetName: string | undefined;
  newAssetTypeConf: { [key: string]: string | number };
  assetTypeConfiguration: AdditionalField[] | undefined;
  // nominalSpeed: number | undefined;
  partNumber: string | undefined;
  assetType: string | undefined;
  partDescription: string | undefined;
  serialNumber: string | undefined;
  note: string | undefined;
  assetGroup: number | undefined;
  assetGroups: AssetGroup[];
  partId: number | undefined;
  setPartId: (partId: number) => void;
  setAssetName: (e: string) => void;
  setNewAssetTypeConf: (
    fn: (type: { [key: string]: string | number }) => { [key: string]: string | number },
  ) => void;
  // setNominalSpeed: (e: number) => void;
  setPartNumber: (e: string) => void;
  setAssetType: (e: AssetType) => void;
  setPartDescription: (e: string) => void;
  setAssetGroup: (e: number) => void;
  setSerialNumber: (e: string) => void;
  setNote: (e: string) => void;
  selectedAsset: number;
  onCommission: (displayType: DisplayType) => void;
  disableInput?: boolean;
  partProperties:
    | {
        [key: string]: {
          label: string;
          type: string;
          value: string | number;
          unit: string;
        };
      }
    | {};
  assetApplicationId: number | null;
  setAssetApplicationId: Dispatch<SetStateAction<number | null>>;
  sensorsQuantity: number | undefined;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setDisabledSave: Dispatch<SetStateAction<boolean>>;
}

const DetailsForm = forwardRef<EditAssetTabsRefProps, DetailsFormProps>(
  (
    {
      selectedAsset,
      assetName,
      newAssetTypeConf,
      assetTypeConfiguration,
      partNumber,
      assetType,
      partDescription,
      serialNumber,
      note,
      assetGroup,
      assetGroups,
      partId,
      partProperties,
      setPartId,
      setAssetName,
      setNewAssetTypeConf,
      setPartNumber,
      setAssetType,
      setPartDescription,
      setAssetGroup,
      setSerialNumber,
      setNote,
      disableInput,
      onCommission,
      assetApplicationId,
      setAssetApplicationId,
      sensorsQuantity,
      setIsLoading,
      setDisabledSave,
    },
    ref,
  ) => {
    const [summaryData, setSummaryData] = useState<ResponseData[]>([]);
    const [selectPartNoModalOpen, setSelectPartNoModalOpen] = useState(false);
    const [openSummary, setOpenSummary] = useState(false);
    const { translate } = useTranslation();
    const componentText = useMemo(() => {
      return {
        requiredFieldsHeader: translate('plants_required_fields_header'),
        optionalFieldsHeader: translate('plants_optional_fields_header'),
        addAssetPhoto: translate('plants_add_asset_photo'),
        addNameplatePhoto: translate('plants_add_nameplate_photo'),
        helperNameText: translate('plants_helper_name_text'),
        partNumberLabel: translate('plants_part_number_label'),
        assetTypeLabel: translate('plants_asset_type_label'),
        assetGroupLabel: translate('plants_asset_group_label'),
        partDescriptionLabel: translate('plants_part_description_label'),
        addButtonText: translate('plants_add_button'),
        serialNumberLabel: translate('plants_serial_number_label'),
        noteLabel: translate('plants_notes_label'),
        assetNameLabel: translate('asset_name_label'),
        createButton: translate('create_button_label'),
        commissionButton: translate('plants_commission_button'),
        applyAll: translate('apply_all_info'),
        nominalSpeedLabel: translate('nominal_speed_label'),
        laggingStyleLabel: translate('lagging_style_label'),
        laggingDepthLabel: translate('lagging_depth_label'),
      };
    }, []);

    const { mutate: updateAssetMutation, isPending } = useUpdateAsset(
      selectedAsset.toString(),
      (data: ResponseData[]) => {
        if (sensorsQuantity) {
          setSummaryData(data);
          setOpenSummary(true);
        }
      },
    );

    useEffect(() => {
      setIsLoading(isPending);
    }, [isPending, setIsLoading]);

    const { isPhone } = useDeviceSize();
    const responsiveProps = {
      gridMarginTop: useResponsiveLanguage({
        en: { xs: { mt: 0 }, md: { mt: 1 } },
      }),
    };

    const handleUpdateAsset = useCallback(() => {
      updateAssetMutation({
        body: {
          name: assetName,
          configuration: newAssetTypeConf,
          assetGroupId: assetGroup,
          serialNumber: serialNumber,
          note: note,
          partId: partId,
          assetApplicationId: assetApplicationId!,
        },
      });
    }, [
      assetApplicationId,
      assetGroup,
      assetName,
      newAssetTypeConf,
      note,
      partId,
      selectedAsset,
      serialNumber,
      updateAssetMutation,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        applyChanges: handleUpdateAsset,
      }),
      [handleUpdateAsset],
    );

    useEffect(() => {
      setDisabledSave(
        !(
          assetName &&
          assetTypeConfiguration?.every(
            (conf) =>
              newAssetTypeConf[conf.name] !== '' && newAssetTypeConf[conf.name] !== undefined,
          ) &&
          partNumber &&
          assetType &&
          assetApplicationId
        ),
      );
    }, [
      assetApplicationId,
      assetName,
      assetType,
      assetTypeConfiguration,
      newAssetTypeConf,
      partNumber,
      setDisabledSave,
    ]);

    const handleCommission = () => {
      onCommission('commission');
    };

    const onPartSave = (part: PartBody | CatalogPartBody) => {
      setAssetType(part.assetType as AssetType);
      setPartDescription(part.description ?? '');
      setPartNumber(part.number);
      setPartId(part.id);
    };

    const additionalConfFields = useMemo(
      () =>
        assetTypeConfiguration
          ?.filter((typeConf) => typeConf.name !== 'speed')
          .map((conf) => {
            if (conf.name === 'polesNumber') {
              return (
                <Grid item xs={6} key={conf.name}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      size='small'
                      required
                      label={conf.label}
                      value={newAssetTypeConf[conf.name]}
                      onChange={(e) => {
                        const newVal = Number(e.target.value);
                        setNewAssetTypeConf((typeConf) => {
                          const newConf = { ...typeConf };
                          newConf[conf.name] = newVal;
                          return newConf;
                        });
                      }}
                    >
                      {[2, 4, 6, 8, 10, 12].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              );
            }
            return (
              <Grid item xs={6} key={conf.name}>
                <FormControl fullWidth>
                  <TextField
                    size='small'
                    required
                    label={conf.label}
                    type={conf.type === 'string' ? 'text' : 'number'}
                    value={newAssetTypeConf[conf.name]}
                    inputProps={{
                      step: conf.type === 'int' ? '1' : '0.01',
                      min: 0,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>{conf.unit}</InputAdornment>,
                    }}
                    onChange={(e) => {
                      if (
                        conf.type === 'int' &&
                        !/^[0-9\b]+$/.test(e.target.value) &&
                        e.target.value !== ''
                      ) {
                        return;
                      }
                      const newVal =
                        conf.type !== 'string' && e.target.value !== ''
                          ? Number(e.target.value)
                          : e.target.value;

                      const valToSave =
                        typeof newVal === 'number' && conf.name === 'powerFrequency' && newVal > 250
                          ? 250
                          : newVal;

                      setNewAssetTypeConf((typeConf) => {
                        const newConf = { ...typeConf };
                        newConf[conf.name] = valToSave;
                        return newConf;
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            );
          }),
      [assetTypeConfiguration, newAssetTypeConf],
    );

    return (
      <>
        <Typography sx={{ mb: 2, mt: 2, fontWeight: 500 }} variant='h6'>
          {componentText.requiredFieldsHeader}
        </Typography>
        <Grid
          container
          item
          xs={12}
          justifyContent='space-between'
          alignItems='flex-start'
          spacing={2}
        >
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                data-testid='asset_edit_asset_name_input'
                size='small'
                required
                label={componentText.assetNameLabel}
                value={assetName}
                onChange={(e) => setAssetName(e.target.value)}
                helperText={componentText.helperNameText}
                disabled={disableInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {assetTypeConfiguration?.some((assetTypeConf) => assetTypeConf.name === 'speed') && (
              <FormControl fullWidth>
                <TextField
                  data-testid='asset_edit_nominal_speed_input'
                  size='small'
                  required
                  label={componentText.nominalSpeedLabel}
                  type='number'
                  value={newAssetTypeConf['speed']}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>RPM</InputAdornment>,
                  }}
                  onChange={(e) => {
                    if (!/^[0-9\b]+$/.test(e.target.value) && e.target.value !== '') {
                      return;
                    }
                    const newVal =
                      e.target.value !== '' ? parseInt(e.target.value) : e.target.value;

                    const valToSave = typeof newVal === 'number' && newVal > 50000 ? 50000 : newVal;

                    setNewAssetTypeConf((typeConf) => {
                      const newConf = { ...typeConf };
                      newConf['speed'] = valToSave;
                      return newConf;
                    });
                  }}
                  disabled={disableInput}
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent='space-between'
          alignItems='flex-start'
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                data-testid='asset_edit_part_number_input'
                value={partNumber}
                size='small'
                required
                label={componentText.partNumberLabel}
                onChange={(e) => {
                  // setPartNumber(e.target.value)
                  e.preventDefault();
                  setSelectPartNoModalOpen(true);
                }}
                onClick={() => setSelectPartNoModalOpen(true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <EditIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                data-testid='asset_edit_asset_type_input'
                value={assetType}
                size='small'
                label={componentText.assetTypeLabel}
                disabled
                onChange={(e) => setAssetType(e.target.value as AssetType)}
              ></TextField>
            </FormControl>
          </Grid>
          {additionalConfFields}
          <Grid
            container
            justifyContent='space-between'
            alignItems='flex-start'
            spacing={2}
            sx={{ mt: 1, ml: 0 }}
          >
            <Grid item xs={12} md={6}>
              <ManageAssetApplication
                assetApplicationId={assetApplicationId}
                setAssetApplicationId={setAssetApplicationId}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }}></Divider>
          </Grid>
        </Grid>
        <Grid container justifyContent='space-between' spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' sx={{ fontWeight: 500 }}>
              {componentText.optionalFieldsHeader}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                value={partDescription}
                size='small'
                label={componentText.partDescriptionLabel}
                disabled
                data-testid='asset_edit_part_description_input'
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                data-testid='asset_edit_asset_group_input'
                value={assetGroup}
                select
                size='small'
                label={componentText.assetGroupLabel}
                onChange={(e) => setAssetGroup(Number(e.target.value))}
                disabled={disableInput}
              >
                {assetGroups.map((option) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent='space-between'
          alignItems='flex-start'
          spacing={2}
          sx={responsiveProps.gridMarginTop}
        >
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                data-testid='asset_edit_serial_number_input'
                value={serialNumber}
                size='small'
                label={componentText.serialNumberLabel}
                onChange={(e) => setSerialNumber(e.target.value)}
                disabled={disableInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                data-testid='asset_edit_note_textfield'
                value={note}
                size='small'
                multiline
                rows={3}
                label={componentText.noteLabel}
                onChange={(e) => setNote(e.target.value)}
                disabled={disableInput}
              />
            </FormControl>
          </Grid>

          {!disableInput && (
            <Grid xs={12} item>
              <Button
                variant='contained'
                onClick={handleCommission}
                startIcon={<LinkIcon data-testid='plant_managment_asset_commission_button' />}
                fullWidth={isPhone}
              >
                {componentText.commissionButton}
              </Button>
            </Grid>
          )}
        </Grid>
        {selectPartNoModalOpen && (
          <SelectPartNoModal
            isOpen={selectPartNoModalOpen}
            onClose={() => setSelectPartNoModalOpen(false)}
            onPartSave={onPartSave}
            disableSelect={disableInput}
          />
        )}
        {openSummary && (
          <EditSummary
            open={openSummary}
            onClose={() => setOpenSummary(false)}
            summaryData={summaryData}
          />
        )}
      </>
    );
  },
);

DetailsForm.displayName = 'DetailsForm';

export default DetailsForm;
