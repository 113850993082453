import { Box, Typography } from "@mui/material";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { memo, ReactNode } from "react";
import CookiePageContent from "./CookiePageContent";
import AcceptArgeement from "./LicenceArgeement/AcceptArgeement";

type Props = {
  open: boolean;
  children: ReactNode;
  title: string;
  showAccept: boolean;
};

const CookiePageContainer = (props: Props) => { 
  // TODO: refactor later
  const { width } = useWindowDimensions(); 
  let heightString = "60rem";
  let marginTopString = "0rem";
  let fontTitleString = "1.8rem";
  let lineHeightString = "3rem";
  let paddingNumber = 2;
  let minHeightString= "10rem";
  let paddingBottomNumber = 3;
  let maxHeightString = "calc(100% - 340px)";
  let leftPaddingString = "calc(50% - 1.1rem)";
  let leftTitlePadding = 0.5;

  if(width < 550){
    fontTitleString = "1rem";
    lineHeightString = "1.5rem";
    paddingNumber = 1;
    paddingBottomNumber = 1;
  }
  if (width < 315) {
    heightString = "13.75rem";
  }
  else if(width < 450) {
    heightString = "18.75rem";
  }
  else if(width < 550){
    heightString = "25rem";
  }
  else if(width < 850){
    heightString = "37.625rem";
    marginTopString = "1.5rem";
    paddingNumber = 0;
    lineHeightString ="2rem"
    fontTitleString="1.2rem";
    leftPaddingString = "calc(50% - 0.4rem)";
    leftTitlePadding = 2
  }
  else if(width < 950){
    heightString = "50rem";
    marginTopString = "1rem";
    leftPaddingString = "calc(50% - 0.2rem)";
    leftTitlePadding = 2
  }
  return (
    <Box 
      width={heightString}
      marginTop={marginTopString}
      minHeight={minHeightString}
      maxHeight={props.showAccept ? '85%' : maxHeightString}
      p={paddingNumber}
      pb={paddingBottomNumber}
      position="fixed"
      top={props.showAccept ? "calc(50% + 30px)" : "calc(50% - 40px)"}
      left={props.showAccept ? "calc(50% + 30px)" : leftPaddingString}
      sx={{
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        boxShadow:
          "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
        borderRadius: "16px",
        background: "white",
      }}
      display={props.open ? "flex" : "none"}
      flexDirection="column"
      justifyContent="center"
    >
      <Typography color="primary" fontSize={fontTitleString} lineHeight={lineHeightString} pt={2} pl={leftTitlePadding}>
        {props.title}
      </Typography>
      <CookiePageContent content={props.children} fullView = {props.showAccept} />
      { props.showAccept &&
           <AcceptArgeement />
      }
    </Box>
  );
};

export default memo(CookiePageContainer);
