import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface RemovePlantParams {
  plantId: number;
  email: string;
}

export const useRemovePlantById = (onSuccess?: () => any) => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('plant_successfully_deleted_toast'),
      errorMess: translate('plant_cannot_be_deleted_toast'),
    };
  }, []);
  return useAuthMutation<RemovePlantParams, undefined, undefined>({
    url: (params) => `plants/${params.plantId}`,
    method: 'DELETE',
    mutationOptions: {
      onSuccess: () => {
        toast.success(componentText.successMess);
        onSuccess?.();
        queryClient.invalidateQueries({
          queryKey: ['roles'],
        });
        queryClient.invalidateQueries({
          queryKey: ['plants'],
        });
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
