import React, { useEffect, useMemo } from 'react';
import Settings from './Settings';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectColorScale,
  selectIsDowntimeSelected,
  selectPeakDetection,
  selectViewType,
  selectedMeasurements,
  setColorScale,
  setPeakDetection,
  setViewType,
} from 'features/assets-management/store/assetsManagementSlice';
import { checkboxStyles } from './MeasurementSettings';
import { useTranslation } from 'languages';

const dataViewOptions = ['3d', 'Spectrogram'];

const colorScaleOptions : { id : string; name : string }[] = [
  { id: 'Greys', name: 'Greyscale' },
  { id: 'Viridis', name: 'Viridis' },
  { id: 'Electric', name: 'Inferno' },
  { id: 'Cividis', name: 'Cividis' },
];
const DisplaySettings = () => {
  const dispatch = useDispatch();
  const checkedMeasurements = useSelector(selectedMeasurements);
  const view = useSelector(selectViewType);
  const colorScale = useSelector(selectColorScale);
  const isDowntimeSelected = useSelector(selectIsDowntimeSelected);

  const peakDetection = useSelector(selectPeakDetection);

  useEffect(() => {
    if (checkedMeasurements.length < 2) {
      dispatch(setViewType(''));
    } else {
      if (!view) {
        dispatch(setViewType('3d'));
      }
    }
  }, [checkedMeasurements]);

  useEffect(() =>{
    isDowntimeSelected && dispatch(setPeakDetection(false))
  }, [isDowntimeSelected])

  const togglePeaks = () =>{
    dispatch(setPeakDetection(!peakDetection))
  }

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      fftDisplaySettings: translate("fft_display_settings"),
      fftSelectDataView: translate("fft_select_data_view"),
      fftColorScale: translate("fft_color_scale"),
      fftDisplaySignificantPeaks: translate("fft_display_significant_peaks"),
      spectrogramLabel: translate("spectrogram_label")
    };
  }, []);
  
  return (
    <Settings title={componentText.fftDisplaySettings}>
      <React.Fragment>
        <Grid item>
          <FormControl size='small' fullWidth>
            <InputLabel id='select-data-view-label'>{componentText.fftSelectDataView}</InputLabel>
            <Select
              data-testid='assets_management_vibration_fft_select_data_view_input'
              labelId='select-data-view-label'
              id='select-data-view'
              value={checkedMeasurements.length < 2 ? '' : view}
              onChange={(e) => dispatch(setViewType(e.target.value))}
              label={componentText.fftSelectDataView}
              sx={{ '&.MuiOutlinedInput-root': { background: 'white' } }}
              disabled={checkedMeasurements.length < 2}
            >
              {dataViewOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  data-testid='assets_management_vibration_fft_select_data_view_items'
                >
                  {option.includes("Spectrogram") ? componentText.spectrogramLabel : option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {view === 'Spectrogram' && (
          <Grid item>
            <FormControl size='small' fullWidth>
              <InputLabel id='select-data-view-label'>{componentText.fftColorScale}</InputLabel>
              <Select
                data-testid='assets_management_vibration_fft_color_scale_input'
                labelId='select-color-scale-label'
                id='select-color-scale'
                value={colorScale}
                onChange={(e) => dispatch(setColorScale(e.target.value))}
                label={componentText.fftColorScale}
                sx={{ '&.MuiOutlinedInput-root': { background: 'white' } }}
              >
                {colorScaleOptions.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    data-testid='assets_management_vibration_fft_color_scale_items'
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item>
        <FormControlLabel
            checked={peakDetection}
            label={componentText.fftDisplaySignificantPeaks}
            control={<Checkbox disableRipple sx={checkboxStyles} />}
            onChange={() => togglePeaks()}
            sx={{ '& .MuiTypography-root': { fontSize: '.75rem' }, ml: -1, mt: -1 }}
            disabled={isDowntimeSelected || checkedMeasurements.length === 0 || checkedMeasurements.length > 1}
          />
        </Grid>
      </React.Fragment>
    </Settings>
  );
};

export default DisplaySettings;
