import { Checkbox, TableCell, TableRow } from '@mui/material';
import { Background } from 'features/dashboard/models/backgroundCell.model';
import { FC, ReactNode } from 'react';

interface DataTableRowProps {
  rowId: string | number;
  cellsTemplates: ReactNode[];
  minimised ?: boolean;
  isRowSelected: boolean;
  onRowCheckboxClick?: (rowId: string | number) => void;
  checkboxes?: string;
  cellBackground?: Background;
}

const DataTableRow: FC<DataTableRowProps> = (props) => {
  const handleCheckboxClick = (rowId: string | number) => {
    props.onRowCheckboxClick?.(rowId);
  };

  const labelId = `datatable-checkbox-${props.rowId}`;
  const dataTestIdNumberLabel = `check_box_${props.rowId}`;
  return (
    <TableRow
      hover
      key={props.rowId}
      selected={props.isRowSelected}
      role='checkbox'
      aria-checked={props.isRowSelected}
      data-testid={dataTestIdNumberLabel}
    >
      {props.checkboxes !== 'none' && (
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            checked={props.isRowSelected}
            onClick={() => handleCheckboxClick(props.rowId)}
            inputProps={{
              'aria-labelledby': labelId,
            }}
            data-testid={dataTestIdNumberLabel}
          />
        </TableCell>
      )}
      {props.cellsTemplates.map((template, index) => (
        <TableCell
          key={`${props.rowId}_${index}`}
          sx={{
            padding: props.minimised ? 0.5 : 2,
            backgroundColor:
              props.cellBackground && props.cellBackground.cells.includes(index)
                ? props.cellBackground.color
                : 'inherit',
          }}
        >
          {template}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default DataTableRow;
