import { useAuthMutation } from "api/queries";
import { useTranslation } from "languages";
import { useMemo } from "react";
import { toast } from "react-toastify";

interface IntellilubeOnOffParams {
  assetId: number;
  serialNumber: string;
  onOff: boolean;
}

export const useSetIntellilubeOnOff = (onSuccess?: () => void) => {

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      onText: translate("successfully_turned_on"),
      offText: translate("successfully_turned_off"),
      faildOnText: translate("failed_turned_on"),
      faildOffText: translate("failed_turned_off"),
    };
  }, []);

  return useAuthMutation<IntellilubeOnOffParams, {}>({
    url: (params: IntellilubeOnOffParams) =>
      `assets/${params.assetId}/sensors/${params.serialNumber}/turn-on-off?onOff=${params.onOff}`,
    method: "POST",
    mutationOptions: {
      onSuccess: (_, { params }) => {
        toast.success(`${params?.onOff ? componentText.onText : componentText.offText }`);
        onSuccess?.();
      },
      onError: (_, { params }) => {
        toast.error(`${params?.onOff ? componentText.faildOnText : componentText.faildOffText }`);
      },
    },
  });
};