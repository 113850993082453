import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPlantId } from 'store/accountSlice';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

type Email = string;

export type PlantRoleApiModel =
  | 'general_user'
  | 'plant_administrator'
  | 'plant_viewer'
  | 'super_administrator'
  | 'super_viewer';

export interface InviteUsersToPlantBody {
  users: {
    email: Email;
    role: PlantRoleApiModel | string;
    assetGroupIds?: number[];
  }[];
}

export const useInviteUsersToPlant = (onSuccess: () => void) => {
  const plantId = useSelector(selectPlantId);
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('users_failed_to_be_invited_to_plant_toast'),
    };
  }, []);
  return useAuthMutation<{}, InviteUsersToPlantBody>({
    url: () => `plants/${plantId}/users`,
    method: 'POST',
    mutationOptions: {
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('users') });
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
