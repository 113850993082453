import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AssignedAsset,
  AssignedSensor,
  CommissioningAsset,
  Gateway,
} from '../components/AssetsTable/SensorCommissionByGateway/models';
import { RootState } from 'app/store';
import { SensorType } from 'models/sensor/models';

export interface PlantsManagementState {
  bulkCommission: {
    maxSensorLimit: number;
    gateway: Gateway | undefined;
    sensorType: SensorType;
    assetList: AssignedAsset[];
    commissioningAssets: CommissioningAsset[];
  };
  sensorCommissionStatus : [] | { location : string; serialNumber : string }[];
  assetGroupFilter : string;
}

const initialState: PlantsManagementState = {
  bulkCommission: {
    maxSensorLimit: 20,
    gateway: undefined,
    sensorType: 'Sensor',
    assetList: [],
    commissioningAssets: [],
  },
  sensorCommissionStatus: [],
  assetGroupFilter: 'all',
};

export const plantsManagementSlice = createSlice({
  name: 'plantsManagement',
  initialState,
  reducers: {
    setAssetList: (state, action: PayloadAction<AssignedAsset[]>) => {
      state.bulkCommission.assetList = action.payload;
    },
    assignSensor: (
      state,
      action : PayloadAction<{
        assetId : number;
        assetName : string;
        assetGroup : string;
        assetType : string;
        sensorIndex : number;
        sensor : AssignedSensor;
      }>,
    ) => {
      const { assetId, assetName, assetGroup, assetType, sensorIndex, sensor } = action.payload;

      const existingAsset = state.bulkCommission.commissioningAssets.find((a) => a.id === assetId);

      if (existingAsset) {
        const existingAssetIndex = state.bulkCommission.commissioningAssets.indexOf(existingAsset);

        state.bulkCommission.commissioningAssets[existingAssetIndex].sensors[sensorIndex] = sensor;
      } else {
        const tempSensors: AssignedSensor[] = [];
        tempSensors[sensorIndex] = sensor;
        state.bulkCommission.commissioningAssets.push({
          id: assetId,
          name: assetName,
          group: assetGroup,
          type: assetType,
          sensors: tempSensors,
        });
      }
    },
    updateSensorName: (
      state,
      action : PayloadAction<{ assetId : number; sensorIndex : number; newName : string }>,
    ) => {
      const { assetId, sensorIndex, newName } = action.payload;

      const existingAsset = state.bulkCommission.commissioningAssets.find((a) => a.id === assetId);

      if (existingAsset && existingAsset.sensors[sensorIndex]) {
        existingAsset.sensors[sensorIndex].name = newName;
      }
    },
    unassignSensor: (state, action : PayloadAction<{ assetId : number; sensorIndex : number }>) => {
      const { assetId, sensorIndex } = action.payload;

      const existingAsset = state.bulkCommission.commissioningAssets.find((a) => a.id === assetId);

      if (existingAsset && existingAsset.sensors[sensorIndex]) {
        existingAsset.sensors.splice(sensorIndex, 1);
      }
    },
    setCommissionGateway: (state, action: PayloadAction<Gateway>) => {
      state.bulkCommission.gateway = action.payload;
    },
    resetBulkCommissionState: (state) => {
      state.bulkCommission = initialState.bulkCommission;
    },
    setSensorError: (
      state,
      action : PayloadAction<{ assetId : number; sensorIndex : number; error ?: string }>,
    ) => {
      const { assetId, sensorIndex, error } = action.payload;

      const existingAsset = state.bulkCommission.commissioningAssets.find((a) => a.id === assetId);

      if (existingAsset && existingAsset.sensors[sensorIndex]) {
        existingAsset.sensors[sensorIndex].error = error;
      }
    },
    setSensorCommissionStatus: (state, action) => {
      state.sensorCommissionStatus = action.payload;
    },
    setAssetGroupFilter: (state, action) => {
      state.assetGroupFilter = action.payload;
    },
  },
});

export const {
  setAssetList,
  updateSensorName,
  assignSensor,
  unassignSensor,
  setCommissionGateway,
  resetBulkCommissionState,
  setSensorError,
  setSensorCommissionStatus,
  setAssetGroupFilter,
} = plantsManagementSlice.actions;
export default plantsManagementSlice.reducer;

// Selectors
export const selectCommissionGateway = (state : RootState) =>
  state.plantsManagement.bulkCommission.gateway;
export const selectCommissionSensorType = (state : RootState) =>
  state.plantsManagement.bulkCommission.sensorType;
export const selectCommissioningAssets = (state : RootState) =>
  state.plantsManagement.bulkCommission.commissioningAssets;
export const selectAssetList = (state : RootState) =>
  state.plantsManagement.bulkCommission.assetList;
export const selectMaxSensorLimit = (state : RootState) =>
  state.plantsManagement.bulkCommission.maxSensorLimit;
export const selectCommissionBlocked = (state : RootState) =>
  state.plantsManagement.bulkCommission.commissioningAssets.some((a) =>
    a.sensors.some((s) => s.error),
  );
export const selectSensorCommissionStatus = (state : RootState) =>
  state.plantsManagement.sensorCommissionStatus;
export const selectAssetGroupFilter = (state : RootState) => state.plantsManagement.assetGroupFilter;
