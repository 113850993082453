import { useAuthQuery } from 'api/queries';
import {
  Asset,
  AssetCollectionDevice,
  AssetSensor,
} from 'features/plants-management/models/asset.model';
import { useTranslation } from 'languages';
import { AssetType } from 'models/Asset/models';
import { SensorType } from 'models/sensor/models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

type Record = {
  assetGroupId: number;
  assetGroupName: string;
  assetId: number;
  collectionDevices: AssetCollectionDevice[];
  description: string;
  name: string;
  sensors: AssetSensor[];
  thumbnailUrl: string | null;
  type: string;
  partNumber: string;
  speed: number;
  serialNumber: string;
  notes: string | null;
};

type Response = {
  availableTypes: SensorType[];
  availableAssetTypes: AssetType[];
  records: Record[];
};

export type AssetInfo = Omit<Asset, 'commissioningDate' | 'sensorType'>;

type AssetData = {
  availableTypes: SensorType[];
  availableAssetTypes: AssetType[];
  records: AssetInfo[];
};

export const useGetAssets = (unmonitoredOnly = false) => {
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      errorMess: translate('failed_to_load_assets_from_plant_toast'),
    };
  }, []);

  return useAuthQuery<AssetData, Response>({
    url: `plants/${plantId}/assets`,
    params: {
      status: unmonitoredOnly ? 'unmonitored' : 'monitored',
    },
    queryOptions: {
      select: (response) => ({
        ...response,
        records: response.records.map((data) => ({
          id: data.assetId,
          name: data.name,
          description: data.description,
          partNumber: data.partNumber,
          assetType: data.type as AssetType,
          assetGroupName: data.assetGroupName,
          collectionDevices: data.collectionDevices,
          sensors: data.sensors,
          thumbnailUrl: data.thumbnailUrl ?? undefined,
          assetGroupId: data.assetGroupId,
          speed: data.speed,
          serialNumber: data.serialNumber,
          notes: data.notes,
        })),
      }),
      meta: {
        error: {
          message: componentText.errorMess,
        },
      },
    },
  });
};
