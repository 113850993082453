import { Sensor } from "features/assets-management/models";

export type Property = "parentName" | "sensorId";

export const getUniqueList = (selectedSensors:Sensor[], property:Property) =>{
    let resArr:Sensor[] = [];
    selectedSensors.filter(function(item:Sensor){
    let i = resArr.findIndex((x:Sensor) => (x[property] == item[property]));
    if(i <= -1){
        resArr.push(item);
    }
  return null;
 });
  return resArr;
}
