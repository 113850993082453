import ReactGA from "react-ga4";

export const trackAssetsNavigationTab = ({ action, label }: { action: string, label: string }) => {
    ReactGA.event(action, {
        category: "assets_management_navigation",
        label: label,
        value: 1,
    });
}

export const trackCreateAsset = () => {
    ReactGA.event("create_asset_button", {
        category: "asset_list",
        label: "create_asset_button",
        value: 1,
    });
}

export const trackOpenModalAddAsset = () => {
    ReactGA.event("open_modal_add_asset", {
        category: "asset_list",
        label: "button_add_asset",
        value: 1,
    });
}