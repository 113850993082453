import { Box, Typography } from "@mui/material";
import { Sensor } from "features/gateways-management/components/SensorAssignmentTable/models/SensorAssignment.models";
import { getSensorIco } from "helpers/getSensorIco";
import { FC } from "react";

type Props = {
    sensor: Sensor
}

const style = {
    display: "flex",
    svg: {
        path :{
            fill:"rgba(1, 1, 1, 0.6)",
            fillOpacity: 1,
            transform: "scale(0.8)"
        }
    }
}
const SensorRow:FC<Props> = ({sensor}) =>{

  return (
    <Box display="flex" alignItems="center" gap={1} my={.5}>
        <Box sx={style}>{getSensorIco(sensor.type, true)}</Box>
        <Typography variant="body1">{`${sensor.serialNumber}, ${sensor.location}`}</Typography>
    </Box>
  );
}

export default SensorRow;