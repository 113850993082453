import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { useDeleteCustomPart } from 'api/plants/useDeleteCustomPart';
import { PartBody } from 'api/plants/useGetCustomParts';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface DeletePartConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  partToDelete: PartBody;
}

const DeletePartConfirmationModal = ({
  isOpen,
  onClose,
  partToDelete,
}: DeletePartConfirmationModalProps) => {
  const { mutate: deleteCustomPart, isPending } = useDeleteCustomPart(() => onClose());

  const handleDelete = () => {
    deleteCustomPart({
      params: {
        partId: partToDelete.id,
      },
    });
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      removePartInfo: translate('remove_part_info'),
      cancel: translate('plants_cancel_button'),
      delete: translate('asset_delete_asset_button'),
    };
  }, []);
  return (
    <DodgeModal open={isOpen} onClose={onClose}>
      <Typography>{`${componentText.removePartInfo} ${partToDelete.number}?`}</Typography>
      <Grid container justifyContent='space-between' sx={{ width: '100%', mt: '1rem' }}>
        <Button variant='outlined' onClick={onClose}>
          {componentText.cancel}
        </Button>
        <LoadingButton
          loading={isPending}
          variant='contained'
          color='secondary'
          onClick={handleDelete}
          data-testid='plants_management_part_deletion_modal_delete_button'
        >
          {componentText.delete}
        </LoadingButton>
      </Grid>
    </DodgeModal>
  );
};

export default DeletePartConfirmationModal;
