import DodgeModal from 'components/DodgeModal/DodgeModal';
import { AdditionalProperties } from 'features/plants-management/models/sensor.model';
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import QrCodeReader from './QrCodeReader';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { getCatrigeSize } from '../../CommissionsSensors/IntellilubeCommissionModal';
import { GetSensorsByAssetIdResponse } from 'api/plants/useGetSensorsByAssetId';
import { useReplaceCartridge } from 'api/assets/useReplaceCartridge';
import { LoadingButton } from '@mui/lab';
import { getSensorIco } from 'helpers/getSensorIco';
import { useTranslation } from 'languages';

type DisplayType = 'form' | 'qr-reader';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedSensor: GetSensorsByAssetIdResponse;
  selectedAssetId: number;
}

const IntellilubeChangeDischargePeriod: FC<Props> = ({
  open,
  onClose,
  selectedSensor,
  selectedAssetId,
}) => {
  const { mutate, isPending } = useReplaceCartridge('', onClose);

  const [display, setDisplay] = useState<DisplayType>('form');
  const [qrCode, setQrCode] = useState<string>('');
  const [additionalProperties, setAdditionalProperties] = useState<AdditionalProperties>();
  const [periodUnit, setPeriodUnit] = useState('m');
  const [periodVal, setPeriodVal] = useState('');
  const [cartridgeSize, setCartridgeSize] = useState('');
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      modalTitle: translate("change_discharge_period_label"),
      saveButton: translate("menu_save_button"),
      week : translate("week_label"),
      month: translate("month_label"),
      dischargePeriod: translate("sensor_details_discharge_period"),
      tip: translate("tip")
    };
  }, []);

  const cartridgeSizeScan = useRef(true);
  const periodValues = useMemo(
    () => Array.from({ length: periodUnit === 'w' ? 26 : 24 }, (_, i) => `${i + 1}`),
    [periodUnit],
  );

  useEffect(() => {
    if (cartridgeSize === '500') {
      setPeriodUnit('w');
      return;
    }
    setPeriodUnit('m');
    if (Number(periodVal) > 24) {
      setPeriodVal('24');
    }
  }, [cartridgeSize, periodVal]);

  useEffect(() => {
    setPeriodUnit(
      selectedSensor.properties.dischargePeriod.value.toString().endsWith('w') ? 'w' : 'm',
    );
    setPeriodVal(
      selectedSensor.properties.dischargePeriod.value
        .toString()
        .substring(0, selectedSensor.properties.dischargePeriod.value.toString().length - 1),
    );
    setCartridgeSize(selectedSensor.properties.cartridgeSize.value.toString());
  }, [selectedSensor]);

  useEffect(() => {
    if (!qrCode) {
      return;
    }
    if (cartridgeSizeScan.current) {
      const arrayQrCode = qrCode.split('-');
      setAdditionalProperties((prev) => ({
        ...prev,
        cartridgePN: arrayQrCode[3],
        cartridgeBN: arrayQrCode[2],
        cartridgeFillDate: arrayQrCode[1],
        cartridgeQRcode: qrCode,
      }));
      const cartridgeSize = getCatrigeSize(qrCode);
      setCartridgeSize(cartridgeSize);
      setPeriodUnit(cartridgeSize === '500' ? 'w' : 'm');
    } else {
      const arrayQrCode = qrCode.split(';');
      const period = arrayQrCode[arrayQrCode.length - 3];
      setPeriodUnit(period.toLowerCase().endsWith('w') ? 'w' : 'm');
      setPeriodVal(period.substring(0, period.length - 1));
      setAdditionalProperties((prev) => ({
        ...prev!,
        ptWizardQRcode: qrCode,
      }));
    }
  }, [qrCode]);

  useEffect(() => {
    setDisplay('form');
    setQrCode('');
  }, [open]);

  const handleSaveSettings = () => {
    mutate({
      params: {
        serialNumber: selectedSensor.serialNumber,
        assetId: selectedAssetId,
      },
      body: {
        properties: {
          cartridgeSize: null,
          dischargePeriod: `${periodVal}${periodUnit}`,
        },
        additionalProperties: additionalProperties ?? null,
      },
    });
  };

  const handleScanDischargePeriod = () => {
    setDisplay('qr-reader');
    cartridgeSizeScan.current = false;
  };

  return (
    <DodgeModal title={componentText.modalTitle} open={open} onClose={onClose} width='31.25rem'>
      {display === 'form' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              fontWeight={600}
              sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              {getSensorIco(selectedSensor.type)} {`Intelli-Lube (${selectedSensor.serialNumber})`}
            </Typography>
            <Typography>{selectedSensor.location}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='contained'
              sx={{
                padding: '0.465rem 0.75rem',
                mr: '0.75rem',
                minWidth: 0,
              }}
              onClick={handleScanDischargePeriod}
            >
              <QrCodeScannerIcon />
            </Button>
            <FormControl size='small' sx={{ width: '38%', mr: '4%' }}>
              <InputLabel id='period-label'>{componentText.dischargePeriod}</InputLabel>
              <Select
                data-testid='plant_management_sensors_change_settings_discharge_period_input'
                labelId='period-label'
                value={periodVal}
                label={componentText.dischargePeriod}
                onChange={(e) => {
                  const value = e.target.value;
                  setPeriodVal(value);
                }}
              >
                {periodValues.map((val) => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              value={`${periodUnit === 'w' ? componentText.week : componentText.month}${periodVal === '1' ? '' : componentText.tip}`}
              size='small'
              sx={{
                width: '38%',
                '& input': {
                  cursor: 'default',
                },
              }}
              data-testid='plant_management_sensors_change_settings_discharge_period_placeholder'
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ margin: '2rem 0 0.5rem' }} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <LoadingButton
              data-testid='plant_management_sensors_change_settings_save_button'
              loading={isPending}
              variant='contained'
              color='secondary'
              sx={{ fontWeight: 'bold' }}
              disabled={!periodVal}
              onClick={handleSaveSettings}
            >
              {componentText.saveButton}
            </LoadingButton>
          </Grid>
        </Grid>
      )}
      {display === 'qr-reader' && (
        <QrCodeReader
          setQrCode={setQrCode}
          setDisplay={setDisplay}
          validateCartridgeSizeQRCode={cartridgeSizeScan.current}
        />
      )}
    </DodgeModal>
  );
};

export default memo(IntellilubeChangeDischargePeriod);
