import { useAuthMutation } from "api/queries";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectGatewayId } from 'features/gateways-management/store/gatewaysSlice';
import { useTranslation } from "languages";
import { useMemo } from "react";


export const getGateways = (serialNumber : string | null, macAddress : string | null) => `gateways/available?serialNumber=${serialNumber}&macAddress=${macAddress}`;

export const useCreateGateway = (onSuccess: () => void) => {
  const gatewayId = useSelector(selectGatewayId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess : translate("gateway_successfully_created_toast"),
      errorMess : translate("failed_to_create_gateway_toast"),
    };
  }, []);
  return useAuthMutation<{}, any>({
    url: () => `gateways/${gatewayId}`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: () => {
        onSuccess();
        toast.success(componentText.successMess);
      },
      onError: () => {
        toast.error(componentText.errorMess);
      }
    }
  });
}

