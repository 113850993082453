import { Box } from "@mui/material";
import { FC } from "react";
import { ImageType } from "./models/image.model";

type ImageTypeProps = {
 type: ImageType
}

const BoxImageType:FC<ImageTypeProps> = ({type}) =>{
   return (
    <Box
     width="136px"
     height="42px"
     position="absolute"
     bottom="0"
     left="0"
     display="flex"
     justifyContent="center"
     alignItems="center"
     sx={{
        background:"rgba(238, 238, 238, 0.8)", 
        borderRadius:"12px",
        fontWeight: "600",
        color: "rgba(0, 0, 0, 0.6)",
    }}
    >
      {type}  
    </Box>
   );
}
export default BoxImageType;