import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface Response {
  autoTimezone: boolean;
  timezone: string;
  timezones: {
    [key: string]: string;
  };
}

export const useGetTimezoneSettings = () => {
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_get_user_timezone_settings_toast'),
    };
  }, []);

  return useAuthQuery<Response>({
    url: `users/datetime-settings`,
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
