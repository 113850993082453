import { Box } from "@mui/material";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { memo, ReactNode } from "react";

type Props = {
    content: ReactNode;
    fullView: boolean;
  }

const CookiePageContent = (props: Props) =>{
  const { width } = useWindowDimensions();
  let paddingNumber = 2;
  let overflowString = "auto";
  if (width < 550) {
    paddingNumber = .6;
  }
  else if(width < 850){
    paddingNumber = 1.9;
  }
    return(
        <Box
          width={ props.fullView ? "100%" : "97%"}
          maxHeight={ props.fullView ? "100%" : "95%"}
          overflow={overflowString}
          p={paddingNumber}
          sx={{
            '&::-webkit-scrollbar' :{width:"5px", height:"5px"},
            '&::-webkit-scrollbar:horizontal' :{width:"5px"},
            '&::-webkit-scrollbar-thumb':{background: "#337179"},
            '&::-webkit-scrollbar-track-piece:start':{background: "transparent", mt: "1rem"}
          }}
        >
          {props.content}
        </Box>
    );
}

export default memo(CookiePageContent);
