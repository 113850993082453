import { useAuthQuery } from 'api/queries';
import {
  Gateway,
  Sensor,
} from 'features/gateways-management/components/SensorAssignmentTable/models/SensorAssignment.models';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

type SensorSignalInfo = Pick<
  Sensor,
  'id' | 'serialNumber' | 'rssi' | 'firmwareVersion' | 'status' | 'gatewaysInRange'
>;

type GateWithSensorInfo = Omit<Gateway, 'sensors'> & {
  sensors: SensorSignalInfo[];
};

type UnassignedSensor = Pick<Sensor, 'id' | 'serialNumber' | 'firmwareVersion' | 'gatewaysInRange'>;

export interface ResponseModel {
  monitored: GateWithSensorInfo[];
  unassigned: UnassignedSensor[];
}

export const prepareUrl = (plantId: number) => `plants/${plantId}/gateways-sensors-signal-info`;

export const useGetSensorsSignalInfo = (enabled: boolean) => {
  const plantId = useSelector(selectPlantId);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_sensors_signal_info_from_plant_id_toast'),
    };
  }, []);

  return useAuthQuery<ResponseModel>({
    url: `plants/${plantId}/gateways-sensors-signal-info`,
    queryOptions: {
      enabled: !!plantId && enabled,
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
