import DodgeModal from 'components/DodgeModal/DodgeModal';
import { AdditionalProperties, Sensor } from 'features/plants-management/models/sensor.model';
import { Dispatch, FC, SetStateAction, memo, useEffect, useMemo, useRef, useState } from 'react';
import QrCodeReader from '../AssetsTable/EditAsset/QrCodeReader';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { useTranslation } from "languages";

export function getCatrigeSize(qrCode: string) {
  switch (qrCode[1]) {
    case 'S':
      return '60';
    case 'M':
      return '120';
    case 'L':
      return '250';
    case 'X':
      return '500';
    default:
      return '';
  }
}

export type DisplayType = 'form' | 'qr-reader';

const breatherCartridgeSizeList = [
  { partNumber: '750015', label: 'OB1' },
  { partNumber: '750017', label: 'OB4' },
];

interface Props {
  open: boolean;
  onClose: () => void;
  selectedSensors: Sensor[];
  setSelectedSensors: Dispatch<SetStateAction<Sensor[]>>;
  setReadyToCommission: Dispatch<SetStateAction<boolean>>;
}

const IntellilubeCommissionModal: FC<Props> = ({
  open,
  onClose,
  selectedSensors,
  setSelectedSensors,
  setReadyToCommission,
}) => {
  const [display, setDisplay] = useState<DisplayType>('form');
  const [qrCode, setQrcode] = useState<string>('');
  const [additionalProperties, setAdditionalProperties] = useState<AdditionalProperties>();
  const [periodUnit, setPeriodUnit] = useState('m');
  const [periodVal, setPeriodVal] = useState('');
  const [cartridgeSize, setCartridgeSize] = useState('');
  const [breatherCartridgeSize, setBreatherCartridgeSize] = useState('');
  const [disableBack, setDisableBack] = useState<boolean>(true);
  const [sensorsForCommissioning, setSensorsForCommissioning] = useState<Sensor[]>(
    selectedSensors?.filter(
      (sensor) => sensor.type === 'IntelliLube' || sensor.type === 'BreatherSensor',
    ) || [],
  );
  const [index, setIndex] = useState<number>(0);

  const cartridgeSizeScan = useRef(true);
  const periodValues = useMemo(
    () => Array.from({ length: periodUnit === 'w' ? 26 : 24 }, (_, i) => `${i + 1}`),
    [periodUnit],
  );

  useEffect(() => {
    setAdditionalProperties(sensorsForCommissioning[index].additionalProperties);
    setPeriodUnit(
      sensorsForCommissioning[index].initialSettings?.dischargePeriod.endsWith('w') ? 'w' : 'm',
    );
    setPeriodVal(
      sensorsForCommissioning[index].initialSettings?.dischargePeriod.substring(
        0,
        sensorsForCommissioning[index].initialSettings!.dischargePeriod.length - 1,
      ) ?? '',
    );
    setCartridgeSize(sensorsForCommissioning[index].initialSettings?.cartridgeSize ?? '');
    setBreatherCartridgeSize(
      sensorsForCommissioning[index].additionalProperties?.cartridgePN ?? '',
    );
  }, [index, sensorsForCommissioning]);

  useEffect(() => {
    if (!qrCode) {
      return;
    }
    if (cartridgeSizeScan.current) {
      const arrayQrCode = qrCode.split('-');
      setAdditionalProperties((prev) => ({
        ...prev,
        cartridgePN: arrayQrCode[3],
        cartridgeBN: arrayQrCode[2],
        cartridgeFillDate: arrayQrCode[1],
        cartridgeQRcode: qrCode,
      }));
      const cartridgeSize = getCatrigeSize(qrCode);
      setCartridgeSize(cartridgeSize);
      setPeriodUnit(cartridgeSize === '500' ? 'w' : 'm');
    } else {
      const arrayQrCode = qrCode.split(';');
      const period = arrayQrCode[arrayQrCode.length - 3];
      setPeriodUnit(period.toLowerCase().endsWith('w') ? 'w' : 'm');
      setPeriodVal(period.substring(0, period.length - 1));
      setAdditionalProperties((prev) => ({
        ...prev!,
        ptWizardQRcode: qrCode,
      }));
    }
  }, [qrCode]);

  useEffect(() => {
    setDisplay('form');
    setQrcode('');
  }, [open]);

  const handleSaveSettings = () => {
    switch (sensorsForCommissioning[index].type) {
      case 'IntelliLube':
        {
          setSelectedSensors((prev) =>
            prev.map((sensor) =>
              sensor.serialNumber !== sensorsForCommissioning[index].serialNumber
                ? sensor
                : {
                    ...sensor,
                    initialSettings: {
                      cartridgeSize: cartridgeSize,
                      dischargePeriod: periodVal ? `${periodVal}${periodUnit}` : '',
                    },
                    pin: '',
                    additionalProperties,
                  },
            ),
          );
          setSensorsForCommissioning((prev) => {
            const newArr = [...prev];
            newArr[index] = {
              ...newArr[index],
              initialSettings: {
                cartridgeSize: cartridgeSize,
                dischargePeriod: periodVal ? `${periodVal}${periodUnit}` : '',
              },
              pin: '',
              additionalProperties,
            };
            return newArr;
          });
        }
        break;
      case 'BreatherSensor':
        {
          setSelectedSensors((prev) =>
            prev.map((sensor) =>
              sensor.serialNumber !== sensorsForCommissioning[index].serialNumber
                ? sensor
                : {
                    ...sensor,
                    pin: '',
                    additionalProperties: {
                      cartridgePN: breatherCartridgeSize,
                    },
                  },
            ),
          );
          setSensorsForCommissioning((prev) => {
            const newArr = [...prev];
            newArr[index] = {
              ...newArr[index],
              pin: '',
              additionalProperties: {
                cartridgePN: breatherCartridgeSize,
              },
            };
            return newArr;
          });
        }
        break;
    }
  };

  const handleSave = () => {
    handleSaveSettings();
    if (index < sensorsForCommissioning.length - 1) {
      setIndex(index + 1);
    } else {
      setReadyToCommission(true);
    }
  };

  const handleRemove = () => {
    setSelectedSensors((prev) =>
      prev.filter((sensor) => sensor.serialNumber !== sensorsForCommissioning[index].serialNumber),
    );
    setSensorsForCommissioning((prev) => prev.filter((_item, i) => i !== index));

    if (index === sensorsForCommissioning.length - 1 && index !== 0) {
      setIndex(index - 1);
    }
    if (sensorsForCommissioning.length === 1) {
      onClose();
      return;
    }
  };

  const handleBack = () => {
    handleSaveSettings();
    setIndex(index - 1);
  };
  useEffect(() => {
    index !== 0 ? setDisableBack(false) : setDisableBack(true);
  }, [index]);

  const handleScanCartridgeSize = () => {
    setDisplay('qr-reader');
    cartridgeSizeScan.current = true;
  };
  const handleScanDischargePeriod = () => {
    setDisplay('qr-reader');
    cartridgeSizeScan.current = false;
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      removeInstruction: translate("remove_instruction_commission_sensor"),
      commission: translate("commission_commission_sensor"),
      selectedSensor: translate("selected_sensor_commission_sensor"),
      cartridgeSize: translate("cartridge_size_commission_sensor"),
      scanQrCode: translate("scan_qr_code_commission_sensor"),
      dischargePeriod: translate("discharge_period_commission_sensor"),
      selectCartridgeSize: translate("select_cartridge_size_commission_sensor"),
      selectCartridgeType: translate("select_cartridge_type"),
      remove: translate("remove_commission_sensor"),
      next: translate("next_commission_sensor"),
      back: translate("back_commission_sensor"),
      month: translate("recurring_events_Month"),
      week: translate("recurring_events_week"),
      weeks: translate("recurring_events_weeks"),
      months: translate("recurring_events_Months")
    };
  }, []);

  return (
    <DodgeModal title='Commission' open={open} onClose={onClose} width='31.25rem'>
      {display === 'form' && (
        <Grid container mt='0.5rem' spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' fontWeight={600}>
              {componentText.selectedSensor}
            </Typography>
            <Typography>{`${sensorsForCommissioning[index].serialNumber}, ${sensorsForCommissioning[index].location}`}</Typography>
          </Grid>
          {sensorsForCommissioning[index].type === 'IntelliLube' && (
            <Grid item container xs={12}>
              <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{componentText.cartridgeSize}</Typography>
              </Grid>
              <Grid item xs={8}>
                {cartridgeSize ? (
                  <>
                    <Button
                      variant='contained'
                      sx={{
                        padding: '0.5rem 0.75rem',
                        mr: '0.75rem',
                        minWidth: 0,
                      }}
                      onClick={handleScanCartridgeSize}
                    >
                      <QrCodeScannerIcon />
                    </Button>
                    <TextField
                      disabled
                      value={`${cartridgeSize} cc`}
                      size='small'
                      sx={{
                        width: '80%',
                      }}
                    />
                  </>
                ) : (
                  <Button
                    variant='contained'
                    startIcon={<QrCodeScannerIcon />}
                    sx={{
                      width: '80%',
                    }}
                    onClick={handleScanCartridgeSize}
                  >
                   {componentText.scanQrCode}
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          {sensorsForCommissioning[index].type === 'IntelliLube' && (
            <Grid item container xs={12}>
              <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{componentText.dischargePeriod}</Typography>
              </Grid>
              <Grid item xs={8}>
                {periodVal ? (
                  <>
                    <Button
                      variant='contained'
                      sx={{
                        padding: '0.465rem 0.75rem',
                        mr: '0.75rem',
                        minWidth: 0,
                      }}
                      onClick={handleScanDischargePeriod}
                    >
                      <QrCodeScannerIcon />
                    </Button>
                    <FormControl size='small' sx={{ width: '38%', mr: '4%' }}>
                      <InputLabel id='period-label'>{componentText.dischargePeriod}</InputLabel>
                      <Select
                        labelId='period-label'
                        value={periodVal}
                        label={componentText.dischargePeriod}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (cartridgeSize === '500') {
                            setPeriodUnit('w');
                            setPeriodVal(value);
                          } else {
                            const numberVal = Number(value);
                            setPeriodUnit('m');
                            setPeriodVal(numberVal > 24 ? '24' : value);
                          }
                        }}
                        startAdornment={
                          <InputAdornment position='start'>
                            <EditIcon sx={{ width: '1rem', height: '1rem' }} />
                          </InputAdornment>
                        }
                      >
                        {periodValues.map((val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      disabled
                      value={`${periodUnit === 'w' ? (periodVal === '1' ? componentText.week : componentText.weeks) : (periodVal === '1' ? componentText.month : componentText.months)}`}
                      size='small'
                      sx={{
                        width: '38%',
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      variant='contained'
                      startIcon={<QrCodeScannerIcon />}
                      sx={{
                        width: '80%',
                      }}
                      onClick={handleScanDischargePeriod}
                    >
                      {componentText.scanQrCode}
                    </Button>
                    <IconButton
                      sx={{
                        ml: '0.2rem',
                      }}
                      onClick={() => setPeriodVal('1')}
                    >
                      <BorderColorOutlinedIcon />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {sensorsForCommissioning[index].type === 'BreatherSensor' && (
            <Grid item container xs={12}>
              <FormControl fullWidth sx={{ my: 1 }} size='small'>
                <InputLabel id='select-label'>
                  {sensorsForCommissioning[index].type === 'BreatherSensor'
                    ? componentText.selectCartridgeType
                    : componentText.selectCartridgeSize}
                </InputLabel>
                <Select
                  labelId='select-label'
                  id='select'
                  value={breatherCartridgeSize}
                  label={componentText.selectCartridgeSize}
                  onChange={(e: SelectChangeEvent<string>) => {
                    setBreatherCartridgeSize(e.target.value);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  size='small'
                >
                  {breatherCartridgeSizeList.map((item) => (
                    <MenuItem value={item.partNumber}>
                      <Box display='flex' gap={1}>
                        <Typography>{item.label}</Typography>
                        <Typography color='silver'>{`PN: ${item.partNumber}`}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='outlined'
              color='primary'
              sx={{ fontWeight: 'bold' }}
              onClick={handleRemove}
            >
              {componentText.remove}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>*{componentText.removeInstruction}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ width: '100%' }} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant='contained'
              color='secondary'
              sx={{ fontWeight: 'bold' }}
              //disabled={!cartridgeSize || !periodVal}
              disabled={
                sensorsForCommissioning[index].type === 'IntelliLube'
                  ? !cartridgeSize || !periodVal
                  : sensorsForCommissioning[index].type === 'BreatherSensor'
                    ? !breatherCartridgeSize
                    : true
              }
              onClick={handleSave}
            >
              {componentText.next}
            </Button>
            {!disableBack && (
              <Button
                variant='outlined'
                color='secondary'
                sx={{
                  color: 'black',
                  fontWeight: 'bold',
                }}
                onClick={handleBack}
              >
                {componentText.back}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {display === 'qr-reader' && (
        <QrCodeReader
          setQrCode={setQrcode}
          setDisplay={setDisplay}
          validateCartridgeSizeQRCode={cartridgeSizeScan.current}
        />
      )}
    </DodgeModal>
  );
};

export default memo(IntellilubeCommissionModal);
