import { SortByAlpha, Search, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { useGetUserRoles } from 'api/users';
import { usePutVisiblePlants } from 'api/users/usePutVisiblePlants';
import { StyledMenu } from 'features/user-panel/components/DropdownMenu/StyledMenu';
import getUrlWithoutParams from 'helpers/getUrlWithoutParams';
import { mapToAcceptedDropdownPlant } from 'helpers/mapPlant';
import { DropdownPlant } from 'models/plant/dropdownPlant.model';
import { useState, useMemo, MouseEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPlant, setRefetchAssetTree } from 'store/accountSlice';
import style from './PlantMenu.module.scss';
import { useTranslation } from 'languages';
import InfiniteScroll from 'react-infinite-scroller';

const recordsPerPage = 20;

const PlantMenu = () => {
  const { data: plantList = [], isLoading } = useGetUserRoles();
  const userPlants = useMemo(() => mapToAcceptedDropdownPlant(plantList), [plantList]);
  const scrollRef = useRef(null);
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [plantIdParamChecked, setPlantIdParamChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [visiblePlants, setVisiblePlants] = useState<DropdownPlant[]>([]);
  const dispatch = useDispatch();
  const selectedPlant = useSelector(selectPlant);
  const open = Boolean(anchorEl);

  const [sortAZ, setSortAZ] = useState(true);
  const toggleSort = () => {
    setSortAZ((sort) => !sort);
    setPagesLoaded(0);
  };

  const [search, setSearch] = useState('');
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(event.target.value);
    setPagesLoaded(0);
  };

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setPagesLoaded(0);
    setVisiblePlants(plantList.filter((plant) => plant.visible));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPagesLoaded(0);
    setAnchorEl(null);
  };

  const { mutate: putUnitMutation, isPending: putLoading } = usePutVisiblePlants(handleClose);

  const saveFilters = () => {
    if (visiblePlants.length === 0) {
      toast.error('You have to select at least one plant.', { toastId: 'plantfiltererror' });
      return;
    }
    dispatch(setRefetchAssetTree(true));
    if (visiblePlants === userPlants) {
      putUnitMutation({
        body: [],
      });
    } else {
      putUnitMutation({
        body: visiblePlants.map((plant) => plant.plantId),
      });
    }

    window.history.replaceState({}, '', getUrlWithoutParams());
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has('plantId') || isLoading || userPlants.length === 0 || plantIdParamChecked) {
      return;
    }
    const requestedPlantId = Number(urlParams.get('plantId'));
    const requestedPlant = userPlants.find((plant) => plant.plantId === requestedPlantId);
    setPlantIdParamChecked(true);
    if (!requestedPlant) {
      toast.error(
        'Could not find the plant specified in the URL. Please make sure you have access granted to it.',
        { toastId: 'urlPlantNotFound' },
      );
      return;
    }
    const visiblePlantList = userPlants.filter((plant) => plant.visible);
    const isRequestedPlantVisible = visiblePlantList.some(
      (plant) => plant.plantId === requestedPlantId,
    );
    if (!isRequestedPlantVisible) {
      dispatch(setRefetchAssetTree(true));
      putUnitMutation({
        body: [...visiblePlantList.map((plant) => plant.plantId), requestedPlantId],
      });
    }
  }, [isLoading, userPlants, visiblePlants]);

  const togglePlant = (plant: DropdownPlant, checked: boolean) => {
    if (checked) {
      setVisiblePlants((prevFilters) => [...prevFilters, plant]);
    } else {
      setVisiblePlants((prevFilters) =>
        prevFilters.filter((filter) => filter.plantId !== plant.plantId),
      );
    }
  };

  const toggleAllPlants = (checked: boolean) => {
    if (checked) {
      setVisiblePlants(userPlants);
    } else {
      setVisiblePlants([]);
    }
  };

  const filteredUserPlants = useMemo(
    () =>
      userPlants.filter((plant) => plant.plantName.toLowerCase().includes(search.toLowerCase())),
    [userPlants, search],
  );

  const sortedUserPlants = useMemo(() => {
    if (sortAZ) {
      return [...filteredUserPlants].sort((a, b) =>
        a.plantId === selectedPlant?.plantId
          ? -1
          : b.plantId === selectedPlant?.plantId
            ? 1
            : 0 ||
              Number(b.visible) - Number(a.visible) ||
              a.plantName.localeCompare(b.plantName, 'en', { numeric: true }),
      );
    }
    return [...filteredUserPlants].sort((a, b) =>
      a.plantId === selectedPlant?.plantId
        ? -1
        : b.plantId === selectedPlant?.plantId
          ? 1
          : 0 ||
            Number(b.visible) - Number(a.visible) ||
            -1 * a.plantName.localeCompare(b.plantName, 'en', { numeric: true }),
    );
  }, [sortAZ, filteredUserPlants, selectedPlant?.plantId]);

  const clearFilter = () => {
    setVisiblePlants([sortedUserPlants[0]]);
  };

  const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      placeholder: translate('search_placeholder'),
      clearFilter: translate('menu_clear_filter_label'),
      sortLabel: translate('menu_sort_label'),
      sortAZ: translate('menu_sort_atoz_label'),
      sortZA: translate('menu_sort_ztoa_label'),
      cancelButton: translate('plants_cancel_button'),
      okButton: translate('status_ok'),
      selectAll: translate('select_all_label'),
      moreThan100plants: translate('cannot_select_more_than_100_plants'),
    };
  }, [open]);

  return (
    <Grid item>
      {userPlants.length > 0 && (
        <>
          <IconButton
            color='inherit'
            size='small'
            onClick={handleOpen}
            className={style['icon-button']}
          >
            <Edit fontSize='small' />
          </IconButton>
          <StyledMenu
            id='plant-menu'
            MenuListProps={{
              'aria-labelledby': 'plant selection',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Grid container flexDirection='column' className={style['menu-container']}>
              <Grid item>
                <Grid container flexDirection='column' alignItems='flex-start'>
                  <Button
                    data-testid='plants_management_sort_button'
                    variant='text'
                    className={`${style['text-button']} ${style['alt-color']}`}
                    startIcon={<SortByAlpha />}
                    onClick={toggleSort}
                  >
                    {`${componentText.sortLabel} ${sortAZ ? componentText.sortAZ : componentText.sortZA}`}
                  </Button>
                  <Button
                    data-testid='plants_management_clear_button'
                    variant='text'
                    className={style['text-button']}
                    onClick={clearFilter}
                  >
                    {componentText.clearFilter}
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  data-testid='plants_management_search_box'
                  placeholder={componentText.placeholder}
                  size='small'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  className={style['search-bar']}
                  onKeyDown={handleKeyboardEvent}
                />
              </Grid>
              <Grid item>
                <FormGroup className={style['checkbox-container']} ref={scrollRef}>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={(page) => {
                      setPagesLoaded(page);
                    }}
                    useWindow={false}
                    hasMore={
                      sortedUserPlants.length > recordsPerPage + pagesLoaded * recordsPerPage
                    }
                    loader={
                      <div className='loader' key={0}>
                        Loading ...
                      </div>
                    }
                    getScrollParent={() => scrollRef.current}
                  >
                    <FormControlLabel
                      checked={visiblePlants.length === userPlants.length}
                      onChange={(_, checked) => toggleAllPlants(checked)}
                      control={<Checkbox data-testid='plants_management_select_all_checkbox' />}
                      label={componentText.selectAll}
                    />
                    {sortedUserPlants
                      .slice(0, recordsPerPage + pagesLoaded * recordsPerPage)
                      .map((plant) => (
                        <FormControlLabel
                          onChange={(_, checked) => togglePlant(plant, checked)}
                          key={plant.plantId}
                          checked={visiblePlants.some(
                            (selectedPlant) => selectedPlant.plantId === plant.plantId,
                          )}
                          control={<Checkbox />}
                          label={plant.plantName}
                          data-testid='plants_management_checkbox'
                        />
                      ))}
                  </InfiniteScroll>
                </FormGroup>
              </Grid>
              <Grid item>
                <Grid container justifyContent='flex-end'>
                  <Button
                    data-testid='plants_management_cancel_button'
                    variant='outlined'
                    onClick={handleClose}
                  >
                    {componentText.cancelButton}
                  </Button>
                  <Tooltip
                    title={
                      (visiblePlants.length > 100 && !(visiblePlants.length === userPlants.length))
                        ? componentText.moreThan100plants + visiblePlants.length
                        : ''
                    }
                  >
                    <span>
                      <LoadingButton
                        disabled={(visiblePlants.length > 100 && !(visiblePlants.length === userPlants.length)) || visiblePlants.length === 0}
                        data-testid='plants_management_ok_button'
                        loading={putLoading}
                        variant='contained'
                        style={{ marginLeft: '12px' }}
                        onClick={saveFilters}
                      >
                        {componentText.okButton}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </StyledMenu>
        </>
      )}
    </Grid>
  );
};

export default PlantMenu;
