import React from 'react';
import { Box } from '@mui/material';
import { CopyrightBar } from '../../CopyrightBar';
import { LoggedInProfile } from '../../LoggedInProfile';
import style from './OnboardingLayout.module.scss';
import { Link } from 'react-router-dom';
import { useGetImageUrl } from 'helpers/useGetImageUrl';

const LayoutContext = React.createContext(null);

type PropsTypes = {
  children: React.ReactNode;
};

const OnboardingLayout = ({ children }: PropsTypes) => {
  return (
    <LayoutContext.Provider value={null}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        className={style.page}
        gap='1.5rem'
      >
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          paddingLeft={4}
          paddingRight={4}
          paddingTop={4}
        >
          <Link to={'/'}>
            <img src={useGetImageUrl('public/dodge.png')} className={style.img} alt='DRG logo' />
          </Link>
          <Box>
            <LoggedInProfile />
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          {children}
        </Box>
        <Box>
          <CopyrightBar></CopyrightBar>
        </Box>
      </Box>
    </LayoutContext.Provider>
  );
};

export default React.memo<PropsTypes>(OnboardingLayout);
