import { useAuthMutation } from 'api/queries';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

export interface PartBody {
  number: string;
  assetType: string;
  description: string;
  manufacturer?: number;
  properties?: {
    driveEndBearing?: number;
    nonDriveEndBearing?: number;
    power?: string;
    speed?: string;
    variableSpeedDrive?: boolean;
    frameSize?: string;
  };
}

export const useCreateCustomPart = (onSuccess?: (id: number) => void) => {
  const plantId = useSelector(selectPlantId);
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('custom_part_created_successfully_toast'),
      errorMess: translate('failed_to_create_custom_part_toast'),
    };
  }, []);

  return useAuthMutation<undefined, PartBody, number>({
    url: `plants/${plantId}/parts`,
    method: 'POST',
    mutationOptions: {
      onSuccess: ({ data }) => {
        onSuccess?.(data);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('parts') });
        toast.success(componentText.successMess);
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
