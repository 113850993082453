import { AdditionalProperties } from 'features/plants-management/models/sensor.model';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { selectPlantId } from 'store/accountSlice';
import { useAuthMutation } from './../queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface Body {
  properties:
    | {
        cartridgeSize: string | null;
        dischargePeriod?: string | null;
      }
    | object;
  additionalProperties: AdditionalProperties | null;
}

interface Params {
  assetId: number;
  serialNumber: string;
}

export const useReplaceCartridge = (
  size: string,
  onSucces?: () => void,
  sensorType?: 'BreatherSensor' | 'IntelliLube',
) => {
  const queryClient = useQueryClient();
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('settings_successfully_changed_toast'),
      errorMess: translate('failed_to_change_sensor_settings_toast'),
    };
  }, []);

  return useAuthMutation<Params, Body>({
    url: ({ assetId, serialNumber }) =>
      `assets/${assetId}/sensors/${serialNumber}/change-cartridge-settings`,
    method: 'POST',
    mutationOptions: {
      onSuccess: (_, variables) => {
        if (!size.length) {
          toast.success(componentText.successMess);
        } else {
          toast.success(
            `Cartridge ${sensorType === 'BreatherSensor' ? 'type' : 'size'} has been changed to ${size}`,
          );
        }

        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey.includes('sensors'),
        });
        onSucces?.();
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
