import { useAuthQuery } from 'api/queries';
import { useSelector } from 'react-redux';
import { selectChannelDetails } from 'store/accountSlice';

type Props = {
  plantId: number;
  reportType: 'Standard' | 'Plant';
};

type Response = {
  id: number;
  name: string;
  createdOn: string;
};

export const useGetTemplates = ({ plantId, reportType }: Props) => {
  const channelDetails = useSelector(selectChannelDetails);

  return useAuthQuery<Response>({
    url: `channel-partners/${channelDetails?.channelId}/report-templates`,
    params: { plantId: plantId.toString(), reportType: reportType },
    queryOptions: {
      enabled: plantId !== -1,
    },
  });
};
