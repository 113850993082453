import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { FC, useEffect, useMemo, useState } from 'react';
import { okColor } from 'features/dashboard/colors/colors';
import { selectSensorCommissionStatus } from 'features/plants-management/store/plantsManagementSlice';
import { useSelector } from 'react-redux';
import SensorsList from './SensorsList';
import GatewaysList from './GatewaysList';
import { selectPlantId } from 'store/accountSlice';
import { useGetSensorsBaseInfo } from 'api/plants/useGetSensorsBaseInfo';
import {
  Sensor,
  Gateway,
} from 'features/gateways-management/components/SensorAssignmentTable/models/SensorAssignment.models';
import { usePostAddSensorsToNearest } from 'api/gateways/usePostAddSensorsToNearest';
import SensorRow from './SensorRow';
import GatewayRow from './GatewayRow';
import { usePostMonitoredSensors } from 'api/gateways/usePostMonitoredSensors';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useGetSensorsSignalInfo } from 'api/plants/useGetSensorsSignalInfo';
import { useTranslation } from 'languages';

export interface SelectedData {
  gateway: Gateway[];
  sensors: Sensor[];
}
type Props = {
  onClose?: () => void;
  onCancel?: () => void;
  assetName?: string;
  assetId: number;
};

const AssignSensors: FC<Props> = ({ onClose, assetName, assetId }) => {
  const [allowableGateways, setAllowableGateways] = useState<string[]>([]);
  const [signalsData, setSignalsData] = useState<
    { gatewayId: string; sumRssi: number; count: number }[]
  >([]);
  const [unassignedSensors, setUnassignedSensors] = useState<Sensor[]>([]);
  const [gateways, setGateways] = useState<Gateway[]>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedGatewayId, setSelectedGatewayId] = useState<string | undefined>();
  const [selectedData, setSelectedData] = useState<SelectedData[]>([]);
  const [pickedSensors, setPickedSensors] = useState<Sensor[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [remaining, setRemaining] = useState<boolean>(false);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successfullyCommissionedToAssign: translate('successfully_commissioned_to_assign'),
      sensorDataGatewayCollectionWarningAssign: translate(
        'sensor_data_gateway_collection_warning_assign',
      ),
      sensorAssignmentTabInGatewayMenuAssign: translate(
        'sensor_assignment_tab_in_gateway_menu_assign',
      ),
      assignToGatewayInstructionAssign: translate('assign_to_gateway_instruction_assign'),
      nextAssign: translate('next_assign'),
      assignToNearestGatewayAssign: translate('assign_to_nearest_gateway_assign'),
      selectedSensorsAssign: translate('selected_sensors_assign'),
      selectedGatewayAssign: translate('selected_gateway_assign'),
      assignToSelectedGatewayAssign: translate('assign_to_selected_gateway_assign'),
      assignRemainingSensorsAssign: translate('assign_remaining_sensors_assign'),
      backAssign: translate('back_assign'),
      closeAssign: translate('close_assign'),
      assigningSensors: translate('assigning_sensors_assign_sensors'),
      successfullyAssignedSensors: translate('successfully_assigned_sensors_assign_sensors'),
      failedToAssignSensorsToGateId: translate(
        'failed_to_assign_sensors_to_gate_id_assign_sensors',
      ),
    };
  }, []);

  const data = useSelector(selectSensorCommissionStatus);
  const sensorsText = data
    .map((sensor) => `${sensor.location} (${sensor.serialNumber})`)
    .join(', ');

  const activePlantId = useSelector(selectPlantId);

  const [step, setStep] = useState<number>(1);

  const { data: dataBaseSensorsInfo, isLoading: isBaseSensorsInfoLoading } =
    useGetSensorsBaseInfo(activePlantId);
  const { data: dataSignalSensorsInfo, isLoading: isSignalSensorsInfoLoading } =
    useGetSensorsSignalInfo(true);

  const fusedSensorInfo: { monitored: Gateway[]; unassigned: Sensor[] } | undefined =
    useMemo(() => {
      if (
        dataBaseSensorsInfo &&
        dataSignalSensorsInfo &&
        !(isBaseSensorsInfoLoading || isSignalSensorsInfoLoading)
      ) {
        return {
          monitored: dataBaseSensorsInfo.monitored.reduce((results, g) => {
            const signalInfo = dataSignalSensorsInfo.monitored.find(
              (gs) => gs.deviceId === g.deviceId,
            );

            if (signalInfo) results.push({ ...g, ...signalInfo } as Gateway);

            return results;
          }, [] as Gateway[]),
          unassigned: dataBaseSensorsInfo.unassigned.reduce((results, s) => {
            const signalInfo = dataSignalSensorsInfo.unassigned.find((ss) => s.id === ss.id);

            if (signalInfo) results.push({ ...s, ...signalInfo } as Sensor);
            return results;
          }, [] as Sensor[]),
        };
      }
    }, [dataBaseSensorsInfo, dataSignalSensorsInfo]);

  useEffect(() => {
    if (fusedSensorInfo) {
      const sensors = fusedSensorInfo.unassigned as Sensor[];
      const monitoredGateways = fusedSensorInfo.monitored as Gateway[];
      setUnassignedSensors(
        sensors.filter((s) => data.some((sensor) => sensor.serialNumber === s.serialNumber)),
      );
      setGateways(monitoredGateways);
    }
  }, [fusedSensorInfo]);

  useEffect(() => {
    let temp: string[] = [];
    if (selectedIds && selectedIds.length) {
      const filteredSensors = unassignedSensors.filter((s) => selectedIds.includes(s.id));

      for (const s of filteredSensors) {
        if (s.gatewaysInRange.length === 0) {
          setAllowableGateways([]);
          break;
        } else {
          s.gatewaysInRange.forEach((r) => {
            if (!temp.includes(r.gatewayId)) temp.push(r.gatewayId);
          });
        }
      }
      if (temp.length) {
        for (const s of filteredSensors) {
          temp = temp.filter((i) => s.gatewaysInRange.some((r) => r.gatewayId === i));
        }
        setAllowableGateways(temp);
      }
    }
  }, [selectedIds]);

  useEffect(() => {
    const gatewayData: any = {};
    if (selectedIds && selectedIds.length && gateways && gateways.length) {
      const filteredSensors = unassignedSensors.filter((s) => selectedIds.includes(s.id));

      filteredSensors.forEach((item) => {
        item.gatewaysInRange.forEach((gateway) => {
          if (gatewayData[gateway.gatewayId]) {
            gatewayData[gateway.gatewayId].sumRssi += gateway.rssi;
            gatewayData[gateway.gatewayId].count++;
          } else {
            gatewayData[gateway.gatewayId] = {
              sumRssi: gateway.rssi,
              count: 1,
            };
          }
        });
      });

      const result = Object.keys(gatewayData).map((gatewayId) => {
        return {
          gatewayId: gatewayId,
          sumRssi: gatewayData[gatewayId].sumRssi,
          count: gatewayData[gatewayId].count,
        };
      });

      setSignalsData(result);
    }
  }, [selectedIds]);

  useEffect(() => {
    if (fusedSensorInfo && !(isBaseSensorsInfoLoading || isSignalSensorsInfoLoading)) {
      const sensors = fusedSensorInfo.unassigned as Sensor[];
      const monitoredGateways = fusedSensorInfo.monitored as Gateway[];
      setUnassignedSensors(
        sensors.filter((s: any) => data.some((sensor) => sensor.serialNumber === s.serialNumber)),
      );
      setGateways(monitoredGateways);
    }
  }, [fusedSensorInfo]);

  const { mutate: addSensorsToNearestMutation, isPending: isLoadingToNearest } =
    usePostAddSensorsToNearest(setIsSuccess);
  const handleAddSensorsToNearest = () => {
    addSensorsToNearestMutation({
      params: {
        plantId: activePlantId,
      },
      body: selectedIds,
    });
  };
  useEffect(() => {
    isSuccess && onClose?.();
  }, [isSuccess]);

  const getSelectedGateway = useMemo(() => {
    return gateways && gateways.length && selectedGatewayId
      ? gateways.filter((g) => selectedGatewayId === g.deviceId)
      : [];
  }, [selectedGatewayId]);

  const getSelectedSensors = useMemo(() => {
    return unassignedSensors && unassignedSensors.length && selectedIds && selectedIds.length
      ? unassignedSensors.filter((s) => selectedIds.some((id) => id === s.id))
      : [];
  }, [selectedIds]);

  const isRemainingSensors = useMemo(() => {
    const totalSensors = selectedData.reduce(
      (acc: any, gateway: any) => acc + gateway.sensors.length,
      0,
    );
    return totalSensors < data.length;
  }, [selectedData, data]);

  const { mutate: postSensorsMutation, isPending: postLoading } = usePostMonitoredSensors();
  const handleAssignSensors = () => {
    const toastPromise = new Promise<string>((resolve, reject) => {
      selectedData.forEach((data: SelectedData) => {
        postSensorsMutation(
          {
            params: {
              gatewayId: data.gateway[0].deviceId,
            },
            body: data.gateway[0].sensors
              .map((s: Sensor) => s.id)
              .concat(data.sensors.map((s: Sensor) => s.id)),
          },
          {
            onSettled: () => {
              setTimeout(() => {
                onClose?.();
              }, 3000);
            },
            onSuccess: () => {
              resolve(data.gateway[0].deviceId);
            },
            onError: () => {
              reject(data.gateway[0].deviceId);
            },
          },
        );
      });
    });

    toast.promise(toastPromise, {
      pending: {
        render() {
          return componentText.assigningSensors;
        },
        type: 'info',
      },
      success: componentText.successfullyAssignedSensors,
      error: {
        render({ data }) {
          return `${componentText.failedToAssignSensorsToGateId} ${data}`;
        },
        type: 'error',
      },
    });
  };
  const handleNext = () => {
    setStep(2);
    setPickedSensors([...pickedSensors, ...getSelectedSensors]);
    const found = selectedData.find(
      (d: SelectedData) => d.gateway[0].deviceId === getSelectedGateway[0].deviceId,
    );
    setSelectedData([
      ...selectedData.filter(
        (d: SelectedData) => d.gateway[0].deviceId !== getSelectedGateway[0].deviceId,
      ),
      {
        gateway: getSelectedGateway,
        sensors: found ? found.sensors.concat(getSelectedSensors) : getSelectedSensors,
      },
    ]);
  };
  const handleBack = () => {
    setStep(1);
    setSelectedData([]);
    setPickedSensors([]);
  };

  const handleAssignRemainingSensors = () => {
    setUnassignedSensors(
      unassignedSensors.filter(
        (s) => !!!pickedSensors.some((sensor: Sensor) => sensor.id === s.id),
      ),
    );
    setStep(1);
    setSelectedGatewayId(undefined);
    setSelectedIds([]);
    setRemaining(true);
  };

  if (step === 1)
    return (
      <Grid container mt={3} gap={2}>
        {!remaining && (
          <Grid
            item
            xs={12}
            container
            alignItems='center'
            sx={{
              background: 'rgba(76, 175, 80, 0.14)',
              borderRadius: '12px',
              p: 1,
            }}
          >
            <Grid item xs={1.8}>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{
                  width: '1.5rem',
                  height: '1.5rem',
                  borderRadius: '50%',
                  background: okColor,
                  m: 1,
                }}
              >
                <CheckIcon sx={{ color: 'white', width: '90%' }} />
              </Box>
            </Grid>
            <Grid item xs={10.2}>
              <Typography>
                <strong>{sensorsText}</strong>
                {` ${componentText.successfullyCommissionedToAssign} `}
                <strong>{assetName}</strong>
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant='body1' fontSize='95%'>
            {componentText.sensorDataGatewayCollectionWarningAssign}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' fontSize='95%'>
            {componentText.sensorAssignmentTabInGatewayMenuAssign}
          </Typography>
        </Grid>
        <Grid item xs={12} my={1}>
          <SensorsList
            sensors={unassignedSensors}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            selectedData={selectedData}
            selectedGatewayId={selectedGatewayId}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' fontSize='95%'>
            {componentText.assignToGatewayInstructionAssign}
          </Typography>
        </Grid>
        <Grid item xs={12} my={1}>
          <GatewaysList
            id={selectedGatewayId}
            setId={setSelectedGatewayId}
            gateways={gateways}
            selectedIds={selectedIds}
            allowableGateways={allowableGateways}
            signalsData={signalsData}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            data-testid='plants_managements_assign_next_button'
            fullWidth
            variant='contained'
            color='secondary'
            sx={{ fontWeight: '600' }}
            onClick={handleNext}
            disabled={!Boolean(selectedIds.length) || !Boolean(selectedGatewayId)}
          >
            {componentText.nextAssign}
          </Button>
        </Grid>
        {!remaining && (
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoadingToNearest}
              fullWidth
              variant='outlined'
              color='secondary'
              sx={{ fontWeight: '600', color: 'black' }}
              onClick={handleAddSensorsToNearest}
              disabled={!Boolean(selectedIds.length) || Boolean(selectedGatewayId)}
            >
              {componentText.assignToNearestGatewayAssign}
            </LoadingButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            fullWidth
            variant='outlined'
            color='secondary'
            sx={{ fontWeight: '600', color: 'black' }}
            onClick={() => onClose?.()}
          >
            {componentText.closeAssign}
          </Button>
        </Grid>
      </Grid>
    );

  return (
    <Grid container gap={2} mt={2}>
      {selectedData &&
        selectedData.length &&
        selectedData.map((selected: any) => (
          <>
            <Grid item xs={12}>
              <Typography fontWeight='600'>{componentText.selectedSensorsAssign}</Typography>
            </Grid>
            <Grid item xs={12}>
              {selected.sensors.map((sensor: Sensor) => (
                <SensorRow sensor={sensor} />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight='600'>{componentText.selectedGatewayAssign}</Typography>
            </Grid>
            <Grid item xs={12}>
              {selected.gateway.map((gateway: Gateway) => (
                <GatewayRow gateway={gateway} signalsData={signalsData} />
              ))}
            </Grid>
            <Grid item xs={12} my={1}>
              <Divider sx={{ width: '100%' }} />
            </Grid>
          </>
        ))}
      <Grid item xs={12}>
        <LoadingButton
          data-testid='plants_managements_assign_to_selected_gateway_button'
          fullWidth
          variant='contained'
          color='secondary'
          sx={{ fontWeight: '600' }}
          onClick={handleAssignSensors}
          loading={postLoading}
        >
          {componentText.assignToSelectedGatewayAssign}
        </LoadingButton>
      </Grid>
      {isRemainingSensors && (
        <Grid item xs={12}>
          <Button
            fullWidth
            variant='outlined'
            color='secondary'
            sx={{ fontWeight: '600', color: 'black' }}
            onClick={handleAssignRemainingSensors}
          >
            {componentText.assignRemainingSensorsAssign}
          </Button>
        </Grid>
      )}
      {!remaining && (
        <Grid item xs={12}>
          <Button
            fullWidth
            variant='outlined'
            color='secondary'
            sx={{ fontWeight: '600', color: 'black' }}
            onClick={handleBack}
          >
            {componentText.backAssign}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          fullWidth
          variant='outlined'
          color='secondary'
          sx={{ fontWeight: '600', color: 'black' }}
          onClick={() => onClose?.()}
        >
          {componentText.closeAssign}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AssignSensors;
