import { PlotData, PlotMouseEvent } from 'plotly.js';
import { memo, useCallback, useEffect, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { ChartTooltipData } from '../charts/ChartTooltip';
import { TimeSeriesData } from './models/TimeSeriesGraph.models';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { useGetAxisLabel } from '../charts/useGetAxisLabel';

type Props = {
  chartData: TimeSeriesData[];
  setTooltipValue: (val: ChartTooltipData | undefined) => void;
  width?: number;
  height?: number;
  marginTop?: number;
  pr?: string;
  openTreeMenu ?: boolean;
  yaxis ?: string;
  yAxisLabel?:string,
};

const AmplitudeChart = ({
  chartData,
  width,
  height,
  setTooltipValue,
  marginTop,
  pr,
  openTreeMenu,
  yaxis,
  yAxisLabel
} : Props) => {
  const {axisLabelTranslated : xAxisLabelTranslated} = useGetAxisLabel('Time [s]'); 
  const dateFormatter = useDateFormatter();
  const traces: Partial<PlotData>[] = useMemo(() => {
    const tracesTemp: Partial<PlotData>[] = [];

    chartData.forEach((measurment: TimeSeriesData, index: number) => {
      if (measurment.data) {
        tracesTemp.push({
          name: dateFormatter(measurment.measurmentDate, 'MM/DD/YYYY | HH:mm'),
          type: 'scatter',
          x: measurment.data.map((point) => {
            return point.time;
          }),
          y: measurment.data.map((point) => {
            return point.value;
          }),
          automargin: true,
          hoverinfo: 'none',
        });
      }
    });
    return tracesTemp;
  }, [chartData]);

  const showTooltip = useCallback(
    (event: Readonly<PlotMouseEvent>) => {
      const title =
        typeof event.points[0].yaxis.title === 'string'
          ? event.points[0].yaxis.title
          : event.points[0].yaxis.title.text!;

      const xUnit: string =
        typeof event.points[0].xaxis.title === 'string'
          ? event.points[0].xaxis.title
          : event.points[0].xaxis.title.text!;
      const unit = title.replaceAll('[', '').replaceAll(']', '');
      const xValue = event.points[0].x?.toString();
      const yValue = event.points[0].y?.toString();

      setTooltipValue({
        xValue: xValue ? `${xUnit}: ` + String(Math.round(Number(xValue) * 10) / 10) : undefined,
        value: yValue ? String(Math.round(Number(yValue) * 10000) / 10000) : undefined,
        unit: unit,
        xPos: event.event.clientX,
        yPos: event.event.clientY,
      });
    },
    [setTooltipValue],
  );

  const hideTooltip = useCallback(
    (_: Readonly<PlotMouseEvent>) => {
      setTooltipValue(undefined);
    },
    [setTooltipValue],
  );

  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
  }, [pr, openTreeMenu]);

  return (
    <>
      <Plot
        data={traces}
        style={{ width: '100%', height: '100%' }}
        layout={{
          width: width ? width : undefined,
          height: height ? height : undefined,
          xaxis: { title: xAxisLabelTranslated },
          yaxis: { title: '[g]' },
          //font: { size: 18 },
          autosize: true,
          margin: {
            t: marginTop,
          },
        }}
        useResizeHandler={true}
        config={{ scrollZoom: true, autosizable: true }}
        onHover={showTooltip}
        onUnhover={hideTooltip}
      />
    </>
  );
};
export default memo(AmplitudeChart);
