import { Divider, Grid } from '@mui/material';
import { scrollBar } from 'features/assets-management/components/VibrationFFT/components/menu/MeasurementSettings';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { trackOpenManageAccount } from 'shared/analytics';
import { useDeviceSize } from 'shared/responsive';
import ManageAccountContent from './ManageAccountContent';
import ManageAccountMenu from './ManageAccountMenu';
interface ManageAccountContainerProps {
  link?: string;
  onClose?: () => void;
  logout: () => void;
  firstName: string;
  lastName: string;
  email: string;
}

type orientationType = 'horizontal' | 'vertical';

const ManageAccountContainer: FC<ManageAccountContainerProps> = ({
  link,
  logout,
  firstName,
  lastName,
  email,
  onClose,
}) => {
  const [activeLink, setActiveLink] = React.useState<string>(link ?? 'manage-plants');
  const { isPhone, isTablet } = useDeviceSize();

  const handleActiveLink = useCallback(
    (e: React.MouseEvent<HTMLElement>) => setActiveLink(e.currentTarget.id),
    [],
  );
  const handleActiveMobileLink = useCallback(
    // @ts-expect-error false-positive
    (e: React.MouseEvent<HTMLElement>) => setActiveLink(e.target.value),
    [],
  );

  useEffect(() => {
    trackOpenManageAccount();
  }, []);

  const { isDesktop } = useDeviceSize();

  const responsiveProps = {
    orientation: (isPhone || isTablet) && !isDesktop ? 'horizontal' : 'vertical',
    gridPadding: (isPhone || isTablet) && !isDesktop ? '1rem 0' : '2rem 0.5rem 2rem 2rem',
    extraPadding: (isPhone || isTablet) && !isDesktop ? '' : '2rem 0',
    containerHeight: (isPhone || isTablet) && !isDesktop ? undefined : '95%',
    childHeight: (isPhone || isTablet) && !isDesktop ? undefined : '100%',
    menuWidth :  (isPhone || isTablet) && !isDesktop ? 12 : 2,
    contentWidth : (isPhone || isTablet) && !isDesktop ? 12 : 9,
    dividerWidth : (isPhone || isTablet) && !isDesktop ? 12 : 1,
    scrollBar:
      (isPhone || isTablet) && !isDesktop
        ? undefined
        : {
            ...scrollBar,
            overflowY: 'auto',
            '&::-webkit-scrollbar-track-piece:start': {
              mt: '1rem',
            },
          },
  };

  // INFO: there is difference, between e.target on mobile and desktop version of comp
  const linkHandler =
    (isPhone || isTablet) && !isDesktop ? handleActiveMobileLink : handleActiveLink;

  return (
    <Grid container height={responsiveProps.containerHeight}>
      <Grid item xs={responsiveProps.menuWidth} spacing={1} height={responsiveProps.childHeight} py={2}>
        <ManageAccountMenu activeLink={activeLink} handleActiveLink={linkHandler} logout={logout} />
      </Grid>
      <Grid item xs={responsiveProps.dividerWidth} height={responsiveProps.childHeight}>
        <Divider orientation={responsiveProps.orientation as orientationType} />
      </Grid>
      <Grid item xs={responsiveProps.contentWidth} p={2} height={responsiveProps.childHeight} sx={responsiveProps.scrollBar}>
        <ManageAccountContent
          activeLink={activeLink}
          firstName={firstName}
          lastName={lastName}
          email={email}
          onClose={onClose}
        />
      </Grid>
    </Grid>
  );
};

export default memo(ManageAccountContainer);
