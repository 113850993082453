import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface RejectUserFromPlantParams {
  plantId: number;
}

type RejectUserFromPlantBody = {
  email: string;
};

export const useRejectUserFromPlant = () => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('user_successfully_rejected_from_plant_toast'),
      errorMess: translate('user_failed_to_be_rejected_from_plant_toast'),
    };
  }, []);
  return useAuthMutation<RejectUserFromPlantParams, RejectUserFromPlantBody>({
    url: (params: RejectUserFromPlantParams) => `plants/${params.plantId}/users`,
    method: 'DELETE',
    mutationOptions: {
      onSuccess: (_, { params }) => {
        toast.success(componentText.successMess);
        queryClient.invalidateQueries({ queryKey: ['users'] });
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
