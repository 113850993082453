import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { CatalogPartBody } from 'api/plants/useGetCatalogParts';
import { PartBody } from 'api/plants/useGetCustomParts';
import { FilterWithAlias } from 'components/DataTable/models';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import { useMemo, useState } from 'react';
import DodgePartNoSelection from './DodgePartNoSelection';
import OwnPartNoSelection from './OwnPartNoSelection';
import { useTranslation } from 'languages';

interface SelectSensorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPartSave: (part: PartBody | CatalogPartBody) => any;
  initFilter?: string | FilterWithAlias;
  mode?: 'pick';
  disableSelect?: boolean;
  motorBearingManufacturer?: boolean;
}

const SelectPartNoModal = ({
  isOpen,
  onClose,
  onPartSave,
  mode,
  initFilter,
  disableSelect,
  motorBearingManufacturer,
}: SelectSensorModalProps) => {
  const [selectedPage, setSelectedPage] = useState(0);
  const component =
    selectedPage === 0 ? (
      <DodgePartNoSelection
        onClose={onClose}
        onPartSave={onPartSave}
        initFilter={initFilter}
        disableSelect={disableSelect}
        mode={mode}
        motorBearingManufacturer={motorBearingManufacturer}
      />
    ) : (
      <OwnPartNoSelection
        onClose={onClose}
        onPartSave={onPartSave}
        disableSelect={disableSelect}
        initFilter={initFilter}
        mode={mode}
      />
    );
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      requiredFieldsHeader: translate('plants_required_fields_header'),
      catalogPartsLabel: translate('plants_catalog_parts_label'),
      selfPartsLabel: translate('plants_self_parts_label'),
    };
  }, []);
  return (
    <DodgeModal
      open={isOpen}
      onClose={onClose}
      width='850px'
      testId='plants_management_part_selection_modal'
      title={
        <FormControl sx={{ margin: '0 1rem 1rem' }}>
          <RadioGroup
            value={selectedPage}
            onChange={(e) => setSelectedPage(Number(e.target.value))}
            sx={{
              color: 'black',
            }}
            row
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              data-testid='plants_management_dodge_part_number'
              label={componentText.catalogPartsLabel}
            />
            {mode !== 'pick' && (
              <FormControlLabel
                value={1}
                control={<Radio />}
                data-testid='plants_management_self_part_number'
                label={componentText.selfPartsLabel}
              />
            )}
          </RadioGroup>
        </FormControl>
      }
      hideCloseIco
    >
      {component}
    </DodgeModal>
  );
};

export default SelectPartNoModal;
