import { SxProps, Theme } from '@mui/material';
import { memo, ReactNode } from 'react';
import Folded from './Folded';
import Unfolded from './Unfolded';

type Props = {
  height: number;
  isOpen: boolean;
  onChange: (value: boolean) => void;
  children?: ReactNode;
  containerSx?: SxProps<Theme>;
};

const SideToolbox = ({ isOpen, onChange, children, containerSx, height }: Props) => {
  const defaultContainerSx: SxProps<Theme> = {
    position: 'sticky',
    right: 0,
    height: '100%',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    ...containerSx,
  };

  if (!isOpen) return <Folded unfoldClick={() => onChange(!isOpen)} />;
  else
    return (
      <Unfolded onFoldClick={() => onChange(!isOpen)} containerSx={defaultContainerSx}>
        {children ? children : <>Unfolded component</>}
      </Unfolded>
    );
};

export default memo(SideToolbox);
