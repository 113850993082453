import { Box, CircularProgress, TablePagination, TextField, Typography } from '@mui/material';
import { Sensor } from 'features/plants-management/models/sensor.model';
import { getSensorIco } from 'helpers/getSensorIco';
import { componentTextType } from 'languages';
import React, { ReactNode, useState } from 'react';

const itemsOnPage = 5;

type SensorsListProps = {
  sensors: Sensor[];
  listHeader: string;
  emptyMessage: string;
  isLoading?: boolean;
  incrementIndex?: number;
  actionButton: ReactNode;
  onSensorClicked: (sensor: Sensor) => void;
  changeLocation?: (value: string, sensor: Sensor) => void;
  testid?: string;
  componentText: componentTextType;
};

export const SensorsList = ({
  sensors,
  listHeader,
  emptyMessage,
  isLoading,
  actionButton,
  onSensorClicked,
  changeLocation,
  incrementIndex,
  testid,
  componentText,
}: SensorsListProps) => {
  const [page, setPage] = useState<number>(0);
  const { rowsPerPageLabel, selected, positionLabel, characters } = componentText;
  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  if (!isLoading && !sensors.length) {
    return <Typography>{emptyMessage}</Typography>;
  }

  return (
    <Box display='flex' flexDirection='column' data-testid='sensors-list'>
      <Box display='flex' gap='1rem' alignItems='center'>
        {isLoading && <CircularProgress color='primary' size={'20px'} />}
        <Typography variant='h6' fontWeight='600' mb={1}>
          {listHeader}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap='0.5rem' data-testid={testid}>
        {sensors
          .slice(page * itemsOnPage, page * itemsOnPage + itemsOnPage)
          .map((sensor, index) => {
            return (
              <Box
                key={sensor.serialNumber}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box display='flex' alignItems='center' gap={1} flex='1 1 250px'>
                  {getSensorIco(sensor.type)}
                  <Typography fontSize='0.85rem'>{sensor.serialNumber}</Typography>
                </Box>
                {listHeader === selected && (
                  <Box
                    my='0.2rem'
                    mx='0.5rem'
                    display='flex'
                    justifyContent='end'
                    alignItems='center'
                    flex='4 1 60%'
                  >
                    <TextField
                      fullWidth
                      value={sensor.location}
                      onChange={(event) => changeLocation!(event.target.value, sensor)}
                      size='small'
                      label={positionLabel}
                      FormHelperTextProps={{
                        style: {
                          textAlign: 'end',
                          marginRight: 0,
                        },
                      }}
                      inputProps={{ maxLength: 75 }}
                      error={sensor.location.length > 74}
                      helperText={
                        <Typography
                          component='span'
                          color={
                            sensor.location.length > 74
                              ? 'error'
                              : sensor.location.length > 59
                                ? 'secondary'
                                : undefined
                          }
                          fontSize='0.75rem'
                          fontWeight='bold'
                        >
                          {`${sensor.location.length}/75 ${characters}`}
                        </Typography>
                      }
                    />
                  </Box>
                )}
                {React.isValidElement(actionButton) &&
                  React.cloneElement(actionButton, {
                    // @ts-ignore
                    onClick: () => {
                      onSensorClicked(sensor);
                    },
                  })}
              </Box>
            );
          })}
      </Box>
      {!isLoading && (
        <Box display='flex' justifyContent='center'>
          <TablePagination
            labelRowsPerPage={rowsPerPageLabel}
            component='div'
            count={sensors.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={itemsOnPage}
            rowsPerPageOptions={[itemsOnPage]}
            sx={{ border: 'none' }}
            data-testid='list-pagination'
            labelDisplayedRows={
              ({ from, to, count }) => {
                return `${from}-${to} ${componentText.of} ${count}`
              }
            }
          />
        </Box>
      )}
    </Box>
  );
};
