export const body = {
  fontWeight: "400",
  letterSpacing: "0.15px",
};
export const subTitle = {
    fontWeight: "bold",
    ml: "0.5rem",
    mb:"0.5rem",
    mt:"2rem",
}
export const bigSubTitle = {
    fontWeight: "bold",
    mb:"0.5rem",
    mt:"2rem",
}
export const subTitle2 = {
  fontWeight:"bold",
  mb:"1rem",
  mt:"1rem",
}