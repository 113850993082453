import {
  Box,
  Grid,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import classes from './DataTableToolbar.module.scss';
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import { useDeviceSize } from 'shared/responsive';
import { useTranslation } from 'languages';
import { LoadingButton } from '@mui/lab';

export interface TableToolbarProps<T> {
  header: string;
  total: number;
  searchPlaceholder?: string;
  leftToolbarAdditionalText?: string;
  leftToolbarAdditionalDownloadIcon?: boolean;
  leftToolbarAdditionalRefreshIcon?: boolean;
  rightToolbarSlot?: ReactNode;
  onSearch?: (filter: string) => void;
  searchBox?: string;
  searchInitValue?: string;
  leftToolbarAdditionalSelect?: ReactNode;
  onDownload?: (sortedAndFilteredRows?: T[] | []) => void;
  sortedAndFilteredRows?: T[] | [] | undefined;
  disabledTotal?: boolean;
  headerSx?: SxProps<Theme>;
  disableHeaderSeparator?: boolean;
  downloadIconColor?: string;
  searchControlled?: string;
  customTotalInfo ?: string;
  onRefresh?: () => void;
  refreshLoading?: boolean;
}

const DataTableToolbar = <T extends {}>(props: TableToolbarProps<T>) => {
  const theme = useTheme();
  const { isPhone, isTablet } = useDeviceSize();
  const [search, setSearch] = useState<string>('');

  const activePlantId = useSelector(selectPlantId);

  useEffect(() => {
    setSearch('');
  }, [activePlantId]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    props.onSearch?.(event.target.value);
  };

  useEffect(() => {
    if (props.searchInitValue) {
      setSearch(props.searchInitValue);
      props.onSearch?.(props.searchInitValue);
    } else {
      setSearch('');
    }
  }, [props.searchInitValue]);

  const { translate } = useTranslation();
  const componentText = useMemo(() => { 
    return {
      searchDefault: translate('asset_table_search_default'),
      totalInfo: translate('asset_table_total_info', { totalCount: props.total }),
    };
  }, [props.total]);

  if (isPhone || isTablet) {
    return (
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant='h6' sx={{ ...props.headerSx }}>
              {props.header}
              {!props.leftToolbarAdditionalSelect && !props.disabledTotal && (
                <Typography
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 500,
                    marginTop: 1,
                    marginLeft: 1,
                  }}
                >
                  {' | '}
                  {props.customTotalInfo ?? componentText.totalInfo}
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box display='flex' alignItems='center'>
            {!!props.leftToolbarAdditionalDownloadIcon && (
              <FileDownloadIcon
                onClick={() => props.onDownload?.(props.sortedAndFilteredRows)}
                sx={{
                  display: 'inline-block',
                  color: props.downloadIconColor ?? 'rgba(0, 0, 0, 0.54)',
                  cursor: 'pointer',
                  verticalAlign: 'middle',
                  marginRight: 2,
                  marginTop: 0.5,
                }}
              />
            )}
          {
          !!props.leftToolbarAdditionalRefreshIcon && 
            <LoadingButton disableElevation disableRipple onClick={() => props.onRefresh?.()} loading={props.refreshLoading} sx={{minWidth: '2.5rem', mt:'.125rem',  '&.MuiButtonBase-root:hover': {bgcolor: 'transparent'}}}>
              {!props.refreshLoading ? <RefreshIcon
                sx={{ color: props.downloadIconColor ?? 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
              /> : ' '}
            </LoadingButton>
          }
            </Box>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Box display='flex' gap={'1rem'}>
              {props.rightToolbarSlot}
            </Box>
          </Grid>
          <Grid item xs={12} mt={2} mb={1}>
            <TextField
              fullWidth
              onChange={changeHandler}
              defaultValue={props.searchControlled}
              className={classes['search-input']}
              type='search'
              data-testid='plant_management_search_text_field'
              placeholder={
                props.searchPlaceholder
                  ? props.searchPlaceholder
                  : componentText.searchDefault
              }
              value={search}
              variant='outlined'
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
  return (
    <Toolbar className={classes.toolbar}>
      <Box
        className={classes['toolbar-left']}
        sx={
          isPhone
            ? { flexDirection: 'column', alignItems: 'flex-start !important', gap: 2 }
            : undefined
        }
      >
        {props.leftToolbarAdditionalSelect}
        <Typography
          variant='h6'
          sx={{ ml: 2, [theme.breakpoints.down('xs')]: { ml: 0 }, ...props.headerSx }}
        >
          {props.header}
        </Typography>
        {!props.leftToolbarAdditionalSelect && !props.disabledTotal && (
          <Typography sx={{ ml: 1, fontSize: '14px', fontWeight: 500 }}>
            {' '}
            | {props.customTotalInfo ?? componentText.totalInfo}
          </Typography>
        )}
        {!!props.leftToolbarAdditionalText && <Typography sx={{ ml: 1 }}>&bull;</Typography>}
        {!!props.leftToolbarAdditionalText && (
          <Typography color='primary' sx={{ ml: 1, fontSize: '14px', fontWeight: 500 }}>
            {props.leftToolbarAdditionalText}
          </Typography>
        )}
        {!!props.leftToolbarAdditionalDownloadIcon && !props.disableHeaderSeparator && (
          <Typography sx={{ ml: 1, fontSize: '14px', fontWeight: 500 }}> | </Typography>
        )}
        {!!props.leftToolbarAdditionalDownloadIcon && (
          <Typography
            sx={{
              ml: 1,
              fontSize: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FileDownloadIcon
              onClick={() => props.onDownload?.(props.sortedAndFilteredRows)}
              sx={{ color: props.downloadIconColor ?? 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
            />
          </Typography>
        )}
        {
          !!props.leftToolbarAdditionalRefreshIcon && 
            <LoadingButton disableElevation disableRipple onClick={() => props.onRefresh?.()} loading={props.refreshLoading} sx={{minWidth: '2.5rem', '&.MuiButtonBase-root:hover': {bgcolor: 'transparent'}}}>
              {!props.refreshLoading ? <RefreshIcon
                sx={{ color: props.downloadIconColor ?? 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
              /> : ' '}
            </LoadingButton>
        }
        {props.searchBox !== 'none' && (
          <TextField
            sx={{ ml: isPhone ? 2 : 4, [theme.breakpoints.down('xs')]: { ml: 0 } }}
            onChange={changeHandler}
            defaultValue={props.searchControlled}
            className={classes['search-input']}
            type='search'
            data-testid='plant_management_search_text_field'
            placeholder={
              props.searchPlaceholder ? props.searchPlaceholder : componentText.searchDefault
            }
            value={search}
            variant='outlined'
            size='small'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Box>
      <Box>{props.rightToolbarSlot}</Box>
    </Toolbar>
  );
};

export default DataTableToolbar;
