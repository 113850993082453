import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { tooltipStyle } from './SyncError';
import { useTranslation } from 'languages';

type PropsNoDataError = {
  children: any;
};

const MissingCartridgeError: FC<PropsNoDataError> = ({ children }) => {
  const { translate } = useTranslation();
  return (
    <Tooltip
      title={translate('asset_tile_body_missing_cartridge_tooltip')}
      placement='top'
      componentsProps={{ tooltip: { sx: tooltipStyle } }}
    >
      {children}
    </Tooltip>
  );
};

export default MissingCartridgeError;
