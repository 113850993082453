import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { usePostCollectRawData } from 'api/assets/usePostCollectRawData';
import { usePostCollectRawDataEdwin } from 'api/assets/usePostCollectRawDataEdwin';
import { RoleRequired } from 'components/RoleRequired';
import {
  selectActiveAsset,
  selectActiveSensor,
  selectQueueCollectRawData,
  selectSelectedAssets,
  selectSelectedSensors,
} from 'features/assets-management/store/assetsManagementSlice';
import { UserRole } from 'features/plants-management/models/user.model';
import { useTranslation } from 'languages';
import { useSelector } from 'react-redux';
import { selectPlant } from 'store/accountSlice';

const CollectRawData = () => {
  const title = useSelector(selectActiveAsset);
  const activeSensor = useSelector(selectActiveSensor);
  const locations = useSelector(selectSelectedSensors);
  const selectedSensor = locations.find((s) => s.sensorId === activeSensor);
  const selectedAsset = useSelector(selectSelectedAssets).find((a) => a.name === title);
  const queueCollectRawData = useSelector(selectQueueCollectRawData);
  const selectedPlant = useSelector(selectPlant);
  const { collectRawData } = usePostCollectRawData({assetId: selectedAsset?.assetId, sensorId: selectedSensor?.sensorId});
  const isCollecting = selectedSensor && queueCollectRawData.includes(selectedSensor.sensorId);

  const {mutate: collectRawDataMutation} = collectRawData

  const handleCollectRawData = () => {
    if (selectedAsset && selectedSensor?.type === 'PerformanceSensor') {
      collectRawDataMutation({
        params: { assetId: selectedAsset.assetId, sensorId: selectedSensor.sensorId },
      });
    } else if (selectedAsset && selectedSensor?.type === 'Sensor') {
      collectRawDataMutationEdwin ({
        params: { assetId: selectedAsset.assetId, sensorId: selectedSensor.sensorId },
      });
    }
  };

  const { translate } = useTranslation();

  const { mutate: collectRawDataMutationEdwin, isPending: isPendingEdwin } = usePostCollectRawDataEdwin();

  const componentText = {
    collectRawDataButton: translate('asset_collect_raw_data_button'),
  };

  return (
    <>
      {selectedSensor?.isMonitored && (
        <RoleRequired
          roles={[
            'Super Viewer',
            ...(selectedPlant?.plantSettings.enableLoadMeasurementsForPlantViewers
              ? (['Plant Viewer'] satisfies UserRole[])
              : []),
          ]}
          mode='blacklist'
        >
          <Grid item display='flex' alignItems='center' style={{ height: '100%' }}>
            <LoadingButton
              style={{ marginLeft: 2 }}
              variant='text'
              size='large'
              color='primary'
              disabled={isCollecting || isPendingEdwin}
              onClick={handleCollectRawData}
              loading={isCollecting || isPendingEdwin}
              loadingIndicator={
                <Typography variant='subtitle1' color='primary'>
                  Loading...
                </Typography>
              }
            >
              {!isCollecting && !isPendingEdwin && (
                <Typography
                  variant='subtitle1'
                  color='primary'
                  data-testid='assets_management_collect_raw_data_button'
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '.9rem',
                  }}
                >
                  {isCollecting || isPendingEdwin ? 'Collecting Raw Data...' : componentText.collectRawDataButton}
                </Typography>
              )}
            </LoadingButton>
          </Grid>
        </RoleRequired>
      )}
    </>
  );
};

export default CollectRawData;
