import { Button, Grid } from "@mui/material";
import { useTranslation } from "languages";
import { FC, memo, useMemo } from "react";

const ranges = [{id:1, days:0, hrs: 12}, {id:2, days:0, hrs: 24}, {id:3, days:7, hrs: 0}, {id:4, days:30, hrs: 0}, {id: 5, days:90, hrs: 0}];

interface RangeProps {
    handleDateRange: (days:number, hrs:number) => void;
}

const Range: FC<RangeProps> = ({handleDateRange}) =>{
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
     last: translate("last_chp"),
     days : translate("days_chp"),
     hours: translate("hours_chp"),
    };
  }, []);
    return(
        <Grid container direction="column" item xs={3} sx={{px:"1rem", py:"1.5rem", background:"rgba(51, 113, 121, 0.15)", borderTopLeftRadius:"12px", borderBottomLeftRadius:"12px"}}>
        {
          ranges.map(range => <Grid item ml="0.4rem" py="0.25rem" key={range.id}><Button onClick={()=>handleDateRange(range.days, range.hrs)} color="primary" variant="text"  sx={{paddingLeft:0, fontSize:"0.9rem",fontWeight:600, '&:hover':{background:"none"}}}>{componentText.last} {range.days ? range.days +" " + componentText.days: range.hrs + " " + componentText.hours}</Button></Grid>)
        }
      </Grid>
    );
}

export default memo(Range);