import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { PlantBody } from 'models/plant/createPlantBody.model';
import axios from 'axios';

export type CreatePlantError = {
  message: string;
};

export const predictedErrors = {
  UniqueKeyViolation: {
    message: 'Provided plant name is already in use',
  },
};

export const useCreatePlant = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useAuthMutation<{}, PlantBody>({
    url: 'plants',
    method: 'POST',
    mutationOptions: {
      onSuccess: () => {
        onSuccess?.();
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes(`plants`) });
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes(`users`) });
      },
      onError(error) {
        Object.entries(predictedErrors).forEach(([key, value]) => {
          if (axios.isAxiosError(error)) {
            if (key === error.response?.data.message) {
              toast.error(value.message);
            } else {
              toast.error('Unexpected error');
            }
          } else {
            if (key === error.message) {
              toast.error(value.message);
            } else {
              toast.error('Unexpected error');
            }
          }
        });
      },
    },
  });
};
