import { useAuthMutation } from 'api/queries';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'languages';
import { useMemo } from 'react';

interface AcceptUserToPlantBody {
  accepted: boolean;
  email: string;
}

interface AcceptUserToPlantParams {
  plantId: number;
}

export const useAcceptUserToPlant = () => {
  const queryClient = useQueryClient();
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successMess: translate('user_successfully_accepted_to_plant_toast'),
      errorMess: translate('user_failed_to_be_accepted_to_plant_toast'),
    };
  }, []);
  return useAuthMutation<AcceptUserToPlantParams, AcceptUserToPlantBody>({
    url: (params: AcceptUserToPlantParams) => `plants/${params.plantId}/users/accept`,
    method: 'PUT',
    mutationOptions: {
      onSuccess: (_, { params }) => {
        toast.success(componentText.successMess);
        queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('users') });
      },
      onError: () => {
        toast.error(componentText.errorMess);
      },
    },
  });
};
