import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAxis,
  selectFFTSensor,
  selectFilterBeans,
  selectMaxRunningSpeedValue,
  selectRunningSpeedFFT,
  selectSelectedSensorTriAxisMode,
  selectTempNominalSpeed,
  selectViewType,
  setActiveRMS,
  setActiveUnit,
  setAxis,
  setFaultFrequencies,
  setFilterBeans,
  setMarginRange,
  setNominalSpeed,
  setNumberOfHarmonics,
  setTempNominalSpeed,
  setWindowingFunction,
} from 'features/assets-management/store/assetsManagementSlice';
import SelectRMS from '../../../VibrationFFTHeader/SelectRMS';
import Settings from './Settings';
import { useTranslation } from 'languages';
import { SensorType } from 'models/sensor/models';
import { Asset, Sensor } from 'features/assets-management/models';

type Props = {
  rms: string;
  unit: string;
  selectedAsset: Asset | undefined;
  selectedAssetId: number;
  location: number;
  sensorInfo: Sensor | undefined;
  faultFrequencies: string[];
  numberOfHarmonics: number | undefined;
  isSelectedSensors: boolean;
  windowingFunction: string;
  marginRange: number;
  nominalSpeed: number;
  bearingReadyToAnalytics: string | null;
  deReadyToAnalytics: string | null;
  ndeReadyToAnalytics: string | null;
};

const SpectralAnalysisSettings: FC<Props> = ({
  rms,
  unit,
  selectedAsset,
  selectedAssetId,
  location,
  sensorInfo,
  faultFrequencies,
  numberOfHarmonics,
  isSelectedSensors,
  windowingFunction,
  marginRange,
  nominalSpeed,
  bearingReadyToAnalytics,
  deReadyToAnalytics,
  ndeReadyToAnalytics,
}) => {
  const dispatch = useDispatch();
  const filterBeans = useSelector(selectFilterBeans);
  const [localFilterBeans, setLocalFilterBeans] = useState<number | string>(filterBeans);
  const view = useSelector(selectViewType);
  const fftSensor = useSelector(selectFFTSensor);
  const axis = useSelector(selectAxis);
  const runningSpeedFFT = useSelector(selectRunningSpeedFFT);

  const [baseNominalSpeed, setBaseNominalSpeed] = useState(selectedAsset?.nominalSpeed ?? 0);
  const tempNominalSpeed = useSelector(selectTempNominalSpeed);
  const [tempFF, setTempFF] = useState<string[]>([]);
  const [changesInputEventDetected, setChangesInputEventDetected] = useState(false);
  const [test, setTest] = useState(false);
  const isTriAxisEnabled = useSelector(selectSelectedSensorTriAxisMode);
  const maxVal = useSelector(selectMaxRunningSpeedValue);
  const [open, setOpen] = useState(false);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      fftSpectralAnalysisSettings: translate('fft_spectral_analysis_settings'),
      fftAxis: translate('fft_axis'),
      fftRadial: translate('fft_radial'),
      fftAxial: translate('fft_axial'),
      fftTangential: translate('fft_tangential'),
      fftChartUnit: translate('fft_chart_unit'),
      fftFaultFrequencies: translate('fft_fault_frequencies'),
      fftNoHarmonics: translate('fft_no_harmonics'),
      fftWindowingFunction: translate('fft_windowing_function'),
      fftRunningSpeed: translate('fft_running_speed'),
      fftNominalSpeed: translate('fft_nominal_speed'),
      fftBackToNominalSpeed: translate('fft_back_to_nominal_speed'),
      fftMarkerWidth: translate('fft_marker_width'),
      fftHighPassFilter: translate('fft_high_pass_filter'),
      fftTextOnlyDodgeBearings: translate('fft_text_only_dodge_bearings'),
      fftTextFfBearingsNominalSpeed: translate('fft_text_ff_bearings_nominal_speed'),
      fftTextFfNotAvailableMotor: translate('fft_text_ff_not_available_motor'),
      fftTextFfNotAvailable: translate('fft_text_ff_not_available'),
      fftRunningSpeedOption: translate('fft_running_speed_option'),
      fftRoller: translate('fft_roller'),
      fftInner: translate('fft_inner'),
      fftOuter: translate('fft_outer'),
      fftCage: translate('fft_cage'),
      none: translate('none')
    };
  }, []);

  const faultFrequenciesAvailable: string | true = useMemo(() => {
    if (selectedAsset?.type === 'bearing') {
      if (bearingReadyToAnalytics?.includes('nonDodgeBearing'))
        return componentText.fftTextOnlyDodgeBearings;
      // else if (bearingReadyToAnalytics?.includes('lowNominalSpeed'))
      //   return componentText.fftTextFfBearingsNominalSpeed;
      else return true;
    } else if (selectedAsset?.type === 'motor') {
      if (fftSensor && fftSensor.motorInfo === 'DE' && deReadyToAnalytics !== 'ready')
        return componentText.fftTextFfNotAvailableMotor;
      else if (fftSensor && fftSensor.motorInfo === 'NDE' && ndeReadyToAnalytics !== 'ready')
        return componentText.fftTextFfNotAvailableMotor;
      else return true;
    } else {
      return `${componentText.fftTextFfNotAvailable} ${selectedAsset?.type}.`;
    }
  }, [selectedAsset, fftSensor, deReadyToAnalytics, ndeReadyToAnalytics, bearingReadyToAnalytics]);

  const handleChangeRms = (e: SelectChangeEvent<string>) => {
    dispatch(setActiveRMS(e.target.value));
  };

  const handleChangeUnit = (e: SelectChangeEvent<string>) => {
    dispatch(setActiveUnit(e.target.value));
  };

  const handleChangeAxis = (e: SelectChangeEvent<string>) => {
    dispatch(setAxis(e.target.value));
  };

  const handleChangeFilterBins = useCallback(
    (value: string) => {
      if (value.length === 0 || Number(value) < 0) {
        dispatch(setFilterBeans(0));
        setLocalFilterBeans(0);
      } else if (unit === 'Hz' ? Number(value) > 10 : Number(value) > 600) {
        dispatch(setFilterBeans(10));
        setLocalFilterBeans(10);
      } else {
        dispatch(
          setFilterBeans(
            unit === 'Hz' ? Math.round(Number(value)) : Math.round(Number(value) / 60),
          ),
        );
        setLocalFilterBeans(
          unit === 'Hz' ? Math.round(Number(value)) : Math.round(Number(value) / 60),
        );
      }
    },
    [dispatch, unit],
  );

  useEffect(() => {
    if (
      localFilterBeans.toString().length === 0 ||
      (unit === 'Hz' ? Number(localFilterBeans) > 10 : Number(localFilterBeans) > 600) ||
      Number(localFilterBeans) < 0
    ) {
      return;
    }
    const timeout = setTimeout(
      () =>
        handleChangeFilterBins(
          typeof localFilterBeans === 'string'
            ? localFilterBeans
            : unit === 'Hz'
              ? localFilterBeans.toString()
              : (localFilterBeans * 60).toString(),
        ),
      2000,
    );
    return () => clearTimeout(timeout);
  }, [handleChangeFilterBins, localFilterBeans, unit]);

  useEffect(() => {
    dispatch(setFaultFrequencies(['runningSpeed']));
    dispatch(setActiveRMS('Velocity-Z'));
    dispatch(setNumberOfHarmonics(1))
  }, [location]);

  useEffect(() => {
    if (view === '3d') {
      setTempFF(faultFrequencies);
      dispatch(setFaultFrequencies([]));
    } else {
      if (tempFF.length > 0 && faultFrequencies && faultFrequencies.length === 0)
        dispatch(setFaultFrequencies(tempFF));
      else dispatch(setFaultFrequencies(['runningSpeed']));
    }
  }, [view]);

  useEffect(() => {
    setChangesInputEventDetected(false);
    if (!selectedAsset) {
      return;
    }
    if (runningSpeedFFT === null || runningSpeedFFT === 0) {
      dispatch(setNominalSpeed(selectedAsset.nominalSpeed));
      setBaseNominalSpeed(selectedAsset.nominalSpeed);
      dispatch(setTempNominalSpeed(selectedAsset.nominalSpeed.toString()));
    } else {
      dispatch(setNominalSpeed(runningSpeedFFT));
      setBaseNominalSpeed(runningSpeedFFT);
      dispatch(setTempNominalSpeed(runningSpeedFFT.toString()));
    }
  }, [dispatch, selectedAsset, runningSpeedFFT]);

  useEffect(() => {
    const maxLimit = unit === 'Hz' ? maxVal * 60 : maxVal;
    Number(tempNominalSpeed) <= maxLimit &&
      (unit === 'Hz' ? Number(tempNominalSpeed) >= 0 : Number(tempNominalSpeed) >= 0) &&
      dispatch(setNominalSpeed(Number(tempNominalSpeed)));
  }, [tempNominalSpeed, maxVal]);

  const helperText = useMemo(
    () =>
      maxVal !== 0
        ? unit === 'Hz'
          ? Number(tempNominalSpeed) < 0.01
            ? 'Min value: 0.01'
            : Number(tempNominalSpeed) > Number(maxVal * 60)
              ? `Max value: ${Math.floor(maxVal * 100) / 100}`
              : ''
          : Number(tempNominalSpeed) < 0.1
            ? 'Min value: 0.1'
            : Number(tempNominalSpeed) > Number(maxVal)
              ? `Max value: ${Math.floor(maxVal * 10) / 10}`
              : ''
        : '',
    [unit, tempNominalSpeed, baseNominalSpeed, maxVal],
  );

  const error = useMemo(
    () =>
      maxVal !== 0
        ? unit === 'Hz'
          ? Number(tempNominalSpeed) < 0.01 ||
            Number(tempNominalSpeed) > Math.floor(maxVal * 60 * 100) / 100
          : Number(tempNominalSpeed) < 0.1 ||
            Number(tempNominalSpeed) > Number(Math.floor(maxVal * 100) / 100)
        : false,
    [tempNominalSpeed, maxVal],
  );
  const getValue = () => {
    return unit === 'Hz'
      ? changesInputEventDetected
        ? test
          ? tempNominalSpeed
          : Number(Number(tempNominalSpeed) / 60).toString()
        : Number(Number(tempNominalSpeed) / 60)
            .toFixed(2)
            .toString()
      : changesInputEventDetected
        ? Number(tempNominalSpeed).toString()
        : Number(tempNominalSpeed).toFixed(1).toString();
  };

  const faultFrequenciesMenuItems = [
    <MenuItem
      key='runningSpeed'
      value='runningSpeed'
      data-testid='assets_management_vibration_fft_running_speed'
    >
      {componentText.fftRunningSpeedOption}
    </MenuItem>,
    <MenuItem key='roller' value='roller' data-testid='assets_management_vibration_fft_roller'>
      {componentText.fftRoller}
    </MenuItem>,
    <MenuItem key='innerrace' value='innerrace' data-testid='assets_management_vibration_fft_inner'>
      {componentText.fftInner}
    </MenuItem>,
    <MenuItem key='outerrace' value='outerrace' data-testid='assets_management_vibration_fft_outer'>
      {componentText.fftOuter}
    </MenuItem>,
    <MenuItem key='cage' value='cage' data-testid='assets_management_vibration_fft_cage'>
      {componentText.fftCage}
    </MenuItem>,
  ];

  const handleOpenTooltip = () =>{
    setOpen(true)
  }
  const handleCloseTooltip = () =>{
    setOpen(false)
  }

  return (
    <Settings title={componentText.fftSpectralAnalysisSettings}>
      <React.Fragment>
        {faultFrequenciesAvailable && typeof faultFrequenciesAvailable === 'string' && (
          <Grid
            data-testid='assets_management_vibration_fft_fault_frequencies_message'
            item
            xs={12}
            sx={{
              background: '#fffaef',
              borderRadius: '.25rem',
              border: '1px solid #f0b323',
              p: 1,
              fontSize: '.8rem',
            }}
          >
            {faultFrequenciesAvailable}
          </Grid>
        )}
        <Grid item>
          {sensorInfo && (
            <SelectRMS
              rms={rms}
              onChange={handleChangeRms}
              selectedAssetId={selectedAssetId}
              selectedSensor={location}
              sensorType={sensorInfo.type as SensorType}
              isAssetDodgeAndBearing={!!selectedAsset?.isDodge && selectedAsset?.type === 'bearing'}
              background='white'
            />
          )}
        </Grid>
        {sensorInfo && (sensorInfo.type === 'PerformanceSensor' || isTriAxisEnabled) && (
          <FormControl size='small' fullWidth>
            <InputLabel id='select-axis-label'>{componentText.fftAxis}</InputLabel>
            <Select
              data-testid='assets_management_vibration_fft_axis_input'
              labelId='select-axis-label'
              id='select-axis'
              value={axis}
              onChange={handleChangeAxis}
              label={componentText.fftAxis}
              sx={{ '&.MuiOutlinedInput-root': { background: 'white' } }}
            >
              <MenuItem value='Z' data-testid='assets_management_vibration_fft_axis_radial'>
                {componentText.fftRadial}
              </MenuItem>
              <MenuItem value='X' data-testid='assets_management_vibration_fft_axis_axial'>
                {componentText.fftAxial}
              </MenuItem>
              <MenuItem value='Y' data-testid='assets_management_vibration_fft_axis_tagential'>
                {componentText.fftTangential}
              </MenuItem>
            </Select>
          </FormControl>
        )}
        <Grid item container spacing={1}>
          <Grid item xs={7}>
            <TextField
              fullWidth
              type='number'
              value={
                unit === 'Hz' || typeof localFilterBeans === 'string'
                  ? localFilterBeans
                  : localFilterBeans * 60
              }
              onChange={(e) => {
                setLocalFilterBeans(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleChangeFilterBins(localFilterBeans.toString());
                }
              }}
              onBlur={(e) => handleChangeFilterBins(e.target.value)}
              label={componentText.fftHighPassFilter}
              data-testid='assets_management_vibration_fft_high_pass_filter_input'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    data-testid='assets_management_vibration_fft_high_pass_filter_unit'
                    position='end'
                  >
                    {unit}
                  </InputAdornment>
                ),
                inputProps: {
                  min: 0,
                  max: unit === 'Hz' ? 10 : 600,
                  step: unit === 'Hz' ? 1 : 60,
                },
              }}
              sx={{ '& .MuiOutlinedInput-root': { background: 'white' } }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl size='small' fullWidth>
              <InputLabel id='select-unit-label'>{componentText.fftChartUnit}</InputLabel>
              <Select
                data-testid='assets_management_vibration_fft_chart_unit_input'
                labelId='select-unit-label'
                id='select-unit'
                value={unit}
                onChange={handleChangeUnit}
                label={componentText.fftChartUnit}
                sx={{ '&.MuiOutlinedInput-root': { background: 'white' } }}
              >
                <MenuItem data-testid='assets_management_vibration_fft_chart_unit_hz' value='Hz'>
                  Hz
                </MenuItem>
                <MenuItem data-testid='assets_management_vibration_fft_chart_unit_cpm' value='CPM'>
                  CPM
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={7}>
            <FormControl size='small' fullWidth>
              <InputLabel id='select-fault-frequencies-label'>
                {componentText.fftFaultFrequencies}
              </InputLabel>
              <Select
                data-testid='assets_management_vibration_fft_fault_frequencies_input'
                labelId='select-fault-frequencies-label'
                id='select-fault-frequencies'
                multiple
                value={faultFrequencies}
                onChange={(e) => {
                  dispatch(setFaultFrequencies(e.target.value));
                }}
                label={componentText.fftFaultFrequencies}
                disabled={isSelectedSensors ? view === '3d' : true}
                sx={{ '&.MuiOutlinedInput-root': { background: 'white', width: '10rem' } }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                {typeof faultFrequenciesAvailable === 'boolean' && faultFrequenciesAvailable
                  ? faultFrequenciesMenuItems
                  : faultFrequenciesMenuItems.slice(0, 1)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            {view !== '3d' && (
              <Tooltip open={open} onClose={handleCloseTooltip} title="Accepted values only from 1 to 20" TransitionComponent={Zoom} componentsProps={{ tooltip: {sx:{background:'#FEF9ED', border:'1px solid #F0B323', borderRadius: '6px', color: 'black', letterSpacing: '.5px', fontSize: '.7rem'}}}}>
                <TextField 
                  data-testid='assets_management_vibration_no_harmonics_input'
                  id='number-of-harmonics'
                  size='small'
                  fullWidth
                  label={componentText.fftNoHarmonics}
                  value={numberOfHarmonics}
                  type='number'
                  onChange={(e) => {
                    handleCloseTooltip();
                    if(e.target.value.length === 0){
                      dispatch(setNumberOfHarmonics(undefined));
                      return;
                    }
                    const newVal = e.target.value.length && parseInt(e.target.value, 10);
                    if(newVal > 20){
                      dispatch(setNumberOfHarmonics(20));
                      handleOpenTooltip()
                    } 
                    else if(newVal < 1){
                      dispatch(setNumberOfHarmonics(1))
                      handleOpenTooltip()
                    }
                    else{
                      dispatch(setNumberOfHarmonics(newVal))
                    }
                  }}
                  onBlur={(e) => {
                    e.target.value.length === 0 && dispatch(setNumberOfHarmonics(1))
                  }}
                  sx={{ '& .MuiOutlinedInput-root': { background: 'white' } }}
                  InputProps={{
                    inputProps:{
                      step: 1
                    }
                  }}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={7}>
            <FormControl size='small' fullWidth>
              <InputLabel id='select-wuindowing-label'>
                {componentText.fftWindowingFunction}
              </InputLabel>
              <Select
                data-testid='assets_management_vibration_fft_windowing_function_input'
                labelId='select-windowing-label'
                id='select-windowing'
                value={windowingFunction}
                onChange={(e) => dispatch(setWindowingFunction(e.target.value))}
                label={componentText.fftWindowingFunction}
                sx={{ '&.MuiOutlinedInput-root': { background: 'white' } }}
              >{
                [{value: 'None', label: componentText.none}, {value: 'Hann', label: 'Hann'}].map( option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
              }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            {view !== 'Spectrogram' && view !== '3d' && (
              <TextField
                fullWidth
                type='number'
                value={unit === 'Hz' ? marginRange / 60 : marginRange}
                onChange={(e) => {
                  dispatch(
                    setMarginRange(unit === 'Hz' ? Number(e.target.value) * 60 : e.target.value),
                  );
                }}
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    dispatch(setMarginRange(60));
                  } else if (
                    unit === 'Hz' ? Number(e.target.value) < 0.5 : Number(e.target.value) < 30
                  ) {
                    dispatch(setMarginRange(30));
                  } else if (
                    unit === 'Hz' ? Number(e.target.value) > 10 : Number(e.target.value) > 600
                  ) {
                    dispatch(setMarginRange(600));
                  }
                }}
                data-testid='assets_management_vibration_fft_marker_width_input'
                label={componentText.fftMarkerWidth}
                size='small'
                InputProps={{
                  endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>,
                  inputProps: {
                    min: unit === 'Hz' ? 0.5 : 30,
                    max: unit === 'Hz' ? 10 : 600,
                    step: unit === 'Hz' ? 0.5 : 1,
                  },
                }}
                sx={{ '& .MuiOutlinedInput-root': { background: 'white' } }}
              />
            )}
          </Grid>
        </Grid>
        {view !== '3d' && view !== 'Spectrogram' && baseNominalSpeed !== 0 && (
          <Grid
            item
            container
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.25)',
              borderRadius: '.25rem',
              p: '1rem .5rem',
              background: 'white',
            }}
          >
            <Grid item xs={12}>
              {!selectedAsset || baseNominalSpeed === 0 ? (
                <Typography sx={{ fontSize: '0.8rem' }}>
                  This option is only available for devices with a nominal speed higher than 0 RPM.
                </Typography>
              ) : (
                <TextField
                  fullWidth
                  type='number'
                  disabled
                  value={
                    unit === 'Hz'
                      ? Number(baseNominalSpeed / 60)
                          .toFixed(2)
                          .toString()
                      : Number(baseNominalSpeed.toFixed(1)).toString()
                  }
                  label={componentText.fftNominalSpeed}
                  size='small'
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>,
                  }}
                  sx={{ '& .MuiOutlinedInput-root': { background: 'white' } }}
                />
              )}
            </Grid>
            <Grid item container justifyContent='center' mt={2.5} flexDirection='column' gap={2}>
              <TextField
                fullWidth
                type='number'
                className='number-input'
                value={getValue()}
                onChange={(e) => {
                  const arrValue = e.target.value.split('.');
                  const stringValue =
                    arrValue.length === 1
                      ? e.target.value
                      : unit === 'Hz'
                        ? arrValue[0] + '.' + arrValue[1].substring(0, 2)
                        : arrValue[0] + '.' + arrValue[1].substring(0, 1);
                  e.nativeEvent instanceof InputEvent
                    ? setChangesInputEventDetected(true)
                    : setChangesInputEventDetected(false);
                  setTest(unit === 'Hz' && arrValue[1] === '0');
                  dispatch(
                    setTempNominalSpeed(
                      unit === 'Hz'
                        ? (arrValue[1] === '0' ? stringValue : Number(stringValue) * 60).toString()
                        : stringValue,
                    ),
                  );
                }}
                error={error}
                helperText={helperText}
                label={componentText.fftRunningSpeed}
                size='small'
                InputProps={{
                  endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>,
                  inputProps: {
                    min: unit === 'Hz' ? 0.01 : 0.1,
                    max:
                      unit === 'Hz' ? Number((maxVal * 60).toFixed(2)) : Number(maxVal.toFixed(1)),
                    step: unit === 'Hz' ? 0.01 : 0.1,
                  },
                }}
                sx={{ '& .MuiOutlinedInput-root': { background: 'white' } }}
              />
              <Button
                data-testid='assets_management_vibration_fft_back_to default_speed_button'
                variant='contained'
                size='small'
                onClick={() => {
                  dispatch(setTempNominalSpeed(baseNominalSpeed));
                  setChangesInputEventDetected(false);
                }}
              >
                {componentText.fftBackToNominalSpeed}
              </Button>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    </Settings>
  );
};

export default SpectralAnalysisSettings;
