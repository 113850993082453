import LanguagePopup from "components/LanguagePopup/LanguagePopup";
import { LoggedInProfile } from "components/LoggedInProfile";
import React, { useEffect, useState } from "react";

const Profile = () =>{
    const [open, setOpen] = useState(false);
    const [userLanguage, setUserLanguage] = useState('');
    const [hidePopup, setHidePopup] = useState(false)
    
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const isSpanishInterface = () => {
      const language = navigator.language;
      return language.startsWith('es');
      };

    useEffect(() => {
      const language = localStorage.getItem('language')
      const popup = localStorage.getItem('hideLanguagePopup')
      language && setUserLanguage(JSON.parse(language));
      popup && setHidePopup(JSON.parse(popup));
      }, []);

    useEffect(() => {
       if(hidePopup) return;

       isSpanishInterface() && userLanguage !== 'es' ?  handleClickOpen() : handleClose();
    }, [userLanguage])
  
    return (
      <React.Fragment>
        <LoggedInProfile />
        {open && !hidePopup && <LanguagePopup open={open} handleClose={handleClose} />}
      </React.Fragment>
    )
}

export default Profile;