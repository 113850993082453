import { useAuthMutation } from 'api/queries';

export type BodyModel = {
  name: string;
};

export const usePostApiKey = () => {
  return useAuthMutation<{}, BodyModel>({
    url: `users/api-keys`,
    method: 'POST',
  });
};
