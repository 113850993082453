import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { getUniqueList } from '../../../VibrationFFTHeader/utils/getUniqueAssetsList';
import { SensorType } from 'models/sensor/models';
import { Sensor } from 'features/assets-management/models';
import { compare, compareSensorName } from '../../../VibrationFFTHeader/utils/compareSensors';
import Settings from './Settings';
import {
  selectFFTSensor,
  selectInitialMotorInfo,
  setActiveAsset,
  setActiveSensor,
  setRunningSpeedFFT,
  setSelectedFFTSensor,
} from 'features/assets-management/store/assetsManagementSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'languages';

type Props = {
  excludedSensorTypes: SensorType[];
  asset: string;
  locations: Sensor[];
  location: number;
  isSelectedSensors: boolean;
  splitForMotor?: boolean;
};
const AssetSettings: FC<Props> = ({
  excludedSensorTypes,
  asset,
  locations,
  location,
  isSelectedSensors,
  splitForMotor,
}) => {
  const dispatch = useDispatch();
  const initialMotorInfo = useSelector(selectInitialMotorInfo);
  const [activeLocationList, setActiveLocationList] = useState<Sensor[]>([]);
  const [selectedPosValue, setSelectedPosValue] = useState<string>('');
  const fftSensor = useSelector(selectFFTSensor);

  const locationsFFT = useMemo(() => {
    if (locations && locations.length && splitForMotor) {
      !location ||
      !locations.find((l) => l.sensorId === location) ||
      !fftSensor ||
      !('motorInfo' in fftSensor)
        ? setSelectedPosValue(locations[0].sensorId + ' ' + 'DE')
        : setSelectedPosValue(location + ' ' + fftSensor.motorInfo);
      return locations
        .filter((sensor) => !excludedSensorTypes.includes(sensor.type as SensorType))
        .flatMap((item) => [
          { ...item, motorInfo: `DE`, initialMotorInfo },
          { ...item, motorInfo: `NDE`, initialMotorInfo },
        ]);
    } else {
      return locations
        .filter((sensor) => !excludedSensorTypes.includes(sensor.type as SensorType))
        .map((sensor) => ({
          ...sensor,
          name: initialMotorInfo ? sensor.name + ' ' + initialMotorInfo : sensor.name,
          initialMotorInfo,
        }));
    }
  }, [locations, splitForMotor, initialMotorInfo]);

  useEffect(() => {
    dispatch(setSelectedFFTSensor({ ...fftSensor, initialMotorInfo }));
  }, [locationsFFT, initialMotorInfo]);

  const availablePositionOptions = useMemo(() => {
    const temp = getUniqueList(activeLocationList, 'sensorId')
      // .filter((s) => !excludedSensorTypes.includes(s.type as SensorType))
      .sort(compare);

    if (!splitForMotor) return temp;
    return temp.flatMap((m) => [
      { ...m, motorInfo: 'DE' },
      { ...m, motorInfo: 'NDE' },
    ]);
  }, [activeLocationList, excludedSensorTypes]);

  useEffect(() => {
    if (isSelectedSensors && location === 0) {
      if (!location || !locationsFFT.find((l) => l.sensorId === location)) {
        dispatch(setActiveSensor(locationsFFT[0].sensorId));
        dispatch(setSelectedFFTSensor({ ...locationsFFT[0], motorInfo: 'DE' }));
        setSelectedPosValue(locationsFFT[0].sensorId + ' ' + 'DE');
      }
      (!asset || !locationsFFT.find((l) => l.parentName === asset)) &&
        dispatch(setActiveAsset(locationsFFT[0].parentName));
    } else if (isSelectedSensors && asset !== '') {
      const filteredList = locationsFFT.filter((item) => item.parentName === asset);
      setActiveLocationList(filteredList);
    } else if (!isSelectedSensors) {
      dispatch(setActiveSensor(0));
      dispatch(setActiveAsset(''));
      dispatch(setSelectedFFTSensor(null));
    }
  }, [locationsFFT]);

  useEffect(() => {
    if (isSelectedSensors && asset !== '') {
      const filteredList = locationsFFT.filter((item) => item.parentName === asset);
      setActiveLocationList(filteredList);
      if (filteredList.length > 0) {
        if (!location || !filteredList.find((l) => l.sensorId === location)) {
          dispatch(setActiveSensor(filteredList[0].sensorId));
          dispatch(setSelectedFFTSensor({ ...filteredList[0], motorInfo: 'DE' }));
          setSelectedPosValue(filteredList[0].sensorId + ' ' + 'DE');
        }
      }
    }
  }, [asset, locationsFFT, isSelectedSensors]);

  useEffect(() => {
    if (
      isSelectedSensors &&
      activeLocationList.length > 0 &&
      !locationsFFT.find((x) => x.sensorId === location)
    ) {
      dispatch(setActiveSensor(activeLocationList[0].sensorId));
      dispatch(setSelectedFFTSensor({ ...activeLocationList[0], motorInfo: 'DE' }));
      setSelectedPosValue(activeLocationList[0].sensorId + ' ' + 'DE');
    } else if (isSelectedSensors && activeLocationList.length === 0) {
      if (!location || !locationsFFT.find((l) => l.sensorId === location)) {
        dispatch(setActiveSensor(locationsFFT[0].sensorId));
        dispatch(setSelectedFFTSensor({ ...locationsFFT[0], motorInfo: 'DE' }));
        setSelectedPosValue(locationsFFT[0].sensorId + ' ' + 'DE');
      }
      (!asset || !locationsFFT.find((l) => l.parentName === asset)) &&
        dispatch(setActiveAsset(locationsFFT[0].parentName));
    }
  }, [activeLocationList]);

  const handleChangeAsset = (e: SelectChangeEvent<string>) => {
    dispatch(setRunningSpeedFFT(null));
    dispatch(setActiveAsset(e.target.value));
  };

  const handleChangeLocationForMotor = (type: 'DE' | 'NDE', selectedObject: Sensor | undefined) => {
    dispatch(setRunningSpeedFFT(null));
    dispatch(setActiveSensor(selectedObject?.sensorId ?? 0));
    dispatch(setSelectedFFTSensor({ ...selectedObject, motorInfo: type }));
  };
  const handleChangeLocation = (e: SelectChangeEvent<string>) => {
    dispatch(setRunningSpeedFFT(null));
    dispatch(setActiveSensor(Number(e.target.value)));
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      fftAssetSettings: translate('fft_asset_settings'),
      fftSelectAsset: translate('fft_select_asset'),
      fftSelectPosition: translate('fft_select_position'),
    };
  }, []);

  return (
    <Settings title={componentText.fftAssetSettings}>
      <React.Fragment>
        <Grid item>
          <FormControl size='small' fullWidth>
            <InputLabel id='select-asset-label'>{componentText.fftSelectAsset}</InputLabel>
            <Select
              data-testid='assets_management_vibration_fft_select_asset_input'
              labelId='select-asset-label'
              id='select-asset'
              value={asset}
              onChange={handleChangeAsset}
              label={componentText.fftSelectAsset}
              disabled={isSelectedSensors ? false : true}
              sx={{
                '&.MuiOutlinedInput-root': {
                  background: 'white',
                  width: '17.45rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                },
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {isSelectedSensors &&
                getUniqueList(locationsFFT, 'parentName')
                  // .filter((sensor) => !excludedSensorTypes.includes(sensor.type as SensorType))
                  .sort(compare)
                  .map((item: Sensor) => (
                    <MenuItem key={item.sensorId} value={item.parentName}>
                      {' '}
                      {item.parentName}{' '}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {splitForMotor ? (
            <FormControl size='small' fullWidth>
              <InputLabel id='select-location-label'>{componentText.fftSelectPosition}</InputLabel>
              <Select
                data-testid='assets_management_vibration_fft_select_position_input'
                labelId='select-location-label'
                id='select-location'
                defaultValue={''}
                value={selectedPosValue}
                onChange={(e) => {
                  const sensorId = e.target.value.split(' ')[0];
                  const type = e.target.value.split(' ')[1];

                  const foundOption = availablePositionOptions.find(
                    (f) => f.sensorId === parseInt(sensorId, 10) && f.motorInfo === type,
                  );

                  if (foundOption) {
                    setSelectedPosValue(e.target.value);
                    handleChangeLocationForMotor(type as 'DE' | 'NDE', foundOption);
                  }
                }}
                label={componentText.fftSelectPosition}
                disabled={isSelectedSensors ? false : true}
                sx={{
                  '&.MuiOutlinedInput-root': {
                    background: 'white',
                    width: '17.45rem',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  },
                }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                {isSelectedSensors &&
                  activeLocationList.length > 0 &&
                  availablePositionOptions.sort(compareSensorName).map((item: Sensor) => (
                    <MenuItem
                      data-testid='assets_management_vibration_fft_select_position_items'
                      key={String(item.sensorId) + `${item.motorInfo}`}
                      value={item.sensorId + ' ' + `${item.motorInfo}`}
                    >
                      {item.name + ' ' + `${item.motorInfo}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl size='small' fullWidth>
              <InputLabel id='select-location-label'>{componentText.fftSelectPosition}</InputLabel>
              <Select
                data-testid='assets_management_vibration_fft_select_position_input'
                labelId='select-location-label'
                id='select-location'
                value={location ? location.toString() : ''}
                onChange={handleChangeLocation}
                label={componentText.fftSelectPosition}
                disabled={isSelectedSensors ? false : true}
                sx={{
                  '&.MuiOutlinedInput-root': {
                    background: 'white',
                    width: '17.45rem',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  },
                }}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                {isSelectedSensors &&
                  activeLocationList.length > 0 &&
                  getUniqueList(activeLocationList, 'sensorId')
                    // .filter((s) => !excludedSensorTypes.includes(s.type as SensorType))
                    .sort(compare)
                    .map((item: Sensor) => (
                      <MenuItem key={item.sensorId} value={item.sensorId}>
                        {' '}
                        {item.name}{' '}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </React.Fragment>
    </Settings>
  );
};

export default AssetSettings;
