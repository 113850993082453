import { Box, Typography } from "@mui/material";
import { FC } from "react";
import {getColor, getSignalStrength} from "./GatewaysList"
import GatewayImage from "../../../../images/gateway-image.png";
import { Gateway } from "features/gateways-management/components/SensorAssignmentTable/models/SensorAssignment.models";
import { useGetSignalStrengthTranslated } from "./useGetSignalStrengthTranslated";

type Props = {
    gateway: Gateway,
    signalsData: {gatewayId: string, sumRssi: number, count: number}[];
}

const GatewayRow:FC<Props> = ({gateway, signalsData}) =>{
  const getSignalStrengthTranslated  = useGetSignalStrengthTranslated();
  return (
    <Box display="flex" alignItems="center" gap={1}>   
        <img src={GatewayImage} alt="gateway image" style={{transform: "scale(0.8)"}} />   
        <Typography variant="body1">{gateway.name}</Typography>
         <Box 
          width="5rem" 
          height="1.2rem" 
          p={.1} ml={1} 
          sx={{
            background: getColor(getSignalStrength(gateway.deviceId, signalsData)), 
            borderRadius:"12px",display:"flex", 
            justifyContent:"center", alignItems:"center", 
            color:"white", 
            fontSize:".8rem"
            }}>
               {getSignalStrengthTranslated(getSignalStrength(gateway.deviceId, signalsData))}
        </Box> 
    </Box>
  );
}

export default GatewayRow;