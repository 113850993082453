import { ArrowForwardIos } from '@mui/icons-material';
import { Divider, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { ReactNode, memo } from 'react';
import { useDeviceSize } from 'shared/responsive';

type Props = {
  onFoldClick: () => void;
  children: ReactNode;
  containerSx?: SxProps<Theme>;
};

const Unfolded = ({ children, onFoldClick, containerSx }: Props) => {
  const theme = useTheme();
  const { isPhone, isXSPhone } = useDeviceSize();

  return (
    <Stack sx={containerSx}>
      {children}
      <Box
        width={'100%'}
        sx={{ position: 'sticky', bottom: 0, backgroundColor: '#EFF4F4', zIndex: 1 }}
      >
        <Divider flexItem />
        <IconButton
          onClick={onFoldClick}
          sx={{
            width: isPhone || isXSPhone ? '44px' : undefined,
            float: isPhone || isXSPhone ? 'right' : undefined,
            [theme.breakpoints.only('sm')]: { width: 'initial', float: 'left' },
          }}
        >
          <ArrowForwardIos color='primary' />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default memo(Unfolded);
