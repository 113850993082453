import { ExpandLess, ExpandMore, Fullscreen, GetApp } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FFTDataAPI, useGetMultipleFFT } from 'api/assets/useGetFFT';
import { useGetUnits } from 'api/users/useGetUnits';
import DodgeModal from 'components/DodgeModal/DodgeModal';
import useDateFormatter from 'components/Uitls/useDateFormatter';
import { Sensor } from 'features/assets-management/models';
import { RMSDataType } from 'features/assets-management/models/DataType.models';
import { FFTData, Peak } from 'features/assets-management/models/FFTChart.models';
import {
  selectActiveRMS,
  selectActiveSensor,
  selectActiveUnit,
  selectFilterBeans,
  selectMarginRange,
  selectNominalSpeed,
  selectNumberOfHarmonics,
  selectPeakFactor,
  selectPeakSamples,
  selectSelectedBearingParts,
  selectSelectedSensorTriAxisMode,
  selectSelectedSensors,
  selectWindowing,
} from 'features/assets-management/store/assetsManagementSlice';
import { SensorType } from 'models/sensor/models';
import moment from 'moment';
import { memo, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { utils, write } from 'xlsx';
import SelectDataPrompt from '../SelectDataPrompt/SelectDataPrompt';
import useArrayMemo from '../TimeSeriesGraph/utils/useArrayMemo';
import ChartTooltip, { ChartTooltipData } from '../charts/ChartTooltip';
import FftChart, { HarmonicShape } from '../charts/FFTCharts';
import BearingPartsLegend from './BearingPartsLegend';
import { getFFTformContent } from './FFTform';

export type Units = {
  rms: string;
  frequency: string;
};

type Props = {
  dates: string[];
  activeAssetId: number;
  activeSensorId: number;
  activeUnit: string;
  activeFFTType: string;
  axis: string;
  velocityUnit: string;
};

export const fftLabelDict = new Map([
  ['Velocity-Z', 'Radial Velocity'],
  ['Acceleration-Z', 'Radial Acceleration'],
  ['Velocity-Y', 'Tangential Velocity'],
  ['Acceleration-Y', 'Tangential Acceleration'],
  ['Velocity-X', 'Axial Velocity'],
  ['Acceleration-X', 'Axial Acceleration'],
  ['envelopeVelocity-Z', 'Radial Envelope Velocity'],
  ['envelopeAcceleration-Z', 'Radial Envelope Acceleration'],
  ['envelopeVelocity-Y', 'Tangential Envelope Velocity'],
  ['envelopeAcceleration-Y', 'Tangential Envelope Acceleration'],
  ['envelopeVelocity-X', 'Axial Envelope Velocity'],
  ['envelopeAcceleration-X', 'Axial Envelope Acceleration'],
]);

export interface DelayedRefetchProps {
  nominalSpeed: number;
  marginRange: number;
  peakFactor?: number;
  peakSamples?: number;
}

const FftControl = (props: Props) => {
  const [folded, setFolded] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedMeasurements, setSelectedMeasurements] = useState<string[]>([]);
  const [url, setUrl] = useState<string>();
  const [units, setUnits] = useState<Units>();
  const [tooltipValue, setTooltipValue] = useState<ChartTooltipData | undefined>();
  const [disableDownload, setDisableDownload] = useState<boolean>(false);
  const [displayRunningSpeed, setDisplayRunningSpeed] = useState<boolean>(false);
  const dateFormatter = useDateFormatter();

  const selectedsensors = useSelector(selectSelectedSensors);
  const activeSensorId = useSelector(selectActiveSensor);
  const selectedRMS = useSelector(selectActiveRMS);
  const selectedUnit = useSelector(selectActiveUnit);
  const triAxisMode = useSelector(selectSelectedSensorTriAxisMode);
  const bearingParts = useSelector(selectSelectedBearingParts);
  const noOfHarmonics = useSelector(selectNumberOfHarmonics);
  const marginRange = useSelector(selectMarginRange);
  const nominalSpeed = useSelector(selectNominalSpeed);
  const peakFactor = useSelector(selectPeakFactor);
  const peakSamples = useSelector(selectPeakSamples);
  const windowing = useSelector(selectWindowing);
  const filterBins = useSelector(selectFilterBeans);

  const { data: unitsData } = useGetUnits();

  const [delayedRefetchProps, setDelayedRefetchProps] = useState<DelayedRefetchProps>({
    marginRange: marginRange,
    nominalSpeed: nominalSpeed,
  });

  useEffect(() => {
    setSelectedMeasurements([]);
  }, [activeSensorId]);

  useEffect(() => {
    if (selectedRMS.includes('envelop') && nominalSpeed < 500) {
      setSelectedMeasurements([]);
    }
  }, [nominalSpeed, selectActiveRMS]);

  useEffect(() => {
    if (marginRange < 30 || marginRange > 600 || marginRange.toString().length === 0) {
      return;
    }
    const timeout = setTimeout(() => {
      setDelayedRefetchProps({
        marginRange: marginRange,
        nominalSpeed: nominalSpeed,
        peakFactor: peakFactor,
        peakSamples: peakSamples,
      });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [marginRange, nominalSpeed, peakFactor, peakSamples]);

  useEffect(() => {
    if (selectedMeasurements.length > 5) {
      setDisableDownload(true);
    } else {
      setDisableDownload(false);
    }
  }, [selectedMeasurements.length]);

  useEffect(() => {
    if (unitsData) {
      setUnits({
        rms: unitsData.velocityrms.isImperialOn
          ? unitsData.velocityrms.impName
          : unitsData.velocityrms.metName,
        frequency: 'Hz',
      });
    }
  }, [unitsData]);

  useEffect(() => {
    if (selectedMeasurements.length === 0) {
      return;
    }

    if (!selectedMeasurements.every((measurement) => props.dates.includes(measurement))) {
      setSelectedMeasurements([]);
    }
  }, [selectedMeasurements, props.dates]);

  const queriesResults = useGetMultipleFFT({
    assetId: props.activeAssetId,
    sensorId: props.activeSensorId,
    dateTime: selectedMeasurements,
    freqUnit: props.activeUnit,
    velocityUnit: props.velocityUnit,
    FFTType: props.activeFFTType,
    axis: props.axis,
    bearingParts: bearingParts.join(','),
    noOfHarmonics,
    marginRange:
      props.activeUnit === 'metric'
        ? delayedRefetchProps.marginRange / 60
        : delayedRefetchProps.marginRange,
    speed: delayedRefetchProps.nominalSpeed,
    peakFactor: delayedRefetchProps.peakFactor,
    peakSamples: delayedRefetchProps.peakSamples,
    windowing,
    filterBins,
  });

  const memoizedArray: FFTDataAPI[] =
    useArrayMemo(
      queriesResults
        .filter((result) => result.status === 'success')
        .flatMap((query) => (query.data ? [query.data] : [])),
    ) ?? [];

  const [chartData, axesLabels, runningSpeedValues, harmonicShapes, peaks] = useMemo(() => {
    const chartDataTemp: FFTData[] = [];
    const axesLabelsTemp: (string | null)[] = [];
    const runningSpeedTemp: { nominalSpeedFrequency: number; nominalSpeed: number }[] = [];
    const harmonicShapes: HarmonicShape[] = [];
    const peaks: Peak[] = [];

    memoizedArray?.forEach((data, index) => {
      const fftData = data.fftData;
      if (fftData) {
        const yMax = Math.max(...fftData.map((item) => item.value));
        axesLabelsTemp.push(data.xLabelName, data.yLabelName);
        chartDataTemp.push({
          measurmentDate: selectedMeasurements[index],
          data: fftData,
        });
        runningSpeedTemp.push({
          nominalSpeedFrequency: data.nominalSpeedFrequency,
          nominalSpeed: data.nominalSpeed,
        });
        if (data.bearingPartsCage) {
          data.bearingPartsCage.forEach((cage) =>
            harmonicShapes.push({
              type: 'rect',
              layer: 'below',
              x0: cage.begin,
              x1: cage.end,
              y0: 0,
              y1: yMax,
              fillcolor: 'rgba(255, 196, 255, 0.5)',
              line: {
                color: 'rgba(255, 196, 255, 0.5)',
                width: 0,
              },
              measurementDate: moment(selectedMeasurements[index])
                .local()
                .format('MM/DD/YYYY|HH:mm'),
            }),
          );
        }
        if (data.bearingPartsRoller) {
          data.bearingPartsRoller.forEach((roller) =>
            harmonicShapes.push({
              type: 'rect',
              layer: 'below',
              x0: roller.begin,
              x1: roller.end,
              y0: 0,
              y1: yMax,
              fillcolor: 'rgba(224, 115, 92, 0.5)',
              line: {
                color: 'rgba(224, 115, 92, 0.5)',
                width: 0,
              },
              measurementDate: moment(selectedMeasurements[index])
                .local()
                .format('MM/DD/YYYY|HH:mm'),
            }),
          );
        }
        if (data.bearingPartsInnerRace) {
          data.bearingPartsInnerRace.forEach((innerRace) =>
            harmonicShapes.push({
              type: 'rect',
              layer: 'below',
              x0: innerRace.begin,
              x1: innerRace.end,
              y0: 0,
              y1: yMax,
              fillcolor: 'rgba(103, 196, 126, 0.5)',
              line: {
                color: 'rgba(103, 196, 126, 0.5)',
                width: 0,
              },
              measurementDate: moment(selectedMeasurements[index])
                .local()
                .format('MM/DD/YYYY|HH:mm'),
            }),
          );
        }
        if (data.bearingPartsOuterRace) {
          data.bearingPartsOuterRace.forEach((outerRace) =>
            harmonicShapes.push({
              type: 'rect',
              layer: 'below',
              x0: outerRace.begin,
              x1: outerRace.end,
              y0: 0,
              y1: yMax,
              fillcolor: 'rgba(94, 174, 248, 0.5)',
              line: {
                color: 'rgba(94, 174, 248, 0.5)',
                width: 0,
              },
              measurementDate: moment(selectedMeasurements[index])
                .local()
                .format('MM/DD/YYYY|HH:mm'),
            }),
          );
        }
        if (data.peaks) {
          peaks.push({
            measurementDate: selectedMeasurements[index],
            data: data.peaks,
          });
        }
      }
    });
    return [chartDataTemp, axesLabelsTemp, runningSpeedTemp, harmonicShapes, peaks];
  }, [memoizedArray, selectedMeasurements]);

  // Find proper vibration label
  const findVibrationLabel = (sensorType: SensorType, triAxisMode: boolean, rms: string) => {
    if (sensorType === 'PerformanceSensor' || triAxisMode === true) {
      return RMSDataType.triAxisMode.find((entry) => entry.axis === rms)?.label;
    } else {
      return RMSDataType.default.find((entry) => entry.axis === rms)?.label;
    }
  };

  const handleDownload = () => {
    if (units) {
      const { name, parentName, type } = selectedsensors.find(
        (sensor) => sensor.sensorId === activeSensorId,
      ) as Sensor;
      const dataType = findVibrationLabel(type as SensorType, !!triAxisMode, selectedRMS);
      const workbook = utils.book_new();
      chartData.forEach((item) => {
        if (item.measurmentDate) {
          const content = getFFTformContent(
            name,
            parentName as string,
            selectedRMS.toUpperCase().includes('ACCELERATION') ? 'g' : units.rms.split('RMS')[0],
            selectedUnit,
            dataType,
            item,
          );
          const sheetName = moment(item.measurmentDate).format('MM-DD-YYYY-HHmm');
          workbook.SheetNames.push(sheetName);
          const worksheet = utils.aoa_to_sheet(content as String[][]);
          workbook.Sheets[sheetName] = worksheet;
          worksheet['!cols'] = [{ wch: 30 }, { wch: 30 }];
        }
      });
      const wbout = write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([new Uint8Array(wbout)], { type: 'application/octet-stream' });
      setUrl(URL.createObjectURL(blob));
    }
  };

  const handleChangeDisplay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayRunningSpeed(event.target.checked);
  };

  return (
    <Box sx={{ ml: 2, mt: 2, p: 2 }}>
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ width: '100%', pr: 2 }}
      >
        <Grid item xs={10}>
          <Typography variant='h6' sx={{ fontWeight: 'bold', fontSize: '26px' }}>
            FFT
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: folded && chartData.length > 0 ? 'flex' : 'none',
            opacity: disableDownload ? 0.5 : 1,
          }}
          component={disableDownload ? 'div' : 'a'}
          href={url}
          download='fft_data.xlsx'
        >
          <Grid
            container
            alignItems={'center'}
            onClick={disableDownload ? undefined : handleDownload}
            sx={{ cursor: disableDownload ? 'default' : 'pointer', mr: '2rem' }}
            data-testid='fft-download'
          >
            <Grid item xs={3}>
              <GetApp color={disableDownload ? 'disabled' : 'primary'} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h5' sx={{ fontWeight: 500, fontSize: '16px', ml: '0.5125rem' }}>
                Download
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Grid
            container
            alignItems={'center'}
            justifyContent='space-between'
            sx={{ cursor: 'pointer' }}
            onClick={() => setFolded(!folded)}
          >
            <Grid item xs={3}>
              {folded ? <ExpandLess color='primary' /> : <ExpandMore color='primary' />}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                {folded ? 'Show less' : 'Show more'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={folded}>
        <Grid container sx={{ mt: 1, pr: 2 }} alignItems={'center'}>
          <Grid item xs={9} md={9}>
            <Autocomplete
              data-testid='fft-measurement'
              multiple
              limitTags={2}
              options={props.dates}
              getOptionLabel={(date) => dateFormatter(date, 'MM/DD/YYYY | HH:mm')}
              getOptionDisabled={(_) =>
                props.activeFFTType.includes('envelope') ? selectedMeasurements.length > 0 : false
              }
              renderInput={(measurements) => (
                <TextField
                  {...measurements}
                  label='Select Measurement'
                  data-testid='measurement-date'
                />
              )}
              size={'small'}
              sx={{ width: '30%' }}
              ChipProps={{
                variant: 'outlined',
                color: 'primary',
              }}
              onChange={(_, value) => {
                setSelectedMeasurements(value);
              }}
              value={selectedMeasurements}
              disabled={selectedRMS.includes('envelop') && nominalSpeed < 500}
            />
          </Grid>
          {selectedMeasurements.length > 0 ? (
            <>
              <Grid item xs={2} md={2} display='flex' justifyContent='flex-end'>
                <FormControlLabel
                  control={
                    <Checkbox checked={displayRunningSpeed} onChange={handleChangeDisplay} />
                  }
                  label='Display running speed'
                  disabled={selectedMeasurements.length > 1}
                  data-testid='display_running_speed'
                />
              </Grid>
              <Grid item xs={1} md={1} justifyContent={'right'}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<Fullscreen />}
                  sx={{ fontWeight: 500, fontSize: '14px', mr: '1rem' }}
                  onClick={() => setExpanded(!expanded)}
                >
                  Expand
                </Button>
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid container sx={{ width: '100%', height: '75vh', mt: '0rem' }}>
          <Grid item xs={12} sx={{ width: '100%', position: 'relative' }} data-testid='fft_chart'>
            {selectedMeasurements.length > 0 ? (
              <>
                {props.activeFFTType.startsWith('envelope') && <BearingPartsLegend />}
                <FftChart
                  xAxisLabel={axesLabels[0] ?? ''}
                  yAxisLabel={`${fftLabelDict.get(`${props.activeFFTType}-${props.axis}`)} [${
                    axesLabels[1]
                  }]`}
                  // unit={props.activeUnit}
                  type={props.activeFFTType}
                  chartData={chartData}
                  // selected={selectedMeasurements}
                  setTooltipValue={setTooltipValue}
                  displayRunningSpeed={displayRunningSpeed}
                  runningSpeedValues={runningSpeedValues[0]}
                  harmonicShapes={harmonicShapes}
                  peaks={peaks}
                />
              </>
            ) : (
              <SelectDataPrompt infoText='Pick date from select above to display chart' />
            )}

            <DodgeModal
              title='FFT'
              width={'97%'}
              height={'80rem'}
              open={expanded}
              onClose={() => setExpanded(!expanded)}
            >
              <div style={{ position: 'relative', height: '100%' }}>
                {props.activeFFTType.startsWith('envelope') && <BearingPartsLegend />}
                <FftChart
                  xAxisLabel={axesLabels[0] ?? ''}
                  yAxisLabel={`${fftLabelDict.get(`${props.activeFFTType}-${props.axis}`)} [${
                    axesLabels[1]
                  }]`}
                  // unit={props.activeUnit}
                  type={props.activeFFTType}
                  chartData={chartData}
                  // selected={selectedMeasurements}
                  setTooltipValue={setTooltipValue}
                  displayRunningSpeed={displayRunningSpeed}
                  runningSpeedValues={runningSpeedValues[0]}
                  harmonicShapes={harmonicShapes}
                  peaks={peaks}
                />
              </div>
            </DodgeModal>
          </Grid>
        </Grid>
      </Collapse>
      {tooltipValue && <ChartTooltip {...tooltipValue} renderInModal={expanded} />}
    </Box>
  );
};

export default memo(FftControl);
