import { useAuthQuery } from 'api/queries';
import { useTranslation } from 'languages';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';

export type PartProperties = {
  numberOfPhase: number | null;
  polesNumber: number | null;
  driveEndBearing: number | null;
  nonDriveEndBearing: number | null;
  frameSize: string | null;
  speed: string | null;
  power: string | null;
  variableSpeedDrive: boolean | null;
};

export interface PartBody {
  id: number;
  number: string;
  assetType: string;
  description: string | null;
  manufacturerId?: number;
  numberOfAssetInstances: number;
  properties?: PartProperties;
}

interface PartBodyResponse {
  records: PartBody[];
  totalRecords: number;
}

export const useGetCustomParts = (
  pageSize: number,
  page: number,
  search: string,
  assetType: string,
  sortOrder: string,
) => {
  const plantId = useSelector(selectPlantId);
  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      toastErrorMess: translate('failed_to_load_custom_parts_toast'),
    };
  }, []);

  return useAuthQuery<PartBodyResponse>({
    url: `plants/${plantId}/parts`,
    params: {
      pageSize: pageSize.toString(),
      pageNumber: page.toString(),
      sortOrder: sortOrder,
      searchString: search,
      assetType: assetType,
    },
    queryOptions: {
      meta: {
        error: {
          message: componentText.toastErrorMess,
        },
      },
    },
  });
};
