import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, forwardRef, memo } from 'react';

type Props = {
  children: ReactNode;
  sx?: SxProps<Theme>;
};

const StrokeContainer = forwardRef((props : Props, ref) => {
  const theme = useTheme();
  const isSm = useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`);

  return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          padding: '16px',
          background: '#FFFFFF',
          border: '1px solid #D9D9D9',
          borderRadius: '16px',
          margin: '1%',
          mr: !isSm ? 1 : '0.225rem',
          ...props.sx,
        }}
        ref={ref}
        data-testid='table-container'
      >
        {props.children}
      </Box>
  );
})

StrokeContainer.displayName = 'StrokeContainer';

export default memo(StrokeContainer);
