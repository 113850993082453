import { AxiosResponse } from 'axios';
import { SensorType } from 'models/sensor/models';
import { UseMutationResult } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectPlantId } from 'store/accountSlice';
import { useAuthMutation } from '../queries';

type Params = {
  assetGroupName?: string;
  sensorType?: SensorType;
  searchString?: string;
};

type Body = {
  type: string;
  notificationWay: 'email' | 'push';
  value: boolean;
};

export const usePutAllSensorsNotif = () => {
  const plantId = useSelector(selectPlantId);

  return useAuthMutation<Params, Body>({
    url(params) {
      const baseUrl = `users/plant/${plantId}/sensors-notifications`;

      const entries = Object.entries(params);

      if (entries.length === 0) return baseUrl;

      const queryParams = new URLSearchParams();
      entries.forEach(([key, value], index) => {
        if (!value) return;
        queryParams.append(key, value);
      });

      return baseUrl.concat('?' + queryParams.toString());
    },
    method: 'PUT',
  }) as UseMutationResult<AxiosResponse<unknown>>;
};
