import { FC, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, Alert, Divider, Button, Box, Link } from '@mui/material';
import { GatewaySensorsList } from '../../../../components/GatewaySensorsList/GatewaySensorsList';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useCommissionSensors } from 'api/gateways/useCommissionSensors';
import { Sensor } from 'features/plants-management/models/sensor.model';
import { selectPlantId } from 'store/accountSlice';
import createSensorsRecord from 'api/create-sensors-record';
import CommissionMonitor from './CommissionMonitor';
import { DisplayType } from '../AssetsTable/EditAsset/EditAsset';
import IntellilubeCommissionModal from './IntellilubeCommissionModal';
import { useTranslation } from 'languages';

type CommissionSensorsProps = {
  assetDetails: {
    id: number;
    name: string;
    assetGroupId: number;
    assetGroupName: string;
    sensorsCount: number;
    assetType: string;
  };
  showSuccess?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  setForceOpen?: (forceOpen: boolean) => void;
  onAssignSensors?: (display: DisplayType) => void;
  onlyIntelliLube?: boolean;
};

type Display = 'commissioning' | 'monitor';

const CommissionSensors: FC<CommissionSensorsProps> = ({
  assetDetails,
  showSuccess,
  onClose,
  onCancel,
  setForceOpen,
  onAssignSensors,
  onlyIntelliLube,
}) => {
  const plantId = useSelector(selectPlantId);
  const [selectedGatewayId, setSelectedGatewayId] = useState<string | undefined>(undefined);
  const [selectedSensors, setSelectedSensors] = useState<Sensor[]>([]);
  const [display, setDisplay] = useState<Display>('commissioning');
  const [commissionIntelliLube, setCommissionIntelliLube] = useState(false);
  const [readyToCommission, setReadyToCommission] = useState<boolean>(false);

  const { mutate: commissionSensorsMutation, isPending } = useCommissionSensors(() => {
    handleChangeModal();
    setCommissionIntelliLube(false);
  });

  const handleCommissionSensors = () => {
    if (
      selectedSensors.some(
        (sensor) => sensor.type === 'IntelliLube' || sensor.type === 'BreatherSensor',
      )
    ) {
      setCommissionIntelliLube(true);
    } else setReadyToCommission(true);
  };

  useEffect(() => {
    if (readyToCommission && selectedSensors.length > 0) {
      commissionSensorsMutation({
        params: {
          gatewayId: selectedGatewayId as string,
        },
        body: {
          assetId: assetDetails.id,
          plantId,
          sensors: createSensorsRecord(selectedSensors),
        },
      });
    }
  }, [readyToCommission]);
  const handleChangeModal = () => {
    setDisplay('monitor');
    setForceOpen?.(true);
  };

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      successfullyCreatedAssignedTo: translate('successfully_created_assigned_to'),
      desktopCommissioningWarning: translate('desktop_commissioning_warning'),
      selectedAsset: translate('selected_asset'),
      selectGatewayAndPressButton: translate('select_gateway_and_press_button'),
      cancel: translate('cancel'),
      commission: translate('commission'),
      assetGroupCommission: translate('asset_group_commission'),
      howToCommissionGateways: translate('how_to_commission_gateways'),
    };
  }, []);

  return (
    <Box>
      {display === 'commissioning' && (
        <Grid container spacing={3} mt={0} direction={'column'}>
          {showSuccess && (
            <Grid item>
              <Alert sx={{ width: '100%' }} data-testid='plants_management_success_alert'>
                <b>{assetDetails.name}</b> {componentText.successfullyCreatedAssignedTo}{' '}
                <b>{assetDetails.assetGroupName}</b> {componentText.assetGroupCommission}.
              </Alert>
            </Grid>
          )}
          <Grid item>
            <Typography variant='body1'>
              {componentText.desktopCommissioningWarning}{' '}
              <Link href='https://iiot-dodgeindustrial.swipeguide.com/' target='_blank' sx={{color: "#327179 !important"}}>
                {componentText.howToCommissionGateways}
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6' color='secondary' mb={-2.5}>
              {componentText.selectedAsset} <b>{assetDetails.name}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1'>{componentText.selectGatewayAndPressButton}</Typography>
          </Grid>
          <Grid item minHeight={'50%'}>
            <GatewaySensorsList
              onSelectedGatewayId={setSelectedGatewayId}
              selectedSensors={selectedSensors}
              setSelectedSensors={setSelectedSensors}
              data-testid='gateway-sensors-list'
              sensorsQuant={assetDetails.sensorsCount}
              assetType={assetDetails.assetType}
              onlyIntelliLube={onlyIntelliLube}
            />
          </Grid>
          <Grid item>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              {onCancel ? (
                <Button
                  variant='outlined'
                  onClick={onCancel}
                  data-testid='plant_management_commission_sensors_modal_window_cancel_button'
                >
                  {componentText.cancel}
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <LoadingButton
                variant='contained'
                color='secondary'
                loading={isPending}
                disabled={!selectedGatewayId || !selectedSensors.length}
                sx={{ color: 'text.primary', fontWeight: 'bold' }}
                onClick={handleCommissionSensors}
                data-testid='plant_management_commission_sensors_button'
              >
                {componentText.commission}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      {display === 'monitor' && selectedGatewayId && (
        <CommissionMonitor
          sensors={selectedSensors.map((s) => {
            return { serialNumber: s.serialNumber, location: s.location };
          })}
          gatewayId={selectedGatewayId}
          onClose={onClose}
          setForceOpen={setForceOpen}
          onAssignSensors={onAssignSensors}
        />
      )}
      {commissionIntelliLube && (
        <IntellilubeCommissionModal
          open={commissionIntelliLube}
          setReadyToCommission={setReadyToCommission}
          selectedSensors={selectedSensors}
          setSelectedSensors={setSelectedSensors}
          onClose={() => setCommissionIntelliLube(false)}
        />
      )}
    </Box>
  );
};
export default CommissionSensors;
