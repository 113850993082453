import * as React from 'react';
import { FC, memo } from 'react';
import Box from '@mui/material/Box';
import { StyledMenu } from './StyledMenu';
import Login from './Login';
import MenuContent from './MenuContent';
import { useGetAuthData } from 'auth/hooks';
import ManageAccount from '../ManageAccount/ManageAccount';
import { useSelector } from 'react-redux';
import { selectBlockUserPanel } from 'components/CookiePage/store/cookieSlice';

interface DropdownMenuProps{
    logout: ()=>void;
}

const DropdownMenu: FC<DropdownMenuProps> = ({logout}) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    if(modalOpen) {
      document.getElementsByTagName('body')[0].style.setProperty('overflow', 'hidden');
    } else {
      document.getElementsByTagName('body')[0].style.removeProperty('overflow');
    }

  } ,[modalOpen]);

  const [link, setlLink] = React.useState<string>("manage-plants");
  
  const isPreferencesRedirect = window.location.pathname.split('/').pop() === 'preferences';
  
  const isEulaOpen = useSelector(selectBlockUserPanel);
  React.useEffect(() =>{setAnchorEl(null)}, [isEulaOpen]);

  React.useEffect(() => {
    if (isPreferencesRedirect) {
      setModalOpen(true);
      setlLink("notification");
    }
  }, [isPreferencesRedirect]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    localStorage.removeItem('redirectionUrl');
    setAnchorEl(null);
  };

  const handleOpenManageAccount = (e: React.MouseEvent<HTMLElement>) => {
    setModalOpen(true); 
    setlLink(e.currentTarget.id);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    handleClose();
  };

  const { given_name, family_name, userEmail } = useGetAuthData(); 
  return (
    <Box display="inline-block">
      <Login open={open} handleClick={handleClick} firstName={given_name || "loading"} lastName={family_name || "..."} email={userEmail}/>
      <StyledMenu
        id="menu"
        MenuListProps={{
          'aria-labelledby': 'user',
        }}
        anchorEl={anchorEl}
        open={isEulaOpen ? false: open}
        onClose={handleClose}
      >
        <MenuContent logout={logout} handleOpenManageAccount={handleOpenManageAccount} firstName={given_name} lastName={family_name} email={userEmail}/>
      </StyledMenu>
        { modalOpen && <ManageAccount open={modalOpen}  onClose={handleModalClose} link={link} logout={logout} firstName={given_name} lastName={family_name} email={userEmail} /> }
    </Box> 
  );
}

export default memo(DropdownMenu);