import { Box } from '@mui/material';
import SelectDataPrompt from './SelectDataPrompt';
import { useSelector } from 'react-redux';
import { Asset, Sensor } from 'features/assets-management/models';
import { RootState } from 'app/store';
import { useMemo } from 'react';
import { useTranslation } from 'languages';

const SelectAssetsPrompt = () => {
  const assets: Asset[] = useSelector((state: RootState) => state.assetsManagement.selectedAssets);

  const { translate } = useTranslation();
  const componentText = useMemo(() => {
    return {
      unomitoredLabel: translate('asset_unmonitored_label'),
      selectAssetSensorLabel: translate('asset_select_asset_sensor_label'),
    };
  }, []);

  const isUnmonitoredAsset = useMemo(() => {
    return assets.length && assets.every((a) => a.sensors.length === 0);
  }, [assets]);

  return (
    <Box display='flex' justifyContent='center' alignItems='center' minHeight='80vh'>
      <SelectDataPrompt
        infoText={
          isUnmonitoredAsset
            ? componentText.unomitoredLabel
            : componentText.selectAssetSensorLabel
        }
        disableIcon={Boolean(isUnmonitoredAsset)}
      />
    </Box>
  );
};

export default SelectAssetsPrompt;
